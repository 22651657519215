import { useSelector } from "react-redux";
import {
  erreurDeOperation,
  estEnMode,
  MODE_AFFICHAGE,
  uneOperationEstEnCours,
} from "./reducer";

const { LIGHT } = MODE_AFFICHAGE;

export const useOperationEstEnCours = (operations) =>
  useSelector((state) => uneOperationEstEnCours(state.ui, operations));

export const useErreurDeOperation = (operations) =>
  useSelector((state) => erreurDeOperation(state.ui, operations));

export const useEstEnModeLight = () =>
  useSelector((state) => estEnMode(LIGHT, state.ui));

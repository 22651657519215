import { connect } from "react-redux";
import AiguillageVersLeDetail from "./AiguillageVersLeDetail";
import {
  erreurDeOperation,
  uneOperationEstEnCours,
} from "../../../../Ui/reducer";
import { selectDerogationDetaillee } from "../../domaine/reducer";
import { rapportFicheTransmissionUrl } from "../../../../Connecteurs/Crm/rapports";
import { CHARGER_UNE_DEROGATION_DETAILLEE } from "../../domaine/actions";

const mapStateToProps = (state, ownProps) => {
  const demande = selectDerogationDetaillee(
    state.derogations,
    ownProps.idDemande
  );

  const demandePasEncoreChargee = demande === undefined;

  return {
    isLoading:
      uneOperationEstEnCours(state.ui, [CHARGER_UNE_DEROGATION_DETAILLEE]) ||
      demandePasEncoreChargee,

    demande: {
      ...demande,
      urlRapport:
        demande && demande.ficheDeTransmission
          ? rapportFicheTransmissionUrl(demande.ficheDeTransmission.id)
          : null,
    },

    erreurs: erreurDeOperation(state.ui, [CHARGER_UNE_DEROGATION_DETAILLEE]),
  };
};

export default connect(mapStateToProps)(AiguillageVersLeDetail);

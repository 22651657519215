import React from "react";
import { ProgressBar } from "../../../../Kit/Loaders/ProgressBar";
import { FormattedMessage } from "react-intl";

export default function Loading({ progressBar }) {
  return (
    <div className="flex-dir-col flex-ai-center">
      {progressBar.description && (
        <span className="text-bold">
          <FormattedMessage id={progressBar.description} />
        </span>
      )}

      {progressBar && (
        <div>
          <div className="flex-ai-center flex-content-center mt-10">
            <ProgressBar value={progressBar.pourcentage} max={100} />
          </div>
        </div>
      )}
    </div>
  );
}

import {
  crmPatch,
  crmPost,
  fetchCrmEtParseReponse,
  fetchCrmPourUnique,
} from "./webApi";

export const httpServiceCrm = {
  get: cible => fetchCrmEtParseReponse(cible),

  getUnique: (cible, descriptionDeLaRecherche) =>
    fetchCrmPourUnique(cible, descriptionDeLaRecherche),

  post: (cible, body) => crmPost(cible, body),

  patch: (cible, body) => crmPatch(cible, body),
};

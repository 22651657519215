import { Money } from "../../../../../Money/money";
import { PAS_DE_TEG } from "./reducer";
import { httpK3000 } from "../../../../../Connecteurs/K3000/httpK3000";
import {
  ID_MARQUE_LIZEMED,
  isLizemed,
} from "../../../../../Connecteurs/Crm/codesGroupesOptions";
import {
  LABELS_PAYS_K3000,
  LABELS_TYPE_DE_FINANCEMENT_K3000,
} from "../../../../../Connecteurs/K3000/labelsK3000";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";

const { ESPAGNE, FRANCE } = MARCHES;

export const tegAdapterK3000 = {
  async getTeg(recherche, { http = httpK3000 } = {}) {
    try {
      const { dureeEnMois, montant } = recherche;

      const { taux } = await http.post(`/api/v1/teg/taux`, {
        dureeEnMois,
        montantAFinancer: String(Money(montant).toUnit()),
        parametres: getParametresGrilleDeTeg(recherche),
      });

      return taux;
    } catch (e) {
      // Il arrive parfois que K3000 renvoie des erreurs bas-niveau que Force ne parvient pas à lire.
      // https://sentry.io/organizations/itl/issues/1728604981/events/c9e4a07733fb4d1c97079d8c4acd265c/?project=1301490
      // La raison n'est pas très claire...pour mieux comprendre pour l'instant on re-throw
      if (!e.details) throw e;

      const { httpStatus } = e.details;

      const pasDeTeg = httpStatus === 404;
      if (pasDeTeg) return PAS_DE_TEG;

      throw e;
    }
  },
};

export function getParametresGrilleDeTeg({
  marche,
  marcheProjet,
  marqueItl,
  typeDeFinancement,
}) {
  if (isLizemed(marqueItl)) return [clePays(), cleMarche(), cleMarqueItl()];
  else {
    if (marche === FRANCE)
      return [clePays(), cleMarche(), cleTypeFinancement()];
    if (marche === ESPAGNE) return [clePays(), cleMarche()];
  }

  function clePays() {
    return { cle: "pays", valeur: LABELS_PAYS_K3000[marche] };
  }
  function cleMarche() {
    return { cle: "marche", valeur: marcheProjet };
  }
  function cleMarqueItl() {
    return { cle: "marque_itl", valeur: ID_MARQUE_LIZEMED };
  }
  function cleTypeFinancement() {
    return {
      cle: "typefinancement",
      valeur: LABELS_TYPE_DE_FINANCEMENT_K3000[typeDeFinancement],
    };
  }
}

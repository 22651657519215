import { httpServiceCrm } from "../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../utils/odata/odata";
import { identiteAdapterCrm } from "../../../../../identiteUtilisateur/identite.adapter.crm";

export const detailPourNotificationMargeAdapterCrm = {
  async getDetailPourNotification(
    { idDerogation },
    { http = httpServiceCrm, identiteAdapter = identiteAdapterCrm } = {}
  ) {
    const derogationConcernee = await getDetailDerogation(idDerogation, http);
    const marcheDuDemandeur = await identiteAdapter.getMarcheDeUtilisateur(
      derogationConcernee.createdby.systemuserid
    );

    const [ftConcernee, advDuDemandeur] = await Promise.all([
      getDetailFtConcernee(derogationConcernee, http),
      identiteAdapter.getAdvDesCommerciaux(marcheDuDemandeur),
    ]);

    return {
      derogationConcernee: {
        dateDemande: derogationConcernee.itl_datedemande,
        derogateur: {
          nom: derogationConcernee.itl_derogateur.itl_name,
          commentaire: derogationConcernee.itl_commentairesdecideur,
        },
        demandeur: {
          id: derogationConcernee.createdby.systemuserid,
          marche: marcheDuDemandeur,
          directeur: {
            id: derogationConcernee.createdby._parentsystemuserid_value,
          },
          adv: advDuDemandeur.map(adv => ({ id: adv.idUtilisateur })),
        },
        ft: { id: derogationConcernee.itl_FTconcernee.itl_fichetransmissionid },
        client: { nom: ftConcernee.itl_CompteId.name },
        projet: {
          id: derogationConcernee.itl_projetconcerne.itl_projetid,
          nom: derogationConcernee.itl_projetconcerne.itl_projectname,
        },
      },
    };
  },
};

async function getDetailDerogation(idDerogation, http) {
  const requeteDetailDerogation = odataQuery(
    `itl_derogations(${idDerogation})`,
    {
      $select: ["itl_datedemande", "itl_commentairesdecideur"],
      $expand: [
        [
          "itl_projetconcerne",
          { $select: ["itl_projetid", "itl_projectname"] },
        ],
        ["itl_FTconcernee", { $select: ["itl_fichetransmissionid"] }],
        [
          "createdby",
          { $select: ["systemuserid", "_parentsystemuserid_value"] },
        ],
        ["itl_derogateur", { $select: ["itl_name"] }],
      ],
    }
  );

  return http.get(requeteDetailDerogation);
}

async function getDetailFtConcernee(detailDerogationCrm, http) {
  const requeteFtConcernee = odataQuery(
    `itl_fichetransmissions(${
      detailDerogationCrm.itl_FTconcernee.itl_fichetransmissionid
    })`,
    { $expand: [["itl_CompteId", { $select: ["name"] }]] }
  );

  return http.get(requeteFtConcernee);
}

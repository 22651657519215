import { connect } from "react-redux";
import { PaliersPourInvestissement } from "./PaliersPourInvestissement";
import { calculerEnMultiPaliers } from "../../../domaine/simulation/modeDeCalcul/actions";
import { orchestrerLePlanDeFinancement } from "../../../domaine/planDeFinancement/actions";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import { planDeFinancementEstCalculable } from "../../../domaine/planDeFinancement/naturesDuCalcul/investissement/planDeFinancementEstCalculable";
import { selectPaliersLoyersFinancier } from "../../../domaine/planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

const mapStateToProps = (state) => {
  const { simulation, planDeFinancement } = calculateur(state);
  return {
    // Si le plan n'est pas calculable, on montre à l'utilisateur les paliers qu'il a saisi.
    // Ça lui permet de conserver un Force "utilisable".
    paliers: planDeFinancementEstCalculable(simulation)
      ? selectPaliersLoyersFinancier(planDeFinancement)
      : simulation.modeDeCalcul.paliers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPaliersChange: (paliers) => {
    dispatch(calculerEnMultiPaliers(paliers));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaliersPourInvestissement);

import uuid from "uuid";
import Instantanes from "./Instantanes";
import { connect } from "react-redux";
import { listeDesInstantanes } from "../../../../reduxStore/enhancers/instantanes/avecInstantanes";
import {
  prendreInstantane,
  supprimerInstantane,
} from "../../../../reduxStore/enhancers/instantanes/actions";
import { resumeDeCalculateur } from "./resumeDeCalculateur";
import { initialiserCalculateurDepuisInstantane } from "../../domaine/actions";
import { selectProjetDeTravail } from "../../domaine/simulation/projetDeTravail/reducer";
import { choixDuProjet } from "../../../../reduxStore/rootReducer";

const mapStateToProps = (state) => ({
  instantanes: listeDesInstantanes(state.calculateur.etatStable).map(
    ({ id, timestampCreation, capture }) => ({
      id,
      timestampCreation,
      ...resumeDeCalculateur(
        capture,
        selectProjetDeTravail(choixDuProjet(state))
      ),
    })
  ),
});

const mapDispatchToProps = (dispatch) => ({
  onClick: (idInstantane) => {
    dispatch(initialiserCalculateurDepuisInstantane(idInstantane, Date.now()));
  },
  onCreate: () => dispatch(prendreInstantane(uuid(), Date.now())),
  onDelete: (idInstantane) => dispatch(supprimerInstantane(idInstantane)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Instantanes);

import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { getQueryStringParam } from "../../../routing/routingUtils";
import ErrorBoundary from "../../../Kit/Erreur/ErrorBoundary";
import Calculateur from "./Calculateur";
import ControleDeProjetUtilisable from "./ProjetDeTravail/ControleDeProjetUtilisable.container";
import InitialiserCalculateur from "./InitialiserCalculateur";

export function EntreeCalculateur() {
  const location = useLocation();
  const idProjet = getQueryStringParam(location, "id-projet");

  if (!idProjet) return <Redirect to="/" />;

  const idFtImport = getQueryStringParam(location, "id-ft");

  return (
    <ErrorBoundary zone="CALCULATEUR">
      <ControleDeProjetUtilisable idProjet={idProjet.toLowerCase()}>
        <InitialiserCalculateur idFtImport={idFtImport}>
          <Calculateur />
        </InitialiserCalculateur>
      </ControleDeProjetUtilisable>
    </ErrorBoundary>
  );
}

import { connect } from "react-redux";
import UnRachatDeMateriel from "./UnRachatDeMateriel";
import {
  racheterUnMateriel,
  supprimerRachatDeMateriel,
} from "../../../../domaine/simulation/montantAFinancer/actions";
import { LONGUEUR_MAX_DESCRIPTION_DEPENSE } from "../../../../../../Connecteurs/Crm/limites";
import { orchestrerLePlanDeFinancement } from "../../../../domaine/planDeFinancement/actions";
import { estEnMode, MODE_AFFICHAGE } from "../../../../../../Ui/reducer";

const { LIGHT } = MODE_AFFICHAGE;

const mapStateToProps = (state, ownProps) => ({
  longueurMaxDescription: LONGUEUR_MAX_DESCRIPTION_DEPENSE,
  rachat: ownProps.rachat,
  estEnModeLight: estEnMode(LIGHT, state.ui),
});

const mapDispatchToProps = dispatch => ({
  onChange: rachat => dispatch(racheterUnMateriel(rachat)),
  onChangeQuiRecalcule: rachat => {
    dispatch(racheterUnMateriel(rachat));
    dispatch(orchestrerLePlanDeFinancement());
  },
  onDelete: rachat => {
    dispatch(supprimerRachatDeMateriel(rachat.id));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnRachatDeMateriel);

import { commissionsAdapterCrm } from "../App/Calculateur/domaine/creationDeFt/creation/depenses/commission/commissions.adapter.crm";
import { materielsAdapterCrm } from "../App/Calculateur/domaine/creationDeFt/creation/depenses/materiels/materiels.adapter.crm";
import { identiteAdapterCrm } from "../identiteUtilisateur/identite.adapter.crm";
import { loyersAdapterCrm } from "../App/Calculateur/domaine/creationDeFt/creation/loyers/loyers.adapter.crm";
import { projetsAdapterCrm } from "../App/Calculateur/domaine/simulation/projetDeTravail/projets.adapter.crm";
import { margeCibleAdapterHardcode } from "../App/Calculateur/domaine/simulation/margeCible/margeCible.adapter.hardcode";
import { referentielAdapterCrm } from "../referentiel/referentiel.adapter.crm";
import { ftPourImportAdapterCrm } from "../App/Calculateur/domaine/importDeFt/ftPourImport.adapter.crm";
import { produitAssuranceAdapterAssu4000 } from "../App/Calculateur/domaine/simulation/assurance/france/produitAssurance.adapter.assu4000";
import { notificationDeVrAdapterCrm } from "../App/Calculateur/domaine/simulation/derogations/vr/notificationDeVr.adapter.crm";
import { creerAssuranceEspagneAdapterCrm } from "../App/Calculateur/domaine/creationDeFt/creation/assurance/espagne/creerAssurance.espagne.adapter.crm";
import { derogationDeVrAdapterCrm } from "../App/Derogations/domaine/typesDerogations/vr/derogationDeVr.adapter.crm";
import { derogationsParProjetAdapterCrm } from "../App/Derogations/domaine/derogationsParProjet.adapter.crm";
import { resumeDeDerogationAdapterCrm } from "../App/Derogations/domaine/resumeDeDerogation.adapter.crm";
import { creerAssuranceFranceAdapterCrm } from "../App/Calculateur/domaine/creationDeFt/creation/assurance/france/creerAssurance.france.adapter.crm";
import { derogationDeMargeAdapterCrm } from "../App/Derogations/domaine/typesDerogations/marge/derogationDeMarge.adapter.crm";
import { creerDerogationDeMargeAdapterCrm } from "../App/Calculateur/domaine/creationDeFt/creation/derogationDeMarge/creerDerogationDeMarge.adapter.crm";
import { assuranceFranceAdapterK3000 } from "../App/Calculateur/domaine/simulation/assurance/france/assurance.france.adapter.k3000";
import { planDeFinancementLoyersAdapterK3000 } from "../App/Calculateur/domaine/planDeFinancement/naturesDuCalcul/loyers/planDeFinancementLoyers.adapter.k3000";
import { derogateursAdapterCrm } from "../App/Calculateur/domaine/simulation/derogateurs/derogateurs.adpter.crm";
import { profilUtilisateurAdapterCrm } from "../App/Calculateur/domaine/simulation/profilUtilisateur/profilUtilisateur.adapter.crm";
import { tegAdapterK3000 } from "../App/Calculateur/domaine/simulation/tauxInteret/teg.adapter.k3000";
import { detailPourNotificationMargeAdapterCrm } from "../App/Derogations/domaine/typesDerogations/marge/detailPourNotificationMarge.adapter.crm";
import { notificationDerogationDeVrAdapterCrm } from "../App/Derogations/domaine/typesDerogations/vr/notificationDerogationDeVr.adapter.crm";
import { marquesItlAdapterCrm } from "../App/ModeLight/domaine/marquesItl.adapter.crm";
import { marchesAdapterCrm } from "../App/ModeLight/domaine/marches.adapter.crm";
import { ftAdapterCrm } from "../App/Calculateur/domaine/creationDeFt/creation/ft/ft.adapter.crm";
import { creerDerogationDeTegAdapterCrm } from "../App/Calculateur/domaine/creationDeFt/creation/derogationDeTeg/creerDerogationDeTeg.adapter.crm";
import { derogationDeTegAdapterCrm } from "../App/Derogations/domaine/typesDerogations/teg/derogationDeTeg.adapter.crm";
import { detailPourNotificationAdapterCrm } from "../App/Derogations/domaine/notification/detailPourNotification.adapter.crm";
import { planDeFinancementInvestissementAdapterK3000 } from "../App/Calculateur/domaine/planDeFinancement/naturesDuCalcul/investissement/planDeFinancementInvestissement.adapter.k3000";
import { margeAdapterK3000 } from "../App/Calculateur/domaine/simulation/montantAFinancer/marge.adapter.K3000";
import { vrGrillesAdapterK3000 } from "../App/Calculateur/domaine/simulation/valeurResiduelle/vrGrilles.adapter.k3000";
import { fraisDeReglementAdapterK3000 } from "../App/Calculateur/domaine/planDeFinancement/naturesDuCalcul/loyers/fraisDeReglement.adapter.k3000";
import { creerMaintenanceAdapterCrm } from "../App/Calculateur/domaine/creationDeFt/creation/maintenance/creerMaintenance.adapter.crm";
import { derogationAssuranceEspagneAdapterCrm } from "../App/Derogations/domaine/typesDerogations/assuranceEspagne/derogationAssuranceEspagne.adapter.crm";
import { decisionDeDerogationAdapterCrm } from "../App/Derogations/domaine/decision/decisionDeDerogation.adapter.crm";
import { catalogueProduitsAdapterR4000 } from "../App/Calculateur/domaine/simulation/catalogueProduits/catalogueProduits.adapter.r4000";

export const ADAPTERS_DE_PROD = {
  assuranceFranceAdapter: assuranceFranceAdapterK3000,
  catalogueProduitsAdapter: catalogueProduitsAdapterR4000,
  commissionsAdapter: commissionsAdapterCrm,
  creerAssuranceEspagneAdapter: creerAssuranceEspagneAdapterCrm,
  creerAssuranceFranceAdapter: creerAssuranceFranceAdapterCrm,
  creerDerogationDeMargeAdapter: creerDerogationDeMargeAdapterCrm,
  creerDerogationDeTegAdapter: creerDerogationDeTegAdapterCrm,
  creerMaintenanceAdapter: creerMaintenanceAdapterCrm,
  decisionDeDerogationAdapter: decisionDeDerogationAdapterCrm,
  derogateursAdapter: derogateursAdapterCrm,
  derogationDeMargeAdapter: derogationDeMargeAdapterCrm,
  derogationDeTegAdapter: derogationDeTegAdapterCrm,
  derogationDeVrAdapter: derogationDeVrAdapterCrm,
  derogationAssuranceEspagneAdapter: derogationAssuranceEspagneAdapterCrm,
  derogationsParProjetAdapter: derogationsParProjetAdapterCrm,
  detailPourNotificationMargeAdapter: detailPourNotificationMargeAdapterCrm,
  detailPourNotificationAdapter: detailPourNotificationAdapterCrm,
  fraisDeReglementAdapter: fraisDeReglementAdapterK3000,
  ftAdapter: ftAdapterCrm,
  ftPourImportAdapter: ftPourImportAdapterCrm,
  identiteAdapter: identiteAdapterCrm,
  loyersAdapter: loyersAdapterCrm,
  marchesAdapter: marchesAdapterCrm,
  margeAdapter: margeAdapterK3000,
  margeCibleAdapter: margeCibleAdapterHardcode,
  marquesItlAdapter: marquesItlAdapterCrm,
  materielsAdapter: materielsAdapterCrm,
  notificationDeVrAdapter: notificationDeVrAdapterCrm,
  notificationDerogationDeVrAdapter: notificationDerogationDeVrAdapterCrm,
  planDeFinancementLoyersAdapter: planDeFinancementLoyersAdapterK3000,
  planDeFinancementInvestissementAdapter: planDeFinancementInvestissementAdapterK3000,
  produitAssuranceAdapter: produitAssuranceAdapterAssu4000,
  profilUtilisateurAdapter: profilUtilisateurAdapterCrm,
  projetsAdapter: projetsAdapterCrm,
  referentielAdapter: referentielAdapterCrm,
  resumeDeDerogationAdapter: resumeDeDerogationAdapterCrm,
  tegAdapter: tegAdapterK3000,
  vrGrillesAdapter: vrGrillesAdapterK3000,
};

export function registerAdapters(adapters) {
  for (const [nomAdapter, adapter] of Object.entries(adapters)) {
    Adapters[nomAdapter] = adapter;
  }
}

export const Adapters = {};

import {
  RECUPERER_DEROGATEUR_ASSURANCE_ESPAGNE_SUCCES,
  RECUPERER_DEROGATEUR_DE_MARGE_SUCCES,
  VIDER_LES_DEROGATEURS_DE_MARGE,
} from "./actions";
import { POSTE_UTILISATEUR } from "../../../../../identiteUtilisateur/reducer";

const { DIRECTEUR_REGIONAL, DIRECTEUR_COMMERCIAL } = POSTE_UTILISATEUR;

const STATE_INITIAL = {
  derogateursDeMarge: [],
  derogateurAssuranceEspagne: null,
};

export default function derogateursReducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case RECUPERER_DEROGATEUR_DE_MARGE_SUCCES:
      return {
        ...state,
        derogateursDeMarge: [...state.derogateursDeMarge, action.derogateur],
      };

    case VIDER_LES_DEROGATEURS_DE_MARGE:
      return { ...state, derogateursDeMarge: STATE_INITIAL.derogateursDeMarge };

    case RECUPERER_DEROGATEUR_ASSURANCE_ESPAGNE_SUCCES:
      return { ...state, derogateurAssuranceEspagne: action.derogateur };

    default:
      return state;
  }
}

export function selectDerogateurDeMargeCompetentPourAction(state, tauxDemande) {
  if (tauxDemande < 0)
    return derogateurDeMargeAuPoste(state, DIRECTEUR_COMMERCIAL);

  const derogateurDR = derogateurDeMargeAuPoste(state, DIRECTEUR_REGIONAL);
  if (derogateurDR && derogateurDR.autonomieDeDerogation <= tauxDemande)
    return derogateurDR;

  return derogateurDeMargeAuPoste(state, DIRECTEUR_COMMERCIAL);
}

function derogateurDeMargeAuPoste(state, poste) {
  return state.derogateursDeMarge.find((d) => d.poste === poste);
}

export const selectDerogateurDeMargeAInformer = (
  state,
  derogateurPourAction
) => {
  if (derogateurPourAction.poste === DIRECTEUR_REGIONAL) return undefined;

  if (derogateurPourAction.poste === DIRECTEUR_COMMERCIAL) {
    const derogateurDR = derogateurDeMargeAuPoste(state, DIRECTEUR_REGIONAL);
    if (derogateurDR.idUtilisateur !== derogateurPourAction.idUtilisateur)
      return derogateurDR;
  }
};

export const anomalieEstDerogeeParUtilisateurConnecte = (
  anomalieAvecDerogateur
) => anomalieAvecDerogateur.derogateur.estUtilisateurConnecte === true;

export const selectDerogateurDeMargeConnecte = (state) =>
  state.derogateursDeMarge.find((d) => d.estUtilisateurConnecte === true);

export function selectDerogateurAssuranceEspagne(state) {
  return state.derogateurAssuranceEspagne;
}

import {
  ACTIVER_MAINTENANCE,
  DECALER_DEBUT_DE_MAINTENANCE,
  DEFINIR_MONTANT_MAINTENANCE_PAR_ECHEANCE,
  DESACTIVER_MAINTENANCE,
  PASSER_EN_MAINTENANCE_AVEC_EQUILIBRAGE,
  REVENIR_EN_MAINTENANCE_SANS_EQUILIBRAGE,
} from "./actions";
import {
  PERIODICITE_PAR_DEFAUT,
  PERIODICITES,
} from "../modeDeCalcul/periodicite/periodicite";
import {
  CALCULER_EN_LINEAIRE,
  CALCULER_EN_MULTI_PALIERS,
  CHANGER_LA_PERIODICITE,
} from "../modeDeCalcul/actions";
import { nombreEcheances } from "../../planDeFinancement/paliers";

export const STATE_INITIAL = {
  estActivee: false,
  equilibrage: { actif: false, disponible: false, nbEcheancesDuPlan: 0 },
  montantTotal: null,
  montantParEcheance: null,
  dureeEnEcheances: null,
  nbEcheancesDeDecalage: null,
  periodicite: PERIODICITE_PAR_DEFAUT,
};

export function maintenanceReducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case ACTIVER_MAINTENANCE: {
      return {
        ...state,
        estActivee: true,
        ...action.maintenance,
        equilibrage: getEquilibrage(
          state.nbEcheancesDeDecalage,
          action.dureeEnEcheances,
          state.equilibrage.nbEcheancesDuPlan
        ),
      };
    }

    case DEFINIR_MONTANT_MAINTENANCE_PAR_ECHEANCE:
      return { ...state, montantParEcheance: action.montantParEcheance };

    case DECALER_DEBUT_DE_MAINTENANCE: {
      return {
        ...state,
        nbEcheancesDeDecalage: action.nbEcheancesDeDecalage,
        equilibrage: getEquilibrage(
          action.nbEcheancesDeDecalage,
          state.dureeEnEcheances,
          state.equilibrage.nbEcheancesDuPlan
        ),
      };
    }

    case CHANGER_LA_PERIODICITE:
      return {
        ...state,
        periodicite: action.periodicite,

        nbEcheancesDeDecalage: PERIODICITES.convertir(
          state.nbEcheancesDeDecalage,
          state.periodicite
        ).en(action.periodicite),

        dureeEnEcheances: PERIODICITES.convertir(
          state.dureeEnEcheances,
          state.periodicite
        ).en(action.periodicite),
      };

    case PASSER_EN_MAINTENANCE_AVEC_EQUILIBRAGE:
      return { ...state, equilibrage: { ...state.equilibrage, actif: true } };

    case REVENIR_EN_MAINTENANCE_SANS_EQUILIBRAGE:
      return { ...state, equilibrage: { ...state.equilibrage, actif: false } };

    case CALCULER_EN_LINEAIRE:
      return {
        ...state,
        equilibrage: getEquilibrage(
          state.nbEcheancesDeDecalage,
          state.dureeEnEcheances,
          action.nombreEcheances
        ),
      };

    case CALCULER_EN_MULTI_PALIERS:
      return {
        ...state,
        equilibrage:
          action.paliers.length <= 2
            ? getEquilibrage(
                state.nbEcheancesDeDecalage,
                state.dureeEnEcheances,
                nombreEcheances(action.paliers)
              )
            : STATE_INITIAL.equilibrage,
      };

    case DESACTIVER_MAINTENANCE:
      return STATE_INITIAL;

    default:
      return state;
  }

  function getEquilibrage(decalage, duree, dureeDuPlan) {
    const equilibrageEstDisponible = decalage + duree === dureeDuPlan;

    return {
      ...state.equilibrage,
      actif: equilibrageEstDisponible ? state.equilibrage.actif : false,
      disponible: equilibrageEstDisponible,
      nbEcheancesDuPlan: dureeDuPlan,
    };
  }
}

export function selectMaintenance(state) {
  return state;
}

export function doitCalculerLaMaintenance(state) {
  if (!state.estActivee) return false;
  if (state.montantTotal === null) return false;
  if (state.dureeEnEcheances === null) return false;

  return true;
}

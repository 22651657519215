import { MARCHES } from "../../../../../identiteUtilisateur/reducer";
import { $, Zero } from "../../../../../Money/money";
import {
  montantDeLaMargeEffective,
  valeurDuMontantAFinancer,
} from "./montants";
import { auMoinsUneAssurance } from "../assurance/france/reducer";

const { ESPAGNE, FRANCE } = MARCHES;

export const MONTANT_INVESTISSEMENT_TROP_ELEVE =
  "MONTANT_INVESTISSEMENT_TROP_ELEVE";

export const commissionCommerciale = (
  state,
  marcheUtilisateur,
  stateAssurance,
  client
) => {
  const calculParMarche = {
    [ESPAGNE]: () => calculEspagne(state),
    [FRANCE]: () => calculFrance(state, stateAssurance.france, client),
  };

  return calculParMarche[marcheUtilisateur]();
};

function calculFrance(state, stateAssuranceFrance, client) {
  const limite = $(750000);
  const maf = valeurDuMontantAFinancer(state);
  const investissementTropEleve = maf.greaterThan(limite);

  if (investissementTropEleve)
    return {
      erreur: {
        type: MONTANT_INVESTISSEMENT_TROP_ELEVE,
        limite: limite.toObject(),
      },
    };

  function getTauxDeCommission() {
    const tauxDeBase = client.estNouveau ? 0.08 : 0.04;
    const majorationPourAssurance = auMoinsUneAssurance(stateAssuranceFrance)
      ? 0.005
      : 0;
    return tauxDeBase + majorationPourAssurance;
  }

  const tauxDeCommission = getTauxDeCommission();
  return {
    montant: montantDeLaMargeEffective(state).multiply(tauxDeCommission),
  };
}

function calculEspagne(state) {
  return { montant: montantDeLaMargeEffective(state).multiply(0.05) };
}

export const COMMISSION_NULLE = { montant: Zero };

import { Adapters } from "../../../../../../reduxStore/adapters";
import { call, put, select } from "redux-saga/effects";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import planDeFinancementEstCalculable from "./planDeFinancementEstCalculable";
import { chargerPlanDeFinancement } from "../../actions";
import { PLAN_DE_FINANCEMENT_VIDE } from "../../PlanDeFinancement/PlanDeFinancement";
import {
  recupererLesVrGrillesSaga,
  recupererLesVrUnLoyerSaga,
} from "../../../simulation/valeurResiduelle/sagas";
import { vrUnLoyerEstActivee } from "../../../simulation/valeurResiduelle/selecteurs/vrUnLoyer.selecteurs";
import { recupererTegGrilleSaga } from "../../../simulation/tauxInteret/sagas";
import {
  MODES_DE_CALCUL,
  selectModeDeCalcul,
} from "../../../simulation/modeDeCalcul/reducer";
import { recalculerLeTauxDeMargePourTegSaga } from "../../../simulation/montantAFinancer/marge.sagas";
import { getParametresEffectifsPourLePlan } from "./planDeFinancement.parametres";
import { calculerMontantMaintenanceParEcheanceSaga } from "../../../simulation/maintenance/sagas";

const { LINEAIRE, MULTI_PALIERS } = MODES_DE_CALCUL;

export function* orchestrationDuCalculLoyers() {
  yield call(recupererTegGrilleSaga);
  yield call(calculerPlanDeFinancementSaga);
}

export function* calculerPlanDeFinancementSaga({
  planDeFinancementAdapter = Adapters.planDeFinancementLoyersAdapter,
} = {}) {
  const { simulation } = yield select(calculateur);

  if (!planDeFinancementEstCalculable(simulation)) {
    yield put(chargerPlanDeFinancement(PLAN_DE_FINANCEMENT_VIDE));
    return;
  }

  yield* recupererLesVr();
  yield* recalculerLeTauxDeMargePourTeg();
  yield* calculerLeMontantParEcheanceDeMaintenance();
  yield* calculerLePlanDeFinancement({ planDeFinancementAdapter });
}

function* recupererLesVr() {
  yield call(recupererLesVrGrillesSaga);

  const { simulation } = yield select(calculateur);
  if (vrUnLoyerEstActivee(simulation.valeurResiduelle))
    yield call(recupererLesVrUnLoyerSaga);
}

function* recalculerLeTauxDeMargePourTeg() {
  yield call(recalculerLeTauxDeMargePourTegSaga);
}

function* calculerLeMontantParEcheanceDeMaintenance() {
  yield call(calculerMontantMaintenanceParEcheanceSaga);
}

function* calculerLePlanDeFinancement({ planDeFinancementAdapter }) {
  const { simulation } = yield select(calculateur);

  const calculParMode = {
    [LINEAIRE]: planDeFinancementAdapter.calculerPlanLineaire,
    [MULTI_PALIERS]: planDeFinancementAdapter.calculerPlanMultiPaliers,
  };
  const calcul = calculParMode[selectModeDeCalcul(simulation.modeDeCalcul)];

  const plan = yield call(calcul, getParametresEffectifsPourLePlan(simulation));
  yield put(chargerPlanDeFinancement(plan));
}

export const CHARGER_MARQUES_ITL = "ModeLight/CHARGER_MARQUES_ITL";
export const chargerMarquesItl = () => ({ type: CHARGER_MARQUES_ITL });

export const CHARGER_MARQUES_ITL_SUCCES = `${CHARGER_MARQUES_ITL}_SUCCES`;
export const chargerMarquesItl_succes = (marquesItl) => ({
  type: CHARGER_MARQUES_ITL_SUCCES,
  marquesItl,
});

export const INITIALISER_MODE_LIGHT = "ModeLight/INITIALISER_MODE_LIGHT";
export const initialiserModeLight = (idMarcheChoisi, marqueItlChoisie) => ({
  type: INITIALISER_MODE_LIGHT,
  idMarcheChoisi,
  marqueItlChoisie,
});

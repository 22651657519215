export const DONNER_TEG_GRILLE = "DONNER_TEG_GRILLE";
export const donnerTegGrille = (tegGrille) => ({
  type: DONNER_TEG_GRILLE,
  tegGrille,
});

export const DONNER_TEG_MANUEL = "DONNER_TEG_MANUEL";
export const donnerTegManuel = (tegManuel) => ({
  type: DONNER_TEG_MANUEL,
  tegManuel,
});

export const REVENIR_AU_TEG_GRILLE = "REVENIR_AU_TEG_GRILLE";
export function revenirAuTegGrille() {
  return { type: REVENIR_AU_TEG_GRILLE };
}

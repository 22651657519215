import {
  AJOUTER_PIECE_JOINTE,
  MOTIVER_ANOMALIE,
  SUPPRIMER_PIECE_JOINTE,
} from "./actions";
import { ANOMALIES } from "../anomalies/Anomalies";

const ETAT_INITIAL = {
  motivations: { parAnomalie: {} },
  piecesJointes: { parAnomalie: {} },
};

function motivationsDesAnomalies(state = ETAT_INITIAL, action) {
  switch (action.type) {
    case MOTIVER_ANOMALIE: {
      const idAnomalie = creerIdSuivantTypeAnomalie(action.anomalie);
      return {
        ...state,
        motivations: {
          parAnomalie: {
            ...state.motivations.parAnomalie,
            [idAnomalie]: action.texte,
          },
        },
      };
    }

    case AJOUTER_PIECE_JOINTE: {
      const idAnomalie = creerIdSuivantTypeAnomalie(action.anomalie);
      return {
        ...state,
        piecesJointes: {
          parAnomalie: {
            ...state.piecesJointes.parAnomalie,
            [idAnomalie]: [
              ...(state.piecesJointes.parAnomalie[idAnomalie] || []),
              action.pieceJointe,
            ],
          },
        },
      };
    }

    case SUPPRIMER_PIECE_JOINTE: {
      const sansLaPj = Object.entries(state.piecesJointes.parAnomalie)
        .map(([idAnomalie, pjs]) => [
          idAnomalie,
          pjs.filter((pj) => pj.id !== action.pieceJointe.id),
        ])
        .reduce(
          (resultat, [idAnomalie, pjs]) => ({ ...resultat, [idAnomalie]: pjs }),
          {}
        );

      return { ...state, piecesJointes: { parAnomalie: sansLaPj } };
    }

    default:
      return state;
  }
}

export default motivationsDesAnomalies;

export function enrichirAvecMotivation(anomalie, state) {
  return { ...anomalie, ...selectMotivation(state, anomalie) };
}

export const selectMotivation = (state, anomalie) => {
  const idAnomalie = creerIdSuivantTypeAnomalie(anomalie);
  return {
    motivation: state.motivations.parAnomalie[idAnomalie] || null,
    piecesJointes: state.piecesJointes.parAnomalie[idAnomalie] || [],
  };
};

function creerIdSuivantTypeAnomalie(anomalie) {
  switch (anomalie.idType) {
    case ANOMALIES.TAUX_DE_VR_NECESSITE_DEROGATION:
      return `${anomalie.idType}.${anomalie.details.produit.id}`;
    default:
      return anomalie.idType;
  }
}

export function tailleDesPiecesJointes(state, limiteEnMegaOctets) {
  const toutesLesPjs = Object.values(state.piecesJointes.parAnomalie).flat();
  const totalEnKo = toutesLesPjs.reduce(
    (total, pj) => total + pj.tailleEnKo,
    0
  );
  const tailleEnMo = totalEnKo / 1024;
  const depasseLaLimite = tailleEnMo > limiteEnMegaOctets;
  return {
    depasseLaLimite,
    tailleEnMo: tailleEnMo.toFixed(2),
    limite: limiteEnMegaOctets.toFixed(2),
  };
}

import {
  CALCULER_EN_LINEAIRE,
  CALCULER_EN_MULTI_PALIERS,
  CHANGER_LA_PERIODICITE,
  CHANGER_NATURE_DU_CALCUL,
} from "./actions";
import {
  nombreDeMois,
  PERIODICITE_PAR_DEFAUT,
  PERIODICITES,
} from "./periodicite/periodicite";
import { CHARGER_PLAN_DE_FINANCEMENT } from "../../planDeFinancement/actions";
import { PLAN_DE_FINANCEMENT_VIDE } from "../../planDeFinancement/PlanDeFinancement/PlanDeFinancement";

export const MODES_DE_CALCUL = {
  LINEAIRE: "LINEAIRE",
  MULTI_PALIERS: "MULTI-PALIERS",
};

export const NATURE_DU_CALCUL = {
  LOYERS: "LOYERS",
  INVESTISSEMENT: "INVESTISSEMENT",
};

const { LINEAIRE, MULTI_PALIERS } = MODES_DE_CALCUL;
const { LOYERS } = NATURE_DU_CALCUL;

const STATE_INITIAL = {
  natureDuCalcul: LOYERS,
  paliers: [],
  periodicite: PERIODICITE_PAR_DEFAUT,
};

const { convertir } = PERIODICITES;

export default function reducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CALCULER_EN_LINEAIRE:
      return { ...state, paliers: [{ duree: action.nombreEcheances }] };

    case CHANGER_LA_PERIODICITE:
      const dureeTotaleConvertie = convertir(
        selectNombreEcheances(state),
        state.periodicite
      ).en(action.periodicite);

      return {
        ...state,
        paliers: [{ duree: dureeTotaleConvertie }],
        periodicite: action.periodicite,
      };

    case CALCULER_EN_MULTI_PALIERS:
      return { ...state, mode: MULTI_PALIERS, paliers: action.paliers };

    case CHANGER_NATURE_DU_CALCUL:
      return { ...state, natureDuCalcul: action.nouvelleNature };

    case CHARGER_PLAN_DE_FINANCEMENT:
      return {
        ...state,
        paliers:
          action.planDeFinancement === PLAN_DE_FINANCEMENT_VIDE
            ? state.paliers
            : action.planDeFinancement.paliers,
      };

    default:
      return state;
  }
}

export function selectDureeEnMois(state) {
  return nombreDeMois(selectNombreEcheances(state), state.periodicite);
}

export function selectNombreEcheances(state) {
  return state.paliers.reduce((totale, p) => p.duree + totale, 0);
}

export function selectNatureDuCalcul(state) {
  return state.natureDuCalcul;
}

export function selectModeDeCalcul(state) {
  if (state.paliers.length === 0) return undefined;
  if (state.paliers.length === 1) return LINEAIRE;
  if (state.paliers.length > 1) return MULTI_PALIERS;
}

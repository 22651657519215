import { TEG } from "./TEG";
import { connect } from "react-redux";
import {
  selectTegGrille,
  selectTegManuel,
  tegBase100,
} from "../../domaine/simulation/tauxInteret/reducer";
import { calculateur } from "../../../../reduxStore/rootReducer";
import {
  donnerTegManuel,
  revenirAuTegGrille,
} from "../../domaine/simulation/tauxInteret/actions";
import {
  ORCHESTRER_PLAN_DE_FINANCEMENT,
  orchestrerLePlanDeFinancement,
} from "../../domaine/planDeFinancement/actions";
import { selectAnomalieDeTeg } from "../../domaine/anomalies/Anomalies";
import { selectDerogationDeTeg } from "../../domaine/simulation/derogations/reducer";
import {
  estEnMode,
  MODE_AFFICHAGE,
  uneOperationEstEnCours,
} from "../../../../Ui/reducer";
import { selectMarcheUtilisateur } from "../../domaine/simulation/profilUtilisateur/reducer";
import { selectPosteUtilisateur } from "../../../../identiteUtilisateur/reducer";

const { LIGHT } = MODE_AFFICHAGE;

const mapStateToProps = (state) => {
  const { simulation, anomalies } = calculateur(state);

  return {
    // On ne passe *pas* le TEG effectif (selectTegEffectif) ici.
    // Car le effectif sert à calculer et pour la France on ne prend jamais en compte le manuel.
    // Alors qu'à l'affichage on veut bel et bien montrer le manuel en priorité.
    // Au lieu de faire un sélecteur dédié, on passe les 2 et le composant fait un "??"
    tegGrille: tegBase100(selectTegGrille(simulation.tauxInteret)),
    tegManuel: tegBase100(selectTegManuel(simulation.tauxInteret)),
    marcheUtilisateur: selectMarcheUtilisateur(simulation.profilUtilisateur),
    anomalieDeTeg: selectAnomalieDeTeg(anomalies),
    derogationDeTeg: selectDerogationDeTeg(simulation.derogations),
    estEnModeLight: estEnMode(LIGHT, state.ui),
    retourGrilleEstPermis: !uneOperationEstEnCours(
      state.ui,
      ORCHESTRER_PLAN_DE_FINANCEMENT
    ),
    posteUtilisateur: selectPosteUtilisateur(state.identiteUtilisateur),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTegChange: (teg) => {
    dispatch(donnerTegManuel(teg / 100));
    dispatch(orchestrerLePlanDeFinancement());
  },
  onRevenirAGrille: () => {
    dispatch(revenirAuTegGrille());
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TEG);

import { URL_DU_CRM } from "./webApi";

export const UrlsCrm = {
  ft: idFt => ({
    ouvrir: encodeURI(
      `${URL_DU_CRM}/main.aspx?etn=itl_fichetransmission&pagetype=entityrecord&id={${idFt}}`
    ),
  }),

  projet: idProjet => ({
    ouvrir: encodeURI(
      `${URL_DU_CRM}/main.aspx?etn=itl_projet&pagetype=entityrecord&id={${idProjet}}`
    ),
  }),
};

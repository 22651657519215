import { call, put, select } from "redux-saga/effects";
import { donnerTegGrille } from "./actions";
import { selectMarcheUtilisateur } from "../profilUtilisateur/reducer";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import { selectDureeEnMois } from "../modeDeCalcul/reducer";
import { valeurDuMontantAFinancer } from "../montantAFinancer/montants";
import { Adapters } from "../../../../../reduxStore/adapters";
import {
  selectMarcheChoisi,
  selectMarqueItlChoisie,
} from "../projetDeTravail/reducer";
import { typeDeFinancementActif } from "../valeurResiduelle/selecteurs/generalites.selecteurs";

export function* recupererTegGrilleSaga({
  tegAdapter = Adapters.tegAdapter,
} = {}) {
  const { simulation } = yield select(calculateur);

  const aucuneRaisonDeDemanderTeg = rienAFinancer(simulation.montantAFinancer);
  if (aucuneRaisonDeDemanderTeg) return;

  const recherche = {
    marche: selectMarcheUtilisateur(simulation.profilUtilisateur),
    dureeEnMois: selectDureeEnMois(simulation.modeDeCalcul),
    montant: valeurDuMontantAFinancer(simulation.montantAFinancer).toObject(),
    marcheProjet: selectMarcheChoisi(simulation.projetDeTravail).id,
    marqueItl: selectMarqueItlChoisie(simulation.projetDeTravail).id,
    typeDeFinancement: typeDeFinancementActif(simulation.valeurResiduelle),
  };

  const teg = yield call(tegAdapter.getTeg, recherche);
  yield put(donnerTegGrille(teg));
}

function rienAFinancer(montantAFinancer) {
  return valeurDuMontantAFinancer(montantAFinancer).isZero();
}

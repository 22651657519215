import { all, call, put, select } from "redux-saga/effects";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import { importService as importServiceDomaine } from "../importService";
import { erreursEmpechantImportDeFt } from "../erreursEmpechantImportDeFt";
import { i18n } from "../../../../../i18n/i18n";
import { getMarcheUtilisateur } from "../../simulation/profilUtilisateur/sagas";
import { orchestrerLePlanDeFinancementSaga } from "../../planDeFinancement/sagas";
import { Adapters } from "../../../../../reduxStore/adapters";
import { importerLesTauxDeVrSaga } from "./import.vr.sagas";
import { importerAssuranceFranceSaga } from "./import.assurance.france.sagas";
import { yieldCatalogueProduits } from "../../simulation/catalogueProduits/sagas";

export function* importerFtSaga(
  idFt,
  timestampImport,
  {
    ftPourImportAdapter = Adapters.ftPourImportAdapter,
    importService = importServiceDomaine,
  } = {}
) {
  const catalogueProduits = yield* yieldCatalogueProduits();

  const [
    depenses,
    depensesAssurees,
    commissions,
    reventes,
    loyers,
    informationsFinancieres,
    derogationsDeVr,
    derogationsDeTeg,
    derogationsAssuranceEspagne,
    assurance,
    maintenance,
  ] = yield all([
    call(ftPourImportAdapter.getDepenses, idFt, catalogueProduits),
    call(ftPourImportAdapter.getDepensesAssurees, idFt, catalogueProduits),
    call(ftPourImportAdapter.getCommissions, idFt),
    call(ftPourImportAdapter.getReventes, idFt),
    call(ftPourImportAdapter.getLoyers, idFt),
    call(ftPourImportAdapter.getInfosFinancieres, idFt),
    call(ftPourImportAdapter.getDerogationsDeVr, idFt, catalogueProduits),
    call(ftPourImportAdapter.getDerogationsDeTeg, idFt),
    call(ftPourImportAdapter.getDerogationsAssuranceEspagne, idFt),
    call(ftPourImportAdapter.getAssuranceDeFt, idFt),
    call(ftPourImportAdapter.getMaintenance, idFt),
  ]);

  const ft = {
    depenses,
    depensesAssurees,
    commissions,
    reventes,
    loyers,
    informationsFinancieres,
    derogationsDeVr,
    derogationsDeTeg,
    derogationsAssuranceEspagne,
    assurance,
    maintenance,
  };

  const marcheUtilisateur = yield* getMarcheUtilisateur();
  const intl = i18n.getIntl(marcheUtilisateur);

  const erreurs = yield call(erreursEmpechantImportDeFt, ft, { intl });
  if (erreurs.length > 0) throw new Error(erreurs);

  yield* importerLeSansEffetsDeBords(ft, timestampImport, { importService });
  yield call(importerLesTauxDeVrSaga, ft);
  yield call(importerAssuranceFranceSaga, ft.depensesAssurees);

  yield call(orchestrerLePlanDeFinancementSaga);
}

function* importerLeSansEffetsDeBords(ft, timestampImport, { importService }) {
  const { simulation } = yield select(calculateur);
  const actionsImport = importService.convertirFtEnActions(ft, {
    timestampImport,
    catalogueProduits: simulation.catalogueProduits,
  });

  for (let action of actionsImport) yield put(action);
}

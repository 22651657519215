import { useMarcheUtilisateur } from "../../../../identiteUtilisateur/hooks";
import React, { Fragment } from "react";
import { MARCHES } from "../../../../identiteUtilisateur/reducer";

const { FRANCE } = MARCHES;

export const FranceSeulement = ({ children }) => {
  const marche = useMarcheUtilisateur();
  const featureDisponible = marche === FRANCE;
  return featureDisponible ? children : <Fragment />;
};

import { FormattedMessage, useIntl } from "react-intl";
import { enEuros } from "../../../../Kit/formatage";
import PropTypes from "prop-types";
import React from "react";
import { ht } from "../../../../i18n/raccourcis";

export function DerogationsLiees(props) {
  const intl = useIntl();
  return (
    <div className="pure-u-1">
      <h4>
        <FormattedMessage id="derogation_de_marge.derogations_liees.titre" />
      </h4>
      <ul>{props.derogationsLiees.map((d) => UneDerogation(d, intl))}</ul>
    </div>
  );
}

DerogationsLiees.propTypes = {
  derogationsLiees: PropTypes.array.isRequired,
};

function UneDerogation(derogation, intl) {
  const {
    actualisationEstimee,
    nom,
    dureeEnMois,
    depenses,
  } = derogation.ficheDeTransmission;

  return (
    <li key={derogation.idDerogation}>
      {[
        nom,
        enEuros(actualisationEstimee, ht(intl)),
        `${dureeEnMois} mois`,
      ].join(" - ")}
      <ul>
        {depenses.map((depense, index) => (
          <li key={index}>{depense}</li>
        ))}
      </ul>
    </li>
  );
}

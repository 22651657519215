import { isNullMoneyObject, Money, Zero } from "../../../../../Money/money";
import {
  listeDesCommissions,
  listeDesProduitsAchetes,
  listeDesRachatsDeContrats,
  listeDesRachatsDeMateriels,
  PAS_DE_TAUX_DE_MARGE,
} from "./reducer";

const montantAFinancerAvecMarge = (state, marge) =>
  montantAchatDesProduitsAchetes(state)
    .add(encoursDesRemisesAuContrat(state))
    .add(montantDesCommissions(state))
    .add(montantDeLaNonValeur(state))
    .add(marge)
    .subtract(montantDeLaMargeSurRevente(state));

export const valeurDuMontantAFinancer = (state) =>
  montantAFinancerAvecMarge(state, montantDeLaMargeEffective(state));

export const valeurDuMontantAFinancerAvecMargeManuelle = (state) =>
  montantAFinancerAvecMarge(state, montantDeLaMargeManuelle(state));

export const valeurDuMontantAFinancerAvecMargeZero = (state) =>
  montantAFinancerAvecMarge(state, Zero);

export const montantAchatDesProduitsAchetes = (state) =>
  listeDesProduitsAchetes(state).reduce(
    (total, produit) =>
      total.add(Money(produit.montantUnitaire).multiply(produit.quantite)),
    Zero
  );

const encoursDesRemisesAuContrat = (state) =>
  rachatsDeContrats(state)
    .sansReventes()
    .reduce((total, rachat) => total.add(Money(rachat.montantEncours)), Zero);

export const montantDeLaMargeSurRevente = (state) => {
  const reventesMoinsRachats = montantDesReventesMoinsRachats(state);
  const rachatsBeneficiaires = reventesMoinsRachats.greaterThan(Zero);
  return rachatsBeneficiaires ? reventesMoinsRachats : Zero;
};

const montantDesReventesMoinsRachats = (state) => {
  const materiels = rachatsDeMateriels(state)
    .avecReventes()
    .map((r) => ({ rachat: r.montantDuRachat, revente: r.montantDeLaRevente }));

  const contrats = rachatsDeContrats(state)
    .avecReventes()
    .map((r) => ({ rachat: r.montantEncours, revente: r.montantDeLaRevente }));

  const rachats = [...materiels, ...contrats];

  return rachats.reduce(
    (total, rachat) =>
      total.add(Money(rachat.revente).subtract(Money(rachat.rachat))),
    Zero
  );
};

export const tauxDeMargeManuel = (state) => state.margeDeBase.tauxManuel;

export const tauxDeMargeEffectif = (state) =>
  state.margeDeBase.tauxRecalculePourTeg ?? state.margeDeBase.tauxManuel;

export const tauxDeMargeRecalculePourTeg = (state) =>
  state.margeDeBase.tauxRecalculePourTeg;

export const montantDeLaMargeEffective = (state) => {
  const taux = tauxDeMargeEffectif(state);
  if (taux === PAS_DE_TAUX_DE_MARGE) return Zero;

  return montantDesDepenses(state).multiply(taux);
};

const montantDeLaMargeManuelle = (state) => {
  const taux = tauxDeMargeManuel(state);
  if (taux === PAS_DE_TAUX_DE_MARGE) return Zero;

  return montantDesDepenses(state).multiply(taux);
};

export const selectInfosDeMarge = (state) => ({
  tauxManuel: tauxDeMargeManuel(state),
  montantManuel: montantDeLaMargeManuelle(state).toObject(),
  tauxEffectif: tauxDeMargeEffectif(state),
  montantEffectif: montantDeLaMargeEffective(state).toObject(),
});

export const tauxDeNonValeur = (state) => {
  const depenses = montantDesDepenses(state);
  const aucuneDepense = depenses.equalsTo(Zero);
  if (aucuneDepense) return 0;

  return montantDeLaNonValeur(state)
    .convertPrecision(4)
    .divide(depenses.toUnit())
    .toUnit();
};

export function montantDeLaNonValeur(state) {
  const reventesMoinsRachats = montantDesReventesMoinsRachats(state);
  const rachatsDeficitaires = reventesMoinsRachats.lessThan(Zero);
  return rachatsDeficitaires ? reventesMoinsRachats.multiply(-1) : Zero;
}

export const montantDesDepenses = (state) =>
  montantAchatDesProduitsAchetes(state)
    .add(encoursDesRachatsDeContrats(state))
    .add(encoursDesRachatsDeMateriels(state))
    .add(montantDesCommissions(state));

const encoursDesRachatsDeContrats = (state) =>
  rachatsDeContrats(state)
    .tous()
    .reduce((total, rachat) => total.add(Money(rachat.montantEncours)), Zero);

const encoursDesRachatsDeMateriels = (state) =>
  rachatsDeMateriels(state)
    .tous()
    .reduce((total, rachat) => Money(rachat.montantDuRachat).add(total), Zero);

const montantDesCommissions = (state) =>
  listeDesCommissions(state).reduce(
    (total, commission) => total.add(Money(commission.montant)),
    Zero
  );

export const montantInvestissement = (state) =>
  montantDesDepenses(state).add(montantDeLaNonValeur(state));

function rachatsDeMateriels(state) {
  return {
    tous: () => listeDesRachatsDeMateriels(state),
    avecReventes: () =>
      listeDesRachatsDeMateriels(state).filter(
        (rachat) =>
          !isNullMoneyObject(rachat.montantDuRachat) &&
          !isNullMoneyObject(rachat.montantDeLaRevente)
      ),
  };
}

function rachatsDeContrats(state) {
  return {
    tous: () => listeDesRachatsDeContrats(state),
    avecReventes: () =>
      listeDesRachatsDeContrats(state).filter(
        (r) =>
          !isNullMoneyObject(r.montantEncours) &&
          !isNullMoneyObject(r.montantDeLaRevente) &&
          !Money(r.montantDeLaRevente).isZero()
      ),
    sansReventes: () =>
      listeDesRachatsDeContrats(state).filter(
        (r) =>
          !isNullMoneyObject(r.montantEncours) &&
          (isNullMoneyObject(r.montantDeLaRevente) ||
            Money(r.montantDeLaRevente).isZero())
      ),
  };
}

export const montantAchatDesProduitsNeufs = (state) =>
  listeDesProduitsAchetes(state)
    .filter((p) => !p.estOccasion)
    .reduce(
      (total, produit) =>
        total.add(Money(produit.montantUnitaire).multiply(produit.quantite)),
      Zero
    );

import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const Aide = ({ id, children, className = "", style, place = "right" }) => (
  <div style={style} className={className}>
    <span
      data-tip
      data-for={id}
      style={{
        fontWeight: "bold",
        padding: "0 5px",
        backgroundColor: "rgba(51, 122, 183, 0.5)",
        borderRadius: "50%",
        color: "white",
        cursor: "default",
      }}
    >
      ?
    </span>

    <ReactTooltip type="info" effect="solid" id={id} place={place}>
      {children}
    </ReactTooltip>
  </div>
);

Aide.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
  place: PropTypes.oneOf(["top", "right", "bottom", "left"]),
};

export default Aide;

import { montantArrondi } from "../../../../../../../Kit/formatage";
import { CODES_GROUPES_OPTIONS } from "../../../../../../../Connecteurs/Crm/codesGroupesOptions";

const { typesDeRachats, typesDeMateriels } = CODES_GROUPES_OPTIONS;

export function mapperRachatDeMateriel(rachat) {
  return {
    depense: {
      itl_isoccasion: true,
      itl_annee: rachat.annee,
      itl_quantite: 1,
      itl_montantht: montantArrondi(rachat.montantDuRachat, 2),
      itl_montanttotalht: montantArrondi(rachat.montantDuRachat, 2),
      itl_name: rachat.description,
      itl_typematerielcode: typesDeMateriels.rachat,
      itl_type_depense_force: typesDeRachats.achatReventeMateriel,
    },
    revente: {
      itl_montantht: montantArrondi(rachat.montantDeLaRevente, 2),
      itl_name: rachat.description,
    },
  };
}

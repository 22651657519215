import { CHARGER_MARQUES_ITL_SUCCES } from "./actions";

const STATE_INITIAL = {
  marquesItlDisponibles: [],
};

export function modeLightReducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CHARGER_MARQUES_ITL_SUCCES:
      return {
        ...state,
        marquesItlDisponibles: action.marquesItl,
      };

    default:
      return state;
  }
}

export const selectMarquesItl = (state) => state.marquesItlDisponibles;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, useIntl } from "react-intl";
import "./ListeDesDerogations.mobile.css";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import BadgeErreur from "../../../../Kit/Badges/Erreur/BadgeErreur";
import { enEuros } from "../../../../Kit/formatage";
import { STATUT_DEROGATION } from "../../domaine/reducer";
import { Tab, Tabs } from "../../../../Kit/Tabs/Tabs";
import {
  derogationEstVieille,
  getLabelStatut,
  jour,
  msg,
  titres,
} from "./Common";

const { A_DECIDER, ACCEPTEE } = STATUT_DEROGATION;

class ListeDesDerogationsMobile extends Component {
  componentDidMount() {
    this.props.initialisation();
  }

  render() {
    const {
      isLoading,
      demandesAssigneesAuConnecte,
      demandesAssigneesEquipe,
      demandesStatuees,
      onClickVoirDetailsDemande,
      erreurs,
      utilisateurEstBroker,
      intl,
    } = this.props;

    demandesAssigneesAuConnecte.sort(
      (a, b) => a.dateDemande.localeCompare(b.dateDemande) // Date ASC
    );

    demandesStatuees.sort((a, b) =>
      // Date DESC
      b.dateDeDecision ? b.dateDecision.localeCompare(a.dateDecision) : 1
    );

    return (
      <div className="derogations-mobile">
        {erreurs.length > 0 ? (
          <BadgeErreur messages={erreurs} />
        ) : (
          <div>
            {isLoading ? (
              <BarreDeChargement size="S" />
            ) : (
              <Tabs>
                <Tab
                  label={titres(intl).enAttente(demandesAssigneesAuConnecte)}
                >
                  {demandesAssigneesAuConnecte.map((d) => (
                    <UneDerogation
                      key={d.id}
                      derogation={d}
                      utilisateurEstBroker={utilisateurEstBroker}
                      onClick={() => onClickVoirDetailsDemande(d.id)}
                    />
                  ))}
                </Tab>

                {demandesAssigneesEquipe.length > 0 && (
                  <Tab
                    label={titres(intl).assigneesEquipe(
                      demandesAssigneesEquipe
                    )}
                  >
                    {demandesAssigneesEquipe.map((d) => (
                      <UneDerogation
                        key={d.id}
                        derogation={d}
                        utilisateurEstBroker={utilisateurEstBroker}
                        onClick={() => onClickVoirDetailsDemande(d.id)}
                      />
                    ))}
                  </Tab>
                )}

                <Tab label={titres(intl).statuees(demandesStatuees)}>
                  {demandesStatuees.map((d) => (
                    <UneDerogation
                      key={d.id}
                      derogation={d}
                      onClick={() => onClickVoirDetailsDemande(d.id)}
                    />
                  ))}
                </Tab>
              </Tabs>
            )}
          </div>
        )}
      </div>
    );
  }
}

function UneDerogation({ derogation, onClick, utilisateurEstBroker }) {
  const intl = useIntl();
  const estDecidee = () => derogation.statut !== A_DECIDER;
  return (
    <div className="une-derogation">
      <p className="text-bold">{derogation.nom}</p>
      <ul>
        <li>
          <span
            className={
              !estDecidee() && derogationEstVieille(derogation.dateDemande)
                ? "vieille-derogation"
                : ""
            }
          >
            <b>{msg("date_de_demande", intl)} : </b>
            {!estDecidee() && derogationEstVieille(derogation.dateDemande) ? (
              <>⚠️ </>
            ) : (
              ""
            )}
            {jour(derogation.dateDemande)}
          </span>
        </li>
        {estDecidee() && (
          <li>
            <b>{msg("date_de_decision", intl)} : </b>
            {jour(derogation.dateDecision)}
          </li>
        )}
        <li>
          <b>{msg("auteur", intl)} : </b>
          {derogation.auteur.nom}
        </li>
        <li>
          <b>{msg("ft", intl)} : </b>
          {derogation.ficheDeTransmission.nom}
        </li>
        <li>
          <b>{msg("valeur_demandee", intl)} : </b>
          {derogation.valeurDemandee}
        </li>
        {utilisateurEstBroker === false && (
          <li>
            <b>{msg("actualisation_estimee", intl)} : </b>
            {enEuros(derogation.ficheDeTransmission.actualisationEstimee)}
          </li>
        )}
        <li>
          <b>{msg("motivation", intl)}</b>
          {derogation.motivation}
        </li>
      </ul>

      <div className="actions">
        {estDecidee() && (
          <span
            className={`tag ${
              derogation.statut === ACCEPTEE ? "button-success" : "button-error"
            }`}
          >
            {getLabelStatut(intl)[derogation.statut]}
          </span>
        )}
        <button
          className="pure-button pure-button-primary button-large"
          onClick={onClick}
        >
          {msg("voir_details", intl)}
        </button>
      </div>
    </div>
  );
}

ListeDesDerogationsMobile.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initialisation: PropTypes.func.isRequired,
  demandesAssigneesAuConnecte: PropTypes.array.isRequired,
  demandesAssigneesEquipe: PropTypes.array.isRequired,
  demandesStatuees: PropTypes.array.isRequired,
  erreurs: PropTypes.array.isRequired,
  onClickVoirDetailsDemande: PropTypes.func.isRequired,
};

export default injectIntl(ListeDesDerogationsMobile);

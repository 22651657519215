import React from "react";
import PropTypes from "prop-types";

const DatePicker = ({ value, onChange, placeholder, style, className }) => (
  <input
    type="date"
    value={value}
    onChange={(event) => onChange(event.target.value)}
    placeholder={placeholder}
    style={style}
    className={className}
  />
);

DatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

DatePicker.defaultProps = {
  style: {},
  className: "",
};

export default DatePicker;

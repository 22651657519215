import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  CHARGER_INSTANTANES,
  chargerInstantanes_succes,
  PRENDRE_INSTANTANE,
  SUPPRIMER_INSTANTANE,
} from "./actions";
import { lesInstantanesCalculateur } from "../../rootReducer";
import { localStorageService } from "../../../utils/localStorageService";

export const CLE_DES_INSTANTANES_CALCULATEUR =
  "calculateur/instantanés/snapshot";

export function* persisterInstantanesSaga({
  storageService = localStorageService,
} = {}) {
  const instantanes = yield select(lesInstantanesCalculateur);
  yield call(
    storageService.persister,
    CLE_DES_INSTANTANES_CALCULATEUR,
    instantanes
  );
}

export function* chargerInstantanesSaga({
  storageService = localStorageService,
} = {}) {
  const snapshotDansStorage = yield call(
    storageService.charger,
    CLE_DES_INSTANTANES_CALCULATEUR,
    { parId: {} }
  );

  const instantanes = Object.entries(
    snapshotDansStorage.parId
  ).map(([id, instantane]) => ({ id, ...instantane }));

  yield put(chargerInstantanes_succes(instantanes));
}

export default function* sagasDesInstantanes() {
  yield all([
    takeEvery(PRENDRE_INSTANTANE, persisterInstantanesSaga),
    takeEvery(SUPPRIMER_INSTANTANE, persisterInstantanesSaga),
    takeEvery(CHARGER_INSTANTANES, chargerInstantanesSaga),
  ]);
}

import { httpServiceCrm } from "../../../../Connecteurs/Crm/httpServiceCrm";
import { formatyyyyMMdd } from "../../../../utils/dateUtils";
import { CODES_GROUPES_OPTIONS } from "../../../../Connecteurs/Crm/codesGroupesOptions";

const { statutDeDerogation } = CODES_GROUPES_OPTIONS;

export const decisionDeDerogationAdapterCrm = {
  async prendreDecision(
    idDerogation,
    decision,
    { http = httpServiceCrm } = {}
  ) {
    await http.patch(`itl_derogations(${idDerogation})`, {
      itl_statutderogation: statutDeDerogation.get(decision.statut),
      itl_datedecision: formatyyyyMMdd(decision.dateDecision),
      itl_commentairesdecideur: decision.commentaireDecideur,
      "itl_derogateur@odata.bind": `/itl_derogateurs(${
        decision.derogateur.id
      })`,
    });
  },
};

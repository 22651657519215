import {
  httpK3000,
  montantK3000,
} from "../../../../../../Connecteurs/K3000/httpK3000";
import { $obj, Money } from "../../../../../../Money/money";
import { PlanDeFinancement } from "../../PlanDeFinancement/PlanDeFinancement";
import {
  LABELS_PERIODICITE_K3000,
  LABELS_TERME_K3000,
} from "../../../../../../Connecteurs/K3000/labelsK3000";
import { nombreEcheances } from "../../paliers";
import { fraisDeReglementAdapterK3000 } from "./fraisDeReglement.adapter.k3000";

export const planDeFinancementLoyersAdapterK3000 = {
  async calculerPlanLineaire(
    parametres,
    {
      http = httpK3000,
      fraisDeReglementAdapter = fraisDeReglementAdapterK3000,
    } = {}
  ) {
    const { montant } = await http.post("/api/v1/financier/loyer", {
      typeDePeriodicite: LABELS_PERIODICITE_K3000[parametres.periodicite],
      montantAFinancer: montantK3000(parametres.montantAFinancer),
      montantValeurResiduelle: montantK3000(parametres.vr.montant),
      terme: LABELS_TERME_K3000[parametres.terme],
      nombreDEcheances: nombreEcheances(parametres.paliers),
      tegAnnuel: parametres.teg.toString(),
    });
    const loyer = $obj(montant);
    const paliers = parametres.paliers.map(p => ({ ...p, loyer }));

    const fraisDeReglement = await calculerFraisDeReglement(
      paliers.map(p => p.loyer),
      parametres,
      { fraisDeReglementAdapter }
    );

    return PlanDeFinancement(paliers, { ...parametres, fraisDeReglement });
  },

  async calculerPlanMultiPaliers(
    parametres,
    {
      http = httpK3000,
      fraisDeReglementAdapter = fraisDeReglementAdapterK3000,
    } = {}
  ) {
    const premierPalier = parametres.paliers[0];
    const dernierPalier = [...parametres.paliers].pop();

    const premierLoyerInconnu = premierPalier.loyer === undefined;
    const dernierLoyerInconnu = dernierPalier.loyer === undefined;
    const tousPaliersConnus = !premierLoyerInconnu && !dernierLoyerInconnu;

    let paliersComplets = null;
    if (dernierLoyerInconnu || tousPaliersConnus)
      paliersComplets = await calculerLeDernierLoyer(parametres, { http });
    else if (premierLoyerInconnu)
      paliersComplets = await calculerLePremierLoyer(parametres, { http });

    const fraisDeReglement = await calculerFraisDeReglement(
      paliersComplets.map(p => p.loyer),
      parametres,
      { fraisDeReglementAdapter }
    );

    return PlanDeFinancement(paliersComplets, {
      ...parametres,
      fraisDeReglement,
    });
  },
};

async function calculerFraisDeReglement(
  loyers,
  { delaiDeReglement, modeDeReglement, teg, tva },
  { fraisDeReglementAdapter }
) {
  return await fraisDeReglementAdapter.calculerFraisDeReglement({
    loyers,
    tva: tva.tauxApplique,
    teg,
    modeDeReglement,
    delaiDeReglement,
  });
}

async function calculerLeDernierLoyer(parametres, { http }) {
  const dernierPalier = [...parametres.paliers].pop();
  const sansDernierPalier = parametres.paliers.slice(0, -1);

  const paliersCompletsK3000 = await http.post(
    "/api/v1/financier/multipalier/dernier-palier",
    parametresDuCalculDePaliers(parametres, dernierPalier, sansDernierPalier)
  );

  return paliersCompletsK3000.map((p, i) => ({
    ...parametres.paliers[i], // On préserve les éventuels champs qui viennent des paramètre, comme {avecMaintenance}.
    duree: p.nombreDEcheances,
    loyer: $obj(p.montant),
  }));
}

async function calculerLePremierLoyer(parametres, { http }) {
  const sansPremierPalier = parametres.paliers.slice(1);
  const palierIncomplet = parametres.paliers[0];

  const paliersCompletsK3000 = await http.post(
    "/api/v1/financier/multipalier/premier-palier",
    parametresDuCalculDePaliers(parametres, palierIncomplet, sansPremierPalier)
  );

  return paliersCompletsK3000.map((p, i) => ({
    ...parametres.paliers[i], // On préserve les éventuels champs qui viennent des paramètre, comme {avecMaintenance}.
    duree: p.nombreDEcheances,
    loyer: $obj(p.montant),
  }));
}

function parametresDuCalculDePaliers(
  parametres,
  palierSansLoyer,
  paliersComplet
) {
  return {
    typeDePeriodicite: LABELS_PERIODICITE_K3000[parametres.periodicite],
    montantAFinancer: Money(parametres.montantAFinancer)
      .toUnit()
      .toString(),
    montantValeurResiduelle: Money(parametres.vr.montant)
      .toUnit()
      .toString(),
    terme: LABELS_TERME_K3000[parametres.terme],
    nombreDEcheances: palierSansLoyer.duree,
    tegAnnuel: parametres.teg.toString(),
    chaineDeLoyers: paliersComplet.map(p => ({
      nombreDEcheances: p.duree,
      montant: Money(p.loyer)
        .toUnit()
        .toString(),
    })),
  };
}

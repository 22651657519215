import { call, select } from "redux-saga/effects";
import { calculateur } from "../../../../../../../reduxStore/rootReducer";
import {
  auMoinsUneAssurance,
  montantPrimeAssuranceFranceParLoyer,
  PAS_DE_PRIME_ASSURANCE,
  selectPrimeAssurance,
} from "../../../../simulation/assurance/france/reducer";
import { assuranceFrDesactivee } from "../../../../simulation/assurance/france/sagas";
import { Adapters } from "../../../../../../../reduxStore/adapters";

export function* assurerFtFrance(
  idFt,
  { assuranceFranceAdapter = Adapters.creerAssuranceFranceAdapter } = {}
) {
  if (yield* assuranceFrDesactivee()) return;

  const {
    simulation: { assurance },
  } = yield select(calculateur);

  const aucuneAssurance = auMoinsUneAssurance(assurance.france) === false;
  if (aucuneAssurance) return;

  yield call(
    assuranceFranceAdapter.ajouterAssuranceSurFt,
    idFt,
    assurance.france
  );
}

export function* assurerDepenseFranceSaga(
  materiel,
  depenseCrm,
  { assuranceFranceAdapter = Adapters.creerAssuranceFranceAdapter } = {}
) {
  if (yield* assuranceFrDesactivee()) return;

  const { simulation } = yield select(calculateur);
  const { assurance } = simulation;

  const primeAssurance = selectPrimeAssurance(assurance.france, materiel);
  const materielEstAssure = primeAssurance !== PAS_DE_PRIME_ASSURANCE;
  if (!materielEstAssure) return;

  yield call(assuranceFranceAdapter.ajouterAssuranceSurDepense, {
    materiel,
    depenseCrm,
    primeAssurance,
  });
}

export function* assurerLoyerFranceSaga(
  loyerCrm,
  palierForce,
  { assuranceFranceAdapter = Adapters.creerAssuranceFranceAdapter } = {}
) {
  if (yield* assuranceFrDesactivee()) return;

  const { simulation } = yield select(calculateur);
  const { assurance } = simulation;

  const primeParLoyer = montantPrimeAssuranceFranceParLoyer(assurance.france);
  yield call(
    assuranceFranceAdapter.ajouterAssuranceSurLoyer,
    loyerCrm,
    palierForce,
    primeParLoyer.toObject()
  );
}

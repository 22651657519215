import { listeDesRachatsDeContrats } from "../../simulation/montantAFinancer/reducer";
import { AnomalieRachatDeContratIncomplet } from "../Anomalies";
import { argentVide } from "../../../../../Money/money";

export function verifierRachatsDeContrats(simulation) {
  return listeDesRachatsDeContrats(simulation.montantAFinancer)
    .filter(rachatIncomplet)
    .map(() => AnomalieRachatDeContratIncomplet());
}

function rachatIncomplet(r) {
  const sansNumeroContrat = estVide(r.numeroContrat);
  const sansDateEncours = estVide(r.dateEncours);
  const sansMaterielRachete =
    estVide(r.materielRachete.idFamille) || estVide(r.materielRachete.idMarque);
  const sansMontantEncours = argentVide(r.montantEncours);

  return (
    sansNumeroContrat ||
    sansDateEncours ||
    sansMaterielRachete ||
    sansMontantEncours
  );
}

function estVide(valeur) {
  return valeur === "" || valeur === null || valeur === undefined;
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { PERIODICITES } from "../../domaine/simulation/modeDeCalcul/periodicite/periodicite";

const { TRIMESTRIEL, MENSUEL, ANNUEL } = PERIODICITES;

class Periodicite extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  render() {
    const { value } = this.state;
    const { className = "" } = this.props;
    return (
      <div className={`flex-dir-col ${className}`}>
        <span className="text-bold">
          <FormattedMessage id="calculateur.periodicite.titre" />
        </span>
        {[MENSUEL, TRIMESTRIEL, ANNUEL].map((periodicite) => (
          <div key={periodicite} className="une-periodicite p-2-0">
            <input
              type="radio"
              name="periodicite"
              id={`periodicite-${periodicite}`}
              value={periodicite}
              checked={value === periodicite}
              onChange={(e) => {
                this.setState({ value: Number(e.target.value) });
                this.props.onChange(Number(e.target.value));
              }}
            />
            <label
              className="pointable ml-5"
              htmlFor={`periodicite-${periodicite}`}
            >
              <FormattedMessage id={CLES_PERIODICITE[periodicite]} />
            </label>
          </div>
        ))}
      </div>
    );
  }
}
Periodicite.propTypes = {
  value: PropTypes.oneOf([MENSUEL, TRIMESTRIEL, ANNUEL]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Periodicite;

const CLES_PERIODICITE = {
  [MENSUEL]: "calculateur.periodicite.choix.mensuel",
  [TRIMESTRIEL]: "calculateur.periodicite.choix.trimestriel",
  [ANNUEL]: "calculateur.periodicite.choix.annuel",
};

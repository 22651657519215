import { enEuros } from "../../../../../../Kit/formatage";
import { UrlsForce } from "../../../../../../routing/Urls";
import {
  selectMarcheUtilisateur,
  selectNomPrenomUtilisateur,
} from "../../../simulation/profilUtilisateur/reducer";
import { valeurDuMontantAFinancer } from "../../../simulation/montantAFinancer/montants";
import { templating } from "../../../../../../i18n/templating";
import { ACTION_SUR_DEROGATION_DE_MARGE_FR } from "./templates/ACTION_SUR_DEROGATION_DE_MARGE_fr";
import { ACTION_SUR_DEROGATION_DE_MARGE_ES } from "./templates/ACTION_SUR_DEROGATION_DE_MARGE_es";
import { INFO_DEROGATION_DE_MARGE_FR } from "./templates/INFO_DEROGATION_DE_MARGE_fr";
import { INFO_DEROGATION_DE_MARGE_ES } from "./templates/INFO_DEROGATION_DE_MARGE_es";
import { MARCHES } from "../../../../../../identiteUtilisateur/reducer";
import { ht } from "../../../../../../i18n/raccourcis";
import { i18n } from "../../../../../../i18n/i18n";
import { selectProjetDeTravail } from "../../../simulation/projetDeTravail/reducer";

const { FRANCE, ESPAGNE } = MARCHES;

export function mailPourActionSurDerogationDeMarge(
  ft,
  derogationDeMarge,
  calculateurDuState
) {
  const templateDuMarche = {
    [FRANCE]: () => ACTION_SUR_DEROGATION_DE_MARGE_FR,
    [ESPAGNE]: () => ACTION_SUR_DEROGATION_DE_MARGE_ES,
  };

  const { simulation, planDeFinancement } = calculateurDuState;
  const marche = selectMarcheUtilisateur(simulation.profilUtilisateur);
  const projet = selectProjetDeTravail(simulation.projetDeTravail);
  const intl = i18n.getIntl(marche);

  const { CORPS, SUJET } = templateDuMarche[marche]();

  const sujet = templating(SUJET, {
    demandeur: selectNomPrenomUtilisateur(simulation.profilUtilisateur),
  });

  const corps = templating(CORPS, {
    demandeur: selectNomPrenomUtilisateur(simulation.profilUtilisateur),
    projet: projet.etiquette,
    client: projet.client.nom,
    ft: ft.nom,
    montantAFinancer: enEuros(
      valeurDuMontantAFinancer(simulation.montantAFinancer).toObject(),
      ht(intl)
    ),
    vr: enEuros(planDeFinancement.montantTotalDeVr, ht(intl)),
    tauxDeMargeDemande: derogationDeMarge.itl_valeurdemandee,
    tauxDeMargeEnAutonomie: derogationDeMarge.itl_valeurorigine,
    motivation: derogationDeMarge.itl_commentairesdemandeur,
    urlOuvrirDerogation: UrlsForce.derogation(
      derogationDeMarge.itl_derogationid
    ).ouvrir,
  });

  return { sujet, corps };
}

export function mailInfoSurDerogationDeMarge(
  ft,
  derogationDeMarge,
  projetDeTravail,
  nomDerogateurDeMarge,
  calculateurDuState
) {
  const templateDuMarche = {
    [FRANCE]: INFO_DEROGATION_DE_MARGE_FR,
    [ESPAGNE]: INFO_DEROGATION_DE_MARGE_ES,
  };

  const { simulation, planDeFinancement } = calculateurDuState;
  const marche = selectMarcheUtilisateur(simulation.profilUtilisateur);
  const intl = i18n.getIntl(marche);

  const { CORPS, SUJET } = templateDuMarche[marche];

  const sujet = templating(SUJET, {
    demandeur: selectNomPrenomUtilisateur(simulation.profilUtilisateur),
  });

  const corps = templating(CORPS, {
    demandeur: selectNomPrenomUtilisateur(simulation.profilUtilisateur),
    projet: projetDeTravail.etiquette,
    client: projetDeTravail.client.nom,
    ft: ft.nom,
    derogateur: nomDerogateurDeMarge,
    montantAFinancer: enEuros(
      valeurDuMontantAFinancer(simulation.montantAFinancer).toObject(),
      ht(intl)
    ),
    vr: enEuros(planDeFinancement.montantTotalDeVr, ht(intl)),
    tauxDeMargeDemande: derogationDeMarge.itl_valeurdemandee,
    tauxDeMargeEnAutonomie: derogationDeMarge.itl_valeurorigine,
    motivation: derogationDeMarge.itl_commentairesdemandeur,
    urlOuvrirDerogation: UrlsForce.derogation(
      derogationDeMarge.itl_derogationid
    ).ouvrir,
  });

  return { sujet, corps };
}

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { erreurDeOperation, uneOperationEstEnCours } from "../../../Ui/reducer";
import {
  INITIALISER_CALCULATEUR,
  initialiserCalculateurDepuisFt,
  initialiserCalculateurVide,
} from "../domaine/actions";
import BarreDeChargement from "../../../Kit/Loaders/BarreDeChargement";
import Barriere from "../../../Kit/Erreur/Barriere";
import { chargerInstantanes } from "../../../reduxStore/enhancers/instantanes/actions";

function InitialiserCalculateur({
  chargementEnCours,
  children,
  erreursGlobales,
  idFtImport,
  initialisation,
}) {
  useEffect(() => {
    initialisation(idFtImport);
  }, [initialisation, idFtImport]);

  if (chargementEnCours)
    return (
      <div className="flex-1 mt-20 flex-ai-center">
        <BarreDeChargement size="L" />
      </div>
    );

  return <Barriere messages={erreursGlobales}>{children}</Barriere>;
}

const mapStateToProps = (state) => ({
  chargementEnCours: uneOperationEstEnCours(state.ui, [
    INITIALISER_CALCULATEUR,
  ]),
  erreursGlobales: erreurDeOperation(state.ui, [INITIALISER_CALCULATEUR]),
});

const mapDispatchToProps = (dispatch) => ({
  initialisation: (idFtImport) => {
    if (idFtImport)
      dispatch(
        initialiserCalculateurDepuisFt(idFtImport, new Date().getTime())
      );
    else dispatch(initialiserCalculateurVide());

    dispatch(chargerInstantanes());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InitialiserCalculateur);

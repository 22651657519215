import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import UneCommission from "./UneCommission";
import "./Commissionnement.css";
import "./Commissionnement-mobile.css";

const Commissionnement = ({
  commissions,
  onChangeMontant,
  onChangeDescription,
  onDelete,
  ajouterUneCommissionVide,
  ajoutEstDisabled,
  estEnModeLight,
}) => (
  <div className="commissionnement">
    <h3 className="mb-5">
      <FormattedMessage id="calculateur.commissionnement.titre" />
    </h3>
    <div className="les-commissions">
      {commissions.map((commission) => (
        <div key={commission.id}>
          <UneCommission
            commission={commission}
            onChangeMontant={onChangeMontant}
            onChangeDescription={onChangeDescription}
            onDelete={onDelete}
            estEnModeLight={estEnModeLight}
          />
        </div>
      ))}
      <div className="ajouter-une-commission">
        <button
          className="pure-button pure-button-primary"
          onClick={ajouterUneCommissionVide}
          disabled={ajoutEstDisabled}
        >
          <FormattedMessage id="calculateur.commissionnement.ajouter_une_commission" />
        </button>
      </div>
    </div>
  </div>
);

Commissionnement.propTypes = {
  commissions: PropTypes.array.isRequired,
  ajouterUneCommissionVide: PropTypes.func.isRequired,
  ajoutEstDisabled: PropTypes.bool.isRequired,
  onChangeMontant: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  className: PropTypes.string,
  estEnModeLight: PropTypes.bool.isRequired,
};

export default Commissionnement;

import Terme from "./Terme";
import { connect } from "react-redux";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { choisirLeTerme } from "../../domaine/simulation/terme/actions";
import { orchestrerLePlanDeFinancement } from "../../domaine/planDeFinancement/actions";
import { termesDisponibles } from "../../domaine/simulation/terme/terme";
import { selectMarcheUtilisateur } from "../../domaine/simulation/profilUtilisateur/reducer";

const mapStateToProps = (state) => ({
  choisi: calculateur(state).simulation.terme.choisi,
  disponibles: termesDisponibles(
    selectMarcheUtilisateur(calculateur(state).simulation.profilUtilisateur)
  ),
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (terme) => {
    dispatch(choisirLeTerme(Number(terme)));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Terme);

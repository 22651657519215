const CORPS = `Hola,<br />
Acabas de recibir una nueva solicitud de <b>derogación de la TAE</b>.<br />
<ul>
  <li><b>Vendedor : </b>{demandeur}</li>
  <li><b>Proyecto : </b>{projet}</li>
  <li><b>Cliente : </b>{client}</li>
  <li><b>FT : </b>{ft}</li>
  <li><b>Importe total de la operación : </b>{montantAFinancer}</li>
  <li><b>VR : </b>{vr}</li>
  <li><b>Margen : </b>{marge}</li>
  <li><b>TAE solicitada (%) : </b>{tegDemande}</li>
  <li><b>TAE : </b>{tegGrille}</li>
  <li><b>Explicación de la solicitud : </b>{motivation}</li>
</ul>
<br />
Para poder enviar tu decision, favor de pinchar en el enlace siguiente :
<a
  href="{urlOuvrirDerogation}"
  style="
    display: block;
    width: 300px;
    text-align: center;
    margin: 10px;
    color: white;
    text-decoration: none;
    background-color: #0078e7;
    padding: 10px;
    border-radius: 7px;
  "
>
  Detalle de la solicitud
</a>
`;

const SUJET = `[Ficha de transmisión] Nueva solicitud de excepción de la TAE - {demandeur}`;

export const NOTIFICATION_DEROGATION_DE_TEG_ES = { CORPS, SUJET };

import { PLAN_DE_FINANCEMENT_VIDE } from "./PlanDeFinancement/PlanDeFinancement";
import { CHARGER_PLAN_DE_FINANCEMENT } from "./actions";

const STATE_INITIAL = PLAN_DE_FINANCEMENT_VIDE;

export function planDeFinancementReducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CHARGER_PLAN_DE_FINANCEMENT:
      return action.planDeFinancement;

    default:
      return state;
  }
}

export const TYPES_INVALIDITES_DEROGATION_DE_VR = {
  TROP_FORTE_HAUSSE_DU_MONTANT_DE_MATERIEL:
    "TROP_FORTE_HAUSSE_DU_MONTANT_DE_MATERIEL",
  DUREE_DE_FINANCEMENT_SUPERIEURE_A_CELLE_DE_LA_DEROGATION:
    "DUREE_DE_FINANCEMENT_SUPERIEURE_A_CELLE_DE_LA_DEROGATION",
  TAUX_MANUEL_DEPASSE_LE_TAUX_ACCORDE: "TAUX_MANUEL_DEPASSE_LE_TAUX_ACCORDE",
  TYPE_DE_FINANCEMENT_MODIFIE: "TYPE_DE_FINANCEMENT_MODIFIE",
};

const {
  TROP_FORTE_HAUSSE_DU_MONTANT_DE_MATERIEL,
  DUREE_DE_FINANCEMENT_SUPERIEURE_A_CELLE_DE_LA_DEROGATION,
  TAUX_MANUEL_DEPASSE_LE_TAUX_ACCORDE,
  TYPE_DE_FINANCEMENT_MODIFIE,
} = TYPES_INVALIDITES_DEROGATION_DE_VR;

export function InvaliditeTropForteHausseDuProduit(
  montantActuelDuProduit,
  montantMaxAutorise
) {
  return {
    idType: TROP_FORTE_HAUSSE_DU_MONTANT_DE_MATERIEL,
    details: {
      montantActuel: montantActuelDuProduit.toObject(),
      montantLimite: montantMaxAutorise.toObject(),
    },
  };
}

export function InvaliditeDureeDeFinancementAugmente() {
  return {
    idType: DUREE_DE_FINANCEMENT_SUPERIEURE_A_CELLE_DE_LA_DEROGATION,
  };
}

export function InvaliditeTauxManuelDepasse(maximumAutorise) {
  return {
    idType: TAUX_MANUEL_DEPASSE_LE_TAUX_ACCORDE,
    details: { tauxMax: maximumAutorise },
  };
}

export function InvaliditeTypeDeFinancementModifie() {
  return { idType: TYPE_DE_FINANCEMENT_MODIFIE };
}

import Loading from "./Loading";
import { connect } from "react-redux";
import { selectProgressBar } from "../../../../Ui/reducer";
import { CREER_FICHE_DE_TRANSMISSION } from "../../domaine/creationDeFt/actions";

const mapStateToProps = (state) => ({
  progressBar: selectProgressBar(state.ui, CREER_FICHE_DE_TRANSMISSION),
});

export default connect(mapStateToProps)(Loading);

import React from "react";
import PropTypes from "prop-types";

export function IframeRapportFt({ urlRapport }) {
  return (
    <div className="flex-1 mt-20">
      <iframe
        className="details-derogation w100"
        style={style.iframe}
        title="rapport-fiche-de-transmission"
        src={urlRapport}
      />
    </div>
  );
}
IframeRapportFt.propTypes = {
  urlRapport: PropTypes.string.isRequired,
};

const style = {
  iframe: {
    height: "750px",
    width: "830px",
    border: "2px solid",
    margin: "auto",
  },
};

import { DEFINIR_CHAINE_DE_LOYERS_DE_REFERENCE } from "./actions";
import { nombreEcheances } from "../../planDeFinancement/paliers";
import { nombreDeMois } from "../modeDeCalcul/periodicite/periodicite";

const aucunPalier = [];
const STATE_INITIAL = {
  paliers: aucunPalier,
  periodicite: null,
};

export default function reducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case DEFINIR_CHAINE_DE_LOYERS_DE_REFERENCE:
      return {
        ...state,
        paliers: action.paliers,
        periodicite: action.periodicite,
      };

    default:
      return state;
  }
}

export function dureeDeReferenceEnMois(state) {
  return nombreDeMois(nombreEcheances(state.paliers), state.periodicite);
}

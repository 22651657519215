export function reducerQuiPeutReset(reducerWrappe, actionQuiReset = {}) {
  return function (state, action) {
    const doitReset = action.type === actionQuiReset.type;
    if (doitReset) {
      const stateInitialDuWrappe = reducerWrappe(undefined, {});
      return stateInitialDuWrappe;
    }

    return reducerWrappe(state, action);
  };
}

import React from "react";
import PropTypes from "prop-types";

const Desactivable = ({ desactiverChildren, children, className }) => (
  <div style={{ position: "relative" }} className={className}>
    {children}
    {desactiverChildren && masque}
  </div>
);

const masque = (
  <div
    style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,.13)",
    }}
  />
);

Desactivable.propTypes = {
  desactiverChildren: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Desactivable;

const CORPS = `Bonjour, <br />
Pour information (aucune action n'est requise de votre part).<br/>
Une nouvelle demande de <b>Dérogation de Marge</b> a été soumise.<br />
<ul>
  <li><b>Demandeur : </b>{demandeur}</li>
  <li><b>Projet : </b>{projet}</li>
  <li><b>Client : </b>{client}</li>
  <li><b>FT : </b>{ft}</li>
  <li><b>Dérogateur : </b>{derogateur}</li>
  <li><b>Montant à financer : </b>{montantAFinancer}</li>
  <li><b>VR : </b>{vr}</li>
  <li><b>Taux de marge demandé : </b>{tauxDeMargeDemande}</li>
  <li><b>Taux de marge en autonomie : </b>{tauxDeMargeEnAutonomie}</li>
  <li><b>Motivation de la demande : </b>{motivation}</li>
</ul>`;

const SUJET =
  "[INFO - Fiche de Transmission] Nouvelle demande de dérogation de Marge - {demandeur}";

export const INFO_DEROGATION_DE_MARGE_FR = { CORPS, SUJET };

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Alert from "../../../../../Kit/Alert/Alert";
import { FormattedMessage } from "react-intl";
import { UrlsCrm } from "../../../../../Connecteurs/Crm/Urls";

export function CreationReussie({ messages, onCloseMessage }) {
  return (
    <Fragment>
      {messages.map(({ type, idEntite }, index) => (
        <div className="flex-ai-center mb-5" key={index}>
          <div className="mha mt-10">
            <Alert onClose={() => onCloseMessage(type, idEntite)}>
              <FormattedMessage id="calculateur.ft_creee_avec_succes" />{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={UrlsCrm.ft(idEntite).ouvrir}
              >
                <FormattedMessage id="calculateur.ft_creee_avec_succes.lien" />
              </a>
            </Alert>
          </div>
        </div>
      ))}
    </Fragment>
  );
}

CreationReussie.propTypes = {
  messages: PropTypes.array.isRequired,
  onCloseMessage: PropTypes.func.isRequired,
};

import {
  CHARGER_MARQUES_ITL,
  chargerMarquesItl_succes,
  INITIALISER_MODE_LIGHT,
} from "./actions";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { Adapters } from "../../../reduxStore/adapters";
import { chargerCatalogueProduitsCompletSaga } from "../../Calculateur/domaine/simulation/catalogueProduits/sagas";
import { trySaga } from "../../../utils/sagasUtils";
import { calculerEnLineaire } from "../../Calculateur/domaine/simulation/modeDeCalcul/actions";
import {
  identiteUtilisateur,
  modeLight,
} from "../../../reduxStore/rootReducer";
import { selectMarcheUtilisateur } from "../../../identiteUtilisateur/reducer";
import { chargerProfilUtilisateur_succes } from "../../Calculateur/domaine/simulation/profilUtilisateur/actions";
import { definirModeAffichage } from "../../../Ui/actions";
import { MODE_AFFICHAGE } from "../../../Ui/reducer";
import { chargerMargeCibleSaga } from "../../Calculateur/domaine/simulation/margeCible/sagas";
import { chargerProjetDeTravail_succes } from "../../Calculateur/domaine/simulation/projetDeTravail/actions";
import { recupererDerogateurDeMarge_succes } from "../../Calculateur/domaine/simulation/derogateurs/actions";
import { viderCalculateur } from "../../Calculateur/domaine/actions";
import { selectMarquesItl } from "./reducer";
import {
  DEROGATEUR_DE_MARGE_FICTIF_MODE_LIGHT,
  PROJET_DE_TRAVAIL_FICTIF_MODE_LIGHT,
} from "./contenuFictifModeLight";
import { chargerPrimeMinimaleAssuranceEspagneSaga } from "../../Calculateur/domaine/simulation/assurance/espagne/sagas";

const { LIGHT } = MODE_AFFICHAGE;

export function* chargerMarquesItlSaga({
  marquesItlAdapter = Adapters.marquesItlAdapter,
} = {}) {
  const saga = function*() {
    const state = yield select(modeLight);
    const marquesDejaChargees = selectMarquesItl(state).length > 0;
    if (marquesDejaChargees) return;

    const identite = yield select(identiteUtilisateur);
    const marquesItl = yield call(
      marquesItlAdapter.getMarquesItl,
      selectMarcheUtilisateur(identite)
    );
    yield put(chargerMarquesItl_succes(marquesItl));
  };

  yield* trySaga(saga, CHARGER_MARQUES_ITL);
}

export function* initialiserModeLightSaga(
  action,
  { marchesAdapter = Adapters.marchesAdapter } = {}
) {
  const saga = function*() {
    yield put(viderCalculateur());
    yield put(definirModeAffichage(LIGHT));
    const marche = yield call(marchesAdapter.getMarche, action.idMarcheChoisi);
    yield put(
      chargerProjetDeTravail_succes(
        PROJET_DE_TRAVAIL_FICTIF_MODE_LIGHT(action.marqueItlChoisie, marche)
      )
    );
    yield put(
      recupererDerogateurDeMarge_succes(DEROGATEUR_DE_MARGE_FICTIF_MODE_LIGHT)
    );
    yield put(calculerEnLineaire(60));

    yield* chargerProfilUtilisateurSaga();

    yield all([
      call(chargerMarquesItlSaga),
      call(chargerMargeCibleSaga),
      call(chargerCatalogueProduitsCompletSaga),
      call(chargerPrimeMinimaleAssuranceEspagneSaga),
    ]);
  };

  yield* trySaga(saga, INITIALISER_MODE_LIGHT);
}

function* chargerProfilUtilisateurSaga() {
  const identite = yield select(identiteUtilisateur);
  const marcheUtilisateur = selectMarcheUtilisateur(identite);
  yield put(chargerProfilUtilisateur_succes({ marcheUtilisateur }));
}

export function* sagasDuModeLight() {
  yield all([
    takeEvery(CHARGER_MARQUES_ITL, chargerMarquesItlSaga),
    takeEvery(INITIALISER_MODE_LIGHT, initialiserModeLightSaga),
  ]);
}

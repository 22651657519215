import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useFileInput } from "./useFileInput.hook";

export function InputFile({ onFilePick, onFileDelete }) {
  const [fileInput, resetFileInput] = useFileInput();
  const [fichierEstChoisi, setFichierEstChoisi] = useState(false);

  return (
    <div>
      <input
        type="file"
        ref={fileInput}
        onChange={(e) => {
          const file = e.target.files[0];
          fichierEnBase64(file).then((base64) => {
            onFilePick({ name: file.name, type: file.type, base64 });
            setFichierEstChoisi(true);
          });
        }}
      />

      <button
        className="pure-button button-warning ml-10"
        onClick={() => {
          resetFileInput();
          setFichierEstChoisi(false);
          onFileDelete();
        }}
        disabled={!fichierEstChoisi}
      >
        <FormattedMessage id="general.supprimer" />
      </button>
    </div>
  );
}

InputFile.propTypes = {
  onFilePick: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
};

export function fichierEnBase64(fichier) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = (error) => reject(error);
    reader.onload = () => {
      const base64SansMetadata = reader.result.replace(
        /^data:(.*;base64,)?/,
        ""
      );
      resolve(base64SansMetadata);
    };

    reader.readAsDataURL(fichier);
  });
}

import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import { FormattedMessage } from "react-intl";
import "./AjouterElement.css";
import "./AjouterElement-mobile.css";
import Aide from "../../../../../Kit/Aide/Aide";
import {
  LOUEUR_DE_CONTRAT,
  produitAcheteVide,
  rachatDeContratVide,
  rachatDeMaterielVide,
} from "../../../domaine/simulation/montantAFinancer/reducer";
import { NATURE_DU_CALCUL } from "../../../domaine/simulation/modeDeCalcul/reducer";

const { CONCURRENT, ITL } = LOUEUR_DE_CONTRAT;
const { INVESTISSEMENT } = NATURE_DU_CALCUL;

export default function AjouterElement({
  onFinancerProduit,
  onRacheterMateriel,
  onRacheterContrat,
  projetEstAER,
  estEnModeLight,
  natureDuCalcul,
  nombreDeMaterielsFinances,
}) {
  const estInvestissement = natureDuCalcul === INVESTISSEMENT;
  const dejaUnMateriel = nombreDeMaterielsFinances === 1;

  return (
    <div className="actions-sur-produits">
      <MaterielNeuf
        onClick={onFinancerProduit}
        disabled={estInvestissement && dejaUnMateriel}
      />
      <MaterielOccasion
        onClick={onFinancerProduit}
        disabled={estInvestissement && dejaUnMateriel}
      />
      <RacheterUnMateriel
        onClick={onRacheterMateriel}
        disabled={estInvestissement}
      />
      <RacheterUnContratConcurrent
        onClick={onRacheterContrat}
        disabled={estInvestissement}
      />
      <RacheterUnContratItl
        estDisponible={(projetEstAER || estEnModeLight) && !estInvestissement}
        onClick={onRacheterContrat}
      />
    </div>
  );
}

AjouterElement.propTypes = {
  onFinancerProduit: PropTypes.func.isRequired,
  onRacheterMateriel: PropTypes.func.isRequired,
  onRacheterContrat: PropTypes.func.isRequired,
  projetEstAER: PropTypes.bool,
  natureDuCalcul: PropTypes.oneOf(Object.values(NATURE_DU_CALCUL)).isRequired,
};

function MaterielNeuf({ onClick, disabled }) {
  return (
    <div>
      <button
        onClick={() => onClick(produitAcheteVide(uuid(), new Date().getTime()))}
        className="pure-button pure-button-primary"
        disabled={disabled}
      >
        <FormattedMessage id="calculateur.produits.financer_produit_neuf" />
      </button>
    </div>
  );
}

function MaterielOccasion({ onClick, disabled }) {
  return (
    <div>
      <button
        onClick={() =>
          onClick({
            ...produitAcheteVide(uuid(), new Date().getTime()),
            estOccasion: true,
          })
        }
        className="pure-button pure-button-primary"
        disabled={disabled}
      >
        <FormattedMessage id="calculateur.produits.financer_produit_occasion" />
      </button>
    </div>
  );
}

function RacheterUnMateriel({ onClick, disabled }) {
  return (
    <div>
      <button
        className="pure-button pure-button-primary"
        onClick={() =>
          onClick(rachatDeMaterielVide(uuid(), new Date().getTime()))
        }
        disabled={disabled}
      >
        <FormattedMessage id="calculateur.produits.racheter_un_materiel" />
      </button>
    </div>
  );
}

function RacheterUnContratConcurrent({ onClick, disabled }) {
  return (
    <div>
      <button
        onClick={() =>
          onClick({
            ...rachatDeContratVide(uuid(), new Date().getTime()),
            loueurContrat: CONCURRENT,
          })
        }
        className="pure-button pure-button-primary"
        disabled={disabled}
      >
        <FormattedMessage id="calculateur.produits.racheter_contrat_concurrent" />
      </button>
    </div>
  );
}

function RacheterUnContratItl({ onClick, estDisponible }) {
  return (
    <div className="flex-dir-row">
      <button
        onClick={() =>
          onClick({
            ...rachatDeContratVide(uuid(), new Date().getTime()),
            loueurContrat: ITL,
          })
        }
        className="pure-button pure-button-primary"
        disabled={!estDisponible}
      >
        <FormattedMessage id="calculateur.produits.racheter_contrat_itl" />
      </button>
      {!estDisponible && (
        <div className="ml-10 flex-ai-center">
          <Aide id="infobulle-rachat-contrat-itl-indisponible">
            <div style={{ maxWidth: "160px" }}>
              <FormattedMessage id="calculateur.produits.racheter_contrat_itl_indisponible" />
            </div>
          </Aide>
        </div>
      )}
    </div>
  );
}

import { combineReducers } from "redux";
import montantAFinancerReducer from "./montantAFinancer/reducer";
import tauxInteretReducer from "./tauxInteret/reducer";
import valeurResiduelleReducer from "./valeurResiduelle/reducer";
import termeReducer from "./terme/terme";
import catalogueProduitsReducer from "./catalogueProduits/reducer";
import modeDeCalculReducer from "./modeDeCalcul/reducer";
import profilUtilisateurReducer from "./profilUtilisateur/reducer";
import derogationsReducer from "./derogations/reducer";
import derogateursReducer from "./derogateurs/reducer";
import margeCibleReducer from "./margeCible/reducer";
import conditionsDeReglementReducer from "./conditionsDeReglement/reducer";
import chaineDeLoyersDeReferenceReducer from "./chaineDeLoyersDeReference/reducer";
import assuranceReducer from "./assurance/reducer";
import { franchiseTroisMoisReducer } from "./franchiseTroisMois/reducer";
import { reducerQuiPeutReset } from "../../../../reduxStore/enhancers/reducerQuiPeutReset/reducerQuiPeutReset";
import { viderCalculateur } from "../actions";
import { projetDeTravailReducer } from "./projetDeTravail/reducer";
import { maintenanceReducer } from "./maintenance/reducer";

export const simulationReducer = combineReducers({
  assurance: resetQuandVideLeCalculateur(assuranceReducer),
  catalogueProduits: resetQuandVideLeCalculateur(catalogueProduitsReducer),
  chaineDeLoyersDeReference: resetQuandVideLeCalculateur(
    chaineDeLoyersDeReferenceReducer
  ),
  conditionsDeReglement: resetQuandVideLeCalculateur(
    conditionsDeReglementReducer
  ),
  derogateurs: resetQuandVideLeCalculateur(derogateursReducer),
  derogations: resetQuandVideLeCalculateur(derogationsReducer),
  franchiseTroisMois: resetQuandVideLeCalculateur(franchiseTroisMoisReducer),
  maintenance: resetQuandVideLeCalculateur(maintenanceReducer),
  margeCible: resetQuandVideLeCalculateur(margeCibleReducer),
  modeDeCalcul: resetQuandVideLeCalculateur(modeDeCalculReducer),
  montantAFinancer: resetQuandVideLeCalculateur(montantAFinancerReducer),
  profilUtilisateur: profilUtilisateurReducer,
  projetDeTravail: projetDeTravailReducer,
  tauxInteret: resetQuandVideLeCalculateur(tauxInteretReducer),
  terme: resetQuandVideLeCalculateur(termeReducer),
  valeurResiduelle: resetQuandVideLeCalculateur(valeurResiduelleReducer),
});

function resetQuandVideLeCalculateur(reducer) {
  return reducerQuiPeutReset(reducer, viderCalculateur());
}

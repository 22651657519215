import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import Aide from "../../../../Kit/Aide/Aide";
import "./BornesDeVr.css";
import "./BornesDeVr-mobile.css";

export function BornesDeVr({ bornesDeVr }) {
  if (bornesDeVr.details.length === 0) return <Fragment />;

  return (
    <div className="bornes-de-vr">
      <div className="globales">
        {estGrilleVide(bornesDeVr) ? (
          <FormattedMessage id="calculateur.bornes_de_vr.globales.avec_grille_vide" />
        ) : (
          <FormattedMessage
            id="calculateur.bornes_de_vr.globales"
            values={{ min: bornesDeVr.min, max: bornesDeVr.max }}
          />
        )}
      </div>

      <Aide id="infobulle-bornes-de-vr">
        <div>
          {bornesDeVr.details.map(b => (
            <div key={b.materiel}>
              {estGrilleVide(b) ? (
                <FormattedMessage
                  id="calculateur.bornes_de_vr.un_detail.avec_grille_vide"
                  values={{ materiel: b.materiel }}
                />
              ) : (
                <FormattedMessage
                  id="calculateur.bornes_de_vr.un_detail"
                  values={{ materiel: b.materiel, min: b.min, max: b.max }}
                />
              )}
            </div>
          ))}
        </div>
      </Aide>
    </div>
  );
}

function estGrilleVide({ min, max }) {
  return isNaN(min) && isNaN(max);
}

import {
  ANOMALIES,
  selectAnomaliesDerogeables,
} from "../../../anomalies/Anomalies";
import { enrichirAvecMotivation } from "../../../motivationsDesAnomalies/reducer";
import { pourcentage } from "../../../../../../Kit/formatage";
import { CODES_GROUPES_OPTIONS } from "../../../../../../Connecteurs/Crm/codesGroupesOptions";
import { formatyyyyMMdd } from "../../../../../../utils/dateUtils";
import { STATUT_DEROGATION } from "../../../../../Derogations/domaine/reducer";

const { MARGE_TROP_FAIBLE } = ANOMALIES;
const { ACCEPTEE, A_DECIDER } = STATUT_DEROGATION;
const ID_TYPE_DEROGATION_DE_MARGE =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_MARGE;
const { statutDeDerogation } = CODES_GROUPES_OPTIONS;

export function mapperLaDemandeDeDerogationDeMarge(calculateur, { intl }) {
  const anomalieDeMarge = getAnomalieDeMarge(calculateur);

  if (!anomalieDeMarge)
    return {
      derogationMappee: null,
      derogateur: null,
      notificationEstRequise: false,
    };

  const anomalieEnrichie = enrichirAvecMotivation(
    anomalieDeMarge,
    calculateur.motivationsDesAnomalies
  );

  const derogationMappee = mapperEnDerogation(anomalieEnrichie, { intl });

  return {
    derogationMappee,
    derogateur: anomalieDeMarge.derogateur,
    notificationEstRequise:
      !anomalieDeMarge.derogateur.estUtilisateurConnecte &&
      !anomalieEnrichie.dejaDerogee,
  };
}

function getAnomalieDeMarge(calculateur) {
  const anomalieDeMargeDerogee = selectAnomaliesDerogeables(
    calculateur.anomalies,
    true
  ).find(anomalie => anomalie.idType === MARGE_TROP_FAIBLE);

  const anomalieDeMargeNonDerogee = selectAnomaliesDerogeables(
    calculateur.anomalies,
    false
  ).find(anomalie => anomalie.idType === MARGE_TROP_FAIBLE);

  return anomalieDeMargeDerogee || anomalieDeMargeNonDerogee;
}

function mapperEnDerogation(anomalie, { intl }) {
  const { details, dejaDerogee, derogation } = anomalie;
  const derogateur = anomalie.derogation
    ? { idDerogateur: anomalie.derogation.idDerogateur }
    : anomalie.derogateur;

  const autoValidee = derogateur.estUtilisateurConnecte;
  const dejaAcceptee = dejaDerogee && derogation.statut === ACCEPTEE;

  const { commentaireDecideur, commentaireDemandeur } = mapperCommentaires(
    anomalie,
    { dejaAcceptee, autoValidee, dejaDerogee },
    { intl }
  );

  return {
    itl_name: mapperNom(anomalie, autoValidee, { intl }),
    itl_valeurorigine: pourcentage(details.tauxMinimalAutonomie),
    itl_valeurdemandee: pourcentage(details.tauxDuPlan),
    itl_statutderogation: autoValidee
      ? statutDeDerogation.get(ACCEPTEE)
      : dejaDerogee
        ? statutDeDerogation.get(derogation.statut)
        : statutDeDerogation.get(A_DECIDER),
    itl_commentairesdemandeur: commentaireDemandeur,
    itl_commentairesdecideur: commentaireDecideur,
    "itl_Typederogation@odata.bind": `/itl_typederogations(${ID_TYPE_DEROGATION_DE_MARGE})`,
    "itl_derogateur@odata.bind": `/itl_derogateurs(${derogateur.idDerogateur})`,
    itl_datedecision:
      dejaAcceptee || autoValidee ? formatyyyyMMdd(Date.now()) : undefined,
  };
}

function mapperNom(anomalie, autoValidee, { intl }) {
  const { tauxMinimalAutonomie, tauxDuPlan } = anomalie.details;

  const nomDerogation = intl.formatMessage(
    { id: "derogation_de_marge.resume" },
    {
      tauxDerogation: pourcentage(tauxDuPlan),
      tauxNormal: pourcentage(tauxMinimalAutonomie),
    }
  );
  const autoValidation = autoValidee
    ? intl.formatMessage(traduction("commentaire_auto_validee")) + " - "
    : "";

  return `${autoValidation}${nomDerogation}`;
}

function mapperCommentaires(
  anomalie,
  { dejaAcceptee, autoValidee, dejaDerogee },
  { intl }
) {
  const commentaireDecideur = dejaAcceptee
    ? intl.formatMessage(traduction("commentaire_decideur"), {
        commentairePrecedent: anomalie.derogation.commentaires.decideur,
      })
    : autoValidee
      ? intl.formatMessage(traduction("commentaire_auto_validee"))
      : undefined;

  const commentaireDemandeur = dejaAcceptee
    ? intl.formatMessage(traduction("commentaire_demandeur"), {
        commentairePrecedent: anomalie.derogation.commentaires.demandeur,
      })
    : dejaDerogee
      ? anomalie.derogation.commentaires.demandeur
      : anomalie.motivation;

  return { commentaireDecideur, commentaireDemandeur };
}

function traduction(cle) {
  return { id: "derogation_de_marge.validee_automatiquement." + cle };
}

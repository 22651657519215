import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import ReactDrawer from "react-drawer";
import { FormattedMessage } from "react-intl";

function BoutonAvecConfirmation(props) {
  const [confirmationAffichee, setConfirmationAffichee] = useState(false);

  const {
    label,
    disabled,
    drawerButtonDisabled,
    children: confirmation,
    onConfirm,
    boutonTestId,
  } = props;

  // Le dernier && est pour les cas où l'appelant fait un
  // <BoutonAvecConfirmation>
  //  { testBoolean && <div>confirmation</div> }
  // </BoutonAvecConfirmation>
  const confirmationNecessaire =
    confirmation !== undefined && confirmation !== null && confirmation;

  return (
    <div>
      <button
        onClick={(event) => {
          event.preventDefault();
          if (confirmationNecessaire) setConfirmationAffichee(true);
          else onConfirm();
        }}
        disabled={disabled}
        className="pure-button pure-button-primary"
        data-testid={boutonTestId}
      >
        {label}
      </button>
      {ReactDOM.createPortal(
        <ReactDrawer
          open={confirmationAffichee}
          position="top"
          onClose={() => setConfirmationAffichee(false)}
        >
          <div className="flex-dir-col flex-ai-center tiroir">
            <div className="mb-10">{confirmation}</div>
            <div className="flex-content-sb mb-10">
              <button
                className="pure-button mr-20"
                onClick={(event) => {
                  event.preventDefault();
                  setConfirmationAffichee(false);
                }}
              >
                <FormattedMessage id="general.annuler" />
              </button>
              <button
                className="pure-button pure-button-primary ml-20"
                disabled={drawerButtonDisabled}
                onClick={(event) => {
                  event.preventDefault();
                  onConfirm();
                  setConfirmationAffichee(false);
                }}
              >
                {label}
              </button>
            </div>
          </div>
        </ReactDrawer>,
        noeudDeRenduDuDrawer
      )}
    </div>
  );
}

BoutonAvecConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  drawerButtonDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  boutonTestId: PropTypes.string,
};

export default BoutonAvecConfirmation;

// Pour permettre le test facile avec react-testing-library
// https://github.com/testing-library/react-testing-library/issues/62#issuecomment-438653348
let noeudDeRenduDuDrawer = document.getElementById("root");
if (!noeudDeRenduDuDrawer) {
  noeudDeRenduDuDrawer = document.createElement("div");
  noeudDeRenduDuDrawer.setAttribute("id", "root");
  document.body.appendChild(noeudDeRenduDuDrawer);
}

import numeral from "numeral";
import "numeral/locales/fr";
import { Money } from "../Money/money";

export function enEuros(montantObject, taxe = `HT`) {
  const argent = Money(montantObject).toRoundedUnit(2);
  numeral.locale("fr");
  const format = numeral(argent).format("0,0[.]00 $");
  return taxe ? `${format} ${taxe}` : format;
}

export function pourcentage(taux) {
  return `${(taux * 100).toFixed(2)} %`;
}

export function montantArrondi(montantObject, decimales) {
  return Money(montantObject).toRoundedUnit(decimales);
}

export function taux(chainePourcentage) {
  return Number(chainePourcentage.replace("%", "").replace(" ", "")) / 100;
}

export function tauxBase100(chainePourcentage) {
  return Number(chainePourcentage.replace("%", "").replace(" ", ""));
}

export function nombre(chaineEuros) {
  const nonDigitsOrSeparator = /[^\d+,.]/g;
  return Number(
    chaineEuros.replaceAll(nonDigitsOrSeparator, "").replaceAll(",", ".")
  );
}

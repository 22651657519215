import {
  listeDesProduitsAchetes,
  produitSansMontant,
} from "../../simulation/montantAFinancer/reducer";
import {
  ID_MARQUE_GENERIQUE,
  produitEstNonReference,
} from "../../simulation/catalogueProduits/reducer";
import { AnomalieMaterielIncomplet } from "../Anomalies";

export function verifierLesMateriels(simulation) {
  const { montantAFinancer } = simulation;

  return listeDesProduitsAchetes(montantAFinancer)
    .filter((p) => {
      return (
        nonReferenceIncomplet(p) ||
        occasionSansAnnee(p) ||
        sansLabelDeMarque(p) ||
        sansFamille(p) ||
        sansMontant(p) ||
        sansQuantite(p)
      );
    })
    .map(() => AnomalieMaterielIncomplet());
}

function nonReferenceIncomplet(produit) {
  const sansDesignation = estManquant(produit.designationDuNonReference);
  return produitEstNonReference(produit) && sansDesignation;
}

function sansLabelDeMarque(produit) {
  const aUneFamille = estPresent(produit.idFamille);
  const estGenerique = produit.idMarque === ID_MARQUE_GENERIQUE;
  const pasDeLabelMarque = estManquant(produit.labelMarque);
  return aUneFamille && estGenerique && pasDeLabelMarque;
}

function occasionSansAnnee(produit) {
  return produit.estOccasion && !produit.annee;
}

function sansFamille(produit) {
  return estManquant(produit.idFamille);
}

function sansMontant(produit) {
  return produitSansMontant(produit);
}

function sansQuantite(produit) {
  return estManquant(produit.quantite);
}

function estManquant(x) {
  return x === null || x === undefined || x === "";
}

function estPresent(x) {
  return !estManquant(x);
}

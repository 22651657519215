import React from "react";
import { FormattedMessage } from "react-intl";
import { InputFile } from "../../../../../../Kit/Formulaires/InputFile";

export function PieceJointe({ onFileDelete, onFilePick }) {
  return (
    <div className="la-piece-jointe">
      <div>
        <FormattedMessage id="derogation_de_vr.avis_broker.ajouter_piece_jointe" />
      </div>
      <InputFile onFilePick={onFilePick} onFileDelete={onFileDelete} />
    </div>
  );
}

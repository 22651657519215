import React from "react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";

// C'est la lib https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-react qui
// est utilisée par Force.
// Mise en place inspirée par https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/migration-guide.md

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID_DE_FORCE_DANS_AZURE,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_ID_DE_ITL_DANS_AZURE}`,
  },
});

// On utilise le scope dans acquireTokenSilent
// pour avoir un « aud » du token qui correspond
// à l'API appelée
export const getAccessToken = async (scope) => {
  const accounts = pca.getAllAccounts();
  const token = await pca.acquireTokenSilent({
    account: accounts[0],
    scopes: [scope],
  });
  return token.accessToken;
};

export const AppAvecAuthentificationRequise = ({ children }) => (
  <MsalProvider instance={pca}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      {children}
    </MsalAuthenticationTemplate>
  </MsalProvider>
);

import { getMarcheUtilisateur } from "../../../simulation/profilUtilisateur/sagas";
import { i18n } from "../../../../../../i18n/i18n";
import { select } from "redux-saga/effects";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { enrichirAvecMotivation } from "../../../motivationsDesAnomalies/reducer";
import { pourcentage } from "../../../../../../Kit/formatage";

export function* anomalieEnDemandeDeDerogation(anomalieDeTeg) {
  const marcheUtilisateur = yield* getMarcheUtilisateur();
  const intl = i18n.getIntl(marcheUtilisateur);
  const { motivationsDesAnomalies } = yield select(calculateur);

  return {
    ...enrichirAvecMotivation(anomalieDeTeg, motivationsDesAnomalies),
    resume: intl.formatMessage(
      { id: "derogation_de_teg.resume" },
      {
        tauxNormal: pourcentage(anomalieDeTeg.details.tegGrille),
        tauxDerogation: pourcentage(anomalieDeTeg.details.tegManuel),
      }
    ),
  };
}

import {
  listeDesProduitsAchetes,
  selectProduitAchete,
} from "../../simulation/montantAFinancer/reducer";
import { AnomalieVrNecessiteDerogation } from "../Anomalies";
import { produitEstNonReference } from "../../simulation/catalogueProduits/reducer";
import {
  lesTauxDeVrDemandes,
  tauxEffectifDeVr,
  tauxSeuilDerogation,
} from "../../simulation/valeurResiduelle/selecteurs/taux.selecteurs";
import {
  demandeUneVrUnLoyer,
  vrUnLoyerEstAutoriseePourLeMateriel,
} from "../../simulation/valeurResiduelle/selecteurs/vrUnLoyer.selecteurs";
import { selectDureeEnMois } from "../../simulation/modeDeCalcul/reducer";
import { selectVrParMois } from "../../simulation/valeurResiduelle/selecteurs/generalites.selecteurs";
import GrilleDeVr from "../../simulation/valeurResiduelle/GrilleDeVr";

export function verifierLesTauxDeVr(simulation) {
  return uneSeuleAnomalieParProduit([
    ...getLesTauxSuperieursGrille(simulation),
    ...getLesTauxSurProduitsToujoursDeroges(simulation),
  ]).map(({ produit, tauxDemande, tauxSeuil }) =>
    AnomalieVrNecessiteDerogation(produit, tauxSeuil, tauxDemande)
  );
}

function getLesTauxSuperieursGrille(simulation) {
  const { valeurResiduelle, montantAFinancer } = simulation;

  return lesTauxDeVrDemandes(valeurResiduelle)
    .map(([idProduit, tauxDemande]) => {
      const produit = selectProduitAchete(montantAFinancer, idProduit);
      const tauxSeuil = tauxSeuilDerogation(valeurResiduelle, produit);
      return { produit, tauxDemande, tauxSeuil };
    })
    .filter(({ tauxDemande, tauxSeuil }) => tauxDemande > tauxSeuil)
    .filter(
      ({ produit }) => !estUneVrUnLoyerAutorisee(valeurResiduelle, produit)
    );
}

function estUneVrUnLoyerAutorisee(valeurResiduelle, produit) {
  if (!demandeUneVrUnLoyer(valeurResiduelle)) return false;
  return vrUnLoyerEstAutoriseePourLeMateriel(valeurResiduelle, produit);
}

function getLesTauxSurProduitsToujoursDeroges(simulation) {
  const { montantAFinancer, valeurResiduelle } = simulation;

  return listeDesProduitsAchetes(montantAFinancer)
    .filter(p => p.idFamille !== null)
    .filter(
      p =>
        p.estOccasion ||
        produitEstNonReference(p) ||
        dureeEstHorsGrille(p, simulation) ||
        produitAvecGrilleVide(p, simulation)
    )
    .map(p => ({
      produit: p,
      tauxSeuil: tauxSeuilDerogation(valeurResiduelle, p),
      tauxDemande: tauxEffectifDeVr(valeurResiduelle, p),
    }));
}

function dureeEstHorsGrille(produit, simulation) {
  return new GrilleDeVr(
    selectVrParMois(simulation.valeurResiduelle, produit, simulation)
  ).estUneDureeHorsBorne(selectDureeEnMois(simulation.modeDeCalcul));
}

function produitAvecGrilleVide(produit, simulation) {
  return new GrilleDeVr(
    selectVrParMois(simulation.valeurResiduelle, produit, simulation)
  ).estVide();
}

function uneSeuleAnomalieParProduit(anomalies) {
  const distinct = [];
  for (const a of anomalies) {
    if (distinct.map(x => x.produit.id).includes(a.produit.id)) continue;

    distinct.push(a);
  }
  return distinct;
}

import { httpServiceCrm } from "../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../utils/odata/odata";

export const profilUtilisateurAdapterCrm = {
  async getNomPrenom(idUtilisateur, { http = httpServiceCrm } = {}) {
    const requeteNomPrenom = odataQuery(`systemusers(${idUtilisateur})`, {
      $select: ["fullname"],
    });

    const nomPrenomCrm = await http.get(requeteNomPrenom);

    return nomPrenomCrm.fullname;
  },
};

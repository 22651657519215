import { connect } from "react-redux";
import { selectOperationsSucces } from "../../../../../Ui/reducer";
import { CREER_FICHE_DE_TRANSMISSION } from "../../../domaine/creationDeFt/actions";
import { supprimerOperationSucces } from "../../../../../Ui/actions";
import { CreationReussie } from "./CreationReussie";

const mapStateToProps = (state) => ({
  messages: selectOperationsSucces(state.ui).filter(
    (o) => o.type === CREER_FICHE_DE_TRANSMISSION
  ),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseMessage: (type, idEntite) =>
    dispatch(supprimerOperationSucces(type, idEntite)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreationReussie);

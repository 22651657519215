import { connect } from "react-redux";
import {
  erreurDeOperation,
  uneOperationEstEnCours,
} from "../../../../Ui/reducer";
import {
  CHARGER_RESUMES_DE_DEROGATIONS,
  chargerResumesDeDerogations,
} from "../../domaine/actions";
import {
  selectDerogateursDeTeg,
  selectResumeDeDerogationsAStatuer,
  selectResumeDeDerogationsStatuees,
} from "../../domaine/reducer";
import ListeDesDerogationsResponsive from "./ListeDesDerogations.responsive";
import {
  POSTE_UTILISATEUR,
  selectUtilisateurALePoste,
} from "../../../../identiteUtilisateur/reducer";
import {
  derogations,
  identiteUtilisateur,
  referentiel,
} from "../../../../reduxStore/rootReducer";
import {
  derogationEstAssigneeAuConnecte,
  derogationEstDansEquipeDuConnecte,
} from "../../domaine/repartitionDesDerogations";

const { BROKER } = POSTE_UTILISATEUR;

const mapStateToProps = (state) => {
  const i = identiteUtilisateur(state);
  const r = referentiel(state);
  const teg = selectDerogateursDeTeg(derogations(state));

  const demandesAStatuer = selectResumeDeDerogationsAStatuer(state.derogations);

  return {
    isLoading: uneOperationEstEnCours(state.ui, [
      CHARGER_RESUMES_DE_DEROGATIONS,
    ]),
    demandesAssigneesAuConnecte: demandesAStatuer.filter((derogation) =>
      derogationEstAssigneeAuConnecte(derogation, i, r, teg)
    ),
    demandesAssigneesEquipe: demandesAStatuer.filter((derogation) =>
      derogationEstDansEquipeDuConnecte(derogation, i)
    ),
    demandesStatuees: selectResumeDeDerogationsStatuees(state.derogations),
    erreurs: erreurDeOperation(state.ui, [CHARGER_RESUMES_DE_DEROGATIONS]),
    utilisateurEstBroker: selectUtilisateurALePoste(i, BROKER),
  };
};

const mapDispatchToProps = (dispatch, { history }) => ({
  initialisation: () => dispatch(chargerResumesDeDerogations()),
  onClickVoirDetailsDemande: (idDemande) =>
    history.push(`/derogations/demande/${idDemande}`),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListeDesDerogationsResponsive);

import { listeDesProduitsAchetes } from "../../../../simulation/montantAFinancer/reducer";
import {
  ID_MARQUE_GENERIQUE,
  labelCatalogue,
  laMarque,
  leProduitCatalogue,
  REFERENCEMENT_PRODUIT,
  suivantReferencement,
} from "../../../../simulation/catalogueProduits/reducer";
import { derogationDeVrDeProduitAchete } from "./derogationDeVr.mapping";
import { selectMarcheUtilisateur } from "../../../../simulation/profilUtilisateur/reducer";
import { selectAssuranceEspagneEstAjoutee } from "../../../../simulation/assurance/espagne/reducer";
import { montantArrondi } from "../../../../../../../Kit/formatage";
import { Money } from "../../../../../../../Money/money";
import { CODES_GROUPES_OPTIONS } from "../../../../../../../Connecteurs/Crm/codesGroupesOptions";
import { MARCHES } from "../../../../../../../identiteUtilisateur/reducer";
import { tauxEffectifDeVr } from "../../../../simulation/valeurResiduelle/selecteurs/taux.selecteurs";
import { montantDeVrDuProduit } from "../../../../simulation/valeurResiduelle/selecteurs/montants.selecteurs";

const { EST_REFERENCE, EST_NON_REFERENCE } = REFERENCEMENT_PRODUIT;
const { ESPAGNE, FRANCE } = MARCHES;

export function mapperLesMateriels(calculateur) {
  const { montantAFinancer, catalogueProduits } = calculateur.simulation;

  const depenses = listeDesProduitsAchetes(montantAFinancer).map(achat => {
    const produit = leProduitCatalogue(catalogueProduits, achat.idFamille);

    return {
      depense: unProduitAcheteEnDepense(achat, calculateur),
      derogationDeVr: derogationDeVrDeProduitAchete(achat, calculateur),
      produit: { id: achat.id, label: produit.label, crm: produit.crm },
      marque: {
        label: laMarque(catalogueProduits, achat.idMarque).label,
      },
    };
  });

  return depenses;
}

function unProduitAcheteEnDepense(produitAchete, calculateur) {
  const { simulation } = calculateur;

  return {
    ...generaux(produitAchete, simulation),
    ...montants(produitAchete),
    ...assurance(produitAchete, simulation),
    ...vr(produitAchete, simulation),
  };
}

function generaux(produitAchete, simulation) {
  const { catalogueProduits } = simulation;
  const { idFamille, idMarque } = produitAchete;

  const produitCatalogue = leProduitCatalogue(catalogueProduits, idFamille);
  const marque = laMarque(catalogueProduits, idMarque);

  return {
    "itl_FamilleId@odata.bind": `/itl_familles(${produitCatalogue.crm.id})`,
    "itl_MarqueId@odata.bind":
      marque.crm.id !== ID_MARQUE_GENERIQUE
        ? `/itl_marques(${marque.crm.id})`
        : undefined,
    itl_marquenonreferencee: produitAchete.labelMarque,
    itl_name:
      produitAchete.description ||
      labelCatalogue(catalogueProduits, produitAchete),
    itl_typematerielcode: CODES_GROUPES_OPTIONS.typesDeMateriels.materielAchete,
    itl_isoccasion: produitAchete.estOccasion,
    itl_annee: produitAchete.annee || undefined,
    itl_designationmatnonreference: suivantReferencement(produitAchete, {
      [EST_NON_REFERENCE]: () => produitAchete.designationDuNonReference,
      [EST_REFERENCE]: () => undefined,
    }),
  };
}

function montants(produitAchete) {
  const { montantUnitaire, quantite } = produitAchete;
  return {
    itl_montantht: montantArrondi(montantUnitaire, 2),
    itl_quantite: quantite,
    itl_montanttotalht: Money(montantUnitaire)
      .multiply(quantite)
      .toUnit(),
  };
}

function assurance(produitAchete, simulation) {
  const { assurance } = simulation;

  const mappingParMarche = {
    [ESPAGNE]: () => {
      const depenseEstAssuree =
        selectAssuranceEspagneEstAjoutee(assurance.espagne) &&
        !produitAchete.estOccasion;

      const ID_DU_PRODUIT_ASSURANCE_ESPAGNE_EN_CRM =
        process.env.REACT_APP_ASSURANCE_ID_DE_ASSURANCE_ESPAGNE_EN_CRM;
      return {
        "itl_AssuranceId@odata.bind": depenseEstAssuree
          ? `/itl_assurances(${ID_DU_PRODUIT_ASSURANCE_ESPAGNE_EN_CRM})`
          : undefined,
      };
    },

    [FRANCE]: () => ({}),
  };

  const { profilUtilisateur } = simulation;
  const marcheUtilisateur = selectMarcheUtilisateur(profilUtilisateur);
  return mappingParMarche[marcheUtilisateur]();
}

function vr(produitAchete, simulation) {
  const { valeurResiduelle } = simulation;
  const tauxDeVr = tauxEffectifDeVr(valeurResiduelle, produitAchete);
  const vr = montantDeVrDuProduit(valeurResiduelle, produitAchete);
  return {
    itl_vrdemandee_pourcent: tauxDeVr * 100,
    itl_vrdemandee_euros: vr.toUnit(),
  };
}

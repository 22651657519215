import React from "react";
import PropTypes from "prop-types";

export const ProgressBar = ({ max = 100, value }) => (
  <div>
    <progress max={max} value={value} />
  </div>
);

ProgressBar.propTypes = {
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
};

const CORPS = `Hola,<br />
Acabas de recibir una nueva solicitud de <b>derogación de margen</b>.<br />
<ul>
  <li><b>Vendedor : </b>{demandeur}</li>
  <li><b>Proyecto : </b>{projet}</li>
  <li><b>Cliente : </b>{client}</li>
  <li><b>FT : </b>{ft}</li>
  <li><b>Importe total de la operación : </b>{montantAFinancer}</li>
  <li><b>VR : </b>{vr}</li>
  <li><b>Margen solicitado (%) : </b>{tauxDeMargeDemande}</li>
  <li><b>Margen minimo disponible : </b>{tauxDeMargeEnAutonomie}</li>
  <li><b>Explicación de la solicitud : </b>{motivation}</li>
</ul>
<br />
Para poder enviar tu decision, favor de pinchar en el enlace siguiente :
<a
  href="{urlOuvrirDerogation}"
  style="
    display: block;
    width: 300px;
    text-align: center;
    margin: 10px;
    color: white;
    text-decoration: none;
    background-color: #0078e7;
    padding: 10px;
    border-radius: 7px;
  "
>
  Detalle de la solicitud
</a>
`;

const SUJET = `[Ficha de transmisión] Nueva solicitud de excepción de Margen - {demandeur}`;

export const ACTION_SUR_DEROGATION_DE_MARGE_ES = { CORPS, SUJET };

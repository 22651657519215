export const INITIALISER_CALCULATEUR = "Calculateur/INITIALISER_CALCULATEUR";

export const INITIALISER_CALCULATEUR_VIDE =
  "Calculateur/INITIALISER_CALCULATEUR_VIDE";
export const initialiserCalculateurVide = () => ({
  type: INITIALISER_CALCULATEUR_VIDE,
});

export const INITIALISER_CALCULATEUR_DEPUIS_FT =
  "INITIALISER_CALCULATEUR_DEPUIS_FT";
export const initialiserCalculateurDepuisFt = (
  idFicheTransmissionAImporter,
  timestampImport
) => ({
  type: INITIALISER_CALCULATEUR_DEPUIS_FT,
  idFicheTransmission: idFicheTransmissionAImporter,
  timestampImport,
});

export const VIDER_CALCULATEUR = "VIDER_CALCULATEUR";
export const viderCalculateur = () => ({
  type: VIDER_CALCULATEUR,
});

export const INITIALISER_CALCULATEUR_DEPUIS_INSTANTANE =
  "INITIALISER_CALCULATEUR_DEPUIS_INSTANTANE";
export const initialiserCalculateurDepuisInstantane = (
  idInstantane,
  timestampRestauration
) => ({
  type: INITIALISER_CALCULATEUR_DEPUIS_INSTANTANE,
  idInstantane,
  timestampRestauration,
});

import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Carte.css";
import "./Carte-mobile.css";
import { useWindowDimensions } from "../../utils/hooks";

export const Carte = ({ titre, children }) => {
  const [estDepliee, setEstDepliee] = useState(true);

  const cssContenu = classNames("carte-contenu", {
    "carte-contenu-visible": estDepliee,
    "carte-contenu-masque": !estDepliee,
  });

  return (
    <div className="carte">
      <header onClick={() => setEstDepliee(!estDepliee)}>
        <div>{titre}</div>
        <IconeAccordeon contenuEstVisible={estDepliee} />
      </header>

      <div className={cssContenu}>{children}</div>
    </div>
  );
};

Carte.propTypes = {
  titre: PropTypes.string.isRequired,
};

function IconeAccordeon({ contenuEstVisible }) {
  const { width } = useWindowDimensions();
  if (width > 800) return <></>;

  const css = classNames("carte-icone-accordeon", {
    "carte-icone-accordeon-contenu-visible": contenuEstVisible,
    "carte-icone-accordeon-contenu-masque": !contenuEstVisible,
  });
  return (
    <div className={css}>
      <FontAwesomeIcon icon={faChevronDown} size="xs" />
    </div>
  );
}

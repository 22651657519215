import { call, put, select } from "redux-saga/effects";
import {
  ACCEPTER_DEROGATION_DE_MARGE,
  REFUSER_DEROGATION_DE_MARGE,
} from "./actions";
import { identiteUtilisateur } from "../../../../../reduxStore/rootReducer";
import { STATUT_DEROGATION } from "../../reducer";
import { chargerUneDerogationDetaillee } from "../../actions";
import { notifierLeCommercialDeLaDecisionSaga } from "./notification.sagas";
import { enEuros } from "../../../../../Kit/formatage";
import { ht } from "../../../../../i18n/raccourcis";
import { Adapters } from "../../../../../reduxStore/adapters";
import { trySaga } from "../../../../../utils/sagasUtils";
import { selectIdCrmUtilisateur } from "../../../../../identiteUtilisateur/reducer";

const { ACCEPTEE, REFUSEE } = STATUT_DEROGATION;

export function* accepterDerogationDeMargeSaga(action) {
  const saga = function* () {
    const acceptee = action.intl.formatMessage({
      id: "derogations.decision.acceptee",
    });

    yield call(
      deciderDerogationDeMargeSaga,
      action.derogation,
      { ...action.decision, statut: ACCEPTEE },
      { verdict: acceptee, emoji: "✅" },
      { intl: action.intl }
    );
  };

  yield* trySaga(saga, ACCEPTER_DEROGATION_DE_MARGE);
}

export function* refuserDerogationDeMargeSaga(action) {
  const saga = function* () {
    const refusee = action.intl.formatMessage({
      id: "derogations.decision.refusee",
    });

    yield call(
      deciderDerogationDeMargeSaga,
      action.derogation,
      { ...action.decision, statut: REFUSEE },
      { verdict: refusee, emoji: "❌" },
      { intl: action.intl }
    );
  };

  yield* trySaga(saga, REFUSER_DEROGATION_DE_MARGE);
}

export function* deciderDerogationDeMargeSaga(
  derogation,
  decision,
  notification,
  { derogationDeMargeAdapter = Adapters.derogationDeMargeAdapter, intl } = {}
) {
  const idDerogateur = yield call(recupererMonIdDerogateur);
  const decisionComplete = {
    statut: decision.statut,
    dateDecision: decision.dateDecision,
    commentaireDecideur: decision.commentaireDecideur,
    derogateur: { id: idDerogateur },
  };

  const commentaires = getCommentaireDecisionGroupee(derogation, intl);

  yield call(derogationDeMargeAdapter.prendreDecision, derogation.id, {
    ...decisionComplete,
    commentaireDerogationsLiees: commentaires.pourDerogPrincipale,
  });

  for (const { idDerogation } of derogation.derogationsLiees)
    yield call(derogationDeMargeAdapter.prendreDecision, idDerogation, {
      ...decisionComplete,
      commentaireDerogationsLiees: commentaires.pourDerogLiees,
    });

  yield call(notifierLeCommercialDeLaDecisionSaga, derogation.id, notification);
  yield put(chargerUneDerogationDetaillee(derogation.id));
}

const ID_TYPE_DEROGATION_DE_MARGE =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_MARGE;

export function* recupererMonIdDerogateur({
  resumeDeDerogationAdapter = Adapters.resumeDeDerogationAdapter,
} = {}) {
  const identite = yield select(identiteUtilisateur);
  const idDerogateur = yield call(
    resumeDeDerogationAdapter.getIdDerogateurDeUtilisateur,
    selectIdCrmUtilisateur(identite),
    ID_TYPE_DEROGATION_DE_MARGE
  );
  return idDerogateur;
}

function getCommentaireDecisionGroupee(derogationPrincipale, intl) {
  const { ficheDeTransmission, derogationsLiees } = derogationPrincipale;

  const nbLiees = derogationsLiees.length;
  const aucuneLiee = nbLiees === 0;
  if (aucuneLiee) return {};

  const actualisation = enEuros(
    ficheDeTransmission.actualisationEstimee,
    ht(intl)
  );

  const mois = intl.formatMessage({ id: "general.duree.mois" }).toLowerCase();
  const dureeEnMois = `${ficheDeTransmission.dureeEnMois} ${mois}`;

  const traduction = (finDeLaCle) => ({
    id: `derogation_de_marge.notification.decision_groupee.titre.${finDeLaCle}`,
  });

  return {
    pourDerogPrincipale: intl.formatMessage(
      traduction("derogation_principale"),
      { nbLiees }
    ),
    pourDerogLiees: intl.formatMessage(traduction("derogations_liees"), {
      ftPrincipale: ficheDeTransmission.nom,
      actualisation,
      dureeEnMois,
      depenses: ficheDeTransmission.depenses.map((d) => `- ${d}`).join("\n"),
    }),
  };
}

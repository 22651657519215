import { CODES_GROUPES_OPTIONS } from "../../../../Connecteurs/Crm/codesGroupesOptions";
import { Money } from "../../../../Money/money";
import { nombreDeMois } from "../simulation/modeDeCalcul/periodicite/periodicite";
import { loyersEnPaliers } from "./importService";

const { typesDeMateriels } = CODES_GROUPES_OPTIONS;

export function erreursEmpechantImportDeFt(ft, { intl }) {
  return [
    rachatSansTypePrecis(ft, { intl }),
    remiseAuContratSansTypePrecis(ft, { intl }),
    ftAvecFranchiseTroisMois(ft),
  ].filter(e => e !== undefined);
}

function rachatSansTypePrecis(ft, { intl }) {
  const rachatSansTypePrecis = ft.depenses
    .filter(d => d.codeTypeMateriel === typesDeMateriels.rachat)
    .some(d => d.codeTypeRachat === null);

  if (rachatSansTypePrecis) {
    return intl.formatMessage({
      id: "calculateur.import_de_ft.erreur.rachat_sans_type_precis",
    });
  }
}

function remiseAuContratSansTypePrecis(ft, { intl }) {
  const remiseAuContratSansTypePrecis = ft.depenses
    .filter(d => d.codeTypeMateriel === typesDeMateriels.remisAuContrat)
    .some(d => d.codeTypeRachat === null);

  if (remiseAuContratSansTypePrecis)
    return intl.formatMessage({
      id: "calculateur.import_de_ft.erreur.remise_au_contrat_sans_type_precis",
    });
}

function ftAvecFranchiseTroisMois(ft) {
  const paliers = loyersEnPaliers(ft);
  const { periodicite } = ft.informationsFinancieres;

  const contientFranchiseTroisMois =
    Money(paliers[0].loyer).isZero() &&
    nombreDeMois(paliers[0].duree, periodicite) === 3;

  if (contientFranchiseTroisMois)
    return "L'import d'une FT contenant une franchise 3 mois n'est pas supporté.";
}

import React from "react";

export const Option_MaterielCategorise = ({ label, value, estUnDefault }) => (
  <div className="flex-dir-col">
    {label}
    {!estUnDefault &&
      value.categorisation && (
        <div className="mt-5 tags-triangles">
          <span>{value.categorisation.niveauParent || <>&nbsp;</>}</span>
          <span>{value.categorisation.niveauEnfant || <>&nbsp;</>}</span>
        </div>
      )}
  </div>
);

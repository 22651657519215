import b64toBlob from "b64-to-blob";
import download from "downloadjs";
import { httpServiceCrm } from "../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../utils/odata/odata";
import { TYPE_DE_DEROGATION } from "../../reducer";
import {
  CODES_GROUPES_OPTIONS,
  MAPPING_CRM_VERS_DOMAINE,
} from "../../../../../Connecteurs/Crm/codesGroupesOptions";
import { $obj, Money } from "../../../../../Money/money";
import {
  ID_MARQUE_GENERIQUE,
  ID_MATERIEL_NON_REFERENCE,
} from "../../../../Calculateur/domaine/simulation/catalogueProduits/reducer";
import { tauxBase100 } from "../../../../../Kit/formatage";
import { formatyyyyMMdd } from "../../../../../utils/dateUtils";
import { crmCreerNote } from "../../../../../Connecteurs/Crm/creerNote";
import { rapportFicheTransmissionUrl } from "../../../../../Connecteurs/Crm/rapports";
import { guidEqual } from "../../../../../utils/guidUtils";
import { identiteAdapterCrm } from "../../../../../identiteUtilisateur/identite.adapter.crm";

const { statutDeDerogation } = CODES_GROUPES_OPTIONS;
const { VR } = TYPE_DE_DEROGATION;
const { statutDerogation, typesDeFinancement } = MAPPING_CRM_VERS_DOMAINE;

export const derogationDeVrAdapterCrm = {
  async getDerogation(
    idDerogation,
    { httpService = httpServiceCrm, identiteAdapter = identiteAdapterCrm } = {}
  ) {
    const requete = getRequeteDerogation(idDerogation);
    const derogation = await httpService.get(requete);

    const familleEstNonReferencee = guidEqual(
      derogation.itl_depenseftid._itl_familleid_value,
      ID_MATERIEL_NON_REFERENCE
    );

    const marcheDuDemandeur = await identiteAdapter.getMarcheDeUtilisateur(
      derogation.createdby.systemuserid
    );

    return {
      id: derogation.itl_derogationid,
      type: VR,
      nom: derogation.itl_name,
      statut: statutDerogation[derogation.itl_statutderogation],
      dateDemande: derogation.itl_datedemande,
      commentaireBroker: derogation.itl_commentairebroke,
      commentaireDecideur: derogation.itl_commentairesdecideur,
      motivation: derogation.itl_commentairesdemandeur,
      descriptionMaterielsConcernes: derogation.itl_depenseftid.itl_name,
      dateDecision: derogation.itl_datedecision,
      dateAvisBroker: derogation.itl_datecombroke,
      valeurOrigine: tauxBase100(derogation.itl_valeurorigine),
      valeurDemandee: tauxBase100(derogation.itl_valeurdemandee),
      valeurBroker: {
        pourcentage: derogation.itl_vrbroke,
        montant: $obj(derogation.itl_vrbroke_montant),
      },
      derogateur: {
        nom: derogation.itl_derogateur
          ? derogation.itl_derogateur.itl_name
          : "",
      },
      projet: {
        id: derogation.itl_projetconcerne.itl_projetid,
        nom: derogation.itl_projetconcerne.itl_projectname,
        idMarche: derogation.itl_projetconcerne._itl_marcheid_value,
        client: { nom: derogation.itl_projetconcerne.itl_CompteId?.name },
      },
      ficheDeTransmission: {
        id: derogation.itl_FTconcernee.itl_fichetransmissionid,
        dureeEnMois: derogation.itl_FTconcernee.itl_dureeenmois,
        typeDeFinancement:
          typesDeFinancement[
            derogation.itl_FTconcernee._itl_typefinancementid_value.toUpperCase()
          ],
      },
      broker: { nom: derogation.itl_broketitl.fullname },
      auteur: {
        id: derogation.createdby.systemuserid,
        nom: derogation.createdby.fullname,
        marche: marcheDuDemandeur,
        directeur: { id: derogation.createdby._parentsystemuserid_value },
      },
      depense: {
        montantUnitaire: $obj(derogation.itl_depenseftid.itl_montantht),
        famille: {
          id: derogation.itl_depenseftid._itl_familleid_value,
          estNonReferencee: familleEstNonReferencee,
        },
        marque: {
          id:
            derogation.itl_depenseftid._itl_marqueid_value ||
            ID_MARQUE_GENERIQUE,
        },
        estOccasion: derogation.itl_depenseftid.itl_isoccasion,
        annee: derogation.itl_depenseftid.itl_annee,
      },
      piecesJointes: derogation.itl_derogation_Annotations.map(pj => ({
        id: pj.annotationid,
        nom: pj.filename,
      })),
      ft: {
        urlRapport: rapportFicheTransmissionUrl(
          derogation.itl_FTconcernee.itl_fichetransmissionid
        ),
      },
    };
  },

  async donnerAvisBroker(avis, { httpService = httpServiceCrm } = {}) {
    const avisCrm = {
      itl_datecombroke: formatyyyyMMdd(avis.dateDecision),
      itl_datedecision: formatyyyyMMdd(avis.dateDecision),
      itl_commentairebroke: avis.commentaireBroker,
      itl_vrbroke: avis.valeurBroker.pourcentage,
      itl_vrbroke_montant: Money(avis.valeurBroker.montant).toUnit(),
      "itl_broketitl@odata.bind": `/systemusers(${avis.idBroker})`,
      itl_statutderogation: statutDeDerogation.get(avis.statutDerogation),
    };

    await httpService.patch(`itl_derogations(${avis.idDerogation})`, avisCrm);
  },

  async ajouterPieceJointe(
    idDerogation,
    pieceJointe,
    { httpService = httpServiceCrm } = {}
  ) {
    await crmCreerNote(
      {
        titre: pieceJointe.titre,
        fichierJoint: {
          nom: pieceJointe.name,
          type: pieceJointe.type,
          base64: pieceJointe.base64,
        },
        regarding: {
          type: "itl_derogation",
          cible: `/itl_derogations(${idDerogation})`,
        },
      },
      { httpService }
    );
  },

  async telechargerPieceJointe(
    fichier,
    { http = httpServiceCrm, telechargerChezClient = download } = {}
  ) {
    const fichierCrm = await http.get(
      odataQuery(`annotations(${fichier.id})`, {
        $select: ["documentbody", "mimetype"],
      })
    );

    // Idée originale pour le téléchargement :
    // https://alexanderdevelopment.net/post/2016/09/29/downloading-dynamics-crm-attachments-with-javascript/
    // Pour ne pas coder la conversion en Blob :
    // https://www.npmjs.com/package/b64-to-blob
    telechargerChezClient(
      b64toBlob(fichierCrm.documentbody, fichierCrm.mimetype),
      fichier.nom,
      fichierCrm.mimetype
    );
  },
};

function getRequeteDerogation(idDerogation) {
  const champsDirects = [
    "itl_derogationid",
    "itl_name",
    "itl_statutderogation",
    "itl_datedecision",
    "itl_datedemande",
    "itl_valeurdemandee",
    "itl_valeurorigine",
    "itl_commentairesdemandeur",
    "itl_commentairesdecideur",
    "itl_vrbroke",
    "itl_vrbroke_montant",
    "itl_commentairebroke",
    "itl_datecombroke",
  ];
  const depense = [
    "itl_depenseftid",
    {
      $select: [
        "itl_name",
        "itl_montantht",
        "_itl_familleid_value",
        "_itl_marqueid_value",
        "itl_isoccasion",
        "itl_annee",
      ],
    },
  ];
  const derogateur = ["itl_derogateur", { $select: ["itl_name"] }];
  const projet = [
    "itl_projetconcerne",
    {
      $select: ["itl_projetid", "itl_projectname", "_itl_marcheid_value"],
      $expand: ["itl_CompteId", { $select: ["name"] }],
    },
  ];
  const ft = [
    "itl_FTconcernee",
    {
      $select: [
        "itl_fichetransmissionid",
        "itl_dureeenmois",
        "_itl_typefinancementid_value",
      ],
    },
  ];
  const broker = ["itl_broketitl", { $select: ["fullname"] }];
  const auteur = [
    "createdby",
    { $select: ["systemuserid", "fullname", "_parentsystemuserid_value"] },
  ];
  const piecesJointes = [
    "itl_derogation_Annotations",
    { $select: ["annotationid", "filename"] },
  ];

  return odataQuery(`itl_derogations(${idDerogation})`, {
    $select: champsDirects,
    $expand: [depense, derogateur, projet, ft, broker, auteur, piecesJointes],
  });
}

import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import "./InputMontant.css";
import { injectIntl } from "react-intl";

class InputMontant extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: enString(props.value),
    };
    this.input = null;
  }

  componentDidMount() {
    const { focusOnMount } = this.props;
    if (focusOnMount) this.input.select();
  }

  componentDidUpdate(prevProps) {
    const ancienne = prevProps.value;
    const nouvelle = this.props.value;

    if (ancienne !== nouvelle) this.setState({ value: enString(nouvelle) });
  }

  render() {
    const { value } = this.state;
    const {
      id,
      placeholder,
      style = {},
      disabled,
      append,
      className = "",
      appendClassName = "",
      onFocus,
      onChange = noOp,
      onBlur = noOp,
      testId,
      intl,
    } = this.props;

    const erreur =
      value !== "" &&
      !estUnMontantValide(value) &&
      !estUnMontantValide(value.replace(",", "."));

    const placeholderReel =
      placeholder === null || placeholder === undefined
        ? intl.formatMessage({ id: "general.montant" })
        : placeholder;

    return (
      <Fragment>
        <input
          type="text"
          id={id}
          ref={(element) => (this.input = element)}
          onChange={(e) => {
            this.setState({ value: enString(e.target.value) });
            this.signalerChangement(e.target.value, onChange);
          }}
          onBlur={() => {
            this.signalerChangement(value, onBlur);
          }}
          onFocus={onFocus}
          value={value.replace(".", ",")}
          placeholder={placeholderReel}
          className={
            className +
            " input-addon-field" +
            (erreur ? " erreur" : "") +
            (append ? " border-radius-append-none" : "")
          }
          style={{ textAlign: "right", ...style }}
          disabled={disabled}
          data-testid={testId}
        />
        {append && (
          <div className={`input-addon-append-item ${appendClassName}`}>
            {append}
          </div>
        )}
      </Fragment>
    );
  }

  signalerChangement(value, signal) {
    const laValeurEstModifiee = Number(value) !== this.props.value;
    if (!laValeurEstModifiee) return;

    const montantVide = value === "";
    if (montantVide) signal(null);

    if (estUnMontantValide(value)) {
      signal(Number(value));
      return;
    }

    const avecVirguleEnPoints = value.replace(",", ".");
    if (estUnMontantValide(avecVirguleEnPoints))
      signal(Number(avecVirguleEnPoints));
  }
}

InputMontant.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  focusOnMount: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  append: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  appendClassName: PropTypes.string,
  testId: PropTypes.string,
};

function noOp() {}

export default injectIntl(InputMontant);

export const estUnMontantValide = (valeur) => {
  if (isNaN(valeur)) return false;
  if (Number.parseFloat(valeur)) return true;
  if (valeur === 0 || valeur === "0") return true;

  return false;
};

function enString(value) {
  return value !== null && value !== undefined ? String(value) : "";
}

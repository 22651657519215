import { TYPE_DE_DEROGATION } from "../../reducer";
import { httpServiceCrm } from "../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../utils/odata/odata";
import { MAPPING_CRM_VERS_DOMAINE } from "../../../../../Connecteurs/Crm/codesGroupesOptions";

const { ASSURANCE_ESPAGNE } = TYPE_DE_DEROGATION;
const { statutDerogation } = MAPPING_CRM_VERS_DOMAINE;

export const derogationAssuranceEspagneAdapterCrm = {
  async getDerogation(idDerogation, { http = httpServiceCrm } = {}) {
    const derogationCrm = await http.get(
      odataQuery(`itl_derogations(${idDerogation})`, {
        $select: [
          "itl_derogationid",
          "itl_name",
          "itl_statutderogation",
          "itl_valeurdemandee",
          "itl_valeurorigine",
          "itl_commentairesdemandeur",
          "itl_commentairesdecideur",
          "itl_datedecision",
        ],
        $expand: [
          ["itl_FTconcernee", { $select: ["itl_name"] }],
          [
            "itl_derogateur",
            { $select: ["_itl_derogateur_value", "itl_name"] },
          ],
          ["createdby", { $select: ["systemuserid", "fullname"] }],
        ],
      })
    );

    return {
      id: idDerogation,
      type: ASSURANCE_ESPAGNE,
      nom: derogationCrm.itl_name,
      statut: statutDerogation[derogationCrm.itl_statutderogation],
      valeurDemandee: derogationCrm.itl_valeurdemandee,
      valeurOrigine: derogationCrm.itl_valeurorigine,
      motivation: derogationCrm.itl_commentairesdemandeur,
      dateDecision: derogationCrm.itl_datedecision,
      commentaireDecideur: derogationCrm.itl_commentairesdecideur,
      ft: { nom: derogationCrm.itl_FTconcernee.itl_name },
      derogateur: {
        nom: derogationCrm.itl_derogateur.itl_name,
        utilisateur: { id: derogationCrm.itl_derogateur._itl_derogateur_value },
      },
      auteur: {
        id: derogationCrm.createdby.systemuserid,
        nom: derogationCrm.createdby.fullname,
      },
    };
  },
};

import { connect } from "react-redux";
import Produits from "./Produits";
import { calculateur } from "../../../../reduxStore/rootReducer";
import {
  listeDesProduitsAchetes,
  listeDesRachatsDeContrats,
  listeDesRachatsDeMateriels,
} from "../../domaine/simulation/montantAFinancer/reducer";
import { TYPES_DE_PRODUITS } from "./UnProduit";

const mapStateToProps = (state) => {
  const { simulation } = calculateur(state);

  return {
    produits: [
      ...produitsAchetes(simulation),
      ...rachatsDeContrats(simulation),
      ...rachatsDeMateriels(simulation),
    ].sort((a, b) => a.timestamp - b.timestamp),
  };
};

function produitsAchetes({ montantAFinancer }) {
  const { PRODUIT_ACHETE } = TYPES_DE_PRODUITS;
  return listeDesProduitsAchetes(montantAFinancer).map(type(PRODUIT_ACHETE));
}

function rachatsDeContrats({ montantAFinancer }) {
  const { RACHAT_DE_CONTRAT } = TYPES_DE_PRODUITS;
  return listeDesRachatsDeContrats(montantAFinancer).map(
    type(RACHAT_DE_CONTRAT)
  );
}

function rachatsDeMateriels({ montantAFinancer }) {
  const { RACHAT_DE_MATERIEL } = TYPES_DE_PRODUITS;
  return listeDesRachatsDeMateriels(montantAFinancer).map(
    type(RACHAT_DE_MATERIEL)
  );
}

function type(type) {
  return (p) => ({ ...p, type });
}

export default connect(mapStateToProps)(Produits);

import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import "./ChoixDeLaMarqueItl.css";
import "./ChoixDeLaMarqueItl-mobile.css";
import { SelectMarqueItlActive } from "../SelectMarqueItlActive/SelectMarqueItlActive";

export function ChoixDeLaMarqueItl({ chargerLesMarques, marquesItl }) {
  useEffect(() => {
    chargerLesMarques();
  }, [chargerLesMarques]);

  return (
    <div className="choix-de-la-marque-itl">
      <h3>
        <FormattedMessage id="choix_de_marque_itl.choix" />
      </h3>
      <SelectMarqueItlActive marquesItl={marquesItl} />
    </div>
  );
}

ChoixDeLaMarqueItl.propTypes = {
  chargerLesMarques: PropTypes.func.isRequired,
};

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import "./CreationDeFt.css";
import "./CreationDeFt-mobile.css";
import Anomalies from "./Anomalies/Anomalies";
import BoutonAvecConfirmation from "../../../../Kit/BoutonAvecConfirmation/BoutonAvecConfirmation";
import ResumeDesDemandesDeDerogations from "./JustificationDesDerogations/ResumeDesDemandesDeDerogations";
import Identification from "./Identification.container";
import ErreursDeCreation from "./ResultatDeCreation/ErreursDeCreation.container";
import Loading from "./Loading.container";

function CreationDeFt(props) {
  const intl = useIntl();
  const {
    onSubmit,
    planDeFinancementManquant,
    isLoading,
    erreursDeInitialisation,
    erreursOrchestrationPlanDeFinancement,
    anomaliesBloquantes,
    anomaliesDerogeables,
    presenceDeAnomaliesDejaDerogees,
    tailleDesPiecesJointes,
    simulation,
  } = props;

  const creationImpossible =
    erreursDeInitialisation.length > 0 ||
    anomaliesBloquantes.length > 0 ||
    erreursOrchestrationPlanDeFinancement.length > 0 ||
    planDeFinancementManquant;

  const bloquantesPresentes = anomaliesBloquantes.length > 0;
  const derogeablesPresentes = anomaliesDerogeables.length > 0;

  const vaCreerDesDerogations =
    derogeablesPresentes || presenceDeAnomaliesDejaDerogees;

  const cle = `calculateur.creation_fiche_de_transmission`;
  const labelDuBoutonDeCreation = vaCreerDesDerogations
    ? intl.formatMessage({ id: `${cle}.creer_avec_derogation` })
    : intl.formatMessage({ id: `${cle}.creer` });

  const divClass = classNames("creation-de-ft", "qui-apparait", {
    "anomalies-bloquantes": bloquantesPresentes,
  });

  return (
    <div className={divClass}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="qui-apparait">
          <ErreursDeCreation />

          <div className="creation-de-ft-contenu">
            <Identification />
            <div className="actions">
              <BoutonAvecConfirmation
                label={labelDuBoutonDeCreation}
                onConfirm={() => onSubmit(intl)}
                disabled={creationImpossible}
                drawerButtonDisabled={
                  anomaliesDerogeables.some(
                    (anomalie) =>
                      typeof anomalie.motivation !== "string" ||
                      anomalie.motivation === ""
                  ) || tailleDesPiecesJointes.depasseLaLimite
                }
                boutonTestId="creer-ft"
              >
                {anomaliesDerogeables.length > 0 && (
                  <ResumeDesDemandesDeDerogations
                    demandes={anomaliesDerogeables}
                    labelDuBouton={labelDuBoutonDeCreation}
                    tailleDesPiecesJointes={tailleDesPiecesJointes}
                  />
                )}
              </BoutonAvecConfirmation>

              {bloquantesPresentes && (
                <Anomalies
                  anomalies={anomaliesBloquantes}
                  simulation={simulation}
                  label={texte("validation_impossible")}
                  className="mt-5"
                />
              )}

              {!bloquantesPresentes && derogeablesPresentes && (
                <Anomalies
                  anomalies={anomaliesDerogeables}
                  simulation={simulation}
                  label={texte("derogation_necessaire")}
                  boutonQuiRemue
                  className="mt-5"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

CreationDeFt.propTypes = {
  simulation: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  planDeFinancementManquant: PropTypes.bool.isRequired,
  erreursDeInitialisation: PropTypes.array.isRequired,
  erreursOrchestrationPlanDeFinancement: PropTypes.array.isRequired,
  anomaliesBloquantes: PropTypes.array.isRequired,
  anomaliesDerogeables: PropTypes.array.isRequired,
  presenceDeAnomaliesDejaDerogees: PropTypes.bool.isRequired,
  tailleDesPiecesJointes: PropTypes.object.isRequired,
};

export default CreationDeFt;

function texte(cle) {
  return (
    <FormattedMessage
      id={`calculateur.creation_fiche_de_transmission.${cle}`}
    />
  );
}

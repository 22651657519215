const SUJET = "Respuesta a su solicitud de autorización de VR";

const CORPS = `Buenos días, <br/><br/>
El broker {broker} ha dado una respuesta a la solicitud de autorización de VR enviada el día {dateDemande} para el proyecto "{projet}".<br/>
{decision}
<br/><br/>

Comentario :
<p style="background-color: #eeeeee;padding:10px;font-style: italic">
{commentaireBroker}
</p>

<br /><br />

Puede regresar al proyecto y crear una nueva ficha de transmisión con los VRs aceptados haciendo <a href="{urlFtForce}">click aquí</a>. <br/><br/>
Este aviso cancela la solicitud de derogación pero solamente riesgo podrá validar el valor final acordado.`;

const DECISION_STANDARD =
  'La VR que propone para el equipo "{familleMateriel}" de la marca "{marqueMateriel}" es {pourcentageAvis}, lo que representa {valeurAvis} (valor solicitado :{pourcentageDemande}, valor en el tabulador :{pourcentageOrigine}).';

const DECISION_REFUS_CATEGORIQUE =
  'La solicitud para el equipo "{familleMateriel}" de la marca "{marqueMateriel}" ha sido rechazada categóricamente.';

export const TEMPLATE_AVIS_SUR_DEROG_ES = {
  CORPS,
  SUJET,
  DECISION_STANDARD,
  DECISION_REFUS_CATEGORIQUE,
};

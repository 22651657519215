import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DerogationDeMarge.css";
import "./DerogationDeMarge-mobile.css";
import { FormattedMessage, useIntl } from "react-intl";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import BadgeErreur from "../../../../Kit/Badges/Erreur/BadgeErreur";
import { STATUT_DEROGATION } from "../../domaine/reducer";
import BadgeWarning from "../../../../Kit/Badges/Warning/BadgeWarning";
import Textarea from "../../../../Kit/Formulaires/Textarea";
import { IframeRapportFt } from "../Kit/IframeRapportFt";
import { DerogationsLiees } from "./DerogationsLiees";
import { LienVersAccueil } from "../Navigation/LienVersAccueil";
import { StatutDerogationSimple } from "../Kit/StatutDerogationSimple";
import { pourcentage } from "../../../../Kit/formatage";
import { RecalculDeMarge } from "./RecalculDeMarge";

const { ACCEPTEE, REFUSEE } = STATUT_DEROGATION;

function DerogationDeMarge(props) {
  const intl = useIntl();
  const {
    demande,
    onAccepter,
    onRefuser,
    isLoading,
    erreurs,
    decisionEstAutorisee,
    recalculerLaMarge,
    recalculDeMargeEstVisible,
  } = props;

  const [commentaireDecideur, setCommentaireDecideur] = useState(
    props.demande.commentaireDecideur || ""
  );

  return (
    <div className="derogation-de-marge">
      <LienVersAccueil />

      <h4>
        <FormattedMessage id="derogation_de_marge.titre" />
      </h4>

      <div>
        {erreurs.length > 0 && <BadgeErreur messages={erreurs} />}

        {isLoading && erreurs.length === 0 ? (
          <BarreDeChargement size="S" />
        ) : (
          <div>
            <div>
              <ul>
                {bulletPoint("derogation.nom", demande.nom)}
                {bulletPoint(
                  "derogation.fiche_de_transmission",
                  demande.ficheDeTransmission.nom
                )}
                {bulletPoint("derogation.motivation", demande.motivation)}
                {bulletPoint(
                  "derogation.teg",
                  pourcentage(demande.ficheDeTransmission.teg)
                )}
              </ul>
            </div>

            {demande.derogationsLiees.length > 0 && (
              <DerogationsLiees derogationsLiees={demande.derogationsLiees} />
            )}

            {recalculDeMargeEstVisible && (
              <RecalculDeMarge
                recalculerLaMarge={recalculerLaMarge}
                idFt={demande.ficheDeTransmission.id}
              />
            )}
            <StatutDerogationSimple derogation={demande} />

            <div className="le-commentaire">
              <Textarea
                className="w100"
                rows={4}
                placeholder={
                  demande.auteur &&
                  intl.formatMessage(
                    { id: "derogation.decision.commentaire" },
                    { auteur: demande.auteur.nom }
                  )
                }
                value={commentaireDecideur}
                onChange={(texte) => setCommentaireDecideur(texte)}
              />
            </div>

            <div className="les-boutons">
              <button
                data-testid="accepter-derogation"
                className={
                  "pure-button button-large" +
                  (decisionEstAutorisee ? " button-success" : "")
                }
                disabled={demande.statut === ACCEPTEE || !decisionEstAutorisee}
                onClick={() =>
                  onAccepter(demande, { commentaireDecideur }, intl)
                }
              >
                <FormattedMessage id="derogation.decision.accepter" />
              </button>
              <button
                data-testid="refuser-derogation"
                className={
                  "pure-button button-large" +
                  (decisionEstAutorisee ? " button-error" : "")
                }
                disabled={demande.statut === REFUSEE || !decisionEstAutorisee}
                onClick={() =>
                  onRefuser(demande, { commentaireDecideur }, intl)
                }
              >
                <FormattedMessage id="derogation.decision.refuser" />
              </button>
            </div>

            {!decisionEstAutorisee && (
              <div className="pure-u-1 mt-20">
                <BadgeWarning>
                  <FormattedMessage id="derogation_de_marge.decision.hors_prerogative" />
                </BadgeWarning>
              </div>
            )}

            {demande.urlRapport && (
              <IframeRapportFt urlRapport={demande.urlRapport} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export const DerogationDeMargePropType = PropTypes.shape({
  id: PropTypes.string,
  nom: PropTypes.string,
  auteur: PropTypes.shape({ nom: PropTypes.string }),
  statut: PropTypes.oneOf(Object.values(STATUT_DEROGATION)),
  dateDecision: PropTypes.string,
  urlRapport: PropTypes.string,
  motivation: PropTypes.string,
  commentaireDecideur: PropTypes.string,
});

DerogationDeMarge.propTypes = {
  demande: DerogationDeMargePropType.isRequired,
  onAccepter: PropTypes.func.isRequired,
  onRefuser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  erreurs: PropTypes.array.isRequired,
  decisionEstAutorisee: PropTypes.bool.isRequired,
  recalculerLaMarge: PropTypes.func.isRequired,
  recalculDeMargeEstVisible: PropTypes.bool.isRequired,
};

export default DerogationDeMarge;

function bulletPoint(idLabel, valeur) {
  return (
    <li>
      <b>
        <FormattedMessage id={idLabel} />
      </b>
      {" " + valeur}
    </li>
  );
}

import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Select from "../../../../Kit/Formulaires/Select";
import { guidEqual } from "../../../../utils/guidUtils";
import { UrlsForce } from "../../../../routing/Urls";

export function SelectMarqueItlActive({
  marquesItl,
  marcheChoisi,
  marqueItlChoisie,
}) {
  const marquesItlOptions = useMemo(
    () =>
      marquesItl.map((m) => ({
        value: { marche: m.marche, marqueItl: { id: m.id, nom: m.nom } },
        label: m.nom,
      })),
    [marquesItl]
  );

  const value = useMemo(
    () =>
      marcheChoisi && marqueItlChoisie
        ? marquesItlOptions.find(
            (option) =>
              guidEqual(option.value.marche.id, marcheChoisi.id) &&
              guidEqual(option.value.marqueItl.id, marqueItlChoisie.id)
          )
        : null,
    [marcheChoisi, marqueItlChoisie, marquesItlOptions]
  );

  const history = useHistory();
  const intl = useIntl();

  return (
    <div className="select-marque-itl-active">
      <Select
        options={marquesItlOptions}
        value={value}
        onChange={({ value }) => {
          history.push(
            UrlsForce.modeLight().entree(value.marche, value.marqueItl)
          );
        }}
        inputId="select-marque-itl"
        placeholder={intl.formatMessage({
          id: "choix_de_marque_itl.marques_disponibles",
        })}
      />
    </div>
  );
}

SelectMarqueItlActive.propTypes = {
  marquesItl: PropTypes.array.isRequired,
  marcheChoisi: PropTypes.object,
  marqueItlChoisie: PropTypes.object,
};

import { FormattedMessage } from "react-intl";
import React, { Fragment } from "react";
import { PAS_DE_PRIME_ASSURANCE } from "../../../../../domaine/simulation/assurance/france/reducer";
import FaCheck from "react-icons/lib/fa/check";
import { Money, Zero } from "../../../../../../../Money/money";
import { enEuros } from "../../../../../../../Kit/formatage";

export function AjouterOuRetirer({
  onAjouterAssurance,
  onRetirerAssurance,
  primeAssurance,
}) {
  return (
    <div className="pure-button-group">
      {Retirer(onRetirerAssurance, primeAssurance)}
      {Ajouter(onAjouterAssurance, primeAssurance)}
    </div>
  );
}

function Retirer(onRetirerAssurance, primeAssurance) {
  const estRetiree = primeAssurance === PAS_DE_PRIME_ASSURANCE;
  const actif = estRetiree ? "button-success" : "";
  return (
    <button
      className={`pointable pure-button ${actif}`}
      onClick={onRetirerAssurance}
    >
      {estRetiree ? (
        <Fragment>
          <FaCheck className="mr-5" /> {message("est_retiree")}
        </Fragment>
      ) : (
        message("retirer")
      )}
    </button>
  );
}

function Ajouter(onAjouterAssurance, primeAssurance) {
  const estAjoutee = primeAssurance !== PAS_DE_PRIME_ASSURANCE;
  const actif = estAjoutee ? "button-success" : "";
  return (
    <button
      className={`pointable pure-button ${actif}`}
      onClick={onAjouterAssurance}
    >
      {estAjoutee ? (
        <Fragment>
          <FaCheck className="mr-5" />
          {message("est_ajoutee")}{" "}
          {montantSiValorise(Money(primeAssurance.parLoyer.montant))}
        </Fragment>
      ) : (
        message("ajouter")
      )}
    </button>
  );
}

function message(suffixe) {
  return <FormattedMessage id={"calculateur.assurance." + suffixe} />;
}

function montantSiValorise(montantDeLaPrime) {
  return montantDeLaPrime.greaterThan(Zero)
    ? `(${enEuros(montantDeLaPrime.toObject(), "")})`
    : "";
}

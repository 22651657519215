import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DerogationDeTeg.css";
import "./DerogationDeTeg-mobile.css";
import { LienVersAccueil } from "../Navigation/LienVersAccueil";
import { FormattedMessage, useIntl } from "react-intl";
import Textarea from "../../../../Kit/Formulaires/Textarea";
import { STATUT_DEROGATION } from "../../domaine/reducer";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import BadgeErreur from "../../../../Kit/Badges/Erreur/BadgeErreur";
import { StatutDerogationSimple } from "../Kit/StatutDerogationSimple";
import { IframeRapportFt } from "../Kit/IframeRapportFt";

const { ACCEPTEE, REFUSEE } = STATUT_DEROGATION;

export function DerogationDeTeg({
  isLoading,
  erreurs,
  derogation,
  onAccepterDerogation,
  onRefuserDerogation,
}) {
  return (
    <div className="derogation-de-teg">
      <LienVersAccueil />

      <h4>
        <FormattedMessage id="derogation_de_teg.titre" />
      </h4>

      <div>
        {erreurs.length > 0 && <BadgeErreur messages={erreurs} />}

        {isLoading && erreurs.length === 0 ? (
          <BarreDeChargement size="S" />
        ) : (
          <div>
            <Informations derogation={derogation} />
            <StatutDerogationSimple derogation={derogation} />
            <Decision
              derogation={derogation}
              onAccepter={(commentaire) =>
                onAccepterDerogation(derogation, commentaire)
              }
              onRefuser={(commentaire) =>
                onRefuserDerogation(derogation, commentaire)
              }
            />
            <IframeRapportFt urlRapport={derogation.ft.urlRapport} />
          </div>
        )}
      </div>
    </div>
  );
}

function Informations({ derogation }) {
  return (
    <div>
      <ul>
        <li>
          <b>{Label("nom")}</b> {derogation.nom}
        </li>
        <li>
          <b>{Label("fiche_de_transmission")}</b> {derogation.ft.nom}
        </li>
        <li>
          <b>{Label("motivation")}</b> {derogation.motivation}
        </li>
      </ul>
    </div>
  );
}

function Decision({ derogation, onAccepter, onRefuser }) {
  const [commentaire, setCommentaire] = useState(
    derogation.commentaireDecideur || ""
  );
  const intl = useIntl();

  return (
    <div className="le-commentaire">
      <Textarea
        value={commentaire}
        onChange={setCommentaire}
        className="w100"
        rows={4}
        placeholder={intl.formatMessage(
          { id: "derogation.decision.commentaire" },
          { auteur: derogation.auteur.nom }
        )}
      />

      <div className="les-boutons">
        <button
          className="pure-button button-large button-success"
          onClick={() => onAccepter(commentaire)}
          disabled={derogation.statut === ACCEPTEE || commentaire === ""}
        >
          <FormattedMessage id="derogation.decision.accepter" />
        </button>

        <button
          className="pure-button button-large button-error"
          onClick={() => onRefuser(commentaire)}
          disabled={derogation.statut === REFUSEE || commentaire === ""}
        >
          <FormattedMessage id="derogation.decision.refuser" />
        </button>
      </div>
    </div>
  );
}

function Label(suffixe) {
  return <FormattedMessage id={`derogation.${suffixe}`} />;
}

DerogationDeTeg.propTypes = {
  derogation: PropTypes.object.isRequired,
  onAccepterDerogation: PropTypes.func.isRequired,
  onRefuserDerogation: PropTypes.func.isRequired,
};

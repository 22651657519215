import { odataQuery } from "../../../../utils/odata/odata";
import { httpServiceCrm } from "../../../../Connecteurs/Crm/httpServiceCrm";
import {
  ID_MARQUE_GENERIQUE,
  laMarqueParIdCrm,
  leProduitCatalogueParIdCrm,
} from "../simulation/catalogueProduits/reducer";
import {
  CODES_GROUPES_OPTIONS,
  MAPPING_CRM_VERS_DOMAINE,
} from "../../../../Connecteurs/Crm/codesGroupesOptions";
import { nombre, taux } from "../../../../Kit/formatage";
import { $obj, sontProches } from "../../../../Money/money";
import { formatyyyyMMdd } from "../../../../utils/dateUtils";
import {
  AUCUNE_OPTION,
  OPTION_ASSURANCE,
} from "../simulation/assurance/france/reducer";
import { TAUX_DE_VR_VIDE } from "../simulation/valeurResiduelle/selecteurs/taux.selecteurs";
import { getTauxDepuisPourcentage } from "../../../../Connecteurs/Crm/utils";
import { ID_PRODUIT_COMMISSION } from "../creationDeFt/creation/depenses/commission/commissions.adapter.crm";

const { typesDeMateriels } = CODES_GROUPES_OPTIONS;
const { PERTE_DE_RECETTES, GARANTIE_DES_LOYERS } = OPTION_ASSURANCE;
const ID_TYPE_DEROGATION_DE_VR = process.env.REACT_APP_ID_TYPE_DE_DEROGATION_VR;
const ID_TYPE_DEROGATION_DE_TEG =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_TEG;
const ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_ASSURANCE_ESPAGNE;
const ID_TYPE_SERVICE_MAINTENANCE =
  process.env.REACT_APP_ID_TYPE_SERVICE_MAINTENANCE;

const {
  modesDeReglement,
  periodicites,
  delaisDeReglement,
  termes,
  typesDeFinancement,
  statutDerogation,
} = MAPPING_CRM_VERS_DOMAINE;

export const ftPourImportAdapterCrm = {
  async getDepenses(
    idFt,
    stateCatalogueProduits,
    { httpService = httpServiceCrm } = {}
  ) {
    const depensesCrm = await httpService.get(
      odataQuery("itl_depensefts", {
        $select: [
          "itl_depenseftid",
          "itl_name",
          "itl_montantht",
          "itl_quantite",
          "_itl_familleid_value",
          "_itl_marqueid_value",
          "itl_marquenonreferencee",
          "itl_typematerielcode",
          "itl_isoccasion",
          "itl_annee",
          "itl_type_depense_force",
          "itl_date_encours_force",
          "itl_num_contrat_force",
          "itl_designationmatnonreference",
          "itl_vrdemandee_pourcent",
          "itl_vrdemandee_euros",
        ],
        $filter: [
          `_itl_fichetransmissionid_value eq '${idFt}' and _itl_familleid_value ne '${ID_PRODUIT_COMMISSION}'`,
        ],
      })
    );

    return depensesCrm.map((d) => {
      const itlMarqueidValue = d._itl_marqueid_value;
      return {
        id: d.itl_depenseftid,
        description: d.itl_name,
        montantUnitaire: $obj(d.itl_montantht),
        quantite: d.itl_quantite,
        idFamille: getIdFamille(stateCatalogueProduits, d._itl_familleid_value),
        idMarque: getIdMarque(stateCatalogueProduits, itlMarqueidValue),
        labelMarque: d.itl_marquenonreferencee || "",
        estOccasion: d.itl_isoccasion,
        annee: d.itl_annee,
        designationDuNonReference: d.itl_designationmatnonreference,
        codeTypeMateriel: d.itl_typematerielcode,
        codeTypeRachat: d.itl_type_depense_force,
        contrat: {
          numeroContrat: d.itl_num_contrat_force,
          dateEncours: formatyyyyMMdd(d.itl_date_encours_force),
        },
        vr: {
          taux:
            d.itl_vrdemandee_pourcent !== null
              ? d.itl_vrdemandee_pourcent / 100
              : TAUX_DE_VR_VIDE,
          montant: $obj(d.itl_vrdemandee_euros),
        },
      };
    });
  },

  async getReventes(idFt, { httpService = httpServiceCrm } = {}) {
    const reventesCrm = await httpService.get(
      odataQuery("itl_recettefts", {
        $select: ["itl_montantht", "_itl_depenseftid_value"],
        $filter: [`_itl_fichetransmissionid_value eq '${idFt}'`],
      })
    );
    return reventesCrm.map((r) => ({
      montantUnitaire: $obj(r.itl_montantht),
      idDepense: r._itl_depenseftid_value,
    }));
  },

  async getLoyers(idFt, { httpService = httpServiceCrm } = {}) {
    const loyersCrm = await httpService.get(
      odataQuery("itl_loyerfts", {
        $select: ["itl_nombreecheance", "itl_montant"],
        $filter: [`_itl_fichetransmissionid_value eq '${idFt}'`],
        $orderBy: [["itl_rangloyercode", "asc"]],
      })
    );
    return loyersCrm.map((l) => ({
      nombreEcheances: l.itl_nombreecheance,
      montant: $obj(l.itl_montant),
    }));
  },

  async getCommissions(idFt, { http = httpServiceCrm } = {}) {
    const commissionsCrm = await http.get(
      odataQuery("itl_depensefts", {
        $select: ["itl_depenseftid", "itl_montantht", "itl_name"],
        $filter: [
          `_itl_fichetransmissionid_value eq '${idFt}' and _itl_familleid_value eq '${ID_PRODUIT_COMMISSION}'`,
        ],
      })
    );
    return commissionsCrm.map((c) => ({
      id: c.itl_depenseftid,
      description: c.itl_name,
      montant: $obj(c.itl_montantht),
    }));
  },

  async getInfosFinancieres(idFt, { httpService = httpServiceCrm } = {}) {
    const infosCrm = await httpService.get(
      odataQuery(`itl_fichetransmissions(${idFt})`, {
        $select: [
          "itl_typecontratcode",
          "itl_margecalculeepourcentage",
          "itl_periodicitecode",
          "itl_conditionsreglementcode",
          "itl_conditionpaiementcode",
          "_itl_typefinancementid_value",
          "itl_vrenvisagee",
          "itl_teg",
        ],
      })
    );

    return {
      terme: termes[infosCrm.itl_typecontratcode],
      tauxDeMarge: getTauxDepuisPourcentage(
        infosCrm.itl_margecalculeepourcentage
      ),
      periodicite: periodicites[infosCrm.itl_periodicitecode],
      modeDeReglement: modesDeReglement[infosCrm.itl_conditionsreglementcode],
      delaiDeReglement: delaisDeReglement[infosCrm.itl_conditionpaiementcode],
      typeDeFinancement:
        typesDeFinancement[infosCrm._itl_typefinancementid_value.toUpperCase()],
      vr: { montant: $obj(infosCrm.itl_vrenvisagee) },
      teg: getTauxDepuisPourcentage(infosCrm.itl_teg),
    };
  },

  async getDerogationsDeVr(
    idFt,
    stateCatalogueProduits,
    { httpService = httpServiceCrm } = {}
  ) {
    const derogationsCrm = await httpService.get(
      odataQuery("itl_derogations", {
        $select: [
          "itl_valeurorigine",
          "itl_valeurdemandee",
          "itl_vrbroke",
          "itl_statutderogation",
          "_itl_derogateur_value",
          "itl_commentairesdemandeur",
          "itl_commentairesdecideur",
          "itl_commentairebroke",
          "itl_datecombroke",
          "_itl_broketitl_value",
        ],
        $expand: [
          [
            "itl_depenseftid",
            {
              $select: [
                "itl_depenseftid",
                "itl_montantht",
                "_itl_familleid_value",
                "_itl_marqueid_value",
              ],
            },
          ],
          ["itl_FTconcernee", { $select: ["_itl_typefinancementid_value"] }],
        ],
        $filter: [
          `_itl_ftconcernee_value eq '${idFt}'`,
          `_itl_typederogation_value eq '${ID_TYPE_DEROGATION_DE_VR}'`,
        ],
      })
    );

    return derogationsCrm.map((d) => {
      const idProduit = d.itl_depenseftid.itl_depenseftid;

      return {
        statut: statutDerogation[d.itl_statutderogation],
        valeurOrigine: taux(d.itl_valeurorigine),
        valeurDemandee: taux(d.itl_valeurdemandee),
        valeurBroker: d.itl_vrbroke ? d.itl_vrbroke / 100 : null,
        idDerogateur: d._itl_derogateur_value,
        idBroker: d._itl_broketitl_value,
        commentaires: {
          demandeur: d.itl_commentairesdemandeur,
          decideur: d.itl_commentairesdecideur,
          broker: d.itl_commentairebroke,
        },
        dateAvisBroker: formatyyyyMMdd(d.itl_datecombroke),
        produit: {
          id: idProduit,
          montantUnitaire: $obj(d.itl_depenseftid.itl_montantht),
          idFamille: getIdFamille(
            stateCatalogueProduits,
            d.itl_depenseftid._itl_familleid_value
          ),
          idMarque: getIdMarque(
            stateCatalogueProduits,
            d.itl_depenseftid._itl_marqueid_value
          ),
        },
        ft: {
          typeDeFinancement:
            typesDeFinancement[
              d.itl_FTconcernee._itl_typefinancementid_value.toUpperCase()
            ],
        },
      };
    });
  },

  async getDerogationsDeTeg(idFt, { http = httpServiceCrm } = {}) {
    const derogations = await http.get(
      odataQuery("itl_derogations", {
        $select: [
          "itl_valeurorigine",
          "itl_valeurdemandee",
          "itl_statutderogation",
        ],
        $filter: [
          `_itl_ftconcernee_value eq '${idFt}'`,
          `_itl_typederogation_value eq '${ID_TYPE_DEROGATION_DE_TEG}'`,
        ],
      })
    );

    return derogations.map((d) => ({
      statut: statutDerogation[d.itl_statutderogation],
      valeurOrigine: taux(d.itl_valeurorigine),
      valeurDemandee: taux(d.itl_valeurdemandee),
    }));
  },

  async getDerogationsAssuranceEspagne(idFt, { http = httpServiceCrm } = {}) {
    const derogations = await http.get(
      odataQuery("itl_derogations", {
        $select: [
          "itl_valeurorigine",
          "itl_valeurdemandee",
          "itl_statutderogation",
          "_itl_derogateur_value",
          "itl_commentairesdemandeur",
          "itl_commentairesdecideur",
        ],
        $filter: [
          `_itl_ftconcernee_value eq '${idFt}'`,
          `_itl_typederogation_value eq '${ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE}'`,
        ],
      })
    );

    return derogations.map((d) => ({
      statut: statutDerogation[d.itl_statutderogation],
      valeurOrigine: $obj(nombre(d.itl_valeurorigine)),
      valeurDemandee: $obj(nombre(d.itl_valeurdemandee)),
      derogateur: { id: d._itl_derogateur_value },
      commentaires: {
        demandeur: d.itl_commentairesdemandeur,
        decideur: d.itl_commentairesdecideur,
      },
    }));
  },

  async getAssuranceDeFt(idFt, { http = httpServiceCrm } = {}) {
    const sansAssurance = { estAjoutee: false };
    const avecAssurance = (montantPrimeAnnuelle, montantParLoyer) => ({
      estAjoutee: true,
      prime: {
        annuelle: { montant: $obj(montantPrimeAnnuelle) },
        parLoyer: { montant: $obj(montantParLoyer) },
      },
    });

    const [ft, loyers] = await Promise.all([
      http.get(
        odataQuery(`itl_fichetransmissions(${idFt})`, {
          $select: ["itl_montantassurance", "itl_commentaires"],
        })
      ),
      http.get(
        odataQuery("itl_loyerfts", {
          $select: [
            "itl_montantassuranceecheance",
            "itl_nombreecheance",
            "itl_montant",
          ],
          $filter: [`_itl_fichetransmissionid_value eq '${idFt}'`],
        })
      ),
    ]);

    if (estUnMontant(ft.itl_montantassurance))
      return avecAssurance(
        ft.itl_montantassurance,
        loyers[0].itl_montantassuranceecheance
      );

    if (ft.itl_commentaires === null) return sansAssurance;

    try {
      const infoAssurance = JSON.parse(ft.itl_commentaires);

      const infosAssuranceCompletes =
        infoAssurance.primeParLoyer !== undefined &&
        infoAssurance.primeAnnuelle !== undefined;

      if (!infosAssuranceCompletes) return sansAssurance;

      const montantsIncorrects =
        !estUnMontant(infoAssurance.primeParLoyer) ||
        !estUnMontant(infoAssurance.primeAnnuelle);

      if (montantsIncorrects) throw Error();

      return avecAssurance(
        infoAssurance.primeAnnuelle / 100,
        infoAssurance.primeParLoyer / 100
      );
    } catch (e) {
      const resume = `Impossible d'importer l'assurance de la FT ${idFt}`;
      const explique = `Les montants des primes sont censés être dans itl_commentaires.`;
      const valeur = `Valeur du champ pour la FT : ${ft.itl_commentaires}`;
      throw new Error(`${resume}. ${explique}. ${valeur}.`);
    }
  },

  async getDepensesAssurees(
    idFt,
    stateCatalogueProduits,
    { httpService = httpServiceCrm } = {}
  ) {
    const depensesAssureee = await httpService.get(
      odataQuery("itl_depensefts", {
        $select: [
          "itl_depenseftid",
          "_itl_familleid_value",
          "itl_montantht",
          "itl_quantite",
          "itl_isoccasion",
          "_itl_assuranceid_value",
        ],
        $expand: [
          [
            "itl_SelectionAssuranceId",
            { $select: ["itl_isgarantieloyers", "itl_isperteexploitation"] },
          ],
        ],
        $filter: [
          `_itl_fichetransmissionid_value eq '${idFt}'`,
          `itl_typematerielcode eq ${typesDeMateriels.materielAchete}`,
        ],
      })
    );

    return depensesAssureee.map((d) => ({
      materiel: {
        id: d.itl_depenseftid,
        idFamille: getIdFamille(stateCatalogueProduits, d._itl_familleid_value),
        famille: { crm: { id: d._itl_familleid_value } },
        montantUnitaire: $obj(d.itl_montantht),
        quantite: d.itl_quantite,
        estOccasion: d.itl_isoccasion,
      },
      estAssure: d.itl_SelectionAssuranceId !== null,
      option:
        d.itl_SelectionAssuranceId === null
          ? AUCUNE_OPTION
          : d.itl_SelectionAssuranceId.itl_isgarantieloyers
          ? GARANTIE_DES_LOYERS
          : d.itl_SelectionAssuranceId.itl_isperteexploitation
          ? PERTE_DE_RECETTES
          : AUCUNE_OPTION,
    }));
  },

  async getMaintenance(idFt, { http = httpServiceCrm } = {}) {
    const [[maintenanceCrm], loyersCrm] = await Promise.all([
      http.get(
        odataQuery("itl_services", {
          $select: [
            "itl_montanttotalservice",
            "itl_dureetotale",
            "itl_echeancedemarrage",
          ],
          $filter: [
            `_itl_fichetransmissionid_value eq '${idFt}'`,
            `_itl_typeserviceid_value eq '${ID_TYPE_SERVICE_MAINTENANCE}'`,
          ],
        })
      ),
      http.get(
        odataQuery("itl_loyerfts", {
          $select: ["itl_montant", "itl_montanthtfullservice"],
          $filter: [`_itl_fichetransmissionid_value eq '${idFt}'`],
        })
      ),
    ]);

    if (!maintenanceCrm) return { estActivee: false };

    return {
      estActivee: true,
      dureeEnEcheances: maintenanceCrm.itl_dureetotale,
      nbEcheancesDeDecalage: maintenanceCrm.itl_echeancedemarrage - 1,
      montantTotal: $obj(maintenanceCrm.itl_montanttotalservice),
      avecEquilibrage: equilibrageEstPresent(loyersCrm),
    };
  },
};

function estUnMontant(valeur) {
  if (valeur === null) return false;
  return !Number.isNaN(Number(valeur));
}

export function equilibrageEstPresent(paliersCrm) {
  if (paliersCrm.length === 1) return false;
  if (paliersCrm.length > 2) return false;

  const [a, b] = paliersCrm;

  const financierDifferent = !sontEgaux(a.itl_montant, b.itl_montant);
  const fullServiceEgaux =
    sontEgaux(a.itl_montanthtfullservice, b.itl_montanthtfullservice) &&
    sontEgaux(a.itl_montant, a.itl_montanthtfullservice);

  return financierDifferent && fullServiceEgaux;

  function sontEgaux(a, b) {
    // On compare avec une tolérance d'1 € pour éviter les soucis d'arrondis
    return sontProches(a, b, 1_000);
  }
}

function getIdFamille(stateCatalogueProduits, idFamilleCrm) {
  return leProduitCatalogueParIdCrm(stateCatalogueProduits, idFamilleCrm).id;
}

function getIdMarque(stateCatalogueProduits, idMarqueCrm) {
  return idMarqueCrm
    ? laMarqueParIdCrm(stateCatalogueProduits, idMarqueCrm).id
    : ID_MARQUE_GENERIQUE;
}

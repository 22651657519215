import { listeDesProduitsAchetes } from "../../simulation/montantAFinancer/reducer";
import {
  estRefuseeCategoriquement,
  PAS_DE_DEROGATION,
  selectDerogationDeVr,
} from "../../simulation/derogations/reducer";
import { selectInvaliditesDerogationDeVr } from "../../simulation/derogations/vr/invalidites/selectInvaliditesDerogationDeVr";
import { AnomalieVrRefuseeCategoriquement } from "../Anomalies";

export function verifierLesVrRefuseesCategoriquement(simulation) {
  const { montantAFinancer, derogations } = simulation;

  return listeDesProduitsAchetes(montantAFinancer)
    .map((produit) => selectDerogationDeVr(derogations, produit))
    .filter((derogationDeVr) => laDerogationExiste(derogationDeVr))
    .filter((derogationDeVr) =>
      laDerogationEstToujoursValide(derogationDeVr, simulation)
    )
    .filter((derogationDeVr) => estRefuseeCategoriquement(derogationDeVr))
    .map(() => AnomalieVrRefuseeCategoriquement());
}

const laDerogationExiste = (derogationDeVr) =>
  derogationDeVr !== PAS_DE_DEROGATION;

const laDerogationEstToujoursValide = (derogationDeVr, simulation) =>
  selectInvaliditesDerogationDeVr(derogationDeVr, simulation).length === 0;

import {
  ACTIVER_LA_VR_UN_LOYER,
  APPLIQUER_LE_TAUX_DE_VR_GRILLE,
  CHANGER_TYPE_DE_FINANCEMENT,
  CHARGER_LES_GRILLES_DE_VR_SUCCES,
  DEMANDER_TAUX_DE_VR,
  DESACTIVER_LA_VR_UN_LOYER,
  DONNER_LES_VR_GRILLES,
} from "./actions";
import { SUPPRIMER_PRODUIT } from "../montantAFinancer/actions";
import { CHARGER_PROFIL_UTILISATEUR_SUCCES } from "../profilUtilisateur/actions";

export const TYPE_DE_FINANCEMENT = { LLD: "LLD", CB: "CB" };
const { LLD } = TYPE_DE_FINANCEMENT;

export const GRILLE_DE_VR_VIDE = {};

export const AUCUNE_VR_CHARGEE = [];

const STATE_INITIAL = {
  typeDeFinancement: LLD,
  vrs: AUCUNE_VR_CHARGEE,
  tauxDemandes: { parIdProduit: {}, estUneDemandeDeVrUnLoyer: false },
  tauxGrille: { parIdProduit: {} },
  marcheUtilisateur: null,
  vrUnLoyerEstActivee: false,
};

export default function reducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CHARGER_LES_GRILLES_DE_VR_SUCCES:
      return { ...state, vrs: action.lesVr };

    case CHARGER_PROFIL_UTILISATEUR_SUCCES:
      return { ...state, marcheUtilisateur: action.profil.marcheUtilisateur };

    case DONNER_LES_VR_GRILLES:
      return {
        ...state,
        tauxGrille: {
          parIdProduit: action.vrs.reduce(
            (taux, { materiel, tauxDeVr, tauxSeuilDerogation }) => ({
              ...taux,
              [materiel.id]: { tauxDeVr, tauxSeuilDerogation },
            }),
            {}
          ),
        },
      };

    case DEMANDER_TAUX_DE_VR: {
      return {
        ...state,
        tauxDemandes: {
          parIdProduit: action.demandes.reduce(
            (resultat, demande) => ({
              ...resultat,
              [demande.materiel.id]: demande.taux,
            }),
            state.tauxDemandes.parIdProduit
          ),
          estUneDemandeDeVrUnLoyer: action.estUneDemandeDeVrUnLoyer,
        },
      };
    }

    case SUPPRIMER_PRODUIT:
    case APPLIQUER_LE_TAUX_DE_VR_GRILLE: {
      const tauxDemandesAJour = { ...state.tauxDemandes.parIdProduit };
      delete tauxDemandesAJour[action.idProduit];

      return {
        ...state,
        tauxDemandes: {
          parIdProduit: tauxDemandesAJour,
          estUneDemandeDeVrUnLoyer: false,
        },
      };
    }

    case ACTIVER_LA_VR_UN_LOYER:
      return { ...state, vrUnLoyerEstActivee: true };

    case DESACTIVER_LA_VR_UN_LOYER:
      return {
        ...state,
        tauxDemandes: STATE_INITIAL.tauxDemandes,
        vrUnLoyerEstActivee: false,
      };

    case CHANGER_TYPE_DE_FINANCEMENT:
      return { ...state, typeDeFinancement: action.typeDeFinancement };

    default:
      return state;
  }
}

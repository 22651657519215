import {
  CHARGER_DEROGATEURS_DE_TEG_SUCCES,
  CHARGER_RESUMES_DE_DEROGATIONS_SUCCES,
  CHARGER_UNE_DEROGATION_DETAILLEE_SUCCES,
} from "./actions";
import { indexerParCle } from "../../../utils/objetUtils";
import { guidEqual } from "../../../utils/guidUtils";

const STATE_INITIAL = {
  derogations: {
    resumees: { parId: {} },
    detaillees: { parId: {} },
  },
  derogateursDeTeg: [],
};

export const STATUT_DEROGATION = {
  A_DECIDER: "A_DECIDER",
  ACCEPTEE: "ACCEPTEE",
  REFUSEE: "REFUSEE",
  ACCORD_PARTIEL: "ACCORD_PARTIEL",
};
const { A_DECIDER } = STATUT_DEROGATION;

export const TYPE_DE_DEROGATION = {
  MARGE: "MARGE",
  VR: "VR",
  TEG: "TEG",
  ASSURANCE_ESPAGNE: "ASSURANCE_ESPAGNE",
};

function derogationsReducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CHARGER_RESUMES_DE_DEROGATIONS_SUCCES:
      return {
        ...state,
        derogations: {
          ...state.derogations,
          resumees: {
            parId: indexerParCle(
              action.derogations,
              "id",
              state.derogations.resumees.parId
            ),
          },
        },
      };

    case CHARGER_UNE_DEROGATION_DETAILLEE_SUCCES:
      return {
        ...state,
        derogations: {
          ...state.derogations,
          detaillees: {
            parId: {
              ...state.derogations.detaillees.parId,
              [action.derogation.id]: action.derogation,
            },
          },
        },
      };

    case CHARGER_DEROGATEURS_DE_TEG_SUCCES:
      return { ...state, derogateursDeTeg: action.derogateursDeTeg };

    default:
      return state;
  }
}

export default derogationsReducer;

export const selectDerogationDetaillee = (state, idDerogation) =>
  state.derogations.detaillees.parId[idDerogation];

export const selectDerogateursDeTeg = (state) => state.derogateursDeTeg;

export const selectResumeDeDerogationsAStatuer = (state) =>
  Object.values(state.derogations.resumees.parId).filter(
    ({ statut }) => statut === A_DECIDER
  );

export const selectResumeDeDerogationsStatuees = (state) =>
  Object.values(state.derogations.resumees.parId).filter(
    ({ statut }) => statut !== A_DECIDER
  );

export function selectDerogationsDeVrParProjet(state, idProjet) {
  const derogationsDeVrDuProjet = Object.values(
    state.derogations.detaillees.parId
  )
    .filter((d) => d.type === TYPE_DE_DEROGATION.VR)
    .filter((d) => guidEqual(d.projet.id, idProjet));

  return { derogations: { deVr: derogationsDeVrDuProjet } };
}

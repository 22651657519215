import Periodicite from "./Periodicite";
import { connect } from "react-redux";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { changerLaPeriodicite } from "../../domaine/simulation/modeDeCalcul/actions";
import { orchestrerLePlanDeFinancement } from "../../domaine/planDeFinancement/actions";

const mapStateToProps = (state) => ({
  value: calculateur(state).simulation.modeDeCalcul.periodicite,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (periodicite) => {
    dispatch(changerLaPeriodicite(periodicite));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Periodicite);

import { httpServiceCrm } from "../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../utils/odata/odata";
import {
  CODES_GROUPES_OPTIONS,
  MAPPING_CRM_VERS_DOMAINE,
} from "../../../../../Connecteurs/Crm/codesGroupesOptions";
import { TYPE_DE_DEROGATION } from "../../reducer";
import { $obj } from "../../../../../Money/money";
import { formatyyyyMMdd } from "../../../../../utils/dateUtils";
import { taux } from "../../../../../Kit/formatage";
import { getTauxDepuisPourcentage } from "../../../../../Connecteurs/Crm/utils";
import { ftPourImportAdapterCrm } from "../../../../Calculateur/domaine/importDeFt/ftPourImport.adapter.crm";
import {
  httpK3000,
  montantK3000,
} from "../../../../../Connecteurs/K3000/httpK3000";
import {
  LABELS_PERIODICITE_K3000,
  LABELS_TERME_K3000,
} from "../../../../../Connecteurs/K3000/labelsK3000";
import { transormerToutesLesDepenses } from "../../../../Calculateur/domaine/importDeFt/importService";
import montantAFinancerReducer from "../../../../Calculateur/domaine/simulation/montantAFinancer/reducer";
import {
  montantDesDepenses,
  valeurDuMontantAFinancerAvecMargeZero,
} from "../../../../Calculateur/domaine/simulation/montantAFinancer/montants";
import { calculerTauxPrecis } from "../../../../Calculateur/domaine/simulation/montantAFinancer/marge.adapter.K3000";

const { MARGE } = TYPE_DE_DEROGATION;
const { statutDerogation } = MAPPING_CRM_VERS_DOMAINE;

export const derogationDeMargeAdapterCrm = {
  async getDerogation(idDerogation, { httpService = httpServiceCrm } = {}) {
    const derogation = await httpService.get(requeteDerogation(idDerogation));
    const depensesDeLaFtPrincipale = await httpService.get(
      requeteDepensesDeFtPrincipale(derogation)
    );

    function pourTauxSuperieurOuEgal(derogationLiee) {
      const tauxDeReference = taux(derogation.itl_valeurdemandee);
      return taux(derogationLiee.itl_valeurdemandee) >= tauxDeReference;
    }

    const derogationsLiees = await httpService
      .get(requeteDerogationsLiees(derogation))
      .then((derogationsLiees) =>
        derogationsLiees.filter(pourTauxSuperieurOuEgal)
      );

    const ftDesDerogationsLiees = await Promise.all(
      derogationsLiees.map((d) =>
        httpService.get(requeteFtDeDerogationLiee(d._itl_ftconcernee_value))
      )
    );

    return {
      id: derogation.itl_derogationid,
      type: MARGE,
      nom: derogation.itl_name,
      statut: statutDerogation[derogation.itl_statutderogation],
      dateDecision: derogation.itl_datedecision,
      valeurDemandee: derogation.itl_valeurdemandee,
      motivation: derogation.itl_commentairesdemandeur,
      commentaireDecideur: derogation.itl_commentairesdecideur,
      auteur: {
        id: derogation.createdby.systemuserid,
        nom: derogation.createdby.fullname,
        directeur: { id: derogation.createdby._parentsystemuserid_value },
      },
      derogateur: { nom: derogation.itl_derogateur.itl_name },
      ficheDeTransmission: {
        id: derogation.itl_FTconcernee.itl_fichetransmissionid,
        nom: derogation.itl_FTconcernee.itl_name,
        actualisationEstimee: $obj(
          derogation.itl_FTconcernee.itl_actualisationestimee
        ),
        dureeEnMois: derogation.itl_FTconcernee.itl_dureeenmois,
        depenses: depensesDeLaFtPrincipale.map((d) => d.itl_name),
        teg: getTauxDepuisPourcentage(derogation.itl_FTconcernee.itl_teg),
      },
      projet: {
        marche: { id: derogation.itl_projetconcerne._itl_marcheid_value },
        marqueItl: { id: derogation.itl_projetconcerne._itl_marqueitlid_value },
      },
      derogationsLiees: derogationsLiees.map((derogLiee) => {
        const ftDeLaDerogation = ftDesDerogationsLiees.find(
          (ft) =>
            ft.itl_fichetransmissionid === derogLiee._itl_ftconcernee_value
        );

        const {
          itl_itl_fichetransmission_itl_depenseft_FicheTransmissionId: depensesFt,
        } = ftDeLaDerogation;

        return {
          idDerogation: derogLiee.itl_derogationid,
          ficheDeTransmission: {
            nom: derogLiee.itl_FTconcernee.itl_name,
            actualisationEstimee: $obj(
              derogLiee.itl_FTconcernee.itl_actualisationestimee
            ),
            dureeEnMois: derogLiee.itl_FTconcernee.itl_dureeenmois,
            depenses: depensesFt.map((depense) => depense.itl_name),
          },
        };
      }),
    };
  },

  async prendreDecision(
    idDerogation,
    decision,
    { httpService = httpServiceCrm } = {}
  ) {
    const { statutDeDerogation } = CODES_GROUPES_OPTIONS;

    const commentaire = `${decision.commentaireDecideur}${
      decision.commentaireDerogationsLiees
        ? `\n${decision.commentaireDerogationsLiees}`
        : ""
    }`;

    await httpService.patch(`itl_derogations(${idDerogation})`, {
      itl_statutderogation: statutDeDerogation.get(decision.statut),
      itl_datedecision: formatyyyyMMdd(decision.dateDecision),
      itl_commentairesdecideur: commentaire,
      "itl_derogateur@odata.bind": `/itl_derogateurs(${decision.derogateur.id})`,
    });
  },

  async recalculerMargeDeFt(
    idFt,
    tegManuel,
    {
      _httpK3000 = httpK3000,
      ftPourImportAdapter = ftPourImportAdapterCrm,
    } = {}
  ) {
    const [
      loyers,
      infosFinancieres,
      depenses,
      reventes,
      commissions,
    ] = await Promise.all([
      ftPourImportAdapter.getLoyers(idFt),
      ftPourImportAdapter.getInfosFinancieres(idFt),
      ftPourImportAdapter.getDepenses(idFt),
      ftPourImportAdapter.getReventes(idFt),
      ftPourImportAdapter.getCommissions(idFt),
    ]);

    const {
      montantAFinancerAvecMargeZero,
      montantDepenses,
    } = getMontantsPourRecalculDeMarge(depenses, reventes, commissions);

    const marge = await _httpK3000.post(
      "/api/v1/financier/calcul/montant-marge",
      {
        listeDePaliersDeLoyer: loyers.map((l) => ({
          nombreDEcheances: l.nombreEcheances,
          montant: montantK3000(l.montant),
        })),
        montantInvestissement: montantK3000(montantAFinancerAvecMargeZero),
        montantValeurResiduelle: montantK3000(infosFinancieres.vr.montant),
        terme: LABELS_TERME_K3000[infosFinancieres.terme],
        typeDePeriodicite:
          LABELS_PERIODICITE_K3000[infosFinancieres.periodicite],
        tegAnnuel: tegManuel.toString(),
      }
    );

    return {
      montant: $obj(marge.montantMarge),
      taux: calculerTauxPrecis(marge.montantMarge, montantDepenses),
    };
  },
};

function requeteDerogation(idDerogation) {
  const champsDirects = [
    "itl_derogationid",
    "itl_name",
    "itl_statutderogation",
    "itl_datedecision",
    "itl_valeurdemandee",
    "itl_commentairesdemandeur",
    "itl_commentairesdecideur",
    "_itl_projetconcerne_value",
    "_itl_typederogation_value",
  ];
  const ft = [
    "itl_FTconcernee",
    {
      $select: [
        "itl_fichetransmissionid",
        "itl_name",
        "itl_actualisationestimee",
        "itl_dureeenmois",
        "itl_teg",
      ],
    },
  ];
  const projet = [
    "itl_projetconcerne",
    { $select: ["_itl_marcheid_value", "_itl_marqueitlid_value"] },
  ];
  const auteur = [
    "createdby",
    {
      $select: ["systemuserid", "fullname", "_parentsystemuserid_value"],
    },
  ];
  const derogateur = ["itl_derogateur", { $select: ["itl_name"] }];

  return odataQuery(`itl_derogations(${idDerogation})`, {
    $select: champsDirects,
    $expand: [ft, projet, auteur, derogateur],
  });
}

function requeteDepensesDeFtPrincipale(derogation) {
  return odataQuery("itl_depensefts", {
    $select: ["itl_name"],
    $filter: [
      `_itl_fichetransmissionid_value eq ${derogation.itl_FTconcernee.itl_fichetransmissionid}`,
    ],
  });
}

function requeteDerogationsLiees(derogation) {
  const {
    itl_statutderogation,
    itl_derogationid,
    _itl_projetconcerne_value,
    _itl_typederogation_value,
  } = derogation;

  const memeTypeDeDerogation = `_itl_typederogation_value eq ${_itl_typederogation_value}`;
  const memeStatutDeDerogation = `itl_statutderogation eq ${itl_statutderogation}`;
  const pasLaDerogationElleMeme = `itl_derogationid ne ${itl_derogationid}`;
  const memeProjet = `_itl_projetconcerne_value eq ${_itl_projetconcerne_value}`;

  const ftLiee = [
    "itl_FTconcernee",
    { $select: ["itl_name", "itl_actualisationestimee", "itl_dureeenmois"] },
  ];

  return odataQuery("itl_derogations", {
    $select: [
      "itl_derogationid",
      "itl_valeurdemandee",
      "_itl_ftconcernee_value",
    ],
    $filter: [
      memeTypeDeDerogation,
      memeStatutDeDerogation,
      pasLaDerogationElleMeme,
      memeProjet,
    ],
    $expand: [ftLiee],
  });
}

function requeteFtDeDerogationLiee(idFt) {
  const depenses = [
    "itl_itl_fichetransmission_itl_depenseft_FicheTransmissionId",
    { $select: ["itl_name"] },
  ];
  return odataQuery(`itl_fichetransmissions(${idFt})`, {
    $select: ["itl_fichetransmissionid"],
    $expand: [depenses],
  });
}

function getMontantsPourRecalculDeMarge(depenses, reventes, commissions) {
  const ft = { depenses, reventes, commissions };
  const toutesLesDepenses = transormerToutesLesDepenses(ft);
  const stateMontantAFinancer = toutesLesDepenses.reduce(
    (state, a) => montantAFinancerReducer(state, a),
    undefined
  );

  return {
    montantAFinancerAvecMargeZero: valeurDuMontantAFinancerAvecMargeZero(
      stateMontantAFinancer
    ).toObject(),
    montantDepenses: montantDesDepenses(stateMontantAFinancer).toObject(),
  };
}

import { httpServiceCrm } from "../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../utils/odata/odata";
import { MAPPING_CRM_VERS_DOMAINE } from "../../../../Connecteurs/Crm/codesGroupesOptions";

const { statutDerogation } = MAPPING_CRM_VERS_DOMAINE;

export const detailPourNotificationAdapterCrm = {
  async getDetailPourNotification(
    idDerogation,
    { http = httpServiceCrm } = {}
  ) {
    const derogation = await http.get(
      odataQuery(`itl_derogations(${idDerogation})`, {
        $select: [
          "itl_statutderogation",
          "itl_datedemande",
          "itl_commentairesdecideur",
        ],
        $expand: [
          [
            "itl_projetconcerne",
            { $select: ["itl_projetid", "itl_projectname"] },
          ],
          ["itl_FTconcernee", { $select: ["itl_fichetransmissionid"] }],
          ["itl_derogateur", { $select: ["itl_name"] }],
          ["createdby", { $select: ["systemuserid"] }],
        ],
      })
    );

    const ft = await http.get(
      odataQuery(
        `itl_fichetransmissions(${
          derogation.itl_FTconcernee.itl_fichetransmissionid
        })`,
        { $expand: [["itl_CompteId", { $select: ["name"] }]] }
      )
    );

    return {
      statut: statutDerogation[derogation.itl_statutderogation],
      dateDemande: derogation.itl_datedemande,
      derogateur: {
        nom: derogation.itl_derogateur.itl_name,
        commentaire: derogation.itl_commentairesdecideur,
      },
      projet: {
        id: derogation.itl_projetconcerne.itl_projetid,
        nom: derogation.itl_projetconcerne.itl_projectname,
      },
      ft: { id: derogation.itl_FTconcernee.itl_fichetransmissionid },
      client: { nom: ft.itl_CompteId.name },
      demandeur: { id: derogation.createdby.systemuserid },
    };
  },
};

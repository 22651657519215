import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, useIntl } from "react-intl";
import ReactTable from "react-table";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import BadgeErreur from "../../../../Kit/Badges/Erreur/BadgeErreur";
import { enEuros } from "../../../../Kit/formatage";
import "react-table/react-table.css";
import "./ListeDesDerogations.desktop.css";
import { Money } from "../../../../Money/money";
import { Tab, Tabs } from "../../../../Kit/Tabs/Tabs";
import {
  derogationEstVieille,
  getLabelStatut,
  jour,
  msg,
  titres,
} from "./Common";

class ListeDesDerogationsDesktop extends Component {
  componentDidMount() {
    this.props.initialisation();
  }

  render() {
    const {
      isLoading,
      demandesAssigneesAuConnecte,
      demandesAssigneesEquipe,
      demandesStatuees,
      onClickVoirDetailsDemande,
      erreurs,
      utilisateurEstBroker,
      intl,
    } = this.props;

    return (
      <div className="derogations-desktop">
        {erreurs.length > 0 && <BadgeErreur messages={erreurs} />}

        <div>
          {isLoading ? (
            <BarreDeChargement size="S" />
          ) : (
            <div>
              <Tabs>
                <Tab
                  label={titres(intl).enAttente(demandesAssigneesAuConnecte)}
                >
                  <TableauDeNonStatuees
                    derogations={demandesAssigneesAuConnecte}
                    utilisateurEstBroker={utilisateurEstBroker}
                    onDerogationClick={(idDerogation) =>
                      onClickVoirDetailsDemande(idDerogation)
                    }
                  />
                </Tab>

                {demandesAssigneesEquipe.length > 0 && (
                  <Tab
                    label={titres(intl).assigneesEquipe(
                      demandesAssigneesEquipe
                    )}
                  >
                    <TableauDeNonStatuees
                      derogations={demandesAssigneesEquipe}
                      utilisateurEstBroker={utilisateurEstBroker}
                      onDerogationClick={(idDerogation) =>
                        onClickVoirDetailsDemande(idDerogation)
                      }
                    />
                  </Tab>
                )}

                <Tab label={titres(intl).statuees(demandesStatuees)}>
                  <TableauDeStatuees
                    derogations={demandesStatuees}
                    onDerogationClick={(idDerogation) =>
                      onClickVoirDetailsDemande(idDerogation)
                    }
                  />
                </Tab>
              </Tabs>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function TableauDeNonStatuees({
  derogations,
  onDerogationClick,
  utilisateurEstBroker,
}) {
  const intl = useIntl();
  return (
    <ReactTable
      data={derogations}
      columns={[
        COLONNES(intl).auteur,
        COLONNES(intl).nomFt,
        utilisateurEstBroker === false
          ? COLONNES(intl).actualisationEstimee
          : null,
        COLONNES(intl).nomDeLaDerogation,
        COLONNES(intl).dateDeLaDemande,
        COLONNES(intl).valeurDemandee,
        COLONNES(intl).dateAvisBroker,
      ].filter((c) => c !== null)}
      defaultPageSize={25}
      defaultSorted={[{ ...COLONNES(intl).dateDeLaDemande, asc: true }]}
      previousText={intl.formatMessage({ id: "pagination.precedente" })}
      nextText={intl.formatMessage({ id: "pagination.suivante" })}
      noDataText={intl.formatMessage({ id: "derogations.aucune_derogation" })}
      ofText={intl.formatMessage({ id: "pagination.de" })}
      rowsText={intl.formatMessage({ id: "pagination.lignes" })}
      className="-striped -highlight"
      getTdProps={(state, rowInfo) => ({
        onClick: () => {
          return rowInfo ? onDerogationClick(rowInfo.original.id) : null;
        },
      })}
      getTrProps={(state, rowInfo) => ({
        className: derogationEstVieille(rowInfo?.original.dateDemande)
          ? "vieille-derogation"
          : "",
      })}
    />
  );
}

function TableauDeStatuees({ derogations, onDerogationClick }) {
  const intl = useIntl();
  return (
    <ReactTable
      data={derogations}
      columns={[
        COLONNES(intl).auteur,
        COLONNES(intl).nomFt,
        COLONNES(intl).actualisationEstimee,
        COLONNES(intl).nomDeLaDerogation,
        COLONNES(intl).valeurDemandee,
        COLONNES(intl).dateDeLaDemande,
        COLONNES(intl).dateDeDecision,
        COLONNES(intl).statutDeDerogation,
      ]}
      filterable
      defaultFilterMethod={(filter, row) => {
        return row[filter.id] !== undefined
          ? String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          : false;
      }}
      defaultPageSize={25}
      defaultSorted={[{ ...COLONNES(intl).dateDeLaDemande, asc: true }]}
      previousText={intl.formatMessage({ id: "pagination.precedente" })}
      nextText={intl.formatMessage({ id: "pagination.suivante" })}
      noDataText={intl.formatMessage({ id: "derogations.aucune_derogation" })}
      ofText={intl.formatMessage({ id: "pagination.de" })}
      rowsText={intl.formatMessage({ id: "pagination.lignes" })}
      className="-striped -highlight"
      getTdProps={(state, rowInfo) => ({
        onClick: () => {
          return rowInfo ? onDerogationClick(rowInfo.original.id) : null;
        },
      })}
    />
  );
}

function COLONNES(intl) {
  return {
    auteur: { Header: msg("auteur", intl), accessor: "auteur.nom" },
    nomFt: { Header: msg("ft", intl), accessor: "ficheDeTransmission.nom" },
    nomDeLaDerogation: { Header: msg("derogation", intl), accessor: "nom" },
    actualisationEstimee: {
      Header: msg("actualisation_estimee", intl),
      id: "actualisationEstimee",
      sortMethod: (a, b) => (Money(a).greaterThan(Money(b)) ? 1 : -1),
      accessor: (d) => d.ficheDeTransmission.actualisationEstimee,
      maxWidth: 160,
      Cell: (row) => (
        <div style={{ textAlign: "right" }}>{enEuros(row.value)}</div>
      ),
    },
    dateDeLaDemande: {
      Header: msg("date_de_demande", intl),
      id: "dateDemande",
      Cell: (row) => (
        <span>
          {derogationEstVieille(row.value) ? <>⚠️ </> : ""}
          {jour(row.value)}
        </span>
      ),
      accessor: "dateDemande",
      maxWidth: 130,
    },
    valeurDemandee: {
      Header: msg("valeur_demandee", intl),
      id: "valeurDemandee",
      accessor: "valeurDemandee",
      maxWidth: 130,
      Cell: (row) => <div style={{ textAlign: "right" }}> {row.value}</div>,
    },
    dateDeDecision: {
      Header: msg("date_de_decision", intl),
      id: "dateDecision",
      Cell: (row) => <span>{jour(row.value)}</span>,
      accessor: "dateDecision",
      maxWidth: 130,
    },
    statutDeDerogation: {
      Header: msg("statut", intl),
      id: "statut",
      accessor: (d) => getLabelStatut(intl)[d.statut],
      Cell: (row) => (
        <span style={{ textTransform: "capitalize" }}>{row.value}</span>
      ),
      maxWidth: 110,
    },
    dateAvisBroker: {
      Header: msg("date_avis_broker", intl),
      Cell: (row) => <span>{jour(row.value)}</span>,
      accessor: "dateAvisBroker",
      maxWidth: 130,
    },
  };
}

ListeDesDerogationsDesktop.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initialisation: PropTypes.func.isRequired,
  demandesAssigneesAuConnecte: PropTypes.array.isRequired,
  demandesAssigneesEquipe: PropTypes.array.isRequired,
  demandesStatuees: PropTypes.array.isRequired,
  erreurs: PropTypes.array.isRequired,
  utilisateurEstBroker: PropTypes.bool.isRequired,
  onClickVoirDetailsDemande: PropTypes.func.isRequired,
};

export default injectIntl(ListeDesDerogationsDesktop);

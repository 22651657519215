import { FormattedMessage } from "react-intl";
import React from "react";

export function RefusCategorique({ checked, onChoice }) {
  return (
    <div className="un-choix-de-vr">
      <div className="label-du-choix">
        <label>
          <input
            type="radio"
            name="valeur"
            checked={checked}
            onChange={onChoice}
            className="mr-10"
          />
          <span className="tag button-error">
            <FormattedMessage id="derogation.decision.refuser_categoriquement" />
          </span>
        </label>
      </div>
    </div>
  );
}

import { getAccessToken } from "./MSAL";

export async function fetchAvecTokenAzure(
  url,
  scopeAzure,
  options,
  fonctionExtraitMessageErreur
) {
  const token = await getAccessToken(scopeAzure);
  const optionsAvecBearer = {
    ...options,
    headers: { ...options.headers, Authorization: `Bearer ${token}` },
  };

  const reponse = await fetch(url, optionsAvecBearer);

  return await parseReponseHttp(
    { reponse, url, options: optionsAvecBearer },
    fonctionExtraitMessageErreur
  );
}

export async function parseReponseHttp(
  { reponse, url, options },
  fonctionExtraitMessageErreur
) {
  // On veut le contenu de la réponse, même sur les réponses statut autre que 2xx
  // https://github.com/github/fetch/issues/203#issuecomment-335786498
  const [ok, textBody, httpStatus] = await Promise.all([
    reponse.ok,
    reponse.text(),
    reponse.status,
  ]);

  if (ok) return textBody;

  delete options.headers.Authorization;

  throw erreurHttp({
    ...JSON.parse(textBody),
    httpStatus,
    message: fonctionExtraitMessageErreur(textBody),
    url,
    options,
  });
}

export function erreurHttp(details) {
  const { url, httpStatus, message } = details;
  const detailsLisibles = [
    `[Message : ${message}]`,
    `[URL : ${url}]`,
    `[Statut HTTP : ${httpStatus}]`,
  ];

  return new ErreurHttp(detailsLisibles.join(" "), details);
}

class ErreurHttp extends Error {
  constructor(message, details) {
    super(message);
    this.details = details;
  }
}

export const TYPES_AVERTISSEMENTS = {
  LOYER_SUPERIEUR_AU_SEUIL_ADMINISTRATION_FISCALE:
    "LOYER_SUPERIEUR_AU_SEUIL_ADMINISTRATION_FISCALE",
  NON_VALEUR_ELEVEE: "NON_VALEUR_ELEVEE",
};

const {
  LOYER_SUPERIEUR_AU_SEUIL_ADMINISTRATION_FISCALE,
  NON_VALEUR_ELEVEE,
} = TYPES_AVERTISSEMENTS;

export function LoyerSuperieurAuSeuilAdministrationFiscale(loyerSeuil) {
  return {
    idType: LOYER_SUPERIEUR_AU_SEUIL_ADMINISTRATION_FISCALE,
    details: { loyerSeuil: loyerSeuil.toObject() },
  };
}

export function AvertissementNonValeurElevee() {
  return { idType: NON_VALEUR_ELEVEE };
}

export function selectAvertissement(avertissements, idType) {
  return avertissements.find((a) => a.idType === idType);
}

import { httpServiceCrm } from "../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../utils/odata/odata";

const ID_TYPE_DEROGATION_TEG = process.env.REACT_APP_ID_TYPE_DE_DEROGATION_TEG;

export const derogateursAdapterCrm = {
  async getDerogateurUnique(
    idTypeDerogation,
    idUtilisateur,
    { httpService = httpServiceCrm } = {}
  ) {
    const requete = requeteDuDerogateur(idUtilisateur, idTypeDerogation);

    const recherche = {
      type: "Dérogateurs",
      recherche: `ID Utilisateur : ${idUtilisateur} - ID type de dérogation : ${idTypeDerogation}`,
    };

    const reponse = await httpService.getUnique(requete, recherche);

    return {
      idDerogateur: reponse.itl_derogateurid,
      nom: reponse.itl_name,
    };
  },

  async getDerogateurUniqueDuType(
    idTypeDerogation,
    { http = httpServiceCrm } = {}
  ) {
    const requete = odataQuery("itl_derogateurs", {
      $select: ["itl_derogateurid"],
      $expand: ["itl_Derogateur", { $select: ["systemuserid", "fullname"] }],
      $filter: [`_itl_typederogation_value eq ${idTypeDerogation}`],
    });

    const reponse = await http.getUnique(requete, {
      type: "Dérogateurs",
      recherche: `ID type de dérogation : ${idTypeDerogation}`,
    });

    return {
      idDerogateur: reponse.itl_derogateurid,
      idUtilisateur: reponse.itl_Derogateur.systemuserid,
      nom: reponse.itl_Derogateur.fullname,
    };
  },

  async getDerogateurPotentiel(
    idTypeDerogation,
    idUtilisateur,
    { http = httpServiceCrm } = {}
  ) {
    const requete = requeteDuDerogateur(idUtilisateur, idTypeDerogation);

    const [derogateur] = await http.get(requete);

    if (!derogateur) return null;

    return {
      idDerogateur: derogateur.itl_derogateurid,
      nom: derogateur.itl_name,
    };
  },

  async getDerogateursDeTeg({ http = httpServiceCrm } = {}) {
    const derogateursCrm = await http.get(
      odataQuery("itl_derogateurs", {
        $select: ["_itl_derogateur_value"],
        $filter: [`_itl_typederogation_value eq ${ID_TYPE_DEROGATION_TEG}`],
      })
    );

    return derogateursCrm.map(d => ({
      utilisateur: { id: d._itl_derogateur_value },
    }));
  },
};

function requeteDuDerogateur(idUtilisateur, idTypeDerogation) {
  return odataQuery("itl_derogateurs", {
    $select: ["itl_derogateurid", "itl_name"],
    $filter: [
      `_itl_derogateur_value eq ${idUtilisateur}`,
      `_itl_typederogation_value eq ${idTypeDerogation}`,
    ],
  });
}

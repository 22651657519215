import React from "react";
import PropTypes from "prop-types";
import Textarea from "../../../../../../Kit/Formulaires/Textarea";
import { Obligatoire } from "../../../../../../Kit/Formulaires/Obligatoire";
import { useStateSyncedToProp } from "../../../../../../utils/hooks";

export function Description(props) {
  const [description, setDescription] = useStateSyncedToProp(
    props.valeurInitiale
  );

  const {
    onChange,
    longueurMax,
    label,
    estObligatoire,
    testId,
    readOnly,
  } = props;

  return (
    <div className="flex-dir-col">
      <div className="flex-content-sb mb-5">
        <div>
          {estObligatoire && (
            <span>
              <Obligatoire className="mr-5" />
            </span>
          )}
          {label}
        </div>
        <span className={description.length > longueurMax ? "text-red" : ""}>
          {description.length} / {longueurMax}
        </span>
      </div>
      <Textarea
        value={description}
        rows={6}
        onChange={(valeur) => setDescription(valeur)}
        onBlur={() => {
          const { valeurInitiale } = props;
          const valeurModifiee = description !== valeurInitiale;
          if (valeurModifiee) onChange(description);
        }}
        maxLength={longueurMax}
        testId={testId}
        readOnly={readOnly}
      />
    </div>
  );
}

Description.propTypes = {
  valeurInitiale: PropTypes.string,
  longueurMax: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.object,
  estObligatoire: PropTypes.bool,
  readOnly: PropTypes.bool,
  testId: PropTypes.string,
};

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./TypeDeFinancement.css";
import "./TypeDeFinancement-mobile.css";
import { TYPE_DE_FINANCEMENT } from "../../domaine/simulation/valeurResiduelle/reducer";

const { CB, LLD } = TYPE_DE_FINANCEMENT;

const TypeDeFinancement = ({ featureEstDisponible, onChange, typeActif }) =>
  !featureEstDisponible ? (
    <Fragment />
  ) : (
    <div className="type-de-financement">
      <h3 className="m-0">Type de financement</h3>
      <input
        type="radio"
        name="type_de_financement"
        className="ml-20"
        onChange={() => onChange(LLD)}
        defaultChecked={typeActif === LLD}
        id="lld"
      />
      <label htmlFor="lld" className="pointable ml-5 mr-10">
        LLD
      </label>
      <input
        type="radio"
        name="type_de_financement"
        onChange={() => onChange(CB)}
        defaultChecked={typeActif === CB}
        id="cb"
      />
      <label htmlFor="cb" className="pointable ml-5">
        CB
      </label>
    </div>
  );

TypeDeFinancement.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TypeDeFinancement;

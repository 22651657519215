import * as Sentry from "@sentry/browser";

export const reporterSentry = {
  init() {
    const environment = process.env.REACT_APP_SENTRY_ENV;
    const dsn = process.env.REACT_APP_SENTRY_DSN;
    Sentry.init({ dsn, environment });
  },

  setUsername(username) {
    Sentry.configureScope((scope) => {
      scope.setUser({ username });
    });
  },

  reportError(exception, details) {
    console.error(exception, details);

    Sentry.withScope((scope) => {
      scope.setExtra("details", details);
      Sentry.captureException(exception);
    });
  },
};

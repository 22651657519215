import { httpServiceCrm } from "../../../../../../Connecteurs/Crm/httpServiceCrm";

export const loyersAdapterCrm = {
  async creerLoyer(loyer, idFt, { http = httpServiceCrm } = {}) {
    const { itl_loyerftid } = await http.post(
      "itl_loyerfts?$select=itl_loyerftid",
      {
        "itl_FicheTransmissionId@odata.bind": `/itl_fichetransmissions(${idFt})`,
        ...loyer,
      }
    );

    return { id: itl_loyerftid };
  },
};

import { call, select } from "redux-saga/effects";
import { Adapters } from "../../../../../../reduxStore/adapters";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { nomDeLaFt } from "../../reducer";

export function* initialiserFtSaga({ ftAdapter = Adapters.ftAdapter } = {}) {
  const calculateurDuState = yield select(calculateur);

  const { simulation, planDeFinancement } = calculateurDuState;

  const nomFt = yield call(nomDeLaFt, simulation, planDeFinancement);

  const ft = { nom: nomFt };
  const ftCreee = yield call(ftAdapter.initialiserFt, calculateurDuState, ft);

  return { id: ftCreee.id, nom: nomFt };
}

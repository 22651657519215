import { connect } from "react-redux";
import { ChoixDeLaMarqueItl } from "./ChoixDeLaMarqueItl";
import { chargerMarquesItl } from "../../domaine/actions";
import { modeLight } from "../../../../reduxStore/rootReducer";
import { selectMarquesItl } from "../../domaine/reducer";

const mapStateToProps = (state) => ({
  marquesItl: selectMarquesItl(modeLight(state)),
});

const mapDispatchToProps = (dispatch) => ({
  chargerLesMarques: () => dispatch(chargerMarquesItl()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoixDeLaMarqueItl);

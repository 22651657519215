import { all, call, put, takeEvery } from "redux-saga/effects";
import { CREER_FICHE_DE_TRANSMISSION } from "../actions";
import {
  avancerProgressBar,
  demarrerProgressBar,
  operationSucces,
  supprimerTousLesSucces,
} from "../../../../../Ui/actions";
import { creerLesLoyersDeLaFtSaga } from "./loyers/loyers.sagas";
import { creerLesDepensesDeLaFtSaga } from "./depenses/depenses.sagas";
import { creerLaDemandeDeDerogationDeMargeSaga } from "./derogationDeMarge/derogationDeMarge.sagas";
import { assurerFtSaga } from "./assurance/assurance.sagas";
import { initialiserFtSaga } from "./ft/sagas";
import { trySaga } from "../../../../../utils/sagasUtils";
import { creerLaDemandeDeDerogationDeTegSaga } from "./derogationDeTeg/derogationDeTeg.sagas";
import { ajouterMaintenanceSaga } from "./maintenance/sagas";

export const ETAPES_DE_CREATION = {
  LOYERS: "calculateur.creation_fiche_de_transmission.etapes.loyers",
  DEPENSES: "calculateur.creation_fiche_de_transmission.etapes.depenses",
  ASSURANCE: "calculateur.creation_fiche_de_transmission.etapes.assurance",
  MAINTENANCE: "calculateur.creation_fiche_de_transmission.etapes.maintenance",
  DEROGATION_DE_MARGE:
    "calculateur.creation_fiche_de_transmission.etapes.derogation_de_marge",
  DEROGATION_DE_TEG:
    "calculateur.creation_fiche_de_transmission.etapes.derogation_de_teg",
};

const {
  DEROGATION_DE_MARGE,
  DEROGATION_DE_TEG,
  DEPENSES,
  ASSURANCE,
  MAINTENANCE,
  LOYERS,
} = ETAPES_DE_CREATION;

const nombreDe = etapes => Object.keys(etapes).length;

export function* creerFtSaga(action) {
  const saga = function*() {
    const { intl } = action;
    const time = action.timestampCreation;

    const operation = CREER_FICHE_DE_TRANSMISSION;

    yield put(supprimerTousLesSucces(operation));
    yield put(demarrerProgressBar(operation, nombreDe(ETAPES_DE_CREATION)));

    const ftCreee = yield call(initialiserFtSaga);

    yield put(avancerProgressBar(operation, LOYERS));
    yield call(creerLesLoyersDeLaFtSaga, ftCreee);

    yield put(avancerProgressBar(operation, DEPENSES));
    yield call(creerLesDepensesDeLaFtSaga, ftCreee, time);

    yield put(avancerProgressBar(operation, ASSURANCE));
    yield call(assurerFtSaga, ftCreee, { intl });

    yield put(avancerProgressBar(operation, MAINTENANCE));
    yield call(ajouterMaintenanceSaga, ftCreee);

    yield put(avancerProgressBar(operation, DEROGATION_DE_MARGE));
    yield call(creerLaDemandeDeDerogationDeMargeSaga, ftCreee, time, { intl });

    yield put(avancerProgressBar(operation, DEROGATION_DE_TEG));
    yield call(creerLaDemandeDeDerogationDeTegSaga, ftCreee, time);

    yield put(operationSucces(operation, ftCreee.id));
  };

  yield* trySaga(saga, CREER_FICHE_DE_TRANSMISSION);
}

export default function* sagasDesFichesDeTransmission() {
  yield all([takeEvery(CREER_FICHE_DE_TRANSMISSION, creerFtSaga)]);
}

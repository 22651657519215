import { connect } from "react-redux";
import uuid from "uuid";
import {
  ajouterCommission,
  supprimerCommission,
} from "../../domaine/simulation/montantAFinancer/actions";
import Commissionnement from "./Commissionnement";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { listeDesCommissions } from "../../domaine/simulation/montantAFinancer/reducer";
import { orchestrerLePlanDeFinancement } from "../../domaine/planDeFinancement/actions";
import { Money, NULL_MONEY } from "../../../../Money/money";
import { montantAchatDesProduitsAchetes } from "../../domaine/simulation/montantAFinancer/montants";
import { estEnMode, MODE_AFFICHAGE } from "../../../../Ui/reducer";
import {
  NATURE_DU_CALCUL,
  selectNatureDuCalcul,
} from "../../domaine/simulation/modeDeCalcul/reducer";

const { LIGHT } = MODE_AFFICHAGE;
const { INVESTISSEMENT } = NATURE_DU_CALCUL;

const mapStateToProps = (state) => {
  const {
    simulation: { montantAFinancer, modeDeCalcul },
  } = calculateur(state);

  return {
    commissions: listeDesCommissions(montantAFinancer).map((c) => ({
      ...c,
      pourcentage: pourcentageDuMontantDesMateriels(c, montantAFinancer),
    })),
    estEnModeLight: estEnMode(LIGHT, state.ui),
    ajoutEstDisabled: selectNatureDuCalcul(modeDeCalcul) === INVESTISSEMENT,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ajouterUneCommissionVide: () =>
    dispatch(ajouterCommission(uuid(), NULL_MONEY.toObject(), "")),
  onChangeMontant: (commission) => {
    dispatch(
      ajouterCommission(commission.id, commission.montant, commission.label)
    );
    dispatch(orchestrerLePlanDeFinancement());
  },
  onChangeDescription: (commission) =>
    dispatch(
      ajouterCommission(commission.id, commission.montant, commission.label)
    ),
  onDelete: (commission) => {
    dispatch(supprimerCommission(commission.id));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Commissionnement);

export function pourcentageDuMontantDesMateriels(
  commission,
  stateMontantAFinancer
) {
  if (!commission.montant) return null;

  const montantDesAchats = montantAchatDesProduitsAchetes(
    stateMontantAFinancer
  );
  if (montantDesAchats.isZero()) return null;

  return Money(commission.montant)
    .multiply(100)
    .divide(montantDesAchats.toUnit())
    .toUnit();
}

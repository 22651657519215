import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import {
  AUCUNE_OPTION,
  OPTION_ASSURANCE,
  PAS_DE_PRIME_ASSURANCE,
} from "../../../../../domaine/simulation/assurance/france/reducer";

const { PERTE_DE_RECETTES, GARANTIE_DES_LOYERS } = OPTION_ASSURANCE;

export function Options({
  materiel,
  onAjouterOption,
  primeAssurance,
  produitAssurance,
}) {
  const optionsNonDisponibles =
    primeAssurance === PAS_DE_PRIME_ASSURANCE ||
    optionsCompatibles(materiel, produitAssurance).length === 0;

  if (optionsNonDisponibles) return <Fragment />;

  const aucuneOption = !primeAssurance.option;
  const perteDeRecettesActive = primeAssurance.option === PERTE_DE_RECETTES;
  const garantieDesLoyersActive = primeAssurance.option === GARANTIE_DES_LOYERS;

  function optionEstDisponible(o) {
    return optionsCompatibles(materiel, produitAssurance).includes(o);
  }

  const optionName = `option-assurance-${materiel.id}`;

  return (
    <div className="mt-5">
      <FormattedMessage id="calculateur.assurance.options" />
      <div className="mt-8 flex-ai-center">
        <div className="mr-20">
          <input
            type="radio"
            className="mr-5"
            onChange={() => onAjouterOption(AUCUNE_OPTION)}
            defaultChecked={aucuneOption}
            id={`option-aucune-${materiel.id}`}
            name={optionName}
          />
          <label className="pointable" htmlFor={`option-aucune-${materiel.id}`}>
            <FormattedMessage id="calculateur.assurance.options.aucune" />
          </label>
        </div>
        {optionEstDisponible(PERTE_DE_RECETTES) && (
          <div className="mr-20">
            <input
              type="radio"
              className="mr-5"
              onClick={() => onAjouterOption(PERTE_DE_RECETTES)}
              defaultChecked={perteDeRecettesActive}
              id={`option-perte-de-recettes-${materiel.id}`}
              name={optionName}
            />
            <label
              className="pointable"
              htmlFor={`option-perte-de-recettes-${materiel.id}`}
            >
              <FormattedMessage id="calculateur.assurance.options.perte_de_recettes" />
            </label>
          </div>
        )}
        {optionEstDisponible(GARANTIE_DES_LOYERS) && (
          <div>
            <input
              type="radio"
              className="mr-5"
              onClick={() => onAjouterOption(GARANTIE_DES_LOYERS)}
              defaultChecked={garantieDesLoyersActive}
              id={`option-garanties-des-loyers-${materiel.id}`}
              name={optionName}
            />
            <label
              className="pointable"
              htmlFor={`option-garanties-des-loyers-${materiel.id}`}
            >
              <FormattedMessage id="calculateur.assurance.options.garantie_des_loyers" />
            </label>
          </div>
        )}
      </div>
    </div>
  );
}

export function optionsCompatibles(materiel, produitAssurance) {
  if (produitAssurance.options === AUCUNE_OPTION) return [];

  const options = Object.entries(produitAssurance.options);
  if (materiel.estOccasion)
    return options
      .filter(([_, taux]) => taux.tauxAdditionnelOccasion !== null)
      .map(([option]) => option);
  else
    return options
      .filter(([_, taux]) => taux.tauxAdditionnelNeuf !== null)
      .map(([option]) => option);
}

export default class GrilleDeVr {
  constructor(vrParMois) {
    this._vrParMois = vrParMois;
  }

  estUneDureeHorsBorne(dureeEnMois) {
    const grille = exclureLesDureesSansVr(this._vrParMois);
    return (
      dureeEnMois < grille.dureeMinimum() || dureeEnMois > grille.dureeMaximum()
    );
  }

  dureeMaximum() {
    const clesTriees = Object.keys(this._vrParMois).sort((a, b) => a - b);
    const borneMaxi = clesTriees[clesTriees.length - 1];
    return borneMaxi;
  }

  dureeMinimum() {
    const clesTriees = Object.keys(this._vrParMois).sort((a, b) => a - b);
    const borneMini = clesTriees[0];
    return borneMini;
  }

  estVide() {
    return Object.keys(this._vrParMois).length === 0;
  }
}

function exclureLesDureesSansVr(vrParMois) {
  const bornesValorisees = Object.entries(vrParMois)
    .filter(([_, valeur]) => valeur !== 0)
    .reduce((bornes, [cle, valeur]) => {
      bornes[cle] = valeur;
      return bornes;
    }, {});

  return new GrilleDeVr(bornesValorisees);
}

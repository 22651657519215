export const UrlsForce = {
  projet: (idProjet) => ({
    ouvrir: `${force}/calculateur?id-projet=${idProjet}`,
  }),

  ft: (idProjet, idFt) => ({
    ouvrir: `${force}/calculateur?id-projet=${idProjet}&id-ft=${idFt}`,
  }),

  derogation: (idDemande) => ({
    ouvrir: `${force}/derogations/demande/${idDemande}`,
  }),

  derogations: () => ({
    path: "/derogations",
  }),

  calculateur: () => ({
    path: "/calculateur",
  }),

  modeLight: () => ({
    path: "/mode-light",
    entree: (marche, marqueItl) =>
      `/mode-light?id-marche=${marche.id}&nom-marque-itl=${marqueItl.nom}&id-marque-itl=${marqueItl.id}`,
  }),

  syntheseDesDerogations: (idFt) => ({
    vr: `${force}/derogations/ft/${idFt}/vr`,
  }),
};
const force = process.env.REACT_APP_URL_FORCE_ITL;

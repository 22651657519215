import {
  DONNER_TEG_GRILLE,
  DONNER_TEG_MANUEL,
  REVENIR_AU_TEG_GRILLE,
} from "./actions";
import { fv, rate } from "../../../../../finance/formulesFinancieres";
import { PERIODICITES } from "../modeDeCalcul/periodicite/periodicite";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";

const { TRIMESTRIEL, MENSUEL, ANNUEL } = PERIODICITES;
const { FRANCE, ESPAGNE } = MARCHES;

export const PAS_DE_TEG = "PAS_DE_TEG";

const STATE_INITIAL = {
  tegGrille: undefined,
  tegManuel: undefined,
};

const reducer = (state = STATE_INITIAL, action) => {
  switch (action.type) {
    case DONNER_TEG_GRILLE:
      return { ...state, tegGrille: action.tegGrille };

    case DONNER_TEG_MANUEL:
      return { ...state, tegManuel: action.tegManuel };

    case REVENIR_AU_TEG_GRILLE:
      return { ...state, tegManuel: STATE_INITIAL.tegManuel };

    default:
      return state;
  }
};
export default reducer;

export const selectTegEffectif = (state, marcheUtilisateur) => {
  if (marcheUtilisateur === FRANCE) return state.tegGrille;
  if (marcheUtilisateur === ESPAGNE) return state.tegManuel ?? state.tegGrille;
};

export const selectTegGrille = (state) => state.tegGrille;

export const selectTegManuel = (state) => state.tegManuel;

export const tegBase100 = (taux) =>
  taux === undefined || taux === PAS_DE_TEG
    ? undefined
    : Number((taux * 100).toFixed(2));

export const selectNaPasDeTegEffectif = (state, marcheUtilisateur) =>
  selectTegEffectif(state, marcheUtilisateur) === PAS_DE_TEG;

export const selectNaPasDeTegGrille = (state) =>
  selectTegGrille(state) === PAS_DE_TEG;

export const tauxInteretParEcheance = (tauxAnnuel, periodicite) => {
  if (periodicite === MENSUEL) return tauxAnnuel / 12;

  if (periodicite === TRIMESTRIEL)
    return rate(4, 0, -100, fv(tauxAnnuel / 12, 12, 0, -100));

  if (periodicite === ANNUEL) return tauxAnnuel;

  throw new Error(
    `Demande de taux d'intérêt par échéance pour une Périodicité inconnue : '${periodicite}' `
  );
};

export function tegManuelFrEstActif(state, marcheUtilisateur) {
  const tegManuel = selectTegManuel(state);
  return marcheUtilisateur === FRANCE && tegManuel !== undefined;
}

import { connect } from "react-redux";
import {
  CREER_FICHE_DE_TRANSMISSION,
  creerFt,
} from "../../domaine/creationDeFt/actions";
import {
  erreurDeOperation,
  uneOperationEstEnCours,
} from "../../../../Ui/reducer";
import { calculateur } from "../../../../reduxStore/rootReducer";
import {
  selectAnomaliesBloquantes,
  selectAnomaliesDerogeables,
} from "../../domaine/anomalies/Anomalies";
import {
  enrichirAvecMotivation,
  tailleDesPiecesJointes,
} from "../../domaine/motivationsDesAnomalies/reducer";
import {
  RECUPERER_DEROGATEUR_DE_MARGE_DIRECTEUR_REGIONAL,
  RECUPERER_DEROGATEUR_DE_MARGE_MAITRE,
} from "../../domaine/simulation/derogateurs/actions";
import { anomalieEstDerogeeParUtilisateurConnecte } from "../../domaine/simulation/derogateurs/reducer";
import CreationDeFt from "./CreationDeFt";
import { ORCHESTRER_PLAN_DE_FINANCEMENT } from "../../domaine/planDeFinancement/actions";
import { planDeFinancementEstCalculable } from "../../domaine/planDeFinancement/naturesDuCalcul/aiguillage";

const LIMITE_DE_TAILLE_DES_PIECES_JOINTES_EN_MO = Number(
  process.env.REACT_APP_LIMITE_DE_TAILLE_DES_PIECES_JOINTES_EN_MO
);

const mapStateToProps = (state) => {
  const isLoading = uneOperationEstEnCours(state.ui, [
    CREER_FICHE_DE_TRANSMISSION,
  ]);

  const { simulation, anomalies, motivationsDesAnomalies } = calculateur(state);

  return {
    simulation,
    isLoading,

    planDeFinancementManquant:
      planDeFinancementEstCalculable(simulation) === false ||
      uneOperationEstEnCours(state.ui, [ORCHESTRER_PLAN_DE_FINANCEMENT]),

    erreursDeInitialisation: erreurDeOperation(state.ui, [
      RECUPERER_DEROGATEUR_DE_MARGE_MAITRE,
      RECUPERER_DEROGATEUR_DE_MARGE_DIRECTEUR_REGIONAL,
    ]),

    erreursOrchestrationPlanDeFinancement: erreurDeOperation(state.ui, [
      ORCHESTRER_PLAN_DE_FINANCEMENT,
    ]),

    anomaliesBloquantes: isLoading ? [] : selectAnomaliesBloquantes(anomalies),

    presenceDeAnomaliesDejaDerogees: isLoading
      ? false
      : selectAnomaliesDerogeables(anomalies, true).length > 0,

    anomaliesDerogeables: isLoading
      ? []
      : selectAnomaliesDerogeables(anomalies, false)
          .map((anomalie) =>
            enrichirAvecMotivation(anomalie, motivationsDesAnomalies)
          )
          .filter(sansLesAnomaliesAutoDerogees),

    tailleDesPiecesJointes: tailleDesPiecesJointes(
      motivationsDesAnomalies,
      LIMITE_DE_TAILLE_DES_PIECES_JOINTES_EN_MO
    ),
  };
};

function sansLesAnomaliesAutoDerogees(anomalie) {
  const pasDeDerogateur = !anomalie.derogateur;
  if (pasDeDerogateur) return true;
  const pasAutoDerogee =
    anomalieEstDerogeeParUtilisateurConnecte(anomalie) === false;
  return pasAutoDerogee;
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (intl) => dispatch(creerFt(Date.now(), intl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreationDeFt);

import { Money, Zero } from "../../../../../../Money/money";
import { tauxEffectifDeVr } from "./taux.selecteurs";

export function montantDeVrDuProduit(state, produit) {
  return Money(produit.montantUnitaire)
    .multiply(produit.quantite)
    .multiply(tauxEffectifDeVr(state, produit));
}

export function montantTotalDeVr(state, produits) {
  return produits.reduce(
    (total, p) => montantDeVrDuProduit(state, p).add(total),
    Zero
  );
}

import { httpServiceCrm } from "../../../../../../Connecteurs/Crm/httpServiceCrm";
import { montantArrondi } from "../../../../../../Kit/formatage";

const ID_TYPE_SERVICE_MAINTENANCE =
  process.env.REACT_APP_ID_TYPE_SERVICE_MAINTENANCE;

export const creerMaintenanceAdapterCrm = {
  async ajouterMaintenance(ft, maintenance, { http = httpServiceCrm } = {}) {
    const {
      nbEcheancesDeDecalage,
      dureeEnEcheances,
      montantParEcheance,
      montantTotal,
    } = maintenance;

    await http.post("itl_services", {
      "itl_FicheTransmissionId@odata.bind": `/itl_fichetransmissions(${ft.id})`,
      itl_name: "Maintenance",
      "itl_TypeServiceId@odata.bind": `/itl_typeservices(${ID_TYPE_SERVICE_MAINTENANCE})`,
      itl_montanttotalservice: montantArrondi(montantTotal, 2),
      itl_montantmaintenanceparecheance: montantArrondi(montantParEcheance, 2),
      itl_dureetotale: dureeEnEcheances,
      itl_echeancedemarrage: nbEcheancesDeDecalage + 1,
    });
  },
};

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { pourcentage } from "../../../../../Kit/formatage";
import { ANOMALIES } from "../../../domaine/anomalies/Anomalies";
import {
  labelCatalogue,
  produitEstNonReference,
} from "../../../domaine/simulation/catalogueProduits/reducer";
import { TAUX_DE_NON_VALEUR_SEUIL_DE_BLOCAGE } from "../../../domaine/anomalies/controles/verifierNonValeur";
import { MessageErreurMaintenanceTropLongue } from "../../Maintenance/Maintenance";

const {
  LOYER_NEGATIF,
  MARGE_TROP_FAIBLE,
  MATERIEL_INCOMPLET,
  MATERIEL_NON_REFERENCE_DESCRIPTION_TROP_LONGUE,
  MAINTENANCE_TROP_LONGUE,
  PAS_DE_TEG_GRILLE,
  RACHAT_DE_CONTRAT_INCOMPLET,
  RACHAT_MATERIEL_INCOMPLET,
  TAUX_DE_VR_NECESSITE_DEROGATION,
  TEG_MANUEL_INFERIEUR_A_GRILLE,
  TROP_DE_NON_VALEUR,
  VR_DEJA_REFUSEE,
  VR_REFUSEE_CATEGORIQUEMENT,
  ASSURANCE_ESPAGNE_MANQUANTE,
  ASSURANCE_ESPAGNE_INSUFFISANTE,
  ASSURANCE_ESPAGNE_DEJA_REFUSEE,
} = ANOMALIES;

export const MessageAnomalie = ({ simulation, anomalie }) => (
  <div>{MESSAGES_DES_ANOMALIES[anomalie.idType](simulation, anomalie)}</div>
);

MessageAnomalie.propTypes = {
  simulation: PropTypes.object.isRequired,
  anomalie: PropTypes.shape({
    idType: PropTypes.oneOf(Object.keys(ANOMALIES)),
    details: PropTypes.object,
  }).isRequired,
};

export const MESSAGES_DES_ANOMALIES = {
  [RACHAT_MATERIEL_INCOMPLET]: () => message("rachat_de_materiel_incomplet"),

  [RACHAT_DE_CONTRAT_INCOMPLET]: () => message("rachat_de_contrat_incomplet"),

  [TROP_DE_NON_VALEUR]: () =>
    message("trop_de_non_valeur", {
      pourcentageDuSeuil: pourcentage(TAUX_DE_NON_VALEUR_SEUIL_DE_BLOCAGE),
    }),

  [MATERIEL_INCOMPLET]: () => message("materiel_incomplet"),

  [MATERIEL_NON_REFERENCE_DESCRIPTION_TROP_LONGUE]: () =>
    message("materiel_non_reference_description_trop_longue"),

  [MARGE_TROP_FAIBLE]: ({ marche, derogations }, anomalie) => {
    const { details } = anomalie;
    return message("marge_trop_faible", {
      margeDuPlan: pourcentage(details.tauxDuPlan),
      minimumEnAutonomie: pourcentage(details.tauxMinimalAutonomie),
    });
  },

  [TAUX_DE_VR_NECESSITE_DEROGATION]: ({ catalogueProduits }, anomalie) => {
    const { vr, produit } = anomalie.details;

    if (produitEstNonReference(produit))
      return message("vr_superieure_au_seuil.produit_non_reference", {
        descriptionMateriel: produit.description,
      });

    if (produit.estOccasion)
      return message("vr_superieure_au_seuil.produit_occasion", {
        labelProduit: labelCatalogue(catalogueProduits, produit),
      });

    return message("vr_superieure_au_seuil", {
      labelProduit: labelCatalogue(catalogueProduits, produit),
      vrProduit: pourcentage(vr.manuelle),
      vrGrille: pourcentage(vr.seuil),
    });
  },

  [VR_DEJA_REFUSEE]: () => message("vr_deja_refusee"),

  [VR_REFUSEE_CATEGORIQUEMENT]: () => message("vr_refusee_categoriquement"),

  [PAS_DE_TEG_GRILLE]: () => message("pas_de_teg"),

  [LOYER_NEGATIF]: () => message("loyer_negatif"),

  [TEG_MANUEL_INFERIEUR_A_GRILLE]: () => message("teg_inferieur_a_grille"),

  [MAINTENANCE_TROP_LONGUE]: () => MessageErreurMaintenanceTropLongue,

  [ASSURANCE_ESPAGNE_MANQUANTE]: () => message("assurance_espagne_manquante"),

  [ASSURANCE_ESPAGNE_INSUFFISANTE]: () =>
    message("assurance_espagne_insuffisante"),

  [ASSURANCE_ESPAGNE_DEJA_REFUSEE]: () =>
    message("assurance_espagne_deja_refusee"),
};

function message(cle, values) {
  return (
    <FormattedMessage id={`calculateur.anomalies.${cle}`} values={values} />
  );
}

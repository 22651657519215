import { Money } from "../../../../Money/money";
import { loyerMaximum } from "../planDeFinancement/paliers";
import {
  AvertissementNonValeurElevee,
  LoyerSuperieurAuSeuilAdministrationFiscale,
} from "./TypesAvertissements";
import { selectMarcheUtilisateur } from "../simulation/profilUtilisateur/reducer";
import { MARCHES } from "../../../../identiteUtilisateur/reducer";
import {
  montantInvestissement,
  tauxDeNonValeur,
} from "../simulation/montantAFinancer/montants";
import { selectPaliersLoyersFinancier } from "../planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

export function avertissements(simulation, planDeFinancement) {
  const resultat = [
    controlerLoyerSuperieurAuSeuilAdministrationFiscale(
      simulation,
      planDeFinancement
    ),
    controlerNonValeurElevee(simulation),
  ].filter((a) => a !== undefined);

  return resultat;
}

function controlerLoyerSuperieurAuSeuilAdministrationFiscale(
  simulation,
  planDeFinancement
) {
  const controleInactif =
    selectMarcheUtilisateur(simulation.profilUtilisateur) === MARCHES.ESPAGNE;
  if (controleInactif) return;

  const loyers = selectPaliersLoyersFinancier(planDeFinancement);
  const loyerMax = Money(loyerMaximum(loyers));
  const investissement = montantInvestissement(simulation.montantAFinancer);
  const seuil = investissement.percentage(20);
  if (loyerMax.greaterThan(seuil))
    return LoyerSuperieurAuSeuilAdministrationFiscale(seuil);
}

function controlerNonValeurElevee(simulation) {
  const { montantAFinancer } = simulation;
  const tropDeNonValeur = tauxDeNonValeur(montantAFinancer) > 0.2;
  if (tropDeNonValeur) return AvertissementNonValeurElevee();
}

import { $obj, NULL_MONEY, somme$obj } from "../../../../../Money/money";
import { paliersAvecFranchise } from "./franchiseTroisMois";

export const PLAN_DE_FINANCEMENT_VIDE = {
  paliers: [],
  tauxInteret: undefined,
  montantTotalDeVr: NULL_MONEY.toObject(),
};

export function PlanDeFinancement(
  paliersComplets,
  { assurance, franchiseTroisMois, teg, vr, fraisDeReglement = [], maintenance }
) {
  const paliers = paliersAvecFranchise(franchiseTroisMois, paliersComplets)
    .map((p, i) => calculerLesFrais(p, i))
    .map((p) => calculerLeFullService(p));

  return { paliers, tauxInteret: teg, montantTotalDeVr: vr.montant };

  function calculerLesFrais(palier, index) {
    const montantDesFrais = fraisDeReglement[index] ?? $obj(0);

    return {
      ...palier,
      frais: { montant: montantDesFrais },
      loyerAvecFrais: somme$obj([palier.loyer, montantDesFrais]),
    };
  }

  function calculerLeFullService(palier) {
    const montantMaintenance = palier.avecMaintenance
      ? maintenance.montantParEcheance
      : $obj(0);

    const montantAssurance = assurance.primeParLoyer ?? $obj(0);

    return {
      ...palier,
      loyerFullService: somme$obj([
        palier.loyerAvecFrais,
        montantMaintenance,
        montantAssurance,
      ]),
      maintenance: { montantParEcheance: montantMaintenance },
      assurance: { primeParLoyer: montantAssurance },
    };
  }
}

import {
  ACTIVER_FRANCHISE_TROIS_MOIS,
  DESACTIVER_FRANCHISE_TROIS_MOIS,
} from "./actions";
import {
  nombreDeMois,
  PERIODICITE_PAR_DEFAUT,
  PERIODICITES,
} from "../modeDeCalcul/periodicite/periodicite";
import {
  CALCULER_EN_LINEAIRE,
  CALCULER_EN_MULTI_PALIERS,
  CHANGER_LA_PERIODICITE,
} from "../modeDeCalcul/actions";
import { selectMarcheUtilisateur } from "../profilUtilisateur/reducer";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";
import GrilleDeVr from "../valeurResiduelle/GrilleDeVr";
import { selectDureeEnMois } from "../modeDeCalcul/reducer";
import { nombreEcheances } from "../../planDeFinancement/paliers";

const { TRIMESTRIEL, MENSUEL, ANNUEL } = PERIODICITES;
const { FRANCE } = MARCHES;

const STATE_INITIAL = {
  estActivee: false,
  periodicite: PERIODICITE_PAR_DEFAUT,
};

export function franchiseTroisMoisReducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case ACTIVER_FRANCHISE_TROIS_MOIS:
      return { ...state, estActivee: true };

    case DESACTIVER_FRANCHISE_TROIS_MOIS:
      return { ...state, estActivee: false };

    case CHANGER_LA_PERIODICITE:
      const { periodicite } = action;

      return {
        ...state,
        periodicite,
        estActivee:
          state.estActivee && [MENSUEL, TRIMESTRIEL].includes(periodicite),
      };

    case CALCULER_EN_LINEAIRE: {
      const nouvelleDuree = nombreDeMois(
        action.nombreEcheances,
        state.periodicite
      );
      return { ...state, estActivee: state.estActivee && nouvelleDuree <= 60 };
    }

    case CALCULER_EN_MULTI_PALIERS: {
      const nouvelleDuree = nombreDeMois(
        nombreEcheances(action.paliers),
        state.periodicite
      );
      return { ...state, estActivee: state.estActivee && nouvelleDuree <= 60 };
    }

    default:
      return state;
  }
}

export const franchiseTroisMoisEstActivee = (state) => state.estActivee;

export function indisponibilitesFranchiseTroisMois(simulation) {
  const blocages = [];

  const { modeDeCalcul } = simulation;
  if (modeDeCalcul.periodicite === ANNUEL)
    blocages.push("PERIODICITE_ANNUELLE");

  const { profilUtilisateur } = simulation;
  if (selectMarcheUtilisateur(profilUtilisateur) !== FRANCE)
    blocages.push("UTILISATEUR_HORS_FRANCE");

  const duree = selectDureeEnMois(simulation.modeDeCalcul);
  if (duree > 60) blocages.push("DUREE_TROP_LONGUE");

  return blocages;
}

export const NB_ECHEANCES_DE_FRANCHISE_PAR_PERIODICITE = {
  [MENSUEL]: () => 3,
  [TRIMESTRIEL]: () => 1,
  [ANNUEL]: () => {
    throw ErreurDemandeAnnuel();
  },
};

const ErreurDemandeAnnuel = () =>
  new Error(
    "L'opération franchise 3 mois ne devrait pas être activée en Annuel"
  );

export function nbEcheancesDeFranchise(state) {
  return !franchiseTroisMoisEstActivee(state)
    ? 0
    : NB_ECHEANCES_DE_FRANCHISE_PAR_PERIODICITE[state.periodicite]();
}

export function repartirLesDemandesDeVr(
  demandesDeVr,
  dureeEnMoisAvecFranchise
) {
  const demandesGrilles = [];
  const demandesQuinzeEuros = [];

  for (const demande of demandesDeVr) {
    const dureeAvecFranchiseEstHorsBorne = new GrilleDeVr(
      demande.grilleDeVr
    ).estUneDureeHorsBorne(dureeEnMoisAvecFranchise);

    if (dureeAvecFranchiseEstHorsBorne) demandesQuinzeEuros.push(demande);
    else demandesGrilles.push(demande);
  }

  return { demandesGrilles, demandesQuinzeEuros };
}

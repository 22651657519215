import { httpServiceCrm } from "../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../utils/odata/odata";

export const referentielAdapterCrm = {
  async getMarchesEtBrokers({ http = httpServiceCrm } = {}) {
    const requete = odataQuery("itl_marches", {
      $select: ["itl_marcheid", "_itl_brokeritl_value"],
    });

    const marchesEtBrokers = await http.get(requete);

    return marchesEtBrokers.map(m => ({
      idMarche: m.itl_marcheid,
      idBroker: m._itl_brokeritl_value,
    }));
  },
};

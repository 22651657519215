import { all, call, put, select, take } from "redux-saga/effects";
import {
  CHARGER_CATEGORIES_PRODUIT_SUCCES,
  CHARGER_MARQUES_DE_PRODUITS_SUCCES,
  CHARGER_PRODUITS_SUCCES,
  chargerCategoriesProduit_succes,
  chargerMarquesDeProduits_succes,
  chargerProduits_succes,
  chargerSelectionDesMaterielsDisponibles,
} from "./actions";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import {
  CHARGER_LES_GRILLES_DE_VR_SUCCES,
  chargerLesGrillesDeVr_succes,
} from "../valeurResiduelle/actions";
import { getLabelCatalogue, produitEstNonReference } from "./reducer";
import {
  grouper,
  trier,
} from "../../../../../Kit/Formulaires/SelectAvecGroupe";
import { Adapters } from "../../../../../reduxStore/adapters";
import { selectMarcheChoisi } from "../projetDeTravail/reducer";
import { getMarcheUtilisateur } from "../profilUtilisateur/sagas";

export function* chargerCatalogueProduitsCompletSaga({
  catalogueProduitsAdapter = Adapters.catalogueProduitsAdapter,
} = {}) {
  const marcheUtilisateur = yield* getMarcheUtilisateur();
  const { simulation } = yield select(calculateur);

  const [catalogue, categories] = yield all([
    call(
      catalogueProduitsAdapter.getCatalogue,
      marcheUtilisateur,
      selectMarcheChoisi(simulation.projetDeTravail)
    ),
    call(catalogueProduitsAdapter.getCategoriesProduit, marcheUtilisateur),
  ]);

  yield put(chargerProduits_succes(catalogue.produits));
  yield put(chargerMarquesDeProduits_succes(catalogue.marques));
  yield put(chargerCategoriesProduit_succes(categories));
  yield put(chargerLesGrillesDeVr_succes(catalogue.grillesDeVr));
}

export function* sagasDuCatalogueProduit() {
  function* creerSelectionDeMaterielsWatch() {
    // Dans un while(true) car la saga "take" 4 actions pour se lancer.
    // On veut qu'elle soit à nouveau prête à être déclenchée dès qu'elle a fini.
    while (true) yield* creerSelectionDesMaterielsDisponiblesSaga();
  }
  yield all([creerSelectionDeMaterielsWatch()]);
}

export function* creerSelectionDesMaterielsDisponiblesSaga() {
  const [
    chargerVrs,
    chargerProduits,
    chargerMarques,
    chargerCategories,
  ] = yield all([
    take(CHARGER_LES_GRILLES_DE_VR_SUCCES),
    take(CHARGER_PRODUITS_SUCCES),
    take(CHARGER_MARQUES_DE_PRODUITS_SUCCES),
    take(CHARGER_CATEGORIES_PRODUIT_SUCCES),
  ]);

  const { lesVr } = chargerVrs;
  const { produits } = chargerProduits;
  const { marquesDeProduits } = chargerMarques;
  const { categories } = chargerCategories;

  const selection = lesVr.map(({ idProduit: idFamille, idMarque }) => {
    const produitCatalogue = produits.find(p => p.id === idFamille);
    const famille = { id: produitCatalogue.id, label: produitCatalogue.label };
    const marque = marquesDeProduits.find(m => m.id === idMarque);
    const label = getLabelCatalogue({ idFamille }, produitCatalogue, marque);
    const categorie = produitCatalogue.categorie;
    const motsCles = produitCatalogue.motsCles;
    const estNonReference = produitEstNonReference({ idFamille });

    const categorisation = estNonReference
      ? {}
      : {
          niveauParent: categories.find(c =>
            c.categoriesEnfants.map(f => f.id).includes(categorie.id)
          )?.nom,
          niveauEnfant: categories
            .map(c => c.categoriesEnfants)
            .flat()
            .find(f => f.id === categorie.id)?.nom,
        };

    return {
      value: { famille, marque, categorisation },
      label,
      groupe: trouverCategorieRacine(categorie.id, categories)?.nom,
      estUnDefault: estNonReference,
      motsCles,
    };
  });

  yield put(chargerSelectionDesMaterielsDisponibles(grouper(trier(selection))));
}

export function* yieldCatalogueProduits() {
  const { simulation } = yield select(calculateur);
  return simulation.catalogueProduits;
}

export function trouverCategorieRacine(idCategorie, toutesLesCategories) {
  const parent = trouverParent();

  return !parent
    ? laCategorie()
    : trouverCategorieRacine(parent.id, toutesLesCategories);

  function trouverParent() {
    return toutesLesCategories.find(c =>
      c.categoriesEnfants.find(enfant => enfant.id === idCategorie)
    );
  }

  function laCategorie() {
    return toutesLesCategories.find(c => c.id === idCategorie);
  }
}

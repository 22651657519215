import Identification from "./Identification";
import { connect } from "react-redux";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { nomDeLaFt } from "../../domaine/creationDeFt/reducer";
import { selectProjetDeTravail } from "../../domaine/simulation/projetDeTravail/reducer";

const mapStateToProps = (state) => {
  const { simulation, planDeFinancement } = calculateur(state);
  return {
    projetDeTravail: selectProjetDeTravail(simulation.projetDeTravail),
    fiche: {
      nom: nomDeLaFt(simulation, planDeFinancement),
    },
  };
};

export default connect(mapStateToProps)(Identification);

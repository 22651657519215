import { definirMaintenanceParEcheance } from "./actions";
import { call, put, select } from "redux-saga/effects";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import { doitCalculerLaMaintenance, selectMaintenance } from "./reducer";
import { maintenanceAdapterK3000 } from "./maintenance.adapter.K3000";
import { valeurDuMontantAFinancer } from "../montantAFinancer/montants";
import { selectMarcheUtilisateur } from "../profilUtilisateur/reducer";
import { selectTegEffectif } from "../tauxInteret/reducer";
import { montantTotalDeVr } from "../valeurResiduelle/selecteurs/montants.selecteurs";
import { listeDesProduitsAchetes } from "../montantAFinancer/reducer";
import { calculerEnMultiPaliers } from "../modeDeCalcul/actions";
import { Money } from "../../../../../Money/money";

export function* calculerMontantMaintenanceParEcheanceSaga({
  maintenanceAdapter = maintenanceAdapterK3000,
} = {}) {
  const { simulation } = yield select(calculateur);
  const maintenance = selectMaintenance(simulation.maintenance);

  if (!doitCalculerLaMaintenance(maintenance)) return;

  const calculEquilibrageOuNon = {
    [true]: () => avecEquilibrage(simulation, { maintenanceAdapter }),
    [false]: () => sansEquilibrage(maintenance),
  };

  yield* calculEquilibrageOuNon[maintenance.equilibrage.actif]();
}

function* sansEquilibrage(maintenance) {
  const montantParEcheance = Money(maintenance.montantTotal)
    .divide(maintenance.dureeEnEcheances)
    .toObject();

  yield put(definirMaintenanceParEcheance(montantParEcheance));
}

function* avecEquilibrage(simulation, { maintenanceAdapter }) {
  const { montantParEcheance, paliersFinanciers } = yield call(
    maintenanceAdapter.maintenanceAvecEquilibrage,
    parametres()
  );

  yield put(calculerEnMultiPaliers(paliersFinanciers));
  yield put(definirMaintenanceParEcheance(montantParEcheance));

  function parametres() {
    const maintenance = selectMaintenance(simulation.maintenance);
    return {
      maintenance: {
        montantTotal: maintenance.montantTotal,
        nbEcheancesDeDecalage: maintenance.nbEcheancesDeDecalage,
      },
      montantAFinancer: valeurDuMontantAFinancer(
        simulation.montantAFinancer
      ).toObject(),
      paliers: simulation.modeDeCalcul.paliers,
      periodicite: simulation.modeDeCalcul.periodicite,
      teg: selectTegEffectif(
        simulation.tauxInteret,
        selectMarcheUtilisateur(simulation.profilUtilisateur)
      ),
      terme: simulation.terme.choisi,
      vr: {
        montant: montantTotalDeVr(
          simulation.valeurResiduelle,
          listeDesProduitsAchetes(simulation.montantAFinancer)
        ).toObject(),
      },
    };
  }
}

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import Euros from "../../../../../../Kit/Finance/Euros";
import InputText from "../../../../../../Kit/Formulaires/InputText";
import DatePicker from "../../../../../../Kit/Formulaires/DatePicker";
import InputMontant from "../../../../../../Kit/Formulaires/InputMontant";
import { Carte } from "../../../../../../Kit/Carte/Carte";
import { Obligatoire } from "../../../../../../Kit/Formulaires/Obligatoire";
import { LOUEUR_DE_CONTRAT } from "../../../../domaine/simulation/montantAFinancer/reducer";
import Textarea from "../../../../../../Kit/Formulaires/Textarea";
import { descriptionCompleteDeRachatDeContrat } from "../../../../domaine/simulation/montantAFinancer/rachatDeContrat";
import Select, {
  WrapCustomOption,
} from "../../../../../../Kit/Formulaires/Select";
import { $obj, forInput, MoneyPropType } from "../../../../../../Money/money";
import { Option_MaterielCategorise } from "../Kit/Option_MaterielCategorise";
import { filtreDeMateriels } from "../../../../../../Kit/Formulaires/Select.filtres";

function UnRachatDeContrat({
  estEnModeLight,
  longueurMaxDescription,
  onChange,
  onChangeQuiRecalcule,
  onDelete,
  rachat,
  selectionDesMateriels,
}) {
  const intl = useIntl();

  const descriptionComplete = descriptionCompleteDeRachatDeContrat(
    rachat,
    longueurMaxDescription
  );

  return (
    <Carte titre={titreParLoueur(rachat.loueurContrat, intl)}>
      <div className="flex-dir-col flex-content-sb flex-1">
        <div>
          <div className="ligne-de-formulaire">
            <label>
              <Obligatoire className="mr-5" />
              <FormattedMessage id="calculateur.rachat_de_contrat.montant_encours" />
            </label>
            <div className="ml-20">
              <InputMontant
                focusOnMount={rachat.montantEncours === null}
                value={forInput(rachat.montantEncours)}
                onBlur={montantEncours => {
                  onChangeQuiRecalcule({
                    ...rachat,
                    montantEncours: $obj(montantEncours),
                  });
                }}
                className="input-s"
                append={<Euros />}
              />
            </div>
          </div>

          <div className="ligne-de-formulaire">
            <label>
              <FormattedMessage id="calculateur.rachat_de_contrat.montant_de_la_revente" />
            </label>
            <div className="ml-20">
              <InputMontant
                value={forInput(rachat.montantDeLaRevente)}
                onBlur={montantDeLaRevente => {
                  onChangeQuiRecalcule({
                    ...rachat,
                    montantDeLaRevente: $obj(montantDeLaRevente),
                  });
                }}
                className="input-s"
                append={<Euros />}
              />
            </div>
          </div>

          {!estEnModeLight && (
            <div className="ligne-de-formulaire">
              <label>
                <Obligatoire className="mr-5" />
                <FormattedMessage id="calculateur.rachat_de_contrat.date_encours" />
              </label>
              <DatePicker
                value={rachat.dateEncours}
                className="input-l"
                onChange={dateEncours => onChange({ ...rachat, dateEncours })}
              />
            </div>
          )}

          {!estEnModeLight && (
            <div className="ligne-de-formulaire">
              <label>
                <Obligatoire className="mr-5" />
                <FormattedMessage id="calculateur.rachat_de_contrat.numero_contrat" />
              </label>
              <InputText
                value={rachat.numeroContrat}
                className="input-m"
                onChange={numeroContrat =>
                  onChange({ ...rachat, numeroContrat })
                }
              />
            </div>
          )}

          {!estEnModeLight && (
            <div className="ligne-de-formulaire">
              <Obligatoire className="mr-5" />
              <Select
                value={
                  rachat.materielRachete.idMarque &&
                  rachat.materielRachete.idMarque
                    ? {
                        value: rachat.materielRachete,
                        label: rachat.materielRachete.label,
                      }
                    : null
                }
                options={selectionDesMateriels.materiels}
                onChange={selection => {
                  const { value, label } = selection;
                  const { famille, marque } = value;
                  const materielRachete = {
                    idFamille: famille.id,
                    idMarque: marque.id,
                    label,
                  };
                  onChange({ ...rachat, materielRachete });
                }}
                className="w100"
                placeholder={intl.formatMessage({
                  id: "calculateur.un_produit.famille",
                })}
                fonctionDeFiltre={filtreDeMateriels}
                customComponents={{
                  Option: WrapCustomOption(Option_MaterielCategorise),
                }}
              />
            </div>
          )}

          {!estEnModeLight && (
            <div>
              <div className="flex-content-sb mb-5">
                <FormattedMessage id="calculateur.rachat_de_contrat.description_materiel" />
                <span
                  className={
                    rachat.description.length >
                    descriptionComplete.caracteresRestants
                      ? "text-red"
                      : ""
                  }
                >
                  {rachat.description.length} /{" "}
                  {descriptionComplete.caracteresRestants}
                </span>
              </div>
              <Textarea
                rows={6}
                value={rachat.description}
                onChange={description => onChange({ ...rachat, description })}
                className="w100 mb-10"
                maxLength={descriptionComplete.caracteresRestants}
              />
              <div className="mb-5">
                <i>
                  <FormattedMessage id="calculateur.rachat_de_contrat.description_complete" />
                </i>
              </div>
              <div style={{ maxWidth: "300px" }}>
                {descriptionComplete.complete}
              </div>
            </div>
          )}
        </div>

        <div className="mha">
          <button
            className="suppr pure-button button-error button-small bouton-supprimer-produit"
            onClick={() => onDelete(rachat)}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ marginRight: "5px" }}
            />
            <FormattedMessage id="general.supprimer" />
          </button>
        </div>
      </div>
    </Carte>
  );
}

function titreParLoueur(loueur, intl) {
  const { CONCURRENT, ITL } = LOUEUR_DE_CONTRAT;
  const titres = {
    [ITL]: "calculateur.rachat_de_contrat.loueur_itl.titre",
    [CONCURRENT]: "calculateur.rachat_de_contrat.loueur_concurrent.titre",
  };

  return intl.formatMessage({ id: titres[loueur] });
}

export const UnRachatDeContratPropTypes = {
  rachat: PropTypes.shape({
    id: PropTypes.string.isRequired,
    montantEncours: MoneyPropType,
    montantDeLaRevente: MoneyPropType,
    dateEncours: PropTypes.string,
    numeroContrat: PropTypes.string,
    loueurContrat: PropTypes.oneOf(Object.values(LOUEUR_DE_CONTRAT)).isRequired,
    description: PropTypes.string,
    materielRachete: PropTypes.shape({
      idFamille: PropTypes.string,
      idMarque: PropTypes.string,
      label: PropTypes.string,
    }),
    timestamp: PropTypes.number.isRequired,
  }),
  longueurMaxDescription: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeQuiRecalcule: PropTypes.func.isRequired,
  selectionDesMateriels: PropTypes.object.isRequired,
  estEnModeLight: PropTypes.bool.isRequired,
};

UnRachatDeContrat.propTypes = {
  ...UnRachatDeContratPropTypes,
};

export default UnRachatDeContrat;

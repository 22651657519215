import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  CHARGER_DEROGATIONS_DE_VR_PAR_PROJET,
  CHARGER_RESUMES_DE_DEROGATIONS,
  CHARGER_UNE_DEROGATION_DETAILLEE,
  chargerDerogateursDeTeg_succes,
  chargerResumesDeDerogations_succes,
  chargerUneDerogationDetaillee_succes,
  REDIRIGER_VERS_LES_DEROGATIONS_DU_PROJET,
} from "./actions";
import { TYPE_DE_DEROGATION } from "./reducer";
import {
  identiteUtilisateur,
  referentiel,
} from "../../../reduxStore/rootReducer";
import {
  accepterDerogationDeMargeSaga,
  refuserDerogationDeMargeSaga,
} from "./typesDerogations/marge/decision.sagas";
import {
  ACCEPTER_DEROGATION_DE_MARGE,
  REFUSER_DEROGATION_DE_MARGE,
} from "./typesDerogations/marge/actions";
import { AVIS_BROKER_DEROGATION_DE_VR } from "./typesDerogations/vr/actions";
import { avisBrokerDerogationVrSaga } from "./typesDerogations/vr/decision.sagas";
import { derogationEstVisible } from "./repartitionDesDerogations";
import { Adapters } from "../../../reduxStore/adapters";
import { trySaga } from "../../../utils/sagasUtils";
import {
  ACCEPTER_DEROGATION_DE_TEG,
  REFUSER_DEROGATION_DE_TEG,
} from "./typesDerogations/teg/actions";
import {
  accepterDerogationDeTegSaga,
  refuserDerogationDeTegSaga,
} from "./typesDerogations/teg/decision.sagas";
import {
  ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE,
  REFUSER_DEROGATION_ASSURANCE_ESPAGNE,
} from "./typesDerogations/assuranceEspagne/actions";
import {
  accepterDerogationAssuranceEspagneSaga,
  refuserDerogationAssuranceEspagneSaga,
} from "./typesDerogations/assuranceEspagne/decision.sagas";

const ID_TYPE_DEROGATION_DE_VR = process.env.REACT_APP_ID_TYPE_DE_DEROGATION_VR;
const { VR, MARGE, TEG, ASSURANCE_ESPAGNE } = TYPE_DE_DEROGATION;

export function* chargerUneDerogationDetailleeSaga(
  action,
  {
    derogationDeMargeAdapter = Adapters.derogationDeMargeAdapter,
    derogationDeVrAdapter = Adapters.derogationDeVrAdapter,
    derogationDeTegAdapter = Adapters.derogationDeTegAdapter,
    derogationAssuranceEspagneAdapter = Adapters.derogationAssuranceEspagneAdapter,
    resumeDeDerogationAdapter = Adapters.resumeDeDerogationAdapter,
  } = {}
) {
  const saga = function* () {
    const typeDeLaDerogation = yield call(
      resumeDeDerogationAdapter.getTypeDeLaDerogation,
      action.idDerogation
    );

    const getParType = {
      [MARGE]: derogationDeMargeAdapter.getDerogation,
      [VR]: derogationDeVrAdapter.getDerogation,
      [TEG]: derogationDeTegAdapter.getDerogation,
      [ASSURANCE_ESPAGNE]: derogationAssuranceEspagneAdapter.getDerogation,
    };

    const derogation = yield call(
      getParType[typeDeLaDerogation],
      action.idDerogation
    );

    yield put(chargerUneDerogationDetaillee_succes(derogation));
  };

  yield* trySaga(saga, CHARGER_UNE_DEROGATION_DETAILLEE);
}

export function* chargerResumesDeDerogationsSaga({
  resumeDeDerogationAdapter = Adapters.resumeDeDerogationAdapter,
  derogateursAdapter = Adapters.derogateursAdapter,
  filtreDerogationVisible = derogationEstVisible,
} = {}) {
  const saga = function* () {
    const [derogations, derogateursDeTeg, i, r] = yield all([
      call(resumeDeDerogationAdapter.getDerogations),
      call(derogateursAdapter.getDerogateursDeTeg),
      select(identiteUtilisateur),
      select(referentiel),
    ]);

    yield put(chargerDerogateursDeTeg_succes(derogateursDeTeg));

    const derogationsVisibles = derogations.filter((derogation) =>
      filtreDerogationVisible(derogation, i, r, derogateursDeTeg)
    );

    yield put(chargerResumesDeDerogations_succes(derogationsVisibles));
  };

  yield* trySaga(saga, CHARGER_RESUMES_DE_DEROGATIONS);
}

export function* chargerDerogationsDeVrParProjetSaga(
  action,
  {
    derogationsParProjetAdapter = Adapters.derogationsParProjetAdapter,
    derogationDeVrAdapter = Adapters.derogationDeVrAdapter,
  } = {}
) {
  const saga = function* () {
    const idsDesDerogations = yield call(
      derogationsParProjetAdapter.getIdsDesDerogations,
      action.idProjet,
      ID_TYPE_DEROGATION_DE_VR
    );

    const derogationsDeVrDuProjet = yield all(
      idsDesDerogations.map((id) =>
        call(derogationDeVrAdapter.getDerogation, id)
      )
    );

    for (const derogation of derogationsDeVrDuProjet) {
      yield put(chargerUneDerogationDetaillee_succes(derogation));
    }
  };

  yield* trySaga(saga, CHARGER_DEROGATIONS_DE_VR_PAR_PROJET);
}

export function* redirigerVersLesDerogationsDuProjetSaga(
  action,
  { derogationsParProjetAdapter = Adapters.derogationsParProjetAdapter } = {}
) {
  const { source } = action;
  let projet = null;

  const redirigeDepuisFt = source.idFt !== undefined;
  if (redirigeDepuisFt)
    projet = yield call(derogationsParProjetAdapter.getProjetDeFt, source.idFt);
  else {
    const redirigeDepuisUneDerog = source.idDerogation !== undefined;
    if (redirigeDepuisUneDerog)
      projet = yield call(
        derogationsParProjetAdapter.getProjetDeDerogation,
        source.idDerogation
      );
    else redirectionImpossible(action);
  }

  const { routeurDeApp } = action;
  yield call(routeurDeApp.replace, `/derogations/projet/${projet.id}/vr`);
}

function redirectionImpossible(action) {
  const impossible = `Impossible de trouver le projet vers lequel rediriger.`;
  const raison = `L'action redux n'a pas l'info.`;
  const dumpAction = JSON.stringify(action);
  throw Error(`${impossible} ${raison} ${dumpAction}`);
}

export default function* sagasDesDerogations() {
  yield all([
    takeEvery(ACCEPTER_DEROGATION_DE_MARGE, accepterDerogationDeMargeSaga),
    takeEvery(REFUSER_DEROGATION_DE_MARGE, refuserDerogationDeMargeSaga),
    takeEvery(ACCEPTER_DEROGATION_DE_TEG, accepterDerogationDeTegSaga),
    takeEvery(REFUSER_DEROGATION_DE_TEG, refuserDerogationDeTegSaga),
    takeEvery(
      ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE,
      accepterDerogationAssuranceEspagneSaga
    ),
    takeEvery(
      REFUSER_DEROGATION_ASSURANCE_ESPAGNE,
      refuserDerogationAssuranceEspagneSaga
    ),
    takeEvery(
      CHARGER_UNE_DEROGATION_DETAILLEE,
      chargerUneDerogationDetailleeSaga
    ),
    takeEvery(
      CHARGER_DEROGATIONS_DE_VR_PAR_PROJET,
      chargerDerogationsDeVrParProjetSaga
    ),
    takeEvery(
      REDIRIGER_VERS_LES_DEROGATIONS_DU_PROJET,
      redirigerVersLesDerogationsDuProjetSaga
    ),
    takeEvery(CHARGER_RESUMES_DE_DEROGATIONS, chargerResumesDeDerogationsSaga),
    takeEvery(AVIS_BROKER_DEROGATION_DE_VR, avisBrokerDerogationVrSaga),
  ]);
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MessageAnomalie } from "../Anomalies/MessageAnomalie";
import { ANOMALIES } from "../../../domaine/anomalies/Anomalies";
import { InputMultipleFiles } from "../../../../../Kit/Formulaires/InputMultipleFiles";
import Textarea from "../../../../../Kit/Formulaires/Textarea";
import { enhancedMessage } from "../../../../../i18n/raccourcis";

const { TAUX_DE_VR_NECESSITE_DEROGATION } = ANOMALIES;

export default function UneDemandeDeDerogation(props) {
  return (
    <>
      <div className="mb-5">
        <MessageAnomalie
          simulation={props.simulation}
          anomalie={props.anomalie}
        />
      </div>
      <div>{INSTRUCTION_DE_MOTIVATION(props.anomalie.idType)}</div>
      <Motivation
        valeurInitiale={props.anomalie.motivation}
        onChange={(valeur) => props.onChange(props.anomalie, valeur)}
      />
      <PiecesJointes
        demande={props.anomalie}
        onPieceJointePick={props.onPieceJointePick}
        onPieceJointeDelete={props.onPieceJointeDelete}
      />
      <p>
        {texteRiche("personneConsultee", {
          personneConsultee: props.anomalie.personneConsultee.nom,
        })}
      </p>
    </>
  );
}

UneDemandeDeDerogation.propTypes = {
  simulation: PropTypes.any,
  anomalie: PropTypes.any,
  onChange: PropTypes.func,
  onPieceJointePick: PropTypes.any,
  onPieceJointeDelete: PropTypes.any,
};

const INSTRUCTION_DE_MOTIVATION = (idTypeAnomalie) => {
  return idTypeAnomalie === TAUX_DE_VR_NECESSITE_DEROGATION
    ? texte("motivation_vr")
    : texte("motivation_generique");
};

function texte(cle) {
  return <FormattedMessage id={`calculateur.demande_de_derogation.${cle}`} />;
}

function Motivation({ valeurInitiale, onChange }) {
  const [motivation, setMotivation] = useState(valeurInitiale);

  return (
    <Textarea
      cols={80}
      rows={4}
      className="mt-10"
      value={motivation || ""}
      onBlur={() => onChange(motivation)}
      onChange={(value) => setMotivation(value)}
    />
  );
}

function PiecesJointes({ demande, onPieceJointePick, onPieceJointeDelete }) {
  if (demande.idType !== TAUX_DE_VR_NECESSITE_DEROGATION) return <></>;

  return (
    <div className="mt-10">
      <span className="tag button-warning">
        <FormattedMessage id="calculateur.creation_fiche_de_transmission.temps_denvoi_des_pieces_jointes" />
      </span>
      <InputMultipleFiles
        files={demande.piecesJointes}
        onFilePick={(fichier) => onPieceJointePick(demande, fichier)}
        onFileDelete={(fichier) => onPieceJointeDelete(fichier)}
      />
    </div>
  );
}

function texteRiche(cle, values) {
  return enhancedMessage(`calculateur.demande_de_derogation.${cle}`, values);
}

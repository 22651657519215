import React from "react";
import PropTypes from "prop-types";
import "./BadgeWarning.css";

const BadgeWarning = ({ children, style, className }) => (
  <div className={`badge-warning ${className}`} style={style}>
    {children}
  </div>
);

BadgeWarning.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
BadgeWarning.defaultProps = {
  style: {},
  className: "",
};

export default BadgeWarning;

import { Money } from "../../../../../Money/money";

export function selectPaliers(plan, { inclureFranchise = false } = {}) {
  return plan.paliers.filter(filtrerSurFranchise(inclureFranchise));
}

export function selectPaliersLoyersFullService(
  plan,
  { inclureFranchise = false } = {}
) {
  return plan.paliers
    .filter(filtrerSurFranchise(inclureFranchise))
    .map((p) => ({ duree: p.duree, loyerFullService: p.loyerFullService }));
}

export function selectPaliersLoyersFinancier(
  plan,
  { inclureFranchise = false } = {}
) {
  return plan.paliers
    .filter(filtrerSurFranchise(inclureFranchise))
    .map((p) => ({ duree: p.duree, loyer: p.loyer }));
}

export function selectPaliersLoyersAvecFrais(
  plan,
  { inclureFranchise = false } = {}
) {
  return plan.paliers
    .filter(filtrerSurFranchise(inclureFranchise))
    .map((p) => ({
      duree: p.duree,
      loyerAvecFrais: p.loyerAvecFrais,
      frais: p.frais,
    }));
}

export function vrUnLoyerEstEffective(plan) {
  if (plan.paliers.length === 0) return false;

  const dernierPalier = [...plan.paliers].pop();
  const dernierLoyerSansCentimes = Money(dernierPalier.loyer).toRoundedUnit(0);
  const vrSansCentimes = Money(plan.montantTotalDeVr).toRoundedUnit(0);

  return dernierLoyerSansCentimes === vrSansCentimes;
}

function filtrerSurFranchise(inclureFranchise) {
  return (p) => (!inclureFranchise && p.estFranchise ? false : true);
}

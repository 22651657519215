export const MOTIVER_ANOMALIE = "MOTIVER_ANOMALIE";
export const motiverAnomalie = (anomalie, texte) => ({
  type: MOTIVER_ANOMALIE,
  anomalie,
  texte,
});

export const AJOUTER_PIECE_JOINTE = "AJOUTER_PIECE_JOINTE";
export const ajouterPieceJointe = (anomalie, pieceJointe) => ({
  type: AJOUTER_PIECE_JOINTE,
  anomalie,
  pieceJointe,
});

export const SUPPRIMER_PIECE_JOINTE = "SUPPRIMER_PIECE_JOINTE";
export const supprimerPieceJointe = (pieceJointe) => ({
  type: SUPPRIMER_PIECE_JOINTE,
  pieceJointe,
});

export function formatyyyyMMdd(timestamp) {
  const { annee, mois, jour } = parse(timestamp);

  const moisMM = String(mois).padStart(2, "0");
  const jourJJ = String(jour).padStart(2, "0");

  return `${annee}-${moisMM}-${jourJJ}`;
}

export function aujourdhuiyyyyMMdd() {
  return formatyyyyMMdd(Date.now());
}

export function formatjjMMaaaa(timestamp) {
  const { annee, mois, jour } = parse(timestamp);

  const moisMM = String(mois).padStart(2, "0");
  const jourJJ = String(jour).padStart(2, "0");

  return `${jourJJ}/${moisMM}/${annee}`;
}

export function formatjjMMaa(timestamp) {
  const { annee, mois, jour } = parse(timestamp);

  const moisMM = String(mois).padStart(2, "0");
  const jourJJ = String(jour).padStart(2, "0");
  const anneeAA = String(annee).substring(2);

  return `${jourJJ}/${moisMM}/${anneeAA}`;
}

function parse(timestamp) {
  const date = new Date(timestamp);

  const annee = date.getFullYear();
  const mois = date.getMonth() + 1;
  const jour = date.getDate();
  return { annee, mois, jour };
}

export function differenceEnMinutes(timestampApres, timestampAvant) {
  return (timestampApres - timestampAvant) / 1000 / 60;
}

export function differenceEnJours(timestampApres, timestampAvant) {
  return (timestampApres - timestampAvant) / 1000 / 3600 / 24;
}

export function plusVieuxQueXJours(timestamp, x) {
  return differenceEnJours(Date.now(), timestamp) > x;
}

import { AnomalieTropDeNonValeur } from "../Anomalies";
import { tauxDeNonValeur } from "../../simulation/montantAFinancer/montants";

export const TAUX_DE_NON_VALEUR_SEUIL_DE_BLOCAGE = 0.4;

export function verifierNonValeur(simulation) {
  const { montantAFinancer } = simulation;
  const tropDeNonValeur =
    tauxDeNonValeur(montantAFinancer) > TAUX_DE_NON_VALEUR_SEUIL_DE_BLOCAGE;

  if (tropDeNonValeur) return AnomalieTropDeNonValeur();
}

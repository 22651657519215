import { useSelector } from "react-redux";
import { permissionSurDerogation } from "./permissionSurDerogation";
import {
  identiteUtilisateur,
  referentiel,
} from "../../../../reduxStore/rootReducer";

export const usePeutDecider = (derogation) =>
  useSelector((state) =>
    permissionSurDerogation.peutDecider(
      derogation,
      identiteUtilisateur(state),
      referentiel(state)
    )
  );

export const ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE =
  "ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE";
export const accepterDerogationAssuranceEspagne = (derogation, decision) => ({
  type: ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE,
  derogation,
  decision,
});

export const REFUSER_DEROGATION_ASSURANCE_ESPAGNE =
  "REFUSER_DEROGATION_ASSURANCE_ESPAGNE";
export const refuserDerogationAssuranceEspagne = (derogation, decision) => ({
  type: REFUSER_DEROGATION_ASSURANCE_ESPAGNE,
  derogation,
  decision,
});

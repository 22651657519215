import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import { MoneyPropType } from "../../../../../Money/money";
import { pairePourcentageMontant } from "../../../../../utils/pourcentageUtils";
import { enEuros } from "../../../../../Kit/formatage";

export function ResumeDeDecision({ derogation }) {
  return (
    <div className="decision">
      <div>{avisBroker(derogation)}</div>
    </div>
  );
}

ResumeDeDecision.propTypes = {
  derogation: PropTypes.shape({
    dateDecision: PropTypes.string,
    derogateur: PropTypes.shape({ nom: PropTypes.string.isRequired }),
    valeurBroker: PropTypes.shape({
      pourcentage: PropTypes.number,
      montant: MoneyPropType,
    }),
    dateAvisBroker: PropTypes.string,
  }),
};

function avisBroker(derogation) {
  const avisDisponible = derogation.valeurBroker.pourcentage !== null;

  const avis = pairePourcentageMontant({
    pourcentage: derogation.valeurBroker.pourcentage,
    montant: derogation.valeurBroker.montant,
    base: derogation.depense.montantUnitaire,
  });

  return !avisDisponible ? (
    <Fragment />
  ) : (
    <div>
      <ul>
        <li>
          <b>
            <FormattedMessage
              id="derogation_de_vr.decisions.broker"
              values={{ broker: derogation.broker.nom }}
            />
          </b>
          <ul className="details-decision">
            <li>
              <FormattedMessage
                id="derogation_de_vr.decisions.broker.details"
                values={{
                  pourcentage: <b>{avis.pourcentage}%</b>,
                  montant: enEuros(avis.montant, ""),
                  dateAvis: <FormattedDate value={derogation.dateAvisBroker} />,
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="derogation_de_vr.decisions.commentaire"
                values={{ commentaire: derogation.commentaireBroker }}
              />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

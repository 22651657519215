import { httpServiceCrm } from "../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../utils/odata/odata";

export const marchesAdapterCrm = {
  async getMarche(idMarche, { http = httpServiceCrm } = {}) {
    const requete = odataQuery(`itl_marches(${idMarche})`, {
      $select: ["itl_marcheid", "itl_name"],
    });

    const marcheCrm = await http.get(requete);

    return {
      id: marcheCrm.itl_marcheid,
      nom: marcheCrm.itl_name,
    };
  },
};

import BadgeInfo from "../../../../Kit/Badges/Info/BadgeInfo";
import { FormattedMessage } from "react-intl";
import BadgeSucces from "../../../../Kit/Badges/Succes/BadgeSucces";
import { FormattedEnhancedMessage } from "react-intl-enhanced-message";
import BadgeWarning from "../../../../Kit/Badges/Warning/BadgeWarning";
import React from "react";
import { STATUT_DEROGATION } from "../../domaine/reducer";

const { ACCEPTEE, REFUSEE, A_DECIDER } = STATUT_DEROGATION;

export function StatutDerogationSimple({ derogation }) {
  return (
    <div className="pure-u-1">
      {derogation.statut === A_DECIDER && (
        <BadgeInfo>
          <FormattedMessage id="derogation.statut.a_decider" />
        </BadgeInfo>
      )}
      {derogation.statut === ACCEPTEE && (
        <BadgeSucces>
          <div>
            <FormattedEnhancedMessage
              id="derogation.statut.acceptee"
              enhancers={{
                strong: (texte) => <strong>{texte}</strong>,
              }}
              values={{
                dateDecision: jour(derogation.dateDecision),
                derogateur: derogation.derogateur.nom,
              }}
            />
          </div>
        </BadgeSucces>
      )}
      {derogation.statut === REFUSEE && (
        <BadgeWarning>
          <div>
            <FormattedEnhancedMessage
              id="derogation.statut.refusee"
              enhancers={{
                strong: (texte) => <strong>{texte}</strong>,
              }}
              values={{
                dateDecision: jour(derogation.dateDecision),
                derogateur: derogation.derogateur.nom,
              }}
            />
          </div>
        </BadgeWarning>
      )}
    </div>
  );
}

const jour = (dateDecision) => new Date(dateDecision).toLocaleDateString();

import { TERMES } from "../../finance/TERMES";
import {
  DELAIS_DE_REGLEMENT,
  MODES_DE_REGLEMENT,
} from "../../App/Calculateur/domaine/simulation/conditionsDeReglement/reducer";
import { STATUT_DEROGATION } from "../../App/Derogations/domaine/reducer";
import { PERIODICITES } from "../../App/Calculateur/domaine/simulation/modeDeCalcul/periodicite/periodicite";
import { TYPE_DE_FINANCEMENT } from "../../App/Calculateur/domaine/simulation/valeurResiduelle/reducer";
import { guidEqual } from "../../utils/guidUtils";

export const CODES_GROUPES_OPTIONS = {
  // Le CRM parle de "conditions de règlement" pour désigner
  // les "modes de règlement".
  // C'est ce qui explique que la Map sous cette clé référence
  // les Modes de règlement
  conditionsDeReglement: new Map([
    [MODES_DE_REGLEMENT.PRELEVEMENT_AUTOMATIQUE, 100_000_000],
    [MODES_DE_REGLEMENT.CHEQUE, 100_000_001],
    [MODES_DE_REGLEMENT.VIREMENT, 100_000_002],
    [MODES_DE_REGLEMENT.MANDAT, 100_000_003],
  ]),

  // Le CRM parle de "conditions de paiement" pour désigner
  // les "délais de réglement".
  // C'est ce qui explique que la Map sous cette clé référence
  // les Délais de règlement
  conditionsDePaiement: new Map([
    [DELAIS_DE_REGLEMENT.A_RECEPTION, 100_000_000],
    [DELAIS_DE_REGLEMENT.TRENTE_JOURS, 100_000_001],
    [DELAIS_DE_REGLEMENT.CINQUANTE_JOURS, 100_000_003], // Oui 50 et 60 sont inversés en CRM
    [DELAIS_DE_REGLEMENT.SOIXANTE_JOURS, 100_000_002],
    [DELAIS_DE_REGLEMENT.QUATREVINGTDIX_JOURS, 100_000_004],
  ]),

  methodesDeSaisieDesLoyers: {
    sansFraisDeReglement: 100_000_000,
  },
  periodicites: new Map([
    [PERIODICITES.MENSUEL, 100_000_000],
    [PERIODICITES.TRIMESTRIEL, 100_000_001],
    [PERIODICITES.ANNUEL, 100_000_002],
  ]),
  termes: new Map([[TERMES.A_ECHOIR, 100_000_000], [TERMES.ECHU, 100_000_001]]),
  typesDeMateriels: {
    materielAchete: 100_000_000,
    remisAuContrat: 100_000_003,
    commissionAVerser: 100_000_004,
    rachat: 100_000_005,
  },
  statutDeDerogation: new Map([
    [STATUT_DEROGATION.A_DECIDER, 100_000_000],
    [STATUT_DEROGATION.ACCEPTEE, 100_000_001],
    [STATUT_DEROGATION.REFUSEE, 100_000_002],
    [STATUT_DEROGATION.ACCORD_PARTIEL, 100_000_003],
  ]),
  TYPES_CONTRAT: {
    NOUVEAU_CT: 100_000_000,
    AER: 100_000_001,
    AVENANT: 100_000_002,
    PROLONGATION_NEGOCIEE: 100_000_003,
    CB: 100_000_004,
    MARCHE_PUBLIC: 100_000_005,
    CB_VENTE: 100_000_006,
  },
  typesDeRachats: {
    rachatContratItl: 100_000_000,
    rachatContratConcurrent: 100_000_001,
    achatReventeMateriel: 100_000_002,
  },
  etatEntite: {
    enCours: 0,
  },
};

const CGO = CODES_GROUPES_OPTIONS;
const { MENSUEL, TRIMESTRIEL, ANNUEL } = PERIODICITES;
const {
  PRELEVEMENT_AUTOMATIQUE,
  CHEQUE,
  VIREMENT,
  MANDAT,
} = MODES_DE_REGLEMENT;
const {
  A_RECEPTION,
  TRENTE_JOURS,
  CINQUANTE_JOURS,
  SOIXANTE_JOURS,
  QUATREVINGTDIX_JOURS,
} = DELAIS_DE_REGLEMENT;
const { A_ECHOIR, ECHU } = TERMES;
const { A_DECIDER, ACCEPTEE, REFUSEE, ACCORD_PARTIEL } = STATUT_DEROGATION;
const { CB, LLD } = TYPE_DE_FINANCEMENT;

export const MAPPING_CRM_VERS_DOMAINE = {
  statutDerogation: {
    [CGO.statutDeDerogation.get(A_DECIDER)]: A_DECIDER,
    [CGO.statutDeDerogation.get(ACCEPTEE)]: ACCEPTEE,
    [CGO.statutDeDerogation.get(REFUSEE)]: REFUSEE,
    [CGO.statutDeDerogation.get(ACCORD_PARTIEL)]: ACCORD_PARTIEL,
  },

  modesDeReglement: {
    [CGO.conditionsDeReglement.get(
      PRELEVEMENT_AUTOMATIQUE
    )]: PRELEVEMENT_AUTOMATIQUE,
    [CGO.conditionsDeReglement.get(CHEQUE)]: CHEQUE,
    [CGO.conditionsDeReglement.get(VIREMENT)]: VIREMENT,
    [CGO.conditionsDeReglement.get(MANDAT)]: MANDAT,
  },

  periodicites: {
    [CGO.periodicites.get(MENSUEL)]: MENSUEL,
    [CGO.periodicites.get(TRIMESTRIEL)]: TRIMESTRIEL,
    [CGO.periodicites.get(ANNUEL)]: ANNUEL,
  },

  delaisDeReglement: {
    [CGO.conditionsDePaiement.get(A_RECEPTION)]: A_RECEPTION,
    [CGO.conditionsDePaiement.get(TRENTE_JOURS)]: TRENTE_JOURS,
    [CGO.conditionsDePaiement.get(CINQUANTE_JOURS)]: CINQUANTE_JOURS,
    [CGO.conditionsDePaiement.get(SOIXANTE_JOURS)]: SOIXANTE_JOURS,
    [CGO.conditionsDePaiement.get(QUATREVINGTDIX_JOURS)]: QUATREVINGTDIX_JOURS,
  },

  termes: {
    [CGO.termes.get(ECHU)]: ECHU,
    [CGO.termes.get(A_ECHOIR)]: A_ECHOIR,
  },

  typesDeFinancement: {
    [process.env.REACT_APP_ID_TYPE_DE_FINANCEMENT_LLD.toUpperCase()]: LLD,
    [process.env.REACT_APP_ID_TYPE_DE_FINANCEMENT_CB.toUpperCase()]: CB,
  },
};

export const ID_MARQUE_LIZEMED = process.env.REACT_APP_ID_MARQUE_ITL_LIZEMED;
export let isLizemed = function(idMarqueItl) {
  return guidEqual(idMarqueItl, ID_MARQUE_LIZEMED);
};

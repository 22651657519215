export const ACCEPTER_DEROGATION_DE_MARGE = "ACCEPTER_DEROGATION_DE_MARGE";
export const accepterDerogationDeMarge = (derogation, decision, intl) => ({
  type: ACCEPTER_DEROGATION_DE_MARGE,
  derogation,
  decision,
  intl,
});

export const REFUSER_DEROGATION_DE_MARGE = "REFUSER_DEROGATION_DE_MARGE";
export const refuserDerogationDeMarge = (derogation, decision, intl) => ({
  type: REFUSER_DEROGATION_DE_MARGE,
  derogation,
  decision,
  intl,
});

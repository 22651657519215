import { call, put } from "redux-saga/effects";
import {
  operationEnCours,
  operationTerminee,
} from "../../../../../../Ui/actions";
import {
  INITIALISER_DEROGATION_DE_MARGE,
  viderLesDerogateursDeMarge,
} from "../actions";
import { recupererLeDerogateurMaitreSaga } from "./marge.derogateurMaitre.sagas";
import { recupererLeDerogateurDirecteurRegionalSaga } from "./marge.derogateurDirecteurRegional.sagas";

export function* initialiserDerogateursDeMargeSaga() {
  yield put(operationEnCours(INITIALISER_DEROGATION_DE_MARGE));
  yield put(viderLesDerogateursDeMarge());
  yield call(recupererLeDerogateurDirecteurRegionalSaga);
  yield call(recupererLeDerogateurMaitreSaga);
  yield put(operationTerminee(INITIALISER_DEROGATION_DE_MARGE));
}

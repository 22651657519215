import InputMontant from "../../../../Kit/Formulaires/InputMontant";
import { enEuros, pourcentage } from "../../../../Kit/formatage";
import React, { useState } from "react";

export function RecalculDeMarge({ recalculerLaMarge, idFt }) {
  const [margeRecalculee, setMargeRecalculee] = useState(null);

  return (
    <div className="recalcul-de-marge">
      <h4>Recalcul de marge</h4>
      <div>
        <label htmlFor="teg-input">Taux Refi : </label>
        <div>
          <div>
            <InputMontant
              onBlur={(value) =>
                recalculerLaMarge(idFt, Number((value / 100).toFixed(4))).then(
                  setMargeRecalculee
                )
              }
              className="input-s"
              append="%"
              testId="teg-input"
              placeholder=""
            />
          </div>
        </div>
        {margeRecalculee && (
          <div data-testid="marge-recalculee" className="marge-recalculee">
            <b>Marge recalculée :</b> {enEuros(margeRecalculee.montant, "")} (
            {pourcentage(margeRecalculee.taux)})
          </div>
        )}
      </div>
    </div>
  );
}

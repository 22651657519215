import { definirPrimeAssuranceEspagneManuelle } from "../../../domaine/simulation/assurance/espagne/actions";
import { connect } from "react-redux";
import {
  getMontantAAssurer,
  selectAssuranceEspagneEstVisible,
  selectPrimeAssuranceEspagne,
} from "../../../domaine/simulation/assurance/espagne/reducer";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import { orchestrerLePlanDeFinancement } from "../../../domaine/planDeFinancement/actions";
import { selectClient } from "../../../domaine/simulation/projetDeTravail/reducer";
import { AssuranceEspagneManuelle } from "./AssuranceEspagneManuelle";
import { valeurDuMontantAFinancer } from "../../../domaine/simulation/montantAFinancer/montants";
import { forInput } from "../../../../../Money/money";

const mapStateToProps = (state) => {
  const { simulation } = calculateur(state);
  const { assurance, projetDeTravail, profilUtilisateur } = simulation;

  return {
    estVisible: selectAssuranceEspagneEstVisible(profilUtilisateur),
    prime: selectPrimeAssuranceEspagne(assurance.espagne),
    montantAFinancer: forInput(
      valeurDuMontantAFinancer(simulation.montantAFinancer).toObject()
    ),
    periodicite: simulation.modeDeCalcul.periodicite,
    montantAAssurer: getMontantAAssurer(simulation).toObject(),
    client: selectClient(projetDeTravail),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (prime) => {
    dispatch(definirPrimeAssuranceEspagneManuelle(prime));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssuranceEspagneManuelle);

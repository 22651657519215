import { call, select } from "redux-saga/effects";
import { mapperLaDemandeDeDerogationDeMarge } from "./derogationDeMarge.mapping";
import { crmSendEmail } from "../../../../../../Connecteurs/Crm/sendEmail";
import { selectDerogateurDeMargeAInformer } from "../../../simulation/derogateurs/reducer";
import {
  mailInfoSurDerogationDeMarge,
  mailPourActionSurDerogationDeMarge,
} from "./derogationDeMarge.notifications";
import { selectMarcheUtilisateur } from "../../../simulation/profilUtilisateur/reducer";
import { Adapters } from "../../../../../../reduxStore/adapters";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { selectProjetDeTravail } from "../../../simulation/projetDeTravail/reducer";
import { chargerDerogationsDeMargeDuProjetSaga } from "../../../simulation/derogations/sagas";

export function* creerLaDemandeDeDerogationDeMargeSaga(
  ft,
  timestampCreation,
  {
    intl,
    derogationDeMargeAdapter = Adapters.creerDerogationDeMargeAdapter,
    identiteAdapter = Adapters.identiteAdapter,
    envoyerEmail = crmSendEmail,
  } = {}
) {
  // Charger les dérogations *avant* le mapping, comme ça le mapping
  // aura accès aux dérogations à jour, même si l'utilisateur n'a
  // pas rafraichit son navigateur depuis longtemps
  yield call(chargerDerogationsDeMargeDuProjetSaga);

  const calculateurDuState = yield select(calculateur);

  const mapping = yield call(
    mapperLaDemandeDeDerogationDeMarge,
    calculateurDuState,
    { intl }
  );

  const pasDeDerogationDeMarge = mapping.derogationMappee === null;
  if (pasDeDerogationDeMarge) return;

  const { simulation } = calculateurDuState;

  const derogationCreee = yield call(
    derogationDeMargeAdapter.creerDerogationDeMarge,
    mapping.derogationMappee,
    ft.id,
    selectProjetDeTravail(simulation.projetDeTravail).id,
    timestampCreation
  );

  // Recharge la dérogation créée à l'instant pour que Force la prenne en compte
  // si l'utilisateur continue à utiliser Force sans recharger la page.
  yield call(chargerDerogationsDeMargeDuProjetSaga);

  if (!mapping.notificationEstRequise) return;

  yield* notifierDerogateur(mapping, ft, derogationCreee, {
    identiteAdapter,
    envoyerEmail,
  });

  yield* notifierPourInfo(mapping, ft, derogationCreee, { envoyerEmail });
}

function* notifierDerogateur(
  mappingDerogation,
  ft,
  derogationCreee,
  { identiteAdapter, envoyerEmail }
) {
  const calculateurDuState = yield select(calculateur);
  const { simulation } = calculateurDuState;

  const { corps, sujet } = yield call(
    mailPourActionSurDerogationDeMarge,
    ft,
    {
      ...mappingDerogation.derogationMappee,
      itl_derogationid: derogationCreee.id,
    },
    calculateurDuState
  );

  const advDesCommerciaux = yield call(
    identiteAdapter.getAdvDesCommerciaux,
    selectMarcheUtilisateur(simulation.profilUtilisateur)
  );

  const enCopie = advDesCommerciaux.map(
    adv => `/systemusers(${adv.idUtilisateur})`
  );

  yield call(envoyerEmail, {
    sujet,
    corps,
    destinataire: `/systemusers(${mappingDerogation.derogateur.idUtilisateur})`,
    destinatairesCC: enCopie,
    regarding: {
      type: "itl_derogation",
      id: `/itl_derogations(${derogationCreee.id})`,
    },
  });
}

function* notifierPourInfo(
  mappingDerogation,
  ft,
  derogationCreee,
  { envoyerEmail }
) {
  const calculateurDuState = yield select(calculateur);

  const { simulation } = calculateurDuState;
  const derogateurDeMargeAInformer = yield call(
    selectDerogateurDeMargeAInformer,
    simulation.derogateurs,
    mappingDerogation.derogateur
  );

  if (!derogateurDeMargeAInformer) return;

  const { sujet, corps } = yield call(
    mailInfoSurDerogationDeMarge,
    ft,
    {
      ...mappingDerogation.derogationMappee,
      itl_derogationid: derogationCreee.id,
    },
    selectProjetDeTravail(simulation.projetDeTravail),
    mappingDerogation.derogateur.nom,
    calculateurDuState
  );

  yield call(envoyerEmail, {
    sujet,
    corps,
    destinataire: `/systemusers(${derogateurDeMargeAInformer.idUtilisateur})`,
    regarding: {
      type: "itl_derogation",
      id: `/itl_derogations(${derogationCreee.id})`,
    },
  });
}

import React from "react";
import PropTypes from "prop-types";
import BadgeErreur from "../Badges/Erreur/BadgeErreur";
import AuCentre from "../Layout/AuCentre";
import { useIntl } from "react-intl";

function Barriere(props) {
  const { messages, children } = props;
  const intl = useIntl();

  return messages.length > 0 ? (
    <AuCentre>
      <BadgeErreur messages={messagesToString(messages, intl)} />
    </AuCentre>
  ) : (
    children
  );
}

Barriere.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        intlId: PropTypes.string.isRequired,
        balises: PropTypes.object,
      }),
    ])
  ).isRequired,
};

export default Barriere;

function messagesToString(messages, intl) {
  return messages.map((m) => {
    const estIntl = Object.keys(m).includes("intlId");
    if (estIntl) {
      return intl.formatMessage({ id: m.intlId }, m.balises);
    } else {
      return m;
    }
  });
}

import { $obj } from "../../../../../Money/money";
import { LABELS_TERME_K3000 } from "../../../../../Connecteurs/K3000/labelsK3000";
import { nombreEcheances } from "../../planDeFinancement/paliers";
import {
  httpK3000,
  montantK3000,
} from "../../../../../Connecteurs/K3000/httpK3000";
import { LABELS_PERIODICITE_K3000 } from "../../../../../Connecteurs/K3000/labelsK3000";

export const maintenanceAdapterK3000 = {
  async maintenanceAvecEquilibrage(parametres, { http = httpK3000 } = {}) {
    const nbEcheances = nombreEcheances(parametres.paliers);

    const planK3000 = await http.post(
      "/api/v2/financier/loyer/avec-maintenance-lissee",
      {
        montantAFinancer: montantK3000(parametres.montantAFinancer),
        tegAnnuel: parametres.teg.toString(),
        montantValeurResiduelle: montantK3000(parametres.vr.montant),
        terme: LABELS_TERME_K3000[parametres.terme],
        typeDePeriodicite: LABELS_PERIODICITE_K3000[parametres.periodicite],
        montantMaintenanceTotal: montantK3000(
          parametres.maintenance.montantTotal
        ),
        nombreDEcheancesTotal: nbEcheances,
        nombreDEcheancesAvecMaintenance:
          nbEcheances - parametres.maintenance.nbEcheancesDeDecalage,
      }
    );

    const loyerFullService = planK3000.loyersService.montantLoyer;
    const loyerFinancierSecondPalier =
      planK3000.loyersFinancier[1].montantLoyer;

    return {
      montantParEcheance: $obj(loyerFullService - loyerFinancierSecondPalier),
      paliersFinanciers: planK3000.loyersFinancier.map(l => ({
        duree: l.nombreDEcheances,
        loyer: $obj(l.montantLoyer),
      })),
    };
  },
};

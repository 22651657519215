import React from "react";
import { FormattedMessage } from "react-intl";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import "./PaliersSouhaites.css";
import "./PaliersSouhaites-mobile.css";
import { BornesDeVr } from "./BornesDeVr";
import { NATURE_DU_CALCUL } from "../../domaine/simulation/modeDeCalcul/reducer";
import PaliersPourLoyer from "./PourLoyer/PaliersPourLoyer.container";
import PaliersPourInvestissement from "./PourInvestissement/PaliersPourInvestissement.container";

const { INVESTISSEMENT, LOYERS } = NATURE_DU_CALCUL;

export function PaliersSouhaites({
  calculDuPlanDeFinancementEnCours,
  bornesDeVr,
  natureDuCalcul,
}) {
  return (
    <div className="paliers-souhaites">
      <h3>
        <FormattedMessage id="calculateur.paliers" />
      </h3>
      {calculDuPlanDeFinancementEnCours ? (
        <BarreDeChargement size="S" />
      ) : (
        <div>
          <BornesDeVr bornesDeVr={bornesDeVr} />

          {natureDuCalcul === LOYERS && <PaliersPourLoyer />}
          {natureDuCalcul === INVESTISSEMENT && <PaliersPourInvestissement />}
        </div>
      )}
    </div>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { enEuros } from "../../../../../Kit/formatage";
import BadgeWarning from "../../../../../Kit/Badges/Warning/BadgeWarning";
import { ht } from "../../../../../i18n/raccourcis";

export function ResumeDeDerogation({ derogation }) {
  const intl = useIntl();
  return (
    <div className="resume">
      {derogation.depense.famille.estNonReferencee && (
        <BadgeWarning className="materiel-non-reference">
          <FormattedMessage id="derogation_de_vr.famille_non_referencee" />
        </BadgeWarning>
      )}
      <ul>
        <Item cle="nom_du_projet" valeur={derogation.projet.nom} />
        <Item cle="nom_du_client" valeur={derogation.projet.client.nom} />
        <Item cle="motivation" valeur={derogation.motivation} />
        <Item
          cle="materiels_concernes"
          valeur={
            derogation.descriptionMaterielsConcernes || "(Non renseignée)"
          }
        />
        <Item
          cle="montant_du_materiel"
          valeur={enEuros(derogation.depense.montantUnitaire, ht(intl))}
        />
        <Item
          cle="duree_du_contrat"
          valeur={intl.formatMessage(
            { id: "derogation_de_vr.duree_du_contrat.en_mois" },
            { dureeEnMois: derogation.ficheDeTransmission.dureeEnMois }
          )}
        />
        <Item
          cle="type_de_financement"
          valeur={derogation.ficheDeTransmission.typeDeFinancement}
        />

        {derogation.depense.estOccasion && (
          <Item
            cle="materiel_occasion"
            valeur={
              <FormattedMessage
                id="derogation_de_vr.materiel_occasion_annee"
                values={{ annee: derogation.depense.annee }}
              />
            }
          />
        )}
      </ul>
    </div>
  );
}

ResumeDeDerogation.propTypes = {
  derogation: PropTypes.object.isRequired,
};

function Item({ cle, valeur }) {
  return (
    <li>
      <b>
        <FormattedMessage id={`derogation_de_vr.${cle}`} /> :{" "}
      </b>
      {valeur}
    </li>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./LienVersAccueil.css";
import { UrlsForce } from "../../../../routing/Urls";

export function LienVersAccueil() {
  return (
    <div className="retour-accueil-derogations">
      <Link to={UrlsForce.derogations().path}>
        <div className="pure-button pure-button-primary">
          <FontAwesomeIcon icon={faArrowLeft} className="mr-10" />
          <FormattedMessage id="derogations.navigation.retour_accueil" />
        </div>
      </Link>
    </div>
  );
}

import { i18n } from "../../../../i18n/i18n";
import { selectMarcheUtilisateur } from "../../domaine/simulation/profilUtilisateur/reducer";
import { selectInfosDeMarge } from "../../domaine/simulation/montantAFinancer/montants";
import {
  listeDesProduitsAchetes,
  listeDesRachatsDeContrats,
} from "../../domaine/simulation/montantAFinancer/reducer";
import { labelCatalogue } from "../../domaine/simulation/catalogueProduits/reducer";
import { enEuros } from "../../../../Kit/formatage";
import { ht } from "../../../../i18n/raccourcis";
import { MODE_AFFICHAGE } from "../../../../Ui/reducer";
import { nomDuTypeDeFinancementActif } from "../../domaine/simulation/valeurResiduelle/selecteurs/generalites.selecteurs";
import { projetDeTravailEstFictif } from "../../../ModeLight/domaine/contenuFictifModeLight";
import {
  selectMarcheChoisi,
  selectProjetDeTravail,
} from "../../domaine/simulation/projetDeTravail/reducer";
import { guidEqual } from "../../../../utils/guidUtils";
import { selectPaliersLoyersFullService } from "../../domaine/planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

const { CLASSIQUE, LIGHT } = MODE_AFFICHAGE;

export const resumeDeCalculateur = (calculateurDeInstantane, projetActif) => {
  const { simulation, planDeFinancement } = calculateurDeInstantane;

  const intl = i18n.getIntl(
    selectMarcheUtilisateur(simulation.profilUtilisateur)
  );

  const impossible = {
    erreur: intl.formatMessage({ id: "calculateur.instantanes.inutilisable" }),
  };

  try {
    if (simulationEstObsolete(simulation)) return impossible;

    return {
      titre:
        selectProjetDeTravail(simulation.projetDeTravail)?.nom ??
        selectProjetDeTravail(simulation.projetDeTravail).marqueItl.nom,
      modeAffichage: projetDeTravailEstFictif(
        selectProjetDeTravail(simulation.projetDeTravail)
      )
        ? LIGHT
        : CLASSIQUE,
      paliers: selectPaliersLoyersFullService(planDeFinancement),
      infosMarge: selectInfosDeMarge(simulation.montantAFinancer),
      typeDeFinancement: nomDuTypeDeFinancementActif(
        simulation.valeurResiduelle,
        selectMarcheUtilisateur(simulation.profilUtilisateur)
      ),
      produits: listeDesProduitsAchetes(simulation.montantAFinancer)
        .concat(listeDesRachatsDeContrats(simulation.montantAFinancer))
        .sort((a, b) => a.timestamp - b.timestamp)
        .map(produit =>
          resumeDeProduit(produit, simulation.catalogueProduits, intl)
        ),
      blocage: getBlocageDeRestauration(simulation, projetActif),
    };
  } catch (e) {
    return impossible;
  }
};

export function simulationEstObsolete(simulation) {
  const echeancesAuMauvaisEndroit = simulation.echeances !== undefined;
  const catalogueProduitObsolete =
    simulation.catalogueProduits.produits === undefined ||
    simulation.catalogueProduits.selectionDesMaterielsDisponibles === undefined;
  const valeurResiduelleNonStockeeAPlat =
    simulation.valeurResiduelle.vrs === undefined;
  const derogateursHorsSimulation = simulation.derogateurs === undefined;
  const profilUtilisateurSansMarche =
    simulation.profilUtilisateur.marcheUtilisateur === undefined;
  const projetDeTravailHorsCalculateur =
    simulation.projetDeTravail === undefined;
  const derogateursAvecLatitude =
    simulation.derogateurs.derogateursDeMarge.find(
      d => d.latitude !== undefined
    ) !== undefined;
  const derogateursSansPoste =
    simulation.derogateurs.derogateursDeMarge.find(
      d => d.poste === undefined
    ) !== undefined;
  const avecMontantDeMarge =
    simulation.montantAFinancer.margeDeBase.montant !== undefined;
  const avecMargeEnTauxDeSimulation =
    simulation.montantAFinancer.margeDeBase.tauxDeSimulation !== undefined;
  const avecClientSansRisque =
    simulation.projetDeTravail?.projetDeTravail?.client?.risque === undefined;
  const sansMaintenance = simulation.maintenance === undefined;
  const avecEstAjouteeSurAssuranceEspagne =
    simulation.assurance.espagne.estAjoutee !== undefined;
  const catalogueSansIdCrm = Object.values(
    simulation.catalogueProduits.produits.parId
  ).some(p => p.crm === undefined);

  const simulationObsolete =
    echeancesAuMauvaisEndroit ||
    catalogueProduitObsolete ||
    valeurResiduelleNonStockeeAPlat ||
    derogateursHorsSimulation ||
    profilUtilisateurSansMarche ||
    projetDeTravailHorsCalculateur ||
    derogateursAvecLatitude ||
    derogateursSansPoste ||
    avecMontantDeMarge ||
    avecMargeEnTauxDeSimulation ||
    avecClientSansRisque ||
    sansMaintenance ||
    avecEstAjouteeSurAssuranceEspagne ||
    catalogueSansIdCrm;

  return simulationObsolete;
}

export const resumeDeProduit = (produit, catalogueProduits, intl) => {
  const descriptionConstruite = [];

  if (produit.description) descriptionConstruite.push(produit.description);
  else if (produit.idFamille)
    descriptionConstruite.push(labelCatalogue(catalogueProduits, produit));

  if (produit.montantUnitaire)
    descriptionConstruite.push(enEuros(produit.montantUnitaire, ht(intl)));

  return descriptionConstruite.join(" - ");
};

function getBlocageDeRestauration(simulationDeInstantane, projetActif) {
  const projetDeInstantane = selectProjetDeTravail(
    simulationDeInstantane.projetDeTravail
  );

  const lesDeuxModesSontDifferents =
    projetDeTravailEstFictif(projetActif) !==
    projetDeTravailEstFictif(projetDeInstantane);

  const marchesDifferents = !guidEqual(
    selectMarcheChoisi(simulationDeInstantane.projetDeTravail).id,
    projetActif.marche.id
  );

  if (lesDeuxModesSontDifferents && marchesDifferents)
    return "calculateur.instantanes.blocage.marche_different";
}

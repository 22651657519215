import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import InputMontant from "../../../../Kit/Formulaires/InputMontant";
import Textarea from "../../../../Kit/Formulaires/Textarea";
import Euros from "../../../../Kit/Finance/Euros";
import {
  $obj,
  forInput,
  isNullMoneyObject,
  MoneyPropType,
} from "../../../../Money/money";
import { Carte } from "../../../../Kit/Carte/Carte";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

function UneCommission({
  onDelete,
  commission,
  onChangeMontant,
  onChangeDescription,
  estEnModeLight,
}) {
  return (
    <Carte titre="Commission">
      <div className="commission-contenu">
        <div>
          <InputMontant
            focusOnMount={isNullMoneyObject(commission.montant)}
            value={forInput(commission.montant)}
            onBlur={(nouveauMontant) =>
              onChangeMontant({ ...commission, montant: $obj(nouveauMontant) })
            }
            append={<Euros />}
            className="input-m"
          />
          {commission.pourcentage && (
            <span className="flex-ai-center ml-5">
              {commission.pourcentage} %
            </span>
          )}
        </div>
        {!estEnModeLight && (
          <div>
            <Label
              labelInitial={commission.label || ""}
              onChange={(nouveauLabel) =>
                onChangeDescription({ ...commission, label: nouveauLabel })
              }
            />
          </div>
        )}
        <button
          className="pure-button button-error button-small"
          onClick={() => onDelete(commission)}
        >
          <FontAwesomeIcon icon={faTimesCircle} className="mr-5" />
          <FormattedMessage id="general.supprimer" />
        </button>
      </div>
    </Carte>
  );
}

UneCommission.propTypes = {
  commission: PropTypes.shape({
    id: PropTypes.string.isRequired,
    montant: MoneyPropType,
    label: PropTypes.string,
  }),
  onChangeMontant: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  estEnModeLight: PropTypes.bool.isRequired,
};

export default UneCommission;

function Label({ labelInitial, onChange }) {
  const [label, setLabel] = useState(labelInitial);

  return (
    <Textarea
      value={label}
      onChange={(value) => setLabel(value)}
      onBlur={() => onChange(label)}
      placeholder="Label"
      rows={3}
    />
  );
}

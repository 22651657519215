import { POSTE_UTILISATEUR } from "../../../identiteUtilisateur/reducer";

const { DIRECTEUR_COMMERCIAL } = POSTE_UTILISATEUR;

export function PROJET_DE_TRAVAIL_FICTIF_MODE_LIGHT(marqueItl, marche) {
  return {
    estFictif: true,
    client: {
      estFictif: true,
      estNouveau: false,
      tva: { recupereLaTva: true, tauxApplique: 0.2 },
      risque: { estRisque: false },
    },
    marqueItl,
    marche,
  };
}

export function projetDeTravailEstFictif(projetDeTravail) {
  return projetDeTravail.estFictif;
}

export const DEROGATEUR_DE_MARGE_FICTIF_MODE_LIGHT = {
  autonomieDeDerogation: 0,
  poste: DIRECTEUR_COMMERCIAL,
  estFictif: true,
};

// Utilise la technique décrite en
// https://docs.microsoft.com/en-us/powerapps/developer/common-data-service/webapi/impersonate-another-user-web-api
export const impersonifieurCrm = {
  impersonifier(utilisateurCible) {
    this._cible = utilisateurCible;
  },
  headerHttp() {
    if (this._cible !== undefined)
      return { MSCRMCallerID: this._cible.idUtilisateur };
  },
};

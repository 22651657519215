import React from "react";
import "./NatureDuCalcul.css";
import "./NatureDuCalcul-mobile.css";

import { FormattedMessage } from "react-intl";
import { NATURE_DU_CALCUL } from "../../domaine/simulation/modeDeCalcul/reducer";

const { LOYERS, INVESTISSEMENT } = NATURE_DU_CALCUL;

const NatureDuCalcul = ({
  onChange,
  natureActive,
  natureInvestissementEstDisponible,
}) => (
  <div className="nature-du-calcul">
    <h3>
      <FormattedMessage id="calculateur.nature_du_calcul" />
    </h3>
    <input
      type="radio"
      name="naure_du_calcul"
      id="loyers"
      onChange={() => onChange(LOYERS)}
      defaultChecked={natureActive === LOYERS}
    />
    <label htmlFor="loyers">
      <FormattedMessage id="calculateur.nature_du_calcul.loyers" />
    </label>

    <input
      type="radio"
      name="naure_du_calcul"
      id="investissement"
      onChange={() => onChange(INVESTISSEMENT)}
      defaultChecked={natureActive === INVESTISSEMENT}
      disabled={!natureInvestissementEstDisponible}
    />
    <label htmlFor="investissement">
      <FormattedMessage id="calculateur.nature_du_calcul.investissement" />
    </label>
  </div>
);

export default NatureDuCalcul;

import React from "react";
import PropTypes from "prop-types";
import { enEuros } from "../../../../../../Kit/formatage";
import { MoneyPropType } from "../../../../../../Money/money";

export function ChoixDeVr({ valeur, checked, onChoice, label }) {
  return (
    <div className="un-choix-de-vr">
      <div className="label-du-choix">
        <label>
          <input
            type="radio"
            name="valeur"
            value={valeur.pourcentage}
            checked={checked}
            onChange={() => onChoice()}
            className="mr-10"
          />
          {label}
        </label>
      </div>
      <div>{valeur.pourcentage}%</div>
      <div>{enEuros(valeur.montant, "")}</div>
    </div>
  );
}

ChoixDeVr.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valeur: PropTypes.shape({
    pourcentage: PropTypes.number,
    montant: MoneyPropType,
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  onChoice: PropTypes.func.isRequired,
};

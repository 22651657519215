import React from "react";
import PropTypes from "prop-types";
import FaExclamationTriangle from "react-icons/lib/fa/exclamation-triangle";
import ReactTooltip from "react-tooltip";
import "./Anomalies.css";
import { MessageAnomalie } from "./MessageAnomalie";

const Anomalies = ({
  anomalies,
  boutonQuiRemue,
  className = "",
  label,
  simulation,
}) => (
  <div className={className}>
    <button
      data-tip
      data-for="details-anomalies"
      data-event="click"
      style={{ whiteSpace: "normal" }}
      className={`pure-button button-warning ${
        boutonQuiRemue ? "bouton-qui-remue" : ""
      }`}
    >
      <FaExclamationTriangle style={{ marginRight: "5px" }} />
      {label}
    </button>
    <ReactTooltip
      type="light"
      effect="solid"
      id="details-anomalies"
      place="bottom"
      className="tooltip-details-anomalies"
    >
      <ul>
        {anomalies.map((a, index) => (
          <li key={index}>
            <MessageAnomalie simulation={simulation} anomalie={a} />
          </li>
        ))}
      </ul>
    </ReactTooltip>
  </div>
);

Anomalies.propTypes = {
  simulation: PropTypes.object.isRequired,
  anomalies: PropTypes.array.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  boutonQuiRemue: PropTypes.bool,
  className: PropTypes.string,
};

export default Anomalies;

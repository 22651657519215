import { put } from "redux-saga/effects";
import {
  effacerErreur,
  erreurEstSurvenue,
  operationEnCours,
  operationTerminee,
} from "../Ui/actions";

export function* trySaga(saga, operation) {
  try {
    yield put(operationEnCours(operation));
    yield put(effacerErreur(operation));

    yield* saga();
  } catch (e) {
    yield put(erreurEstSurvenue(operation, e));
  } finally {
    yield put(operationTerminee(operation));
  }
}

import ErreursDeCreation from "./ErreursDeCreation";
import { connect } from "react-redux";
import { erreurDeOperation } from "../../../../../Ui/reducer";
import {
  RECUPERER_DEROGATEUR_DE_MARGE_DIRECTEUR_REGIONAL,
  RECUPERER_DEROGATEUR_DE_MARGE_MAITRE,
} from "../../../domaine/simulation/derogateurs/actions";
import { CREER_FICHE_DE_TRANSMISSION } from "../../../domaine/creationDeFt/actions";
import { ORCHESTRER_PLAN_DE_FINANCEMENT } from "../../../domaine/planDeFinancement/actions";

const mapStateToProps = (state) => ({
  toutesLesErreurs: erreurDeOperation(state.ui, [
    RECUPERER_DEROGATEUR_DE_MARGE_MAITRE,
    RECUPERER_DEROGATEUR_DE_MARGE_DIRECTEUR_REGIONAL,
    CREER_FICHE_DE_TRANSMISSION,
    ORCHESTRER_PLAN_DE_FINANCEMENT,
  ]),
});

export default connect(mapStateToProps)(ErreursDeCreation);

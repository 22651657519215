export const CHARGER_LES_DEROGATIONS_DE_MARGE =
  "CHARGER_LES_DEROGATIONS_DE_MARGE";
export function chargerLesDerogationsDeMarge(derogations) {
  return {
    type: CHARGER_LES_DEROGATIONS_DE_MARGE,
    derogations: derogations.map(arrondirTaux),
  };
}

function arrondirTaux(derogationDeMarge) {
  return {
    ...derogationDeMarge,
    taux: {
      origine: Number(derogationDeMarge.taux.origine.toFixed(4)),
      demande: Number(derogationDeMarge.taux.demande.toFixed(4)),
    },
  };
}

export const AJOUTER_DEROGATION_DE_VR = "AJOUTER_DEROGATION_DE_VR";
export const ajouterDerogationDeVr = (idProduit, derogation) => ({
  type: AJOUTER_DEROGATION_DE_VR,
  idProduit,
  derogation,
});

export const AJOUTER_NOTIFICATION_DE_DEROGATION_DE_VR =
  "AJOUTER_NOTIFICATION_DE_DEROGATION_DE_VR";
export const ajouterNotificationDeDerogationDeVr = (notification) => ({
  type: AJOUTER_NOTIFICATION_DE_DEROGATION_DE_VR,
  notification,
});

export const AJOUTER_DEROGATION_DE_TEG = "AJOUTER_DEROGATION_DE_TEG";
export const ajouterDerogationDeTeg = (derogation) => ({
  type: AJOUTER_DEROGATION_DE_TEG,
  derogation,
});

export const AJOUTER_DEROGATION_ASSURANCE_ESPAGNE =
  "AJOUTER_DEROGATION_ASSURANCE_ESPAGNE";
export const ajouterDerogationAssuranceEspagne = (derogation) => ({
  type: AJOUTER_DEROGATION_ASSURANCE_ESPAGNE,
  derogation,
});

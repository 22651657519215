export const ANOMALIES = {
  LOYER_NEGATIF: "LOYER_NEGATIF",
  MARGE_TROP_FAIBLE: "MARGE_TROP_FAIBLE",
  MATERIEL_INCOMPLET: "MATERIEL_INCOMPLET",
  MATERIEL_NON_REFERENCE_DESCRIPTION_TROP_LONGUE:
    "MATERIEL_NON_REFERENCE_DESCRIPTION_TROP_LONGUE",
  MAINTENANCE_TROP_LONGUE: "MAINTENANCE_TROP_LONGUE",
  PAS_DE_TEG_GRILLE: "PAS_DE_TEG_GRILLE",
  RACHAT_DE_CONTRAT_INCOMPLET: "RACHAT_DE_CONTRAT_INCOMPLET",
  RACHAT_MATERIEL_INCOMPLET: "RACHAT_MATERIEL_INCOMPLET",
  TAUX_DE_VR_NECESSITE_DEROGATION: "TAUX_DE_VR_NECESSITE_DEROGATION",
  TEG_MANUEL_INFERIEUR_A_GRILLE: "TEG_MANUEL_INFERIEUR_A_GRILLE",
  TROP_DE_NON_VALEUR: "TROP_DE_NON_VALEUR",
  VR_DEJA_REFUSEE: "VR_DEJA_REFUSEE",
  VR_REFUSEE_CATEGORIQUEMENT: "VR_REFUSEE_CATEGORIQUEMENT",
  ASSURANCE_ESPAGNE_MANQUANTE: "ASSURANCE_ESPAGNE_MANQUANTE",
  ASSURANCE_ESPAGNE_INSUFFISANTE: "ASSURANCE_ESPAGNE_INSUFFISANTE",
  ASSURANCE_ESPAGNE_DEJA_REFUSEE: "ASSURANCE_ESPAGNE_DEJA_REFUSEE",
};

const {
  LOYER_NEGATIF,
  MARGE_TROP_FAIBLE,
  MATERIEL_INCOMPLET,
  MATERIEL_NON_REFERENCE_DESCRIPTION_TROP_LONGUE,
  MAINTENANCE_TROP_LONGUE,
  PAS_DE_TEG_GRILLE,
  RACHAT_DE_CONTRAT_INCOMPLET,
  RACHAT_MATERIEL_INCOMPLET,
  TAUX_DE_VR_NECESSITE_DEROGATION,
  TEG_MANUEL_INFERIEUR_A_GRILLE,
  TROP_DE_NON_VALEUR,
  VR_DEJA_REFUSEE,
  VR_REFUSEE_CATEGORIQUEMENT,
  ASSURANCE_ESPAGNE_MANQUANTE,
  ASSURANCE_ESPAGNE_INSUFFISANTE,
  ASSURANCE_ESPAGNE_DEJA_REFUSEE,
} = ANOMALIES;

export function AnomalieRachatDeMaterielIncomplet() {
  return { idType: RACHAT_MATERIEL_INCOMPLET };
}

export function AnomalieRachatDeContratIncomplet() {
  return { idType: RACHAT_DE_CONTRAT_INCOMPLET };
}

export function AnomalieMargeTropFaible(tauxMinimalAutonomie, tauxDuPlan) {
  return {
    idType: MARGE_TROP_FAIBLE,
    details: { tauxMinimalAutonomie, tauxDuPlan },
    estDerogeable: true,
  };
}

export function AnomalieVrNecessiteDerogation(produit, vrSeuil, vrManuelle) {
  return {
    idType: TAUX_DE_VR_NECESSITE_DEROGATION,
    details: {
      produit: {
        id: produit.id,
        idFamille: produit.idFamille,
        idMarque: produit.idMarque,
        estOccasion: produit.estOccasion,
        description: produit.description,
      },
      vr: { manuelle: vrManuelle, seuil: vrSeuil },
    },
    estDerogeable: true,
  };
}

export function AnomalieTropDeNonValeur() {
  return { idType: TROP_DE_NON_VALEUR };
}

export function AnomalieMaterielIncomplet() {
  return { idType: MATERIEL_INCOMPLET };
}

export function AnomalieMaterielNonReferenceDescriptionTropLongue() {
  return { idType: MATERIEL_NON_REFERENCE_DESCRIPTION_TROP_LONGUE };
}

export function AnomaliePasDeTegGrille() {
  return { idType: PAS_DE_TEG_GRILLE };
}

export function AnomalieVrDejaRefusee() {
  return { idType: VR_DEJA_REFUSEE };
}

export function AnomalieLoyerNegatif() {
  return { idType: LOYER_NEGATIF };
}

export function AnomalieMaintenanceTropLongue() {
  return { idType: MAINTENANCE_TROP_LONGUE };
}

export function AnomalieTegManuelInferieurAGrille(tegGrille, tegManuel) {
  return {
    idType: TEG_MANUEL_INFERIEUR_A_GRILLE,
    estDerogeable: true,
    details: { tegGrille, tegManuel },
  };
}
export function AnomalieVrRefuseeCategoriquement() {
  return { idType: VR_REFUSEE_CATEGORIQUEMENT };
}

export function AnomalieAssuranceEspagneManquante() {
  return { idType: ASSURANCE_ESPAGNE_MANQUANTE };
}

export function AnomalieAssuranceEspagneDejaRefusee() {
  return { idType: ASSURANCE_ESPAGNE_DEJA_REFUSEE };
}

export function AnomalieAssuranceEspagneInsuffisante(
  primeMinimale,
  primeSaisie
) {
  return {
    idType: ASSURANCE_ESPAGNE_INSUFFISANTE,
    estDerogeable: true,
    details: { primeMinimale, primeSaisie },
  };
}

export const selectAnomaliesBloquantes = (anomalies) =>
  anomalies.filter((anomalie) => !anomalie.estDerogeable);

export const selectAnomaliesDerogeables = (anomalies, dejaDerogee) =>
  anomalies.filter(
    (anomalie) => anomalie.estDerogeable && anomalie.dejaDerogee === dejaDerogee
  );

export const selectAnomalieDeMargeNonDerogee = (anomalies) =>
  anomalies.filter(
    (anomalie) => anomalie.idType === MARGE_TROP_FAIBLE && !anomalie.dejaDerogee
  );

export const selectAnomalieDeVrDuProduit = (anomalies, idProduit) =>
  anomalies.find(
    (anomalie) =>
      anomalie.idType === TAUX_DE_VR_NECESSITE_DEROGATION &&
      anomalie.details.produit.id === idProduit
  );

export function selectAnomalieDeTeg(anomalies) {
  return selectAnomalieDuType(TEG_MANUEL_INFERIEUR_A_GRILLE, anomalies);
}

export function selectAnomalieAssuranceEspagneInsuffisante(anomalies) {
  return selectAnomalieDuType(ASSURANCE_ESPAGNE_INSUFFISANTE, anomalies);
}

export function selectAnomaliesDeTauxHorsGrille(anomalies) {
  return anomalies.filter((a) => a.idType === PAS_DE_TEG_GRILLE);
}

export function selectAnomalieDuType(typeAnomalie, anomalies) {
  return anomalies.find((a) => a.idType === typeAnomalie);
}

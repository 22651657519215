import {
  PAS_DE_DEROGATION,
  selectDerogationDeMargeCouvrantLeTaux,
} from "../../simulation/derogations/reducer";
import { tauxDeMargeEffectif } from "../../simulation/montantAFinancer/montants";

export function affecterDerogationDeMarge(anomalie, simulation) {
  const { montantAFinancer, derogations } = simulation;
  const tauxDuPlan = tauxDeMargeEffectif(montantAFinancer);

  const derogationAnterieure = selectDerogationDeMargeCouvrantLeTaux(
    derogations,
    tauxDuPlan
  );

  return {
    ...anomalie,
    derogation: derogationAnterieure,
    dejaDerogee: derogationAnterieure !== PAS_DE_DEROGATION,
  };
}

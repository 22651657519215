import {
  listeDesProduitsAchetes,
  listeDesRachatsDeContrats,
} from "../../../simulation/montantAFinancer/reducer";
import { selectNaPasDeTegEffectif } from "../../../simulation/tauxInteret/reducer";
import { selectModeDeCalcul } from "../../../simulation/modeDeCalcul/reducer";
import { valeurDuMontantAFinancer } from "../../../simulation/montantAFinancer/montants";
import { selectMarcheUtilisateur } from "../../../simulation/profilUtilisateur/reducer";

export const IMPOSSIBILITE_DE_CALCULER = {
  MODE_DE_CALCUL_ABSENT: "MODE_DE_CALCUL_ABSENT",
  RIEN_A_FINANCER: "RIEN_A_FINANCER",
  PAS_DE_DUREE_DE_FINANCEMENT: "PAS_DE_DUREE_DE_FINANCEMENT",
  TEG_ABSENT: "TEG_ABSENT",
};

const {
  MODE_DE_CALCUL_ABSENT,
  RIEN_A_FINANCER,
  PAS_DE_DUREE_DE_FINANCEMENT,
  TEG_ABSENT,
} = IMPOSSIBILITE_DE_CALCULER;

export default function planDeFinancementEstCalculable(simulation) {
  return blocagesDuCalculDuPlan(simulation).length === 0;
}

export function blocagesDuCalculDuPlan(simulation) {
  const blocages = [
    pasDeModeDeCalcul(simulation),
    rienAFinancer(simulation),
    pasDeDureeDeFinancement(simulation),
    pasDeTegEffectif(simulation),
  ].filter((b) => b !== undefined);

  return blocages;
}

function pasDeModeDeCalcul({ modeDeCalcul }) {
  const pasDeModeDeCalcul = selectModeDeCalcul(modeDeCalcul) === undefined;
  if (pasDeModeDeCalcul) return MODE_DE_CALCUL_ABSENT;
}

function rienAFinancer({ montantAFinancer }) {
  const aucunElement =
    listeDesProduitsAchetes(montantAFinancer).length === 0 &&
    listeDesRachatsDeContrats(montantAFinancer).length === 0;

  const aucunMontant = valeurDuMontantAFinancer(montantAFinancer).isZero();

  if (aucunElement || aucunMontant) return RIEN_A_FINANCER;
}

function pasDeDureeDeFinancement({ modeDeCalcul }) {
  const { paliers } = modeDeCalcul;
  const pasDeDuree = paliers.length === 0 || paliers[0].duree <= 0;
  if (pasDeDuree) return PAS_DE_DUREE_DE_FINANCEMENT;
}

function pasDeTegEffectif({ tauxInteret, profilUtilisateur }) {
  const pasDeTeg = selectNaPasDeTegEffectif(
    tauxInteret,
    selectMarcheUtilisateur(profilUtilisateur)
  );
  if (pasDeTeg) return TEG_ABSENT;
}

import { PAS_DE_DEROGATION } from "../../reducer";
import { selectInvaliditesDerogationDeVr } from "./selectInvaliditesDerogationDeVr";

export function enrichirAvecInvalidites(derogationDeVr, simulation) {
  if (derogationDeVr === PAS_DE_DEROGATION) return derogationDeVr;

  return {
    ...derogationDeVr,
    invalidites: selectInvaliditesDerogationDeVr(derogationDeVr, simulation),
  };
}

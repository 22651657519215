import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { STATUT_DEROGATION } from "../../../../../../Derogations/domaine/reducer";
import { pourcentage } from "../../../../../../../Kit/formatage";
import { statutDeLaDecision } from "../../../../../../Derogations/domaine/typesDerogations/vr/statutDeLaDecision";
import { MessageInvalidite } from "./MessageInvalidite";
import { invaliditesPriorisees } from "./invaliditesPriorisees";
import { TYPES_INVALIDITES_DEROGATION_DE_VR } from "../../../../../domaine/simulation/derogations/vr/invalidites/Invalidites";
import { estRefuseeCategoriquement } from "../../../../../domaine/simulation/derogations/reducer";
import { SupprimerDemandeDeVr } from "./SupprimerDemandeDeVr";

const { TYPE_DE_FINANCEMENT_MODIFIE } = TYPES_INVALIDITES_DEROGATION_DE_VR;
const { ACCEPTEE, ACCORD_PARTIEL, REFUSEE } = STATUT_DEROGATION;

const BadgeDerogationDeVr = ({
  anomalieDeVr,
  derogationDeVr,
  onSupprimerDemandeDeVr,
  afficherSuppression,
}) => {
  if (!anomalieDeVr && !derogationDeVr) return <Fragment />;
  if (derogationDeVr?.estFictive) return <Fragment />;

  // L'utilisateur demande une VR supérieure
  if (anomalieDeVr) {
    const { vr } = anomalieDeVr.details;
    const { derogation } = anomalieDeVr;

    return (
      <Contenu>
        <div className="flex-ai-center">
          <VrDemandee pourcentage={pourcentage(vr.manuelle)} />

          {derogation && derogation.invalidites.length === 0 ? (
            <VrObtenue derogation={derogation} />
          ) : (
            <VrEnAttente />
          )}

          {afficherSuppression && (
            <SupprimerDemandeDeVr onClick={onSupprimerDemandeDeVr} />
          )}
        </div>

        {derogation && derogation.invalidites.length > 0 && (
          <InvaliditesDeDerogation invalidites={derogation.invalidites} />
        )}
      </Contenu>
    );
  }

  // L'utilisateur ne demande pas de VR supérieure dans l'IHM ce jour, mais il a une dérogation.
  // Donc c'est qu'il a demandé une VR supérieure à un moment.
  // Donc on lui affiche les infos de la dérogation.
  if (derogationDeVr) {
    const { aRecuDecision, valeurDemandee, invalidites } = derogationDeVr;
    const afficherBadge = invalidites.length === 0;
    return (
      <Contenu>
        {afficherBadge && aRecuDecision && (
          <VrObtenue derogation={derogationDeVr} />
        )}

        {afficherBadge && !aRecuDecision && (
          <>
            <VrDemandee pourcentage={pourcentage(valeurDemandee)} />
            <VrEnAttente />
          </>
        )}

        {invalidites.length > 0 && (
          <InvaliditesDeDerogation invalidites={invalidites} />
        )}
      </Contenu>
    );
  }
};

export default BadgeDerogationDeVr;

function VrDemandee({ pourcentage }) {
  return (
    <span className="tag mr-10" data-testid="badge-derogation-de-vr-demande">
      <FormattedMessage
        id="calculateur.un_produit.derogation_de_vr.demandee"
        values={{ pourcentage }}
      />
    </span>
  );
}

function VrEnAttente() {
  return (
    <span
      className="tag button-secondary"
      data-testid="badge-derogation-de-vr-decision"
    >
      <FormattedMessage id="calculateur.un_produit.derogation_de_vr.en_attente" />
    </span>
  );
}

function VrObtenue({ derogation }) {
  function classeDuBadge() {
    const classes = {
      [REFUSEE]: "button-error",
      [ACCEPTEE]: "button-success",
      [ACCORD_PARTIEL]: "button-warning",
    };
    const statut = statutDeLaDecision(derogation, derogation.valeurBroker);
    return classes[statut];
  }

  return (
    <span
      className={`tag ${classeDuBadge()}`}
      data-testid="badge-derogation-de-vr-decision"
    >
      {estRefuseeCategoriquement(derogation) ? (
        <FormattedMessage id="calculateur.un_produit.derogation_de_vr.refus_categorique" />
      ) : (
        <FormattedMessage
          id="calculateur.un_produit.derogation_de_vr.obtenue"
          values={{ pourcentage: pourcentage(derogation.valeurBroker) }}
        />
      )}
    </span>
  );
}

function InvaliditesDeDerogation({ invalidites }) {
  const priorisees = invaliditesPriorisees(invalidites, [
    TYPE_DE_FINANCEMENT_MODIFIE,
  ]);

  return (
    <div className="mt-5" data-testid="derogation-invalidites">
      {priorisees.map((i, index) => (
        <div key={index} className="mb-5" style={{ maxWidth: "300px" }}>
          <MessageInvalidite invalidite={i} />
        </div>
      ))}
    </div>
  );
}

function Contenu({ children }) {
  return <div className="mt-5">{children}</div>;
}

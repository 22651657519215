export const ORCHESTRER_PLAN_DE_FINANCEMENT = "ORCHESTRER_PLAN_DE_FINANCEMENT";
export const orchestrerLePlanDeFinancement = () => ({
  type: ORCHESTRER_PLAN_DE_FINANCEMENT,
});

export const CHARGER_PLAN_DE_FINANCEMENT = "CHARGER_PLAN_DE_FINANCEMENT";
export const chargerPlanDeFinancement = (planDeFinancement) => ({
  type: CHARGER_PLAN_DE_FINANCEMENT,
  planDeFinancement,
});

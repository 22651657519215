import { sontProchesObj } from "../../../../../Money/money";

export function fusionnerParFullService(paliers) {
  let i = 0;
  const resultat = [];

  while (i < paliers.length) {
    if (deuxFullServiceEgaux(paliers, i)) {
      resultat.push({
        ...paliers[i],
        duree: paliers[i].duree + paliers[i + 1].duree,
      });
      i += 2; // Saute le full service qui a été fusionné
    } else {
      resultat.push(paliers[i]);
      i++;
    }
  }

  return resultat;
}

function deuxFullServiceEgaux(paliers, indexDepart) {
  const loyerA = paliers[indexDepart].loyerFullService;
  const loyerB = paliers[indexDepart + 1]?.loyerFullService;
  return sontProchesObj(loyerA, loyerB, 10);
}

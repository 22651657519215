import { ANOMALIES } from "../Anomalies";
import { affecterDerogationDeMarge } from "./affecterDerogationDeMarge";
import { affecterDerogationDeVr } from "./affecterDerogationDeVr";
import { affecterDerogationDeTeg } from "./affecterDerogationDeTeg";
import { affecterDerogationDeAssuranceEspagne } from "./affecterDerogationDeAssuranceEspagne";

const {
  MARGE_TROP_FAIBLE,
  TAUX_DE_VR_NECESSITE_DEROGATION,
  TEG_MANUEL_INFERIEUR_A_GRILLE,
  ASSURANCE_ESPAGNE_INSUFFISANTE,
} = ANOMALIES;

export function enrichirAvecDerogationAnterieure(anomalie, simulation) {
  switch (anomalie.idType) {
    case MARGE_TROP_FAIBLE:
      return affecterDerogationDeMarge(anomalie, simulation);

    case TAUX_DE_VR_NECESSITE_DEROGATION:
      return affecterDerogationDeVr(anomalie, simulation);

    case TEG_MANUEL_INFERIEUR_A_GRILLE:
      return affecterDerogationDeTeg(anomalie);

    case ASSURANCE_ESPAGNE_INSUFFISANTE:
      return affecterDerogationDeAssuranceEspagne(anomalie, simulation);

    default:
      return anomalie;
  }
}

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./Marge.css";
import "./Marge-mobile.css";
import { pourcentage } from "../../../../Kit/formatage";
import { forInput } from "../../../../Money/money";
import InputMontant from "../../../../Kit/Formulaires/InputMontant";
import Euros from "../../../../Kit/Finance/Euros";
import { STATUT_DEROGATION } from "../../../Derogations/domaine/reducer";
import Aide from "../../../../Kit/Aide/Aide";

const { ACCEPTEE, A_DECIDER } = STATUT_DEROGATION;

export default function Marge({
  infosMarge,
  onChangementDuMontant,
  onChangementDuTaux,
  margeTropFaible,
  derogation,
}) {
  return (
    <div>
      <div className="marge">
        <h3>
          <FormattedMessage id="calculateur.marge" />
        </h3>

        <div className="marge-montant">
          <InputMontant
            onBlur={onChangementDuMontant}
            value={forInput(infosMarge.montantManuel)}
            append={<Euros />}
            className={`input-s ${margeTropFaible ? "text-red" : ""}`}
            key={forInput(infosMarge.montantManuel)}
            testId="marge-input-montant"
            disabled
          />
        </div>

        <div className="marge-pourcentage">
          <InputMontant
            onBlur={(pourcentage) => {
              onChangementDuTaux((pourcentage || 0) / 100);
            }}
            value={Number((infosMarge.tauxManuel * 100).toFixed(2))}
            append="%"
            key={infosMarge.tauxManuel}
            className={`input-s ${margeTropFaible ? "text-red" : ""}`}
          />
        </div>

        {infosMarge.tauxManuel !== infosMarge.tauxEffectif && (
          <div className="marge-recalculee-info">
            <div data-testid="marge-recalculee">
              Marge projet : {pourcentage(infosMarge.tauxEffectif)}
            </div>
            <Aide id="info-marge-recalculee">
              Marge calculée sur la base du TEG grille
            </Aide>
          </div>
        )}
      </div>

      {derogation && (
        <div data-testid="message-derogation" className="marge-derogation">
          <i>{messageDeDerogationPresente(derogation)}</i>
        </div>
      )}
    </div>
  );
}

function messageDeDerogationPresente(derogation) {
  const messageParStatut = {
    [ACCEPTEE]: "calculateur.marge.derogation_accordee",
    [A_DECIDER]: "calculateur.marge.derogation_a_decider",
  };

  return (
    <FormattedMessage
      id={messageParStatut[derogation.statut]}
      values={{ tauxDemande: pourcentage(derogation.taux.demande) }}
    />
  );
}

Marge.propTypes = {
  onChangementDuMontant: PropTypes.func.isRequired,
  onChangementDuTaux: PropTypes.func.isRequired,
  margeTropFaible: PropTypes.bool.isRequired,
  derogation: PropTypes.shape({
    statut: PropTypes.oneOf(Object.values(STATUT_DEROGATION)).isRequired,
    taux: PropTypes.shape({
      demande: PropTypes.number.isRequired,
    }).isRequired,
  }),
};

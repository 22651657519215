import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { enEuros } from "../../../../../../../Kit/formatage";
import BarreDeChargement from "../../../../../../../Kit/Loaders/BarreDeChargement";
import {
  getMontantAAssurer,
  PRODUIT_ASSURANCE_INDISPONIBLE,
} from "../../../../../domaine/simulation/assurance/france/reducer";
import { htOuTtc } from "../../../../../../../i18n/raccourcis";
import { Options } from "./Options";
import { AjouterOuRetirer } from "./AjouterOuRetirer";

const Assurance = ({
  isLoading,
  materiel,
  client,
  produitAssurance,
  primeAssurance,
  onAjouterAssurance,
  onRetirerAssurance,
}) => {
  return isLoading ? (
    <div className="flex-ai-center mb-20 h-40">
      <BarreDeChargement size="S" />
    </div>
  ) : produitAssurance !== PRODUIT_ASSURANCE_INDISPONIBLE ? (
    <div className="assurance-france">
      <div className="souscription">
        <Titre materiel={materiel} client={client} />
        <AjouterOuRetirer
          primeAssurance={primeAssurance}
          onAjouterAssurance={() =>
            onAjouterAssurance(materiel, produitAssurance)
          }
          onRetirerAssurance={() => onRetirerAssurance(materiel)}
        />
      </div>
      <Options
        primeAssurance={primeAssurance}
        materiel={materiel}
        produitAssurance={produitAssurance}
        onAjouterOption={(option) =>
          onAjouterAssurance(materiel, produitAssurance, option)
        }
      />
    </div>
  ) : (
    <Fragment />
  );
};

function Titre({ materiel, client }) {
  const intl = useIntl();

  const montant = enEuros(
    getMontantAAssurer(materiel, client).toObject(),
    htOuTtc(intl, client.tva.recupereLaTva)
  );

  return (
    <div className="titre">
      <FormattedMessage id="calculateur.assurance" values={{ montant }} />
    </div>
  );
}

Assurance.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  materiel: PropTypes.object.isRequired,
  produitAssurance: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  primeAssurance: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onAjouterAssurance: PropTypes.func.isRequired,
  onRetirerAssurance: PropTypes.func.isRequired,
};

export default Assurance;

import { httpServiceCrm } from "../../../../../../../Connecteurs/Crm/httpServiceCrm";
import { montantArrondi } from "../../../../../../../Kit/formatage";
import { CODES_GROUPES_OPTIONS } from "../../../../../../../Connecteurs/Crm/codesGroupesOptions";
import { i18n } from "../../../../../../../i18n/i18n";

const { typesDeMateriels } = CODES_GROUPES_OPTIONS;
export const ID_PRODUIT_COMMISSION =
  process.env.REACT_APP_ID_PRODUIT_COMMISSION;

export const commissionsAdapterCrm = {
  async creerCommission(
    { ft, projetDeTravail, marcheUtilisateur },
    commission,
    { http = httpServiceCrm } = {}
  ) {
    await http.post(`itl_depensefts`, {
      "itl_FicheTransmissionId@odata.bind": `/itl_fichetransmissions(${ft.id})`,
      itl_montantht: montantArrondi(commission.montant, 2),
      itl_quantite: 1,
      itl_montanttotalht: montantArrondi(commission.montant, 2),
      itl_name: commission.label || nomParDefaut(marcheUtilisateur),
      itl_typematerielcode: typesDeMateriels.commissionAVerser,
      itl_bypassapporteurcheck: true,
      "itl_FamilleId@odata.bind": `/itl_familles(${ID_PRODUIT_COMMISSION})`,
      "itl_ApporteurId@odata.bind": projetDeTravail.idApporteur
        ? `/contacts(${projetDeTravail.idApporteur})`
        : undefined,
    });
  },
};

function nomParDefaut(marcheUtilisateur) {
  return i18n.getIntl(marcheUtilisateur).formatMessage({
    id:
      "calculateur.creation_fiche_de_transmission.label_commission_apporteur_par_defaut",
  });
}

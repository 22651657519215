import { httpServiceCrm } from "../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../utils/odata/odata";
import {
  GET_MARQUE_GENERIQUE,
  ID_MARQUE_GENERIQUE,
} from "../../../../Calculateur/domaine/simulation/catalogueProduits/reducer";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";

const { FRANCE } = MARCHES;
const ID_ADV_DE_TOUS_LES_BROKERS =
  process.env.REACT_APP_ID_ADV_DE_TOUS_LES_BROKERS;

export const notificationDerogationDeVrAdapterCrm = {
  async getFamilleEtMarque(
    depenseDeLaDerogation,
    { http = httpServiceCrm } = {}
  ) {
    const { famille, marque } = depenseDeLaDerogation;

    const [familleCrm, marqueCrm] = await Promise.all([
      getFamilleCrm(famille, http),
      getMarqueCrm(marque, http),
    ]);

    return {
      famille: { nom: familleCrm.itl_nae },
      marque: { nom: marqueCrm.itl_name },
    };
  },

  async getAdvDuBroker() {
    return { id: ID_ADV_DE_TOUS_LES_BROKERS };
  },
};

async function getFamilleCrm(famille, http) {
  return await http.get(
    odataQuery(`itl_familles(${famille.id})`, { $select: ["itl_nae"] })
  );
}

async function getMarqueCrm(marque, http) {
  return marque.id === ID_MARQUE_GENERIQUE
    ? { itl_name: GET_MARQUE_GENERIQUE(FRANCE).label }
    : await http.get(
        odataQuery(`itl_marques(${marque.id})`, { $select: ["itl_name"] })
      );
}

import { STATUT_DEROGATION } from "../../domaine/reducer";
import { plusVieuxQueXJours } from "../../../../utils/dateUtils";

const { ACCEPTEE, ACCORD_PARTIEL, REFUSEE } = STATUT_DEROGATION;

export const titres = (intl) => ({
  enAttente: (derogations) =>
    titre(intl, "derogations.en_attente.liste", derogations.length),
  assigneesEquipe: (derogations) =>
    titre(intl, "derogations.assignees_a_equipe.liste", derogations.length),
  statuees: (derogations) =>
    titre(intl, "derogations.statuees.liste", derogations.length),
});

export function titre(intl, id, nbDerogations) {
  return intl.formatMessage({ id }, { nombre: nbDerogations });
}

export function jour(date) {
  return date ? new Date(date).toLocaleDateString() : "";
}

export function msg(suffixe, intl) {
  return intl.formatMessage({ id: `derogation.${suffixe}` });
}

export function getLabelStatut(intl) {
  return {
    [ACCEPTEE]: intl.formatMessage({ id: "derogations.decision.acceptee" }),
    [REFUSEE]: intl.formatMessage({ id: "derogations.decision.refusee" }),
    [ACCORD_PARTIEL]: intl.formatMessage({
      id: "derogation.decision.accord_partiel",
    }),
  };
}

export function derogationEstVieille(dateDemande) {
  return plusVieuxQueXJours(new Date(dateDemande), 3);
}

import { httpServiceCrm } from "../../../../../../Connecteurs/Crm/httpServiceCrm";
import { montantArrondi } from "../../../../../../Kit/formatage";
import {
  selectDelaiDeReglement,
  selectModeDeReglement,
} from "../../../simulation/conditionsDeReglement/reducer";
import { TYPE_DE_FINANCEMENT } from "../../../simulation/valeurResiduelle/reducer";
import { CODES_GROUPES_OPTIONS } from "../../../../../../Connecteurs/Crm/codesGroupesOptions";
import { selectProjetDeTravail } from "../../../simulation/projetDeTravail/reducer";
import { typeDeFinancementActif } from "../../../simulation/valeurResiduelle/selecteurs/generalites.selecteurs";

const {
  periodicites,
  termes,
  conditionsDeReglement,
  conditionsDePaiement,
  methodesDeSaisieDesLoyers,
} = CODES_GROUPES_OPTIONS;

const { CB, LLD } = TYPE_DE_FINANCEMENT;
const LLD_CRM = process.env.REACT_APP_ID_TYPE_DE_FINANCEMENT_LLD;
const CB_CRM = process.env.REACT_APP_ID_TYPE_DE_FINANCEMENT_CB;
const ID_ORIGINE_FT_FORCE = process.env.REACT_APP_ID_ORIGINE_FT_FORCE;

export const ftAdapterCrm = {
  async initialiserFt(calculateur, ft, { http = httpServiceCrm } = {}) {
    const { simulation, planDeFinancement } = calculateur;
    const projet = selectProjetDeTravail(simulation.projetDeTravail);

    const typeFinancementCrm = { [LLD]: LLD_CRM, [CB]: CB_CRM };
    const ftCrm = await http.post(
      "itl_fichetransmissions?$select=itl_fichetransmissionid",
      {
        itl_name: ft.nom,
        "itl_ProjetId@odata.bind": `/itl_projets(${projet.id})`,
        "itl_CompteId@odata.bind": `/accounts(${projet.client.id})`,
        "itl_MarqueItlId@odata.bind": `/itl_marqueitls(${projet.marqueItl.id})`,
        itl_dateloyerfinale: projet.dateEstimeeMiseEnLoyerDefinitive,
        itl_codeaffaire: projet.codeAffaire,
        "itl_Origine_FT@odata.bind": `/itl_originefts(${ID_ORIGINE_FT_FORCE})`,
        "itl_ApporteurId@odata.bind":
          projet.idApporteur !== null
            ? `/contacts(${projet.idApporteur})`
            : undefined,
        itl_vrenvisagee: montantArrondi(planDeFinancement.montantTotalDeVr, 2),
        itl_periodicitecode: periodicites.get(
          simulation.modeDeCalcul.periodicite
        ),
        itl_teg: planDeFinancement.tauxInteret * 100,
        itl_typecontratcode: termes.get(simulation.terme.choisi),
        itl_conditionsreglementcode: conditionsDeReglement.get(
          selectModeDeReglement(simulation.conditionsDeReglement)
        ),
        itl_conditionpaiementcode: conditionsDePaiement.get(
          selectDelaiDeReglement(simulation.conditionsDeReglement)
        ),
        itl_methodecalculcode: methodesDeSaisieDesLoyers.sansFraisDeReglement,
        "itl_TypeFinancementId@odata.bind": `/itl_typefinancements(${
          typeFinancementCrm[
            typeDeFinancementActif(simulation.valeurResiduelle)
          ]
        })`,
        itl_isnouveauclient: projet.client.estNouveau,
      }
    );

    return { id: ftCrm.itl_fichetransmissionid, nom: ft.nom };
  },
};

import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Mode = {
  NEUTRE: "NEUTRE",
  SUCCES: "SUCCES",
  WARNING: "WARNING",
};

const MiniBadge = ({ mode, icone, children, className }) => (
  <div className={className}>
    <div className={"tag " + classes[mode]} style={{ fontSize: "90%" }}>
      <FontAwesomeIcon icon={icone} size="lg" className="mr-10" />
      {children}
    </div>
  </div>
);

MiniBadge.propTypes = {
  mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
  className: PropTypes.string,
};

const classes = {
  [Mode.NEUTRE]: "",
  [Mode.SUCCES]: "button-success",
  [Mode.WARNING]: "button-warning",
};

export default MiniBadge;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import {
  MODES_DE_REGLEMENT,
  selectModeDeReglement,
} from "../../domaine/simulation/conditionsDeReglement/reducer";
import { useDispatch, useSelector } from "react-redux";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { changerModeDeReglement } from "../../domaine/simulation/conditionsDeReglement/actions";
import { orchestrerLePlanDeFinancement } from "../../domaine/planDeFinancement/actions";
import Select from "../../../../Kit/Formulaires/Select";

const {
  PRELEVEMENT_AUTOMATIQUE,
  CHEQUE,
  VIREMENT,
  MANDAT,
} = MODES_DE_REGLEMENT;

export const ModeReReglement = ({ className = "" }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const modeActif = useSelector((state) => {
    const { simulation } = calculateur(state);
    const actif = selectModeDeReglement(simulation.conditionsDeReglement);
    return modes(intl).find((m) => m.value.id === actif);
  });

  return (
    <div className={`flex-dir-col w-205 ${className}`}>
      <div className="text-bold">
        <FormattedMessage id="calculateur.conditions_de_reglement.mode" />
      </div>
      <div className="p-2-0">
        <Select
          options={modes(intl)}
          value={modeActif}
          onChange={({ value }) => {
            dispatch(changerModeDeReglement(value.id));
            dispatch(orchestrerLePlanDeFinancement());
          }}
          className="p-2-0"
        />
      </div>
    </div>
  );
};

ModeReReglement.propTypes = {
  modeChoisi: PropTypes.oneOf(Object.keys(MODES_DE_REGLEMENT)),
  className: PropTypes.string,
};

const modes = (intl) => [
  mode(PRELEVEMENT_AUTOMATIQUE, intl),
  mode(CHEQUE, intl),
  mode(VIREMENT, intl),
  mode(MANDAT, intl),
];

function mode(value, intl) {
  const msg = `calculateur.conditions_de_reglement.options.${LABELS[value]}`;
  return { value: { id: value }, label: intl.formatMessage({ id: msg }) };
}

const LABELS = {
  [PRELEVEMENT_AUTOMATIQUE]: "prelevement_automatique",
  [CHEQUE]: "cheque",
  [VIREMENT]: "virement",
  [MANDAT]: "mandat",
};

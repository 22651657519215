import { i18n } from "../../../../i18n/i18n";
import { MARCHES } from "../../../../identiteUtilisateur/reducer";
import { STATUT_DEROGATION } from "../reducer";
import { templating } from "../../../../i18n/templating";
import { TEMPLATE_NOTIFICATION_DECISION_ES } from "./templates/NOTIFICATION_DECISION_es";
import { formatjjMMaaaa } from "../../../../utils/dateUtils";
import { UrlsCrm } from "../../../../Connecteurs/Crm/Urls";
import { UrlsForce } from "../../../../routing/Urls";

const { ACCEPTEE, REFUSEE } = STATUT_DEROGATION;
const { ESPAGNE } = MARCHES;
const { SUJET, CORPS } = TEMPLATE_NOTIFICATION_DECISION_ES;

export function contenuDeLaNotification(derogationPourNotification) {
  return {
    sujet: getSujet(derogationPourNotification),
    texte: getTexte(derogationPourNotification),
  };
}

function getSujet(derogationPourNotification) {
  const emojis = { [ACCEPTEE]: "✅", [REFUSEE]: "❌" };
  return templating(SUJET, {
    emoji: emojis[derogationPourNotification.statut],
    verdict: getVerdict(derogationPourNotification),
    nomDuProjet: derogationPourNotification.projet.nom,
  });
}

function getTexte(derogationPourNotification) {
  return templating(CORPS, {
    dateDeDemandeFormatee: formatjjMMaaaa(
      derogationPourNotification.dateDemande
    ),
    nomDerogateur: derogationPourNotification.derogateur.nom,
    commentaire: derogationPourNotification.derogateur.commentaire,
    verdict: getVerdict(derogationPourNotification),
    nomDuClient: derogationPourNotification.client.nom,
    nomDuProjet: derogationPourNotification.projet.nom,
    urlPourOuvrirFtEnCrm: UrlsCrm.ft(derogationPourNotification.ft.id).ouvrir,
    urlPourOuvrirProjetDansForce: UrlsForce.projet(
      derogationPourNotification.projet.id
    ).ouvrir,
  });
}

function getVerdict(derogationPourNotification) {
  const intlEspagne = i18n.getIntl(ESPAGNE);
  const verdicts = {
    [ACCEPTEE]: "derogations.decision.acceptee",
    [REFUSEE]: "derogations.decision.refusee",
  };
  return intlEspagne.formatMessage({
    id: verdicts[derogationPourNotification.statut],
  });
}

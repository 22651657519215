import {
  CHARGER_CATEGORIES_PRODUIT_SUCCES,
  CHARGER_MARQUES_DE_PRODUITS_SUCCES,
  CHARGER_PRODUITS_SUCCES,
  CHARGER_SELECTION_DE_MATERIELS_DISPONIBLES,
} from "./actions";
import { indexerParCle } from "../../../../../utils/objetUtils";
import { guidEqual } from "../../../../../utils/guidUtils";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";

const { FRANCE, ESPAGNE } = MARCHES;

export const ID_MATERIEL_NON_REFERENCE =
  process.env.REACT_APP_ID_MATERIEL_NON_REFERENCE;

export const ID_MARQUE_GENERIQUE = "__MARQUE_GENERIQUE";

export function GET_MARQUE_GENERIQUE(marcheUtilisateur) {
  const labelGeneriqueParMarche = {
    [FRANCE]: "Générique",
    [ESPAGNE]: "Genérico",
  };
  const label = labelGeneriqueParMarche[marcheUtilisateur];

  return {
    id: ID_MARQUE_GENERIQUE,
    label,
    crm: { id: ID_MARQUE_GENERIQUE },
  };
}

const EST_REFERENCE = "EST_REFERENCE";
const EST_NON_REFERENCE = "EST_NON_REFERENCE";
export const REFERENCEMENT_PRODUIT = { EST_REFERENCE, EST_NON_REFERENCE };

const STATE_INITIAL = {
  produits: { parId: {} },
  marques: { parId: {} },
  categoriesProduit: [],
  selectionDesMaterielsDisponibles: [],
};

const reducer = (state = STATE_INITIAL, action) => {
  switch (action.type) {
    case CHARGER_PRODUITS_SUCCES:
      return {
        ...state,
        produits: { parId: indexerParCle(action.produits, "id") },
      };

    case CHARGER_MARQUES_DE_PRODUITS_SUCCES:
      return {
        ...state,
        marques: { parId: indexerParCle(action.marquesDeProduits, "id") },
      };

    case CHARGER_CATEGORIES_PRODUIT_SUCCES:
      return { ...state, categoriesProduit: action.categories };

    case CHARGER_SELECTION_DE_MATERIELS_DISPONIBLES:
      return { ...state, selectionDesMaterielsDisponibles: action.selection };

    default:
      return state;
  }
};

export default reducer;

export const leProduitCatalogue = (state, idCatalogue) =>
  state.produits.parId[idCatalogue];

export const laMarque = (state, idMarque) => state.marques.parId[idMarque];

export const toutesLesMarques = state => Object.values(state.marques.parId);

export const labelCatalogue = (state, { idFamille, idMarque }) => {
  const produitCatalogue = leProduitCatalogue(state, idFamille);
  const marque = laMarque(state, idMarque);
  return getLabelCatalogue({ idFamille }, produitCatalogue, marque);
};

export function getLabelCatalogue({ idFamille }, leProduitCatalogue, marque) {
  if (produitEstNonReference({ idFamille })) return leProduitCatalogue.label;
  else return `${leProduitCatalogue.label} - ${marque.label}`;
}

export const produitEstNonReference = produit => {
  return guidEqual(produit.idFamille, ID_MATERIEL_NON_REFERENCE);
};

export function suivantReferencement(produit, fonctionsParReferencement) {
  const r = produitEstNonReference(produit) ? EST_NON_REFERENCE : EST_REFERENCE;
  return fonctionsParReferencement[r]();
}

export const selectMaterielsDisponibles = (state, { inclureLeNonReference }) =>
  inclureLeNonReference
    ? state.selectionDesMaterielsDisponibles
    : state.selectionDesMaterielsDisponibles.filter(
        groupe => !groupe.options.find(option => option.estUnDefault)
      );

export const leProduitCatalogueParIdCrm = (state, idCrm) =>
  Object.values(state.produits.parId).find(p => guidEqual(p.crm.id, idCrm));

export const laMarqueParIdCrm = (state, idCrm) =>
  Object.values(state.marques.parId).find(m => guidEqual(m.crm.id, idCrm));

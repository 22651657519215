import { MARCHES } from "../../../../../identiteUtilisateur/reducer";
import { findGuidKey } from "../../../../../utils/objetUtils";
import { isLizemed } from "../../../../../Connecteurs/Crm/codesGroupesOptions";
import {
  TAUX_DE_MARGE_CIBLE,
  TAUX_SEUIL_DE_DEROGATION,
} from "../../../../../ConfigMarge/SeuilsDeMarge";

const { ESPAGNE, FRANCE } = MARCHES;

const MEDICAL = process.env.REACT_APP_ID_MARCHE_MEDICAL;
const ENV_IND = process.env.REACT_APP_ID_MARCHE_ENV_IND;
const DIVERS = process.env.REACT_APP_ID_MARCHE_DIVERS;
const HIGH_TECH = process.env.REACT_APP_ID_MARCHE_HIGH_TECH;
const INFO_BUREAUTIQUE = process.env.REACT_APP_ID_MARCHE_INFO_BUREAUTIQUE;
const OL_ENVIRONNEMENT = process.env.REACT_APP_ID_MARCHE_OL_ENVIRONNEMENT;

export const margeCibleAdapterHardcode = {
  getMargeCible({ marcheUtilisateur, idMarcheProjet, idMarqueItl, client }) {
    return configurationDeMargeCible({
      marcheUtilisateur,
      idMarcheProjet,
      idMarqueItl,
      client,
    });
  },
};

const { FRANCE: CIBLE_FR, ESPAGNE: CIBLE_ES } = TAUX_DE_MARGE_CIBLE;
const { FRANCE: SEUIL_FR, ESPAGNE: SEUIL_ES } = TAUX_SEUIL_DE_DEROGATION;

const CONFIGURATION_DES_MARGES = {
  [FRANCE]: {
    [MEDICAL]: (idMarqueItl, client) => ({
      tauxDeMargeCible: CIBLE_FR.MEDICAL,
      tauxSeuilDeDerogation: isLizemed(idMarqueItl)
        ? SEUIL_FR.MEDICAL.LIZEMED
        : client.estNouveau
          ? SEUIL_FR.MEDICAL.AUTRE.NOUVEAU_CLIENT
          : SEUIL_FR.MEDICAL.AUTRE.CLIENT_EXISTANT,
    }),
    [ENV_IND]: () => ({
      tauxDeMargeCible: CIBLE_FR.ENV_IND,
      tauxSeuilDeDerogation: SEUIL_FR.ENV_IND,
    }),
    [DIVERS]: () => ({
      tauxDeMargeCible: CIBLE_FR.DIVERS,
      tauxSeuilDeDerogation: SEUIL_FR.DIVERS,
    }),
    [HIGH_TECH]: () => ({
      tauxDeMargeCible: CIBLE_FR.HIGH_TECH,
      tauxSeuilDeDerogation: SEUIL_FR.HIGH_TECH,
    }),
    [INFO_BUREAUTIQUE]: () => ({
      tauxDeMargeCible: CIBLE_FR.INFO_BUREAUTIQUE,
      tauxSeuilDeDerogation: SEUIL_FR.INFO_BUREAUTIQUE,
    }),
    [OL_ENVIRONNEMENT]: () => ({
      tauxDeMargeCible: CIBLE_FR.OL_ENVIRONNEMENT,
      tauxSeuilDeDerogation: SEUIL_FR.OL_ENVIRONNEMENT,
    }),
  },
  [ESPAGNE]: {
    [MEDICAL]: () => ({
      tauxDeMargeCible: CIBLE_ES.MEDICAL,
      tauxSeuilDeDerogation: SEUIL_ES.MEDICAL,
    }),
    [ENV_IND]: () => ({
      tauxDeMargeCible: CIBLE_ES.ENV_IND,
      tauxSeuilDeDerogation: SEUIL_ES.ENV_IND,
    }),
    [DIVERS]: () => ({
      tauxDeMargeCible: CIBLE_ES.DIVERS,
      tauxSeuilDeDerogation: SEUIL_ES.DIVERS,
    }),
    [HIGH_TECH]: () => ({
      tauxDeMargeCible: CIBLE_ES.HIGH_TECH,
      tauxSeuilDeDerogation: SEUIL_ES.HIGH_TECH,
    }),
    [INFO_BUREAUTIQUE]: () => ({
      tauxDeMargeCible: CIBLE_ES.INFO_BUREAUTIQUE,
      tauxSeuilDeDerogation: SEUIL_ES.INFO_BUREAUTIQUE,
    }),
    [OL_ENVIRONNEMENT]: () => ({
      tauxDeMargeCible: CIBLE_ES.OL_ENVIRONNEMENT,
      tauxSeuilDeDerogation: SEUIL_ES.OL_ENVIRONNEMENT,
    }),
  },
};

function configurationDeMargeCible({
  marcheUtilisateur,
  idMarcheProjet,
  idMarqueItl,
  client,
}) {
  const margesDuMarche = CONFIGURATION_DES_MARGES[marcheUtilisateur];

  const cleDuMarcheProjet = findGuidKey(margesDuMarche, idMarcheProjet);

  const marcheProjetEstConnu = cleDuMarcheProjet !== undefined;
  if (!marcheProjetEstConnu) throw MarcheProjetInconnu(idMarcheProjet);

  return margesDuMarche[cleDuMarcheProjet](idMarqueItl, client);
}

function MarcheProjetInconnu(idMarcheProjet) {
  const message =
    "Impossible de trouver les données de marge cible pour ce marché. Il est inconnu de Force.";
  const marche = `ID Marché : ${idMarcheProjet}`;
  return Error(`${message} ${marche}`);
}

export const CORPS = `Buen día, <br/>
Se ha ingresado una nueva solicitud de derogación de seguro. <br/><br/>
{nomDerogation}
<br/>
<br />
Para tomar una decisión, haga clic en el siguiente botón :
<a
  href="{urlOuvrirDerogation}"
  style="
    display: block;
    width: 300px;
    text-align: center;
    margin: 10px;
    color: white;
    text-decoration: none;
    background-color: #0078e7;
    padding: 10px;
    border-radius: 7px;
  "
>
  Detalle de la solicitud
</a>`;

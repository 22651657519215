import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { redirigerVersLesDerogationsDuProjet } from "../../domaine/actions";

export function RedirectVersDerogationsParProjet({ idFt, idDerogation }) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (idFt) dispatch(redirigerVersLesDerogationsDuProjet({ idFt }, history));
    else if (idDerogation)
      dispatch(redirigerVersLesDerogationsDuProjet({ idDerogation }, history));
  }, [dispatch, idFt, idDerogation, history]);

  return <Fragment />;
}

RedirectVersDerogationsParProjet.propTypes = {
  idFt: PropTypes.string,
  idDerogation: PropTypes.string,
};

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import FaCheck from "react-icons/lib/fa/check";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import "./VrUnLoyer.css";
import "./VrUnLoyer-mobile.css";

export function VrUnLoyer({
  estActive,
  isLoading,
  vrUnLoyerEstPossible,
  utiliserDesVrUnLoyer,
  desactiverLaVrUnLoyer,
}) {
  return (
    <div className="vr-un-loyer">
      <h3>
        <FormattedMessage id="calculateur.vr_un_loyer" />
      </h3>
      {isLoading ? (
        <BarreDeChargement size="XS" />
      ) : (
        <div
          className={`pure-button-group ${
            !vrUnLoyerEstPossible ? `pure-button-disabled` : ""
          }`}
        >
          {Desactiver({
            estDesactivee: !estActive,
            onDesactiver: desactiverLaVrUnLoyer,
          })}
          {Activer({ estActive, onActiver: utiliserDesVrUnLoyer })}
        </div>
      )}
    </div>
  );
}

function Desactiver({ estDesactivee, onDesactiver }) {
  const css = classNames("pure-button", { "button-success": estDesactivee });
  return (
    <button
      className={css}
      onClick={() => {
        if (!estDesactivee) onDesactiver();
      }}
    >
      {estDesactivee ? (
        <>
          <FaCheck className="mr-5" />
          <FormattedMessage id="general.desactivee" />
        </>
      ) : (
        <FormattedMessage id="general.desactiver" />
      )}
    </button>
  );
}

function Activer({ estActive, onActiver }) {
  const css = classNames("pure-button", { "button-success": estActive });

  return (
    <button
      className={css}
      onClick={() => {
        if (!estActive) onActiver();
      }}
    >
      {estActive ? (
        <>
          <FaCheck className="mr-5" /> <FormattedMessage id="general.activee" />
        </>
      ) : (
        <FormattedMessage id="general.activer" />
      )}
    </button>
  );
}

VrUnLoyer.propTypes = {
  estActive: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  utiliserDesVrUnLoyer: PropTypes.func.isRequired,
  desactiverLaVrUnLoyer: PropTypes.func.isRequired,
  vrUnLoyerEstPossible: PropTypes.bool.isRequired,
};

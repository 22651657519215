import { httpServiceCrm } from "../../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../../utils/odata/odata";
import { httpAssu4000 as httpServiceAssu4000 } from "../../../../../../Connecteurs/Assu4000/httpAssu4000";
import { $obj, Money } from "../../../../../../Money/money";
import {
  AUCUNE_OPTION,
  OPTION_ASSURANCE,
  PRODUIT_ASSURANCE_INDISPONIBLE,
} from "./reducer";

const { PERTE_DE_RECETTES, GARANTIE_DES_LOYERS } = OPTION_ASSURANCE;

export const produitAssuranceAdapterAssu4000 = {
  async getProduitAssurance(
    materiel,
    { httpCrm = httpServiceCrm, httpAssu4000 = httpServiceAssu4000 } = {}
  ) {
    const itl_idassu4000 = await getIdAssu4000(materiel.famille.crm, {
      httpCrm,
    });

    const materielNonAssurable = itl_idassu4000 === null;
    if (materielNonAssurable) return PRODUIT_ASSURANCE_INDISPONIBLE;

    const idAssu4000 = JSON.parse(itl_idassu4000);

    const nonAssurableEnFr = !idAssu4000.fr;
    if (nonAssurableEnFr) return PRODUIT_ASSURANCE_INDISPONIBLE;

    try {
      const produitAssu4000 = await getProduitAssu4000(
        materiel,
        idAssu4000.fr,
        { httpAssu4000 }
      );

      return enProduitAssuranceForce(produitAssu4000);
    } catch (e) {
      // Il arrive parfois qu'ASSU4000 renvoie des erreurs bas-niveau que Force ne parvient pas à lire.
      // https://sentry.io/organizations/itl/issues/2107832290/?environment=PROD&project=1301490
      // La raison n'est pas très claire...pour mieux comprendre pour l'instant on re-throw
      if (!e.details) throw e;

      const { httpStatus } = e.details;
      if (httpStatus === 404) return PRODUIT_ASSURANCE_INDISPONIBLE;

      throw e;
    }
  },
};

async function getIdAssu4000(familleCrm, { httpCrm }) {
  const { itl_idassu4000 } = await httpCrm.get(
    odataQuery(`itl_familles(${familleCrm.id})`, {
      $select: ["itl_idassu4000"],
    })
  );
  return itl_idassu4000;
}

async function getProduitAssu4000(materiel, idAssu4000Fr, { httpAssu4000 }) {
  const { guid, codeTypeMateriel, codeCategorie } = idAssu4000Fr;

  const montantAAssurer = Money(materiel.montantUnitaire)
    .multiply(materiel.quantite)
    .toUnit()
    .toString();

  const produitAssu4000 = await httpAssu4000.post(
    `/api/v1/produit-assurance/${guid}`,
    { montantAAssurer, codeTypeMateriel, codeCategorie }
  );

  return produitAssu4000;
}

function enProduitAssuranceForce(produitAssu4000) {
  const taux = valeur => (valeur ? Number(valeur) : null);

  return {
    primeMinimumAnnuelle: $obj(produitAssu4000.primeMinimumAnnuelle),
    taux: {
      tauxValeurNeuf: taux(produitAssu4000.taux.tauxValeurNeuf),
      tauxValeurOccasion: taux(produitAssu4000.taux.tauxValeurOccasion),
      tauxCategorie: taux(produitAssu4000.taux.tauxCategorie),
    },
    options: produitAssu4000.options.reduce(
      (opts, o) => ({
        ...opts,
        [TYPES_OPTIONS_ASSURANCE[o.libelle]]: {
          tauxAdditionnelNeuf: taux(o.tauxAdditionnelNeuf),
          tauxAdditionnelOccasion: taux(o.tauxAdditionnelOccasion),
        },
      }),
      AUCUNE_OPTION
    ),
  };
}

const PerteDeRecettes =
  process.env.REACT_APP_ASSURANCE_LIBELLE_ASSU4000_OPTION_PERTE_DE_RECETTES;
const GarantieDesLoyers =
  process.env.REACT_APP_ASSURANCE_LIBELLE_ASSU4000_OPTION_GARANTIE_DES_LOYERS;

const TYPES_OPTIONS_ASSURANCE = {
  [PerteDeRecettes]: PERTE_DE_RECETTES,
  [GarantieDesLoyers]: GARANTIE_DES_LOYERS,
};

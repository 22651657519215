export const ACCEPTER_DEROGATION_DE_TEG = "ACCEPTER_DEROGATION_DE_TEG";
export const accepterDerogationDeTeg = (derogation, decision) => ({
  type: ACCEPTER_DEROGATION_DE_TEG,
  derogation,
  decision,
});

export const REFUSER_DEROGATION_DE_TEG = "REFUSER_DEROGATION_DE_TEG";
export const refuserDerogationDeTeg = (derogation, decision) => ({
  type: REFUSER_DEROGATION_DE_TEG,
  derogation,
  decision,
});

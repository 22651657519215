import { call, select } from "redux-saga/effects";
import { assurerLoyerSaga } from "../assurance/assurance.sagas";
import { Adapters } from "../../../../../../reduxStore/adapters";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { mapperLesLoyers } from "./loyers.mapping";

export function* creerLesLoyersDeLaFtSaga(
  ft,
  { loyersAdapter = Adapters.loyersAdapter } = {}
) {
  const { planDeFinancement } = yield select(calculateur);
  const loyers = yield call(mapperLesLoyers, planDeFinancement);

  for (const loyer of loyers) {
    yield* creerUnLoyer(ft, loyer, { loyersAdapter });
  }
}

function* creerUnLoyer(ft, { loyerCrm, palierForce }, { loyersAdapter }) {
  const loyerCreeEnCrm = yield call(loyersAdapter.creerLoyer, loyerCrm, ft.id);
  yield call(assurerLoyerSaga, loyerCreeEnCrm, palierForce);
}

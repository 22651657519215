import { PaliersSouhaites } from "./PaliersSouhaites";
import { connect } from "react-redux";
import { uneOperationEstEnCours } from "../../../../Ui/reducer";
import { ORCHESTRER_PLAN_DE_FINANCEMENT } from "../../domaine/planDeFinancement/actions";
import { bornesDeVr } from "../../domaine/simulation/valeurResiduelle/selecteurs/bornes.selecteurs";
import { listeDesProduitsAchetes } from "../../domaine/simulation/montantAFinancer/reducer";
import { selectNatureDuCalcul } from "../../domaine/simulation/modeDeCalcul/reducer";
import { calculateur } from "../../../../reduxStore/rootReducer";

const mapStateToProps = (state) => {
  const { simulation } = calculateur(state);

  return {
    calculDuPlanDeFinancementEnCours: uneOperationEstEnCours(state.ui, [
      ORCHESTRER_PLAN_DE_FINANCEMENT,
    ]),

    bornesDeVr: bornesDeVr(
      simulation.valeurResiduelle,
      listeDesProduitsAchetes(simulation.montantAFinancer),
      simulation.catalogueProduits
    ),

    natureDuCalcul: selectNatureDuCalcul(simulation.modeDeCalcul),
  };
};

export default connect(mapStateToProps)(PaliersSouhaites);

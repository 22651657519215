import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import {
  ASSURER_MATERIEL_FRANCE,
  definirPrimeAssuranceFrance,
  operationSurAssuranceFrance,
  RECUPERER_PRODUIT_ASSURANCE_FRANCE,
  recupererProduitAssuranceFrance_succes,
  retirerPrimeAssuranceFrance,
  supprimerProduitAssuranceFrance,
} from "./actions";
import {
  effacerErreur,
  erreurEstSurvenue,
  operationEnCours,
  operationTerminee,
} from "../../../../../../Ui/actions";
import { orchestrerLePlanDeFinancement } from "../../../planDeFinancement/actions";
import { MARCHES } from "../../../../../../identiteUtilisateur/reducer";
import { getMarcheUtilisateur } from "../../profilUtilisateur/sagas";
import { Adapters } from "../../../../../../reduxStore/adapters";
import { selectClient } from "../../projetDeTravail/reducer";
import { Money } from "../../../../../../Money/money";
import { leProduitCatalogue } from "../../catalogueProduits/reducer";

const { FRANCE } = MARCHES;

export function* recupererProduitAssuranceFranceSaga(
  action,
  { produitAssuranceAdapter = Adapters.produitAssuranceAdapter } = {}
) {
  if (yield* assuranceFrDesactivee()) return;

  const { materiel } = action;
  if (!estCompletPourAssurance(materiel))
    yield put(supprimerProduitAssuranceFrance(materiel));
  else {
    const operation = operationSurAssuranceFrance(materiel.id);
    try {
      yield put(effacerErreur(operation));
      yield put(operationEnCours(operation));

      const materielPourAssurance = yield* enMaterielPourAssurance(materiel);
      yield call(getProduitAssurance, materielPourAssurance, {
        produitAssuranceAdapter,
      });
    } catch (e) {
      yield put(erreurEstSurvenue(operation, e));
      yield put(supprimerProduitAssuranceFrance(materiel));
    } finally {
      yield put(operationTerminee(operation));
    }
  }
}

function estCompletPourAssurance(produit) {
  return (
    produit.idFamille !== null &&
    produit.montantUnitaire !== null &&
    produit.quantite !== null &&
    !Money(produit.montantUnitaire).isZero()
  );
}

function* enMaterielPourAssurance(materiel) {
  const { simulation } = yield select(calculateur);
  const produitCatalogue = leProduitCatalogue(
    simulation.catalogueProduits,
    materiel.idFamille
  );
  const { id, montantUnitaire, quantite } = materiel;
  const { crm } = produitCatalogue;
  return { id, montantUnitaire, quantite, famille: { crm } };
}

export function* getProduitAssurance(materiel, { produitAssuranceAdapter }) {
  const produitAssurance = yield call(
    produitAssuranceAdapter.getProduitAssurance,
    materiel
  );
  yield put(recupererProduitAssuranceFrance_succes(materiel, produitAssurance));
}

export function* assurerMaterielSaga(
  action,
  { assuranceAdapter = Adapters.assuranceFranceAdapter } = {}
) {
  const { materiel } = action;
  const operation = operationSurAssuranceFrance(materiel.id);
  try {
    yield put(operationEnCours(operation));
    yield call(getPrimeAssurance, action, { assuranceAdapter });
    yield put(orchestrerLePlanDeFinancement());
  } catch (e) {
    yield put(erreurEstSurvenue(operation, e));
    yield put(retirerPrimeAssuranceFrance(materiel));
  } finally {
    yield put(operationTerminee(operation));
  }
}

export function* getPrimeAssurance(
  { materiel, produitAssurance, option },
  { assuranceAdapter }
) {
  const { simulation } = yield select(calculateur);

  const prime = yield call(assuranceAdapter.getPrimeAssurance, {
    materiel,
    produitAssurance,
    option,
    periodicite: simulation.modeDeCalcul.periodicite,
    client: selectClient(simulation.projetDeTravail),
  });

  yield put(definirPrimeAssuranceFrance(materiel, prime));
}

export function* assuranceFrDesactivee() {
  // Désactive si l'utilisateur n'est pas FR
  const marcheUtilisateur = yield* getMarcheUtilisateur();
  return marcheUtilisateur !== FRANCE;
}

export default function* sagasDeAssurance() {
  yield all([
    takeEvery(
      RECUPERER_PRODUIT_ASSURANCE_FRANCE,
      recupererProduitAssuranceFranceSaga
    ),
    takeEvery(ASSURER_MATERIEL_FRANCE, assurerMaterielSaga),
  ]);
}

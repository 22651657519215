import { call, put, select } from "redux-saga/effects";
import { chargerProfilUtilisateur_succes } from "./actions";
import {
  calculateur,
  identiteUtilisateur,
} from "../../../../../reduxStore/rootReducer";
import { selectIdCrmUtilisateurEst } from "../../../../../identiteUtilisateur/reducer";
import { impersonifieurCrm } from "../../../../../Connecteurs/Crm/impersonifieurCrm";
import { selectMarcheUtilisateur } from "./reducer";
import { Adapters } from "../../../../../reduxStore/adapters";

export function* chargerProfilUtilisateurSaga(
  projetDeTravail,
  { profilUtilisateurAdapter = Adapters.profilUtilisateurAdapter } = {}
) {
  const idDuCommercial = projetDeTravail.commercial.idUtilisateur;
  const nomPrenom = yield call(
    profilUtilisateurAdapter.getNomPrenom,
    idDuCommercial
  );

  const identite = yield select(identiteUtilisateur);
  const marcheUtilisateur = selectMarcheUtilisateur(identite);
  const profilComplet = { fullname: nomPrenom, marcheUtilisateur };
  yield put(chargerProfilUtilisateur_succes(profilComplet));

  yield call(impersonifierCommercialSaga, projetDeTravail);
}

export function* impersonifierCommercialSaga(
  projetDeTravail,
  { impersonifieur = impersonifieurCrm } = {}
) {
  const { commercial } = projetDeTravail;
  const idDuCommercial = commercial.idUtilisateur;

  const identite = yield select(identiteUtilisateur);
  const utilisateurEstLeCommercial = selectIdCrmUtilisateurEst(
    identite,
    idDuCommercial
  );
  const doitImpersonifier = !utilisateurEstLeCommercial;
  if (doitImpersonifier) impersonifieur.impersonifier(commercial);
}

export function* getMarcheUtilisateur() {
  const { simulation: { profilUtilisateur } } = yield select(calculateur);

  return selectMarcheUtilisateur(profilUtilisateur);
}

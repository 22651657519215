import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BadgeErreur from "../Badges/Erreur/BadgeErreur";
import { erreurDeOperation } from "../../Ui/reducer";
import { erreurEstSurvenue } from "../../Ui/actions";
import "./ErrorBoundary.css";

// https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
class ErrorBoundary extends Component {
  componentDidCatch(error) {
    const { onError } = this.props;
    onError(error);
  }

  render() {
    const { messagesErreurs, children } = this.props;
    return messagesErreurs.length > 0 ? (
      <div className="error-boundary">
        <BadgeErreur messages={messagesErreurs} />
      </div>
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = {
  onError: PropTypes.func.isRequired,
  messagesErreurs: PropTypes.array.isRequired,
  zone: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  messagesErreurs: erreurDeOperation(state.ui, ownProps.zone),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onError: (error) => {
    dispatch(erreurEstSurvenue(ownProps.zone, error));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);

import { call, put } from "redux-saga/effects";
import {
  operationEnCours,
  operationTerminee,
} from "../../../../../../Ui/actions";
import {
  INITIALISER_DEROGATEUR_ASSURANCE_ESPAGNE,
  recupererDerogateurAssuranceEspagne_succes,
} from "../actions";
import { Adapters } from "../../../../../../reduxStore/adapters";
import { Features } from "../../../../../../features/Features";

const ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_ASSURANCE_ESPAGNE;

export function* initialiserDerogateurAssuranceEspagneSaga({
  derogateursAdapter = Adapters.derogateursAdapter,
} = {}) {
  const featureActivee = Features.AvecDerogationAssuranceEspagne === true;
  if (!featureActivee) return;

  yield put(operationEnCours(INITIALISER_DEROGATEUR_ASSURANCE_ESPAGNE));
  const derogateur = yield call(
    derogateursAdapter.getDerogateurUniqueDuType,
    ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE
  );
  yield put(recupererDerogateurAssuranceEspagne_succes(derogateur));
  yield put(operationTerminee(INITIALISER_DEROGATEUR_ASSURANCE_ESPAGNE));
}

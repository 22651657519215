import React from "react";
import MediaQuery from "react-responsive";
import ListeDesDerogationsMobile from "./ListeDesDerogations.mobile";
import ListeDesDerogationsDesktop from "./ListeDesDerogations.desktop";

const ListeDesDerogationsResponsive = (props) => (
  <div>
    <MediaQuery maxWidth={640}>
      <ListeDesDerogationsMobile {...props} />
    </MediaQuery>
    <MediaQuery minWidth={641}>
      <ListeDesDerogationsDesktop {...props} />
    </MediaQuery>
  </div>
);

export default ListeDesDerogationsResponsive;

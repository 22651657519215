import { CHARGER_PROFIL_UTILISATEUR_SUCCES } from "./actions";

const STATE_INITIAL = {};

export default function reducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CHARGER_PROFIL_UTILISATEUR_SUCCES:
      return { ...state, ...action.profil };

    default:
      return state;
  }
}

export const selectNomPrenomUtilisateur = (state) => state.fullname;
export const selectMarcheUtilisateur = (state) => state.marcheUtilisateur;

import { connect } from "react-redux";
import UnProduitAchete from "./UnProduitAchete";
import {
  financerProduitAchete,
  supprimerProduit,
} from "../../../../domaine/simulation/montantAFinancer/actions";
import { appliquerLeTauxDeVrGrille } from "../../../../domaine/simulation/valeurResiduelle/actions";
import { LONGUEUR_MAX_DESCRIPTION_DEPENSE } from "../../../../../../Connecteurs/Crm/limites";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { orchestrerLePlanDeFinancement } from "../../../../domaine/planDeFinancement/actions";
import {
  recupererProduitAssuranceFrance,
  retirerPrimeAssuranceFrance,
} from "../../../../domaine/simulation/assurance/france/actions";
import {
  labelCatalogue,
  leProduitCatalogue,
  selectMaterielsDisponibles,
  toutesLesMarques,
} from "../../../../domaine/simulation/catalogueProduits/reducer";
import { effacerErreur } from "../../../../../../Ui/actions";
import { CREER_FICHE_DE_TRANSMISSION } from "../../../../domaine/creationDeFt/actions";
import { estEnMode, MODE_AFFICHAGE } from "../../../../../../Ui/reducer";
import { selectNatureDuCalcul } from "../../../../domaine/simulation/modeDeCalcul/reducer";

const { LIGHT } = MODE_AFFICHAGE;

const mapStateToProps = (state, ownProps) => {
  const { simulation } = calculateur(state);
  const { produit } = ownProps;

  return {
    longueurMaxDescription: LONGUEUR_MAX_DESCRIPTION_DEPENSE,
    produit: ownProps.produit,

    produitCatalogue: leProduitCatalogue(
      simulation.catalogueProduits,
      produit.idFamille
    ),

    labelCatalogue:
      produit.idFamille && produit.idMarque
        ? labelCatalogue(simulation.catalogueProduits, produit)
        : null,

    selectionDeMateriels: {
      materiels: selectMaterielsDisponibles(simulation.catalogueProduits, {
        inclureLeNonReference: true,
      }),
    },

    marquesDisponibles: toutesLesMarques(simulation.catalogueProduits),

    estEnModeLight: estEnMode(LIGHT, state.ui),
    natureDuCalcul: selectNatureDuCalcul(simulation.modeDeCalcul),
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeQuiRecalcule: (produit, { rechercheProduitAssurance } = {}) => {
    dispatch(effacerErreur(CREER_FICHE_DE_TRANSMISSION));
    dispatch(financerProduitAchete(produit));

    if (rechercheProduitAssurance) {
      // Par souci de simplicité, on retire l'assurance éventuellement déjà ajoutée
      // avant de récupérer le produit d'assurance disponible.
      dispatch(retirerPrimeAssuranceFrance(produit));
      dispatch(recupererProduitAssuranceFrance(produit));
    }

    dispatch(orchestrerLePlanDeFinancement());
  },
  onChange: produit => dispatch(financerProduitAchete(produit)),
  onDelete: produit => {
    dispatch(supprimerProduit(produit.id));
    dispatch(orchestrerLePlanDeFinancement());
  },
  onAppliquerTauxDeVrGrille: idProduit => {
    dispatch(appliquerLeTauxDeVrGrille(idProduit));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnProduitAchete);

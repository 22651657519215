import { call } from "redux-saga/effects";
import {
  ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE,
  REFUSER_DEROGATION_ASSURANCE_ESPAGNE,
} from "./actions";
import { trySaga } from "../../../../../utils/sagasUtils";
import { deciderDerogationSaga } from "../../decision/sagas";

const ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_ASSURANCE_ESPAGNE;

export function* accepterDerogationAssuranceEspagneSaga(action) {
  function* saga() {
    const { derogation, decision } = action;
    yield call(
      deciderDerogationSaga,
      derogation,
      decision,
      ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE
    );
  }

  yield* trySaga(saga, ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE);
}

export function* refuserDerogationAssuranceEspagneSaga(action) {
  function* saga() {
    const { derogation, decision } = action;
    yield call(
      deciderDerogationSaga,
      derogation,
      decision,
      ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE
    );
  }

  yield* trySaga(saga, REFUSER_DEROGATION_ASSURANCE_ESPAGNE);
}

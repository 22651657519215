import React from "react";
import ErrorBoundary from "../../../Kit/Erreur/ErrorBoundary";
import ChoixDeLaMarqueItl from "../../ModeLight/composants/MarquesItl/ChoixDeLaMarqueItl.container";

const MODE_LIGHT_EST_DISPONIBLE =
  process.env.REACT_APP_MODE_LIGHT_EST_DISPONIBLE === "true";

export function ChoixDuMode() {
  return (
    <ErrorBoundary zone="CHOIX_DU_MODE">
      {MODE_LIGHT_EST_DISPONIBLE && <ChoixDeLaMarqueItl />}
    </ErrorBoundary>
  );
}

import { selectMaintenance } from "./reducer";

export function decouperPourIntegrerLaMaintenance(paliers, stateMaintenance) {
  const maintenance = selectMaintenance(stateMaintenance);
  if (!maintenance.estActivee) return paliers;

  return decouperLesPaliers(
    paliers,
    maintenance.nbEcheancesDeDecalage,
    maintenance.dureeEnEcheances
  );
}

export function decouperLesPaliers(
  paliers,
  nbEcheancesDeDecalage,
  dureeEnEcheances
) {
  const debut = nbEcheancesDeDecalage ?? 0;
  const fin = debut + dureeEnEcheances;

  return metaDecoupe(paliers, { decalage: debut, fin })
    .map(({ avecMaintenance, decoupe, gauche, centre, droite }, i) => {
      if (!decoupe) return { ...paliers[i], avecMaintenance };

      if (decoupe) {
        return [
          unPalier(gauche),
          centre ? unPalier(centre) : undefined,
          unPalier(droite),
        ].filter((p) => p !== undefined);
      }

      function unPalier(item) {
        return {
          ...paliers[i],
          duree: item.echeances,
          avecMaintenance: item.avecMaintenance,
        };
      }
    })
    .flat();
}

export function metaDecoupe(paliers, { decalage, fin }) {
  const decoupe = [];

  let dureeCumulee = 0;
  let decalageRestant = decalage;
  let finRestante = fin;

  for (let i = 0; i < paliers.length; i++) {
    const palier = paliers[i];
    dureeCumulee += palier.duree;

    const palierAvantLeDecalage = decalage >= dureeCumulee;
    if (palierAvantLeDecalage) {
      decoupe.push({ palier: i + 1, decoupe: false, avecMaintenance: false });
      decalageRestant -= palier.duree;
      finRestante -= palier.duree;
      continue;
    }

    const decoupePourDecalage =
      decalageRestant > 0 && decalageRestant < palier.duree;
    if (decoupePourDecalage) {
      const finSurLeMemePalier = finRestante < palier.duree;

      if (finSurLeMemePalier) {
        const gauche = sansMaintenance(decalageRestant);
        const centre = avecMaintenance(
          palier.duree - finRestante - decalageRestant
        );
        const droite = sansMaintenance(palier.duree - finRestante);
        decoupe.push({ palier: i + 1, decoupe: true, gauche, centre, droite });
        decalageRestant = 0;
        finRestante = 0;
      } else {
        const gauche = sansMaintenance(decalageRestant);
        const droite = avecMaintenance(palier.duree - decalageRestant);
        decoupe.push({ palier: i + 1, decoupe: true, gauche, droite });
        decalageRestant = 0;
        finRestante -= palier.duree;
      }
      continue;
    }

    const palierCompletAvecMaintenance =
      decalageRestant === 0 && fin >= dureeCumulee;
    if (palierCompletAvecMaintenance) {
      decoupe.push({ palier: i + 1, decoupe: false, avecMaintenance: true });
      finRestante -= palier.duree;
      continue;
    }

    const decoupePourFin = finRestante !== 0 && finRestante < palier.duree;
    if (decoupePourFin) {
      const gauche = avecMaintenance(finRestante);
      const droite = sansMaintenance(palier.duree - finRestante);
      decoupe.push({ palier: i + 1, decoupe: true, gauche, droite });
      finRestante = 0;
      continue;
    }

    const palierPostFin = finRestante === 0;
    if (palierPostFin) {
      decoupe.push({ palier: i + 1, decoupe: false, avecMaintenance: false });
    }
  }

  return decoupe;

  function sansMaintenance(echeances) {
    return { echeances, avecMaintenance: false };
  }

  function avecMaintenance(echeances) {
    return { echeances, avecMaintenance: true };
  }
}

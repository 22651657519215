import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import UnProduit from "./UnProduit";
import "./Produits.css";
import "./Produits-mobile.css";
import AjouterElement from "./Ajout/AjouterElement.container";

const Produits = ({ produits, className = "" }) => (
  <div className={`produits ${className}`}>
    <h3>
      <FormattedMessage id="calculateur.produits.titre" />
    </h3>
    <div className="produits-et-actions">
      <div className="produits-liste">
        {produits.map((p) => (
          <UnProduit key={p.id} produit={p} />
        ))}
      </div>
      <AjouterElement />
    </div>
  </div>
);

Produits.propTypes = {
  produits: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default Produits;

import {
  AJOUTER_COMMISSION,
  CHANGER_LE_TAUX_DE_MARGE,
  CHANGER_LE_TAUX_DE_MARGE_RECALCULE_POUR_TEG,
  FINANCER_PRODUIT_ACHETE,
  RACHETER_CONTRAT,
  RACHETER_UN_MATERIEL,
  SUPPRIMER_COMMISSION,
  SUPPRIMER_PRODUIT,
  SUPPRIMER_RACHAT_DE_CONTRAT,
  SUPPRIMER_RACHAT_DE_MATERIEL,
} from "./actions";
import { ID_MARQUE_GENERIQUE } from "../catalogueProduits/reducer";
import { argentVide, Money } from "../../../../../Money/money";
import { CHARGER_MARGE_CIBLE_SUCCES } from "../margeCible/actions";
import { REVENIR_AU_TEG_GRILLE } from "../tauxInteret/actions";

export const LOUEUR_DE_CONTRAT = {
  ITL: "ITL",
  CONCURRENT: "CONCURRENT",
};
const { ITL } = LOUEUR_DE_CONTRAT;
export const PAS_DE_TAUX_DE_MARGE = null;

const STATE_INITIAL = {
  produits: {
    achetes: { parId: {} },
  },
  rachats: {
    contrats: { parId: {} },
    materiels: { parId: {} },
  },
  margeDeBase: {
    tauxManuel: PAS_DE_TAUX_DE_MARGE,
    tauxRecalculePourTeg: PAS_DE_TAUX_DE_MARGE,
    tauxDeMargeCible: 0,
  },
  commissions: { parId: {} },
};

const reducer = (state = STATE_INITIAL, action) => {
  switch (action.type) {
    case RACHETER_CONTRAT:
      return {
        ...state,
        rachats: {
          ...state.rachats,
          contrats: {
            parId: {
              ...state.rachats.contrats.parId,
              [action.rachat.id]: action.rachat,
            },
          },
        },
      };

    case SUPPRIMER_RACHAT_DE_CONTRAT:
      const contrats = { ...state.rachats.contrats.parId };
      delete contrats[action.idRachat];

      return {
        ...state,
        rachats: {
          ...state.rachats,
          contrats: { parId: contrats },
        },
      };

    case CHARGER_MARGE_CIBLE_SUCCES:
      return {
        ...state,
        margeDeBase: {
          ...state.margeDeBase,
          tauxDeMargeCible: action.margeCible.tauxDeMargeCible,
          tauxManuel: action.margeCible.tauxDeMargeCible,
        },
      };

    case FINANCER_PRODUIT_ACHETE:
      return {
        ...state,
        produits: {
          ...state.produits,
          achetes: {
            parId: {
              ...state.produits.achetes.parId,
              [action.produit.id]: action.produit,
            },
          },
        },
      };

    case SUPPRIMER_PRODUIT:
      const produitsAchetes = { ...state.produits.achetes.parId };
      delete produitsAchetes[action.idProduit];

      return {
        ...state,
        produits: { achetes: { parId: produitsAchetes } },
      };

    case CHANGER_LE_TAUX_DE_MARGE:
      return {
        ...state,
        margeDeBase: { ...state.margeDeBase, tauxManuel: action.tauxDeMarge },
      };

    case CHANGER_LE_TAUX_DE_MARGE_RECALCULE_POUR_TEG:
      return {
        ...state,
        margeDeBase: {
          ...state.margeDeBase,
          tauxRecalculePourTeg: action.tauxDeMarge,
        },
      };

    case REVENIR_AU_TEG_GRILLE:
      return {
        ...state,
        margeDeBase: {
          ...state.margeDeBase,
          tauxRecalculePourTeg: PAS_DE_TAUX_DE_MARGE,
        },
      };

    case RACHETER_UN_MATERIEL:
      const { rachat } = action;

      return {
        ...state,
        rachats: {
          ...state.rachats,
          materiels: {
            parId: {
              ...state.rachats.materiels.parId,
              [rachat.id]: rachat,
            },
          },
        },
      };

    case SUPPRIMER_RACHAT_DE_MATERIEL:
      const rachatsDeMateriels = { ...state.rachats.materiels.parId };
      delete rachatsDeMateriels[action.idRachat];

      return {
        ...state,
        rachats: { ...state.rachats, materiels: { parId: rachatsDeMateriels } },
      };

    case AJOUTER_COMMISSION:
      return {
        ...state,
        commissions: {
          parId: {
            ...state.commissions.parId,
            [action.commission.id]: action.commission,
          },
        },
      };

    case SUPPRIMER_COMMISSION:
      const commissions = { ...state.commissions.parId };
      delete commissions[action.idCommission];

      return { ...state, commissions: { parId: { ...commissions } } };

    default:
      return state;
  }
};

export default reducer;

export function produitAcheteVide(idProduit, timestamp) {
  return {
    id: idProduit,
    quantite: 1,
    montantUnitaire: null,
    idFamille: null,
    idMarque: ID_MARQUE_GENERIQUE,
    labelMarque: "",
    description: "",
    estOccasion: false,
    annee: null,
    designationDuNonReference: "",
    marqueDuNonReference: "",
    timestamp,
  };
}
export const PAS_DE_MATERIEL_SUR_LE_RACHAT = {};
export function rachatDeContratVide(idProduit, timestamp) {
  return {
    id: idProduit,
    montantEncours: null,
    montantDeLaRevente: null,
    dateEncours: "",
    numeroContrat: "",
    loueurContrat: ITL,
    materielRachete: PAS_DE_MATERIEL_SUR_LE_RACHAT,
    description: "",
    timestamp,
  };
}
export function rachatDeMaterielVide(idRachat, timestamp) {
  return {
    id: idRachat,
    montantDuRachat: null,
    montantDeLaRevente: null,
    description: "",
    annee: null,
    timestamp,
  };
}

export const listeDesCommissions = (state) =>
  Object.values(state.commissions.parId);

export const listeDesProduitsAchetes = (state) =>
  Object.values(state.produits.achetes.parId);

export const listeDesRachatsDeContrats = (state) =>
  Object.values(state.rachats.contrats.parId);

export const listeDesRachatsDeMateriels = (state) =>
  Object.values(state.rachats.materiels.parId);

export const listeAFinancer = (state) => [
  ...listeDesProduitsAchetes(state),
  ...listeDesRachatsDeContrats(state),
  ...listeDesRachatsDeMateriels(state),
  ...listeDesCommissions(state),
];

export const selectProduitAchete = (state, idProduit) =>
  state.produits.achetes.parId[idProduit];

export const produitSansMontant = (produit) =>
  argentVide(produit.montantUnitaire) ||
  Money(produit.montantUnitaire).multiply(produit.quantite).isZero();

import { connect } from "react-redux";
import AjouterElement from "./AjouterElement";
import {
  financerProduitAchete,
  racheterContrat,
  racheterUnMateriel,
} from "../../../domaine/simulation/montantAFinancer/actions";
import { projetDeTravailEstAER } from "../../../domaine/simulation/projetDeTravail/reducer";
import {
  calculateur,
  choixDuProjet,
} from "../../../../../reduxStore/rootReducer";
import { estEnMode, MODE_AFFICHAGE } from "../../../../../Ui/reducer";
import { selectNatureDuCalcul } from "../../../domaine/simulation/modeDeCalcul/reducer";
import { listeDesProduitsAchetes } from "../../../domaine/simulation/montantAFinancer/reducer";

const { LIGHT } = MODE_AFFICHAGE;

const mapStateToProps = (state) => {
  const { modeDeCalcul, montantAFinancer } = calculateur(state).simulation;
  return {
    estEnModeLight: estEnMode(LIGHT, state.ui),
    projetEstAER: estEnMode(LIGHT, state.ui)
      ? undefined
      : projetDeTravailEstAER(choixDuProjet(state)),
    natureDuCalcul: selectNatureDuCalcul(modeDeCalcul),
    nombreDeMaterielsFinances: listeDesProduitsAchetes(montantAFinancer).length,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFinancerProduit: (produit) => dispatch(financerProduitAchete(produit)),
  onRacheterMateriel: (rachat) => dispatch(racheterUnMateriel(rachat)),
  onRacheterContrat: (rachat) => dispatch(racheterContrat(rachat)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AjouterElement);

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import Select, {
  WrapCustomOption,
} from "../../../../../../Kit/Formulaires/Select";
import {
  ID_MARQUE_GENERIQUE,
  produitEstNonReference,
} from "../../../../domaine/simulation/catalogueProduits/reducer";
import Euros from "../../../../../../Kit/Finance/Euros";
import InputMontant from "../../../../../../Kit/Formulaires/InputMontant";
import { Carte } from "../../../../../../Kit/Carte/Carte";
import { Obligatoire } from "../../../../../../Kit/Formulaires/Obligatoire";
import Textarea from "../../../../../../Kit/Formulaires/Textarea";
import { Description } from "../Kit/Description";
import Assurance from "./Assurance/Assurance.container";
import { $obj, forInput, MoneyPropType } from "../../../../../../Money/money";
import Vr from "./Vr/Vr.container";
import { useStateSyncedToProp } from "../../../../../../utils/hooks";
import { InputTextSuggestion } from "../../../../../../Kit/FormulairesInputTextSuggestion";
import { filtreDeMateriels } from "../../../../../../Kit/Formulaires/Select.filtres";
import { Option_MaterielCategorise } from "../Kit/Option_MaterielCategorise";
import { NATURE_DU_CALCUL } from "../../../../domaine/simulation/modeDeCalcul/reducer";

const { INVESTISSEMENT } = NATURE_DU_CALCUL;

function UnProduitAchete({
  produit,
  onChange,
  onChangeQuiRecalcule,
  onDelete,
  onAppliquerTauxDeVrGrille,
  produitCatalogue,
  labelCatalogue,
  longueurMaxDescription,
  marquesDisponibles,
  selectionDeMateriels,
  estEnModeLight,
  natureDuCalcul,
}) {
  const intl = useIntl();

  const onChangeQuantite = quantite => {
    onChangeQuiRecalcule(
      { ...produit, quantite },
      { rechercheProduitAssurance: true }
    );
  };

  const onChangePrix = montantUnitaire => {
    onChangeQuiRecalcule(
      { ...produit, montantUnitaire: $obj(montantUnitaire) },
      { rechercheProduitAssurance: true }
    );
  };

  const onChangeMateriel = selection => {
    const { value: { famille, marque } } = selection;

    const pasDeChangement =
      famille.id === produit.idFamille && marque.id === produit.idMarque;
    if (pasDeChangement) return;

    onChangeQuiRecalcule(
      {
        ...produit,
        idFamille: famille.id,
        idMarque: marque.id,
        labelMarque: marque.id !== ID_MARQUE_GENERIQUE ? marque.label : "",
        description:
          marque.id === ID_MARQUE_GENERIQUE
            ? famille.label
            : famille.label + " - " + marque.label,
      },
      { rechercheProduitAssurance: true }
    );

    onAppliquerTauxDeVrGrille(produit.id);
  };

  const onChangeLabelDeLaMarque = labelMarque => {
    const utiliseUneDescriptionAutomatique = produitEstNonReference(produit);
    const description = utiliseUneDescriptionAutomatique
      ? descriptionAutomatiqueDuNonReference(
          labelCatalogue,
          produit.designationDuNonReference,
          labelMarque
        )
      : produitCatalogue.label + " - " + labelMarque;

    onChange({ ...produit, labelMarque, description });
  };

  const onChangeAnneeDeOccasion = annee =>
    onChangeQuiRecalcule({ ...produit, annee });

  const onChangeDesignationDuNonReference = designationDuNonReference => {
    const description = descriptionAutomatiqueDuNonReference(
      labelCatalogue,
      designationDuNonReference,
      produit.labelMarque
    );
    onChange({ ...produit, designationDuNonReference, description });
  };

  const onChangeDescription = description =>
    onChange({ ...produit, description });

  const onSupprimerMateriel = () => onDelete(produit);

  return (
    <Carte
      titre={
        produit.estOccasion
          ? intl.formatMessage({ id: "calculateur.un_produit.occasion" })
          : intl.formatMessage({ id: "calculateur.un_produit.neuf" })
      }
    >
      <div className="flex-dir-col flex-content-sb flex-1">
        <div>
          <div className="ligne-de-formulaire">
            <QuantiteEtPrix
              quantite={produit.quantite}
              onChangeQuantite={onChangeQuantite}
              prix={forInput(produit.montantUnitaire)}
              onChangePrix={onChangePrix}
              focusChampPrix={forInput(produit.montantUnitaire) === null}
              montantDisabled={natureDuCalcul === INVESTISSEMENT}
            />
          </div>

          <div className="ligne-de-formulaire">
            <Materiel
              selectionDeMateriels={selectionDeMateriels}
              idFamille={produit.idFamille}
              idMarque={produit.idMarque}
              labelCatalogue={labelCatalogue}
              onChange={onChangeMateriel}
            />
          </div>

          {!estEnModeLight &&
            produit.idFamille !== null &&
            produit.idMarque === ID_MARQUE_GENERIQUE && (
              <div className="ligne-de-formulaire">
                <LabelDeLaMarque
                  value={produit.labelMarque}
                  onChange={onChangeLabelDeLaMarque}
                  marquesDisponibles={marquesDisponibles}
                />
              </div>
            )}

          {produit.estOccasion && (
            <div className="ligne-de-formulaire">
              <AnneeDeOccasion
                value={produit.annee}
                onChange={onChangeAnneeDeOccasion}
                estObligatoire={!estEnModeLight}
              />
            </div>
          )}

          {!estEnModeLight &&
            produitEstNonReference(produit) && (
              <div>
                <DesignationDuNonReference
                  value={produit.designationDuNonReference || ""}
                  onChange={onChangeDesignationDuNonReference}
                />
              </div>
            )}

          {produit.idFamille && (
            <div className="mb-10">
              <Vr materiel={produit} />
            </div>
          )}

          {!estEnModeLight && (
            <Description
              longueurMax={longueurMaxDescription}
              onChange={onChangeDescription}
              valeurInitiale={produit.description}
              label={
                <FormattedMessage id="calculateur.un_produit.description_materiel" />
              }
              testId={"description"}
              readOnly={produitEstNonReference(produit)}
            />
          )}
        </div>

        <Assurance materiel={produit} />

        <div className="mha">
          <SupprimerMateriel onClick={onSupprimerMateriel} />
        </div>
      </div>
    </Carte>
  );
}

export const UnProduitAchetePropTypes = {
  produit: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantite: PropTypes.number,
    montantUnitaire: MoneyPropType,
    description: PropTypes.string.isRequired,
    idFamille: PropTypes.string,
    idMarque: PropTypes.string,
    timestamp: PropTypes.number.isRequired,
    estOccasion: PropTypes.bool.isRequired,
    annee: PropTypes.number,
  }),
  produitCatalogue: PropTypes.object,
  labelCatalogue: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeQuiRecalcule: PropTypes.func.isRequired,
  onAppliquerTauxDeVrGrille: PropTypes.func.isRequired,
  longueurMaxDescription: PropTypes.number.isRequired,
  selectionDeMateriels: PropTypes.object.isRequired,
  marquesDisponibles: PropTypes.array.isRequired,
  estEnModeLight: PropTypes.bool.isRequired,
  natureDuCalcul: PropTypes.oneOf(Object.values(NATURE_DU_CALCUL)).isRequired,
};

UnProduitAchete.propTypes = {
  ...UnProduitAchetePropTypes,
};

export default UnProduitAchete;

function QuantiteEtPrix({
  quantite,
  onChangeQuantite,
  prix,
  onChangePrix,
  focusChampPrix,
  montantDisabled,
}) {
  const intl = useIntl();
  return (
    <>
      <div>
        <label>
          <Obligatoire className="mr-5" />
          <FormattedMessage id="calculateur.un_produit.quantite" />
        </label>
      </div>
      <div>
        <InputMontant
          value={quantite}
          onBlur={onChangeQuantite}
          className="input-xs"
          placeholder="1"
          testId="input-materiel-quantite"
        />
        <span className="text-bold">X</span>
        <InputMontant
          focusOnMount={focusChampPrix}
          value={prix}
          onBlur={onChangePrix}
          className="input-l"
          placeholder={intl.formatMessage({
            id: "calculateur.un_produit.montant_unitaire",
          })}
          append={<Euros />}
          testId="input-materiel-prix"
          disabled={montantDisabled}
        />
      </div>
    </>
  );
}

function Materiel({
  selectionDeMateriels,
  idFamille,
  idMarque,
  labelCatalogue,
  onChange,
}) {
  const intl = useIntl();
  const value =
    idFamille && idMarque
      ? { value: { idFamille, idMarque }, label: labelCatalogue }
      : null;

  return (
    <Select
      value={value}
      options={selectionDeMateriels.materiels}
      onChange={onChange}
      placeholder={intl.formatMessage({ id: "calculateur.un_produit.famille" })}
      className="w100"
      inputId="materiel-type"
      fonctionDeFiltre={filtreDeMateriels}
      customComponents={{ Option: WrapCustomOption(Option_MaterielCategorise) }}
    />
  );
}

function LabelDeLaMarque({ value, onChange, marquesDisponibles }) {
  const [valueLocale, setValueLocale] = useStateSyncedToProp(value);

  function getMarquesCorrespondantes(saisie) {
    const recherche = saisie.trim().toLowerCase();
    return marquesDisponibles
      .filter(m => m.label.toLowerCase().includes(recherche))
      .map(m => m.label);
  }

  return (
    <>
      <label className="mr-10">
        <Obligatoire className="mr-5" />
        <FormattedMessage id="calculateur.un_produit.marque" />
      </label>
      <div className="w100">
        <InputTextSuggestion
          value={valueLocale}
          getSuggestions={getMarquesCorrespondantes}
          onChange={(event, { newValue }) => setValueLocale(newValue)}
          onBlur={() => onChange(valueLocale)}
        />
      </div>
    </>
  );
}

function AnneeDeOccasion({ value, onChange, estObligatoire }) {
  return (
    <div>
      <label>
        {estObligatoire && <Obligatoire className="mr-5" />}
        <FormattedMessage id="calculateur.un_produit.annee" />
      </label>
      <InputMontant
        value={value}
        placeholder=""
        onBlur={onChange}
        className="input-s text-align-right"
      />
    </div>
  );
}

function DesignationDuNonReference({ value, onChange }) {
  return (
    <div>
      <div className="mb-5">
        <label>
          <Obligatoire className="mr-5" />
          <FormattedMessage id="calculateur.un_produit.non_reference.designation" />
        </label>
      </div>
      <Textarea
        value={value}
        rows={3}
        onChange={onChange}
        className="w100 mb-10"
        testId="designation-du-non-reference"
      />
    </div>
  );
}

function SupprimerMateriel({ onClick }) {
  return (
    <button
      className="suppr pure-button button-error button-small bouton-supprimer-produit"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTimesCircle} className="mr-5" />
      <FormattedMessage id="general.supprimer" />
    </button>
  );
}

function descriptionAutomatiqueDuNonReference(
  labelCatalogue,
  designationDuNonReference,
  labelMarque
) {
  return `${labelCatalogue} : ${designationDuNonReference} - ${labelMarque}`;
}

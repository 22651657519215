import { httpServiceCrm } from "../../../../../../Connecteurs/Crm/httpServiceCrm";
import { formatyyyyMMdd } from "../../../../../../utils/dateUtils";

export const creerDerogationDeMargeAdapterCrm = {
  async creerDerogationDeMarge(
    derogation,
    idFicheDeTransmission,
    idProjetDeTravail,
    timestampCreation,
    { http = httpServiceCrm } = {}
  ) {
    const creee = await http.post("itl_derogations?$select=itl_derogationid", {
      "itl_FTconcernee@odata.bind": `/itl_fichetransmissions(${idFicheDeTransmission})`,
      "itl_projetconcerne@odata.bind": `/itl_projets(${idProjetDeTravail})`,
      itl_datedemande: formatyyyyMMdd(timestampCreation),
      ...derogation,
    });

    return { id: creee.itl_derogationid };
  },
};

import React, { useState } from "react";
import Autosuggest from "react-autosuggest";

const LONGUEUR_MINI_POUR_SUGGESTIONS = 3;

export function InputTextSuggestion({
  getSuggestions,
  value,
  onChange,
  onBlur,
}) {
  const [suggestions, setSuggestions] = useState([]);

  const onSuggestionsFetchRequested = ({ value }) =>
    setSuggestions(getSuggestions(value));

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const getSuggestionValue = (value) => value;

  const renderSuggestion = (value) => <span>{value}</span>;

  const shouldRenderSuggestions = (value) =>
    value.trim().length >= LONGUEUR_MINI_POUR_SUGGESTIONS;

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions}
      inputProps={{ value, onChange, onBlur }}
    />
  );
}

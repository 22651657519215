import { verifierRachatsDeMateriels } from "./controles/verifierRachatsDeMateriels";
import { verifierRachatsDeContrats } from "./controles/verifierRachatsDeContrats";
import { verifierMargeSuffisante } from "./controles/verifierMargeSuffisante";
import { verifierNonValeur } from "./controles/verifierNonValeur";
import { verifierLaDescriptionDesMaterielsNonReferences } from "./controles/verifierLaDescriptionDesMaterielsNonReferences";
import { verifierLeTegGrilleExistant } from "./controles/verifierLeTegGrilleExistant";
import { verifierLesTauxDeVr } from "./controles/verifierLesTauxDeVr";
import { enrichirAvecDerogationAnterieure } from "./derogationAnterieure/enrichirAvecDerogationAnterieure";
import { verifierLesMateriels } from "./controles/verifierLesMateriels";
import { verifierLesVrDejaRefusees } from "./controles/verifierLesVrDejaRefusees";
import { verifierLesLoyersNegatifs } from "./controles/verifierLesLoyersNegatifs";
import { verifierLeTegManuel } from "./controles/verifierLeTegManuel";
import { enrichirAvecDerogateur } from "./derogateur/enrichirAvecDerogateur";
import { verifierLesVrRefuseesCategoriquement } from "./controles/verifierLesVrRefuseesCategoriquement";
import { verifierLaMaintenanceTropLongue } from "./controles/verifierLaMaintenanceTropLongue";
import { verifierAssuranceEspagneManquante } from "./controles/verifierAssuranceEspagneManquante";
import { verifierAssuranceEspagneInsuffisante } from "./controles/verifierAssuranceEspagneInsuffisante";
import { verifierAssuranceEspagneDejaRefusee } from "./controles/verifierAssuranceEspagneDejaRefusee";

export function controlerLeCalculateur(simulation, planDeFinancement) {
  return [
    verifierLesMateriels(simulation),
    verifierRachatsDeMateriels(simulation),
    verifierRachatsDeContrats(simulation),
    verifierMargeSuffisante(simulation),
    verifierNonValeur(simulation),
    verifierLaDescriptionDesMaterielsNonReferences(simulation),
    verifierLeTegGrilleExistant(simulation),
    verifierLeTegManuel(simulation),
    verifierLesTauxDeVr(simulation),
    verifierLesVrDejaRefusees(simulation),
    verifierLesVrRefuseesCategoriquement(simulation),
    verifierLaMaintenanceTropLongue(simulation),
    verifierAssuranceEspagneManquante(simulation),
    verifierAssuranceEspagneInsuffisante(simulation),
    verifierAssuranceEspagneDejaRefusee(simulation),
    verifierLesLoyersNegatifs(planDeFinancement),
  ]
    .flat()
    .filter((a) => a !== undefined)
    .map(affecterDerogateur(simulation))
    .map(affecterDerogationAnterieure(simulation));
}

const affecterDerogateur = (simulation) => (anomalie) =>
  enrichirAvecDerogateur(anomalie, simulation);

const affecterDerogationAnterieure = (simulation) => (anomalie) =>
  enrichirAvecDerogationAnterieure(anomalie, simulation);

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FaArrowCircleLeft from "react-icons/lib/fa/arrow-circle-left";
import "./CatchLesExceptions.css";
import BadgeErreur from "../../../Kit/Badges/Erreur/BadgeErreur";
import SensInterdit from "./picto/sens_interdit.svg";
import { useIntl } from "react-intl";

const CatchLesExceptions = ({
  children,
  messageErreur,
  onRevenirAuStableClick,
}) => {
  const intl = useIntl();
  return (
    <Fragment>
      {/*
      Affiche TOUJOURS children, puis éventuellement l'overlay d'erreur par-dessus.
      Comme ça, le cycle de vie de {children} ne redémarre pas (didMount() etc) lorsque l'overlay d'erreur disparaît.
      */}
      {children}

      <div style={{ display: messageErreur ? "block" : "none" }}>
        <div className="erreur-overlay" />

        <div className="revenir-etat-stable">
          <FaArrowCircleLeft
            size={50}
            color="white"
            style={{ cursor: "pointer", marginBottom: "20px" }}
            onClick={onRevenirAuStableClick}
          />
          <BadgeErreur
            picto={SensInterdit}
            titre={intl.formatMessage({
              id: "erreur_est_survenue.apres_action",
            })}
            messages={[messageErreur]}
          />
        </div>
      </div>
    </Fragment>
  );
};

CatchLesExceptions.propTypes = {
  messageErreur: PropTypes.string,
  stack: PropTypes.string,
  onRevenirAuStableClick: PropTypes.func.isRequired,
};

export default CatchLesExceptions;

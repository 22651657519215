import React, { useEffect } from "react";
import PropTypes from "prop-types";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import Barriere from "../../../../Kit/Erreur/Barriere";

function ControleDeProjetUtilisable({
  chargementEnCours,
  children,
  erreurs,
  erreursDuProjet,
  initialisation,
}) {
  useEffect(() => initialisation(), []);

  return (
    <div>
      {chargementEnCours ? (
        <div className="mt-20">
          <BarreDeChargement />
        </div>
      ) : (
        <Barriere messages={erreurs}>
          <Barriere messages={erreursDuProjet}>{children}</Barriere>
        </Barriere>
      )}
    </div>
  );
}

ControleDeProjetUtilisable.propTypes = {
  idProjet: PropTypes.string.isRequired,
  initialisation: PropTypes.func.isRequired,
  chargementEnCours: PropTypes.bool.isRequired,
  erreurs: PropTypes.array.isRequired,
  erreursDuProjet: PropTypes.array.isRequired,
};

export default ControleDeProjetUtilisable;

import { fetchAvecTokenAzure } from "../../authentification/fetch";
import { Money } from "../../Money/money";

const URL_K3000 = process.env.REACT_APP_URL_K3000;
const ID_DE_K3000_DANS_AZURE = process.env.REACT_APP_ID_DE_K3000_DANS_AZURE;
const scopeK3000 = `${ID_DE_K3000_DANS_AZURE}/user_impersonation`;

export const httpK3000 = {
  async post(cible, body) {
    const reponse = await fetchAvecTokenAzure(
      `${URL_K3000}${cible}`,
      scopeK3000,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      },
      extractErrorMessageK3000
    );

    return JSON.parse(reponse);
  },
};

export function extractErrorMessageK3000(textBody) {
  const erreurs = JSON.parse(textBody).errors;
  return erreurs[0].title;
}

export function montantK3000(moneyObject) {
  return Money(moneyObject)
    .toUnit()
    .toString();
}

export function pourcentageK3000(taux) {
  return (taux * 100).toFixed(2);
}

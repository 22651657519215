import {
  activerLaVrUnLoyer,
  desactiverLaVrUnLoyer,
} from "../../domaine/simulation/valeurResiduelle/actions";
import { connect } from "react-redux";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { VrUnLoyer } from "./VrUnLoyer";
import { uneOperationEstEnCours } from "../../../../Ui/reducer";
import {
  ORCHESTRER_PLAN_DE_FINANCEMENT,
  orchestrerLePlanDeFinancement,
} from "../../domaine/planDeFinancement/actions";
import { vrUnLoyerEstActivee } from "../../domaine/simulation/valeurResiduelle/selecteurs/vrUnLoyer.selecteurs";
import {
  NATURE_DU_CALCUL,
  selectNatureDuCalcul,
} from "../../domaine/simulation/modeDeCalcul/reducer";
import { vrUnLoyerEstEffective } from "../../domaine/planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

const { LOYERS } = NATURE_DU_CALCUL;

const mapStateToProps = (state) => {
  const { simulation, planDeFinancement } = calculateur(state);

  return {
    vrUnLoyerEstPossible:
      selectNatureDuCalcul(simulation.modeDeCalcul) === LOYERS,
    estActive: vrUnLoyerEstEffective(planDeFinancement),
    isLoading:
      vrUnLoyerEstActivee(simulation.valeurResiduelle) &&
      uneOperationEstEnCours(state.ui, [ORCHESTRER_PLAN_DE_FINANCEMENT]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  utiliserDesVrUnLoyer: () => {
    dispatch(activerLaVrUnLoyer());
    dispatch(orchestrerLePlanDeFinancement());
  },
  desactiverLaVrUnLoyer: () => {
    dispatch(desactiverLaVrUnLoyer());
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VrUnLoyer);

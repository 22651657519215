import { selectMarcheUtilisateur } from "../../simulation/profilUtilisateur/reducer";
import {
  selectAssuranceEspagneEstAjoutee,
  selectMontantPrimeMinimaleAssuranceEspagne,
  selectPrimeAssuranceEspagne,
} from "../../simulation/assurance/espagne/reducer";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";
import { Money } from "../../../../../Money/money";
import { AnomalieAssuranceEspagneInsuffisante } from "../Anomalies";
import { Features } from "../../../../../features/Features";

const { ESPAGNE } = MARCHES;

export function verifierAssuranceEspagneInsuffisante(simulation) {
  const featureActivee = Features.AvecDerogationAssuranceEspagne === true;
  if (!featureActivee) return;

  const { assurance, profilUtilisateur } = simulation;

  if (pasEnEspagne(profilUtilisateur)) return;
  if (aucuneAssurance(assurance)) return;

  const minimum = selectMontantPrimeMinimaleAssuranceEspagne(assurance.espagne);
  const prime = selectPrimeAssuranceEspagne(assurance.espagne).annuelle.montant;

  if (Money(prime).lessThan(Money(minimum)))
    return AnomalieAssuranceEspagneInsuffisante(minimum, prime);
}

function pasEnEspagne(profilUtilisateur) {
  const marche = selectMarcheUtilisateur(profilUtilisateur);
  return marche !== ESPAGNE;
}

function aucuneAssurance(assurance) {
  return !selectAssuranceEspagneEstAjoutee(assurance.espagne);
}

import { PaliersPourLoyer } from "./PaliersPourLoyer";
import { connect } from "react-redux";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import {
  calculerEnLineaire,
  calculerEnMultiPaliers,
} from "../../../domaine/simulation/modeDeCalcul/actions";
import { orchestrerLePlanDeFinancement } from "../../../domaine/planDeFinancement/actions";
import planDeFinancementEstCalculable from "../../../domaine/planDeFinancement/naturesDuCalcul/loyers/planDeFinancementEstCalculable";
import { selectPaliersLoyersFinancier } from "../../../domaine/planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

const mapStateToProps = (state) => {
  const { planDeFinancement, simulation } = calculateur(state);

  return {
    // Si le plan n'est pas calculable, on montre à l'utilisateur les paliers qu'il a saisi.
    // Ça lui permet de conserver un Force "utilisable".
    paliers: planDeFinancementEstCalculable(simulation)
      ? selectPaliersLoyersFinancier(planDeFinancement)
      : simulation.modeDeCalcul.paliers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPaliersChange: (paliers) => {
    const unSeulPalier = paliers.length === 1;
    if (unSeulPalier) dispatch(calculerEnLineaire(paliers[0].duree));
    else dispatch(calculerEnMultiPaliers(paliers));

    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaliersPourLoyer);

import PropTypes from "prop-types";
import React from "react";
import Exclamation from "./picto/Exclamation.svg";

export function BadgeErreurBrut({
  className,
  messages,
  picto = Exclamation,
  style,
  titre,
}) {
  return (
    <div className={`badge-erreur ${className}`} style={style}>
      <div style={{ width: "48px", height: "48px" }}>
        <img
          src={picto}
          className="exclamation"
          width={48}
          height={48}
          alt="exclamation"
        />
      </div>
      <div className="detail-erreur">
        <h3>{titre}</h3>
        <div>
          {messages.map((m, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: m }} />
          ))}
        </div>
      </div>
    </div>
  );
}

BadgeErreurBrut.propTypes = {
  className: PropTypes.any,
  style: PropTypes.any,
  src: PropTypes.any,
  titre: PropTypes.any,
  map: PropTypes.any,
};

import { selectMaintenance } from "../../../simulation/maintenance/reducer";
import { call, select } from "redux-saga/effects";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { Adapters } from "../../../../../../reduxStore/adapters";

export function* ajouterMaintenanceSaga(
  ft,
  { creerMaintenanceAdapter = Adapters.creerMaintenanceAdapter } = {}
) {
  const { simulation } = yield select(calculateur);
  const maintenance = selectMaintenance(simulation.maintenance);

  const pasDeMaintenance = !maintenance.estActivee;
  if (pasDeMaintenance) return;

  yield call(creerMaintenanceAdapter.ajouterMaintenance, ft, {
    montantTotal: maintenance.montantTotal,
    montantParEcheance: maintenance.montantParEcheance,
    dureeEnEcheances: maintenance.dureeEnEcheances,
    nbEcheancesDeDecalage: maintenance.nbEcheancesDeDecalage,
  });
}

import { TYPE_DE_FINANCEMENT } from "./reducer";
import { httpK3000 } from "../../../../../Connecteurs/K3000/httpK3000";
import { LABELS_PERIODICITE_K3000 } from "../../../../../Connecteurs/K3000/labelsK3000";
import {
  nombreDeMois,
  PERIODICITES,
} from "../modeDeCalcul/periodicite/periodicite";
import GrilleDeVr from "./GrilleDeVr";
import { produitSansMontant } from "../montantAFinancer/reducer";
import { getTauxDepuisPourcentage } from "../../../../../Connecteurs/Crm/utils";

const { ANNUEL } = PERIODICITES;
const { CB, LLD } = TYPE_DE_FINANCEMENT;
const UN_POURCENT = 0.01;

export const vrGrillesAdapterK3000 = {
  async getLesVrGrilles(
    demandes,
    {
      typeDeFinancement,
      nombreEcheances,
      periodicite,
      anneeDeReference = new Date().getFullYear(),
    },
    { http = httpK3000 } = {}
  ) {
    const vrParFinancement = {
      [CB]: async () =>
        await vrCreditBail(
          demandes,
          { nombreEcheances, periodicite, anneeDeReference },
          { http }
        ),
      [LLD]: async () =>
        await vrLLD(
          demandes,
          { nombreEcheances, periodicite, anneeDeReference },
          { http }
        ),
    };

    return await vrParFinancement[typeDeFinancement]();
  },
};

async function vrCreditBail(
  demandes,
  { nombreEcheances, periodicite, anneeDeReference },
  { http }
) {
  return await Promise.all(
    demandes.map(async ({ materiel, grilleDeVr }) => {
      const vrCB = produitSansMontant(materiel) ? 0 : UN_POURCENT;

      const vrLLD = await getVrDeK3000(
        { materiel, grilleDeVr },
        { nombreEcheances, periodicite, anneeDeReference },
        { http }
      );

      return { materiel, tauxDeVr: vrCB, tauxSeuilDerogation: vrLLD };
    })
  );
}

async function vrLLD(
  demandes,
  { nombreEcheances, periodicite, anneeDeReference },
  { http }
) {
  return await Promise.all(
    demandes.map(async ({ materiel, grilleDeVr }) => {
      const tauxDeVr = await getVrDeK3000(
        { materiel, grilleDeVr },
        { nombreEcheances, periodicite, anneeDeReference },
        { http }
      );

      return { materiel, tauxDeVr, tauxSeuilDerogation: tauxDeVr };
    })
  );
}

async function getVrDeK3000(
  { materiel, grilleDeVr },
  { nombreEcheances, periodicite, anneeDeReference },
  { http }
) {
  const grille = new GrilleDeVr(grilleDeVr);

  if (grille.estVide()) return 0;

  const echeancesAvecAge =
    nombreEcheances + ageDuMateriel(materiel, periodicite, anneeDeReference);
  const demandeHorsGrille = grille.estUneDureeHorsBorne(
    nombreDeMois(echeancesAvecAge, periodicite)
  );
  if (demandeHorsGrille) return 0;

  const vrK3000 = await http.post(
    "/api/v2/financier/calcul/montant-a-financer-et-vr",
    {
      nombreDEcheances: echeancesAvecAge,
      typeDePeriodicite: LABELS_PERIODICITE_K3000[periodicite],
      produitsAFinancer: [enProduitK3000(materiel, grilleDeVr)],
    }
  );

  // Tant qu'on ne sais pas calculer le hash côté Force, on appelle cette route avec seulement UN produit.
  const leProduitUnique = vrK3000.produits[0];
  return getTauxDepuisPourcentage(leProduitUnique.pourcentageValeurResiduelle);
}

function enProduitK3000(materiel, grilleDeVr) {
  return {
    montantTotalDuProduit: "0",
    grilleValeursResiduelles: enGrilleK3000(grilleDeVr),
  };
}

export function enGrilleK3000(grilleDeVrForce) {
  const bornes = Object.entries(grilleDeVrForce);

  return {
    valeurs: bornes.map(([duree, taux]) => ({
      borneInferieureEnNombreDeMois: Number(duree),
      pourcentageValeurResiduelle: (taux * 100).toString(),
    })),
    borneSuperieureEnNombreDeMois: Number(bornes[bornes.length - 1][0]),
  };
}

export function ageDuMateriel(materiel, periodicite, anneeDeReference) {
  const materielNeuf = !materiel.estOccasion;
  if (materielNeuf) return 0;

  const anneeInconnue = materiel.annee === null;
  if (anneeInconnue) return 0;

  const ageEnAnnees = anneeDeReference - materiel.annee;

  return PERIODICITES.convertir(ageEnAnnees, ANNUEL).en(periodicite);
}

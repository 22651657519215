import React from "react";
import PropTypes from "prop-types";

const InputText = ({
  value,
  onChange,
  onBlur,
  placeholder,
  style,
  className,
  testId,
}) => (
  <input
    type="text"
    value={value}
    onChange={(event) => onChange(event.target.value)}
    onBlur={(event) => onBlur(event.target.value)}
    placeholder={placeholder}
    style={style}
    className={className}
    data-testid={testId}
  />
);

InputText.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

function noOp() {}

InputText.defaultProps = {
  style: {},
  className: "",
  onChange: noOp,
  onBlur: noOp,
};

export default InputText;

const SUJET = `{emoji} Dérogation Force {nomDuProjet} {verdict}`;

const CORPS = `
Bonjour,<br/>
Votre demande de dérogation de marge du {dateDeDemandeFormatee} a été {verdict} par {nomDerogateur}.<br/>
Commentaire : "{commentaire}".<br/>
Cette demande concerne le client {nomDuClient} et le projet {nomDuProjet}.<br/><br/>

<a href="{urlPourOuvrirFtEnCrm}">
    Vous pouvez accéder à la FT associée (dans le CRM) en cliquant ici.</a><br/>
<a href="{urlPourOuvrirProjetEnCrm}">
    Vous pouvez accéder à ce projet (dans Force) en cliquant ici.</a>
`;

export const TEMPLATE_NOTIFICATION_DECISION_FR = { CORPS, SUJET };

export const CHANGER_DELAI_DE_REGLEMENT = "CHANGER_DELAI_DE_REGLEMENT";
export const changerDelaiDeReglement = (delaiChoisi) => ({
  type: CHANGER_DELAI_DE_REGLEMENT,
  delai: delaiChoisi,
});
export const CHANGER_MODE_DE_REGLEMENT = "CHANGER_MODE_DE_REGLEMENT";
export const changerModeDeReglement = (modeChoisi) => ({
  type: CHANGER_MODE_DE_REGLEMENT,
  mode: modeChoisi,
});

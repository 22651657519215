import React from "react";
import { FormattedMessage } from "react-intl";
import { STATUT_DEROGATION } from "../../../../domaine/reducer";

const { A_DECIDER, ACCORD_PARTIEL } = STATUT_DEROGATION;

export function Submit({
  commentaire,
  onSubmit,
  statut,
  derogation,
  valeurAvis,
  pieceJointe,
}) {
  const accordPartielSansSaisie =
    statut === ACCORD_PARTIEL &&
    (valeurAvis.pourcentage === null || Number.isNaN(valeurAvis.pourcentage));

  const avis = {
    idDerogation: derogation.id,
    valeurBroker: valeurAvis,
    commentaireBroker: commentaire,
    dateDecision: Date.now(),
    statutDerogation: statut,
    pieceJointe,
  };

  return (
    <div className="le-bouton">
      <button
        disabled={!commentaire || statut === null || accordPartielSansSaisie}
        className="pure-button pure-button-primary"
        onClick={() => onSubmit(avis)}
      >
        <FormattedMessage
          id={
            derogation.statut !== A_DECIDER
              ? "derogation_de_vr.avis_broker.mettre_a_jour"
              : "derogation_de_vr.avis_broker.soumettre"
          }
        />
      </button>
    </div>
  );
}

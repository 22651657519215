import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { getQueryStringParam } from "../../../routing/routingUtils";
import { connect } from "react-redux";
import {
  INITIALISER_MODE_LIGHT,
  initialiserModeLight,
} from "../domaine/actions";
import BarreDeChargement from "../../../Kit/Loaders/BarreDeChargement";
import { erreurDeOperation, uneOperationEstEnCours } from "../../../Ui/reducer";
import Barriere from "../../../Kit/Erreur/Barriere";
import { calculateur } from "../../../reduxStore/rootReducer";
import Calculateur from "../../Calculateur/composants/Calculateur";
import { chargerInstantanes } from "../../../reduxStore/enhancers/instantanes/actions";
import {
  selectMarcheChoisi,
  selectProjetDeTravail,
} from "../../Calculateur/domaine/simulation/projetDeTravail/reducer";

function EntreeModeLight({ initialiserModeLight, isLoading, erreurs }) {
  const location = useLocation();
  const history = useHistory();
  const idMarche = getQueryStringParam(location, "id-marche");
  const idMarqueItl = getQueryStringParam(location, "id-marque-itl");
  const nomMarqueItl = getQueryStringParam(location, "nom-marque-itl");

  useEffect(() => {
    if (!idMarche || !nomMarqueItl || !idMarqueItl) history.replace("/");
    else initialiserModeLight(idMarche, { id: idMarqueItl, nom: nomMarqueItl });
  }, [initialiserModeLight, idMarche, idMarqueItl, nomMarqueItl, history]);

  return isLoading ? (
    <div className="mt-20" data-testid="entree-mode-light-loader">
      <BarreDeChargement />
    </div>
  ) : (
    <Barriere messages={erreurs}>
      <Calculateur />
    </Barriere>
  );
}

EntreeModeLight.propTypes = {
  initialiserModeLight: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  erreurs: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { simulation } = calculateur(state);
  const marchePasEncoreSet =
    !selectProjetDeTravail(simulation.projetDeTravail) ||
    !selectMarcheChoisi(simulation.projetDeTravail);

  return {
    isLoading:
      uneOperationEstEnCours(state.ui, [INITIALISER_MODE_LIGHT]) ||
      marchePasEncoreSet,
    erreurs: erreurDeOperation(state.ui, [INITIALISER_MODE_LIGHT]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  initialiserModeLight: (marche, marqueItl) => {
    dispatch(initialiserModeLight(marche, marqueItl));
    dispatch(chargerInstantanes());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EntreeModeLight);

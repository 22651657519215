import {
  AJOUTER_DEROGATION_ASSURANCE_ESPAGNE,
  AJOUTER_DEROGATION_DE_TEG,
  AJOUTER_DEROGATION_DE_VR,
  AJOUTER_NOTIFICATION_DE_DEROGATION_DE_VR,
  CHARGER_LES_DEROGATIONS_DE_MARGE,
} from "./actions";
import {
  FINANCER_PRODUIT_ACHETE,
  SUPPRIMER_PRODUIT,
} from "../montantAFinancer/actions";
import { differenceEnMinutes } from "../../../../../utils/dateUtils";
import { MODE_AFFICHAGE } from "../../../../../Ui/reducer";
import { DEFINIR_MODE_AFFICHAGE } from "../../../../../Ui/actions";
import { STATUT_DEROGATION } from "../../../../Derogations/domaine/reducer";

const { CLASSIQUE, LIGHT } = MODE_AFFICHAGE;
const { ACCEPTEE, A_DECIDER, REFUSEE } = STATUT_DEROGATION;

const STATE_INITIAL = {
  deMarge: [],
  deTeg: [],
  deAssuranceEspagne: [],
  deVr: { parIdProduit: {} },
  derniereNotificationPourVr: null,
  modeAffichage: CLASSIQUE,
};

export default function reducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CHARGER_LES_DEROGATIONS_DE_MARGE:
      return { ...state, deMarge: action.derogations };

    case AJOUTER_DEROGATION_DE_TEG:
      return { ...state, deTeg: [...state.deTeg, action.derogation] };

    case AJOUTER_DEROGATION_ASSURANCE_ESPAGNE:
      return {
        ...state,
        deAssuranceEspagne: [...state.deAssuranceEspagne, action.derogation],
      };

    case AJOUTER_DEROGATION_DE_VR:
      const aRecuDecision = action.derogation.statut !== A_DECIDER;

      return ajouterDerogationDeVr(state, {
        ...action.derogation,
        aRecuDecision,
      });

    case SUPPRIMER_PRODUIT:
      const derogationsDeVr = { ...state.deVr.parIdProduit };
      delete derogationsDeVr[action.idProduit];

      return {
        ...state,
        deVr: { parIdProduit: derogationsDeVr },
      };

    case AJOUTER_NOTIFICATION_DE_DEROGATION_DE_VR: {
      return { ...state, derniereNotificationPourVr: action.notification };
    }

    case DEFINIR_MODE_AFFICHAGE:
      if (action.modeAffichage === LIGHT)
        return { ...state, modeAffichage: action.modeAffichage };
      else {
        return {
          ...state,
          deVr: { parIdProduit: {} },
          deMarge: [],
          modeAffichage: action.modeAffichage,
        };
      }

    case FINANCER_PRODUIT_ACHETE:
      if (state.modeAffichage === CLASSIQUE) return state;

      const derogationFictive = {
        produit: action.produit,
        statut: ACCEPTEE,
        aRecuDecision: true,
        estFictive: true,
      };

      return ajouterDerogationDeVr(state, derogationFictive);

    default:
      return state;
  }
}

function ajouterDerogationDeVr(state, derogation) {
  return {
    ...state,
    deVr: {
      parIdProduit: {
        ...state.deVr.parIdProduit,
        [derogation.produit.id]: derogation,
      },
    },
  };
}

export const PAS_DE_DEROGATION = null;

export const selectTauxDeMargeDemandes = (state) =>
  state.deMarge.map((derogation) => derogation.taux.demande);

export const selectDerogationDeMargeAuTauxMinimum = (state) => {
  const tauxMinimum = Math.min(...selectTauxDeMargeDemandes(state));
  return (
    state.deMarge.find((d) => d.taux.demande === tauxMinimum) ||
    PAS_DE_DEROGATION
  );
};

export function selectDerogationDeMargeCouvrantLeTaux(state, tauxACouvrir) {
  return (
    state.deMarge.find((d) => d.taux.demande <= tauxACouvrir) ||
    PAS_DE_DEROGATION
  );
}

export const selectDerogationDeVr = (state, produit) => {
  const derogation = state.deVr.parIdProduit[produit.id];
  if (!derogation) return PAS_DE_DEROGATION;

  const familleChange = produit.idFamille !== derogation.produit.idFamille;
  const marqueChange = produit.idMarque !== derogation.produit.idMarque;
  if (familleChange || marqueChange) return PAS_DE_DEROGATION;

  return derogation;
};

export const selectDerogationDeVrAvecDecision = (state, produit) => {
  const derogation = selectDerogationDeVr(state, produit);
  return derogation && derogation.aRecuDecision
    ? derogation
    : PAS_DE_DEROGATION;
};

export function selectDerogationDeTeg(state) {
  return state.deTeg[0] ?? PAS_DE_DEROGATION;
}

export function selectDerogationAssuranceEspagne(state) {
  return state.deAssuranceEspagne[0] ?? PAS_DE_DEROGATION;
}

export function periodeDeSilenceEnCours(state, timestampActuel = Date.now()) {
  const aucuneNotifPrecedente = !state.derniereNotificationPourVr;
  if (aucuneNotifPrecedente) return false;

  const envoiPrecedent = new Date(state.derniereNotificationPourVr.dateEnvoi);
  return (
    differenceEnMinutes(timestampActuel, envoiPrecedent) <=
    PERIODE_DE_SILENCE_EN_MINUTES_DES_NOTIFICATIONS_DE_VR
  );
}

export function estRefuseeCategoriquement(derogation) {
  return derogation.statut === REFUSEE && derogation.valeurBroker === null;
}

const PERIODE_DE_SILENCE_EN_MINUTES_DES_NOTIFICATIONS_DE_VR =
  process.env
    .REACT_APP_DEROGATION_DE_VR_PERIODE_DE_SILENCE_EN_MINUTES_DES_NOTIFICATIONS;

export const CHARGER_UNE_DEROGATION_DETAILLEE =
  "CHARGER_UNE_DEROGATION_DETAILLEE";
export const chargerUneDerogationDetaillee = (idDerogation) => ({
  type: CHARGER_UNE_DEROGATION_DETAILLEE,
  idDerogation,
});

export const CHARGER_UNE_DEROGATION_DETAILLEE_SUCCES =
  "CHARGER_UNE_DEROGATION_DETAILLEE_SUCCES";
export const chargerUneDerogationDetaillee_succes = (derogation) => ({
  type: CHARGER_UNE_DEROGATION_DETAILLEE_SUCCES,
  derogation,
});

export const CHARGER_RESUMES_DE_DEROGATIONS = "CHARGER_TOUTES_LES_DEROGATIONS";
export const chargerResumesDeDerogations = () => ({
  type: CHARGER_RESUMES_DE_DEROGATIONS,
});

export const CHARGER_RESUMES_DE_DEROGATIONS_SUCCES =
  "CHARGER_RESUMES_DE_DEROGATIONS_SUCCES";
export const chargerResumesDeDerogations_succes = (derogations) => ({
  type: CHARGER_RESUMES_DE_DEROGATIONS_SUCCES,
  derogations,
});

export const CHARGER_DEROGATIONS_DE_VR_PAR_PROJET =
  "CHARGER_DEROGATIONS_DE_VR_PAR_PROJET";
export const chargerDerogationsDeVrParProjet = (idProjet) => ({
  type: CHARGER_DEROGATIONS_DE_VR_PAR_PROJET,
  idProjet,
});

export const REDIRIGER_VERS_LES_DEROGATIONS_DU_PROJET =
  "REDIRIGER_VERS_LES_DEROGATIONS_DU_PROJET";
export const redirigerVersLesDerogationsDuProjet = (source, routeurDeApp) => ({
  type: REDIRIGER_VERS_LES_DEROGATIONS_DU_PROJET,
  source,
  routeurDeApp,
});

export const CHARGER_DEROGATEURS_DE_TEG_SUCCES =
  "CHARGER_DEROGATEURS_DE_TEG_SUCCES";
export const chargerDerogateursDeTeg_succes = (derogateursDeTeg) => ({
  type: CHARGER_DEROGATEURS_DE_TEG_SUCCES,
  derogateursDeTeg,
});

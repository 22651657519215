import { connect } from "react-redux";
import { selectMarquesItl } from "../../domaine/reducer";
import { choixDuProjet, modeLight } from "../../../../reduxStore/rootReducer";
import { SelectMarqueItlActive } from "./SelectMarqueItlActive";
import {
  selectMarcheChoisi,
  selectMarqueItlChoisie,
} from "../../../Calculateur/domaine/simulation/projetDeTravail/reducer";

const mapStateToProps = (state) => ({
  marquesItl: selectMarquesItl(modeLight(state)),
  marcheChoisi: selectMarcheChoisi(choixDuProjet(state)),
  marqueItlChoisie: selectMarqueItlChoisie(choixDuProjet(state)),
});

export default connect(mapStateToProps)(SelectMarqueItlActive);

import React, { Component } from "react";
import { crmDelete, crmGetExact } from "../Connecteurs/Crm/webApi";
import { notificationDeVrAdapterCrm } from "../App/Calculateur/domaine/simulation/derogations/vr/notificationDeVr.adapter.crm";

class Admin extends Component {
  state = {
    suppressionCrm: "",
    fichesDeTransmission: [],
  };

  componentDidMount() {
    notificationDeVrAdapterCrm
      .getDerniereSansAvis({
        idProjet: "20eebf4c-ee1f-ea11-a810-000d3a65469c",
      })
      .then(console.log);
  }

  chargerLesFT() {
    crmGetExact("itl_fichetransmissions?$top=5")
      .then(fichesCompletes =>
        fichesCompletes.map(({ itl_name, itl_fichetransmissionid }) => ({
          itl_name,
          itl_fichetransmissionid,
        }))
      )
      .then(fichesResumees =>
        this.setState({ fichesDeTransmission: fichesResumees })
      );
  }

  render() {
    const { suppressionCrm } = this.state;
    const { fichesDeTransmission } = this.state;
    return (
      <div style={{ marginLeft: "20px" }}>
        <h2>Admin</h2>

        <h4>Suppression dans le CRM</h4>
        <p>
          Exemple : <em>itl_projets(&lt;id_projet&gt;)</em>
        </p>
        <input
          type="text"
          value={suppressionCrm}
          onChange={event =>
            this.setState({ suppressionCrm: event.target.value })
          }
          style={{ width: "500px" }}
        />
        <button
          onClick={() =>
            crmDelete(suppressionCrm).then(() => this.chargerLesFT())
          }
        >
          Supprimer
        </button>

        <h4>Fiches de Transmission</h4>
        <table>
          <tbody>
            {fichesDeTransmission.map(
              ({ itl_name, itl_fichetransmissionid }) => (
                <tr key={itl_fichetransmissionid}>
                  <td>{itl_name}</td>
                  <td>{itl_fichetransmissionid}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Admin;

import { templating } from "../../../../../../i18n/templating";
import { NOTIFICATION_DEROGATION_DE_TEG_ES } from "./templates/NOTIFICATION_DEROGATION_DE_TEG_es";
import {
  selectMarcheUtilisateur,
  selectNomPrenomUtilisateur,
} from "../../../simulation/profilUtilisateur/reducer";
import { enEuros, pourcentage } from "../../../../../../Kit/formatage";
import {
  tauxDeMargeEffectif,
  valeurDuMontantAFinancer,
} from "../../../simulation/montantAFinancer/montants";
import { ht } from "../../../../../../i18n/raccourcis";
import { UrlsForce } from "../../../../../../routing/Urls";
import { selectProjetDeTravail } from "../../../simulation/projetDeTravail/reducer";
import { nomDeLaFt } from "../../reducer";
import { selectMotivation } from "../../../motivationsDesAnomalies/reducer";
import { selectAnomalieDeTeg } from "../../../anomalies/Anomalies";
import { i18n } from "../../../../../../i18n/i18n";

const { SUJET, CORPS } = NOTIFICATION_DEROGATION_DE_TEG_ES;

export function notificationDerogationDeTeg(stateCalculateur, idDerogation) {
  return {
    sujet: getSujet(stateCalculateur),
    corps: getCorps(stateCalculateur, idDerogation),
  };
}

function getSujet(stateCalculateur) {
  return templating(SUJET, {
    demandeur: selectNomPrenomUtilisateur(
      stateCalculateur.simulation.profilUtilisateur
    ),
  });
}

function getCorps(stateCalculateur, idDerogation) {
  const {
    simulation,
    planDeFinancement,
    anomalies,
    motivationsDesAnomalies,
  } = stateCalculateur;
  const projet = selectProjetDeTravail(simulation.projetDeTravail);
  const intl = i18n.getIntl(
    selectMarcheUtilisateur(simulation.profilUtilisateur)
  );
  const anomalieDeTeg = selectAnomalieDeTeg(anomalies);

  return templating(CORPS, {
    demandeur: selectNomPrenomUtilisateur(simulation.profilUtilisateur),
    projet: projet.etiquette,
    client: projet.client.nom,
    ft: nomDeLaFt(simulation, planDeFinancement),
    montantAFinancer: enEuros(
      valeurDuMontantAFinancer(simulation.montantAFinancer).toObject(),
      ht(intl)
    ),
    vr: enEuros(planDeFinancement.montantTotalDeVr, ht(intl)),
    marge: pourcentage(tauxDeMargeEffectif(simulation.montantAFinancer)),
    tegDemande: pourcentage(anomalieDeTeg.details.tegManuel),
    tegGrille: pourcentage(anomalieDeTeg.details.tegGrille),
    motivation: selectMotivation(motivationsDesAnomalies, anomalieDeTeg)
      .motivation,
    urlOuvrirDerogation: UrlsForce.derogation(idDerogation).ouvrir,
  });
}

export const PRENDRE_INSTANTANE = "PRENDRE_INSTANTANE";
export const prendreInstantane = (idInstantane, timestampCreation) => ({
  type: PRENDRE_INSTANTANE,
  idInstantane,
  timestampCreation,
});

export const RESTAURER_INSTANTANE = "RESTAURER_INSTANTANE";
export const restaurerInstantane = (idInstantane, timestampRestauration) => ({
  type: RESTAURER_INSTANTANE,
  idInstantane,
  timestampRestauration,
});

export const SUPPRIMER_INSTANTANE = "SUPPRIMER_INSTANTANE";
export const supprimerInstantane = (idInstantane) => ({
  type: SUPPRIMER_INSTANTANE,
  idInstantane,
});

export const CHARGER_INSTANTANES = "CHARGER_INSTANTANES";
export const chargerInstantanes = () => ({
  type: CHARGER_INSTANTANES,
});
export const CHARGER_INSTANTANES_SUCCES = `${CHARGER_INSTANTANES}_SUCCES`;
export const chargerInstantanes_succes = (instantanes) => ({
  type: CHARGER_INSTANTANES_SUCCES,
  instantanes,
});

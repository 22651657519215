import { httpServiceCrm } from "../../../../../../../Connecteurs/Crm/httpServiceCrm";
import { formatyyyyMMdd } from "../../../../../../../utils/dateUtils";
import { crmCreerNote } from "../../../../../../../Connecteurs/Crm/creerNote";

export const materielsAdapterCrm = {
  async creerDepense(
    grappeDepense,
    idFt,
    idProjet,
    timestampCreation,
    { http = httpServiceCrm, creerNote = crmCreerNote } = {}
  ) {
    const depenseCreee = await http.post(
      "itl_depensefts?$select=itl_depenseftid",
      {
        "itl_FicheTransmissionId@odata.bind": `/itl_fichetransmissions(${idFt})`,
        ...grappeDepense.depense,
      }
    );

    if (grappeDepense.derogationDeVr) {
      const { itl_depenseftid } = depenseCreee;
      const { derogationDeVr } = grappeDepense;
      const { itl_derogationid } = await http.post("itl_derogations", {
        "itl_projetconcerne@odata.bind": `/itl_projets(${idProjet})`,
        "itl_FTconcernee@odata.bind": `/itl_fichetransmissions(${idFt})`,
        "itl_depenseftid@odata.bind": `/itl_depensefts(${itl_depenseftid})`,
        itl_datedemande: formatyyyyMMdd(timestampCreation),
        itl_datedecision: derogationDeVr.depuisUneDerogationAnterieure
          ? formatyyyyMMdd(timestampCreation)
          : null,
        ...derogationDeVr.derogationMappee,
      });

      const creerLesPj = derogationDeVr.piecesJointes.map(
        ({ body, mimeType, nomDuFichier }) =>
          creerNote({
            titre: nomDuFichier,
            fichierJoint: { nom: nomDuFichier, type: mimeType, base64: body },
            regarding: {
              type: "itl_derogation",
              cible: `/itl_derogations(${itl_derogationid})`,
            },
          })
      );
      await Promise.all(creerLesPj);
    }

    return depenseCreee;
  },
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { Carte } from "../../../../../../Kit/Carte/Carte";
import { Obligatoire } from "../../../../../../Kit/Formulaires/Obligatoire";
import InputMontant from "../../../../../../Kit/Formulaires/InputMontant";
import Euros from "../../../../../../Kit/Finance/Euros";
import InputEntier from "../../../../../../Kit/Formulaires/InputEntier";
import { Description } from "../Kit/Description";
import { $obj, forInput, MoneyPropType } from "../../../../../../Money/money";

const UnRachatDeMateriel = ({
  rachat,
  onChange,
  onChangeQuiRecalcule,
  onDelete,
  longueurMaxDescription,
  estEnModeLight,
}) => {
  const intl = useIntl();
  const [annee, setAnnee] = useState(rachat.annee);

  return (
    <Carte
      titre={intl.formatMessage({
        id: "calculateur.produits.racheter_un_materiel",
      })}
    >
      <div className="flex-dir-col flex-content-sb flex-1">
        <div>
          <div className="ligne-de-formulaire">
            <label>
              <Obligatoire className="mr-5" />
              <FormattedMessage id="calculateur.rachat_de_materiel.montant_du_rachat" />
            </label>
            <div className="ml-20">
              <InputMontant
                value={forInput(rachat.montantDuRachat)}
                onBlur={(montantDuRachat) => {
                  onChangeQuiRecalcule({
                    ...rachat,
                    montantDuRachat: $obj(montantDuRachat),
                  });
                }}
                append={<Euros />}
                className="input-s"
              />
            </div>
          </div>
          <div className="ligne-de-formulaire">
            <label>
              <Obligatoire className="mr-5" />
              <FormattedMessage id="calculateur.rachat_de_materiel.montant_de_la_revente" />
            </label>
            <div className="ml-20">
              <InputMontant
                value={forInput(rachat.montantDeLaRevente)}
                onBlur={(montantDeLaRevente) => {
                  onChangeQuiRecalcule({
                    ...rachat,
                    montantDeLaRevente: $obj(montantDeLaRevente),
                  });
                }}
                append={<Euros />}
                className="input-s"
              />
            </div>
          </div>

          {!estEnModeLight && (
            <div className="ligne-de-formulaire">
              <label>
                <Obligatoire className="mr-5" />
                <FormattedMessage id="calculateur.rachat_de_materiel.annee" />
              </label>
              <InputEntier
                value={annee}
                onChange={(value) => setAnnee(value)}
                onBlur={() => onChange({ ...rachat, annee })}
                className="input-s text-align-right"
              />
            </div>
          )}

          {!estEnModeLight && (
            <Description
              valeurInitiale={rachat.description}
              longueurMax={longueurMaxDescription}
              onChange={(description) => onChange({ ...rachat, description })}
              label={
                <FormattedMessage id="calculateur.rachat_de_materiel.description" />
              }
              estObligatoire={true}
            />
          )}
        </div>

        <div className="mha">
          <button
            className="suppr pure-button button-error button-small bouton-supprimer-produit"
            onClick={() => onDelete(rachat)}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ marginRight: "5px" }}
            />
            <FormattedMessage id="general.supprimer" />
          </button>
        </div>
      </div>
    </Carte>
  );
};

export default UnRachatDeMateriel;

export const UnRachatDeMaterielPropTypes = {
  rachat: PropTypes.shape({
    id: PropTypes.string.isRequired,
    montantDuRachat: MoneyPropType,
    montantDeLaRevente: MoneyPropType,
    annee: PropTypes.number,
    description: PropTypes.string,
    timestamp: PropTypes.number.isRequired,
  }),
  longueurMaxDescription: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeQuiRecalcule: PropTypes.func.isRequired,
  estEnModeLight: PropTypes.bool.isRequired,
};

UnRachatDeMateriel.propTypes = {
  ...UnRachatDeMaterielPropTypes,
};

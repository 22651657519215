import React from "react";
import { FormattedMessage } from "react-intl";
import { TYPES_INVALIDITES_DEROGATION_DE_VR } from "../../../../../domaine/simulation/derogations/vr/invalidites/Invalidites";
import { enEuros, pourcentage } from "../../../../../../../Kit/formatage";

const {
  TYPE_DE_FINANCEMENT_MODIFIE,
  DUREE_DE_FINANCEMENT_SUPERIEURE_A_CELLE_DE_LA_DEROGATION,
  TROP_FORTE_HAUSSE_DU_MONTANT_DE_MATERIEL,
  TAUX_MANUEL_DEPASSE_LE_TAUX_ACCORDE,
} = TYPES_INVALIDITES_DEROGATION_DE_VR;

export function MessageInvalidite({ invalidite }) {
  return (
    <span className="tag button-warning">
      {MESSAGES_DES_INVALIDITES[invalidite.idType](invalidite)}
    </span>
  );
}

const MESSAGES_DES_INVALIDITES = {
  [TYPE_DE_FINANCEMENT_MODIFIE]: () => message("type_de_financement_modifie"),
  [DUREE_DE_FINANCEMENT_SUPERIEURE_A_CELLE_DE_LA_DEROGATION]: () =>
    message("duree_de_financement_augmentee"),
  [TAUX_MANUEL_DEPASSE_LE_TAUX_ACCORDE]: ({ details }) =>
    message("taux_manuel_depasse_le_taux_accorde", {
      pourcentage: pourcentage(details.tauxMax),
    }),
  [TROP_FORTE_HAUSSE_DU_MONTANT_DE_MATERIEL]: ({ details }) =>
    message("trop_forte_hausse_du_montant_du_materiel", {
      montantActuel: enEuros(details.montantActuel),
      montantLimite: enEuros(details.montantLimite),
    }),
};

function message(cle, values) {
  return (
    <FormattedMessage
      id={"calculateur.un_produit.derogation_de_vr.invalidites." + cle}
      values={values}
    />
  );
}

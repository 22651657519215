import React from "react";
import PropTypes from "prop-types";
import UnRachatDeContrat from "./Types/Rachat/UnRachatDeContrat.container";
import { UnRachatDeContratPropTypes } from "./Types/Rachat/UnRachatDeContrat";
import UnProduitAchete from "./Types/ProduitAchete/UnProduitAchete.container";
import { UnProduitAchetePropTypes } from "./Types/ProduitAchete/UnProduitAchete";
import { UnRachatDeMaterielPropTypes } from "./Types/Rachat/UnRachatDeMateriel";
import UnRachatDeMateriel from "./Types/Rachat/UnRachatDeMateriel.container";

export const TYPES_DE_PRODUITS = {
  PRODUIT_ACHETE: "PRODUIT_ACHETE",
  RACHAT_DE_CONTRAT: "RACHAT_DE_CONTRAT",
  RACHAT_DE_MATERIEL: "RACHAT_DE_MATERIEL",
};
const {
  RACHAT_DE_MATERIEL,
  RACHAT_DE_CONTRAT,
  PRODUIT_ACHETE,
} = TYPES_DE_PRODUITS;

function UnProduit(props) {
  const { produit } = props;

  const detailsParType = {
    [PRODUIT_ACHETE]: (p) => <UnProduitAchete produit={p} />,
    [RACHAT_DE_CONTRAT]: (r) => <UnRachatDeContrat rachat={r} />,
    [RACHAT_DE_MATERIEL]: (r) => <UnRachatDeMateriel rachat={r} />,
  };

  return (
    <div className="un-produit">{detailsParType[produit.type](produit)}</div>
  );
}

UnProduit.propTypes = {
  produit: PropTypes.oneOfType([
    UnProduitAchetePropTypes.produit,
    UnRachatDeMaterielPropTypes.rachat,
    UnRachatDeContratPropTypes.rachat,
  ]),
};

export default UnProduit;

import { MARCHES } from "../identiteUtilisateur/reducer";

import messagesFr from "./fr";
import messagesEs from "./es";
import { createIntl, createIntlCache } from "react-intl";

const { ESPAGNE, FRANCE } = MARCHES;

const i18nParMarche = {
  [FRANCE]: { locale: "fr", messages: messagesFr },
  [ESPAGNE]: { locale: "es", messages: messagesEs },
};
const MARCHE_PAR_DEFAUT = FRANCE;

const cache = createIntlCache();

export const i18n = {
  init() {},

  geti18n(marcheUtilisateur) {
    return i18nParMarche[marcheUtilisateur || MARCHE_PAR_DEFAUT];
  },

  getIntl(marcheUtilisateur) {
    // https://github.com/formatjs/react-intl/blob/master/docs/API.md#createintl
    return createIntl(this.geti18n(marcheUtilisateur), cache);
  },
};

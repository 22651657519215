import React, { useRef } from "react";
import "./Sticky.css";
import useResizeObserver from "@react-hook/resize-observer";

export function Sticky({ children, selecteurDeLaCible }) {
  // Ce composant utilise useResizeObserver pour modifier le
  // padding-top de { selecteurDeLaCible } dès que { children } change de hauteur.

  const leDivSticky = useRef(null);

  useResizeObserver(leDivSticky, (resized) => {
    ajusterLePaddingTop(selecteurDeLaCible, resized.contentRect.height + 10);
  });

  return (
    <div ref={leDivSticky} className="sticky">
      {children}
    </div>
  );
}

function ajusterLePaddingTop(selecteurDeLaCible, paddingTop) {
  const cible = document.querySelector(selecteurDeLaCible);
  cible.style.paddingTop = `${paddingTop}px`;
}

import motivationsDesAnomaliesReducer from "./motivationsDesAnomalies/reducer";
import { simulationReducer } from "./simulation/reducer";
import { avertissements } from "./avertissements/avertissements";
import { controlerLeCalculateur } from "./anomalies/controlerLeCalculateur";
import { planDeFinancementReducer } from "./planDeFinancement/reducer";
import { reducerQuiPeutReset } from "../../../reduxStore/enhancers/reducerQuiPeutReset/reducerQuiPeutReset";
import { viderCalculateur } from "./actions";

export default function calculateur(state = STATE_INITIAL, action) {
  const nextSimulation = simulationReducer(state.simulation, action);
  const nextPlanDeFinancement = planDeFinancementReducerQuiReset(
    state.planDeFinancement,
    action
  );

  return {
    simulation: nextSimulation,
    planDeFinancement: nextPlanDeFinancement,
    anomalies: controlerLeCalculateur(nextSimulation, nextPlanDeFinancement),
    motivationsDesAnomalies: motivationDesAnomaliesReducerQuiReset(
      state.motivationsDesAnomalies,
      action
    ),
    avertissements: avertissements(nextSimulation, nextPlanDeFinancement),
  };
}

const STATE_INITIAL = {
  simulation: simulationReducer(undefined, { type: null }),
  planDeFinancement: planDeFinancementReducer(undefined, { type: null }),
};

const planDeFinancementReducerQuiReset = resetQuandVideLeCalculateur(
  planDeFinancementReducer
);

const motivationDesAnomaliesReducerQuiReset = resetQuandVideLeCalculateur(
  motivationsDesAnomaliesReducer
);

function resetQuandVideLeCalculateur(reducer) {
  return reducerQuiPeutReset(reducer, viderCalculateur());
}

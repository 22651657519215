const CORPS = `Hola,<br />
A título informativo (ninguna acción es necesaria).<br/>
Acabas de recibir una nueva solicitud de <b>derogación de margen</b>.<br />
<ul>
  <li><b>Vendedor : </b>{demandeur}</li>
  <li><b>Proyecto : </b>{projet}</li>
  <li><b>Cliente : </b>{client}</li>
  <li><b>FT : </b>{ft}</li>
  <li><b>Responsable : </b>{derogateur}</li>
  <li><b>Importe total de la operación : </b>{montantAFinancer}</li>
  <li><b>VR : </b>{vr}</li>
  <li><b>Margen solicitado (%) : </b>{tauxDeMargeDemande}</li>
  <li><b>Margen minimo disponible : </b>{tauxDeMargeEnAutonomie}</li>
  <li><b>Explicación de la solicitud : </b>{motivation}</li>
</ul>`;

const SUJET =
  "[INFO - Ficha de transmisión] Nueva solicitud de excepción de Margen - {demandeur}";

export const INFO_DEROGATION_DE_MARGE_ES = { CORPS, SUJET };

import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { pourcentage } from "../../../../Kit/formatage";
import { STATUT_DEROGATION } from "../../../Derogations/domaine/reducer";

const { A_DECIDER, ACCEPTEE, REFUSEE } = STATUT_DEROGATION;

export function BadgeDerogationDeTeg({ anomalieDeTeg, derogationDeTeg }) {
  if (!anomalieDeTeg) return <Fragment />;

  return (
    <div className="derogation-teg">
      <span className="tag" data-testid="badge-derogation-de-teg-demande">
        <FormattedMessage
          id="calculateur.derogation_de_teg.demande"
          values={{
            pourcentage: pourcentage(anomalieDeTeg.details.tegManuel),
          }}
        />
      </span>

      {!derogationDeTeg && <BadgeDecision statut={A_DECIDER} />}
      {derogationDeTeg && (
        <BadgeDecision
          statut={derogationDeTeg.statut}
          tauxDemande={derogationDeTeg.valeurDemandee}
        />
      )}
    </div>
  );
}

function BadgeDecision({ statut, tauxDemande }) {
  const classParStatut = {
    [A_DECIDER]: "tag button-secondary",
    [ACCEPTEE]: "tag button-success",
    [REFUSEE]: "tag button-error",
  };

  const MessageParStatut = {
    [A_DECIDER]: () => (
      <FormattedMessage id="calculateur.derogation_de_teg.en_attente" />
    ),
    [ACCEPTEE]: () => (
      <FormattedMessage
        id="calculateur.derogation_de_teg.acceptee"
        values={{ pourcentage: pourcentage(tauxDemande) }}
      />
    ),
    [REFUSEE]: () => (
      <FormattedMessage
        id="calculateur.derogation_de_teg.refusee"
        values={{ pourcentage: pourcentage(tauxDemande) }}
      />
    ),
  };

  return (
    <span
      className={classParStatut[statut]}
      data-testid="badge-derogation-de-teg-decision"
    >
      {MessageParStatut[statut]()}
    </span>
  );
}

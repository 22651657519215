import React from "react";
import { FormattedMessage } from "react-intl";
import "./TEG.css";
import { SaisieDeTeg } from "./SaisieDeTeg";
import { BadgeDerogationDeTeg } from "./BadgeDerogationDeTeg";
import {
  MARCHES,
  POSTE_UTILISATEUR,
} from "../../../../identiteUtilisateur/reducer";

const { FRANCE } = MARCHES;
const { COMMERCIAL } = POSTE_UTILISATEUR;

export function TEG({
  tegManuel,
  tegGrille,
  onTegChange,
  onRevenirAGrille,
  marcheUtilisateur,
  anomalieDeTeg,
  derogationDeTeg,
  estEnModeLight,
  retourGrilleEstPermis,
  posteUtilisateur,
}) {
  return (
    <div className="teg">
      <h3>
        <FormattedMessage id="calculateur.teg" />
      </h3>

      <SaisieDeTeg
        tegManuel={tegManuel}
        tegGrille={tegGrille}
        onBlur={onTegChange}
        onRevenirAGrille={onRevenirAGrille}
        afficherEnRouge={anomalieDeTeg !== undefined}
        retourGrilleEstPermis={retourGrilleEstPermis}
        saisieInterdite={
          marcheUtilisateur === FRANCE && posteUtilisateur === COMMERCIAL
        }
      />

      {!estEnModeLight && (
        <BadgeDerogationDeTeg
          anomalieDeTeg={anomalieDeTeg}
          derogationDeTeg={derogationDeTeg}
        />
      )}
    </div>
  );
}

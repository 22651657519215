import { connect } from "react-redux";
import ControleDeProjetUtilisable from "./ControleDeProjetUtilisable";
import {
  CHARGER_PROJET_DE_TRAVAIL,
  chargerProjetDeTravail,
} from "../../domaine/simulation/projetDeTravail/actions";
import {
  erreurDeOperation,
  uneOperationEstEnCours,
} from "../../../../Ui/reducer";
import { choixDuProjet } from "../../../../reduxStore/rootReducer";

const mapStateToProps = (state) => ({
  erreurs: erreurDeOperation(state.ui, CHARGER_PROJET_DE_TRAVAIL),

  erreursDuProjet: getErreursDuProjet(state),

  chargementEnCours:
    uneOperationEstEnCours(state.ui, CHARGER_PROJET_DE_TRAVAIL) ||
    (choixDuProjet(state).projetDeTravail === null &&
      erreurDeOperation(state.ui, CHARGER_PROJET_DE_TRAVAIL).length === 0),
});

const getErreursDuProjet = (state) =>
  choixDuProjet(state).projetDeTravail
    ? choixDuProjet(state).projetDeTravail.erreurs
    : [];

const mapDispatchToProps = (dispatch, ownProps) => ({
  initialisation: () => dispatch(chargerProjetDeTravail(ownProps.idProjet)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControleDeProjetUtilisable);

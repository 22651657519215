import {
  CHANGER_DELAI_DE_REGLEMENT,
  CHANGER_MODE_DE_REGLEMENT,
} from "./actions";

export const DELAIS_DE_REGLEMENT = {
  A_RECEPTION: "A_RECEPTION",
  TRENTE_JOURS: "TRENTE_JOURS",
  CINQUANTE_JOURS: "CINQUANTE_JOURS",
  SOIXANTE_JOURS: "SOIXANTE_JOURS",
  QUATREVINGTDIX_JOURS: "QUATREVINGTDIX_JOURS",
};
export const MODES_DE_REGLEMENT = {
  PRELEVEMENT_AUTOMATIQUE: "PRELEVEMENT_AUTOMATIQUE",
  CHEQUE: "CHEQUE",
  VIREMENT: "VIREMENT",
  MANDAT: "MANDAT",
};

const { A_RECEPTION } = DELAIS_DE_REGLEMENT;
const { PRELEVEMENT_AUTOMATIQUE } = MODES_DE_REGLEMENT;

const STATE_INITIAL = {
  delaiDeReglement: { choisi: A_RECEPTION },
  modeDeReglement: { choisi: PRELEVEMENT_AUTOMATIQUE },
};

function reducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CHANGER_DELAI_DE_REGLEMENT:
      return { ...state, delaiDeReglement: { choisi: action.delai } };

    case CHANGER_MODE_DE_REGLEMENT:
      return { ...state, modeDeReglement: { choisi: action.mode } };

    default:
      return state;
  }
}
export default reducer;

export const selectDelaiDeReglement = (state) => state.delaiDeReglement.choisi;

export const selectModeDeReglement = (state) => state.modeDeReglement.choisi;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faCamera } from "@fortawesome/free-solid-svg-icons";
import "./Instantanes.css";
import { enEuros, pourcentage } from "../../../../Kit/formatage";
import { ht } from "../../../../i18n/raccourcis";
import { MODE_AFFICHAGE } from "../../../../Ui/reducer";

const { LIGHT } = MODE_AFFICHAGE;

const Instantanes = ({
  instantanes,
  onClick,
  onCreate,
  onDelete,
  className = "",
}) => {
  const intl = useIntl();
  return (
    <div className={`instantanes ${className}`}>
      <div className={"instantanes-header"}>
        <h3>
          <FormattedMessage id="calculateur.instantanes.titre" />
        </h3>
        <FontAwesomeIcon
          icon={faCamera}
          size="2x"
          onClick={onCreate}
          className="prendre-instantane"
        />
      </div>

      <div className="instantanes-liste">
        {instantanes.map((instantane) => (
          <UnInstantane
            key={instantane.id}
            instantane={instantane}
            onClick={() => {
              if (!instantane.blocage) onClick(instantane.id);
            }}
            onDelete={() => onDelete(instantane.id)}
            intl={intl}
          />
        ))}
      </div>
    </div>
  );
};

const jour = (timestamp) => new Date(timestamp).toLocaleDateString();
const heure = (timestamp) => new Date(timestamp).toLocaleTimeString();

function UnInstantane({ instantane, intl, onClick, onDelete }) {
  return (
    <div className="un-instantane">
      {instantane.erreur ? (
        <div>{instantane.erreur}</div>
      ) : (
        <div onClick={onClick}>
          <div className="details">
            {instantane.blocage && (
              <div className="overlay-blocage">
                <FormattedMessage id={instantane.blocage} />
              </div>
            )}

            <div className="flex-content-sb">
              <div className="text-bold">{instantane.titre}</div>
              {instantane.modeAffichage === LIGHT && (
                <FontAwesomeIcon icon={faBolt} />
              )}
            </div>
            <div>{instantane.typeDeFinancement}</div>
            <div>
              {instantane.paliers.map(({ duree, loyerFullService }, index) => (
                <p key={index}>
                  {`${duree} x ${enEuros(loyerFullService, ht(intl))}`}
                </p>
              ))}
            </div>
            <FormattedMessage
              id="calculateur.instantanes.marge"
              values={{
                taux: pourcentage(instantane.infosMarge.tauxEffectif),
                montant: enEuros(instantane.infosMarge.montantEffectif, ""),
              }}
            />
            <br />
            {instantane.produits.map((produit, index) => (
              <div key={index}>{produit}</div>
            ))}
            <div className="timestamp">
              {`${jour(instantane.timestampCreation)} ${heure(
                instantane.timestampCreation
              )}`}
            </div>
          </div>
        </div>
      )}
      <div className="supprimer-instantane" onClick={onDelete}>
        <FormattedMessage id="general.supprimer" />
      </div>
    </div>
  );
}

Instantanes.propTypes = {
  instantanes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        timestampCreation: PropTypes.number.isRequired,
        paliers: PropTypes.array.isRequired,
        infosMarge: PropTypes.object.isRequired,
        produits: PropTypes.arrayOf(PropTypes.string).isRequired,
        modeAffichage: PropTypes.string.isRequired,
        blocage: PropTypes.string,
      }),
      PropTypes.shape({ erreur: PropTypes.string }),
    ])
  ),
  onClick: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Instantanes;

import { call, select } from "redux-saga/effects";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { selectAnomalieDeTeg } from "../../../anomalies/Anomalies";
import { Adapters } from "../../../../../../reduxStore/adapters";
import { selectProjetDeTravail } from "../../../simulation/projetDeTravail/reducer";
import { anomalieEnDemandeDeDerogation } from "./derogationDeTeg.mapping";
import { notificationDerogationDeTeg } from "./derogationDeTeg.notifications";

export function* creerLaDemandeDeDerogationDeTegSaga(
  ft,
  timestampCreation,
  { derogationDeTegAdapter = Adapters.creerDerogationDeTegAdapter } = {}
) {
  const { anomalies } = yield select(calculateur);
  const anomalieDeTeg = selectAnomalieDeTeg(anomalies);

  if (!anomalieDeTeg) return;

  const idDerogation = yield* creerLaDemandeDeDerogation(
    { anomalieDeTeg, ft, timestampCreation },
    { derogationDeTegAdapter }
  );

  yield* notifierDeLaDemande(idDerogation, { derogationDeTegAdapter });
}

function* creerLaDemandeDeDerogation(
  { anomalieDeTeg, ft, timestampCreation },
  { derogationDeTegAdapter }
) {
  const demandeDeDerog = yield* anomalieEnDemandeDeDerogation(anomalieDeTeg);
  const { simulation } = yield select(calculateur);
  const idDerogation = yield call(
    derogationDeTegAdapter.creerDerogationDeTeg,
    demandeDeDerog,
    ft,
    selectProjetDeTravail(simulation.projetDeTravail),
    timestampCreation
  );
  return idDerogation;
}

function* notifierDeLaDemande(idDerogation, { derogationDeTegAdapter }) {
  const stateCalculateur = yield select(calculateur);
  const notification = yield call(
    notificationDerogationDeTeg,
    stateCalculateur,
    idDerogation
  );
  yield call(
    derogationDeTegAdapter.notifierDeNouvelleDemande,
    notification,
    idDerogation
  );
}

const SUJET = `{emoji} Derogación Force {nomDuProjet} {verdict}`;

const CORPS = `
Buenos días,<br/>
La solicitud de derogación que realizó el {dateDeDemandeFormatee} ha sido {verdict} por {nomDerogateur}.<br/>
Comentario: "{commentaire}".<br/>
Esta solicitud se encuentra relacionada con el cliente {nomDuClient} y el proyecto {nomDuProjet}.<br/><br/>

<a href="{urlPourOuvrirFtEnCrm}">
    Puede acceder a la FT asociada (en la CRM) haciendo clic aquí.</a><br/>
<a href="{urlPourOuvrirProjetDansForce}">
    Puede acceder al proyecto de financiación (en Force) haciendo clic aquí.</a>
`;

export const TEMPLATE_NOTIFICATION_DECISION_ES = { CORPS, SUJET };

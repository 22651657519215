export const CALCULER_EN_LINEAIRE = "CALCULER_EN_LINEAIRE";
export const calculerEnLineaire = (nombreEcheances) => ({
  type: CALCULER_EN_LINEAIRE,
  nombreEcheances,
});

export const CALCULER_EN_MULTI_PALIERS = "CALCULER_EN_MULTI_PALIERS";
export const calculerEnMultiPaliers = (paliers) => ({
  type: CALCULER_EN_MULTI_PALIERS,
  paliers,
});

export const CHANGER_LA_PERIODICITE = "CHANGER_LA_PERIODICITE";
export const changerLaPeriodicite = (periodicite) => ({
  type: CHANGER_LA_PERIODICITE,
  periodicite,
});

export const CHANGER_NATURE_DU_CALCUL = "CHANGER_NATURE_DU_CALCUL";
export const changerNatureDuCalcul = (nouvelleNature) => ({
  type: CHANGER_NATURE_DU_CALCUL,
  nouvelleNature,
});

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";

export function SupprimerDemandeDeVr({ onClick }) {
  return (
    <FontAwesomeIcon
      icon={faTrash}
      className="mr-5 ml-10 pointable"
      onClick={onClick}
      data-testid="supprimer-demande-de-vr"
    />
  );
}

export const EMPLACEMENT_DE_SUPPRESSION = {
  A_COTE_INPUT: "A_COTE_INPUT",
  A_COTE_BADGE_DEROG: "A_COTE_BADGE_DEROG",
};
const { A_COTE_INPUT, A_COTE_BADGE_DEROG } = EMPLACEMENT_DE_SUPPRESSION;

export function emplacementDeLaSuppressionDeVrManuelle({
  tauxGrille,
  tauxEffectif,
  anomalieDeVr,
  estEnModeLight,
}) {
  if (estEnModeLight && tauxEffectif !== tauxGrille) return A_COTE_INPUT;

  if (!estEnModeLight && anomalieDeVr) return A_COTE_BADGE_DEROG;

  if (!estEnModeLight && !anomalieDeVr && tauxEffectif !== tauxGrille)
    return A_COTE_INPUT;
}

import { LABELS_PERIODICITE } from "../simulation/modeDeCalcul/periodicite/periodicite";
import { nombreEcheances } from "../planDeFinancement/paliers";
import { franchiseTroisMoisEstActivee } from "../simulation/franchiseTroisMois/reducer";
import { selectMarcheUtilisateur } from "../simulation/profilUtilisateur/reducer";
import { nomDuTypeDeFinancementActif } from "../simulation/valeurResiduelle/selecteurs/generalites.selecteurs";
import { selectProjetDeTravail } from "../simulation/projetDeTravail/reducer";
import { selectPaliers } from "../planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

export function nomDeLaFt(simulation, planDeFinancement) {
  const periodicite = libellePeriodicite(simulation);
  const duree = nombreEcheances(selectPaliers(planDeFinancement));
  const franchiseTroisMois = libelleFranchiseTroisMois(simulation);
  const typeDeFinancement = libelleTypeDeFinancement(simulation);
  const projetDeTravail = selectProjetDeTravail(simulation.projetDeTravail).nom;

  return [
    franchiseTroisMois,
    projetDeTravail,
    `${typeDeFinancement} ${periodicite}${duree}`,
  ]
    .filter((x) => x !== null)
    .join(" - ");
}

function libellePeriodicite(simulation) {
  const { modeDeCalcul } = simulation;
  return LABELS_PERIODICITE[modeDeCalcul.periodicite].substring(0, 1);
}

function libelleFranchiseTroisMois(simulation) {
  return franchiseTroisMoisEstActivee(simulation.franchiseTroisMois)
    ? "[Opération franchise 3 mois]"
    : null;
}

function libelleTypeDeFinancement(simulation) {
  return nomDuTypeDeFinancementActif(
    simulation.valeurResiduelle,
    selectMarcheUtilisateur(simulation.profilUtilisateur)
  );
}

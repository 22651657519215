import {
  httpK3000,
  pourcentageK3000,
} from "../../../../../../Connecteurs/K3000/httpK3000";
import { tauxDeMargeEffectif } from "../../../simulation/montantAFinancer/montants";
import { $obj, Money } from "../../../../../../Money/money";
import {
  LABELS_PERIODICITE_K3000,
  LABELS_TERME_K3000,
} from "../../../../../../Connecteurs/K3000/labelsK3000";
import {
  selectMarcheChoisi,
  selectMarqueItlChoisie,
} from "../../../simulation/projetDeTravail/reducer";
import { selectMarcheUtilisateur } from "../../../simulation/profilUtilisateur/reducer";
import { tauxEffectifDeVr } from "../../../simulation/valeurResiduelle/selecteurs/taux.selecteurs";
import { listeDesProduitsAchetes } from "../../../simulation/montantAFinancer/reducer";
import { getParametresGrilleDeTeg } from "../../../simulation/tauxInteret/teg.adapter.k3000";
import { typeDeFinancementActif } from "../../../simulation/valeurResiduelle/selecteurs/generalites.selecteurs";

export const planDeFinancementInvestissementAdapterK3000 = {
  async calculerPlan(simulation, { http = httpK3000 } = {}) {
    const {
      profilUtilisateur,
      montantAFinancer,
      modeDeCalcul,
      valeurResiduelle,
      terme,
      projetDeTravail,
    } = simulation;

    const marcheUtilisateur = selectMarcheUtilisateur(profilUtilisateur);

    const reponse = await http.post(
      "/api/v1/financier/calcul/montant-investissement-et-marge",
      {
        listeDePaliersDeLoyer: modeDeCalcul.paliers.map(p => ({
          nombreDEcheances: p.duree,
          montant: Money(p.loyer)
            .toUnit()
            .toString(),
        })),
        tauxMarge: tauxDeMargeEffectif(montantAFinancer).toString(),
        pourcentageValeurResiduelle: pourcentageK3000(
          tauxEffectifDeVr(
            valeurResiduelle,
            listeDesProduitsAchetes(montantAFinancer)[0]
          )
        ),
        terme: LABELS_TERME_K3000[terme.choisi],
        typeDePeriodicite: LABELS_PERIODICITE_K3000[modeDeCalcul.periodicite],
        parametresGrilleTeg: getParametresGrilleDeTeg({
          marche: marcheUtilisateur,
          marcheProjet: selectMarcheChoisi(projetDeTravail).id,
          marqueItl: selectMarqueItlChoisie(projetDeTravail).id,
          typeDeFinancement: typeDeFinancementActif(valeurResiduelle),
        }),
      }
    );

    return {
      investissement: { montant: $obj(reponse.montantDInvestissement) },
    };
  },
};

import { ID_MARQUE_GENERIQUE } from "../../catalogueProduits/reducer";
import { TYPE_DE_FINANCEMENT } from "../reducer";
import { MARCHES } from "../../../../../../identiteUtilisateur/reducer";
import { typeDeFinancementActif } from "./generalites.selecteurs";

const { ESPAGNE, FRANCE } = MARCHES;
const { CB } = TYPE_DE_FINANCEMENT;

export function demandeUneVrUnLoyer(state) {
  return state.tauxDemandes.estUneDemandeDeVrUnLoyer;
}

export function vrUnLoyerEstAutoriseePourLeMateriel(state, materiel) {
  const regleParMarche = {
    [FRANCE]: () => {
      const estCreditBail = typeDeFinancementActif(state) === CB;
      const materielNeuf = !materiel.estOccasion;
      const materielAvecMarque = materiel.idMarque !== ID_MARQUE_GENERIQUE;

      const casAutorise = estCreditBail && materielNeuf && materielAvecMarque;

      return casAutorise;
    },
    [ESPAGNE]: () => true,
  };

  return regleParMarche[state.marcheUtilisateur]();
}

export function vrUnLoyerEstActivee(state) {
  return state.vrUnLoyerEstActivee;
}

import CatchLesExceptions from "./CatchLesExceptions";
import { connect } from "react-redux";
import { effacerErreurCatchee } from "./quiCatchLesErreurs";
import { erreurCatchee } from "../../rootReducer";

const mapStateToProps = (state) => ({
  messageErreur: erreurCatchee(state).message,
});

const mapDispatchToProps = (dispatch) => ({
  onRevenirAuStableClick: () => dispatch(effacerErreurCatchee(Date.now())),
});

export default connect(mapStateToProps, mapDispatchToProps)(CatchLesExceptions);

import { call, select } from "redux-saga/effects";
import { AVIS_BROKER_DEROGATION_DE_VR } from "./actions";
import { identiteUtilisateur } from "../../../../../reduxStore/rootReducer";
import { notifierDemandeurDeAvisBrokerSaga } from "./notification.sagas";
import { Adapters } from "../../../../../reduxStore/adapters";
import { trySaga } from "../../../../../utils/sagasUtils";

export function* avisBrokerDerogationVrSaga(
  action,
  { derogationDeVrAdapter = Adapters.derogationDeVrAdapter } = {}
) {
  const saga = function* () {
    const { avisBroker } = action;
    const { userIdCrm } = yield select(identiteUtilisateur);

    yield call(derogationDeVrAdapter.donnerAvisBroker, {
      ...avisBroker,
      idBroker: userIdCrm,
    });

    if (avisBroker.pieceJointe) {
      const pieceJointe = {
        titre: "Pièce-jointe du Broker",
        ...avisBroker.pieceJointe,
      };
      yield call(
        derogationDeVrAdapter.ajouterPieceJointe,
        avisBroker.idDerogation,
        pieceJointe
      );
    }

    yield call(notifierDemandeurDeAvisBrokerSaga, avisBroker);
  };

  yield* trySaga(saga, AVIS_BROKER_DEROGATION_DE_VR);
}

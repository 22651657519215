import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import BadgeWarning from "../../../../../Kit/Badges/Warning/BadgeWarning";
import { enhancedMessage } from "../../../../../i18n/raccourcis";
import UneDemandeDeDerogation from "./UneDemandeDeDerogation.container";

const ResumeDesDemandesDeDerogations = ({
  demandes,
  labelDuBouton,
  tailleDesPiecesJointes,
}) => (
  <div style={{ width: "800px" }}>
    <h2>{texte("titre")}</h2>

    {tailleDesPiecesJointes.depasseLaLimite && (
      <BadgeWarning>
        <FormattedMessage
          id="calculateur.creation_fiche_de_transmission.pieces_jointes_trop_grosses"
          values={{
            taillePj: tailleDesPiecesJointes.tailleEnMo,
            limiteAcceptee: tailleDesPiecesJointes.limite,
          }}
        />
      </BadgeWarning>
    )}

    <p>{texteRiche("explications")}</p>

    <ul>
      {demandes.map((demande, index) => (
        <li key={index}>
          <UneDemandeDeDerogation anomalie={demande} />
        </li>
      ))}
    </ul>

    <p>
      {texteRiche("creation_fiche_de_transmission", {
        labelDuBouton: labelDuBouton,
      })}
    </p>
  </div>
);

ResumeDesDemandesDeDerogations.propTypes = {
  demandes: PropTypes.array.isRequired,
  labelDuBouton: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  tailleDesPiecesJointes: PropTypes.object,
};

function texte(cle) {
  return <FormattedMessage id={`calculateur.demande_de_derogation.${cle}`} />;
}

function texteRiche(cle, values) {
  return enhancedMessage(`calculateur.demande_de_derogation.${cle}`, values);
}

export default ResumeDesDemandesDeDerogations;

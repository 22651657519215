import {
  PAS_DE_DEROGATION,
  selectDerogationDeVrAvecDecision,
} from "../../simulation/derogations/reducer";
import { enrichirAvecInvalidites } from "../../simulation/derogations/vr/invalidites/enrichirAvecInvalidites";

export function affecterDerogationDeVr(anomalie, simulation) {
  const { derogations } = simulation;
  const { details } = anomalie;

  const derogation = enrichirAvecInvalidites(
    selectDerogationDeVrAvecDecision(derogations, details.produit),
    simulation
  );

  return {
    ...anomalie,
    derogation,
    dejaDerogee:
      derogation !== PAS_DE_DEROGATION && derogation.invalidites.length === 0,
  };
}

import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LienVersAccueil } from "../Navigation/LienVersAccueil";
import "./DerogationAssuranceEspagne.css";
import { StatutDerogationSimple } from "../Kit/StatutDerogationSimple";
import Textarea from "../../../../Kit/Formulaires/Textarea";
import { STATUT_DEROGATION } from "../../domaine/reducer";
import {
  useErreurDeOperation,
  useOperationEstEnCours,
} from "../../../../Ui/hooks";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import {
  ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE,
  accepterDerogationAssuranceEspagne,
  REFUSER_DEROGATION_ASSURANCE_ESPAGNE,
  refuserDerogationAssuranceEspagne,
} from "../../domaine/typesDerogations/assuranceEspagne/actions";
import { useDispatch } from "react-redux";
import BadgeErreur from "../../../../Kit/Badges/Erreur/BadgeErreur";
import BadgeWarning from "../../../../Kit/Badges/Warning/BadgeWarning";
import { usePeutDecider } from "../../domaine/decision/hooks";

const { ACCEPTEE, REFUSEE } = STATUT_DEROGATION;

export const DerogationAssuranceEspagne = ({ derogation }) => {
  const isLoading = useOperationEstEnCours([
    ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE,
    REFUSER_DEROGATION_ASSURANCE_ESPAGNE,
  ]);

  const erreurs = useErreurDeOperation([
    ACCEPTER_DEROGATION_ASSURANCE_ESPAGNE,
    REFUSER_DEROGATION_ASSURANCE_ESPAGNE,
  ]);

  const dispatch = useDispatch();

  const peutDecider = usePeutDecider(derogation);

  return (
    <div className="derogation-assurance-espagne">
      <LienVersAccueil />

      <h4>
        <FormattedMessage id="derogation_assurance_espagne.titre" />
      </h4>

      {erreurs.length > 0 && <BadgeErreur messages={erreurs} />}

      <ul>
        {bulletPoint("derogation.nom", derogation.nom)}
        {bulletPoint("derogation.fiche_de_transmission", derogation.ft.nom)}
        {bulletPoint(
          "derogation_assurance_espagne.prime_demandee",
          derogation.valeurDemandee
        )}
        {bulletPoint(
          "derogation_assurance_espagne.prime_minimale",
          derogation.valeurOrigine
        )}
        {bulletPoint("derogation.motivation", derogation.motivation)}
      </ul>

      {isLoading && erreurs.length === 0 ? (
        <BarreDeChargement size="S" />
      ) : (
        <>
          <StatutDerogationSimple derogation={derogation} />

          {!peutDecider ? (
            <BadgeWarning className="decision-non-autorisee">
              <FormattedMessage id="derogation.decision.droits_insuffisants" />
            </BadgeWarning>
          ) : (
            <Decision
              derogation={derogation}
              onAccepter={(commentaire) =>
                dispatch(
                  accepterDerogationAssuranceEspagne(derogation, {
                    statut: ACCEPTEE,
                    dateDecision: Date.now(),
                    commentaireDecideur: commentaire,
                  })
                )
              }
              onRefuser={(commentaire) =>
                dispatch(
                  refuserDerogationAssuranceEspagne(derogation, {
                    statut: REFUSEE,
                    dateDecision: Date.now(),
                    commentaireDecideur: commentaire,
                  })
                )
              }
            />
          )}
        </>
      )}
    </div>
  );
};

function bulletPoint(idLabel, valeur) {
  return (
    <li>
      <b>
        <FormattedMessage id={idLabel} />
      </b>{" "}
      {valeur}
    </li>
  );
}

function Decision({ derogation, onAccepter, onRefuser }) {
  const [commentaire, setCommentaire] = useState(
    derogation.commentaireDecideur || ""
  );
  const intl = useIntl();

  return (
    <div className="prise-decision">
      <Textarea
        value={commentaire}
        onChange={setCommentaire}
        className="w100"
        rows={4}
        placeholder={intl.formatMessage(
          { id: "derogation.decision.commentaire" },
          { auteur: derogation.auteur.nom }
        )}
      />
      <div className="les-boutons">
        <button
          className="pure-button button-large button-success"
          onClick={() => onAccepter(commentaire)}
          disabled={derogation.statut === ACCEPTEE || commentaire === ""}
        >
          <FormattedMessage id="derogation.decision.accepter" />
        </button>

        <button
          className="pure-button button-large button-error"
          onClick={() => onRefuser(commentaire)}
          disabled={derogation.statut === REFUSEE || commentaire === ""}
        >
          <FormattedMessage id="derogation.decision.refuser" />
        </button>
      </div>
    </div>
  );
}

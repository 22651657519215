export const PERIODICITES = {
  MENSUEL: 1,
  TRIMESTRIEL: 3,
  ANNUEL: 12,

  convertir: (nbEcheances, periodiciteSource) => ({
    en: (periodiciteCible) =>
      nbEcheances === null
        ? null
        : periodiciteCible > periodiciteSource
        ? Math.ceil(nbEcheances / (periodiciteCible / periodiciteSource))
        : nbEcheances * (periodiciteSource / periodiciteCible),
  }),
};

const { TRIMESTRIEL, MENSUEL, ANNUEL } = PERIODICITES;

export const LABELS_PERIODICITE = {
  [MENSUEL]: "Mensuel",
  [TRIMESTRIEL]: "Trimestriel",
  [ANNUEL]: "Annuel",
};

export const PERIODICITE_PAR_DEFAUT = MENSUEL;

export const nombreDeMois = (nombreEcheances, periodicite) =>
  nombreEcheances * periodicite;

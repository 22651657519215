import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { chargerProfilUtilisateurSaga } from "./simulation/profilUtilisateur/sagas";
import { chargerCatalogueProduitsCompletSaga } from "./simulation/catalogueProduits/sagas";
import { choixDuProjet } from "../../../reduxStore/rootReducer";
import {
  INITIALISER_CALCULATEUR,
  INITIALISER_CALCULATEUR_DEPUIS_FT,
  INITIALISER_CALCULATEUR_DEPUIS_INSTANTANE,
  INITIALISER_CALCULATEUR_VIDE,
  viderCalculateur,
} from "./actions";
import { importerFtSaga } from "./importDeFt/sagas/import.sagas";
import { chargerDerogationsDeMargeDuProjetSaga } from "./simulation/derogations/sagas";
import { initialiserDerogateursDeMargeSaga } from "./simulation/derogateurs/marge/marge.sagas";
import { calculerEnLineaire } from "./simulation/modeDeCalcul/actions";
import { chargerMargeCibleSaga } from "./simulation/margeCible/sagas";
import { chargerProjetDeTravail_succes } from "./simulation/projetDeTravail/actions";
import { trySaga } from "../../../utils/sagasUtils";
import { restaurerInstantane } from "../../../reduxStore/enhancers/instantanes/actions";
import { orchestrerLePlanDeFinancement } from "./planDeFinancement/actions";
import { MODE_AFFICHAGE } from "../../../Ui/reducer";
import { definirModeAffichage } from "../../../Ui/actions";
import { projetDeTravailEstFictif } from "../../ModeLight/domaine/contenuFictifModeLight";
import { chargerPrimeMinimaleAssuranceEspagneSaga } from "./simulation/assurance/espagne/sagas";
import { initialiserDerogateurAssuranceEspagneSaga } from "./simulation/derogateurs/assuranceEspagne/sagas";

const { LIGHT, CLASSIQUE } = MODE_AFFICHAGE;

export function* initialiserCalculateurVideSaga() {
  const saga = function*() {
    yield put(viderCalculateur());
    yield* initialiserContexteDuCalculateur();
    const cinqAns = 60;
    yield put(calculerEnLineaire(cinqAns));
  };

  yield* trySaga(saga, INITIALISER_CALCULATEUR);
}

export function* initialiserCalculateurDepuisFtSaga(action) {
  const saga = function*() {
    yield call(initialiserContexteDuCalculateur);
    yield call(
      importerFtSaga,
      action.idFicheTransmission,
      action.timestampImport
    );
  };

  yield* trySaga(saga, INITIALISER_CALCULATEUR);
}

export function* initialiserCalculateurDepuisInstantaneSaga(action) {
  const saga = function*() {
    const {
      projetDeTravail: projetPreRestauration,
      modeAffichage: modePreRestauration,
    } = yield* getInfosDeRestauration();

    yield put(
      restaurerInstantane(action.idInstantane, action.timestampRestauration)
    );

    const {
      modeAffichage: modePostRestauration,
    } = yield* getInfosDeRestauration();

    // Seulement recharger le projet pré-instantané si le mode a changé
    // pour écraser le projet contenu *dans* l'instantané
    if (modePreRestauration !== modePostRestauration) {
      yield put(chargerProjetDeTravail_succes(projetPreRestauration));
      yield put(definirModeAffichage(modePreRestauration));
    }

    const inutileDeRechargerLeContexte =
      modePreRestauration === modePostRestauration ||
      modePreRestauration === LIGHT;
    if (inutileDeRechargerLeContexte) return;

    yield call(initialiserContexteDuCalculateur);
    yield put(orchestrerLePlanDeFinancement());
  };

  yield* trySaga(saga, INITIALISER_CALCULATEUR);
}

function* getInfosDeRestauration() {
  const { projetDeTravail } = yield select(choixDuProjet);
  const projetFictif = projetDeTravailEstFictif(projetDeTravail);
  const modeAffichage = projetFictif ? LIGHT : CLASSIQUE;
  return { projetDeTravail, modeAffichage };
}

export function* initialiserContexteDuCalculateur() {
  const { projetDeTravail } = yield select(choixDuProjet);
  yield call(chargerProfilUtilisateurSaga, projetDeTravail);

  yield all([
    call(chargerMargeCibleSaga),
    call(chargerCatalogueProduitsCompletSaga),
    call(chargerDerogationsDeMargeDuProjetSaga),
    call(initialiserDerogateursDeMargeSaga),
    call(initialiserDerogateurAssuranceEspagneSaga),
    call(chargerPrimeMinimaleAssuranceEspagneSaga),
  ]);
}

export default function* sagasDuCalculateur() {
  yield all([
    takeEvery(INITIALISER_CALCULATEUR_VIDE, initialiserCalculateurVideSaga),
    takeEvery(
      INITIALISER_CALCULATEUR_DEPUIS_FT,
      initialiserCalculateurDepuisFtSaga
    ),
    takeEvery(
      INITIALISER_CALCULATEUR_DEPUIS_INSTANTANE,
      initialiserCalculateurDepuisInstantaneSaga
    ),
  ]);
}

import { httpServiceCrm } from "./httpServiceCrm";

export function crmCreerNote(note, { httpService = httpServiceCrm } = {}) {
  return httpService.post("annotations", {
    subject: note.titre,
    filename: note.fichierJoint.nom,
    mimetype: note.fichierJoint.type,
    documentbody: note.fichierJoint.base64,
    [`objectid_${note.regarding.type}@odata.bind`]: note.regarding.cible,
  });
}

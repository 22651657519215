import { call } from "redux-saga/effects";
import { MARCHES } from "../../../../../../identiteUtilisateur/reducer";
import {
  assurerDepenseEspagneSaga,
  assurerFtEspagne,
  assurerLoyerEspagneSaga,
} from "./espagne/assurance.sagas";
import {
  assurerDepenseFranceSaga,
  assurerFtFrance,
  assurerLoyerFranceSaga,
} from "./france/assurance.sagas";
import { getMarcheUtilisateur } from "../../../simulation/profilUtilisateur/sagas";

const { ESPAGNE, FRANCE } = MARCHES;

export function* assurerFtSaga(ft, { intl } = {}) {
  const marcheUtilisateur = yield* getMarcheUtilisateur();
  const sagaParMarche = {
    [ESPAGNE]: function* () {
      yield call(assurerFtEspagne, ft.id, { intl });
    },
    [FRANCE]: function* () {
      yield call(assurerFtFrance, ft.id);
    },
  };

  yield* sagaParMarche[marcheUtilisateur]();
}

export function* assurerDepenseSaga(materiel, depenseCrm) {
  const marcheUtilisateur = yield* getMarcheUtilisateur();

  const sagaParMarche = {
    [FRANCE]: function* () {
      yield call(assurerDepenseFranceSaga, materiel, depenseCrm);
    },
    [ESPAGNE]: function* () {
      yield call(assurerDepenseEspagneSaga, depenseCrm);
    },
  };

  yield* sagaParMarche[marcheUtilisateur]();
}

export function* assurerLoyerSaga(loyerCrm, palierForce) {
  const marcheUtilisateur = yield* getMarcheUtilisateur();

  const sagaParMarche = {
    [FRANCE]: function* () {
      yield call(assurerLoyerFranceSaga, loyerCrm, palierForce);
    },
    [ESPAGNE]: function* () {
      yield call(assurerLoyerEspagneSaga, loyerCrm, palierForce);
    },
  };

  yield* sagaParMarche[marcheUtilisateur]();
}

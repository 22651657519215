export const CHARGER_LES_GRILLES_DE_VR_SUCCES = `CHARGER_LES_GRILLES_DE_VR_SUCCES`;
export const chargerLesGrillesDeVr_succes = (lesVr) => ({
  type: CHARGER_LES_GRILLES_DE_VR_SUCCES,
  lesVr,
});

export const APPLIQUER_LE_TAUX_DE_VR_GRILLE = "APPLIQUER_LE_TAUX_DE_VR_GRILLE";
export const appliquerLeTauxDeVrGrille = (idProduit) => ({
  type: APPLIQUER_LE_TAUX_DE_VR_GRILLE,
  idProduit,
});

export const ACTIVER_LA_VR_UN_LOYER = "ACTIVER_LA_VR_UN_LOYER";
export const activerLaVrUnLoyer = () => ({ type: ACTIVER_LA_VR_UN_LOYER });

export const DESACTIVER_LA_VR_UN_LOYER = "DESACTIVER_LA_VR_UN_LOYER";
export const desactiverLaVrUnLoyer = () => ({
  type: DESACTIVER_LA_VR_UN_LOYER,
});

export const CHANGER_TYPE_DE_FINANCEMENT = "CHANGER_TYPE_DE_FINANCEMENT";
export const changerTypeDeFinancement = (typeDeFinancement) => ({
  type: CHANGER_TYPE_DE_FINANCEMENT,
  typeDeFinancement,
});

export const DEMANDER_TAUX_DE_VR = "DEMANDER_TAUX_DE_VR";
export const demanderTauxDeVr = (
  demandes,
  { estUneDemandeDeVrUnLoyer } = { estUneDemandeDeVrUnLoyer: false }
) => ({
  type: DEMANDER_TAUX_DE_VR,
  demandes,
  estUneDemandeDeVrUnLoyer,
});

export const DONNER_LES_VR_GRILLES = "DONNER_LES_VR_GRILLES";
export const donnerLesVrGrilles = (vrs) => ({
  type: DONNER_LES_VR_GRILLES,
  vrs,
});

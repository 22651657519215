import React, { Fragment } from "react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { $obj, forInput } from "../../../../Money/money";
import "./PlanDeFinancement.css";
import "./PlanDeFinancement-mobile.css";
import { enEuros } from "../../../../Kit/formatage";
import { useIntl } from "react-intl";

configurerChartJs();

function PlanDeFinancement({ paliers }) {
  const intl = useIntl();
  return (
    <div className="plan-de-financement-graphique">
      {paliers.length === 0 ? (
        <Fragment />
      ) : (
        <div className="graphique">
          <Bar
            options={optionsDuGraphique(intl)}
            data={creerHistogrammeCharts(paliers, intl)}
          />
        </div>
      )}
    </div>
  );
}

export default PlanDeFinancement;

function configurerChartJs() {
  // Pris sur https://react-chartjs-2.js.org/examples/stacked-bar-chart
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  ChartJS.defaults.font.family = "Open sans";
  ChartJS.defaults.plugins.legend.position = "right";
  ChartJS.defaults.plugins.legend.reverse = true;
}

function cle(id) {
  return { id: "calculateur.plan_de_financement.graphique." + id };
}

function optionsDuGraphique(intl) {
  return {
    maintainAspectRatio: false,
    scales: {
      x: { stacked: true },
      y: {
        stacked: true,
        ticks: { callback: (value) => enEuros($obj(value), "") },
      },
    },
    interaction: { mode: "x" }, // Pour montrer tous les datasets d'une barre dans la tooltip
    plugins: {
      tooltip: {
        callbacks: {
          title: ([{ label }]) =>
            intl.formatMessage(cle("echeance"), { index: label }),
          label: (context) =>
            `${context.dataset.label} : ${enEuros($obj(context.parsed.y), "")}`,
        },
      },
    },
  };
}

export const creerHistogrammeCharts = (paliers, intl) => {
  const echeances = [];

  let echeanceCourante = 1;
  paliers.forEach(({ duree, loyer, frais, maintenance, assurance }) => {
    for (let i = 1; i <= duree; i++, echeanceCourante++)
      echeances.push({
        x: echeanceCourante,
        loyer: forInput(loyer),
        frais: forInput(frais.montant),
        maintenance: forInput(maintenance.montantParEcheance),
        assurance: forInput(assurance.primeParLoyer),
      });
  });

  return {
    labels: [...Array(echeanceCourante).keys()].splice(1),
    datasets: [
      {
        label: label("financier"),
        data: echeances.map((e) => e.loyer),
        backgroundColor: "rgb(22,178,186)",
      },
      {
        label: label("frais"),
        data: echeances.map((e) => e.frais),
        backgroundColor: "rgb(75,112,192)",
      },
      {
        label: label("maintenance"),
        data: echeances.map((e) => e.maintenance),
        backgroundColor: "rgb(53,162,235)",
      },
      {
        label: label("assurance"),
        data: echeances.map((e) => e.assurance),
        backgroundColor: "rgb(235,199,53)",
      },
    ],
  };

  function label(id) {
    return intl.formatMessage(cle(id));
  }
};

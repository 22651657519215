import TypeDeFinancement from "./TypeDeFinancement";
import { connect } from "react-redux";
import { changerTypeDeFinancement } from "../../domaine/simulation/valeurResiduelle/actions";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { orchestrerLePlanDeFinancement } from "../../domaine/planDeFinancement/actions";
import { selectMarcheUtilisateur } from "../../domaine/simulation/profilUtilisateur/reducer";
import { MARCHES } from "../../../../identiteUtilisateur/reducer";
import { typeDeFinancementActif } from "../../domaine/simulation/valeurResiduelle/selecteurs/generalites.selecteurs";

const { FRANCE } = MARCHES;

const mapStateToProps = (state) => {
  const { simulation } = calculateur(state);

  return {
    featureEstDisponible:
      selectMarcheUtilisateur(simulation.profilUtilisateur) === FRANCE,
    typeActif: typeDeFinancementActif(simulation.valeurResiduelle),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (typeDeFinancement) => {
    dispatch(changerTypeDeFinancement(typeDeFinancement));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TypeDeFinancement);

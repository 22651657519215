import PlanDeFinancement from "./PlanDeFinancement";
import { connect } from "react-redux";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { selectPaliers } from "../../domaine/planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

export const mapStateToProps = (state) => ({
  paliers: selectPaliers(calculateur(state).planDeFinancement),
});

export default connect(mapStateToProps)(PlanDeFinancement);

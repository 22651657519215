import { RECUPERER_LES_MARCHES_ET_BROKERS_SUCCES } from "./actions";

const STATE_INITIAL = {
  idsMarchesEtBrokers: [],
};

export default function reducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case RECUPERER_LES_MARCHES_ET_BROKERS_SUCCES:
      return { ...state, idsMarchesEtBrokers: action.idsMarchesEtBrokers };

    default:
      return state;
  }
}

export const selectMarchesEtBrokers = state => state.idsMarchesEtBrokers;

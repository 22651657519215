import { all, call, select, takeEvery } from "redux-saga/effects";
import { ORCHESTRER_PLAN_DE_FINANCEMENT } from "./actions";
import { trySaga } from "../../../../utils/sagasUtils";
import { orchestrationDuCalculLoyers } from "./naturesDuCalcul/loyers/sagas";
import { calculateur } from "../../../../reduxStore/rootReducer";
import {
  NATURE_DU_CALCUL,
  selectNatureDuCalcul,
} from "../simulation/modeDeCalcul/reducer";
import { orchestrationDuCalculInvestissement } from "./naturesDuCalcul/investissement/sagas";

const { LOYERS, INVESTISSEMENT } = NATURE_DU_CALCUL;

export function* orchestrerLePlanDeFinancementSaga() {
  const saga = function* () {
    const natureActive = yield* getNatureActive();

    const orchestrations = {
      [LOYERS]: orchestrationDuCalculLoyers,
      [INVESTISSEMENT]: orchestrationDuCalculInvestissement,
    };

    yield call(orchestrations[natureActive]);
  };

  yield* trySaga(saga, ORCHESTRER_PLAN_DE_FINANCEMENT);
}

function* getNatureActive() {
  const { simulation } = yield select(calculateur);
  return selectNatureDuCalcul(simulation.modeDeCalcul);
}

export default function* sagasDuPlanDeFinancement() {
  yield all([
    takeEvery(
      ORCHESTRER_PLAN_DE_FINANCEMENT,
      orchestrerLePlanDeFinancementSaga
    ),
  ]);
}

import { odataQuery } from "../../utils/odata/odata";
import { fetchAvecTokenAzure } from "../../authentification/fetch";
import { impersonifieurCrm } from "./impersonifieurCrm";

export const URL_DU_CRM = process.env.REACT_APP_URL_DU_CRM;

export function crmGet(entite, specOdata) {
  const requete = odataQuery(entite, specOdata);
  return fetchCrmEtParseReponse(requete);
}

export function crmGetExact(cible) {
  return fetchCrmEtParseReponse(cible);
}

export function crmPost(cible, body) {
  const postLeBody = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Prefer: "return=representation",
      ...impersonifieurCrm.headerHttp(),
    },
  };
  return fetchCrm(cible, postLeBody).then(texteDeLaReponse =>
    JSON.parse(texteDeLaReponse)
  );
}

export function crmPatch(cible, body) {
  const bodyDuPatch = {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  };
  return fetchCrm(cible, bodyDuPatch);
}

export function crmDelete(cible) {
  const supprimer = { method: "DELETE" };
  return fetchCrm(cible, supprimer);
}

export function fetchCrmPourUnique(requete, descriptionDeLaRecherche) {
  return fetchCrm(requete)
    .then(texteDeLaReponse => JSON.parse(texteDeLaReponse).value)
    .then(valeurs => {
      const valeurUnique = valeurs.length === 1;
      if (valeurUnique) return valeurs[0];

      const { type, recherche, titre } = descriptionDeLaRecherche;

      const message = `${
        titre ? titre + " - " : ""
      }La recherche de '${recherche}' dans [${type}] a ramené ${
        valeurs.length
      } élément(s). Un élément unique était attendu.`;

      throw new Error(message);
    });
}

export function fetchCrmEtParseReponse(requete) {
  return fetchCrm(requete).then(texteDeLaReponse => {
    const reponseJson = JSON.parse(texteDeLaReponse);
    const laReponseAUneValue = reponseJson.value !== undefined;
    if (laReponseAUneValue) return reponseJson.value;
    return reponseJson;
  });
}

const scopeCrm = `${URL_DU_CRM}/user_impersonation`;

const fetchCrm = (requete, options) =>
  fetchAvecTokenAzure(
    `${URL_DU_CRM}/api/data/v9.0/${requete}`,
    scopeCrm,
    { ...options, cache: "no-store" },
    extractErrorMessageCrm
  );

function extractErrorMessageCrm(bodyEnTexte) {
  return JSON.parse(bodyEnTexte).error.message;
}

import { connect } from "react-redux";
import UnRachatDeContrat from "./UnRachatDeContrat";
import {
  racheterContrat,
  supprimerRachatDeContrat,
} from "../../../../domaine/simulation/montantAFinancer/actions";
import { LONGUEUR_MAX_DESCRIPTION_DEPENSE } from "../../../../../../Connecteurs/Crm/limites";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { orchestrerLePlanDeFinancement } from "../../../../domaine/planDeFinancement/actions";
import { selectMaterielsDisponibles } from "../../../../domaine/simulation/catalogueProduits/reducer";
import { estEnMode, MODE_AFFICHAGE } from "../../../../../../Ui/reducer";

const { LIGHT } = MODE_AFFICHAGE;

const mapStateToProps = (state, ownProps) => {
  const { simulation } = calculateur(state);

  return {
    longueurMaxDescription: LONGUEUR_MAX_DESCRIPTION_DEPENSE,
    rachat: ownProps.rachat,
    selectionDesMateriels: {
      materiels: selectMaterielsDisponibles(simulation.catalogueProduits, {
        inclureLeNonReference: false,
      }),
    },
    estEnModeLight: estEnMode(LIGHT, state.ui),
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: rachat => dispatch(racheterContrat(rachat)),
  onChangeQuiRecalcule: rachat => {
    dispatch(racheterContrat(rachat));
    dispatch(orchestrerLePlanDeFinancement());
  },
  onDelete: rachat => {
    dispatch(supprimerRachatDeContrat(rachat.id));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnRachatDeContrat);

import {
  httpK3000,
  montantK3000,
} from "../../../../../../Connecteurs/K3000/httpK3000";
import {
  LABELS_DELAI_DE_REGLEMENT_K3000,
  LABELS_MODE_DE_REGLEMENT_K3000,
} from "../../../../../../Connecteurs/K3000/labelsK3000";
import { $obj } from "../../../../../../Money/money";

export const fraisDeReglementAdapterK3000 = {
  async calculerFraisDeReglement(params, { http = httpK3000 } = {}) {
    const { paliers } = await http.post(
      "/api/v1/financier/loyers-avec-frais/loyers",
      {
        loyerHt: params.loyers.map(l => ({ montant: montantK3000(l) })),
        tva: params.tva.toString(),
        tegAnnuel: params.teg.toString(),
        modeReglement: LABELS_MODE_DE_REGLEMENT_K3000[params.modeDeReglement],
        delaiPaiement: LABELS_DELAI_DE_REGLEMENT_K3000[params.delaiDeReglement],
      }
    );

    return paliers.map(p => $obj(p.montantFraisDeReglement));
  },
};

import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { enEuros } from "../../../../../../../Kit/formatage";
import BadgeDerogationDeVr from "./BadgeDerogationDeVr";
import BarreDeChargement from "../../../../../../../Kit/Loaders/BarreDeChargement";
import {
  EMPLACEMENT_DE_SUPPRESSION,
  emplacementDeLaSuppressionDeVrManuelle,
  SupprimerDemandeDeVr,
} from "./SupprimerDemandeDeVr";

const { A_COTE_INPUT, A_COTE_BADGE_DEROG } = EMPLACEMENT_DE_SUPPRESSION;

export function Vr({
  materiel,
  vr,
  isLoading,
  onDemandeDeVr,
  onAppliquerVrGrille,
  anomalieDeVr,
  derogationDeVr,
  estEnModeLight,
}) {
  if (isLoading) return <BarreDeChargement size="XS" />;

  const emplacementSuppression = emplacementDeLaSuppressionDeVrManuelle({
    tauxGrille: vr.tauxGrille,
    tauxEffectif: vr.tauxEffectif,
    anomalieDeVr,
    estEnModeLight,
  });

  return (
    <div>
      <SaisieDeVr
        key={vr.tauxEffectif}
        tauxEffectif={vr.tauxEffectif}
        tauxGrille={vr.tauxGrille}
        montant={vr.montant}
        onAppliquerVrGrille={() => onAppliquerVrGrille(materiel)}
        onSaisieTerminee={(taux) => onDemandeDeVr(materiel, taux)}
        estEnModeLight={estEnModeLight}
        anomalieDeVr={anomalieDeVr}
        afficherSuppression={emplacementSuppression === A_COTE_INPUT}
        onSupprimerDemandeDeVr={() => onAppliquerVrGrille(materiel)}
      />

      <BadgeDerogationDeVr
        anomalieDeVr={anomalieDeVr}
        derogationDeVr={derogationDeVr}
        afficherSuppression={emplacementSuppression === A_COTE_BADGE_DEROG}
        onSupprimerDemandeDeVr={() => onAppliquerVrGrille(materiel)}
      />
    </div>
  );
}

function SaisieDeVr({
  tauxEffectif,
  montant,
  onAppliquerVrGrille,
  onSaisieTerminee,
  tauxGrille,
  estEnModeLight,
  anomalieDeVr,
  afficherSuppression,
  onSupprimerDemandeDeVr,
}) {
  const vrGrille = enPourcent(tauxGrille);
  const valeurInitiale = enPourcent(tauxEffectif);
  const [vrLocale, setVrLocale] = useState(valeurInitiale);

  const afficherAvertissement = estEnModeLight && anomalieDeVr !== undefined;

  return (
    <Fragment>
      <div className="flex-ai-center">
        <label className="mr-10">
          <FormattedMessage id="calculateur.un_produit.valeur_residuelle" />
        </label>
        <InputVr
          testId="input-vr"
          value={vrLocale}
          className={afficherAvertissement ? "text-red" : ""}
          onChange={(valeurSaisie) => setVrLocale(valeurSaisie)}
          onBlur={() => {
            const aucunChangement = vrLocale === valeurInitiale;
            if (aucunChangement) return;

            const retourGrille =
              String(vrLocale) === "" ||
              String(vrLocale) === String(vrGrille) ||
              Number.isNaN(Number(vrLocale));

            if (retourGrille) onAppliquerVrGrille();
            else onSaisieTerminee(enTaux(vrLocale));
          }}
        />
        <span
          className={"ml-10 " + (afficherAvertissement ? "text-red" : "")}
          data-testid="montant-vr"
        >
          <FormattedMessage
            id="calculateur.un_produit.valeur_residuelle_montant"
            values={{ montant: enEuros(montant, "") }}
          />
        </span>

        {afficherSuppression && (
          <SupprimerDemandeDeVr onClick={onSupprimerDemandeDeVr} />
        )}
      </div>
    </Fragment>
  );
}

export function InputVr({ testId, value, onChange, onBlur, className = "" }) {
  return (
    <div>
      <input
        type="text"
        data-testid={testId}
        value={value}
        onChange={(e) => {
          const { value } = e.target;
          onChange(value.trim().replace(",", "."));
        }}
        onBlur={() => onBlur()}
        className={`input-addon-field border-radius-append-none ${className}`}
        style={{ width: "45px" }}
      />
      <div className="input-addon-append-item">%</div>
    </div>
  );
}

function enPourcent(taux) {
  if (taux === null || taux === undefined) return "";
  return Number((taux * 100).toFixed(2));
}
function enTaux(pourcentage) {
  return pourcentage / 100;
}

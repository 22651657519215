import { httpServiceCrm } from "./httpServiceCrm";
import { odataQuery } from "../../utils/odata/odata";

export const EMAIL_CONFIG = {
  EMAIL_DU_COMPTE_EXPEDITEUR:
    process.env.REACT_APP_EMAIL_DU_COMPTE_UTILISATEUR_EXPEDITEUR_DES_EMAILS,
};

export async function crmSendEmail(email, { http = httpServiceCrm } = {}) {
  const emailCree = await crmCreateEmail(email, { http });
  await crmEnvoieEmail(emailCree, { http });
}

export async function crmCreateEmail(email, { http }) {
  const expediteur = await getCompteExpediteur({ http });
  const bodyDuMail = bodyPourEnvoiDeMail({ ...email, expediteur });

  const emailCree = await http.post("emails?$select=activityid", bodyDuMail);

  const piecesJointes = email.piecesJointes || [];
  await Promise.all(
    piecesJointes
      .map(pj => bodyPieceJointe(pj, emailCree.activityid))
      .map(body => http.post("activitymimeattachments", body))
  );

  return emailCree;
}

async function getCompteExpediteur({ http }) {
  const compteExpediteur = await http.getUnique(
    odataQuery("systemusers", {
      $select: ["systemuserid"],
      $filter: [
        `internalemailaddress eq '${EMAIL_CONFIG.EMAIL_DU_COMPTE_EXPEDITEUR}'`,
      ],
    }),
    { recherche: "Compte expéditeur de mail", type: "/systemusers" }
  );

  return { idUtilisateur: compteExpediteur.systemuserid };
}

export function bodyPourEnvoiDeMail(email) {
  const destinatairesCC = email.destinatairesCC || [];
  return {
    subject: email.sujet,
    description: email.corps,
    ["regardingobjectid_" + email.regarding.type + "@odata.bind"]: email
      .regarding.id,
    email_activity_parties: [
      {
        "partyid_systemuser@odata.bind": email.destinataire,
        participationtypemask: To,
      },
      {
        "partyid_systemuser@odata.bind": `/systemusers(${
          email.expediteur.idUtilisateur
        })`,
        participationtypemask: Sender,
      },
      ...destinatairesCC.map(cc => ({
        "partyid_systemuser@odata.bind": cc,
        participationtypemask: CC,
      })),
    ],
  };
}

// https://docs.microsoft.com/en-us/dynamics365/customer-engagement/web-api/activityparty
// Puis chercher "participationtypemask"
const PARTICIPATION_TYPE_MASKS = {
  Sender: 1,
  To: 2,
  CC: 3,
};
const { To, CC, Sender } = PARTICIPATION_TYPE_MASKS;

export function bodyPieceJointe(pieceJointe, idEmailPorteur) {
  return {
    body: pieceJointe.body,
    filename: pieceJointe.nomDuFichier,
    mimetype: pieceJointe.mimeType,
    "objectid_email@odata.bind": `/emails(${idEmailPorteur})`,
    objecttypecode: "email",
  };
}

function crmEnvoieEmail(emailEnCrm, { http }) {
  return http.post(
    `/emails(${emailEnCrm.activityid})/Microsoft.Dynamics.CRM.SendEmail`,
    { IssueSend: true }
  );
}

import { selectEntreeDeVr } from "./generalites.selecteurs";
import { labelCatalogue } from "../../catalogueProduits/reducer";

export function bornesDeVr(state, materiels, stateCatalogueProduits) {
  const details = materiels
    .filter(m => m.idFamille !== null && m.idMarque !== null)
    .map(m => detailsDuMateriel(state, m, stateCatalogueProduits));

  const min = details.reduce(
    (safeMin, { min }) =>
      isNaN(safeMin) ? NaN : safeMin > min ? safeMin : min,
    details[0]?.min
  );

  const max = details.reduce(
    (safeMax, { max }) =>
      isNaN(safeMax) ? NaN : safeMax < max ? safeMax : max,
    details[0]?.max
  );

  return { min, max, details };
}

function detailsDuMateriel(state, materiel, stateCatalogueProduits) {
  const entreeDeVr = selectEntreeDeVr(state, materiel);

  const bornesAvecVr = Object.entries(entreeDeVr.vrParMois)
    .filter(([_, valeur]) => valeur !== 0)
    .map(([duree]) => duree);

  return {
    min: Number(bornesAvecVr[0]),
    max: Number(bornesAvecVr[bornesAvecVr.length - 1]),
    materiel: labelCatalogue(stateCatalogueProduits, materiel),
  };
}

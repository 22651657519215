import { UnPalier } from "../UnPalier";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FormattedMessage } from "react-intl";
import { isNullMoneyObject } from "../../../../../Money/money";
import { paliersSontDifferents } from "../paliersSontDifferents";

export function PaliersPourInvestissement(props) {
  const [paliers, setPaliers] = useState(props.paliers);

  useEffect(() => {
    if (paliersSontDifferents(paliers, props.paliers))
      setPaliers(props.paliers);
  }, [props.paliers]);

  return (
    <div>
      {paliers.map((p, index) => (
        <UnPalier
          key={index}
          index={index}
          palier={p}
          onChange={(p) =>
            setPaliers([
              ...paliers.slice(0, index),
              p,
              ...paliers.slice(index + 1),
            ])
          }
          onDelete={() =>
            setPaliers(paliers.filter((p) => p !== paliers[index]))
          }
          suppressionEstAutorisee={index > 0}
          loyerReadOnly={false}
        />
      ))}

      <div className="boutons-paliers">
        <button
          className="pure-button"
          data-testid="ajouter-palier"
          onClick={() => setPaliers([...paliers, unPalierEnCasDePropsVide()])}
        >
          <FontAwesomeIcon icon={faPlus} size="xs" />
        </button>

        <button
          className="pure-button pure-button-primary ml-10"
          data-testid="appliquer-paliers"
          onClick={() => props.onPaliersChange(paliers)}
          disabled={paliersSontIncomplets(paliers)}
        >
          <FontAwesomeIcon icon={faCheck} size="xs" />
          &nbsp;
          <FormattedMessage id="general.appliquer" />
        </button>
      </div>
    </div>
  );
}

function unPalierEnCasDePropsVide() {
  return [{}];
}

function paliersSontIncomplets(paliers) {
  return paliers.some(
    ({ duree, loyer }) =>
      duree === undefined ||
      duree === null ||
      duree === 0 ||
      isNullMoneyObject(loyer)
  );
}

import React from "react";
import { FormattedMessage } from "react-intl";
import Textarea from "../../../../../../Kit/Formulaires/Textarea";

export function Commentaire({ onChange, value }) {
  return (
    <div className="le-commentaire">
      <div>
        <FormattedMessage id="derogation_de_vr.avis_broker.commentaire" />
      </div>

      <Textarea rows={3} value={value} onChange={onChange} />
    </div>
  );
}

import React from "react";
import InputMontant from "../../../../Kit/Formulaires/InputMontant";
import { useDispatch } from "react-redux";
import {
  activerMaintenance,
  decalerDebutDeMaintenance,
  desactiverMaintenance,
  passerEnMaintenanceAvecEquilibrage,
  revenirEnMaintenanceSansEquilibrage,
} from "../../domaine/simulation/maintenance/actions";
import { $obj, forInput } from "../../../../Money/money";
import { orchestrerLePlanDeFinancement } from "../../domaine/planDeFinancement/actions";
import { useMaintenance } from "../../domaine/simulation/maintenance/hooks";
import "./Maintenance.css";
import "./Maintenance-mobile.css";
import { PERIODICITES } from "../../domaine/simulation/modeDeCalcul/periodicite/periodicite";
import BadgeWarning from "../../../../Kit/Badges/Warning/BadgeWarning";
import { ANOMALIES } from "../../domaine/anomalies/Anomalies";
import { useAnomalie } from "../../domaine/anomalies/hooks";
import { useNatureDuCalcul } from "../../domaine/simulation/modeDeCalcul/hooks";
import { NATURE_DU_CALCUL } from "../../domaine/simulation/modeDeCalcul/reducer";
import Aide from "../../../../Kit/Aide/Aide";

const { TRIMESTRIEL, MENSUEL, ANNUEL } = PERIODICITES;
const { MAINTENANCE_TROP_LONGUE } = ANOMALIES;
const { INVESTISSEMENT } = NATURE_DU_CALCUL;

export const Maintenance = () => {
  const dispatch = useDispatch();
  const maintenance = useMaintenance();
  const maintenanceTropLongue = useAnomalie(MAINTENANCE_TROP_LONGUE);
  const maintenanceIndisponible = useNatureDuCalcul() === INVESTISSEMENT;

  return (
    <div className="maintenance">
      <h3>Maintenance totale du contrat</h3>

      <div className="maintenance-champs">
        <div className="flex-dir-row flex-ai-center">
          <label htmlFor="montant-maintenance">Montant total</label>
          <Aide id="infobulle-maintenance" className="infobulle-maintenance">
            Saisir le montant total de la maintenance pour l'intégralité du
            contrat.
          </Aide>
          <div>
            <InputMontant
              onBlur={(value) => {
                dispatch(
                  value === null
                    ? desactiverMaintenance()
                    : activerMaintenance({
                        montantTotal: $obj(value),
                        dureeEnEcheances: maintenance.dureeEnEcheances,
                      })
                );
                dispatch(orchestrerLePlanDeFinancement());
              }}
              value={forInput(maintenance.montantTotal)}
              disabled={maintenanceIndisponible}
              id="montant-maintenance"
              append="€"
              className="input-s"
              placeholder=""
            />
          </div>
        </div>
        <div>
          <label htmlFor="duree-maintenance">Durée</label>
          <InputMontant
            onBlur={(value) => {
              dispatch(
                value === null
                  ? desactiverMaintenance()
                  : activerMaintenance({
                      montantTotal: maintenance.montantTotal,
                      dureeEnEcheances: value,
                    })
              );
              dispatch(orchestrerLePlanDeFinancement());
            }}
            value={maintenance.dureeEnEcheances}
            disabled={maintenanceIndisponible}
            id="duree-maintenance"
            append={labelEcheances(maintenance.periodicite)}
            className="input-s"
            placeholder=""
          />
        </div>
        <div>
          <label htmlFor="decalage-maintenance">
            Démarre après
            <InputMontant
              onBlur={(value) => {
                dispatch(decalerDebutDeMaintenance(value));
                dispatch(orchestrerLePlanDeFinancement());
              }}
              value={maintenance.nbEcheancesDeDecalage}
              disabled={!maintenance.estActivee}
              className="input-xs"
              append={labelEcheances(maintenance.periodicite)}
              placeholder=""
            />
          </label>
        </div>
        <div>
          <label htmlFor="equilibrage-maintenance" className="pointable">
            <input
              id="equilibrage-maintenance"
              type="checkbox"
              checked={maintenance.equilibrage.actif}
              disabled={maintenance.equilibrage.disponible === false}
              onChange={() => {
                dispatch(
                  maintenance.equilibrage.actif
                    ? revenirEnMaintenanceSansEquilibrage()
                    : passerEnMaintenanceAvecEquilibrage()
                );
                dispatch(orchestrerLePlanDeFinancement());
              }}
            />
            Activer l'équilibrage
          </label>
        </div>
      </div>

      {maintenanceTropLongue && <MaintenanceTropLongue />}
    </div>
  );
};

function labelEcheances(periodicite) {
  const motParPeriodicite = {
    [MENSUEL]: "mois",
    [TRIMESTRIEL]: "trimestres",
    [ANNUEL]: "années",
  };
  return motParPeriodicite[periodicite];
}

function MaintenanceTropLongue() {
  return (
    <BadgeWarning className="warning-maintenance">
      {MessageErreurMaintenanceTropLongue}
    </BadgeWarning>
  );
}

// FR seulement car la maintenance est une feature exclusivement FR.
export const MessageErreurMaintenanceTropLongue =
  "La durée de la maintenance ne peut pas dépasser la durée du financement.";

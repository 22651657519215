import { Adapters } from "../../../../../reduxStore/adapters";
import {
  assuranceFrDesactivee,
  getPrimeAssurance,
  getProduitAssurance,
} from "../../simulation/assurance/france/sagas";
import { call, put, select } from "redux-saga/effects";
import {
  erreurEstSurvenue,
  operationEnCours,
  operationTerminee,
} from "../../../../../Ui/actions";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import { selectProduitAssurance } from "../../simulation/assurance/france/reducer";
import {
  retirerPrimeAssuranceFrance,
  supprimerProduitAssuranceFrance,
} from "../../simulation/assurance/france/actions";

export function* importerAssuranceFranceSaga(
  depenses,
  {
    produitAssuranceAdapter = Adapters.produitAssuranceAdapter,
    assuranceAdapter = Adapters.assuranceFranceAdapter,
  } = {}
) {
  if (yield* assuranceFrDesactivee()) return;

  yield put(operationEnCours(IMPORTER_ASSURANCE_FRANCE));

  try {
    for (const { materiel, estAssure, option } of depenses) {
      yield call(getProduitAssurance, materiel, { produitAssuranceAdapter });

      if (estAssure) {
        const { simulation } = yield select(calculateur);
        const produitAssurance = selectProduitAssurance(
          simulation.assurance.france,
          materiel
        );
        yield call(
          getPrimeAssurance,
          { materiel, produitAssurance, option },
          { assuranceAdapter }
        );
      }
    }
  } catch (e) {
    for (const { materiel } of depenses) {
      yield put(retirerPrimeAssuranceFrance(materiel));
      yield put(supprimerProduitAssuranceFrance(materiel));
    }
    yield put(erreurEstSurvenue(IMPORTER_ASSURANCE_FRANCE, e));
  } finally {
    yield put(operationTerminee(IMPORTER_ASSURANCE_FRANCE));
  }
}

export const IMPORTER_ASSURANCE_FRANCE = "FRANCE/IMPORTER_ASSURANCE";

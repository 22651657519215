import { selectDerogationAssuranceEspagne } from "../../simulation/derogations/reducer";
import { STATUT_DEROGATION } from "../../../../Derogations/domaine/reducer";
import { selectPrimeAssuranceEspagne } from "../../simulation/assurance/espagne/reducer";
import { Money } from "../../../../../Money/money";
import { montantAchatDesProduitsAchetes } from "../../simulation/montantAFinancer/montants";

const { ACCEPTEE } = STATUT_DEROGATION;

export function affecterDerogationDeAssuranceEspagne(anomalie, simulation) {
  const { assurance, derogations, montantAFinancer } = simulation;

  const derogation = selectDerogationAssuranceEspagne(derogations);
  if (!derogation) return aucuneAnterieure();

  const prime = selectPrimeAssuranceEspagne(assurance.espagne).annuelle.montant;
  const dejaAccordee = Money(derogation.valeurDemandee);
  const demandeCourantePlusBasse = Money(prime).lessThan(dejaAccordee);
  if (demandeCourantePlusBasse) return aucuneAnterieure();

  const achatsActuels = montantAchatDesProduitsAchetes(montantAFinancer);
  const maximumCouvert = referencePlus10Pourcents();
  if (achatsActuels.greaterThan(maximumCouvert)) return aucuneAnterieure();

  return {
    ...anomalie,
    derogationAnterieure: derogation,
    dejaDerogee: derogation.statut === ACCEPTEE,
  };

  function aucuneAnterieure() {
    return { ...anomalie, dejaDerogee: false };
  }

  function referencePlus10Pourcents() {
    return Money(derogation.produitsAssocies.montantTotal).multiply(1.1);
  }
}

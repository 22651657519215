import React from "react";
import PropTypes from "prop-types";
import "./BadgeErreur.css";
import Exclamation from "./picto/Exclamation.svg";
import { useIntl } from "react-intl";
import { BadgeErreurBrut } from "./BadgeErreurBrut";

const BadgeErreur = ({
  className = "",
  style = {},
  messages,
  picto = Exclamation,
  titre,
}) => {
  const intl = useIntl();
  const titreReel =
    titre || intl.formatMessage({ id: "erreur_est_survenue.titre" });

  return (
    <BadgeErreurBrut
      className={className}
      style={style}
      picto={picto}
      titre={titreReel}
      messages={messages}
    />
  );
};

BadgeErreur.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  messages: PropTypes.array,
  picto: PropTypes.string,
  titre: PropTypes.string,
};

export default BadgeErreur;

export const FINANCER_PRODUIT_ACHETE = "FINANCER_PRODUIT_ACHETE";
export const financerProduitAchete = (produit) => ({
  type: FINANCER_PRODUIT_ACHETE,
  produit,
});

export const RACHETER_CONTRAT = "RACHETER_CONTRAT";
export const racheterContrat = (rachat) => ({
  type: RACHETER_CONTRAT,
  rachat,
});

export const SUPPRIMER_RACHAT_DE_CONTRAT = "SUPPRIMER_RACHAT_DE_CONTRAT";
export const supprimerRachatDeContrat = (idRachat) => ({
  type: SUPPRIMER_RACHAT_DE_CONTRAT,
  idRachat,
});

export const SUPPRIMER_PRODUIT = "SUPPRIMER_PRODUIT";
export const supprimerProduit = (idProduit) => ({
  type: SUPPRIMER_PRODUIT,
  idProduit,
});

export const CHANGER_LE_TAUX_DE_MARGE = "CHANGER_LE_TAUX_DE_MARGE";
export const changerLeTauxDeMarge = (tauxDeMarge) => ({
  type: CHANGER_LE_TAUX_DE_MARGE,
  tauxDeMarge,
});

export const CHANGER_LE_TAUX_DE_MARGE_RECALCULE_POUR_TEG =
  "CHANGER_LE_TAUX_DE_MARGE_RECALCULE_POUR_TEG";
export const changerLeTauxDeMargeRecalculePourTeg = (tauxDeMarge) => ({
  type: CHANGER_LE_TAUX_DE_MARGE_RECALCULE_POUR_TEG,
  tauxDeMarge,
});

export const RACHETER_UN_MATERIEL = "RACHETER_UN_MATERIEL";
export const racheterUnMateriel = (rachat) => ({
  type: RACHETER_UN_MATERIEL,
  rachat,
});

export const SUPPRIMER_RACHAT_DE_MATERIEL = "SUPPRIMER_RACHAT_DE_MATERIEL";
export const supprimerRachatDeMateriel = (idRachat) => ({
  type: SUPPRIMER_RACHAT_DE_MATERIEL,
  idRachat,
});

export const AJOUTER_COMMISSION = "AJOUTER_COMMISSION";
export const ajouterCommission = (id, montant, label) => ({
  type: AJOUTER_COMMISSION,
  commission: { id, montant, label },
});

export const SUPPRIMER_COMMISSION = "SUPPRIMER_COMMISSION";
export const supprimerCommission = (idCommission) => ({
  type: SUPPRIMER_COMMISSION,
  idCommission,
});

import { TYPE_DE_DEROGATION } from "./reducer";
import { guidEqual } from "../../../utils/guidUtils";
import {
  POSTE_UTILISATEUR,
  selectIdCrmUtilisateurEst,
  selectUtilisateurALePoste,
} from "../../../identiteUtilisateur/reducer";
import { selectMarchesEtBrokers } from "../../../referentiel/reducer";

const { TEG, MARGE, VR, ASSURANCE_ESPAGNE } = TYPE_DE_DEROGATION;
const { DIRECTEUR_COMMERCIAL } = POSTE_UTILISATEUR;

export function derogationEstAssigneeAuConnecte(
  derogation,
  identiteState,
  referentielState,
  derogateursDeTeg
) {
  if (derogation.type === MARGE)
    return connecteEstLeDerogateur(derogation, identiteState);

  if (derogation.type === ASSURANCE_ESPAGNE)
    return connecteEstLeDerogateur(derogation, identiteState);

  if (derogation.type === VR) return identiteState.poste === "BROKER";

  if (derogation.type === TEG)
    return connecteEstDerogateurDeTeg(identiteState, derogateursDeTeg);

  return false;
}

function connecteEstLeDerogateur(derogation, identiteState) {
  return guidEqual(
    derogation.derogateur.utilisateur.id,
    identiteState.userIdCrm
  );
}

function connecteEstDerogateurDeTeg(identiteState, derogateursDeTeg) {
  return derogateursDeTeg.some((derogateur) =>
    guidEqual(derogateur.utilisateur.id, identiteState.userIdCrm)
  );
}

export function derogationEstDansEquipeDuConnecte(derogation, identiteState) {
  if (derogation.type === TEG) return false;
  if (derogation.type === VR) return false;

  if (derogation.type === MARGE || derogation.type === ASSURANCE_ESPAGNE) {
    const utilisateurEstDirCo = selectUtilisateurALePoste(
      identiteState,
      DIRECTEUR_COMMERCIAL
    );
    const derogationEstPourQqunDautre = !guidEqual(
      derogation.derogateur.utilisateur.id,
      identiteState.userIdCrm
    );
    const surLaMemeDivision =
      derogation.projet.division === identiteState.marcheUtilisateur;

    return (
      utilisateurEstDirCo && derogationEstPourQqunDautre && surLaMemeDivision
    );
  }
}

export const derogationEstVisible = (
  derogation,
  identiteState,
  referentielState,
  derogateursDeTeg
) =>
  derogationEstAssigneeAuConnecte(
    derogation,
    identiteState,
    referentielState,
    derogateursDeTeg
  ) || derogationEstDansEquipeDuConnecte(derogation, identiteState);

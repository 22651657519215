import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TERMES } from "../../../../finance/TERMES";

const Terme = ({ choisi, disponibles, onChange, className = "" }) => (
  <div className={`flex-dir-col ${className}`}>
    <span className="text-bold">
      <FormattedMessage id="calculateur.terme.titre" />
    </span>
    {disponibles.map((terme) => (
      <div
        key={terme}
        className="p-2-0"
        onChange={(event) => onChange(event.target.value)}
      >
        <input
          id={`terme-${terme}`}
          type="radio"
          name="terme"
          value={terme}
          defaultChecked={terme === choisi}
        />
        <label htmlFor={`terme-${terme}`} className="pointable ml-5">
          {NOMS_DES_TERMES[terme]}
        </label>
      </div>
    ))}
  </div>
);

const NOMS_DES_TERMES = {
  [TERMES.ECHU]: <FormattedMessage id="calculateur.terme.choix.echu" />,
  [TERMES.A_ECHOIR]: <FormattedMessage id="calculateur.terme.choix.a_echoir" />,
};

Terme.propTypes = {
  choisi: PropTypes.number.isRequired,
  disponibles: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Terme;

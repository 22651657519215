import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AiguillageVersLeDetail from "./AiguillageVersLeDetail.container";
import { chargerUneDerogationDetaillee } from "../../domaine/actions";

export function ChargerDerogationDetaille() {
  const { id: idDerogation } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(chargerUneDerogationDetaillee(idDerogation));
  }, [idDerogation, dispatch]);

  return <AiguillageVersLeDetail idDemande={idDerogation} />;
}

import { httpServiceCrm } from "../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../utils/odata/odata";
import { MARCHES, POSTE_UTILISATEUR } from "./reducer";
import { guidEqual } from "../utils/guidUtils";

const ID_DIVISION_ESPAGNE = process.env.REACT_APP_ID_DIVISION_ESPAGNE;
const ID_DIVISION_FRANCE = process.env.REACT_APP_ID_DIVISION_FRANCE;
const ID_DU_POSTE_COMMERCIAL = process.env.REACT_APP_ID_DU_POSTE_COMMERCIAL;
const ID_DU_POSTE_DIRECTEUR_REGIONAL =
  process.env.REACT_APP_ID_DU_POSTE_DIRECTEUR_REGIONAL;
const ID_DU_POSTE_DIRECTEUR_COMMERCIAL =
  process.env.REACT_APP_ID_DU_POSTE_DIRECTEUR_COMMERCIAL;
const ID_DU_POSTE_DIRECTION = process.env.REACT_APP_ID_DU_POSTE_DIRECTION;
const ID_DU_POSTE_BROKER = process.env.REACT_APP_ID_DU_POSTE_BROKER;
const ID_DU_POSTE_ACAD = process.env.REACT_APP_ID_DU_POSTE_ACAD;
const { ESPAGNE, FRANCE } = MARCHES;
const {
  COMMERCIAL,
  DIRECTEUR_REGIONAL,
  DIRECTEUR_COMMERCIAL,
  ACAD,
  BROKER,
} = POSTE_UTILISATEUR;

export const identiteAdapterCrm = {
  async getIdentiteDuConnecte({ http = httpServiceCrm } = {}) {
    const { UserId } = await http.get("WhoAmI()");

    const identiteCrm = await http.get(
      odataQuery(`systemusers(${UserId})`, {
        $select: [
          "internalemailaddress",
          "_positionid_value",
          "_businessunitid_value",
        ],
      })
    );

    return {
      userIdCrm: UserId,
      email: identiteCrm.internalemailaddress,
      poste: getPoste(identiteCrm._positionid_value),
      marcheUtilisateur: getMarcheDeDivisionCrm(
        identiteCrm._businessunitid_value
      ),
    };
  },

  async getDirecteur(idUtilisateur, { httpService = httpServiceCrm } = {}) {
    const requete = odataQuery(`systemusers(${idUtilisateur})`, {
      $select: ["_parentsystemuserid_value"],
    });
    const reponse = await httpService.get(requete);
    return { idUtilisateur: reponse._parentsystemuserid_value };
  },

  async getIdUtilisateurFromEmail(
    email,
    contexteDeRecherche,
    { http = httpServiceCrm } = {}
  ) {
    const requete = odataQuery("systemusers", {
      $select: ["systemuserid"],
      $filter: [`internalemailaddress eq '${email}'`],
    });
    const recherche = {
      recherche: contexteDeRecherche,
      type: `/systemusers avec email '${email}'`,
    };
    const reponse = await http.getUnique(requete, recherche);
    return { idUtilisateur: reponse.systemuserid };
  },

  async getMarcheDeUtilisateur(idUtilisateur, { http = httpServiceCrm } = {}) {
    const divisionUtilisateur = await http.get(
      odataQuery(`systemusers(${idUtilisateur})`, {
        $select: ["_businessunitid_value"],
      })
    );

    return getMarcheDeDivisionCrm(divisionUtilisateur._businessunitid_value);
  },

  async getAdvDesCommerciaux(marcheUtilisateur) {
    const aucuneAdv = [];

    // Depuis avril 2020 l'Espagne ne souhaite plus avoir d'ADV des commerciaux
    // On laisse quand même l'abstraction en place pour pouvoir facilement
    // revenir les remettre en place.
    const advParMarche = {
      [ESPAGNE]: () => aucuneAdv,
      [FRANCE]: () => aucuneAdv,
    };

    return advParMarche[marcheUtilisateur]();
  },

  async utilisateurAUnRole(
    idUtilisateur,
    rolesCandidats,
    { http = httpServiceCrm } = {}
  ) {
    const { systemuserroles_association } = await http.get(
      odataQuery(`systemusers(${idUtilisateur})`, {
        $expand: [["systemuserroles_association", { $select: ["roleid"] }]],
      })
    );

    const idDeRolesUtilisateurs = systemuserroles_association.map((r) =>
      r.roleid.toUpperCase()
    );

    const candidatChezUtilisateur = rolesCandidats.find((candidat) =>
      idDeRolesUtilisateurs.includes(candidat.toUpperCase())
    );

    return candidatChezUtilisateur !== undefined;
  },

  async getUtilisateurMaitre(
    marcheUtilisateur,
    { http = httpServiceCrm } = {}
  ) {
    const parMarche = {
      [FRANCE]: getSpecsDuMaitre(
        { id: ID_DU_POSTE_DIRECTION, nom: "DIRECTION" },
        { id: ID_DIVISION_FRANCE, nom: "FRANCE" }
      ),
      [ESPAGNE]: getSpecsDuMaitre(
        { id: ID_DU_POSTE_DIRECTION, nom: "DIRECTION" },
        { id: ID_DIVISION_ESPAGNE, nom: "ESPAGNE" }
      ),
    };

    const utilisateur = await http.getUnique(
      parMarche[marcheUtilisateur].requete,
      parMarche[marcheUtilisateur].description
    );

    return { idUtilisateur: utilisateur.systemuserid };
  },
};

export function getMarcheDeDivisionCrm(idDivisionCrm) {
  const estDivisionEspagne = guidEqual(idDivisionCrm, ID_DIVISION_ESPAGNE);
  return estDivisionEspagne ? ESPAGNE : FRANCE;
}

export function getPoste(positionIdCrm) {
  const postes = {
    [ID_DU_POSTE_COMMERCIAL.toUpperCase()]: COMMERCIAL,
    [ID_DU_POSTE_DIRECTEUR_REGIONAL.toUpperCase()]: DIRECTEUR_REGIONAL,
    [ID_DU_POSTE_DIRECTEUR_COMMERCIAL.toUpperCase()]: DIRECTEUR_COMMERCIAL,
    [ID_DU_POSTE_DIRECTION.toUpperCase()]: DIRECTEUR_COMMERCIAL, // Mappe la DIRECTION sur DIRECTEUR_COMMERCIAL
    [ID_DU_POSTE_ACAD.toUpperCase()]: ACAD,
    [ID_DU_POSTE_BROKER.toUpperCase()]: BROKER,
  };

  return postes[positionIdCrm?.toUpperCase()];
}

function getSpecsDuMaitre(poste, division) {
  return {
    requete: odataQuery("systemusers", {
      $select: ["systemuserid"],
      $filter: [
        `_positionid_value eq ${poste.id}`,
        `_businessunitid_value eq ${division.id}`,
      ],
    }),
    description: {
      titre: `Impossible de trouver le dérogateur maître ${division.nom}`,
      recherche: "utilisateur maître",
      type: `utilisateur au poste ${poste.nom} sur la division ${division.nom}`,
    },
  };
}

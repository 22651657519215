import { listeDesProduitsAchetes } from "../../simulation/montantAFinancer/reducer";
import { LONGUEUR_MAX_DESCRIPTION_DEPENSE } from "../../../../../Connecteurs/Crm/limites";
import { AnomalieMaterielNonReferenceDescriptionTropLongue } from "../Anomalies";
import { produitEstNonReference } from "../../simulation/catalogueProduits/reducer";

export function verifierLaDescriptionDesMaterielsNonReferences(simulation) {
  const { montantAFinancer } = simulation;
  return listeDesProduitsAchetes(montantAFinancer)
    .filter(p => produitEstNonReference(p))
    .filter(
      p =>
        p.description !== null &&
        p.description.length > LONGUEUR_MAX_DESCRIPTION_DEPENSE
    )
    .map(() => AnomalieMaterielNonReferenceDescriptionTropLongue());
}

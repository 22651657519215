import { montantArrondi } from "../../../../../../../Kit/formatage";
import { CODES_GROUPES_OPTIONS } from "../../../../../../../Connecteurs/Crm/codesGroupesOptions";
import { isNullMoneyObject, Money } from "../../../../../../../Money/money";
import { LOUEUR_DE_CONTRAT } from "../../../../simulation/montantAFinancer/reducer";
import { formatyyyyMMdd } from "../../../../../../../utils/dateUtils";
import { descriptionCompleteDeRachatDeContrat } from "../../../../simulation/montantAFinancer/rachatDeContrat";
import {
  ID_MARQUE_GENERIQUE,
  laMarque,
  leProduitCatalogue,
} from "../../../../simulation/catalogueProduits/reducer";

const { typesDeMateriels, typesDeRachats } = CODES_GROUPES_OPTIONS;
const { CONCURRENT, ITL } = LOUEUR_DE_CONTRAT;

export function mapperRachatDeContrat(rachat, stateCatalogueProduits) {
  const pasDeRevente =
    isNullMoneyObject(rachat.montantDeLaRevente) ||
    Money(rachat.montantDeLaRevente).isZero();

  const itl_name = descriptionCompleteDeRachatDeContrat(rachat, 100).complete;

  const typeDeDepense = pasDeRevente
    ? typesDeMateriels.remisAuContrat
    : typesDeMateriels.rachat;

  const produitCatalogue = leProduitCatalogue(
    stateCatalogueProduits,
    rachat.materielRachete.idFamille
  );
  const marqueCatalogue = laMarque(
    stateCatalogueProduits,
    rachat.materielRachete.idMarque
  );

  return {
    depense: {
      itl_isoccasion: true,
      itl_montantht: montantArrondi(rachat.montantEncours, 2),
      itl_quantite: 1,
      itl_montanttotalht: montantArrondi(rachat.montantEncours, 2),
      itl_name,
      itl_typematerielcode: typeDeDepense,
      itl_date_encours_force: formatyyyyMMdd(rachat.dateEncours),
      itl_num_contrat_force: rachat.numeroContrat,
      itl_type_depense_force: {
        [ITL]: typesDeRachats.rachatContratItl,
        [CONCURRENT]: typesDeRachats.rachatContratConcurrent,
      }[rachat.loueurContrat],
      "itl_FamilleId@odata.bind": `/itl_familles(${produitCatalogue.crm.id})`,
      "itl_MarqueId@odata.bind":
        marqueCatalogue.crm.id !== ID_MARQUE_GENERIQUE
          ? `/itl_marques(${marqueCatalogue.crm.id})`
          : undefined,
    },
    revente: pasDeRevente
      ? undefined
      : {
          itl_name,
          itl_montantht: montantArrondi(rachat.montantDeLaRevente, 2),
        },
  };
}

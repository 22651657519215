import { selectMarcheUtilisateur } from "../../simulation/profilUtilisateur/reducer";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";
import { selectAssuranceEspagneEstAjoutee } from "../../simulation/assurance/espagne/reducer";
import { AnomalieAssuranceEspagneManquante } from "../Anomalies";
import { Features } from "../../../../../features/Features";

const { ESPAGNE } = MARCHES;

export function verifierAssuranceEspagneManquante(simulation) {
  const featureActivee = Features.AvecDerogationAssuranceEspagne === true;
  if (!featureActivee) return;

  const { assurance, profilUtilisateur } = simulation;

  const marche = selectMarcheUtilisateur(profilUtilisateur);
  const enEspagne = marche === ESPAGNE;
  if (!enEspagne) return;

  const assurancePresente = selectAssuranceEspagneEstAjoutee(assurance.espagne);
  if (!assurancePresente) return AnomalieAssuranceEspagneManquante();
}

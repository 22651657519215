import { call, put, select } from "redux-saga/effects";
import { identiteUtilisateur } from "../../../../reduxStore/rootReducer";
import { selectIdCrmUtilisateur } from "../../../../identiteUtilisateur/reducer";
import { Adapters } from "../../../../reduxStore/adapters";
import { notifierLeCommercialDeLaDecisionSaga } from "../notification/sagas";
import { chargerUneDerogationDetaillee } from "../actions";

export function* deciderDerogationSaga(
  derogation,
  decision,
  idTypeDerogation,
  {
    resumeDeDerogationAdapter = Adapters.resumeDeDerogationAdapter,
    decisionDeDerogationAdapter = Adapters.decisionDeDerogationAdapter,
  } = {}
) {
  yield* persisterDecision(derogation, decision, idTypeDerogation, {
    decisionDeDerogationAdapter,
    resumeDeDerogationAdapter,
  });

  yield call(notifierLeCommercialDeLaDecisionSaga, derogation.id);

  yield put(chargerUneDerogationDetaillee(derogation.id));
}

function* persisterDecision(
  derogation,
  decision,
  idTypeDerogation,
  { decisionDeDerogationAdapter, resumeDeDerogationAdapter }
) {
  const idDerogateur = yield* getMonIdDerogateur(
    { resumeDeDerogationAdapter },
    idTypeDerogation
  );

  yield call(decisionDeDerogationAdapter.prendreDecision, derogation.id, {
    ...decision,
    derogateur: { id: idDerogateur },
  });
}

function* getMonIdDerogateur({ resumeDeDerogationAdapter }, idTypeDerogation) {
  const identite = yield select(identiteUtilisateur);
  const idDerogateur = yield call(
    resumeDeDerogationAdapter.getIdDerogateurDeUtilisateur,
    selectIdCrmUtilisateur(identite),
    idTypeDerogation
  );
  return idDerogateur;
}

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import "./AssuranceEspagne.css";
import "./AssuranceEspagne-mobile.css";
import { $, $obj, forInput } from "../../../../../Money/money";
import InputMontant from "../../../../../Kit/Formulaires/InputMontant";
import {
  calculerMontantParLoyer,
  PAS_DE_PRIME_ASSURANCE,
} from "../../../domaine/simulation/assurance/espagne/reducer";
import { enEuros } from "../../../../../Kit/formatage";
import { htOuTtc } from "../../../../../i18n/raccourcis";
import { PERIODICITES } from "../../../domaine/simulation/modeDeCalcul/periodicite/periodicite";
import BadgeWarning from "../../../../../Kit/Badges/Warning/BadgeWarning";
import { BadgeDerogationAssuranceEspagne } from "./BadgeDerogationAssuranceEspagne";

export function AssuranceEspagneManuelle({
  estVisible,
  prime,
  periodicite,
  onSubmit,
  montantAAssurer,
  client,
  montantAFinancer,
}) {
  if (!estVisible) return <Fragment />;

  const intl = useIntl();

  const [afficherAide, setAfficherAide] = useState(false);
  const [montantAFinancerReference] = useState(montantAFinancer);

  useEffect(() => {
    // Le cas du premier montant reçu est particulier : c'est le cas de l'import de FT.
    // On reçoit un montant, on reçoit une prime, mais on ne veut pas afficher l'aide.
    // Puisque l'utilisateur affiche la FT importée pour la première fois : on ne va pas lui
    // dire que le montant à financer a changé.
    const premierMontantRecu = montantAFinancerReference === montantAFinancer;
    if (premierMontantRecu) return;

    if (prime !== PAS_DE_PRIME_ASSURANCE) setAfficherAide(true);
  }, [montantAFinancer]);

  function submitPrime(prime) {
    onSubmit(prime);
    setAfficherAide(false);
  }

  return (
    <div className="assurance-espagne">
      <div className="main">
        <div className="titre">
          <h3>
            <FormattedMessage id="calculateur.assurance" />
          </h3>
          <div>
            <FormattedMessage
              id="calculateur.assurance.montant_a_assurer"
              values={{
                montant: enEuros(montantAAssurer, htOuTtc(intl, client)),
              }}
            />
          </div>
          <BadgeDerogationAssuranceEspagne />
        </div>

        <Saisie
          prime={prime}
          periodicite={periodicite}
          onSubmit={(prime) => submitPrime(prime)}
        />
      </div>

      {afficherAide && (
        <AideALaSaisie onJaiCompris={() => setAfficherAide(false)} />
      )}
    </div>
  );
}

function Saisie({ prime, periodicite, onSubmit }) {
  return (
    <div className="assurance-espagne-saisie">
      <div className="une-prime">
        {message("prime_annuelle_manuelle")}
        <div>
          <InputMontant
            value={forInput(prime.annuelle.montant)}
            onBlur={(value) => {
              if (value === null) {
                onSubmit(PAS_DE_PRIME_ASSURANCE);
                return;
              }

              const parLoyer = calculerMontantParLoyer($(value), periodicite);
              onSubmit({
                annuelle: { montant: $obj(value) },
                parLoyer: { montant: parLoyer.toObject() },
              });
            }}
            key={forInput(prime.annuelle.montant)}
            testId="input-prime-annuelle"
            className="input-s prime"
            append="€"
          />
        </div>
      </div>
      <div className="une-prime">
        {message("prime_par_loyer")}
        <div>
          <InputMontant
            value={forInput(prime.parLoyer.montant)}
            disabled
            key={forInput(prime.parLoyer.montant)}
            append="€"
            className="input-s"
            testId="input-prime-par-loyer"
          />
        </div>
      </div>
    </div>
  );
}

function AideALaSaisie({ onJaiCompris }) {
  return (
    <div className="aide-a-la-saisie">
      <BadgeWarning>
        <div>{message("montant_a_financer_different")}</div>
        <button className="pure-button button-warning" onClick={onJaiCompris}>
          <FormattedMessage id="general.j_ai_compris" />
        </button>
      </BadgeWarning>
    </div>
  );
}

const message = (suffixe) => (
  <FormattedMessage id={"calculateur.assurance." + suffixe} />
);

AssuranceEspagneManuelle.propTypes = {
  estVisible: PropTypes.bool.isRequired,
  prime: PropTypes.object.isRequired,
  montantAFinancer: PropTypes.number.isRequired,
  periodicite: PropTypes.oneOf(Object.values(PERIODICITES)).isRequired,
  onSubmit: PropTypes.func.isRequired,
  montantAAssurer: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

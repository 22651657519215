import { isNullMoneyObject, Money } from "../Money/money";

export function pairePourcentageMontant(specs) {
  const manqueLeMontant = isNullMoneyObject(specs.montant);

  const manqueLePourcentage =
    specs.pourcentage === null || specs.pourcentage === undefined;

  const demandeVide = manqueLePourcentage && manqueLeMontant;
  const specsDejaCalculees = !manqueLePourcentage && !manqueLeMontant;
  if (demandeVide || specsDejaCalculees) return specs;

  if (manqueLeMontant) {
    const montant = Money(specs.base).percentage(specs.pourcentage);
    return {
      ...specs,
      pourcentage: Number(specs.pourcentage),
      montant: montant.toObject(),
    };
  }

  const pourcentage = Money(specs.montant)
    .convertPrecision(4)
    .divide(Money(specs.base).toUnit())
    .multiply(100);
  return { ...specs, pourcentage: pourcentage.toUnit() };
}

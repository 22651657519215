export const TAUX_DE_VR_VIDE = null;

export const tauxDeVrDemande = (state, produit) =>
  state.tauxDemandes.parIdProduit[produit.id];

export const tauxDeVrGrille = (state, produit) =>
  state.tauxGrille.parIdProduit[produit.id]?.tauxDeVr ?? TAUX_DE_VR_VIDE;

export const tauxSeuilDerogation = (state, produit) =>
  state.tauxGrille.parIdProduit[produit.id]?.tauxSeuilDerogation ??
  TAUX_DE_VR_VIDE;

export function tauxEffectifDeVr(state, produit) {
  const tauxDemande = tauxDeVrDemande(state, produit);
  const tauxGrille = tauxDeVrGrille(state, produit);

  if (tauxDemande === undefined) return tauxGrille;
  else return tauxDemande;
}

export const lesTauxDeVrDemandes = (state) =>
  Object.entries(state.tauxDemandes.parIdProduit);

import { httpServiceCrm } from "../../../../../../../Connecteurs/Crm/httpServiceCrm";
import { templating } from "../../../../../../../i18n/templating";
import { enEuros } from "../../../../../../../Kit/formatage";
import { TEMPLATE_COMMENTAIRE_ASSURANCE } from "./commentaireAssurance.template";
import { Money, toCentimes } from "../../../../../../../Money/money";
import { formatyyyyMMdd } from "../../../../../../../utils/dateUtils";
import { CODES_GROUPES_OPTIONS } from "../../../../../../../Connecteurs/Crm/codesGroupesOptions";
import { crmSendEmail } from "../../../../../../../Connecteurs/Crm/sendEmail";
import { CORPS } from "./templates/INFO_DEROGATION_ASSURANCE_es";
import { UrlsForce } from "../../../../../../../routing/Urls";

const ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_ASSURANCE_ESPAGNE;
const { statutDeDerogation } = CODES_GROUPES_OPTIONS;

export const creerAssuranceEspagneAdapterCrm = {
  async ajouterAssuranceSurFt(
    idFt,
    { prime, paliers, derogation, idProjet },
    { http = httpServiceCrm, envoieEmail = crmSendEmail } = {}
  ) {
    const commentaireAssurance = templating(TEMPLATE_COMMENTAIRE_ASSURANCE, {
      montantPrimeAnnuelle: enEuros(prime.annuelle.montant, "s/IVA"),
      montantParLoyer: enEuros(prime.parLoyer.montant, "s/IVA"),
      chaineDeLoyers: paliers
        .map(p => `${p.duree} x ${enEuros(p.loyerFullService, "s/IVA")}`)
        .join(" - "),
    });

    await http.patch(`/itl_fichetransmissions(${idFt})`, {
      itl_commentaireft: commentaireAssurance,
      itl_commentaires: jsonAssurance({ prime, paliers }),
      itl_montantassurance: Money(prime.annuelle.montant).toUnit(),
      itl_montantassurancebris: Money(prime.annuelle.montant).toUnit(),
      itl_montantassuranceptia: 0,
    });

    if (derogation) {
      const { itl_derogationid } = await http.post(
        "itl_derogations?$select=itl_derogationid",
        {
          "itl_FTconcernee@odata.bind": `/itl_fichetransmissions(${idFt})`,
          "itl_projetconcerne@odata.bind": `/itl_projets(${idProjet})`,
          "itl_derogateur@odata.bind": `/itl_derogateurs(${
            derogation.derogateur.idDerogateur
          })`,
          "itl_Typederogation@odata.bind": `/itl_typederogations(${ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE})`,
          itl_name: derogation.nom,
          itl_valeurorigine: derogation.valeurOrigine,
          itl_valeurdemandee: derogation.valeurDemandee,
          itl_commentairesdemandeur: derogation.commentaires.demandeur,
          itl_commentairesdecideur: derogation.commentaires.decideur,
          itl_datedemande: formatyyyyMMdd(Date.now()),
          itl_statutderogation: statutDeDerogation.get(derogation.statut),
          itl_datedecision: derogation.dateDecision,
        }
      );

      if (!derogation.autoAcceptee)
        await envoieEmail({
          sujet: derogation.nom,
          corps: templating(CORPS, {
            nomDerogation: derogation.nom,
            urlOuvrirDerogation: UrlsForce.derogation(itl_derogationid).ouvrir,
          }),
          regarding: {
            type: "itl_derogation",
            id: `/itl_derogations(${itl_derogationid})`,
          },
          destinataire: `/systemusers(${derogation.derogateur.idUtilisateur})`,
        });
    }
  },

  async ajouterAssuranceSurDepense(
    { depenseCrm, primeAssurance },
    { http = httpServiceCrm } = {}
  ) {
    const ID_ASSURANCE =
      process.env.REACT_APP_ASSURANCE_ID_DE_ASSURANCE_ESPAGNE_EN_CRM;

    await http.patch(`itl_depensefts(${depenseCrm.itl_depenseftid})`, {
      "itl_AssuranceId@odata.bind": `/itl_assurances(${ID_ASSURANCE})`,
      itl_montantassurance: Money(primeAssurance.annuelle.montant).toUnit(),
    });
  },

  async ajouterAssuranceSurLoyer(
    loyerCrm,
    palierForce,
    montantPrimeParLoyer,
    { http = httpServiceCrm } = {}
  ) {
    await http.patch(`itl_loyerfts(${loyerCrm.id})`, {
      itl_montantassuranceecheance: Money(montantPrimeParLoyer).toUnit(),
      itl_montanthtfullservice: Money(palierForce.loyerFullService).toUnit(),
    });
  },
};

function jsonAssurance({ prime, paliers }) {
  const formatApi = montant => toCentimes(montant);
  return JSON.stringify({
    version: "1.0",
    primeParLoyer: formatApi(prime.parLoyer.montant),
    primeAnnuelle: formatApi(prime.annuelle.montant),
    paliers: paliers.map(p => formatApi(p.loyerFullService)),
  });
}

import { guidEqual } from "./guidUtils";

export function supprimer(objet, champsCibles) {
  const estUnTableau = Array.isArray(objet);
  if (estUnTableau) return objet.filter((x) => !champsCibles.includes(x));

  return champsCibles.reduce(
    (resultat, champ) => {
      delete resultat[champ];
      return resultat;
    },
    { ...objet }
  );
}

export function indexerParCle(tableauDeObjets, cle, indexExistant = {}) {
  return tableauDeObjets.reduce(
    (rangement, obj) => ({ ...rangement, [obj[cle]]: obj }),
    indexExistant
  );
}

export function findGuidKey(objet, keyId) {
  return Object.keys(objet).find((id) => guidEqual(id, keyId));
}

import { CHOISIR_LE_TERME } from "./actions";
import { TERMES } from "../../../../../finance/TERMES";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";

const { ECHU, A_ECHOIR } = TERMES;
const { FRANCE } = MARCHES;

export const TERME_PAR_DEFAUT = A_ECHOIR;

const STATE_INITIAL = {
  choisi: TERME_PAR_DEFAUT,
  disponibles: Object.values(TERMES),
};

function terme(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CHOISIR_LE_TERME:
      return {
        ...state,
        choisi: action.terme,
      };
    default:
      return state;
  }
}

export const termesDisponibles = (marcheUtilisateur) =>
  marcheUtilisateur === FRANCE ? [A_ECHOIR] : [A_ECHOIR, ECHU];

export default terme;

import React, { useState } from "react";
import "./Tabs.css";
import { Sticky } from "../Sticky/Sticky";

export function Tabs({ children }) {
  const [activeTab, setActiveTab] = useState(children[0].props.label);
  const tabs = children.filter(React.isValidElement);
  return (
    <div className="tabs">
      <Sticky selecteurDeLaCible=".tab-content">
        <ol className="tab-list">
          {tabs.map((t) => (
            <Tab
              key={t.props.label}
              isActive={activeTab === t.props.label}
              onClick={() => setActiveTab(t.props.label)}
              label={t.props.label}
            />
          ))}
        </ol>
      </Sticky>
      <div className="tab-content">
        {tabs.map((t) => {
          if (t.props.label !== activeTab) return undefined;
          return t.props.children;
        })}
      </div>
    </div>
  );
}

export function Tab({ label, onClick, isActive }) {
  return (
    <li onClick={onClick} className={isActive ? "tab-list-active" : undefined}>
      {label}
    </li>
  );
}

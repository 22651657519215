import { call } from "redux-saga/effects";
import { listeDesRachatsDeContrats } from "../../../../simulation/montantAFinancer/reducer";
import { mapperRachatDeContrat } from "./rachatDeContrat.mapping";
import { crmPost } from "../../../../../../../Connecteurs/Crm/webApi";

export function* creerRachatsDeContratsSaga(simulation, idFt) {
  const lesRachats = yield call(
    listeDesRachatsDeContrats,
    simulation.montantAFinancer
  );

  for (const rachat of lesRachats) {
    const rachatMappe = yield call(
      mapperRachatDeContrat,
      rachat,
      simulation.catalogueProduits
    );
    const { itl_depenseftid } = yield call(
      crmPost,
      "itl_depensefts?$select=itl_depenseftid",
      {
        "itl_FicheTransmissionId@odata.bind": `/itl_fichetransmissions(${idFt})`,
        ...rachatMappe.depense,
      }
    );

    if (rachatMappe.revente)
      yield call(crmPost, "itl_recettefts", {
        "itl_DepenseFtId@odata.bind": `/itl_depensefts(${itl_depenseftid})`,
        "itl_FicheTransmissionId@odata.bind": `/itl_fichetransmissions(${idFt})`,
        ...rachatMappe.revente,
      });
  }
}

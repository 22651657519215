import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ChoixDeVr } from "./Formulaire/ChoixDeVr";
import { SaisieDeVrManuelle } from "./Formulaire/SaisieDeVrManuelle";
import { pairePourcentageMontant } from "../../../../../utils/pourcentageUtils";
import { STATUT_DEROGATION } from "../../../domaine/reducer";
import { Submit } from "./Formulaire/Submit";
import { PieceJointe } from "./Formulaire/PieceJointe";
import { Commentaire } from "./Formulaire/Commentaire";
import { RefusCategorique } from "./Formulaire/RefusCategorique";

const { REFUSEE, ACCEPTEE, ACCORD_PARTIEL } = STATUT_DEROGATION;

export function FormulaireAvisBroker({ derogation, onAvis }) {
  const [statut, setStatut] = useState(derogation.statut);

  const valeurDeRefuser = pairePourcentageMontant({
    base: derogation.depense.montantUnitaire,
    pourcentage: derogation.valeurOrigine,
  });
  const valeurDeAccepter = pairePourcentageMontant({
    base: derogation.depense.montantUnitaire,
    pourcentage: derogation.valeurDemandee,
  });
  const [valeurAvis, setValeurAvis] = useState({
    pourcentage: derogation.valeurBroker.pourcentage,
    montant: derogation.valeurBroker.montant,
  });

  const [pieceJointe, setPieceJointe] = useState(null);
  const [commentaire, setCommentaire] = useState(
    derogation.commentaireBroker || ""
  );

  return (
    <form
      className="formulaire-avis-broker"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="la-vr">
        <fieldset>
          <legend>
            <strong>
              <FormattedMessage id="derogation_de_vr.avis_broker.votre_avis" />
            </strong>
          </legend>

          <div>
            <RefusCategorique
              checked={statut === REFUSEE && valeurAvis.pourcentage === null}
              onChoice={() => {
                setStatut(REFUSEE);
                setValeurAvis({ pourcentage: null, montant: null });
              }}
            />

            <ChoixDeVr
              label={<LabelRefuser />}
              valeur={valeurDeRefuser}
              onChoice={() => {
                setStatut(REFUSEE);
                setValeurAvis(valeurDeRefuser);
              }}
              checked={statut === REFUSEE && valeurAvis.pourcentage !== null}
            />

            <SaisieDeVrManuelle
              montantMateriel={derogation.depense.montantUnitaire}
              valeurMini={valeurDeRefuser}
              valeurInitiale={statut === ACCORD_PARTIEL ? valeurAvis : null}
              onChange={setValeurAvis}
              onChecked={() => setStatut(ACCORD_PARTIEL)}
              checked={statut === ACCORD_PARTIEL}
            />

            <ChoixDeVr
              label={<LabelAccepter />}
              valeur={valeurDeAccepter}
              checked={statut === ACCEPTEE}
              onChoice={() => {
                setStatut(ACCEPTEE);
                setValeurAvis(valeurDeAccepter);
              }}
            />
          </div>
        </fieldset>
      </div>

      <PieceJointe
        onFilePick={setPieceJointe}
        onFileDelete={() => setPieceJointe(null)}
      />

      <Commentaire value={commentaire} onChange={setCommentaire} />

      <Submit
        statut={statut}
        commentaire={commentaire}
        valeurAvis={valeurAvis}
        pieceJointe={pieceJointe}
        derogation={derogation}
        onSubmit={onAvis}
      />
    </form>
  );
}

FormulaireAvisBroker.propTypes = {
  derogation: PropTypes.object.isRequired,
  onAvis: PropTypes.func.isRequired,
};

function LabelRefuser() {
  return (
    <>
      <span className="tag button-error">
        <FormattedMessage id="derogation.decision.refuser" />
      </span>
      <FormattedMessage id="derogation_de_vr.choix_vr.grille" />
    </>
  );
}

function LabelAccepter() {
  return (
    <>
      <span className="tag button-success">
        <FormattedMessage id="derogation.decision.accepter" />
      </span>
      <FormattedMessage id="derogation_de_vr.choix_vr.demandee" />
    </>
  );
}

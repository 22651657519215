import React from "react";
import PropTypes from "prop-types";
import "./BarreDeChargement.css";

const BarreDeChargement = ({ size = "L", testId }) => (
  <div
    className="spinner"
    style={{ height: SIZES[size].height, width: SIZES[size].width }}
    data-testid={testId}
  >
    <div className="rect1" style={{ width: SIZES[size].unitWidth }} />
    <div className="rect2" style={{ width: SIZES[size].unitWidth }} />
    <div className="rect3" style={{ width: SIZES[size].unitWidth }} />
    <div className="rect4" style={{ width: SIZES[size].unitWidth }} />
    <div className="rect5" style={{ width: SIZES[size].unitWidth }} />
  </div>
);

export const SIZES = {
  L: {
    height: "5.77rem",
    width: "3.85rem",
    unitWidth: ".46rem",
  },
  S: {
    height: "2.3rem",
    width: "2rem",
    unitWidth: ".23rem",
  },
  XS: {
    height: "1.54rem",
    width: "1.23rem",
    unitWidth: ".15rem",
  },
};

BarreDeChargement.propTypes = {
  size: PropTypes.oneOf(Object.keys(SIZES)),
};

export default BarreDeChargement;

import { connect } from "react-redux";
import Header from "./Header";
import { calculateur } from "../../../../reduxStore/rootReducer";
import {
  selectAvertissement,
  TYPES_AVERTISSEMENTS,
} from "../../domaine/avertissements/TypesAvertissements";
import { selectDureeEnMois } from "../../domaine/simulation/modeDeCalcul/reducer";
import { uneOperationEstEnCours } from "../../../../Ui/reducer";
import { ORCHESTRER_PLAN_DE_FINANCEMENT } from "../../domaine/planDeFinancement/actions";
import {
  montantDeLaNonValeur,
  montantDesDepenses,
  selectInfosDeMarge,
  tauxDeNonValeur,
} from "../../domaine/simulation/montantAFinancer/montants";
import { selectMarcheUtilisateur } from "../../domaine/simulation/profilUtilisateur/reducer";
import {
  COMMISSION_NULLE,
  commissionCommerciale,
} from "../../domaine/simulation/montantAFinancer/commissionCommerciale";
import { franchiseTroisMoisEstActivee } from "../../domaine/simulation/franchiseTroisMois/reducer";
import { selectClient } from "../../domaine/simulation/projetDeTravail/reducer";
import { selectAnomaliesDeTauxHorsGrille } from "../../domaine/anomalies/Anomalies";
import { selectPaliersLoyersFullService } from "../../domaine/planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

const {
  NON_VALEUR_ELEVEE,
  LOYER_SUPERIEUR_AU_SEUIL_ADMINISTRATION_FISCALE,
} = TYPES_AVERTISSEMENTS;

const mapStateToProps = (state) => {
  const {
    planDeFinancement,
    simulation,
    avertissements,
    anomalies,
  } = calculateur(state);

  return {
    anomalies: selectAnomaliesDeTauxHorsGrille(anomalies),
    simulation: simulation,

    montantDesDepenses: montantDesDepenses(
      simulation.montantAFinancer
    ).toObject(),

    infosMarge: selectInfosDeMarge(simulation.montantAFinancer),

    paliers: selectPaliersLoyersFullService(planDeFinancement),

    dureeEnMois: selectDureeEnMois(simulation.modeDeCalcul),

    tauxDeNonValeur: tauxDeNonValeur(simulation.montantAFinancer),

    montantDeNonValeur: montantDeLaNonValeur(
      simulation.montantAFinancer
    ).toObject(),

    avertissementNonValeurElevee: selectAvertissement(
      avertissements,
      NON_VALEUR_ELEVEE
    ),

    avertissementLoyerTropEleve: selectAvertissement(
      avertissements,
      LOYER_SUPERIEUR_AU_SEUIL_ADMINISTRATION_FISCALE
    ),

    calculDuPlanDeFinancementEnCours: uneOperationEstEnCours(state.ui, [
      ORCHESTRER_PLAN_DE_FINANCEMENT,
    ]),

    commissionCommerciale: franchiseTroisMoisEstActivee(
      simulation.franchiseTroisMois
    )
      ? COMMISSION_NULLE
      : commissionCommerciale(
          simulation.montantAFinancer,
          selectMarcheUtilisateur(simulation.profilUtilisateur),
          simulation.assurance,
          selectClient(simulation.projetDeTravail)
        ),

    franchiseTroisMoisEstActivee: franchiseTroisMoisEstActivee(
      simulation.franchiseTroisMois
    ),
  };
};

export default connect(mapStateToProps)(Header);

import { all, call, takeEvery } from "redux-saga/effects";
import { reporterSentry } from "./reporterSentry";
import { ERREUR_EST_SURVENUE } from "../Ui/actions";

export function* reportingErreurSaga(
  action,
  { reporter = reporterSentry } = {}
) {
  yield call({ fn: reporter.reportError, context: reporter }, action.erreur, {
    operation: action.operation,
    ...action.erreur.details,
  });
}

export default function* sagasDuReporting() {
  yield all([takeEvery(ERREUR_EST_SURVENUE, reportingErreurSaga)]);
}

import { httpServiceCrm } from "../../../../../../Connecteurs/Crm/httpServiceCrm";
import { formatyyyyMMdd } from "../../../../../../utils/dateUtils";
import { CODES_GROUPES_OPTIONS } from "../../../../../../Connecteurs/Crm/codesGroupesOptions";
import { STATUT_DEROGATION } from "../../../../../Derogations/domaine/reducer";
import { pourcentage } from "../../../../../../Kit/formatage";
import { identiteAdapterCrm } from "../../../../../../identiteUtilisateur/identite.adapter.crm";
import { crmSendEmail } from "../../../../../../Connecteurs/Crm/sendEmail";

const { statutDeDerogation } = CODES_GROUPES_OPTIONS;
const { A_DECIDER } = STATUT_DEROGATION;
const ID_TYPE_DEROGATION_DE_TEG =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_TEG;

const EMAIL_DEROGATEUR_PRINCIPAL =
  process.env.REACT_APP_DEROGATION_DE_TEG_EMAIL_DEROGATEUR_PRINCIPAL;

export const creerDerogationDeTegAdapterCrm = {
  async creerDerogationDeTeg(
    anomalieDeTeg,
    ft,
    projetDeTravail,
    timestampCreation,
    { http = httpServiceCrm } = {}
  ) {
    const derogation = await http.post(
      "itl_derogations?$select=itl_derogationid",
      {
        "itl_FTconcernee@odata.bind": `/itl_fichetransmissions(${ft.id})`,
        "itl_projetconcerne@odata.bind": `/itl_projets(${projetDeTravail.id})`,
        "itl_Typederogation@odata.bind": `/itl_typederogations(${ID_TYPE_DEROGATION_DE_TEG})`,
        itl_name: anomalieDeTeg.resume,
        itl_statutderogation: statutDeDerogation.get(A_DECIDER),
        itl_datedemande: formatyyyyMMdd(timestampCreation),
        itl_valeurorigine: pourcentage(anomalieDeTeg.details.tegGrille),
        itl_valeurdemandee: pourcentage(anomalieDeTeg.details.tegManuel),
        itl_commentairesdemandeur: anomalieDeTeg.motivation,
      }
    );

    return derogation.itl_derogationid;
  },

  async notifierDeNouvelleDemande(
    notification,
    idDerogation,
    { identiteAdapter = identiteAdapterCrm, envoyerEmail = crmSendEmail } = {}
  ) {
    const destinataire = await identiteAdapter.getIdUtilisateurFromEmail(
      EMAIL_DEROGATEUR_PRINCIPAL,
      "Dérogateur de TEG"
    );

    await envoyerEmail({
      sujet: notification.sujet,
      corps: notification.corps,
      destinataire: `/systemusers(${destinataire.idUtilisateur})`,
      regarding: {
        type: "itl_derogation",
        id: `/itl_derogations(${idDerogation})`,
      },
    });
  },
};

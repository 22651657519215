import { tauxDeVrGrille, tauxEffectifDeVr } from "./taux.selecteurs";
import { Money } from "../../../../../../Money/money";
import { MARCHES } from "../../../../../../identiteUtilisateur/reducer";
import { TYPE_DE_FINANCEMENT } from "../reducer";
import { labelCatalogue } from "../../catalogueProduits/reducer";
import { i18n } from "../../../../../../i18n/i18n";
import { selectMarcheChoisi } from "../../projetDeTravail/reducer";

const { ESPAGNE, FRANCE } = MARCHES;
const { LLD, CB } = TYPE_DE_FINANCEMENT;

export function selectInfosVr(state, produit) {
  const tauxGrille = tauxDeVrGrille(state, produit);
  const tauxDemande = state.tauxDemandes.parIdProduit[produit.id];
  const tauxEffectif = tauxEffectifDeVr(state, produit);
  const montant = Money(produit.montantUnitaire)
    .multiply(produit.quantite)
    .multiply(tauxEffectif || 0)
    .toObject();

  return { tauxGrille, tauxDemande, tauxEffectif, montant };
}

export const selectVrChargees = (state) => state.vrs;

export const typeDeFinancementActif = (state) => state.typeDeFinancement;

export const nomDuTypeDeFinancementActif = (state, marcheUtilisateur) => {
  const nomParMarche = {
    [FRANCE]: { [CB]: "CB", [LLD]: "LLD" },
    [ESPAGNE]: { [CB]: "CB", [LLD]: "Renting" },
  };
  return nomParMarche[marcheUtilisateur][typeDeFinancementActif(state)];
};

export function selectVrParMois(state, materiel, simulation) {
  const entreeDeVr = selectEntreeDeVr(state, materiel);

  if (entreeDeVr === undefined)
    throw ErreurVrIntrouvable(state, materiel, simulation);

  return entreeDeVr.vrParMois;
}

export function selectEntreeDeVr(state, materiel) {
  return state.vrs.find((vr) => {
    const familleOk = vr.idProduit === materiel.idFamille;
    const marqueOk = vr.idMarque === materiel.idMarque;
    return familleOk && marqueOk;
  });
}

function ErreurVrIntrouvable(state, materiel, simulation) {
  const intl = i18n.getIntl(state.marcheUtilisateur);
  const message = intl.formatMessage(
    { id: "calculateur.vr.introuvable" },
    {
      materiel: labelCatalogue(simulation.catalogueProduits, materiel),
      marche: selectMarcheChoisi(simulation.projetDeTravail).nom,
    }
  );
  return new Error(message);
}

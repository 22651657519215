export const ACTIVER_FRANCHISE_TROIS_MOIS = "ACTIVER_FRANCHISE_TROIS_MOIS";
export const activerFranchiseTroisMois = () => ({
  type: ACTIVER_FRANCHISE_TROIS_MOIS,
});

export const DESACTIVER_FRANCHISE_TROIS_MOIS =
  "DESACTIVER_FRANCHISE_TROIS_MOIS";
export const desactiverFranchiseTroisMois = () => ({
  type: DESACTIVER_FRANCHISE_TROIS_MOIS,
});

import React, { Component } from "react";
import { connect } from "react-redux";
import { chargerIdentiteUtilisateur_succes } from "../identiteUtilisateur/actions";
import { MARCHES } from "../identiteUtilisateur/reducer";
import { identiteUtilisateur } from "../reduxStore/rootReducer";

const { ESPAGNE, FRANCE } = MARCHES;

class MarcheUtilisateurSwitch extends Component {
  render() {
    return (
      <div className="flex-content-center mt-20">
        <button onClick={() => this.switchEtNavigue("/")}>ACCUEIL</button>
        <button onClick={() => this.switchEtNavigue("/derogations")}>
          DÉROGATIONS
        </button>
      </div>
    );
  }

  switchEtNavigue(urlCible) {
    const { identiteUtilisateur, history, match, dispatch } = this.props;
    const marche = match.params[0];

    const switchTo = (marcheUtilisateur) =>
      dispatch(
        chargerIdentiteUtilisateur_succes({
          ...identiteUtilisateur,
          marcheUtilisateur,
        })
      );

    if (marche === "espagne") switchTo(ESPAGNE);
    if (marche === "france") switchTo(FRANCE);

    history.push(urlCible);
  }
}

const mapStateToProps = (state) => ({
  identiteUtilisateur: identiteUtilisateur(state),
});

export default connect(mapStateToProps)(MarcheUtilisateurSwitch);

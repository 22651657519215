import { montantArrondi } from "../../../../../../Kit/formatage";
import { selectPaliers } from "../../../planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";
import { soustraireLeFacturePourCompte } from "../../../planDeFinancement/PlanDeFinancement/facturePourCompte";
import { fusionnerParFullService } from "../../../planDeFinancement/PlanDeFinancement/fusionDePaliers";

export function mapperLesLoyers(planDeFinancement) {
  const paliersForce = selectPaliers(planDeFinancement, {
    inclureFranchise: true,
  });

  const paliersFusionnes = fusionnerParFullService(
    soustraireLeFacturePourCompte(paliersForce)
  );

  return paliersFusionnes.map((palier, index) => mapperUnLoyer(palier, index));
}

function mapperUnLoyer(palier, index) {
  const rangEnCodeOption = 100_000_000 + index;
  return {
    loyerCrm: {
      itl_nombreecheance: palier.duree,
      itl_montant: montantArrondi(palier.loyer, 2),
      itl_montanthtsansfrais: montantArrondi(palier.loyer, 2),
      itl_fraisreglement: montantArrondi(palier.frais.montant, 2),
      itl_montanthtavecfrais: montantArrondi(palier.loyerAvecFrais, 2),
      itl_rangloyercode: rangEnCodeOption,
    },
    palierForce: palier,
  };
}

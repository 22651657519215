import { selectMarcheUtilisateur } from "../../simulation/profilUtilisateur/reducer";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";
import { AnomalieAssuranceEspagneDejaRefusee } from "../Anomalies";
import { selectDerogationAssuranceEspagne } from "../../simulation/derogations/reducer";
import { STATUT_DEROGATION } from "../../../../Derogations/domaine/reducer";
import { selectPrimeAssuranceEspagne } from "../../simulation/assurance/espagne/reducer";
import { Money } from "../../../../../Money/money";

const { ESPAGNE } = MARCHES;
const { REFUSEE } = STATUT_DEROGATION;

export function verifierAssuranceEspagneDejaRefusee(simulation) {
  const { assurance, profilUtilisateur } = simulation;

  if (pasEnEspagne(profilUtilisateur)) return;

  const derogation = selectDerogationAssuranceEspagne(simulation.derogations);
  if (derogation?.statut !== REFUSEE) return;

  const prime = selectPrimeAssuranceEspagne(assurance.espagne).annuelle.montant;
  const anterieure = derogation.valeurDemandee;

  const inferieureOuEgale = Money(prime).lessThanOrEqual(Money(anterieure));
  if (inferieureOuEgale) return AnomalieAssuranceEspagneDejaRefusee();
}

function pasEnEspagne(profilUtilisateur) {
  const marche = selectMarcheUtilisateur(profilUtilisateur);
  return marche !== ESPAGNE;
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { estUnMontantValide } from "../../../../../../Kit/Formulaires/InputMontant";
import Euros from "../../../../../../Kit/Finance/Euros";
import { pairePourcentageMontant } from "../../../../../../utils/pourcentageUtils";
import {
  $,
  $obj,
  forInput,
  Money,
  MoneyPropType,
} from "../../../../../../Money/money";

export function SaisieDeVrManuelle({
  valeurInitiale,
  valeurMini: { pourcentage: pourcentageMini, montant: montantMini },
  montantMateriel,
  checked,
  onChecked,
  onChange,
}) {
  const [pourcentage, setPourcentage] = useState(
    valeurInitiale?.pourcentage ?? ""
  );
  const [montant, setMontant] = useState(
    forInput(valeurInitiale?.montant) ?? ""
  );

  function resetSaisie() {
    setPourcentage("");
    setMontant("");
    onChange({ pourcentage: null, montant: null });
  }

  return (
    <div className="un-choix-de-vr vr-manuelle">
      <BoutonRadio
        checked={checked}
        onChange={() => {
          onChecked();
          resetSaisie();
        }}
      />
      <InputPourcentage
        afficherMontant={checked}
        pourcentage={pourcentage}
        onChange={({ target: { value } }) => {
          const val = value?.replace(",", ".");
          if (estUnMontantValide(val) && val > 0 && val < 100) {
            setPourcentage(val);
            onChecked();
          } else setPourcentage("");
        }}
        onBlur={() => {
          const tropBas = pourcentage <= pourcentageMini;
          const vide = pourcentage === "";

          if (tropBas || vide) {
            resetSaisie();
          } else {
            const paire = pairePourcentageMontant({
              pourcentage,
              base: montantMateriel,
            });
            onChange(paire);
            setMontant(forInput(paire.montant));
          }
        }}
      />
      <InputMontant
        afficherMontant={checked}
        montant={montant}
        onChange={({ target: { value } }) => {
          const val = value?.replace(",", ".");
          if (estUnMontantValide(val)) {
            setMontant(val);
            onChecked();
          } else setMontant("");
        }}
        onBlur={() => {
          const tropBas = $(montant).lessThanOrEqual(Money(montantMini));
          const vide = montant === "";

          if (tropBas || vide) {
            resetSaisie();
          } else {
            const paire = pairePourcentageMontant({
              montant: $obj(montant),
              base: montantMateriel,
            });
            onChange(paire);
            setPourcentage(paire.pourcentage);
          }
        }}
      />
    </div>
  );
}

SaisieDeVrManuelle.propTypes = {
  idDerogation: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChecked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  montantMateriel: MoneyPropType.isRequired,
  valeurMini: PropTypes.object.isRequired,
};

function BoutonRadio(props) {
  return (
    <div className="label-du-choix">
      <label>
        <input
          type="radio"
          name="valeur"
          checked={props.checked}
          onChange={props.onChange}
          className="mr-10"
        />
        <span className="tag button-warning">
          <FormattedMessage id="derogation.decision.accord_partiel" />
        </span>
        <FormattedMessage id="derogation_de_vr.choix_vr.saisie" />
      </label>
    </div>
  );
}

function InputPourcentage(props) {
  return (
    <div>
      <input
        type="text"
        value={props.afficherMontant ? props.pourcentage : ""}
        className="input-s border-radius-append-none"
        onChange={props.onChange}
        onBlur={props.onBlur}
        data-testid="input-pourcentage"
      />
      <div className="input-addon-append-item">%</div>
    </div>
  );
}

function InputMontant(props) {
  return (
    <div>
      <input
        type="text"
        value={props.afficherMontant ? props.montant : ""}
        className="input-s border-radius-append-none"
        onChange={props.onChange}
        onBlur={props.onBlur}
        data-testid="input-montant"
      />
      <div className="input-addon-append-item">
        <Euros />
      </div>
    </div>
  );
}

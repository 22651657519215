import { selectMarcheUtilisateur } from "../../../simulation/profilUtilisateur/reducer";
import {
  valeurDuMontantAFinancer,
  valeurDuMontantAFinancerAvecMargeManuelle,
} from "../../../simulation/montantAFinancer/montants";
import { montantTotalDeVr } from "../../../simulation/valeurResiduelle/selecteurs/montants.selecteurs";
import { listeDesProduitsAchetes } from "../../../simulation/montantAFinancer/reducer";
import { primeParLoyerDuMarche } from "../../../simulation/assurance/reducer";
import {
  selectTegEffectif,
  selectTegManuel,
} from "../../../simulation/tauxInteret/reducer";
import { selectTvaDuClient } from "../../../simulation/projetDeTravail/reducer";
import {
  selectDelaiDeReglement,
  selectModeDeReglement,
} from "../../../simulation/conditionsDeReglement/reducer";
import { selectMaintenance } from "../../../simulation/maintenance/reducer";
import { decouperPourIntegrerLaMaintenance } from "../../../simulation/maintenance/decoupeDesPaliers";

export function getParametresEffectifsPourLePlan(simulation) {
  const { tauxInteret, montantAFinancer, profilUtilisateur } = simulation;
  return {
    ...parametresCommuns(simulation),
    montantAFinancer: valeurDuMontantAFinancer(montantAFinancer).toObject(),
    teg: selectTegEffectif(
      tauxInteret,
      selectMarcheUtilisateur(profilUtilisateur)
    ),
  };
}

export function getParametresManuelsPourLePlan(simulation) {
  const { tauxInteret, montantAFinancer } = simulation;
  return {
    ...parametresCommuns(simulation),
    montantAFinancer: valeurDuMontantAFinancerAvecMargeManuelle(
      montantAFinancer
    ).toObject(),
    teg: selectTegManuel(tauxInteret),
  };
}

function parametresCommuns(simulation) {
  const { profilUtilisateur } = simulation;
  const marcheUtilisateur = selectMarcheUtilisateur(profilUtilisateur);

  const { valeurResiduelle, montantAFinancer, projetDeTravail } = simulation;
  const vr = montantTotalDeVr(
    valeurResiduelle,
    listeDesProduitsAchetes(montantAFinancer)
  );

  const { assurance } = simulation;
  const primeParLoyer = primeParLoyerDuMarche(assurance, marcheUtilisateur);

  const { conditionsDeReglement, maintenance } = simulation;

  return {
    assurance: { primeParLoyer: primeParLoyer.toObject() },
    delaiDeReglement: selectDelaiDeReglement(conditionsDeReglement),
    franchiseTroisMois: simulation.franchiseTroisMois,
    maintenance: parametreMaintenance(selectMaintenance(maintenance)),
    modeDeReglement: selectModeDeReglement(conditionsDeReglement),
    paliers: decouperPourIntegrerLaMaintenance(
      simulation.modeDeCalcul.paliers,
      selectMaintenance(maintenance)
    ),
    periodicite: simulation.modeDeCalcul.periodicite,
    terme: simulation.terme.choisi,
    tva: { tauxApplique: selectTvaDuClient(projetDeTravail) },
    vr: { montant: vr.toObject() },
  };
}

function parametreMaintenance(stateMaintenance) {
  // Seulement un sous-ensemble du state:
  // Les champs utilisés par les adapters, ou par PlanDeFinancement.
  // Rien de plus.
  return {
    estActivee: stateMaintenance.estActivee,
    montantTotal: stateMaintenance.montantTotal,
    montantParEcheance: stateMaintenance.montantParEcheance,
    dureeEnEcheances: stateMaintenance.dureeEnEcheances,
    nbEcheancesDeDecalage: stateMaintenance.nbEcheancesDeDecalage,
  };
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DELAIS_DE_REGLEMENT,
  selectDelaiDeReglement,
} from "../../domaine/simulation/conditionsDeReglement/reducer";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { changerDelaiDeReglement } from "../../domaine/simulation/conditionsDeReglement/actions";
import { orchestrerLePlanDeFinancement } from "../../domaine/planDeFinancement/actions";
import Select from "../../../../Kit/Formulaires/Select";

const {
  A_RECEPTION,
  TRENTE_JOURS,
  CINQUANTE_JOURS,
  SOIXANTE_JOURS,
  QUATREVINGTDIX_JOURS,
} = DELAIS_DE_REGLEMENT;

export const DelaiDeReglement = ({ className = "" }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const delaiChoisi = useSelector((state) => {
    const { simulation } = calculateur(state);
    const actif = selectDelaiDeReglement(simulation.conditionsDeReglement);
    return delais(intl).find((d) => d.value.id === actif);
  });

  return (
    <div className={`flex-dir-col w-115 ${className}`}>
      <div className="text-bold">
        <FormattedMessage id="calculateur.conditions_de_reglement.delai" />
      </div>
      <div className="p-2-0">
        <Select
          options={delais(intl)}
          onChange={({ value }) => {
            dispatch(changerDelaiDeReglement(value.id));
            dispatch(orchestrerLePlanDeFinancement());
          }}
          value={delaiChoisi}
          className="p-2-0"
        />
      </div>
    </div>
  );
};

DelaiDeReglement.propTypes = {
  delaiChoisi: PropTypes.oneOf(Object.keys(DELAIS_DE_REGLEMENT)),
  className: PropTypes.string,
};

function delais(intl) {
  return [
    delai(A_RECEPTION, intl),
    delai(TRENTE_JOURS, intl),
    delai(CINQUANTE_JOURS, intl),
    delai(SOIXANTE_JOURS, intl),
    delai(QUATREVINGTDIX_JOURS, intl),
  ];
}

function delai(value, intl) {
  const id = `calculateur.conditions_de_reglement.options.${LABELS[value]}`;
  return { value: { id: value }, label: intl.formatMessage({ id }) };
}

const LABELS = {
  [A_RECEPTION]: "a_reception",
  [TRENTE_JOURS]: "30_j",
  [CINQUANTE_JOURS]: "50_j",
  [SOIXANTE_JOURS]: "60_j",
  [QUATREVINGTDIX_JOURS]: "90_j",
};

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import DerogationDeMarge from "../DerogationDeMarge/DerogationDeMarge.container";
import BadgeErreur from "../../../../Kit/Badges/Erreur/BadgeErreur";
import { TYPE_DE_DEROGATION } from "../../domaine/reducer";
import DerogationDeTeg from "../DerogationDeTeg/DerogationDeTeg.container";
import { RedirectVersDerogationsParProjet } from "../DerogationsDeVrParProjet/RedirectVersDerogationsParProjet";
import { DerogationAssuranceEspagne } from "../DerogationAssuranceEspagne/DerogationAssuranceEspagne";

const { MARGE, VR, TEG, ASSURANCE_ESPAGNE } = TYPE_DE_DEROGATION;

function AiguillageVersLeDetail({ demande, isLoading, erreurs }) {
  return (
    <div className="container">
      {erreurs.length > 0 ? (
        <div style={{ margin: "30px auto" }}>
          <BadgeErreur messages={erreurs} />
        </div>
      ) : isLoading ? (
        <div className="mt-20">
          <BarreDeChargement />
        </div>
      ) : demande.type === MARGE ? (
        <DerogationDeMarge demande={demande} />
      ) : demande.type === VR ? (
        <RedirectVersDerogationsParProjet idDerogation={demande.id} />
      ) : demande.type === TEG ? (
        <DerogationDeTeg derogation={demande} />
      ) : demande.type === ASSURANCE_ESPAGNE ? (
        <DerogationAssuranceEspagne derogation={demande} />
      ) : (
        <Fragment />
      )}
    </div>
  );
}

AiguillageVersLeDetail.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  erreurs: PropTypes.array.isRequired,
};

export default AiguillageVersLeDetail;

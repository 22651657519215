import { all, put, select, takeEvery } from "redux-saga/effects";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import {
  PAS_DE_PRIME_ASSURANCE,
  selectAssuranceEspagneEstAjoutee,
} from "./reducer";
import {
  definirPrimeAssuranceEspagneManuelle,
  definirPrimeMinimaleAssuranceEspagne,
} from "./actions";
import { SUPPRIMER_PRODUIT } from "../../montantAFinancer/actions";
import { listeDesProduitsAchetes } from "../../montantAFinancer/reducer";
import { getMarcheUtilisateur } from "../../profilUtilisateur/sagas";
import { MARCHES } from "../../../../../../identiteUtilisateur/reducer";
import { $obj } from "../../../../../../Money/money";

const { ESPAGNE } = MARCHES;

export function* retirerAssuranceSiAucunMaterielNeufRestantSaga() {
  yield takeEvery(SUPPRIMER_PRODUIT, function* () {
    const assuranceActive = yield* assuranceEspagneEstActive();

    const { simulation } = yield select(calculateur);
    const produits = listeDesProduitsAchetes(simulation.montantAFinancer);
    const aucunNeufRestant = !produits.find((p) => !p.estOccasion);

    if (assuranceActive && aucunNeufRestant)
      yield put(definirPrimeAssuranceEspagneManuelle(PAS_DE_PRIME_ASSURANCE));
  });
}

function* assuranceEspagneEstActive() {
  const {
    simulation: { assurance },
  } = yield select(calculateur);

  const assuranceAjoutee = selectAssuranceEspagneEstAjoutee(assurance.espagne);
  const marcheUtilisateur = yield* getMarcheUtilisateur();

  return assuranceAjoutee && marcheUtilisateur === ESPAGNE;
}

export function* chargerPrimeMinimaleAssuranceEspagneSaga() {
  const montant =
    process.env.REACT_APP_MONTANT_PRIME_MINIMALE_ASSURANCE_ESPAGNE;

  yield put(
    definirPrimeMinimaleAssuranceEspagne({
      annuelle: { montant: $obj(montant) },
    })
  );
}

export default function* sagasDeAssurance() {
  yield all([retirerAssuranceSiAucunMaterielNeufRestantSaga()]);
}

import { selectMarcheUtilisateur } from "../../../simulation/profilUtilisateur/reducer";
import { montantTotalDeVr } from "../../../simulation/valeurResiduelle/selecteurs/montants.selecteurs";
import { listeDesProduitsAchetes } from "../../../simulation/montantAFinancer/reducer";
import { primeParLoyerDuMarche } from "../../../simulation/assurance/reducer";
import { selectTegEffectif } from "../../../simulation/tauxInteret/reducer";

export function getParametresDuPlan(simulation, fraisDeReglement) {
  const marcheUtilisateur = selectMarcheUtilisateur(
    simulation.profilUtilisateur
  );

  const montantVr = montantTotalDeVr(
    simulation.valeurResiduelle,
    listeDesProduitsAchetes(simulation.montantAFinancer)
  );

  const primeAssuranceParLoyer = primeParLoyerDuMarche(
    simulation.assurance,
    marcheUtilisateur
  );

  // Jamais de maintenance en calcul Investissement.
  // Maintenance + calcul Invest. est un scenario qu'on ne souhaite pas supporter.
  // Ça ajouterait trop de complexité, et le besoin n'a pas été remonté.
  // Dans l'UI, <NatureDuCalcul /> ne permet de toute façon pas de passer en Invest.
  // s'il y a de la maintenance.
  const maintenance = { estActivee: false };

  return {
    vr: { montant: montantVr.toObject() },
    teg: selectTegEffectif(simulation.tauxInteret, marcheUtilisateur),
    franchiseTroisMois: simulation.franchiseTroisMois,
    assurance: { primeParLoyer: primeAssuranceParLoyer.toObject() },
    fraisDeReglement,
    maintenance,
  };
}

import { selectProduitAchete } from "../../../montantAFinancer/reducer";
import { Money } from "../../../../../../../Money/money";
import { selectDureeEnMois } from "../../../modeDeCalcul/reducer";
import { dureeDeReferenceEnMois } from "../../../chaineDeLoyersDeReference/reducer";
import {
  InvaliditeDureeDeFinancementAugmente,
  InvaliditeTauxManuelDepasse,
  InvaliditeTropForteHausseDuProduit,
  InvaliditeTypeDeFinancementModifie,
} from "./Invalidites";
import {
  tauxDeVrDemande,
  tauxSeuilDerogation,
} from "../../../valeurResiduelle/selecteurs/taux.selecteurs";
import { typeDeFinancementActif } from "../../../valeurResiduelle/selecteurs/generalites.selecteurs";
import { estRefuseeCategoriquement } from "../../reducer";

export function selectInvaliditesDerogationDeVr(derogation, simulation) {
  const derogationNonStatuee = !derogation.aRecuDecision;
  if (derogationNonStatuee) return [];

  // Les dérogations fictives du mode light sont toujours valides
  // pour pouvoir calculer le plan de financement peu importe
  // la valeur saisie par l'utilisateur.
  if (derogation.estFictive) return [];

  return [
    controles.augmentationTropForteDuProduit(derogation, simulation),
    controles.dureeDeFinancementAugmentee(derogation, simulation),
    controles.tauxManuelAuDelaDeDerogation(derogation, simulation),
    controles.typeDeFinancementModifie(derogation, simulation),
  ].filter((i) => i !== undefined);
}

const controles = {
  augmentationTropForteDuProduit(derogation, simulation) {
    const produit = selectProduitAchete(
      simulation.montantAFinancer,
      derogation.produit.id
    );
    const montantActuelDuProduit = Money(produit.montantUnitaire);
    const TAUX_D_AUGMENTATION_TOLEREE = 0.1;
    const montantMaxAutorise = Money(
      derogation.produit.montantUnitaire
    ).multiply(1 + TAUX_D_AUGMENTATION_TOLEREE);

    if (montantActuelDuProduit.greaterThan(montantMaxAutorise))
      return InvaliditeTropForteHausseDuProduit(
        montantActuelDuProduit,
        montantMaxAutorise
      );
  },

  dureeDeFinancementAugmentee(derogation, simulation) {
    const dureeActuelle = selectDureeEnMois(simulation.modeDeCalcul);
    const dureeDeReference = dureeDeReferenceEnMois(
      simulation.chaineDeLoyersDeReference
    );
    const dureeEstAugmentee = dureeActuelle > dureeDeReference;

    const { produit } = derogation;
    const { valeurResiduelle } = simulation;
    const vrDemandee = tauxDeVrDemande(valeurResiduelle, produit);
    const seuilSurDureeActuelle = tauxSeuilDerogation(
      valeurResiduelle,
      produit
    );
    const depasseLeSeuilSurLaNouvelleDuree = vrDemandee > seuilSurDureeActuelle;

    if (dureeEstAugmentee && depasseLeSeuilSurLaNouvelleDuree)
      return InvaliditeDureeDeFinancementAugmente();
  },

  tauxManuelAuDelaDeDerogation(derogation, simulation) {
    const { valeurResiduelle } = simulation;

    const tauxDemande = tauxDeVrDemande(valeurResiduelle, derogation.produit);
    if (!tauxDemande) return;

    if (estRefuseeCategoriquement(derogation)) return;

    const maximumAutorise = derogation.valeurBroker;
    if (!tauxEstCouvert(maximumAutorise, tauxDemande))
      return InvaliditeTauxManuelDepasse(maximumAutorise);
  },

  typeDeFinancementModifie(derogation, simulation) {
    if (!derogation.ft) return;

    if (
      derogation.ft.typeDeFinancement !==
      typeDeFinancementActif(simulation.valeurResiduelle)
    )
      return InvaliditeTypeDeFinancementModifie();
  },
};

export function tauxEstCouvert(tauxMaximumAutorise, tauxDemande) {
  const precision = 5;
  const maxAvecTolerance = (tauxMaximumAutorise + 0.00001).toFixed(precision);
  const demandeAvecPrecision = tauxDemande.toFixed(precision);
  return maxAvecTolerance >= demandeAvecPrecision;
}

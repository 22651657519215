const CORPS = `Bonjour, <br />
Une nouvelle demande de <b>Dérogation de Marge</b> a été soumise.<br />
<ul>
  <li><b>Demandeur : </b>{demandeur}</li>
  <li><b>Projet : </b>{projet}</li>
  <li><b>Client : </b>{client}</li>
  <li><b>FT : </b>{ft}</li>
  <li><b>Montant à financer : </b>{montantAFinancer}</li>
  <li><b>VR : </b>{vr}</li>
  <li><b>Taux de marge demandé : </b>{tauxDeMargeDemande}</li>
  <li><b>Taux de marge en autonomie : </b>{tauxDeMargeEnAutonomie}</li>
  <li><b>Motivation de la demande : </b>{motivation}</li>
</ul>
<br />
Pour prendre votre décision, cliquez sur le bouton ci-dessous :
<a
  href="{urlOuvrirDerogation}"
  style="
    display: block;
    width: 300px;
    text-align: center;
    margin: 10px;
    color: white;
    text-decoration: none;
    background-color: #0078e7;
    padding: 10px;
    border-radius: 7px;
  "
>
  Détail de la demande
</a>
`;

const SUJET =
  "[Fiche de Transmission] Nouvelle demande de dérogation de Marge - {demandeur}";

export const ACTION_SUR_DEROGATION_DE_MARGE_FR = { CORPS, SUJET };

export const OPERATION_EN_COURS = "UI/OPERATION_EN_COURS";
export const operationEnCours = (operation) => ({
  type: OPERATION_EN_COURS,
  operation,
});

export const OPERATION_TERMINEE = "UI/OPERATION_TERMINEE";
export const operationTerminee = (operation) => ({
  type: OPERATION_TERMINEE,
  operation,
});

export const ERREUR_EST_SURVENUE = "UI/ERREUR_EST_SURVENUE";
export const erreurEstSurvenue = (operation, erreur) => ({
  type: ERREUR_EST_SURVENUE,
  operation,
  erreur,
});

export const EFFACER_ERREUR = "UI/EFFACER_ERREUR";
export const effacerErreur = (operation) => ({
  type: EFFACER_ERREUR,
  operation,
});

export const OPERATION_SUCCES = "UI/OPERATION_SUCCES";
export const operationSucces = (operation, idEntite) => ({
  type: OPERATION_SUCCES,
  operation,
  idEntite,
});

export const SUPPRIMER_OPERATION_SUCCES = "UI/SUPPRIMER_OPERATION_SUCCES";
export const supprimerOperationSucces = (operation, idEntite) => ({
  type: SUPPRIMER_OPERATION_SUCCES,
  operation,
  idEntite,
});

export const SUPPRIMER_TOUS_LES_SUCCES = "SUPPRIMER_TOUS_LES_SUCCES";
export const supprimerTousLesSucces = (operation) => ({
  type: SUPPRIMER_TOUS_LES_SUCCES,
  operation,
});

export const DEFINIR_MODE_AFFICHAGE = "DEFINIR_MODE_AFFICHAGE";
export const definirModeAffichage = (modeAffichage) => ({
  type: DEFINIR_MODE_AFFICHAGE,
  modeAffichage,
});

export const DEMARRER_PROGRESS_BAR = "UI/DEMARRER_PROGRESS_BAR";
export const demarrerProgressBar = (operationMonitoree, max) => ({
  type: DEMARRER_PROGRESS_BAR,
  operationMonitoree,
  max,
});

export const AVANCER_PROGRESS_BAR = "UI/AVANCER_PROGRESS_BAR";
export const avancerProgressBar = (operationMonitoree, description) => ({
  type: AVANCER_PROGRESS_BAR,
  operationMonitoree,
  description,
});

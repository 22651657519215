import {
  DEFINIR_PRIME_ASSURANCE_FRANCE,
  RECUPERER_PRODUIT_ASSURANCE_FRANCE_SUCCES,
  RETIRER_PRIME_ASSURANCE_FRANCE,
  SUPPRIMER_PRODUIT_ASSURANCE_FRANCE,
} from "./actions";
import { SUPPRIMER_PRODUIT } from "../../montantAFinancer/actions";
import { Money, Zero } from "../../../../../../Money/money";
import { CHANGER_LA_PERIODICITE } from "../../modeDeCalcul/actions";

const STATE_INITIAL = {
  produitsAssurance: { parMateriel: {} },
  primesAssurance: { parMateriel: {} },
};

export const OPTION_ASSURANCE = {
  PERTE_DE_RECETTES: "PERTE_DE_RECETTES",
  GARANTIE_DES_LOYERS: "GARANTIE_DES_LOYERS",
};
export const AUCUNE_OPTION = null;

export default function reducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case RECUPERER_PRODUIT_ASSURANCE_FRANCE_SUCCES:
      const { materiel, produitAssurance } = action;
      return setProduitAssurance(state, materiel.id, produitAssurance);

    case SUPPRIMER_PRODUIT_ASSURANCE_FRANCE:
      return supprimerProduitAssurance(state, action.materiel.id);

    case DEFINIR_PRIME_ASSURANCE_FRANCE:
      return setPrimeAssurance(state, action.materiel.id, action.prime);

    case RETIRER_PRIME_ASSURANCE_FRANCE:
      return retirerPrimeAssurance(state, action.materiel.id);

    case SUPPRIMER_PRODUIT:
      const sansPrime = retirerPrimeAssurance(state, action.idProduit);
      return supprimerProduitAssurance(sansPrime, action.idProduit);

    case CHANGER_LA_PERIODICITE:
      return { ...state, primesAssurance: STATE_INITIAL.primesAssurance };

    default:
      return state;
  }
}

function setPrimeAssurance(state, idMateriel, prime) {
  return {
    ...state,
    primesAssurance: {
      parMateriel: {
        ...state.primesAssurance.parMateriel,
        [idMateriel]: prime,
      },
    },
  };
}

function setProduitAssurance(state, idMateriel, produitAssurance) {
  return {
    ...state,
    produitsAssurance: {
      parMateriel: {
        ...state.produitsAssurance.parMateriel,
        [idMateriel]: produitAssurance,
      },
    },
  };
}

function retirerPrimeAssurance(state, idMateriel) {
  return setPrimeAssurance(state, idMateriel, PAS_DE_PRIME_ASSURANCE);
}

function supprimerProduitAssurance(state, idProduit) {
  return setProduitAssurance(state, idProduit, PRODUIT_ASSURANCE_INDISPONIBLE);
}

export const PRODUIT_ASSURANCE_INDISPONIBLE = "PRODUIT_ASSURANCE_INDISPONIBLE";
export const selectProduitAssurance = (state, materiel) =>
  state.produitsAssurance.parMateriel[materiel.id] ||
  PRODUIT_ASSURANCE_INDISPONIBLE;

export const PAS_DE_PRIME_ASSURANCE = "PAS_DE_PRIME_ASSURANCE";
export const selectPrimeAssurance = (state, materiel) =>
  state.primesAssurance.parMateriel[materiel.id] || PAS_DE_PRIME_ASSURANCE;

export function montantPrimeAssuranceFranceParLoyer(state) {
  return toutesLesPrimes(state)
    .map((p) => Money(p.parLoyer.montant))
    .reduce((total, montant) => total.add(montant), Zero);
}

export function montantPrimeAssuranceFranceAnnuelle(state) {
  return toutesLesPrimes(state)
    .map((p) => Money(p.annuelle.montant))
    .reduce((total, montant) => total.add(montant), Zero);
}

export function auMoinsUneAssurance(state) {
  return toutesLesPrimes(state).length > 0;
}

function toutesLesPrimes(state) {
  return Object.values(state.primesAssurance.parMateriel).filter(
    (p) => p !== PAS_DE_PRIME_ASSURANCE
  );
}

export function getMontantAAssurer(materiel, client) {
  return Money(materiel.montantUnitaire)
    .multiply(materiel.quantite)
    .multiply(1 + (client.tva.recupereLaTva ? 0 : client.tva.tauxApplique));
}

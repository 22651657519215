import { selectMaintenance } from "../../simulation/maintenance/reducer";
import { selectNombreEcheances } from "../../simulation/modeDeCalcul/reducer";
import { AnomalieMaintenanceTropLongue } from "../Anomalies";

export function verifierLaMaintenanceTropLongue(simulation) {
  const maintenance = selectMaintenance(simulation.maintenance);
  if (!maintenance.estActivee) return;

  const nbLimiteEcheances = selectNombreEcheances(simulation.modeDeCalcul);

  const dureeMaintenance =
    maintenance.dureeEnEcheances + maintenance.nbEcheancesDeDecalage;

  if (dureeMaintenance > nbLimiteEcheances)
    return AnomalieMaintenanceTropLongue();
}

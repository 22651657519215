import { Money, Zero } from "../../../../Money/money";

export const nombreEcheances = (paliers) =>
  paliers.reduce((nombreTotal, palier) => nombreTotal + palier.duree, 0);

export const loyerMaximum = (paliers) => {
  if (paliers.length === 0) return Zero.toObject();

  const palierMax = paliers.reduce((max, palier) =>
    Money(palier.loyer).greaterThan(Money(max.loyer)) ? palier : max
  );

  return palierMax.loyer;
};

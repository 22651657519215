import { call, select } from "redux-saga/effects";
import { chargerDerniereNotificationDeVrSaga } from "../../../../simulation/derogations/sagas";
import {
  calculateur,
  choixDuProjet,
} from "../../../../../../../reduxStore/rootReducer";
import { mapperLesMateriels } from "./materiels.mapping";
import { selectNomPrenomUtilisateur } from "../../../../simulation/profilUtilisateur/reducer";
import { crmSendEmail } from "../../../../../../../Connecteurs/Crm/sendEmail";
import { selectDureeEnMois } from "../../../../simulation/modeDeCalcul/reducer";
import { notificationDeNouvelleDerogationDeVr } from "./derogationDeVr.notifications";
import { periodeDeSilenceEnCours } from "../../../../simulation/derogations/reducer";
import { assurerDepenseSaga } from "../../assurance/assurance.sagas";
import { getMarcheUtilisateur } from "../../../../simulation/profilUtilisateur/sagas";
import { Adapters } from "../../../../../../../reduxStore/adapters";
import { typeDeFinancementActif } from "../../../../simulation/valeurResiduelle/selecteurs/generalites.selecteurs";
import { selectBrokerDuMarche } from "../../../../simulation/projetDeTravail/reducer";

export function* creerDepensesDeMaterielsSaga(
  ft,
  timestampCreation,
  { materielsAdapter = Adapters.materielsAdapter } = {}
) {
  // On charge la dernière notification de VR ici, car si on la charge plus tard
  // on se retrouve à charger celle que la présente saga va créer.
  // Le chargement de la notif sert à savoir si on est dans la période de silence des notifications.
  // C'est un peu bancal de devoir charger ici...mais c'est ce que le design actuel permet de mieux.
  yield call(chargerDerniereNotificationDeVrSaga);

  const { projetDeTravail } = yield select(choixDuProjet);
  const c = yield select(calculateur);

  const tousLesMateriels = yield call(mapperLesMateriels, c);

  for (const materielCrm of tousLesMateriels) {
    const depenseCreee = yield call(
      materielsAdapter.creerDepense,
      materielCrm,
      ft.id,
      projetDeTravail.id,
      timestampCreation
    );

    yield call(assurerDepenseSaga, materielCrm.produit, depenseCreee);
  }

  const { doitNotifier, depensesDerogees } = yield call(
    notificationEstRequise,
    tousLesMateriels
  );

  if (doitNotifier) yield call(notifier, depensesDerogees, ft);
}

export function* notificationEstRequise(depenses) {
  const { simulation } = yield select(calculateur);
  const dejaEtePrevenu = periodeDeSilenceEnCours(simulation.derogations);
  if (dejaEtePrevenu) return { doitNotifier: false };

  const depensesAvecNouvellesDerogationsDeVr = depenses.filter(
    d =>
      d.derogationDeVr &&
      d.derogationDeVr.depuisUneDerogationAnterieure === false
  );
  const auMoinsUneNouvelle = depensesAvecNouvellesDerogationsDeVr.length > 0;
  const doitNotifier = auMoinsUneNouvelle;

  return {
    depensesDerogees: depensesAvecNouvellesDerogationsDeVr,
    doitNotifier,
  };
}

export function* notifier(
  depensesAvecDerogationDeVr,
  ftConcernee,
  {
    envoyerEmail = crmSendEmail,
    identiteAdapter = Adapters.identiteAdapter,
  } = {}
) {
  const { simulation } = yield select(calculateur);
  const { projetDeTravail } = yield select(choixDuProjet);

  const { valeurResiduelle, profilUtilisateur, modeDeCalcul } = simulation;
  const typeDeFinancement = typeDeFinancementActif(valeurResiduelle);

  const { sujet, corps } = yield call(
    notificationDeNouvelleDerogationDeVr,
    depensesAvecDerogationDeVr,
    selectNomPrenomUtilisateur(profilUtilisateur),
    selectDureeEnMois(modeDeCalcul),
    { ...ftConcernee, client: projetDeTravail.client, typeDeFinancement }
  );

  const { destinatairesCC, destinataire } = yield* getDestinataires(
    simulation,
    { identiteAdapter }
  );

  yield call(envoyerEmail, {
    sujet,
    corps,
    destinataire,
    destinatairesCC,
    regarding: {
      type: "itl_fichetransmission",
      id: `/itl_fichetransmissions(${ftConcernee.id})`,
    },
    piecesJointes: depensesAvecDerogationDeVr
      .map(d => d.derogationDeVr.piecesJointes)
      .flat(),
  });
}

function* getDestinataires(simulation, { identiteAdapter }) {
  const { projetDeTravail } = simulation;

  const broker = selectBrokerDuMarche(projetDeTravail);
  const destinataire = `/systemusers(${broker.idUtilisateur})`;
  const destinatairesCC = [`/systemusers(${broker.adv.idUtilisateur})`];

  const marcheUtilisateur = yield* getMarcheUtilisateur();
  const advDesCommerciaux = yield call(
    identiteAdapter.getAdvDesCommerciaux,
    marcheUtilisateur
  );
  advDesCommerciaux.forEach(adv =>
    destinatairesCC.push(`/systemusers(${adv.idUtilisateur})`)
  );

  return { destinatairesCC, destinataire };
}

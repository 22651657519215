export function requeteOData(entite, champs, filtre) {
  let requete = `${entite}?$select=${champs}`;
  if (filtre) requete += `&$filter=${filtre}`;

  return requete;
}

export function odataQuery(entite, specs) {
  const toutesLesSpecs = specsToString(specs);

  return `${entite}?${toutesLesSpecs.join("&")}`;
}

function specsToString(specs) {
  const constructeurs = {
    $select: (champs) => `$select=${champs.join(",")}`,
    $filter: (filtres) => `$filter=${filtres.join(" and ")}`,
    $expand: (expand) => {
      const elements = enTableau(expand);

      const details = elements.map(
        ([entite, { $select, $expand: [innerEntite, innerValue] = [] }]) => {
          const selectDirect = $select.join(",");
          // Inner expand est supporté, si on ajoute un ";"
          // https://community.dynamics.com/crm/b/crminthefield/posts/nested-expansion-using-the-web-api
          const innerExpand = innerEntite
            ? `;$expand=${innerEntite}($select=${innerValue.$select.join(",")})`
            : "";
          return `${entite}($select=${selectDirect}${innerExpand})`;
        }
      );

      return `$expand=${details}`;
    },
    $orderBy: (champs) =>
      `$orderby=${champs
        .map(([champ, ordre]) => `${champ} ${ordre}`)
        .join(",")}`,
    $top: (nombre) => `$top=${nombre}`,
  };

  return Object.entries(specs).map(
    ([nom, valeur]) => `${constructeurs[nom](valeur)}`
  );
}

function enTableau(expand) {
  const expandUnique = typeof expand[0] === "string";
  return expandUnique ? [expand] : expand;
}

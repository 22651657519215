import { connect } from "react-redux";
import DerogationDeMarge from "./DerogationDeMarge";
import {
  erreurDeOperation,
  uneOperationEstEnCours,
} from "../../../../Ui/reducer";
import {
  ACCEPTER_DEROGATION_DE_MARGE,
  accepterDerogationDeMarge,
  REFUSER_DEROGATION_DE_MARGE,
  refuserDerogationDeMarge,
} from "../../domaine/typesDerogations/marge/actions";
import { permissionSurDerogation } from "../../domaine/decision/permissionSurDerogation";
import {
  identiteUtilisateur,
  referentiel,
} from "../../../../reduxStore/rootReducer";
import { Adapters } from "../../../../reduxStore/adapters";
import {
  MARCHES,
  POSTE_UTILISATEUR,
  selectMarcheUtilisateur,
  selectUtilisateurALePoste,
} from "../../../../identiteUtilisateur/reducer";

const { FRANCE } = MARCHES;
const { DIRECTEUR_COMMERCIAL } = POSTE_UTILISATEUR;

const mapStateToProps = (state, ownProps) => {
  const i = identiteUtilisateur(state);

  return {
    isLoading: uneOperationEstEnCours(state.ui, [
      ACCEPTER_DEROGATION_DE_MARGE,
      REFUSER_DEROGATION_DE_MARGE,
    ]),

    demande: ownProps.demande,

    erreurs: erreurDeOperation(state.ui, [
      ACCEPTER_DEROGATION_DE_MARGE,
      REFUSER_DEROGATION_DE_MARGE,
    ]),

    decisionEstAutorisee: permissionSurDerogation.peutDecider(
      ownProps.demande,
      i,
      referentiel(state)
    ),

    recalculDeMargeEstVisible:
      selectMarcheUtilisateur(i) === FRANCE &&
      selectUtilisateurALePoste(i, DIRECTEUR_COMMERCIAL),

    recalculerLaMarge: Adapters.derogationDeMargeAdapter.recalculerMargeDeFt,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAccepter: (derogation, decision, intl) =>
    dispatch(
      accepterDerogationDeMarge(
        derogation,
        { ...decision, dateDecision: Date.now() },
        intl
      )
    ),
  onRefuser: (derogation, decision, intl) =>
    dispatch(
      refuserDerogationDeMarge(
        derogation,
        { ...decision, dateDecision: Date.now() },
        intl
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DerogationDeMarge);

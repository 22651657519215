import { combineReducers } from "redux";
import calculateurReducer from "../App/Calculateur/domaine/reducer";
import avecInstantanes, {
  timestampRestaurationInstantane,
} from "./enhancers/instantanes/avecInstantanes";
import ui from "../Ui/reducer";
import quiCatchLesErreurs, {
  AUCUN_CATCH,
  timestampEffacementErreur,
} from "./enhancers/catchErreur/quiCatchLesErreurs";
import referentielReducer from "../referentiel/reducer";
import derogationsReducer from "../App/Derogations/domaine/reducer";
import identiteUtilisateurReducer from "../identiteUtilisateur/reducer";
import { reporterSentry } from "../reporting/reporterSentry";
import { modeLightReducer } from "../App/ModeLight/domaine/reducer";

const reporter = reporterSentry;
const reportErrorDansSentry = reporter.reportError.bind(reporter);

const limiteDesInstantanes = process.env.REACT_APP_INSTANTANE_LIMITE_STOCKAGE;

const rootReducer = () =>
  combineReducers({
    calculateur: quiCatchLesErreurs(
      avecInstantanes(calculateurReducer, Number(limiteDesInstantanes)),
      reportErrorDansSentry
    ),
    ui: ui,
    referentiel: referentielReducer,
    derogations: derogationsReducer,
    modeLight: modeLightReducer,
    identiteUtilisateur: identiteUtilisateurReducer,
  });
export default rootReducer;

// Sélecteurs sur nos reducers, pour avoir accès direct à des morceaux de state métier,
// sans avoir à se soucier des middlewares comme "avecInstantanes" ou "quiCatchLesErreurs"
export const calculateur = (stateGlobal) =>
  stateGlobal.calculateur.etatStable.actif;
export const referentiel = (stateGlobal) => stateGlobal.referentiel;
export const identiteUtilisateur = (stateGlobal) =>
  stateGlobal.identiteUtilisateur;
export const choixDuProjet = (stateGlobal) =>
  calculateur(stateGlobal).simulation.projetDeTravail;
export const derogations = (stateGlobal) => stateGlobal.derogations;
export const modeLight = (stateGlobal) => stateGlobal.modeLight;

export const lesInstantanesCalculateur = (stateGlobal) =>
  stateGlobal.calculateur.etatStable.instantanes;

export const cleCalculateur = (stateGlobal) => {
  const cles = [
    timestampRestaurationInstantane(stateGlobal.calculateur.etatStable) || null,
    timestampEffacementErreur(stateGlobal.calculateur) || null,
  ].filter((c) => c !== null);

  return cles.join("-");
};

export const erreurCatchee = (stateGlobal) => {
  const pasDerreur = stateGlobal.calculateur.catch === AUCUN_CATCH;
  if (pasDerreur) return {};
  else return stateGlobal.calculateur.catch.erreur;
};

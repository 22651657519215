import DerogationsDeVrParProjet from "./DerogationsDeVrParProjet";
import { connect } from "react-redux";
import {
  erreurDeOperation,
  uneOperationEstEnCours,
} from "../../../../Ui/reducer";
import {
  CHARGER_DEROGATIONS_DE_VR_PAR_PROJET,
  chargerDerogationsDeVrParProjet,
} from "../../domaine/actions";
import {
  AVIS_BROKER_DEROGATION_DE_VR,
  avisBrokerDerogationVr,
} from "../../domaine/typesDerogations/vr/actions";
import { selectDerogationsDeVrParProjet } from "../../domaine/reducer";
import { derogationDeVrAdapterCrm } from "../../domaine/typesDerogations/vr/derogationDeVr.adapter.crm";
import { permissionSurDerogation } from "../../domaine/decision/permissionSurDerogation";
import {
  identiteUtilisateur,
  referentiel,
} from "../../../../reduxStore/rootReducer";

const mapStateToProps = (state, ownProps) => {
  const projet = selectDerogationsDeVrParProjet(
    state.derogations,
    ownProps.idProjet
  );
  const derogationsSontChargees = projet.derogations.deVr.length > 0;

  return {
    isLoading: uneOperationEstEnCours(state.ui, [
      CHARGER_DEROGATIONS_DE_VR_PAR_PROJET,
      AVIS_BROKER_DEROGATION_DE_VR,
    ]),
    erreurs: erreurDeOperation(state.ui, [
      CHARGER_DEROGATIONS_DE_VR_PAR_PROJET,
      AVIS_BROKER_DEROGATION_DE_VR,
    ]),
    projet: projet,
    decisionEstAutorisee:
      derogationsSontChargees &&
      permissionSurDerogation.peutDecider(
        // Toutes les dérogations sont sur le même projet, et ce projet est sur un marché.
        // Donc on vérifie la permission sur la première dérogation, qui vaudra pour toutes les dérogations
        projet.derogations.deVr[0],
        identiteUtilisateur(state),
        referentiel(state)
      ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  initialisation: () =>
    dispatch(chargerDerogationsDeVrParProjet(ownProps.idProjet)),
  onAvisBroker: (avis) => dispatch(avisBrokerDerogationVr(avis)),
  onTelechargerPieceJointe: (fichier) =>
    derogationDeVrAdapterCrm.telechargerPieceJointe(fichier),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DerogationsDeVrParProjet);

import { httpServiceCrm } from "../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../utils/odata/odata";

export const derogationsParProjetAdapterCrm = {
  async getIdsDesDerogations(
    idProjet,
    idTypeDeDerogation,
    { http = httpServiceCrm } = {}
  ) {
    const derogations = await http.get(
      odataQuery("itl_derogations", {
        $select: ["itl_derogationid"],
        $filter: [
          `_itl_projetconcerne_value eq ${idProjet}`,
          `_itl_typederogation_value eq ${idTypeDeDerogation}`,
        ],
      })
    );

    return derogations.map(d => d.itl_derogationid);
  },

  async getProjetDeFt(idFt, { http = httpServiceCrm } = {}) {
    const ft = await http.get(
      odataQuery(`itl_fichetransmissions(${idFt})`, {
        $select: ["_itl_projetid_value"],
      })
    );
    return { id: ft._itl_projetid_value };
  },

  async getProjetDeDerogation(idDerogation, { http = httpServiceCrm } = {}) {
    const derog = await http.get(
      odataQuery(`itl_derogations(${idDerogation})`, {
        $select: ["_itl_projetconcerne_value"],
      })
    );
    return { id: derog._itl_projetconcerne_value };
  },
};

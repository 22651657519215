import { httpServiceCrm } from "../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../utils/odata/odata";
import { CODES_GROUPES_OPTIONS } from "../../../../../Connecteurs/Crm/codesGroupesOptions";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";
import {
  getMarcheDeDivisionCrm,
  getPoste,
} from "../../../../../identiteUtilisateur/identite.adapter.crm";

const {
  NOUVEAU_CT,
  AER,
  AVENANT,
  PROLONGATION_NEGOCIEE,
  CB,
  CB_VENTE,
  MARCHE_PUBLIC,
} = CODES_GROUPES_OPTIONS.TYPES_CONTRAT;
const { ESPAGNE, FRANCE } = MARCHES;

export const projetsAdapterCrm = {
  async getProjetDeTravail(idProjet, { httpService = httpServiceCrm } = {}) {
    const projet = await getProjet(idProjet, { httpService });

    const [client, broker, commercialDuProjet] = await Promise.all([
      getClientDuProjet(projet, { httpService }),
      getBrokerDuProjet(projet, { httpService }),
      getCommercialDuProjet(projet._ownerid_value, { httpService }),
    ]);

    return {
      id: projet.itl_projetid,
      nom: projet.itl_projectname,
      etat: projet.statecode,
      marche: projet.itl_MarcheId
        ? {
            id: projet.itl_MarcheId.itl_marcheid,
            nom: projet.itl_MarcheId.itl_name,
            broker: broker,
          }
        : null,
      marqueItl: {
        id: projet.itl_MarqueItlId.itl_marqueitlid,
        nom: projet.itl_MarqueItlId.itl_name,
      },
      idApporteur: projet._itl_apporteurid_value,
      client: client
        ? {
            id: client.accountid,
            nom: client.name,
            tva: {
              tauxApplique: client.itl_TauxAppliqueId?.itl_taux ?? null,
              recupereLaTva: client.itl_isrecuperationtva,
            },
            estNouveau: projet.itl_isnouveauclient || false,
            risque: {
              estRisque: client.itl_estrisque,
              cause: client.itl_CauseRisqueId?.itl_name,
            },
          }
        : null,
      commercial: {
        idUtilisateur: commercialDuProjet.ownerid,
        email: commercialDuProjet.internalemailaddress,
        poste: getPoste(commercialDuProjet._positionid_value),
      },
      dateEstimeeMiseEnLoyerDefinitive:
        projet.itl_dateestimeemiseloyerdefinitive,
      etiquette: `${projet.itl_projectname} ${
        projet.itl_typecontratcode
          ? `(${getLabelTypeDeContrat(
              projet.itl_typecontratcode,
              getMarcheDeDivisionCrm(projet.owninguser._businessunitid_value)
            )})`
          : ""
      }`,
      idTypeDeContrat: projet.itl_typecontratcode,
      codeAffaire: projet.itl_codeaffaire,
      contratRattache: projet.itl_contratrattache,
    };
  },
};

async function getProjet(idProjet, { httpService }) {
  const requeteProjet = odataQuery(`itl_projets(${idProjet})`, {
    $select: [
      "itl_projetid",
      "itl_projectname",
      "_itl_apporteurid_value",
      "itl_dateestimeemiseloyerdefinitive",
      "itl_typecontratcode",
      "itl_contratrattache",
      "itl_codeaffaire",
      "itl_isnouveauclient",
      "_ownerid_value",
      "_itl_compteid_value",
      "statecode",
    ],
    $expand: [
      ["owninguser", { $select: ["_businessunitid_value"] }],
      [
        "itl_MarcheId",
        { $select: ["itl_marcheid", "itl_name", "_itl_brokeritl_value"] },
      ],
      ["itl_MarqueItlId", { $select: ["itl_marqueitlid", "itl_name"] }],
    ],
  });

  return await httpService.get(requeteProjet);
}

function getCommercialDuProjet(idCommercial, { httpService }) {
  return httpService.get(
    odataQuery(`systemusers(${idCommercial})`, {
      $select: ["ownerid", "internalemailaddress", "_positionid_value"],
    })
  );
}

export async function getClientDuProjet(projetCrm, { httpService }) {
  const idClient = projetCrm._itl_compteid_value;

  // Retourne null et ne crash pas, pour éviter
  // https://sentry.io/organizations/itl-prod/issues/3243384829
  // Le projet ne sera pas utilisable, il sera bloqué par erreursEmpechantUtilisationDuProjet()
  if (!idClient) return null;

  const requete = odataQuery(`accounts(${idClient})`, {
    $select: ["accountid", "name", "itl_isrecuperationtva", "itl_estrisque"],
    $expand: [
      ["itl_TauxAppliqueId", { $select: ["itl_taux"] }],
      ["itl_CauseRisqueId", { $select: ["itl_name"] }],
    ],
  });

  return await httpService.get(requete);
}

export async function getBrokerDuProjet(projetCrm, { httpService }) {
  const projetSansMarche = projetCrm.itl_MarcheId === null;
  if (projetSansMarche) return null;

  const [broker, adv] = await Promise.all([
    getLeBrokerDuMarche(projetCrm, { httpService }),
    getAdvDuBroker(),
  ]);

  return { ...broker, adv };
}

async function getLeBrokerDuMarche(projetCrm, { httpService }) {
  const idBroker = projetCrm.itl_MarcheId._itl_brokeritl_value;
  if (!idBroker) return null;

  const requeteBroker = odataQuery(`systemusers(${idBroker})`, {
    $select: ["fullname"],
  });
  const brokerCrm = await httpService.get(requeteBroker);

  return { idUtilisateur: idBroker, nom: brokerCrm.fullname };
}

const ID_ADV_DE_TOUS_LES_BROKERS =
  process.env.REACT_APP_ID_ADV_DE_TOUS_LES_BROKERS;

async function getAdvDuBroker() {
  return { idUtilisateur: ID_ADV_DE_TOUS_LES_BROKERS };
}

function getLabelTypeDeContrat(itl_typecontratcode, marche) {
  const labels = {
    [NOUVEAU_CT]: { [FRANCE]: "NOUVEAU CT", [ESPAGNE]: "NUEVO CT" },
    [AER]: { [FRANCE]: "AER", [ESPAGNE]: "CANCELA Y REEMPLAZA" },
    [AVENANT]: { [FRANCE]: "AVENANT", [ESPAGNE]: "ANEXO/ADENDA" },
    [CB]: { [FRANCE]: "CB", [ESPAGNE]: "LEASING" },
    [CB_VENTE]: { [FRANCE]: "CB Vente", [ESPAGNE]: "" },
    [PROLONGATION_NEGOCIEE]: {
      [FRANCE]: "PROLONGATION NEGOCIEE",
      [ESPAGNE]: "PROROGACIÓN NEGOCIADA",
    },
    [MARCHE_PUBLIC]: {
      [FRANCE]: "MARCHE PUBLIC",
      [ESPAGNE]: "MERCADO PÚBLICO",
    },
  };

  return labels[itl_typecontratcode][marche];
}

import Assurance from "./Assurance";
import { connect } from "react-redux";
import { uneOperationEstEnCours } from "../../../../../../../Ui/reducer";
import {
  assurerMaterielFrance,
  operationSurAssuranceFrance,
  retirerPrimeAssuranceFrance,
} from "../../../../../domaine/simulation/assurance/france/actions";
import {
  selectPrimeAssurance,
  selectProduitAssurance,
} from "../../../../../domaine/simulation/assurance/france/reducer";
import { calculateur } from "../../../../../../../reduxStore/rootReducer";
import { orchestrerLePlanDeFinancement } from "../../../../../domaine/planDeFinancement/actions";
import { IMPORTER_ASSURANCE_FRANCE } from "../../../../../domaine/importDeFt/sagas/import.assurance.france.sagas";
import { selectClient } from "../../../../../domaine/simulation/projetDeTravail/reducer";

const mapStateToProps = (state, ownProps) => {
  const { simulation } = calculateur(state);

  return {
    isLoading: uneOperationEstEnCours(state.ui, [
      operationSurAssuranceFrance(ownProps.materiel.id),
      IMPORTER_ASSURANCE_FRANCE,
    ]),
    produitAssurance: selectProduitAssurance(
      simulation.assurance.france,
      ownProps.materiel
    ),
    primeAssurance: selectPrimeAssurance(
      simulation.assurance.france,
      ownProps.materiel
    ),
    client: selectClient(simulation.projetDeTravail),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAjouterAssurance: (materiel, produitAssurance, option) =>
    dispatch(assurerMaterielFrance(materiel, produitAssurance, option)),
  onRetirerAssurance: (materiel) => {
    dispatch(retirerPrimeAssuranceFrance(materiel));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Assurance);

import { httpServiceCrm } from "../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../utils/odata/odata";
import { STATE_ACTIF } from "../../../Connecteurs/Crm/filtres";
import { MARCHES } from "../../../identiteUtilisateur/reducer";

const { ESPAGNE } = MARCHES;

export const marquesItlAdapterCrm = {
  async getMarquesItl(marcheUtilisateur, { http = httpServiceCrm } = {}) {
    let marquesItl;
    try {
      const espagnolInclus = ["itl_name", "itl_name_esp"];
      marquesItl = await getMarquesAvecChamps(espagnolInclus, { http });
    } catch (e) {
      marquesItl = await getMarquesAvecChamps(["itl_name"], { http });
    }

    return marquesItl.map(m => ({
      id: m.itl_marqueitlid,
      nom:
        marcheUtilisateur === ESPAGNE
          ? m.itl_name_esp ?? m.itl_name
          : m.itl_name,
      marche: { id: m._itl_marcheid_value },
    }));
  },
};

async function getMarquesAvecChamps(champsNoms, { http }) {
  const requete = odataQuery("itl_marqueitls", {
    $select: ["itl_marqueitlid", ...champsNoms, "_itl_marcheid_value"],
    $filter: [STATE_ACTIF],
  });
  const marquesItl = await http.get(requete);
  return marquesItl;
}

import {
  NATURE_DU_CALCUL,
  selectNatureDuCalcul,
} from "../../simulation/modeDeCalcul/reducer";
import planDeFinancementEstCalculableLoyers from "./loyers/planDeFinancementEstCalculable";
import { planDeFinancementEstCalculable as planDeFinancementEstCalculableInvestissement } from "./investissement/planDeFinancementEstCalculable";

const { INVESTISSEMENT, LOYERS } = NATURE_DU_CALCUL;

export function planDeFinancementEstCalculable(simulation) {
  const parNature = {
    [LOYERS]: planDeFinancementEstCalculableLoyers,
    [INVESTISSEMENT]: planDeFinancementEstCalculableInvestissement,
  };

  const natureActive = selectNatureDuCalcul(simulation.modeDeCalcul);
  return parNature[natureActive](simulation);
}

export const RECUPERER_PRODUIT_ASSURANCE_FRANCE =
  "FRANCE/RECUPERER_PRODUIT_ASSURANCE";
export const recupererProduitAssuranceFrance = (materiel) => ({
  type: RECUPERER_PRODUIT_ASSURANCE_FRANCE,
  materiel,
});

export const RECUPERER_PRODUIT_ASSURANCE_FRANCE_SUCCES = `${RECUPERER_PRODUIT_ASSURANCE_FRANCE}_SUCCES`;
export const recupererProduitAssuranceFrance_succes = (
  materiel,
  produitAssurance
) => ({
  type: RECUPERER_PRODUIT_ASSURANCE_FRANCE_SUCCES,
  materiel,
  produitAssurance,
});

export const SUPPRIMER_PRODUIT_ASSURANCE_FRANCE =
  "FRANCE/SUPPRIMER_PRODUIT_ASSURANCE";
export const supprimerProduitAssuranceFrance = (materiel) => ({
  type: SUPPRIMER_PRODUIT_ASSURANCE_FRANCE,
  materiel,
});

export function operationSurAssuranceFrance(idMateriel) {
  return `FRANCE/OPERATION_SUR_ASSURANCE/${idMateriel}`;
}

export const ASSURER_MATERIEL_FRANCE = "FRANCE/ASSURER_MATERIEL";
export const assurerMaterielFrance = (materiel, produitAssurance, option) => ({
  type: ASSURER_MATERIEL_FRANCE,
  materiel,
  produitAssurance,
  option,
});

export const DEFINIR_PRIME_ASSURANCE_FRANCE = "FRANCE/DEFINIR_PRIME_ASSURANCE";
export const definirPrimeAssuranceFrance = (materiel, prime) => ({
  type: DEFINIR_PRIME_ASSURANCE_FRANCE,
  materiel,
  prime,
});

export const RETIRER_PRIME_ASSURANCE_FRANCE = "FRANCE/RETIRER_ASSURANCE";
export const retirerPrimeAssuranceFrance = (materiel) => ({
  type: RETIRER_PRIME_ASSURANCE_FRANCE,
  materiel,
});

import { call, put, select } from "redux-saga/effects";
import { donnerLesVrGrilles } from "./actions";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import {
  calculDeVrUnLoyer,
  ventilerEnModificationsDeTaux,
} from "./calculDeVrUnLoyer";
import { listeDesProduitsAchetes } from "../montantAFinancer/reducer";
import { selectNombreEcheances } from "../modeDeCalcul/reducer";
import { Adapters } from "../../../../../reduxStore/adapters";
import {
  franchiseTroisMoisEstActivee,
  nbEcheancesDeFranchise,
  repartirLesDemandesDeVr,
} from "../franchiseTroisMois/reducer";
import { nombreDeMois } from "../modeDeCalcul/periodicite/periodicite";
import { Money } from "../../../../../Money/money";
import {
  selectVrParMois,
  typeDeFinancementActif,
} from "./selecteurs/generalites.selecteurs";

export function* recupererLesVrUnLoyerSaga() {
  const { simulation } = yield select(calculateur);

  const vrTotale = yield call(calculDeVrUnLoyer, simulation);
  const modificationDesTaux = ventilerEnModificationsDeTaux(
    vrTotale,
    simulation
  );

  yield put(modificationDesTaux);
}

export function* recupererLesVrGrillesSaga({
  vrGrillesAdapter = Adapters.vrGrillesAdapter,
} = {}) {
  const { simulation } = yield select(calculateur);

  const materielsAvecFamille = listeDesProduitsAchetes(
    simulation.montantAFinancer
  ).filter(m => m.idFamille !== null);

  const demandes = materielsAvecFamille.map(materiel => ({
    materiel,
    grilleDeVr: selectVrParMois(
      simulation.valeurResiduelle,
      materiel,
      simulation
    ),
  }));

  const lesVrGrilles = yield* getLesVrGrilles(demandes, { vrGrillesAdapter });

  yield put(donnerLesVrGrilles(lesVrGrilles));
}

function* getLesVrGrilles(demandes, { vrGrillesAdapter }) {
  const { simulation } = yield select(calculateur);

  const pasDeFranchise = !franchiseTroisMoisEstActivee(
    simulation.franchiseTroisMois
  );

  if (pasDeFranchise) {
    return yield* getVrDepuisK3000(demandes, { vrGrillesAdapter });
  } else {
    return yield* getVrPourFranchise(demandes, vrGrillesAdapter);
  }
}

function* getVrDepuisK3000(demandes, { vrGrillesAdapter }) {
  const { simulation } = yield select(calculateur);

  const configuration = {
    typeDeFinancement: typeDeFinancementActif(simulation.valeurResiduelle),
    nombreEcheances:
      selectNombreEcheances(simulation.modeDeCalcul) +
      nbEcheancesDeFranchise(simulation.franchiseTroisMois),
    periodicite: simulation.modeDeCalcul.periodicite,
  };

  const lesVrGrilles = yield call(
    vrGrillesAdapter.getLesVrGrilles,
    demandes,
    configuration
  );

  return lesVrGrilles;
}

function* getVrPourFranchise(demandes, vrGrillesAdapter) {
  const { simulation } = yield select(calculateur);
  const { modeDeCalcul, franchiseTroisMois } = simulation;

  const nombreEcheances =
    selectNombreEcheances(modeDeCalcul) +
    nbEcheancesDeFranchise(franchiseTroisMois);

  const { demandesGrilles, demandesQuinzeEuros } = repartirLesDemandesDeVr(
    demandes,
    nombreDeMois(nombreEcheances, modeDeCalcul.periodicite)
  );

  const lesVrGrilles = yield* getVrDepuisK3000(demandesGrilles, {
    vrGrillesAdapter,
  });

  const lesVrQuinzeEuros = demandesQuinzeEuros.map(({ materiel }) => {
    const tauxDeVr = 15 / Money(materiel.montantUnitaire).toUnit();
    return { materiel, tauxDeVr, tauxSeuilDerogation: tauxDeVr };
  });

  return [...lesVrGrilles, ...lesVrQuinzeEuros];
}

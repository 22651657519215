import React, { Fragment } from "react";
import { useClient } from "../../domaine/simulation/projetDeTravail/hooks";
import "./ClientRisque.css";

export function ClientRisque() {
  const client = useClient();

  if (!client.risque.estRisque) return <Fragment />;

  return (
    <div className="calc-client-risque qui-apparait">
      Attention, client à risque : {client.risque.cause}
    </div>
  );
}

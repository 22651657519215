export const AUCUN_CATCH = {
  erreur: null,
  actionQuiFaitCrasher: null,
};

const quiCatchLesErreurs = (reducerInstable, rapporterErreur = () => {}) => {
  const STATE_INITIAL = {
    etatStable: reducerInstable(undefined, {}),
    catch: AUCUN_CATCH,
  };

  return function (state = STATE_INITIAL, action) {
    switch (action.type) {
      case EFFACER_ERREUR_CATCHEE:
        return {
          ...state,
          catch: AUCUN_CATCH,
          timestampEffacementErreur: action.timestampEffacement,
        };

      default:
        const erreurEnCoursDoncAvaleLesActions = state.catch !== AUCUN_CATCH;
        if (erreurEnCoursDoncAvaleLesActions) return state;

        try {
          return {
            etatStable: reducerInstable(state.etatStable, action),
            catch: AUCUN_CATCH,
          };
        } catch (erreurDuReducer) {
          rapporterErreur(erreurDuReducer, {
            ...erreurDuReducer.details,
            actionQuiFaitCrasher: action,
          });

          return {
            etatStable: state.etatStable,
            catch: {
              erreur: { message: erreurDuReducer.message },
              actionQuiFaitCrasher: action,
            },
          };
        }
    }
  };
};
export default quiCatchLesErreurs;

const EFFACER_ERREUR_CATCHEE = "quiCatchLesErreurs/EFFACER_ERREUR_CATCHEE";
export const effacerErreurCatchee = (timestampEffacement) => ({
  type: EFFACER_ERREUR_CATCHEE,
  timestampEffacement,
});

export function timestampEffacementErreur(state) {
  return state.timestampEffacementErreur;
}

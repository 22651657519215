import { Money } from "../../../../../Money/money";
import { simulationReducer } from "../reducer";
import { listeDesProduitsAchetes } from "../montantAFinancer/reducer";
import { demanderTauxDeVr } from "./actions";
import {
  MODES_DE_CALCUL,
  selectModeDeCalcul,
  selectNombreEcheances,
} from "../modeDeCalcul/reducer";
import {
  calculerEnLineaire,
  calculerEnMultiPaliers,
} from "../modeDeCalcul/actions";
import { montantAchatDesProduitsAchetes } from "../montantAFinancer/montants";
import { Adapters } from "../../../../../reduxStore/adapters";
import { tegManuelFrEstActif } from "../tauxInteret/reducer";
import {
  getParametresEffectifsPourLePlan,
  getParametresManuelsPourLePlan,
} from "../../planDeFinancement/naturesDuCalcul/loyers/planDeFinancement.parametres";
import { selectMarcheUtilisateur } from "../profilUtilisateur/reducer";

const { LINEAIRE, MULTI_PALIERS } = MODES_DE_CALCUL;

export async function calculDeVrUnLoyer(
  simulation,
  { planDeFinancementAdapter = Adapters.planDeFinancementLoyersAdapter } = {}
) {
  const simulationSansVr = mettreLaVrAZero(simulation);
  const simulationAllongee = augmenterLaDuree(simulationSansVr);
  const vrUnLoyer = await loyerDuPlan(simulationAllongee, {
    planDeFinancementAdapter,
  });
  return vrUnLoyer;
}

function mettreLaVrAZero(simulation) {
  const lesProduits = listeDesProduitsAchetes(simulation.montantAFinancer);
  const tousLesTauxAZero = lesProduits.map((p) => ({ materiel: p, taux: 0 }));

  const simulationSansVr = simulationReducer(
    simulation,
    demanderTauxDeVr(tousLesTauxAZero)
  );

  return simulationSansVr;
}

function augmenterLaDuree(simulation) {
  function augmenterLaDureeEnLineaire() {
    const nbEcheances = selectNombreEcheances(simulation.modeDeCalcul);
    return calculerEnLineaire(nbEcheances + 1);
  }

  function augmenterLaDureeMultiPaliers() {
    const sansDernierPalier = simulation.modeDeCalcul.paliers.slice(0, -1);
    const dernierPalier = [...simulation.modeDeCalcul.paliers].pop();
    const paliersAllonges = [
      ...sansDernierPalier,
      { duree: dernierPalier.duree + 1 },
    ];
    return calculerEnMultiPaliers(paliersAllonges);
  }

  const augmentationParModeDeCalcul = {
    [LINEAIRE]: augmenterLaDureeEnLineaire,
    [MULTI_PALIERS]: augmenterLaDureeMultiPaliers,
  };

  const modeActif = selectModeDeCalcul(simulation.modeDeCalcul);
  const actionQuiAugmenteLaDuree = augmentationParModeDeCalcul[modeActif]();

  const simulationAllongee = simulationReducer(
    simulation,
    actionQuiAugmenteLaDuree
  );

  return simulationAllongee;
}

async function loyerDuPlan(simulation, { planDeFinancementAdapter }) {
  const calculParMode = {
    [LINEAIRE]: planDeFinancementAdapter.calculerPlanLineaire,
    [MULTI_PALIERS]: planDeFinancementAdapter.calculerPlanMultiPaliers,
  };

  const modeActif = selectModeDeCalcul(simulation.modeDeCalcul);
  const calculDuPlan = calculParMode[modeActif];
  const plan = await calculDuPlan(getParametresDuPlan(simulation));

  const dernierPalier = [...plan.paliers].pop();
  const dernierLoyer = dernierPalier.loyer;
  return Money(dernierLoyer);
}

function getParametresDuPlan(simulation) {
  return tegManuelFrEstActif(
    simulation.tauxInteret,
    selectMarcheUtilisateur(simulation.profilUtilisateur)
  )
    ? getParametresManuelsPourLePlan(simulation)
    : getParametresEffectifsPourLePlan(simulation);
}

export function ventilerEnModificationsDeTaux(montantDeVr, simulation) {
  const { montantAFinancer } = simulation;

  const totalDesAchats = montantAchatDesProduitsAchetes(montantAFinancer);

  const tauxDeVrDeChaqueProduit = montantDeVr
    .convertPrecision(8)
    .divide(totalDesAchats.toUnit())
    .toUnit();

  const lesProduits = listeDesProduitsAchetes(montantAFinancer);
  const tauxQuiAboutissentAuMontantVoulu = lesProduits.map((p) => ({
    materiel: p,
    taux: tauxDeVrDeChaqueProduit,
  }));

  return demanderTauxDeVr(tauxQuiAboutissentAuMontantVoulu, {
    estUneDemandeDeVrUnLoyer: true,
  });
}

import { isNullMoneyObject } from "../../../../../Money/money";
import { listeDesRachatsDeMateriels } from "../../simulation/montantAFinancer/reducer";
import { AnomalieRachatDeMaterielIncomplet } from "../Anomalies";

export function verifierRachatsDeMateriels(simulation) {
  function estIncomplet(rachat) {
    const montantAchatManquant =
      isNullMoneyObject(rachat.montantDuRachat) &&
      !isNullMoneyObject(rachat.montantDeLaRevente);

    const montantReventeManquant =
      isNullMoneyObject(rachat.montantDeLaRevente) &&
      !isNullMoneyObject(rachat.montantDuRachat);

    const descriptionManquante =
      rachat.description === null || rachat.description === "";

    const anneeManquante = rachat.annee === null || rachat.annee === "";

    return (
      montantAchatManquant ||
      montantReventeManquant ||
      descriptionManquante ||
      anneeManquante
    );
  }

  const { montantAFinancer } = simulation;
  return listeDesRachatsDeMateriels(montantAFinancer)
    .filter(estIncomplet)
    .map(() => AnomalieRachatDeMaterielIncomplet());
}

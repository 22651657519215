export const DEFINIR_PRIME_ASSURANCE_ESPAGNE_MANUELLE =
  "DEFINIR_PRIME_ASSURANCE_ESPAGNE_MANUELLE";
export const definirPrimeAssuranceEspagneManuelle = (prime) => ({
  type: DEFINIR_PRIME_ASSURANCE_ESPAGNE_MANUELLE,
  prime,
});

export const DEFINIR_PRIME_MINIMALE_ASSURANCE_ESPAGNE =
  "DEFINIR_PRIME_MINIMALE_ASSURANCE_ESPAGNE";
export const definirPrimeMinimaleAssuranceEspagne = (prime) => ({
  type: DEFINIR_PRIME_MINIMALE_ASSURANCE_ESPAGNE,
  prime,
});

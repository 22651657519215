import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export default function Identification(props) {
  return (
    <div>
      <div>
        <span className="creation-de-fiche-sub-title">{texte("client")}</span>
        <span className="creation-de-fiche-sub-text">
          {props.projetDeTravail.client.nom}
        </span>
      </div>
      <div>
        <span className="creation-de-fiche-sub-title">{texte("projet")}</span>
        <span className="creation-de-fiche-sub-text">
          {props.projetDeTravail.etiquette}
        </span>
      </div>
      <div>
        <span className="creation-de-fiche-sub-title">
          {texte("fiche_de_transmission")}
        </span>
        <span className="creation-de-fiche-sub-text">{props.fiche.nom}</span>
      </div>
    </div>
  );
}

Identification.propTypes = {
  projetDeTravail: PropTypes.object.isRequired,
  fiche: PropTypes.object.isRequired,
};

function texte(cle) {
  return (
    <FormattedMessage
      id={`calculateur.creation_fiche_de_transmission.${cle}`}
    />
  );
}

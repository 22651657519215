import { Vr } from "./Vr";
import { connect } from "react-redux";
import { calculateur } from "../../../../../../../reduxStore/rootReducer";
import {
  appliquerLeTauxDeVrGrille,
  demanderTauxDeVr,
} from "../../../../../domaine/simulation/valeurResiduelle/actions";
import {
  ORCHESTRER_PLAN_DE_FINANCEMENT,
  orchestrerLePlanDeFinancement,
} from "../../../../../domaine/planDeFinancement/actions";
import { selectAnomalieDeVrDuProduit } from "../../../../../domaine/anomalies/Anomalies";
import { selectDerogationDeVr } from "../../../../../domaine/simulation/derogations/reducer";
import {
  estEnMode,
  MODE_AFFICHAGE,
  uneOperationEstEnCours,
} from "../../../../../../../Ui/reducer";
import { enrichirAvecInvalidites } from "../../../../../domaine/simulation/derogations/vr/invalidites/enrichirAvecInvalidites";
import { selectInfosVr } from "../../../../../domaine/simulation/valeurResiduelle/selecteurs/generalites.selecteurs";

const { LIGHT } = MODE_AFFICHAGE;

const mapStateToProps = (state, ownProps) => {
  const { simulation, anomalies } = calculateur(state);
  const { materiel } = ownProps;

  return {
    isLoading: uneOperationEstEnCours(state.ui, [
      ORCHESTRER_PLAN_DE_FINANCEMENT,
    ]),
    vr: selectInfosVr(simulation.valeurResiduelle, materiel),

    anomalieDeVr: selectAnomalieDeVrDuProduit(anomalies, materiel.id),

    derogationDeVr: enrichirAvecInvalidites(
      selectDerogationDeVr(simulation.derogations, materiel),
      simulation
    ),

    estEnModeLight: estEnMode(LIGHT, state.ui),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDemandeDeVr: (materiel, taux) => {
    dispatch(demanderTauxDeVr([{ materiel, taux }]));
    dispatch(orchestrerLePlanDeFinancement());
  },
  onAppliquerVrGrille: (materiel) => {
    dispatch(appliquerLeTauxDeVrGrille(materiel.id));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Vr);

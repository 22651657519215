import { connect } from "react-redux";
import Marge from "./Marge";
import { changerLeTauxDeMarge } from "../../domaine/simulation/montantAFinancer/actions";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { selectAnomalieDeMargeNonDerogee } from "../../domaine/anomalies/Anomalies";
import { anomalieEstDerogeeParUtilisateurConnecte } from "../../domaine/simulation/derogateurs/reducer";
import { selectDerogationDeMargeAuTauxMinimum } from "../../domaine/simulation/derogations/reducer";
import { orchestrerLePlanDeFinancement } from "../../domaine/planDeFinancement/actions";
import { selectInfosDeMarge } from "../../domaine/simulation/montantAFinancer/montants";

const mapStateToProps = (state) => {
  const { simulation, anomalies } = calculateur(state);

  return {
    infosMarge: selectInfosDeMarge(simulation.montantAFinancer),
    margeTropFaible:
      selectAnomalieDeMargeNonDerogee(anomalies).filter(
        (a) => !anomalieEstDerogeeParUtilisateurConnecte(a)
      ).length > 0,
    derogation: selectDerogationDeMargeAuTauxMinimum(simulation.derogations),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangementDuMontant: () => {},
  onChangementDuTaux: (taux) => {
    dispatch(changerLeTauxDeMarge(taux));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Marge);

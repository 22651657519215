import { call, select } from "redux-saga/effects";
import { calculateur } from "../../../../../../../reduxStore/rootReducer";
import {
  selectAssuranceEspagneEstAjoutee,
  selectPrimeAssuranceEspagne,
} from "../../../../simulation/assurance/espagne/reducer";
import { Adapters } from "../../../../../../../reduxStore/adapters";
import { selectPaliers } from "../../../../planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";
import { selectAnomalieAssuranceEspagneInsuffisante } from "../../../../anomalies/Anomalies";
import { selectMotivation } from "../../../../motivationsDesAnomalies/reducer";
import { selectProjetDeTravail } from "../../../../simulation/projetDeTravail/reducer";
import { enEuros } from "../../../../../../../Kit/formatage";
import { STATUT_DEROGATION } from "../../../../../../Derogations/domaine/reducer";
import { aujourdhuiyyyyMMdd } from "../../../../../../../utils/dateUtils";

const { A_DECIDER } = STATUT_DEROGATION;

export function* assurerFtEspagne(
  idFt,
  { assuranceEspagneAdapter = Adapters.creerAssuranceEspagneAdapter, intl } = {}
) {
  const {
    anomalies,
    motivationsDesAnomalies,
    simulation: { assurance, projetDeTravail },
    planDeFinancement,
  } = yield select(calculateur);

  const assuranceAjoutee = selectAssuranceEspagneEstAjoutee(assurance.espagne);
  if (!assuranceAjoutee) return;

  yield call(assuranceEspagneAdapter.ajouterAssuranceSurFt, idFt, {
    prime: selectPrimeAssuranceEspagne(assurance.espagne),
    paliers: selectPaliers(planDeFinancement),
    derogation: derogationPourAssuranceInsuffisante(
      anomalies,
      motivationsDesAnomalies,
      intl
    ),
    idProjet: selectProjetDeTravail(projetDeTravail).id,
  });
}

function derogationPourAssuranceInsuffisante(
  anomalies,
  motivationsDesAnomalies,
  intl
) {
  const anomalie = selectAnomalieAssuranceEspagneInsuffisante(anomalies);
  if (!anomalie) return;

  const motivation = selectMotivation(motivationsDesAnomalies, anomalie);
  const primeMinimale = enEuros(anomalie.details.primeMinimale, "");
  const primeSaisie = enEuros(anomalie.details.primeSaisie, "");

  if (!anomalie.dejaDerogee)
    return {
      nom: intl.formatMessage(
        { id: "calculateur.derogation_assurance_espagne.titre" },
        { primeMinimale, primeSaisie }
      ),
      statut: A_DECIDER,
      valeurOrigine: primeMinimale,
      valeurDemandee: primeSaisie,
      commentaires: { demandeur: motivation.motivation },
      derogateur: anomalie.derogateur,
    };
  else {
    return {
      autoAcceptee: true,
      nom: intl.formatMessage(
        { id: "calculateur.derogation_assurance_espagne.titre" },
        { primeMinimale, primeSaisie }
      ),
      statut: anomalie.derogationAnterieure.statut,
      dateDecision: aujourdhuiyyyyMMdd(),
      valeurOrigine: primeMinimale,
      valeurDemandee: primeSaisie,
      commentaires: {
        demandeur: commentaire(
          anomalie.derogationAnterieure.commentaires.demandeur
        ),
        decideur: commentaire(
          anomalie.derogationAnterieure.commentaires.decideur
        ),
      },
      derogateur: {
        idDerogateur: anomalie.derogationAnterieure.derogateur.id,
      },
    };

    function commentaire(commentairePrecedent) {
      return intl.formatMessage(
        {
          id:
            "derogation_assurance_espagne.validee_automatiquement.commentaire",
        },
        { commentairePrecedent }
      );
    }
  }
}

export function* assurerDepenseEspagneSaga(
  depenseCrm,
  { assuranceEspagneAdapter = Adapters.creerAssuranceEspagneAdapter } = {}
) {
  const {
    simulation: { assurance },
  } = yield select(calculateur);

  const assuranceAjoutee = selectAssuranceEspagneEstAjoutee(assurance.espagne);
  if (!assuranceAjoutee) return;

  yield call(assuranceEspagneAdapter.ajouterAssuranceSurDepense, {
    depenseCrm,
    primeAssurance: selectPrimeAssuranceEspagne(assurance.espagne),
  });
}

export function* assurerLoyerEspagneSaga(
  loyerCrm,
  palier,
  { assuranceEspagneAdapter = Adapters.creerAssuranceEspagneAdapter } = {}
) {
  const {
    simulation: { assurance },
  } = yield select(calculateur);

  const assuranceAjoutee = selectAssuranceEspagneEstAjoutee(assurance.espagne);
  if (!assuranceAjoutee) return;

  const primeAssurance = selectPrimeAssuranceEspagne(assurance.espagne);
  yield call(
    assuranceEspagneAdapter.ajouterAssuranceSurLoyer,
    loyerCrm,
    palier,
    primeAssurance.parLoyer.montant
  );
}

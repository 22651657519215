import { all, call, put, takeEvery } from "redux-saga/effects";
import { operationEnCours } from "../../../../../Ui/actions";
import {
  CHARGER_PROJET_DE_TRAVAIL,
  chargerProjetDeTravail_succes,
} from "./actions";
import { INITIALISER_CALCULATEUR } from "../../actions";
import { erreursEmpechantUtilisationDuProjet } from "./erreursEmpechantUtilisationDuProjet";
import { Adapters } from "../../../../../reduxStore/adapters";
import { trySaga } from "../../../../../utils/sagasUtils";

export function* chargerProjetDeTravailSaga(
  action,
  { projetsAdapter = Adapters.projetsAdapter } = {}
) {
  const saga = function* () {
    yield put(operationEnCours(INITIALISER_CALCULATEUR));

    const projet = yield call(
      projetsAdapter.getProjetDeTravail,
      action.idProjetDeTravail
    );
    const erreurs = yield call(erreursEmpechantUtilisationDuProjet, projet);
    yield put(chargerProjetDeTravail_succes({ ...projet, erreurs }));
  };

  yield* trySaga(saga, CHARGER_PROJET_DE_TRAVAIL);
}

export default function* sagasDeChoixDuProjet() {
  yield all([takeEvery(CHARGER_PROJET_DE_TRAVAIL, chargerProjetDeTravailSaga)]);
}

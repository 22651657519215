export const CHARGER_PROJET_DE_TRAVAIL = "CHARGER_PROJET_DE_TRAVAIL";
export const chargerProjetDeTravail = (idProjetDeTravail) => ({
  type: CHARGER_PROJET_DE_TRAVAIL,
  idProjetDeTravail,
});

export const CHARGER_PROJET_DE_TRAVAIL_SUCCES = `${CHARGER_PROJET_DE_TRAVAIL}_SUCCES`;
export const chargerProjetDeTravail_succes = (projetDeTravail) => ({
  type: CHARGER_PROJET_DE_TRAVAIL_SUCCES,
  projetDeTravail,
});

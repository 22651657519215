import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import React from "react";
import { FormattedMessage } from "react-intl";

export function PiecesJointes({ fichiers, onDownloadClick }) {
  return (
    <div className="pieces-jointes">
      <b>
        <FormattedMessage id="derogation_de_vr.pieces_jointes" />
      </b>
      <div className="fichiers">
        {fichiers.length === 0 && (
          <div>
            <FormattedMessage id="derogation_de_vr.pieces_jointes.aucune" />
          </div>
        )}
        {fichiers.map((f) => (
          <div
            key={f.id}
            className="flex-ai-center pointable"
            onClick={() => onDownloadClick(f)}
          >
            <div key={f.nom} style={style.nomFichier}>
              {f.nom}
            </div>
            <FontAwesomeIcon
              icon={faDownload}
              className="pointable"
              style={style.supprimerFichier}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const style = {
  fichier: { fontSize: "inherit" },
  nomFichier: {
    border: "1px solid #ccc",
    borderRadius: "3px 0 0 3px",
    padding: "3px 10px",
  },
  supprimerFichier: {
    border: "1px solid #cccccc",
    borderRadius: "0 3px 3px 0",
    borderLeft: "none",
    padding: "4px 5px",
    textAlign: "center",
    backgroundColor: "buttonface",
  },
};

import { Money } from "../../../../Money/money";

export function paliersSontDifferents(paliersA, paliersB) {
  const memeNombre = paliersA.length === paliersB.length;
  if (!memeNombre) return true;

  for (let i = 0; i < paliersB.length; i++) {
    const dureeChange = paliersB[i].duree !== paliersA[i].duree;
    const loyerChange = !Money(paliersB[i].loyer).equalsTo(
      Money(paliersA[i].loyer)
    );
    if (dureeChange || loyerChange) return true;
  }

  return false;
}

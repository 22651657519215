import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PlanDeFinancement from "./PlanDeFinancement/PlanDeFinancement.container";
import PaliersSouhaites from "./PaliersSouhaites/PaliersSouhaites.container";
import Marge from "./Marge/Marge.container";
import "./Calculateur.css";
import "./Calculateur-mobile.css";
import Commissionnement from "./Commisionnement/Commissionnement.container";
import Produits from "./Produits/Produits.container";
import { cleCalculateur } from "../../../reduxStore/rootReducer";
import Instantanes from "./Instantanes/Instantanes.container";
import Periodicite from "./Periodicite/Periodicite.container";
import {
  estEnMode,
  MODE_AFFICHAGE,
  uneOperationEstEnCours,
} from "../../../Ui/reducer";
import Terme from "./Terme/Terme.container";
import { ModeReReglement } from "./ConditionsDeReglement/ModeDeReglement";
import { DelaiDeReglement } from "./ConditionsDeReglement/DelaiDeReglement";
import Desactivable from "../../../Kit/Layout/Desactivable";
import { CREER_FICHE_DE_TRANSMISSION } from "../domaine/creationDeFt/actions";
import Header from "./Header/Header.container";
import CreationDeFt from "./CreationDeFt/CreationDeFt.container";
import VrUnLoyer from "./VrUnLoyer/VrUnLoyer.container";
import TypeDeFinancement from "./TypeDeFinancement/TypeDeFinancement.container";
import InfosUtilesEnDev from "./InfosUtilesEnDev/InfosUtilesEnDev";
import CreationReussie from "./CreationDeFt/ResultatDeCreation/CreationReussie.container";
import TEG from "./TEG/TEG.container";
import SelectMarqueItlActive from "../../ModeLight/composants/SelectMarqueItlActive/SelectMarqueItlActive.container";
import { Sticky } from "../../../Kit/Sticky/Sticky";
import AssuranceEspagneManuelle from "./Assurance/Espagne/AssuranceEspagneManuelle.container";
import NatureDuCalcul from "./NatureDuCalcul/NatureDuCalcul.container";
import { FranceSeulement } from "./BarriereDeMarche/FranceSeulement";
import { ClientRisque } from "./Header/ClientRisque";
import { Maintenance } from "./Maintenance/Maintenance";

const { LIGHT } = MODE_AFFICHAGE;

function Calculateur({ id, calculateurEnLectureSeule, estEnModeLight }) {
  return (
    <Desactivable desactiverChildren={calculateurEnLectureSeule}>
      <Sticky selecteurDeLaCible=".calculateur">
        {!estEnModeLight && <CreationDeFt />}
        <Header />
        <FranceSeulement>
          <ClientRisque />
        </FranceSeulement>
      </Sticky>

      <div className="calculateur">
        <div className="calculateur-simulation" key={id}>
          <CreationReussie />

          <div className="parametres-generaux">
            {estEnModeLight && <SelectMarqueItlActive />}
            <TypeDeFinancement />
            <NatureDuCalcul />
          </div>

          <Produits className="mb-10" />

          <div>
            <div className="actions-en-un-clic">
              <div>
                <VrUnLoyer />
                <Marge />
                <TEG />
                <FranceSeulement>
                  <Maintenance />
                </FranceSeulement>
              </div>
              <AssuranceEspagneManuelle />
            </div>

            <div className="paliers-souhaites-et-conditions">
              <PaliersSouhaites />
              <div className="conditions">
                <h3>
                  <FormattedMessage id="calculateur.conditions" />
                </h3>
                <div className="flex-wrap">
                  <Terme className="mr-20 mb-10" />
                  <Periodicite className="mr-20 mb-10" />
                  <FranceSeulement>
                    <ModeReReglement className="mr-20 mb-10" />
                    <DelaiDeReglement className="mb-10" />
                  </FranceSeulement>
                </div>
              </div>
            </div>
            <div className="w100">
              <PlanDeFinancement />
            </div>
          </div>

          <Commissionnement />

          <InfosUtilesEnDev />
        </div>
        <Instantanes className="calculateur-instantanes" />
      </div>
    </Desactivable>
  );
}

Calculateur.propTypes = {
  calculateurEnLectureSeule: PropTypes.bool.isRequired,
  estEnModeLight: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

const mapStateToProps = (state) => ({
  calculateurEnLectureSeule: uneOperationEstEnCours(
    state.ui,
    CREER_FICHE_DE_TRANSMISSION
  ),
  estEnModeLight: estEnMode(LIGHT, state.ui),
  id: cleCalculateur(state),
});

export default connect(mapStateToProps)(Calculateur);

import {
  CHARGER_INSTANTANES_SUCCES,
  PRENDRE_INSTANTANE,
  RESTAURER_INSTANTANE,
  SUPPRIMER_INSTANTANE,
} from "./actions";

const avecInstantanes = (reducer, limiteDeStockage) => {
  const STATE_INITIAL = {
    actif: reducer(undefined, {}),
    instantanes: {
      parId: {},
    },
  };

  return function (state = STATE_INITIAL, action) {
    switch (action.type) {
      case PRENDRE_INSTANTANE:
        let instantanesCollection = { ...state.instantanes.parId };
        const instantanesIds = Object.keys(instantanesCollection);
        if (instantanesIds.length === limiteDeStockage) {
          delete instantanesCollection[instantanesIds[0]];
        }

        return {
          actif: state.actif,
          instantanes: {
            parId: {
              ...instantanesCollection,
              [action.idInstantane]: {
                capture: state.actif,
                timestampCreation: action.timestampCreation,
              },
            },
          },
        };

      case RESTAURER_INSTANTANE:
        const { idInstantane, timestampRestauration } = action;
        return {
          actif: state.instantanes.parId[idInstantane].capture,
          instantanes: { ...state.instantanes, timestampRestauration },
        };

      case SUPPRIMER_INSTANTANE:
        const instantanesFiltres = { ...state.instantanes.parId };
        delete instantanesFiltres[action.idInstantane];

        return {
          ...state,
          instantanes: { parId: instantanesFiltres },
        };

      case CHARGER_INSTANTANES_SUCCES:
        const instantanes = {};
        action.instantanes.map((i) => (instantanes[i.id] = i));
        return {
          ...state,
          instantanes: { parId: instantanes },
        };

      default:
        return {
          ...state,
          actif: reducer(state.actif, action),
        };
    }
  };
};
export default avecInstantanes;

export const listeDesInstantanes = (state) =>
  Object.keys(state.instantanes.parId)
    .map((id) => ({
      id,
      capture: state.instantanes.parId[id].capture,
      timestampCreation: state.instantanes.parId[id].timestampCreation,
    }))
    .sort((a, b) => b.timestampCreation - a.timestampCreation);

export function timestampRestaurationInstantane(state) {
  return state.instantanes.timestampRestauration;
}

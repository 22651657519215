import { call, select } from "redux-saga/effects";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { getMarcheUtilisateur } from "../../../simulation/profilUtilisateur/sagas";
import { selectTvaDuClient } from "../../../simulation/projetDeTravail/reducer";
import { selectTegEffectif } from "../../../simulation/tauxInteret/reducer";
import {
  selectDelaiDeReglement,
  selectModeDeReglement,
} from "../../../simulation/conditionsDeReglement/reducer";
import { recupererTegGrilleSaga } from "../../../simulation/tauxInteret/sagas";

export function* getFraisDeReglement({ fraisDeReglementAdapter, tegAdapter }) {
  // Récupère le TEG car il est nécessaire au calcul des frais.
  // Et s'il n'a jamais été calculé (exemple si l'utilisateur vient de démarrer Force)
  // alors le calcul crash.
  yield call(recupererTegGrilleSaga, { tegAdapter });

  const parametres = yield* getParametresDuCalcul();

  const frais = yield call(
    fraisDeReglementAdapter.calculerFraisDeReglement,
    parametres
  );

  return frais;
}

function* getParametresDuCalcul() {
  const { simulation } = yield select(calculateur);
  const marcheUtilisateur = yield* getMarcheUtilisateur();

  const loyers = simulation.modeDeCalcul.paliers.map((p) => p.loyer);
  const tva = selectTvaDuClient(simulation.projetDeTravail);
  const teg = selectTegEffectif(simulation.tauxInteret, marcheUtilisateur);
  const modeDeReglement = selectModeDeReglement(
    simulation.conditionsDeReglement
  );
  const delaiDeReglement = selectDelaiDeReglement(
    simulation.conditionsDeReglement
  );

  return { loyers, tva, teg, modeDeReglement, delaiDeReglement };
}

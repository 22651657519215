import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { enEuros, pourcentage } from "../../../../Kit/formatage";
import ChaineDeLoyers from "./ChaineDeLoyers/ChaineDeLoyers";
import "./Header.css";
import "./Header-mobile.css";
import Euros from "../../../../Kit/Finance/Euros";
import { Money, MoneyPropType, Zero } from "../../../../Money/money";
import { ht } from "../../../../i18n/raccourcis";
import { MONTANT_INVESTISSEMENT_TROP_ELEVE } from "../../domaine/simulation/montantAFinancer/commissionCommerciale";
import { MessageAnomalie } from "../CreationDeFt/Anomalies/MessageAnomalie";
import { PAS_DE_TAUX_DE_MARGE } from "../../domaine/simulation/montantAFinancer/reducer";
import Aide from "../../../../Kit/Aide/Aide";

const Header = (props) => {
  const intl = useIntl();
  return props.anomalies.length === 0 ? (
    <div className="calc-header qui-apparait">
      <div className="blocs-1">
        {Loyers(
          props.paliers,
          props.avertissementLoyerTropEleve,
          props.calculDuPlanDeFinancementEnCours,
          props.franchiseTroisMoisEstActivee,
          intl
        )}
        {CommissionCommerciale(
          props.commissionCommerciale,
          props.franchiseTroisMoisEstActivee
        )}
        {Marge(props.infosMarge, intl)}
      </div>
      <div className="blocs-2">
        {Depenses(props.montantDesDepenses, intl)}
        {Duree(props.paliers, props.dureeEnMois)}
        {NonValeur(
          props.montantDeNonValeur,
          props.tauxDeNonValeur,
          props.avertissementNonValeurElevee,
          intl
        )}
      </div>
    </div>
  ) : (
    <div className="calc-header qui-apparait anomalies">
      <ul>
        {props.anomalies.map((a) => (
          <li key={a.idType}>
            <MessageAnomalie simulation={props.simulation} anomalie={a} />
          </li>
        ))}
      </ul>
    </div>
  );
};

Header.propTypes = {
  montantDesDepenses: MoneyPropType,
  infosMarge: PropTypes.object.isRequired,
  paliers: ChaineDeLoyers.propTypes.paliers,
  dureeEnMois: PropTypes.number,
  montantDeNonValeur: MoneyPropType,
  tauxDeNonValeur: PropTypes.number.isRequired,
  avertissementNonValeurElevee: PropTypes.object,
  avertissementLoyerTropEleve: PropTypes.object,
  calculDuPlanDeFinancementEnCours: PropTypes.bool.isRequired,
  commissionCommerciale: PropTypes.object.isRequired,
  franchiseTroisMoisEstActivee: PropTypes.bool.isRequired,
};

export default Header;

const Marge = ({ tauxEffectif, montantEffectif, tauxManuel }, intl) => (
  <div className="header-div" id="header-bloc-marge">
    <div className="header-sub-title">
      <FormattedMessage id="calculateur.plan_de_financement.marge" />
      {tauxEffectif !== tauxManuel && (
        <Aide id="heade-marge-recalculee" className="ml-5">
          Marge calculée sur la base du TEG grille
        </Aide>
      )}
    </div>
    {tauxEffectif !== PAS_DE_TAUX_DE_MARGE && (
      <div>
        <div className="header-sub-text">
          <div>{pourcentage(tauxEffectif)}</div>
          <div className="header-sub-details">
            <FormattedMessage
              id="general.equivalence"
              values={{ valeur: enEuros(montantEffectif, ht(intl)) }}
            />
          </div>
        </div>
      </div>
    )}
  </div>
);

const Loyers = (
  paliers,
  avertissementLoyerTropEleve,
  isLoading,
  franchiseTroisMoisEstActivee,
  intl
) => (
  <div className="header-div" id="header-bloc-loyers">
    <div className="header-sub-title">
      <FormattedMessage id="calculateur.plan_de_financement.loyers" />
      <br />
      <span className="header-sub-details">
        <FormattedMessage id="calculateur.plan_de_financement.loyers.details" />
      </span>
    </div>
    {!isLoading && franchiseTroisMoisEstActivee && (
      <div className="header-franchise-trois-mois">
        Opération franchise 3 mois
      </div>
    )}
    <ChaineDeLoyers
      isLoading={isLoading}
      paliers={paliers}
      avertissementLoyerTropEleve={avertissementLoyerTropEleve}
      intl={intl}
    />
  </div>
);

const Duree = (paliers, dureeEnMois) => (
  <div className="header-div" id="header-bloc-duree">
    <div className="header-sub-title">
      <FormattedMessage id="calculateur.plan_de_financement.duree" />
    </div>
    <div className="header-sub-text">
      {paliers.length > 0 && (
        <div className="duree-de-financement">
          <div>{dureeEnMois}</div>
          <div className="subtitle">
            <FormattedMessage id="general.duree.mois" />
          </div>
        </div>
      )}
    </div>
  </div>
);

const NonValeur = (montant, taux, avertissementNonValeurElevee, intl) => (
  <div
    className={`header-div ${
      avertissementNonValeurElevee ? "header-div-avertissement" : ""
    }`}
    id="header-bloc-non-valeur"
  >
    <div className="header-sub-title">
      <FormattedMessage id="calculateur.plan_de_financement.non_valeur" />
    </div>
    {Money(montant).greaterThan(Zero) && (
      <div>
        <div className="header-sub-text">{pourcentage(taux)}</div>
        <div>
          <FormattedMessage
            id="general.equivalence"
            values={{ valeur: enEuros(montant, ht(intl)) }}
          />
        </div>
      </div>
    )}
    {avertissementNonValeurElevee && (
      <div className="mt-10 text-align-right">
        <FormattedMessage id="calculateur.avertissements.non_valeur_elevee" />
      </div>
    )}
  </div>
);

const Depenses = (montantDesDepenses, intl) => (
  <div className="header-div" id="header-bloc-depenses">
    <div className="header-sub-title">
      <FormattedMessage id="calculateur.plan_de_financement.montant_des_depenses" />
    </div>
    <div className="header-sub-text">
      {MontantSiPositif(montantDesDepenses, intl)}
    </div>
  </div>
);

const CommissionCommerciale = (
  commissionCommerciale,
  franchiseTroisMoisEstActivee
) => {
  const { montant, erreur } = commissionCommerciale;
  return (
    <div className="header-div" id="header-bloc-commission-commerciale">
      <div className="header-sub-title">
        <FormattedMessage id="calculateur.commission_commerciale" />
      </div>
      <div>
        {erreur ? (
          erreur.type === MONTANT_INVESTISSEMENT_TROP_ELEVE && (
            <div className="text-align-right">
              Pour un montant d'investissement supérieur à{" "}
              {enEuros(erreur.limite, "")}, la commission commerciale ne peut
              être calculée.
            </div>
          )
        ) : franchiseTroisMoisEstActivee ? (
          <div className="header-franchise-trois-mois">
            <FormattedMessage id="calculateur.commission_commerciale.indisponible_en_franchise_3_mois" />
          </div>
        ) : (
          montant.greaterThan(Zero) && (
            <div className="header-sub-text">
              {enEuros(montant.toObject(), "")}
            </div>
          )
        )}
      </div>
    </div>
  );
};

function MontantSiPositif(montant, intl) {
  return Money(montant).greaterThan(Zero) ? (
    enEuros(montant, ht(intl))
  ) : (
    <span>
      &nbsp;&nbsp;-&nbsp;&nbsp;
      <Euros />
    </span>
  );
}

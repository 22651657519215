import { $obj, Money } from "../../../../../../Money/money";
import { httpK3000 } from "../../../../../../Connecteurs/K3000/httpK3000";
import { LABELS_PERIODICITE_K3000 } from "../../../../../../Connecteurs/K3000/labelsK3000";
import { PERIODICITES } from "../../modeDeCalcul/periodicite/periodicite";
import { getMontantAAssurer } from "./reducer";

const { ANNUEL } = PERIODICITES;

export const assuranceFranceAdapterK3000 = {
  async getPrimeAssurance(demande, { http = httpK3000 } = {}) {
    const {
      parametresPourMateriel,
      parametresPourAnnuelle,
    } = parametresDeCalculDePrimeAssurance(demande);

    const [prime, primeAnnuelle] = await Promise.all([
      http.post("/api/v1/assurance/prime-assurance", parametresPourMateriel),
      http.post("/api/v1/assurance/prime-assurance", parametresPourAnnuelle),
    ]);

    return {
      parLoyer: { montant: $obj(prime.montantPrimeDAssurance) },
      annuelle: { montant: $obj(primeAnnuelle.montantPrimeDAssurance) },
      tauxApplique: tauxPourLaDemande(demande),
      option: demande.option,
    };
  },
};

export function parametresDeCalculDePrimeAssurance(demande) {
  const { materiel, client } = demande;
  const montantAAssurer = getMontantAAssurer(materiel, client)
    .toUnit()
    .toString();

  const { produitAssurance } = demande;
  const montantPrimeDAssuranceAnnuelleMinimale = Money(
    produitAssurance.primeMinimumAnnuelle
  )
    .toUnit()
    .toString();

  const pourcentageDAssuranceAnnuel = pourcentage(tauxPourLaDemande(demande));

  return {
    parametresPourMateriel: {
      montantAAssurer,
      montantPrimeDAssuranceAnnuelleMinimale,
      pourcentageDAssuranceAnnuel,
      montantImpotMensuel: "0",
      pourcentageDeMarge: "0",
      typeDePeriodicite: LABELS_PERIODICITE_K3000[demande.periodicite],
    },
    parametresPourAnnuelle: {
      montantAAssurer,
      montantPrimeDAssuranceAnnuelleMinimale,
      pourcentageDAssuranceAnnuel,
      montantImpotMensuel: "0",
      pourcentageDeMarge: "0",
      typeDePeriodicite: LABELS_PERIODICITE_K3000[ANNUEL],
    },
  };
}

export function pourcentageAssuranceAnnuel(demande) {
  return pourcentage(tauxPourLaDemande(demande));
}

function tauxPourLaDemande({ materiel, produitAssurance, option }) {
  const { taux, options } = produitAssurance;
  const estNeuf = !materiel.estOccasion;
  if (estNeuf) {
    const tauxDeBase = taux.tauxValeurNeuf || taux.tauxCategorie;
    const tauxOption = option ? options[option].tauxAdditionnelNeuf : 0;
    return tauxDeBase + tauxOption;
  } else {
    const tauxDeBase = taux.tauxValeurOccasion || taux.tauxCategorie;
    const tauxOption = option ? options[option].tauxAdditionnelOccasion : 0;
    return tauxDeBase + tauxOption;
  }
}

function pourcentage(taux) {
  return String(taux * 100);
}

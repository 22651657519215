import {
  selectDerogateurAssuranceEspagne,
  selectDerogateurDeMargeCompetentPourAction,
} from "../../simulation/derogateurs/reducer";
import { ANOMALIES } from "../Anomalies";
import { selectBrokerDuMarche } from "../../simulation/projetDeTravail/reducer";

const {
  MARGE_TROP_FAIBLE,
  TAUX_DE_VR_NECESSITE_DEROGATION,
  TEG_MANUEL_INFERIEUR_A_GRILLE,
  ASSURANCE_ESPAGNE_INSUFFISANTE,
} = ANOMALIES;

export function enrichirAvecDerogateur(anomalie, simulation) {
  switch (anomalie.idType) {
    case TAUX_DE_VR_NECESSITE_DEROGATION:
      return {
        ...anomalie,
        personneConsultee: selectBrokerDuMarche(simulation.projetDeTravail),
      };

    case MARGE_TROP_FAIBLE: {
      const derogateur = selectDerogateurDeMargeCompetentPourAction(
        simulation.derogateurs,
        anomalie.details.tauxDuPlan
      );

      return { ...anomalie, derogateur, personneConsultee: derogateur };
    }

    case TEG_MANUEL_INFERIEUR_A_GRILLE:
      return { ...anomalie, personneConsultee: { nom: "Martin CARRE" } };

    case ASSURANCE_ESPAGNE_INSUFFISANTE: {
      const derogateur = selectDerogateurAssuranceEspagne(
        simulation.derogateurs
      );

      return { ...anomalie, derogateur, personneConsultee: derogateur };
    }
    default:
      return anomalie;
  }
}

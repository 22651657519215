import { CHARGER_IDENTITE_UTILISATEUR_SUCCES } from "./actions";
import { guidEqual } from "../utils/guidUtils";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case CHARGER_IDENTITE_UTILISATEUR_SUCCES:
      return { ...state, ...action.identite };

    default:
      return state;
  }
};
export default reducer;

export const POSTE_UTILISATEUR = {
  COMMERCIAL: "COMMERCIAL",
  DIRECTEUR_REGIONAL: "DIRECTEUR_REGIONAL",
  DIRECTEUR_COMMERCIAL: "DIRECTEUR_COMMERCIAL",
  /*
   Il existe un poste DIRECTION en CRM mais Force le mappe sur DIRECTEUR_COMMERCIAL pour l'instant.
   Ça permet à M. Carre d'avoir les droits du Directeur Commercial.
   Il n'y a pas encore besoin de gérer différemment Direction et Directeur Commercial, donc on fusionne les 2.
   Cf. identiteAdapter pour ce mapping.
   */
  ACAD: "ACAD",
  BROKER: "BROKER",
};

export const MARCHES = {
  ESPAGNE: "MARCHE_ESPAGNE",
  FRANCE: "MARCHE_FRANCE",
};

export const selectIdCrmUtilisateur = (state) => state.userIdCrm;

export const selectIdCrmUtilisateurEst = (state, idCandidat) =>
  guidEqual(state.userIdCrm, idCandidat);

export const selectUtilisateurPasEncoreIdentifie = (state) =>
  state.userIdCrm === undefined;

export const selectUtilisateurALePoste = (state, poste) =>
  selectPosteUtilisateur(state) === poste;

export const selectPosteUtilisateur = (state) => state.poste;
export const selectMarcheUtilisateur = (state) => state.marcheUtilisateur;

import { httpServiceCrm } from "../../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../../utils/odata/odata";

const ID_TYPE_DEROGATION_DE_VR = process.env.REACT_APP_ID_TYPE_DE_DEROGATION_VR;

export const notificationDeVrAdapterCrm = {
  async getDerniereSansAvis({ idProjet }, { http = httpServiceCrm } = {}) {
    const [derniereDerogation] = await http.get(
      odataQuery("itl_derogations", {
        $select: ["createdon"],
        $filter: [
          `_itl_projetconcerne_value eq ${idProjet}`,
          `_itl_typederogation_value eq ${ID_TYPE_DEROGATION_DE_VR}`,
          "itl_vrbroke_montant eq null",
          "itl_vrbroke eq null",
        ],
        $orderBy: [["createdon", "desc"]],
        $top: 1,
      })
    );

    if (!derniereDerogation) return null;

    return { dateEnvoi: derniereDerogation.createdon };
  },
};

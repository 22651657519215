import { call, select } from "redux-saga/effects";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { creerRachatsDeContratsSaga } from "./rachatDeContrat/rachatDeContrat.sagas";
import { creerRachatsDeMaterielsSaga } from "./rachatDeMateriel/rachatDeMateriel.sagas";
import { creerCommissionsSaga } from "./commission/commissions.sagas";
import { creerDepensesDeMaterielsSaga } from "./materiels/materiels.sagas";

export function* creerLesDepensesDeLaFtSaga(ft, timestampCreation) {
  const { simulation } = yield select(calculateur);

  yield call(creerDepensesDeMaterielsSaga, ft, timestampCreation);
  yield call(creerCommissionsSaga, ft);
  yield call(creerRachatsDeContratsSaga, simulation, ft.id);
  yield call(creerRachatsDeMaterielsSaga, simulation, ft.id);
}

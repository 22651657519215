import { call, put, select } from "redux-saga/effects";
import { ID_TYPE_DEROGATION_DE_MARGE } from "./marge.constantes";
import {
  RECUPERER_DEROGATEUR_DE_MARGE_DIRECTEUR_REGIONAL,
  recupererDerogateurDeMarge_succes,
} from "../actions";
import { erreurEstSurvenue } from "../../../../../../Ui/actions";
import {
  calculateur,
  choixDuProjet,
} from "../../../../../../reduxStore/rootReducer";
import { Adapters } from "../../../../../../reduxStore/adapters";
import { getMarcheUtilisateur } from "../../profilUtilisateur/sagas";
import { seuilAutonomieDuDirecteurRegional } from "./seuilsAutonomieDesDirecteursRegionaux";
import {
  selectMarcheChoisi,
  selectMarqueItlChoisie,
} from "../../projetDeTravail/reducer";
import { POSTE_UTILISATEUR } from "../../../../../../identiteUtilisateur/reducer";

const { DIRECTEUR_REGIONAL } = POSTE_UTILISATEUR;

export function* recupererLeDerogateurDirecteurRegionalSaga({
  derogateursAdapter = Adapters.derogateursAdapter,
} = {}) {
  try {
    const directeurRegional = yield call(getDirecteurRegionalSaga);

    const derogateur = yield call(
      derogateursAdapter.getDerogateurPotentiel,
      ID_TYPE_DEROGATION_DE_MARGE,
      directeurRegional.idUtilisateur
    );

    // Pas de directeur -> erreur silencieuse
    // Le dérogateur maître sera disponible
    if (!derogateur) return;

    const marcheUtilisateur = yield* getMarcheUtilisateur();
    const { simulation } = yield select(calculateur);
    const marcheProjet = selectMarcheChoisi(simulation.projetDeTravail);
    const marqueItl = selectMarqueItlChoisie(simulation.projetDeTravail);

    yield put(
      recupererDerogateurDeMarge_succes({
        idDerogateur: derogateur.idDerogateur,
        idUtilisateur: directeurRegional.idUtilisateur,
        autonomieDeDerogation: seuilAutonomieDuDirecteurRegional({
          marcheUtilisateur,
          idMarcheProjet: marcheProjet.id,
          idMarqueItl: marqueItl.id,
        }),
        poste: DIRECTEUR_REGIONAL,
        nom: derogateur.nom,
        estUtilisateurConnecte: directeurRegional.estUtilisateurConnecte,
      })
    );
  } catch (erreur) {
    yield put(
      erreurEstSurvenue(
        RECUPERER_DEROGATEUR_DE_MARGE_DIRECTEUR_REGIONAL,
        erreur
      )
    );
  }
}

export function* getDirecteurRegionalSaga({
  identiteAdapter = Adapters.identiteAdapter,
} = {}) {
  const { projetDeTravail } = yield select(choixDuProjet);

  const commercialDuProjet = projetDeTravail.commercial;

  const commercialDuProjetEstDirecteurRegional =
    commercialDuProjet.poste === DIRECTEUR_REGIONAL;

  if (commercialDuProjetEstDirecteurRegional)
    return {
      idUtilisateur: commercialDuProjet.idUtilisateur,
      estUtilisateurConnecte: true,
    };

  const directeur = yield call(
    identiteAdapter.getDirecteur,
    commercialDuProjet.idUtilisateur
  );

  return {
    idUtilisateur: directeur.idUtilisateur,
    estUtilisateurConnecte: false,
  };
}

import React, { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAnomalie } from "../../../domaine/anomalies/hooks";
import { ANOMALIES } from "../../../domaine/anomalies/Anomalies";
import { enEuros } from "../../../../../Kit/formatage";
import { STATUT_DEROGATION } from "../../../../Derogations/domaine/reducer";
import { useEstEnModeLight } from "../../../../../Ui/hooks";
import { Features } from "../../../../../features/Features";

const {
  ASSURANCE_ESPAGNE_INSUFFISANTE,
  ASSURANCE_ESPAGNE_DEJA_REFUSEE,
} = ANOMALIES;
const { ACCEPTEE, A_DECIDER, REFUSEE } = STATUT_DEROGATION;

export function BadgeDerogationAssuranceEspagne() {
  const featureActivee = Features.AvecDerogationAssuranceEspagne === true;
  if (!featureActivee) return <Fragment />;

  const dejaRefusee = useAnomalie(ASSURANCE_ESPAGNE_DEJA_REFUSEE);
  const anomalie = useAnomalie(ASSURANCE_ESPAGNE_INSUFFISANTE);
  const estModeLight = useEstEnModeLight();

  if (dejaRefusee) return <Fragment />;
  if (!anomalie) return <Fragment />;

  return (
    <div className="badge-derogation">
      <Demande valeurDemandee={anomalie.details.primeSaisie} />

      {estModeLight ? (
        <Fragment />
      ) : (
        <Decision statut={anomalie.dejaDerogee ? ACCEPTEE : A_DECIDER} />
      )}
    </div>
  );
}

function Demande(props) {
  const intl = useIntl();
  const enLight = useEstEnModeLight();

  return (
    <span className={`tag ${enLight ? "anomalie" : ""}`}>
      {intl.formatMessage({
        id: "derogation_assurance_espagne.prime_demandee",
      })}
      {` ${enEuros(props.valeurDemandee, "")}`}
    </span>
  );
}

function Decision({ statut }) {
  const classeDuBadge = () => {
    const classes = {
      [REFUSEE]: "button-error",
      [ACCEPTEE]: "button-success",
      [A_DECIDER]: "button-secondary",
    };
    return classes[statut];
  };

  const motDuBadge = () => {
    const mots = {
      [REFUSEE]: "calculateur.derogation_assurance_espagne.refusee",
      [ACCEPTEE]: "calculateur.derogation_assurance_espagne.acceptee",
      [A_DECIDER]: "calculateur.derogation_assurance_espagne.a_decider",
    };
    return mots[statut];
  };

  return (
    <span className={`tag ${classeDuBadge()}`}>
      <FormattedMessage id={motDuBadge()} />
    </span>
  );
}

import React from "react";
import InputMontant from "../../../../Kit/Formulaires/InputMontant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";

export function SaisieDeTeg({
  tegManuel,
  tegGrille,
  onBlur,
  onRevenirAGrille,
  afficherEnRouge,
  retourGrilleEstPermis,
  saisieInterdite,
}) {
  return (
    <>
      <div>
        <InputMontant
          value={tegManuel ?? tegGrille}
          onBlur={(value) => {
            const vidageDuChamp = value === null && tegManuel !== undefined;
            const saisieDuTauxGrille = memeTaux(value, tegGrille);
            if (vidageDuChamp || saisieDuTauxGrille) onRevenirAGrille();
            else if (value !== null) onBlur(value);
          }}
          className={`input-s ${afficherEnRouge ? "text-red" : ""}`}
          append="%"
          testId="teg-input"
          placeholder=""
          disabled={!retourGrilleEstPermis || saisieInterdite}
        />
      </div>

      {tegManuel !== undefined && retourGrilleEstPermis && (
        <FontAwesomeIcon
          icon={faTrash}
          onClick={onRevenirAGrille}
          className="teg-retour-grille"
          data-testid="teg-retour-grille"
        />
      )}
    </>
  );
}

function memeTaux(taux1, taux2) {
  return Number(taux1).toFixed(4) === Number(taux2).toFixed(4);
}

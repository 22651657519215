import { Zero } from "../../../../../Money/money";
import {
  franchiseTroisMoisEstActivee,
  NB_ECHEANCES_DE_FRANCHISE_PAR_PERIODICITE,
} from "../../simulation/franchiseTroisMois/reducer";

export function paliersAvecFranchise(state, paliers) {
  const pasDeFranchise = !franchiseTroisMoisEstActivee(state);

  if (pasDeFranchise) return paliers;
  else return [lePalierDeLaFranchise(state), ...paliers];
}

function lePalierDeLaFranchise(state) {
  return {
    duree: NB_ECHEANCES_DE_FRANCHISE_PAR_PERIODICITE[state.periodicite](),
    loyer: Zero.toObject(),
    estFranchise: true,
  };
}

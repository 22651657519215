export const TEMPLATE_NOTIFICATION_DE_DEROGATION = `
Bonjour,<br/>
Une fiche de transmission avec <b>dérogation(s) de VR</b> a été créée.<br/>
<ul>
  <li><b>Demandeur :</b> {demandeur}</li>
  <li><b>Client :</b> {client}</li>
  <li><b>FT :</b> {ft}</li>
  <li><b>Durée de financement :</b> {duree} mois</li>
  <li><b>Type de financement :</b> {typeDeFinancement}</li>
</ul>

{details}

Merci de donner votre avis en <a href="{urlSynthese}">cliquant ici</a>.`;

export const TEMPLATE_DESCRIPTION_DEROGATION = `
<h4 style="margin-bottom: 0">Dérogation #{numeroDerogation}</h4>
<ul style="margin-top: 0">
  <li style='{styleMateriel}'><b>Matériel :</b> {labelMateriel}</li>
  <li><b>Marque :</b> {marque}</li>
  <li><b>Description :</b> {description}</li>
  <li><b>Montant unitaire :</b> {montantUnitaire}</li>
  <li><b>VR</b> à {vrDemandee} au lieu de {vrOrigine}</li>
  <li><b>Motivation :</b> {motivation}</li>
</ul>
`;

export const INITIALISER_DEROGATION_DE_MARGE =
  "INITIALISER_DEROGATION_DE_MARGE";
export const RECUPERER_DEROGATEUR_DE_MARGE_DIRECTEUR_REGIONAL =
  "RECUPERER_DEROGATEUR_DE_MARGE_DIRECTEUR_REGIONAL";
export const RECUPERER_DEROGATEUR_DE_MARGE_MAITRE =
  "RECUPERER_DEROGATEUR_DE_MARGE_MAITRE";

export const RECUPERER_DEROGATEUR_DE_MARGE_SUCCES = `RECUPERER_DEROGATEUR_DE_MARGE_SUCCES`;
export const recupererDerogateurDeMarge_succes = (derogateur) => ({
  type: RECUPERER_DEROGATEUR_DE_MARGE_SUCCES,
  derogateur,
});

export const VIDER_LES_DEROGATEURS_DE_MARGE = "VIDER_LES_DEROGATEURS_DE_MARGE";
export const viderLesDerogateursDeMarge = () => ({
  type: VIDER_LES_DEROGATEURS_DE_MARGE,
});

export const INITIALISER_DEROGATEUR_ASSURANCE_ESPAGNE =
  "INITIALISER_DEROGATEUR_ASSURANCE_ESPAGNE";

export const RECUPERER_DEROGATEUR_ASSURANCE_ESPAGNE_SUCCES =
  "RECUPERER_DEROGATEUR_ASSURANCE_ESPAGNE_SUCCES";
export const recupererDerogateurAssuranceEspagne_succes = (derogateur) => ({
  type: RECUPERER_DEROGATEUR_ASSURANCE_ESPAGNE_SUCCES,
  derogateur,
});

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { pourcentage } from "../../../../Kit/formatage";

function InfosUtilesEnDev({ teg }) {
  if (process.env.NODE_ENV !== "development") return <Fragment />;

  return <div>{teg && <p>TEG : {pourcentage(teg)}</p>}</div>;
}

const mapStateToProps = (state) => ({
  teg: calculateur(state).planDeFinancement.tauxInteret,
});

export default connect(mapStateToProps)(InfosUtilesEnDev);

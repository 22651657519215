import { call } from "redux-saga/effects";
import { trySaga } from "../../../../../utils/sagasUtils";
import {
  ACCEPTER_DEROGATION_DE_TEG,
  REFUSER_DEROGATION_DE_TEG,
} from "./actions";
import { deciderDerogationSaga } from "../../decision/sagas";

const ID_TYPE_DEROGATION_TEG = process.env.REACT_APP_ID_TYPE_DE_DEROGATION_TEG;

export function* accepterDerogationDeTegSaga(action) {
  function* saga() {
    const { derogation, decision } = action;
    yield call(
      deciderDerogationSaga,
      derogation,
      decision,
      ID_TYPE_DEROGATION_TEG
    );
  }

  yield* trySaga(saga, ACCEPTER_DEROGATION_DE_TEG);
}

export function* refuserDerogationDeTegSaga(action) {
  function* saga() {
    const { derogation, decision } = action;
    yield call(
      deciderDerogationSaga,
      derogation,
      decision,
      ID_TYPE_DEROGATION_TEG
    );
  }

  yield* trySaga(saga, REFUSER_DEROGATION_DE_TEG);
}

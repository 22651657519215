import React from "react";
import PropTypes from "prop-types";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";

const Accordeon = ({ titre, children, style, estOuvert, id }) => (
  // cf. https://github.com/react-component/collapse#collapse
  // pour la gestion de l'ouverture
  <Collapse defaultActiveKey={estOuvert ? id : undefined}>
    <Panel
      style={style}
      header={titre}
      headerClass="rc-collapse-header-custom"
      key={id}
      id={id}
    >
      {children}
    </Panel>
  </Collapse>
);

Accordeon.propTypes = {
  titre: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  id: PropTypes.string.isRequired,
  ouvert: PropTypes.bool,
  style: PropTypes.object,
};

export default Accordeon;

import { call, select } from "redux-saga/effects";
import { formatjjMMaaaa } from "../../../../../utils/dateUtils";
import { enEuros, pourcentage } from "../../../../../Kit/formatage";
import { derogations } from "../../../../../reduxStore/rootReducer";
import { selectDerogationDetaillee, STATUT_DEROGATION } from "../../reducer";
import { crmSendEmail } from "../../../../../Connecteurs/Crm/sendEmail";
import { UrlsForce } from "../../../../../routing/Urls";
import { templating } from "../../../../../i18n/templating";
import { TEMPLATE_AVIS_SUR_DEROG_FR } from "./templates/TEMPLATE_AVIS_SUR_DEROG_fr";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";
import { TEMPLATE_AVIS_SUR_DEROG_ES } from "./templates/TEMPLATE_AVIS_SUR_DEROG_es";
import { i18n } from "../../../../../i18n/i18n";
import { ht } from "../../../../../i18n/raccourcis";
import { Adapters } from "../../../../../reduxStore/adapters";
import { chargerUneDerogationDetailleeSaga } from "../../sagas";
import { chargerUneDerogationDetaillee } from "../../actions";

const { ESPAGNE, FRANCE } = MARCHES;
const { REFUSEE } = STATUT_DEROGATION;

export function* notifierDemandeurDeAvisBrokerSaga(
  avisDuBroker,
  {
    notificationDerogationDeVrAdapter = Adapters.notificationDerogationDeVrAdapter,
    identiteAdapter = Adapters.identiteAdapter,
    envoyerEmail = crmSendEmail,
  } = {}
) {
  // Recharge la dérogation AVANT de notifier, pour que la notification utilise des données
  // à jour en requêtant le state.
  yield call(
    chargerUneDerogationDetailleeSaga,
    chargerUneDerogationDetaillee(avisDuBroker.idDerogation)
  );

  const derogationsState = yield select(derogations);
  const derogation = yield call(
    selectDerogationDetaillee,
    derogationsState,
    avisDuBroker.idDerogation
  );

  const { famille, marque } = yield call(
    notificationDerogationDeVrAdapter.getFamilleEtMarque,
    derogation.depense
  );

  const { texte, sujet } = yield call(
    notificationAvisAuDemandeur,
    derogation,
    famille.nom,
    marque.nom
  );

  const enCopie = yield* getDestinatairesCC(derogation, {
    identiteAdapter,
    notificationDerogationDeVrAdapter,
  });

  yield call(envoyerEmail, {
    sujet,
    destinataire: `/systemusers(${derogation.auteur.id})`,
    destinatairesCC: enCopie,
    corps: texte,
    regarding: {
      type: "itl_derogation",
      id: `/itl_derogations(${avisDuBroker.idDerogation})`,
    },
  });
}

export function notificationAvisAuDemandeur(
  derogation,
  familleMateriel,
  marqueMateriel
) {
  const templateParMarche = {
    [FRANCE]: TEMPLATE_AVIS_SUR_DEROG_FR,
    [ESPAGNE]: TEMPLATE_AVIS_SUR_DEROG_ES,
  };

  const {
    CORPS,
    SUJET,
    DECISION_STANDARD,
    DECISION_REFUS_CATEGORIQUE,
  } = templateParMarche[derogation.auteur.marche];

  const intl = i18n.getIntl(derogation.auteur.marche);

  const urlFt = UrlsForce.ft(
    derogation.projet.id,
    derogation.ficheDeTransmission.id
  );

  const estRefusCategorique =
    derogation.statut === REFUSEE &&
    derogation.valeurBroker.pourcentage === null;

  const texte = templating(CORPS, {
    broker: derogation.broker.nom,
    dateDemande: formatjjMMaaaa(derogation.dateDemande),
    projet: derogation.projet.nom,
    commentaireBroker: derogation.commentaireBroker.replace("\n", "<br />"),
    urlFtForce: urlFt.ouvrir,
    decision: estRefusCategorique
      ? templating(DECISION_REFUS_CATEGORIQUE, {
          familleMateriel,
          marqueMateriel,
        })
      : templating(DECISION_STANDARD, {
          familleMateriel,
          marqueMateriel,
          pourcentageAvis: pourcentage(
            derogation.valeurBroker.pourcentage / 100
          ),
          valeurAvis: enEuros(derogation.valeurBroker.montant, ht(intl)),
          pourcentageDemande: pourcentage(derogation.valeurDemandee / 100),
          pourcentageOrigine: pourcentage(derogation.valeurOrigine / 100),
        }),
  });

  const sujet = templating(SUJET, {});

  return { texte, sujet };
}

function* getDestinatairesCC(
  demande,
  { identiteAdapter, notificationDerogationDeVrAdapter }
) {
  const advDuBroker = yield call(
    notificationDerogationDeVrAdapter.getAdvDuBroker
  );
  const destinatairesCC = [`/systemusers(${advDuBroker.id})`];

  const advDesCommerciaux = yield call(
    identiteAdapter.getAdvDesCommerciaux,
    demande.auteur.marche
  );
  advDesCommerciaux.forEach(adv =>
    destinatairesCC.push(`/systemusers(${adv.idUtilisateur})`)
  );

  return destinatairesCC;
}

import React from "react";
import PropTypes from "prop-types";
import { enEuros } from "../../../../../Kit/formatage";
import "./ChaineDeLoyers.css";
import "./ChaineDeLoyers-mobile.css";
import { MoneyPropType } from "../../../../../Money/money";
import { FormattedMessage } from "react-intl";
import BarreDeChargement from "../../../../../Kit/Loaders/BarreDeChargement";
import { ht } from "../../../../../i18n/raccourcis";

const ChaineDeLoyers = ({
  paliers,
  avertissementLoyerTropEleve,
  isLoading,
  intl,
}) => (
  <div className="chaine-de-loyers">
    {isLoading ? (
      <BarreDeChargement size="S" testId="chaine-de-loyers-loader" />
    ) : (
      <div id="paliers" data-testid="chaine-de-loyers">
        {paliers.map(({ duree, loyerFullService }, index) => (
          <div className="chiffres" key={index}>
            <div>{duree}</div>
            <div>
              <span>x</span>
            </div>
            <div>{enEuros(loyerFullService, ht(intl))}</div>
          </div>
        ))}
        {avertissementLoyerTropEleve && (
          <div className="mt-5 text-align-right">
            <FormattedMessage
              id="calculateur.avertissements.loyer_trop_eleve"
              values={{
                seuil: enEuros(
                  avertissementLoyerTropEleve.details.loyerSeuil,
                  ht(intl)
                ),
              }}
            />
          </div>
        )}
      </div>
    )}
  </div>
);

ChaineDeLoyers.propTypes = {
  paliers: PropTypes.arrayOf(
    PropTypes.shape({
      duree: PropTypes.number.isRequired,
      loyerFullService: MoneyPropType,
    })
  ),
  avertissementLoyerTropEleve: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
};

export default ChaineDeLoyers;

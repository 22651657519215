import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./DerogationsDeVrParProjet.css";
import "./DerogationsDeVrParProjet-mobile.css";
import BarreDeChargement from "../../../../Kit/Loaders/BarreDeChargement";
import { FormattedMessage } from "react-intl";
import Accordeon from "../../../../Kit/Accordeon/Accordeon";
import MiniBadge, { Mode as MODE } from "../../../../Kit/MiniBadge/MiniBadge";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { ResumeDeDerogation } from "./UneDerogation/ResumeDeDerogation";
import { ResumeDeDecision } from "./UneDerogation/ResumeDeDecision";
import { FormulaireAvisBroker } from "./UneDerogation/FormulaireAvisBroker";
import BadgeErreur from "../../../../Kit/Badges/Erreur/BadgeErreur";
import { PiecesJointes } from "./PiecesJointes";
import BadgeWarning from "../../../../Kit/Badges/Warning/BadgeWarning";
import { IframeRapportFt } from "../Kit/IframeRapportFt";
import { LienVersAccueil } from "../Navigation/LienVersAccueil";
import { STATUT_DEROGATION } from "../../domaine/reducer";

const { NEUTRE, SUCCES } = MODE;
const { A_DECIDER } = STATUT_DEROGATION;

function DerogationsDeVrParProjet({
  isLoading,
  decisionEstAutorisee,
  erreurs,
  projet,
  onAvisBroker,
  onTelechargerPieceJointe,
  initialisation,
}) {
  useEffect(() => {
    initialisation();
  }, [initialisation]);

  return (
    <div className="derogations-de-vr-par-projet">
      {erreurs.length > 0 && (
        <div className="erreurs">
          <BadgeErreur messages={erreurs} />
        </div>
      )}

      {isLoading ? (
        <div className="chargement-en-cours">
          <BarreDeChargement />
        </div>
      ) : !decisionEstAutorisee ? (
        <BadgeWarning className="decision-non-autorisee">
          <FormattedMessage id="derogation.decision.droits_insuffisants" />
        </BadgeWarning>
      ) : (
        <div>
          <LienVersAccueil />
          <h3>
            <FormattedMessage
              id="derogations_de_vr_par_projet.titre"
              values={{ nombreDeDerogations: projet.derogations.deVr.length }}
            />
          </h3>

          {projet.derogations.deVr.map((d) => (
            <div key={d.id} className="une-derogation">
              <Accordeon id={`${d.id}-accordeon`} titre={titreDeAccordeon(d)}>
                <div className="une-derogation-contenu-accordeon">
                  <ResumeDeDerogation derogation={d} />
                  <ResumeDeDecision derogation={d} />
                  <PiecesJointes
                    fichiers={d.piecesJointes}
                    onDownloadClick={onTelechargerPieceJointe}
                  />
                  <FormulaireAvisBroker derogation={d} onAvis={onAvisBroker} />
                  {d.ft.urlRapport && (
                    <IframeRapportFt urlRapport={d.ft.urlRapport} />
                  )}
                </div>
              </Accordeon>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

DerogationsDeVrParProjet.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  decisionEstAutorisee: PropTypes.bool.isRequired,
  initialisation: PropTypes.func.isRequired,
  projet: PropTypes.object.isRequired,
  onAvisBroker: PropTypes.func.isRequired,
  onTelechargerPieceJointe: PropTypes.func.isRequired,
  erreurs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DerogationsDeVrParProjet;

function titreDeAccordeon(derogation) {
  const decisionDonnee = derogation.statut !== A_DECIDER;
  return (
    <div className="titre-accordeon">
      <div>{derogation.nom}</div>
      <div>
        <MiniBadge
          icone={decisionDonnee ? faCheck : faClock}
          mode={decisionDonnee ? SUCCES : NEUTRE}
        >
          <FormattedMessage id="derogation_de_vr.etapes.avis_du_broker" />
        </MiniBadge>
      </div>
    </div>
  );
}

import { call, put, select } from "redux-saga/effects";
import { chargerMargeCible_succes } from "./actions";
import {
  calculateur,
  choixDuProjet,
} from "../../../../../reduxStore/rootReducer";
import { getMarcheUtilisateur } from "../profilUtilisateur/sagas";
import { Adapters } from "../../../../../reduxStore/adapters";
import {
  selectClient,
  selectMarcheChoisi,
  selectMarqueItlChoisie,
} from "../projetDeTravail/reducer";

export function* chargerMargeCibleSaga({
  margeCibleAdapter = Adapters.margeCibleAdapter,
} = {}) {
  const parametres = yield* getParametresMargeCible();
  const margeCible = yield call(margeCibleAdapter.getMargeCible, parametres);
  yield put(chargerMargeCible_succes(margeCible));
}

function* getParametresMargeCible() {
  const { simulation } = yield select(calculateur);
  const idMarcheProjet = selectMarcheChoisi(simulation.projetDeTravail).id;
  const idMarqueItl = selectMarqueItlChoisie(simulation.projetDeTravail).id;

  const marcheUtilisateur = yield* getMarcheUtilisateur();

  const projetDeTravail = yield select(choixDuProjet);
  const client = selectClient(projetDeTravail);

  return { marcheUtilisateur, idMarcheProjet, idMarqueItl, client };
}

import React from "react";
import PropTypes from "prop-types";

function noOp() {}

const InputEntier = ({
  value,
  onChange = noOp,
  onBlur = noOp,
  onFocus,
  className = "",
  testId,
}) => (
  <input
    type="number"
    value={value || ""}
    onFocus={onFocus}
    onChange={(e) => {
      if (e.target.value === "") {
        onChange(null);
        return;
      }
      if (e.target.validity.valid) onChange(parseInt(e.target.value));
    }}
    onBlur={(e) => {
      if (e.target.value === "") {
        onBlur(null);
        return;
      }
      if (e.target.validity.valid) onBlur(parseInt(e.target.value));
    }}
    step={1}
    className={className}
    data-testid={testId}
  />
);

const chaineVide = PropTypes.string;
InputEntier.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, chaineVide]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  testId: PropTypes.string,
};

export default InputEntier;

import { useEffect, useState } from "react";

export function useStateSyncedToProp(initialValue) {
  const [stateValue, setStateValue] = useState(initialValue);
  // https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
  useEffect(() => setStateValue(initialValue), [initialValue]);

  return [stateValue, setStateValue];
}

// https://stackoverflow.com/a/36862446/867600
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

import { getMarcheUtilisateur } from "../profilUtilisateur/sagas";
import { selectTegEffectif, tegManuelFrEstActif } from "../tauxInteret/reducer";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import { call, put, select } from "redux-saga/effects";
import { Adapters } from "../../../../../reduxStore/adapters";
import {
  montantDesDepenses,
  valeurDuMontantAFinancerAvecMargeZero,
} from "./montants";
import { montantTotalDeVr } from "../valeurResiduelle/selecteurs/montants.selecteurs";
import { listeDesProduitsAchetes } from "./reducer";
import { selectMarcheUtilisateur } from "../profilUtilisateur/reducer";
import { changerLeTauxDeMargeRecalculePourTeg } from "./actions";
import { MODES_DE_CALCUL, selectModeDeCalcul } from "../modeDeCalcul/reducer";
import { getParametresManuelsPourLePlan } from "../../planDeFinancement/naturesDuCalcul/loyers/planDeFinancement.parametres";
import { selectPaliersLoyersFinancier } from "../../planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

const { LINEAIRE, MULTI_PALIERS } = MODES_DE_CALCUL;

export function* recalculerLeTauxDeMargePourTegSaga({
  margeAdapter = Adapters.margeAdapter,
  planDeFinancementAdapter = Adapters.planDeFinancementLoyersAdapter,
} = {}) {
  const marcheUtilisateur = yield* getMarcheUtilisateur();
  const { simulation } = yield select(calculateur);
  if (!tegManuelFrEstActif(simulation.tauxInteret, marcheUtilisateur)) return;

  const paliersDuTegManuel = yield* getPaliersDuTegManuel({
    planDeFinancementAdapter,
  });

  const margeRecalculee = yield* getMargeRecalculee(paliersDuTegManuel, {
    margeAdapter,
  });

  yield put(changerLeTauxDeMargeRecalculePourTeg(margeRecalculee.taux));
}

function* getPaliersDuTegManuel({ planDeFinancementAdapter }) {
  const { simulation } = yield select(calculateur);
  const parametresAvecTegManuel = getParametresManuelsPourLePlan(simulation);

  const calculParMode = {
    [LINEAIRE]: planDeFinancementAdapter.calculerPlanLineaire,
    [MULTI_PALIERS]: planDeFinancementAdapter.calculerPlanMultiPaliers,
  };
  const calcul = calculParMode[selectModeDeCalcul(simulation.modeDeCalcul)];

  const planDuTegManuel = yield call(calcul, parametresAvecTegManuel);
  return selectPaliersLoyersFinancier(planDuTegManuel);
}

function* getMargeRecalculee(paliersTegManuel, { margeAdapter }) {
  const { simulation } = yield select(calculateur);

  const vr = montantTotalDeVr(
    simulation.valeurResiduelle,
    listeDesProduitsAchetes(simulation.montantAFinancer)
  );
  const teg = selectTegEffectif(
    simulation.tauxInteret,
    selectMarcheUtilisateur(simulation.profilUtilisateur)
  );

  const parametres = {
    paliers: paliersTegManuel,
    vr: { montant: vr.toObject() },
    terme: simulation.terme.choisi,
    periodicite: simulation.modeDeCalcul.periodicite,
    teg: teg,
    aFinancerSansMarge: {
      montant: valeurDuMontantAFinancerAvecMargeZero(
        simulation.montantAFinancer
      ).toObject(),
    },
    depenses: {
      montant: montantDesDepenses(simulation.montantAFinancer).toObject(),
    },
  };

  return yield call(margeAdapter.getMarge, parametres);
}

import React from "react";
import ReactSelect, { components } from "react-select";
import PropTypes from "prop-types";
import { avecDefaultToujoursVisibles } from "./Select.filtres";

function Select({
  options,
  placeholder,
  style = {},
  onChange,
  value,
  focusOnMount,
  className = "",
  inputId,
  noOptionsMessage,
  customComponents = {},
  fonctionDeFiltre = avecDefaultToujoursVisibles,
}) {
  return (
    <div style={style} className={className}>
      <ReactSelect
        value={value}
        menuIsOpen={focusOnMount}
        autoFocus={focusOnMount}
        options={options}
        isSearchable
        onChange={(optionChoisie) => onChange(optionChoisie)}
        placeholder={placeholder}
        styles={customStyles}
        filterOption={fonctionDeFiltre}
        inputId={inputId}
        noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : undefined}
        components={{
          Option: customComponents.Option || components.Option,
        }}
      />
    </div>
  );
}

const defaultReactSelectColor = "hsl(0,0%,80%)";
const hauteurDuSelect = "2rem";
const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: hauteurDuSelect,
    height: hauteurDuSelect,
    boxShadow: "none",
    borderColor: state.isActive
      ? defaultReactSelectColor
      : defaultReactSelectColor,
    "&:hover": {
      borderColor: defaultReactSelectColor,
      cursor: "pointer",
    },
  }),
  menu: (base) => ({ ...base, marginTop: "2px" }),
  dropdownIndicator: (base) => ({ ...base, padding: "0 4px" }),
  indicators: (base) => ({ ...base, height: hauteurDuSelect }),
  valueContainer: (base) => ({ ...base, height: hauteurDuSelect }),
  container: (base) => ({ ...base, height: hauteurDuSelect }),
};

const uneOption = {
  value: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  estUnDefault: PropTypes.bool,
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(uneOption),
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape(uneOption)),
      }),
    ])
  ).isRequired,
  value: PropTypes.shape({
    value: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  focusOnMount: PropTypes.bool,
  inputId: PropTypes.string,
  noOptionsMessage: PropTypes.string,
};

export default Select;

// Boilerplate pour prendre en compte https://react-select.com/components#replacing-components
// Le composant passé en paramètre ne reçoit que {data}
export function WrapCustomOption(component) {
  return (props) => {
    return (
      <div
        ref={props.innerRef}
        {...props.innerProps}
        style={props.getStyles("option", props)}
      >
        {component(props.data)}
      </div>
    );
  };
}

import { AnomalieMargeTropFaible } from "../Anomalies";
import { selectTauxSeuilDeDerogation } from "../../simulation/margeCible/reducer";
import {
  tauxDeMargeEffectif,
  valeurDuMontantAFinancer,
} from "../../simulation/montantAFinancer/montants";
import { selectDerogateurDeMargeConnecte } from "../../simulation/derogateurs/reducer";

export function verifierMargeSuffisante(simulation) {
  const { montantAFinancer, margeCible, derogateurs } = simulation;

  const rienAFinancer = valeurDuMontantAFinancer(montantAFinancer).isZero();
  if (rienAFinancer) return;

  const tauxDuPlan = tauxDeMargeEffectif(montantAFinancer);
  const seuilDeDerogation = selectTauxSeuilDeDerogation(margeCible);
  const margeEstTropFaible = tauxDuPlan < seuilDeDerogation;
  if (!margeEstTropFaible) return;

  const derogateurConnecte = selectDerogateurDeMargeConnecte(derogateurs);
  const tauxMinimalAutonomie = derogateurConnecte
    ? derogateurConnecte.autonomieDeDerogation
    : seuilDeDerogation;

  return AnomalieMargeTropFaible(tauxMinimalAutonomie, tauxDuPlan);
}

import { CODES_GROUPES_OPTIONS } from "../../../../../Connecteurs/Crm/codesGroupesOptions";
import { UrlsCrm } from "../../../../../Connecteurs/Crm/Urls";

const { TYPES_CONTRAT, etatEntite } = CODES_GROUPES_OPTIONS;

export function erreursEmpechantUtilisationDuProjet(projet) {
  const erreurs = [];

  const projetSansClient = projet.client === null;
  if (projetSansClient)
    erreurs.push({ intlId: "projet_de_travail.erreurs.projet_sans_client" });

  const clientSansTauxDeTva =
    projet.client !== null && projet.client.tva.tauxApplique === null;
  if (clientSansTauxDeTva)
    erreurs.push({
      intlId: "projet_de_travail.erreurs.client_sans_taux_de_tva",
    });

  const projetSansTypeDeContrat = projet.idTypeDeContrat === null;
  if (projetSansTypeDeContrat)
    erreurs.push({
      intlId: "projet_de_travail.erreurs.projet_sans_type_de_contrat",
    });

  const typeContratIdsNecessitantContratRattache = [
    TYPES_CONTRAT.AER,
    TYPES_CONTRAT.AVENANT,
    TYPES_CONTRAT.PROLONGATION_NEGOCIEE,
  ];

  const projetNecessiteUnContratRattache = typeContratIdsNecessitantContratRattache.some(
    id => id === projet.idTypeDeContrat
  );
  const pasDeContratRattache = projet.contratRattache === null;

  if (projetNecessiteUnContratRattache && pasDeContratRattache)
    erreurs.push({
      intlId:
        "projet_de_travail.erreurs.annule_et_remplace_sans_contrat_rattache",
      balises: {
        nouvelleLigne: "<br/>",
        urlProjetCrm: UrlsCrm.projet(projet.id).ouvrir,
      },
    });

  const projetSansMarche = projet.marche === null;
  if (projetSansMarche)
    erreurs.push({ intlId: "projet_de_travail.erreurs.projet_sans_marche" });

  const marcheSansBroker = !projetSansMarche && projet.marche.broker === null;
  if (marcheSansBroker)
    erreurs.push({ intlId: "projet_de_travail.erreurs.marche_sans_broker" });

  const projetNonEnCours = projet.etat !== etatEntite.enCours;
  if (projetNonEnCours)
    erreurs.push({ intlId: "projet_de_travail.erreurs.projet_non_en_cours" });

  return erreurs;
}

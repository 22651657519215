import { combineReducers } from "redux";
import assuranceEspagneReducer, {
  montantPrimeAssuranceEspagneParLoyer,
} from "./espagne/reducer";
import assuranceFranceReducer, {
  montantPrimeAssuranceFranceParLoyer,
} from "./france/reducer";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";

const { ESPAGNE, FRANCE } = MARCHES;

export default combineReducers({
  espagne: assuranceEspagneReducer,
  france: assuranceFranceReducer,
});

export function primeParLoyerDuMarche(state, marcheUtilisateur) {
  const primeParMarche = {
    [ESPAGNE]: () => montantPrimeAssuranceEspagneParLoyer(state.espagne),
    [FRANCE]: () => montantPrimeAssuranceFranceParLoyer(state.france),
  };

  return primeParMarche[marcheUtilisateur]();
}

import { selectProduitAchete } from "../../simulation/montantAFinancer/reducer";
import {
  PAS_DE_DEROGATION,
  selectDerogationDeVr,
} from "../../simulation/derogations/reducer";
import { AnomalieVrDejaRefusee } from "../Anomalies";
import { STATUT_DEROGATION } from "../../../../Derogations/domaine/reducer";
import { TYPES_INVALIDITES_DEROGATION_DE_VR } from "../../simulation/derogations/vr/invalidites/Invalidites";
import { selectInvaliditesDerogationDeVr } from "../../simulation/derogations/vr/invalidites/selectInvaliditesDerogationDeVr";
import { lesTauxDeVrDemandes } from "../../simulation/valeurResiduelle/selecteurs/taux.selecteurs";

const {
  TAUX_MANUEL_DEPASSE_LE_TAUX_ACCORDE,
} = TYPES_INVALIDITES_DEROGATION_DE_VR;
const { REFUSEE, ACCORD_PARTIEL } = STATUT_DEROGATION;

export function verifierLesVrDejaRefusees(simulation) {
  const { montantAFinancer, valeurResiduelle, derogations } = simulation;

  return lesTauxDeVrDemandes(valeurResiduelle)
    .map(([idProduit, tauxDemande]) => {
      const produit = selectProduitAchete(montantAFinancer, idProduit);
      const derogationDeVr = selectDerogationDeVr(derogations, produit);
      return { tauxDemande, derogationDeVr };
    })
    .filter(({ derogationDeVr }) => laDerogationExiste(derogationDeVr))
    .filter(({ derogationDeVr }) =>
      laDerogationEstToujoursValide(derogationDeVr, simulation)
    )
    .filter(({ derogationDeVr }) => laDecisionRecueEstNegative(derogationDeVr))
    .filter(({ tauxDemande, derogationDeVr }) =>
      leNouveauTauxDemandeEstTropHaut(tauxDemande, derogationDeVr)
    )
    .map(() => AnomalieVrDejaRefusee());
}

const laDerogationExiste = (derogationDeVr) =>
  derogationDeVr !== PAS_DE_DEROGATION;

const laDerogationEstToujoursValide = (derogationDeVr, simulation) => {
  const invalidites = selectInvaliditesDerogationDeVr(
    derogationDeVr,
    simulation
  );
  const invaliditesAutreQueLeTaux = invalidites.filter(
    (i) => i.idType !== TAUX_MANUEL_DEPASSE_LE_TAUX_ACCORDE
  );
  return invaliditesAutreQueLeTaux.length === 0;
};

const laDecisionRecueEstNegative = (derogationDeVr) =>
  derogationDeVr.statut === REFUSEE || derogationDeVr.statut === ACCORD_PARTIEL;

const leNouveauTauxDemandeEstTropHaut = (tauxDemande, derogationDeVr) =>
  tauxDemande > derogationDeVr.valeurBroker;

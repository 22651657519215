import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { isNullMoneyObject } from "../../../../../Money/money";
import { UnPalier } from "../UnPalier";
import { supprimerDernierLoyer } from "../../../domaine/simulation/modeDeCalcul/paliers/supprimerDernierLoyer";
import { paliersSontDifferents } from "../paliersSontDifferents";

export class PaliersPourLoyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paliers: props.paliers,
    };
  }

  componentDidUpdate(prevProps) {
    const { paliers } = this.props;
    const paliersOntChange = paliersSontDifferents(paliers, prevProps.paliers);
    if (paliersOntChange) this.setState({ paliers: paliers });
  }

  render() {
    const { paliers } = this.state;

    const appliquerLesPaliersEstPossible =
      paliers.length > 0 &&
      this.paliersSontComplets() &&
      this.paliersDuStateSontModifies();

    return (
      <div className="paliers-souhaites">
        <div className="mb-10">
          {paliers.map((p, index) => (
            <div key={index}>
              <UnPalier
                index={index + 1}
                palier={p}
                loyerReadOnly={index === paliers.length - 1}
                onChange={(palier) => this.onChangeUnPalier(palier, index)}
                suppressionEstAutorisee={index !== 0}
                onDelete={() => this.supprimerPalier(index)}
              />
            </div>
          ))}
        </div>

        <div className="boutons-paliers">
          <button
            className="pure-button"
            data-testid="ajouter-palier"
            onClick={() => this.ajouterPalierVide()}
          >
            <FontAwesomeIcon icon={faPlus} size="xs" />
          </button>

          <button
            className="pure-button pure-button-primary ml-10"
            data-testid="appliquer-paliers"
            onClick={() => this.signalerChangement()}
            disabled={!appliquerLesPaliersEstPossible}
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            &nbsp;
            <FormattedMessage id="general.appliquer" />
          </button>
        </div>
      </div>
    );
  }

  ajouterPalierVide() {
    const palierVide = { duree: null, loyer: null };
    this.setState({
      paliers: [...this.state.paliers, palierVide],
    });
  }

  onChangeUnPalier(palier, indexDuPalier) {
    const paliersIntegres = this.integrerPalier(palier, indexDuPalier);
    this.setState({ paliers: paliersIntegres });
  }

  signalerChangement() {
    if (!this.paliersSontComplets()) return;

    const { paliers } = this.state;
    const sansDernierLoyer = supprimerDernierLoyer(
      paliers
    ).map(({ duree, loyer }) => ({ duree, loyer }));

    this.props.onPaliersChange(sansDernierLoyer);
  }

  integrerPalier(palier, indexDuPalier) {
    const { paliers } = this.state;

    return [
      ...paliers.slice(0, indexDuPalier),
      { ...palier },
      ...paliers.slice(indexDuPalier + 1),
    ];
  }

  paliersSontComplets() {
    const { paliers } = this.state;
    const dernierPalier = paliers[paliers.length - 1];

    return (
      paliers
        .filter((p) => p !== dernierPalier)
        .every(({ duree, loyer }) => duree && !isNullMoneyObject(loyer)) &&
      dernierPalier.duree
    );
  }

  supprimerPalier(indexDuPalier) {
    const { paliers } = this.state;
    const cible = paliers[indexDuPalier];
    const apresSuppression = paliers.filter((p) => p !== cible);
    this.setState({ paliers: apresSuppression });
  }

  paliersDuStateSontModifies() {
    const { paliers: paliersState } = this.state;
    const { paliers: paliersProps } = this.props;

    return paliersSontDifferents(paliersProps, paliersState);
  }
}

PaliersPourLoyer.propTypes = {
  paliers: PropTypes.array,
  onPaliersChange: PropTypes.func.isRequired,
};

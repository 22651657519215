import {
  AVANCER_PROGRESS_BAR,
  DEFINIR_MODE_AFFICHAGE,
  DEMARRER_PROGRESS_BAR,
  EFFACER_ERREUR,
  ERREUR_EST_SURVENUE,
  OPERATION_EN_COURS,
  OPERATION_SUCCES,
  OPERATION_TERMINEE,
  SUPPRIMER_OPERATION_SUCCES,
  SUPPRIMER_TOUS_LES_SUCCES,
} from "./actions";

export const MODE_AFFICHAGE = {
  CLASSIQUE: "CLASSIQUE",
  LIGHT: "LIGHT",
};
const { CLASSIQUE } = MODE_AFFICHAGE;

const STATE_INITIAL = {
  operationsEnCours: [],
  erreursParOperation: [],
  operationsSucces: [],
  progressBars: [],
  modeAffichage: CLASSIQUE,
};

export default function ui(state = STATE_INITIAL, action) {
  switch (action.type) {
    case OPERATION_EN_COURS:
      return {
        ...state,
        operationsEnCours: [...state.operationsEnCours, action.operation],
      };

    case OPERATION_TERMINEE:
      return {
        ...state,
        operationsEnCours: state.operationsEnCours.filter(
          (zone) => zone !== action.operation
        ),
      };

    case OPERATION_SUCCES:
      return {
        ...state,
        operationsSucces: [
          ...state.operationsSucces,
          { type: action.operation, idEntite: action.idEntite },
        ],
      };

    case SUPPRIMER_OPERATION_SUCCES:
      return {
        ...state,
        operationsSucces: state.operationsSucces.filter(
          (o) => o.type !== action.operation || o.idEntite !== action.idEntite
        ),
      };

    case SUPPRIMER_TOUS_LES_SUCCES:
      return {
        ...state,
        operationsSucces: state.operationsSucces.filter(
          (o) => o.type !== action.operation
        ),
      };

    case ERREUR_EST_SURVENUE:
      return {
        ...state,
        erreursParOperation: {
          ...state.erreursParOperation,
          [action.operation]: action.erreur.message,
        },
      };

    case EFFACER_ERREUR:
      return {
        ...state,
        erreursParOperation: {
          ...state.erreursParOperation,
          [action.operation]: undefined,
        },
      };

    case DEFINIR_MODE_AFFICHAGE:
      return { ...state, modeAffichage: action.modeAffichage };

    case DEMARRER_PROGRESS_BAR:
      const { operationMonitoree, max } = action;

      return {
        ...state,
        progressBars: [
          ...state.progressBars.filter(
            (p) => p.operation !== operationMonitoree
          ),
          { operation: operationMonitoree, avancement: 0, pourcentage: 0, max },
        ],
      };

    case AVANCER_PROGRESS_BAR:
      const cible = selectProgressBar(state, action.operationMonitoree);
      const miseAJour = {
        ...cible,
        description: action.description,
        avancement: cible.avancement + 1,
        pourcentage: ((cible.avancement + 1) / cible.max) * 100,
      };

      return {
        ...state,
        progressBars: [
          ...state.progressBars.filter((o) => o !== cible),
          miseAJour,
        ],
      };

    default:
      return state;
  }
}

export const uneOperationEstEnCours = (state, operationCherchee) => {
  const operations = mettreDansUnTableau(operationCherchee);

  for (const operation of operations)
    if (state.operationsEnCours.includes(operation)) return true;

  return false;
};

export const erreurDeOperation = (state, operationCherchee) => {
  const operations = mettreDansUnTableau(operationCherchee);

  return operations.reduce((messages, operation) => {
    return state.erreursParOperation[operation]
      ? [...messages, state.erreursParOperation[operation]]
      : messages;
  }, []);
};

export const selectOperationsSucces = (state) => state.operationsSucces;

export const estEnMode = (mode, state) => state.modeAffichage === mode;

export function selectProgressBar(state, operationMonitoree) {
  return state.progressBars.find((o) => o.operation === operationMonitoree);
}

function mettreDansUnTableau(element) {
  if (Array.isArray(element)) return element;
  else return [element];
}

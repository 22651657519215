import React from "react";
import PropTypes from "prop-types";

function noOp() {}

function Textarea(props) {
  const {
    value,
    onChange = noOp,
    onBlur = noOp,
    placeholder,
    readOnly,
    cols,
    rows,
    maxLength,
    style,
    className,
    testId,
  } = props;
  return (
    <textarea
      className={className}
      style={style}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur(e.target.value)}
      placeholder={placeholder}
      cols={cols}
      rows={rows}
      maxLength={maxLength}
      data-testid={testId}
      readOnly={readOnly}
    />
  );
}

Textarea.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  cols: PropTypes.number,
  rows: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  testId: PropTypes.string,
};

export default Textarea;

import { fetchAvecTokenAzure } from "../../authentification/fetch";

const URL_R4000 = process.env.REACT_APP_URL_R4000;

const ID_DE_R4000_DANS_AZURE = process.env.REACT_APP_ID_DE_R4000_DANS_AZURE;
export const scopeR4000 = `${ID_DE_R4000_DANS_AZURE}/user_impersonation`;

export const httpR4000 = {
  async get(cible) {
    const reponse = await fetchAvecTokenAzure(
      `${URL_R4000}${cible}`,
      scopeR4000,
      { method: "GET", headers: { "Content-Type": "application/json" } },
      extractErrorMessageR4000
    );

    return JSON.parse(reponse);
  },
};

export function extractErrorMessageR4000(textBody) {
  const erreurs = JSON.parse(textBody).errors;
  return erreurs[0].title;
}

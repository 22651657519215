import { UrlsForce } from "../../../../../../../routing/Urls";
import { enEuros } from "../../../../../../../Kit/formatage";
import { $obj } from "../../../../../../../Money/money";
import {
  produitEstNonReference,
  REFERENCEMENT_PRODUIT,
  suivantReferencement,
} from "../../../../simulation/catalogueProduits/reducer";
import { templating } from "../../../../../../../i18n/templating";
import {
  TEMPLATE_DESCRIPTION_DEROGATION,
  TEMPLATE_NOTIFICATION_DE_DEROGATION,
} from "./templates/TEMPLATE_NOUVELLE_DEROGATION_DE_VR";
import { TYPE_DE_FINANCEMENT } from "../../../../simulation/valeurResiduelle/reducer";

const { EST_REFERENCE, EST_NON_REFERENCE } = REFERENCEMENT_PRODUIT;
const { CB, LLD } = TYPE_DE_FINANCEMENT;

const LABELS_TYPE_DE_FINANCEMENT = {
  [CB]: "Crédit-bail",
  [LLD]: "LLD",
};

export function notificationDeNouvelleDerogationDeVr(
  depensesAvecDerogationDeVr,
  demandeurDeLaDerogation,
  duree,
  ft
) {
  const corps = templating(TEMPLATE_NOTIFICATION_DE_DEROGATION, {
    demandeur: demandeurDeLaDerogation,
    client: ft.client.nom,
    ft: ft.nom,
    typeDeFinancement: LABELS_TYPE_DE_FINANCEMENT[ft.typeDeFinancement],
    duree,
    details: depensesAvecDerogationDeVr.map(descriptionDerogation).join(""),
    urlSynthese: UrlsForce.syntheseDesDerogations(ft.id).vr,
  });

  const nombreDeDerogations = depensesAvecDerogationDeVr.length;
  const contientDuNonReference = depensesAvecDerogationDeVr.some((d) =>
    produitEstNonReference(d.produit)
  );

  const sujet = concatener(
    [
      "Demande de dérogation de VR",
      contientDuNonReference ? "[MATÉRIEL NON RÉFÉRENCÉ]" : null,
      demandeurDeLaDerogation,
      nombreDeDerogations === 1
        ? depensesAvecDerogationDeVr[0].depense.itl_name
        : `${nombreDeDerogations} dérogations`,
    ],
    " - "
  );

  return { sujet, corps };
}

function descriptionDerogation(grappeDepense, index) {
  const {
    depense,
    derogationDeVr: { derogationMappee },
    produit,
    marque,
  } = grappeDepense;

  return templating(TEMPLATE_DESCRIPTION_DEROGATION, {
    numeroDerogation: index + 1,
    styleMateriel: suivantReferencement(produit, {
      [EST_REFERENCE]: () => "",
      [EST_NON_REFERENCE]: () => "background-color:yellow",
    }),
    labelMateriel: concatener(
      [produit.label, depense.itl_isoccasion ? "(occasion)" : null],
      " "
    ),
    marque: marque.label,
    description: depense.itl_name,
    montantUnitaire: enEuros($obj(depense.itl_montantht)),
    vrDemandee: derogationMappee.itl_valeurdemandee,
    vrOrigine: derogationMappee.itl_valeurorigine,
    motivation: derogationMappee.itl_commentairesdemandeur,
  });
}

function concatener(morceaux, separateur) {
  return morceaux.filter((m) => m !== null).join(separateur);
}

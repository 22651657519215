import { useRef } from "react";

export function useFileInput() {
  // https://reactjs.org/docs/uncontrolled-components.html#the-file-input-tag
  const fileInput = useRef(null);

  function resetFileInput() {
    fileInput.current.value = null;
  }

  return [fileInput, resetFileInput];
}

import { DerogationDeTeg } from "./DerogationDeTeg";
import { connect } from "react-redux";
import {
  ACCEPTER_DEROGATION_DE_TEG,
  accepterDerogationDeTeg,
  REFUSER_DEROGATION_DE_TEG,
  refuserDerogationDeTeg,
} from "../../domaine/typesDerogations/teg/actions";
import { STATUT_DEROGATION } from "../../domaine/reducer";
import {
  erreurDeOperation,
  uneOperationEstEnCours,
} from "../../../../Ui/reducer";

const { ACCEPTEE, REFUSEE } = STATUT_DEROGATION;

const mapStateToProps = (state) => ({
  isLoading: uneOperationEstEnCours(state.ui, [
    ACCEPTER_DEROGATION_DE_TEG,
    REFUSER_DEROGATION_DE_TEG,
  ]),
  erreurs: erreurDeOperation(state.ui, [
    ACCEPTER_DEROGATION_DE_TEG,
    REFUSER_DEROGATION_DE_TEG,
  ]),
});

const mapDispatchToProps = (dispatch) => ({
  onAccepterDerogation: (derogation, commentaire) =>
    dispatch(
      accepterDerogationDeTeg(derogation, {
        statut: ACCEPTEE,
        dateDecision: Date.now(),
        commentaireDecideur: commentaire,
      })
    ),

  onRefuserDerogation: (derogation, commentaire) =>
    dispatch(
      refuserDerogationDeTeg(derogation, {
        statut: REFUSEE,
        dateDecision: Date.now(),
        commentaireDecideur: commentaire,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DerogationDeTeg);

import { listeAFinancer, listeDesProduitsAchetes, } from "../../../simulation/montantAFinancer/reducer";
import { isNullMoneyObject } from "../../../../../../Money/money";

export const BLOCAGES = {
  AUTRE_CHOSE_QUUN_UNIQUE_MATERIEL: "AUTRE_CHOSE_QUUN_UNIQUE_MATERIEL",
  AUCUN_PALIER_SAISI: "AUCUN_PALIER_SAISI",
  PALIER_SANS_DUREE: "PALIER_SANS_DUREE",
  PALIER_SANS_LOYER: "PALIER_SANS_LOYER",
  DUREE_DE_FINANCEMENT_HORS_GRILLE_DE_VR:
    "DUREE_DE_FINANCEMENT_HORS_GRILLE_DE_VR",
};

const {
  AUTRE_CHOSE_QUUN_UNIQUE_MATERIEL,
  AUCUN_PALIER_SAISI,
  PALIER_SANS_DUREE,
  PALIER_SANS_LOYER,
} = BLOCAGES;

export function planDeFinancementEstCalculable(simulation) {
  return blocagesDuCalculDuPlan(simulation).length === 0;
}

export function blocagesDuCalculDuPlan(simulation) {
  return [
    autreChoseQuunUniqueMateriel(simulation),
    aucunPalier(simulation),
    palierSansDuree(simulation),
    palierSansLoyer(simulation),
  ].filter((b) => b !== undefined);
}

function autreChoseQuunUniqueMateriel(simulation) {
  const { montantAFinancer } = simulation;
  const nombreTotal = listeAFinancer(montantAFinancer).length;
  const nombreDeMateriels = listeDesProduitsAchetes(montantAFinancer).length;

  if (nombreTotal !== 1 || nombreDeMateriels !== 1)
    return AUTRE_CHOSE_QUUN_UNIQUE_MATERIEL;
}

function aucunPalier(simulation) {
  const { modeDeCalcul } = simulation;
  if (modeDeCalcul.paliers.length === 0) return AUCUN_PALIER_SAISI;
}

function palierSansDuree(simulation) {
  const dureeManquante = simulation.modeDeCalcul.paliers.some(
    (p) => p.duree === undefined || p.duree === null || p.duree === 0
  );
  if (dureeManquante) return PALIER_SANS_DUREE;
}

function palierSansLoyer(simulation) {
  const loyerManquant = simulation.modeDeCalcul.paliers.some((p) =>
    isNullMoneyObject(p.loyer)
  );

  if (loyerManquant) return PALIER_SANS_LOYER;
}

import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import { connect, Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import CatchLesExceptions from "./reduxStore/enhancers/catchErreur/CatchLesExceptions.container";
import Admin from "./Admin/Admin";
import { CHARGER_IDENTITE_UTILISATEUR } from "./identiteUtilisateur/actions";
import BarreDeChargement from "./Kit/Loaders/BarreDeChargement";
import { erreurDeOperation, uneOperationEstEnCours } from "./Ui/reducer";
import Barriere from "./Kit/Erreur/Barriere";
import {
  selectMarcheUtilisateur,
  selectUtilisateurPasEncoreIdentifie,
} from "./identiteUtilisateur/reducer";
import Derogations from "./App/Derogations/composants/Derogations";
import { RECUPERER_REFERENTIELS } from "./referentiel/actions";
import { demarrerApp } from "./reduxStore/demarrage";
import { i18n } from "./i18n/i18n";
import { identiteUtilisateur } from "./reduxStore/rootReducer";
import MarcheUtilisateurSwitch from "./Admin/MarcheUtilisateurSwitch";
import { EntreeCalculateur } from "./App/Calculateur/composants/EntreeCalculateur";
import { ChoixDuMode } from "./App/ChoixDuMode/composants/ChoixDuMode";
import EntreeModeLight from "./App/ModeLight/composants/EntreeModeLight";
import { UrlsForce } from "./routing/Urls";

function App({
  store,
  demarrerApp,
  isLoading,
  messagesErreurs,
  i18n,
  enableAdmin,
}) {
  useEffect(() => {
    demarrerApp();
  }, [demarrerApp]);

  const aucuneErreur = messagesErreurs.length === 0;

  return (
    <Provider store={store}>
      <Router>
        <IntlProvider locale={i18n.locale} messages={i18n.messages}>
          <div className="app">
            <CatchLesExceptions>
              {isLoading && aucuneErreur ? (
                <div className="mt-20">
                  <BarreDeChargement />
                </div>
              ) : (
                <Barriere messages={messagesErreurs}>
                  <Switch>
                    <Route exact path="/" component={ChoixDuMode} />
                    <Route
                      path={UrlsForce.calculateur().path}
                      component={EntreeCalculateur}
                    />
                    <Route
                      path={UrlsForce.derogations().path}
                      component={Derogations}
                    />
                    <Route
                      path={UrlsForce.modeLight().path}
                      component={EntreeModeLight}
                    />

                    {enableAdmin && (
                      <Route exact path="/admin" component={Admin} />
                    )}
                    {enableAdmin && (
                      <Route
                        exact
                        path="/admin/marche/(france|espagne)"
                        component={MarcheUtilisateurSwitch}
                      />
                    )}

                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </Barriere>
              )}
            </CatchLesExceptions>
          </div>
        </IntlProvider>
      </Router>
    </Provider>
  );
}

App.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  messagesErreurs: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  demarrerApp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading:
    uneOperationEstEnCours(state.ui, [
      CHARGER_IDENTITE_UTILISATEUR,
      RECUPERER_REFERENTIELS,
    ]) || selectUtilisateurPasEncoreIdentifie(state.identiteUtilisateur),

  messagesErreurs: erreurDeOperation(state.ui, [
    CHARGER_IDENTITE_UTILISATEUR,
    RECUPERER_REFERENTIELS,
  ]),

  i18n: i18n.geti18n(selectMarcheUtilisateur(identiteUtilisateur(state))),

  enableAdmin: process.env.REACT_APP_ENABLE_ADMIN === "true",
});

const mapDispatchToProps = (dispatch) => ({
  demarrerApp: () => dispatch(demarrerApp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

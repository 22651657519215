import {
  POSTE_UTILISATEUR,
  selectIdCrmUtilisateurEst,
  selectMarcheUtilisateur,
} from "../../../../identiteUtilisateur/reducer";
import { selectMarchesEtBrokers } from "../../../../referentiel/reducer";
import { TYPE_DE_DEROGATION } from "../reducer";
import { taux } from "../../../../Kit/formatage";
import { guidEqual } from "../../../../utils/guidUtils";
import { seuilAutonomieDuDirecteurRegional } from "../../../Calculateur/domaine/simulation/derogateurs/marge/seuilsAutonomieDesDirecteursRegionaux";

const { VR, MARGE, ASSURANCE_ESPAGNE } = TYPE_DE_DEROGATION;
const {
  DIRECTEUR_REGIONAL,
  DIRECTEUR_COMMERCIAL,
  COMMERCIAL,
} = POSTE_UTILISATEUR;

export const permissionSurDerogation = {
  peutDecider(derogation, identiteUtilisateur, referentiel) {
    return PEUT_DECIDER_PAR_TYPE[derogation.type](
      derogation,
      identiteUtilisateur,
      referentiel
    );
  },
};

const PEUT_DECIDER_PAR_TYPE = {
  [VR]: (derogation, identiteUtilisateur, referentiel) => {
    return identiteUtilisateur.poste === "BROKER";
  },

  [MARGE]: (derogation, identiteUtilisateur) => {
    const prerogative = prerogativeDeMargeDuPoste(
      identiteUtilisateur.poste,
      selectMarcheUtilisateur(identiteUtilisateur),
      derogation.projet.marche.id,
      derogation.projet.marqueItl.id
    );
    const demande = taux(derogation.valeurDemandee);
    const prerogativePermetDeDecider =
      demande >= prerogative || prerogative === 0;
    return prerogativePermetDeDecider;
  },

  [ASSURANCE_ESPAGNE]: (derogation, identiteUtilisateur) => {
    const utilisateurEstDerogateur = selectIdCrmUtilisateurEst(
      identiteUtilisateur,
      derogation.derogateur.utilisateur.id
    );
    return utilisateurEstDerogateur;
  },
};

export function prerogativeDeMargeDuPoste(
  poste,
  marcheUtilisateur,
  idMarcheProjet,
  idMarqueItl
) {
  const prerogativesParPoste = {
    [DIRECTEUR_COMMERCIAL]: () => 0,
    [COMMERCIAL]: () => 1,
    [DIRECTEUR_REGIONAL]: () =>
      seuilAutonomieDuDirecteurRegional({
        marcheUtilisateur,
        idMarcheProjet,
        idMarqueItl,
      }),
  };

  return prerogativesParPoste[poste]();
}

import { Adapters } from "../../../../../../reduxStore/adapters";
import { call, put, select } from "redux-saga/effects";
import {
  choixDuProjet,
  identiteUtilisateur,
} from "../../../../../../reduxStore/rootReducer";
import { selectMarcheUtilisateur } from "../../profilUtilisateur/reducer";
import {
  RECUPERER_DEROGATEUR_DE_MARGE_MAITRE,
  recupererDerogateurDeMarge_succes,
} from "../actions";
import { erreurEstSurvenue } from "../../../../../../Ui/actions";
import { ID_TYPE_DEROGATION_DE_MARGE } from "./marge.constantes";
import { guidEqual } from "../../../../../../utils/guidUtils";
import { POSTE_UTILISATEUR } from "../../../../../../identiteUtilisateur/reducer";

const { DIRECTEUR_COMMERCIAL } = POSTE_UTILISATEUR;

export function* recupererLeDerogateurMaitreSaga({
  derogateursAdapter = Adapters.derogateursAdapter,
} = {}) {
  try {
    const utilisateurMaitre = yield call(getUtilisateurMaitre);

    const derogateur = yield call(
      derogateursAdapter.getDerogateurUnique,
      ID_TYPE_DEROGATION_DE_MARGE,
      utilisateurMaitre.idUtilisateur
    );

    const autonomieInfinieCarDerogateurMaitre = 0;

    yield put(
      recupererDerogateurDeMarge_succes({
        idDerogateur: derogateur.idDerogateur,
        idUtilisateur: utilisateurMaitre.idUtilisateur,
        poste: DIRECTEUR_COMMERCIAL,
        autonomieDeDerogation: autonomieInfinieCarDerogateurMaitre,
        nom: derogateur.nom,
        estUtilisateurConnecte: utilisateurMaitre.estUtilisateurConnecte,
      })
    );
  } catch (erreur) {
    yield put(erreurEstSurvenue(RECUPERER_DEROGATEUR_DE_MARGE_MAITRE, erreur));
  }
}

export function* getUtilisateurMaitre({
  identiteAdapter = Adapters.identiteAdapter,
} = {}) {
  const identite = yield select(identiteUtilisateur);
  const utilisateurMaitre = yield call(
    identiteAdapter.getUtilisateurMaitre,
    selectMarcheUtilisateur(identite)
  );

  const { projetDeTravail } = yield select(choixDuProjet);
  const projetAppartientAuDerogateurMaitre = guidEqual(
    projetDeTravail.commercial.idUtilisateur,
    utilisateurMaitre.idUtilisateur
  );

  return {
    idUtilisateur: utilisateurMaitre.idUtilisateur,
    estUtilisateurConnecte: projetAppartientAuDerogateurMaitre,
  };
}

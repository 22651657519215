const SUJET = "Réponse à votre demande de dérogation de VR";

const CORPS = `Bonjour, <br /><br />
Un avis a été donné par le broker {broker} sur votre demande de dérogation de VR du {dateDemande} sur le projet "{projet}". <br />
{decision}
<br /><br />

Commentaire :
<p style="background-color: #eeeeee;padding:10px;font-style: italic">
{commentaireBroker}
</p>

<br /><br />

Vous pouvez retourner sur le projet et créer une nouvelle fiche de transmission avec les VR acceptées en <a href="{urlFtForce}">cliquant ici</a>. <br /><br />
Cet avis clôture la demande de dérogation mais seul le service Refi sera habilité à décider de la valeur finale accordée.
`;

const DECISION_STANDARD =
  'La VR pour le matériel "{familleMateriel}" de la marque "{marqueMateriel}" a été proposée à {pourcentageAvis} soit {valeurAvis} (valeur demandée : {pourcentageDemande}, valeur grille : {pourcentageOrigine}).';

const DECISION_REFUS_CATEGORIQUE =
  'La demande pour le matériel "{familleMateriel}" de la marque "{marqueMateriel}" a été refusée catégoriquement.';

export const TEMPLATE_AVIS_SUR_DEROG_FR = {
  CORPS,
  SUJET,
  DECISION_STANDARD,
  DECISION_REFUS_CATEGORIQUE,
};

import { call, put } from "redux-saga/effects";
import {
  CHARGER_IDENTITE_UTILISATEUR,
  chargerIdentiteUtilisateur_succes,
} from "./actions";
import { reporterSentry } from "../reporting/reporterSentry";
import { Adapters } from "../reduxStore/adapters";
import { trySaga } from "../utils/sagasUtils";

export function* chargerIdentiteUtilisateurSaga({
  identiteAdapter = Adapters.identiteAdapter,
  reporter = reporterSentry,
} = {}) {
  const saga = function* () {
    const identite = yield call(identiteAdapter.getIdentiteDuConnecte);

    if (!identite.poste) throw posteInconnu();

    yield call(reporter.setUsername, identite.email);
    yield put(chargerIdentiteUtilisateur_succes(identite));
  };

  yield* trySaga(saga, CHARGER_IDENTITE_UTILISATEUR);
}

function posteInconnu() {
  return new Error(
    "Impossible de déterminer votre poste utilisateur. Force a besoin d'un poste pour être utilisé."
  );
}

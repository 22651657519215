import Dinero from "dinero.js";
import { montantArrondi } from "../Kit/formatage";
import PropTypes from "prop-types";

Dinero.defaultCurrency = "EUR";

export const Zero = $(0);

export function $(montantEuros) {
  if (montantEuros === null || montantEuros === undefined) return NULL_MONEY;

  return Dinero({ amount: Math.round(montantEuros * 100), precision: 2 });
}

export const NULL_MONEY = {
  ...Dinero(),
  toObject() {
    return null;
  },
};

export function isNullMoneyObject(moneyObject) {
  if (moneyObject === undefined) return true;
  return Money(moneyObject).toObject() === null;
}

export function Money(moneyObject) {
  if (moneyObject === null) return NULL_MONEY;

  return Dinero(moneyObject);
}

export function $obj(montant) {
  return $(montant).toObject();
}

export function forInput(montantObject) {
  if (isNullMoneyObject(montantObject)) return null;
  return montantArrondi(montantObject, 2);
}

export const MoneyPropType = PropTypes.shape({
  amount: PropTypes.number,
  precision: PropTypes.number,
  currency: PropTypes.string,
});

export function toCentimes(montantObject) {
  return Money(montantObject).getAmount();
}

export function argentVide(moneyObject) {
  return isNullMoneyObject(moneyObject) || Money(moneyObject).isZero();
}

export function somme$obj(montants) {
  return montants.reduce((total, m) => Money(total).add(Money(m)).toObject());
}

export function sontProches(a, b, ecartCentimes) {
  return sontProchesObj($obj(a), $obj(b), ecartCentimes);
}

export function sontProchesObj(objA, objB, ecartCentimes) {
  return Math.abs(toCentimes(objA) - toCentimes(objB)) <= ecartCentimes;
}

import { connect } from "react-redux";
import UneDemandeDeDerogation from "./UneDemandeDeDerogation";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import { enrichirAvecMotivation } from "../../../domaine/motivationsDesAnomalies/reducer";
import {
  ajouterPieceJointe,
  motiverAnomalie,
  supprimerPieceJointe,
} from "../../../domaine/motivationsDesAnomalies/actions";

const mapStateToProps = (state, ownProps) => {
  const { simulation, motivationsDesAnomalies } = calculateur(state);

  return {
    simulation,
    anomalie: enrichirAvecMotivation(
      ownProps.anomalie,
      motivationsDesAnomalies
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (anomalieConcernee, texte) =>
    dispatch(motiverAnomalie(anomalieConcernee, texte)),
  onPieceJointePick: (anomalieConcernee, pieceJointe) =>
    dispatch(ajouterPieceJointe(anomalieConcernee, pieceJointe)),
  onPieceJointeDelete: (pieceJointe) =>
    dispatch(supprimerPieceJointe(pieceJointe)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UneDemandeDeDerogation);

import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import InputEntier from "../../../../Kit/Formulaires/InputEntier";
import InputMontant from "../../../../Kit/Formulaires/InputMontant";
import Euros from "../../../../Kit/Finance/Euros";
import { $obj, forInput, NULL_MONEY } from "../../../../Money/money";

export function UnPalier(props) {
  const {
    index,
    palier,
    loyerReadOnly,
    onChange,
    onDelete,
    suppressionEstAutorisee,
  } = props;

  return (
    <div className="mb-5">
      <InputEntier
        value={palier.duree}
        onChange={(valeur) => onChange({ ...palier, duree: valeur })}
        onFocus={() => onChange({ ...palier, duree: null })}
        className="input-s"
        testId={`palier-${index}-input-duree`}
      />
      &nbsp;x&nbsp;
      <InputMontant
        value={forInput(palier.loyer)}
        onBlur={(valeur) => onChange({ ...palier, loyer: $obj(valeur) })}
        onFocus={() => onChange({ ...palier, loyer: NULL_MONEY.toObject() })}
        className={`input-s ${loyerReadOnly ? "no-frame-border no-bg" : ""}`}
        appendClassName={loyerReadOnly ? "no-frame-border no-bg" : ""}
        append={<Euros />}
        disabled={loyerReadOnly}
        testId={`palier-${index}-input-loyer`}
      />
      {suppressionEstAutorisee && (
        <FontAwesomeIcon
          icon={faTrash}
          className="mr-5 ml-10 pointable"
          onClick={onDelete}
          data-testid={`palier-${index}-supprimer`}
        />
      )}
    </div>
  );
}

UnPalier.propTypes = {
  index: PropTypes.number.isRequired,
  palier: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  suppressionEstAutorisee: PropTypes.bool.isRequired,
  loyerReadOnly: PropTypes.bool.isRequired,
};

export const CHARGER_PRODUITS_SUCCES = "CHARGER_PRODUITS_SUCCES";
export const chargerProduits_succes = produits => ({
  type: CHARGER_PRODUITS_SUCCES,
  produits,
});

export const CHARGER_MARQUES_DE_PRODUITS_SUCCES =
  "CHARGER_MARQUES_DE_PRODUITS_SUCCES";
export const chargerMarquesDeProduits_succes = marquesDeProduits => ({
  type: CHARGER_MARQUES_DE_PRODUITS_SUCCES,
  marquesDeProduits,
});

export const CHARGER_CATEGORIES_PRODUIT_SUCCES =
  "CHARGER_CATEGORIES_PRODUIT_SUCCES";
export const chargerCategoriesProduit_succes = categories => ({
  type: CHARGER_CATEGORIES_PRODUIT_SUCCES,
  categories,
});

export const CHARGER_SELECTION_DE_MATERIELS_DISPONIBLES =
  "CHARGER_SELECTION_DES_MATERIELS_DISPONIBLES";
export const chargerSelectionDesMaterielsDisponibles = selection => ({
  type: CHARGER_SELECTION_DE_MATERIELS_DISPONIBLES,
  selection,
});

import { PERIODICITES } from "../../App/Calculateur/domaine/simulation/modeDeCalcul/periodicite/periodicite";
import { TERMES } from "../../finance/TERMES";
import { MARCHES } from "../../identiteUtilisateur/reducer";
import {
  DELAIS_DE_REGLEMENT,
  MODES_DE_REGLEMENT,
} from "../../App/Calculateur/domaine/simulation/conditionsDeReglement/reducer";
import { TYPE_DE_FINANCEMENT } from "../../App/Calculateur/domaine/simulation/valeurResiduelle/reducer";

const { CB, LLD } = TYPE_DE_FINANCEMENT;
const { ESPAGNE, FRANCE } = MARCHES;
const { ECHU, A_ECHOIR } = TERMES;
const { TRIMESTRIEL, MENSUEL, ANNUEL } = PERIODICITES;
const {
  PRELEVEMENT_AUTOMATIQUE,
  CHEQUE,
  MANDAT,
  VIREMENT,
} = MODES_DE_REGLEMENT;
const {
  CINQUANTE_JOURS,
  A_RECEPTION,
  SOIXANTE_JOURS,
  TRENTE_JOURS,
  QUATREVINGTDIX_JOURS,
} = DELAIS_DE_REGLEMENT;

export const LABELS_PERIODICITE_K3000 = {
  [MENSUEL]: "periodicite.mensuelle",
  [TRIMESTRIEL]: "periodicite.trimestrielle",
  [ANNUEL]: "periodicite.annuelle",
};

export const LABELS_TERME_K3000 = {
  [A_ECHOIR]: "terme.a_echoir",
  [ECHU]: "terme.echu",
};

export const LABELS_PAYS_K3000 = {
  [FRANCE]: "fr",
  [ESPAGNE]: "es",
};

export const LABELS_MODE_DE_REGLEMENT_K3000 = {
  [PRELEVEMENT_AUTOMATIQUE]: "modeReglement.prelevement",
  [VIREMENT]: "modeReglement.virement",
  [CHEQUE]: "modeReglement.cheque",
  [MANDAT]: "modeReglement.mandat",
};

export const LABELS_DELAI_DE_REGLEMENT_K3000 = {
  [A_RECEPTION]: 0,
  [TRENTE_JOURS]: 30,
  [CINQUANTE_JOURS]: 50,
  [SOIXANTE_JOURS]: 60,
  [QUATREVINGTDIX_JOURS]: 90,
};

export const LABELS_TYPE_DE_FINANCEMENT_K3000 = {
  [CB]: "cb",
  [LLD]: "lld",
};

import { selectAnomalieDeVrDuProduit } from "../../../../anomalies/Anomalies";
import { pourcentage } from "../../../../../../../Kit/formatage";
import {
  labelCatalogue,
  produitEstNonReference,
} from "../../../../simulation/catalogueProduits/reducer";
import { CODES_GROUPES_OPTIONS } from "../../../../../../../Connecteurs/Crm/codesGroupesOptions";
import { selectMotivation } from "../../../../motivationsDesAnomalies/reducer";
import { STATUT_DEROGATION } from "../../../../../../Derogations/domaine/reducer";
import { statutDeLaDecision } from "../../../../../../Derogations/domaine/typesDerogations/vr/statutDeLaDecision";
import { tronquer } from "../../../../../../../utils/stringUtils";

const { statutDeDerogation } = CODES_GROUPES_OPTIONS;
const { A_DECIDER } = STATUT_DEROGATION;

export const PAS_DE_DEROGATION_DE_VR = undefined;
const ID_TYPE_DE_DEROGATION_DE_VR =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_VR;

export function derogationDeVrDeProduitAchete(produit, calculateur) {
  const anomalieDeVr = selectAnomalieDeVrDuProduit(
    calculateur.anomalies,
    produit.id
  );
  const produitSansAnomalieDeVr = anomalieDeVr === undefined;

  if (produitSansAnomalieDeVr) return PAS_DE_DEROGATION_DE_VR;

  if (anomalieDeVr.dejaDerogee) {
    return mapperDepuisDerogationAnterieure(anomalieDeVr, produit, calculateur);
  } else {
    return mapperNouvelleDerogation(anomalieDeVr, produit, calculateur);
  }
}

function getNomDerogation(anomalieDeVr, produit, simulation) {
  const occasion = produit.estOccasion ? " (occasion) " : " ";
  const demande = pourcentage(anomalieDeVr.details.vr.manuelle);
  const seuil = pourcentage(anomalieDeVr.details.vr.seuil);
  const label = labelCatalogue(simulation.catalogueProduits, produit);

  return tronquer(
    `VR${occasion}à ${demande} au lieu de ${seuil} sur ${label}`,
    100
  );
}

function mapperDepuisDerogationAnterieure(anomalieDeVr, produit, calculateur) {
  const derogationAnterieure = anomalieDeVr.derogation;
  const validee = `DEMANDE VALIDÉE AUTOMATIQUEMENT`;
  const pourcentageDecision = derogationAnterieure.valeurBroker * 100;

  const statutNouvelleDerogation = statutDeLaDecision(
    {
      valeurDemandee: anomalieDeVr.details.vr.manuelle * 100,
      valeurOrigine: anomalieDeVr.details.vr.seuil * 100,
    },
    pourcentageDecision
  );

  return {
    depuisUneDerogationAnterieure: true,
    derogationMappee: {
      itl_statutderogation: statutDeDerogation.get(statutNouvelleDerogation),
      itl_name: getNomDerogation(anomalieDeVr, produit, calculateur.simulation),
      itl_valeurorigine: pourcentage(anomalieDeVr.details.vr.seuil),
      itl_valeurdemandee: pourcentage(anomalieDeVr.details.vr.manuelle),
      itl_vrbroke: pourcentageDecision,
      itl_commentairesdemandeur: `${validee} (dérogation déjà obtenue) : ${
        derogationAnterieure.commentaires.demandeur
      }`,
      itl_commentairesdecideur:
        derogationAnterieure.commentaires.decideur !== null
          ? `${validee} (dérogation déjà accordée) : ${
              derogationAnterieure.commentaires.decideur
            }`
          : null,
      itl_commentairebroke:
        derogationAnterieure.commentaires.broker !== null
          ? `${validee} (dérogation déjà obtenue) : ${
              derogationAnterieure.commentaires.broker
            }`
          : null,
      itl_datecombroke: derogationAnterieure.dateAvisBroker,
      itl_datedecision: derogationAnterieure.dateAvisBroker,
      "itl_Typederogation@odata.bind": `/itl_typederogations(${ID_TYPE_DE_DEROGATION_DE_VR})`,
      "itl_derogateur@odata.bind": derogationAnterieure.idDerogateur
        ? `/itl_derogateurs(${derogationAnterieure.idDerogateur})`
        : undefined,
      "itl_broketitl@odata.bind": derogationAnterieure.idBroker
        ? `/systemusers(${derogationAnterieure.idBroker})`
        : undefined,
    },
    piecesJointes: [],
  };
}

function mapperNouvelleDerogation(anomalieDeVr, produit, calculateur) {
  const { motivationsDesAnomalies, simulation } = calculateur;
  const { motivation, piecesJointes } = selectMotivation(
    motivationsDesAnomalies,
    anomalieDeVr
  );

  return {
    depuisUneDerogationAnterieure: false,
    derogationMappee: {
      itl_name: getNomDerogation(anomalieDeVr, produit, simulation),
      itl_valeurdemandee: pourcentage(anomalieDeVr.details.vr.manuelle),
      itl_valeurorigine: pourcentage(anomalieDeVr.details.vr.seuil),
      itl_commentairesdemandeur: `${motivation}${
        produitEstNonReference(anomalieDeVr.details.produit)
          ? `\n\n${anomalieDeVr.details.produit.description}`
          : ""
      }`,
      itl_statutderogation: statutDeDerogation.get(A_DECIDER),
      "itl_Typederogation@odata.bind": `/itl_typederogations(${ID_TYPE_DE_DEROGATION_DE_VR})`,
      "itl_broketitl@odata.bind": `/systemusers(${
        anomalieDeVr.personneConsultee.idUtilisateur
      })`,
    },
    piecesJointes: piecesJointes.map(pj => ({
      body: pj.base64,
      nomDuFichier: pj.name,
      mimeType: pj.type,
    })),
  };
}

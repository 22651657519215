import { STATUT_DEROGATION } from "../../reducer";

const { ACCEPTEE, ACCORD_PARTIEL, REFUSEE } = STATUT_DEROGATION;

export function statutDeLaDecision(demande, pourcentageDecision) {
  const valeurDemandeeOuPlus = pourcentageDecision >= demande.valeurDemandee;
  if (valeurDemandeeOuPlus) return ACCEPTEE;

  const valeurOrigineOuMoins = pourcentageDecision <= demande.valeurOrigine;
  if (valeurOrigineOuMoins) return REFUSEE;

  return ACCORD_PARTIEL;
}

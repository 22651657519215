import { call, takeEvery } from "redux-saga/effects";
import { recupererReferentielsSaga } from "../referentiel/sagas";
import { chargerIdentiteUtilisateurSaga } from "../identiteUtilisateur/sagas";

const DEMARRER_APP = "DEMARRER_APP";
export const demarrerApp = () => ({ type: DEMARRER_APP });

export function* sagaDeDemarrage() {
  yield takeEvery(DEMARRER_APP, function* () {
    yield call(recupererReferentielsSaga);
    yield call(chargerIdentiteUtilisateurSaga);
  });
}

import { httpR4000 } from "../../../../../Connecteurs/R4000/httpR4000";
import { CODE_PAYS_R4000 } from "../../../../../Connecteurs/R4000/labelsR4000";
import { GET_MARQUE_GENERIQUE, ID_MARQUE_GENERIQUE } from "./reducer";
import { getTauxDepuisPourcentage } from "../../../../../Connecteurs/Crm/utils";
import { GRILLE_DE_VR_VIDE } from "../valeurResiduelle/reducer";
import { MARCHES } from "../../../../../identiteUtilisateur/reducer";

const { ESPAGNE, FRANCE } = MARCHES;
export const ID_MATERIEL_NON_REFERENCE =
  process.env.REACT_APP_ID_MATERIEL_NON_REFERENCE;
const ID_CATEGORIE_DIVERS_R4000 =
  process.env.REACT_APP_ID_CATEGORIE_DIVERS_R4000;

export const catalogueProduitsAdapterR4000 = {
  async getCatalogue(
    marcheUtilisateur,
    marcheProjet,
    { http = httpR4000 } = {}
  ) {
    const produits = await getProduitsDuMarche(
      { marcheProjet, marcheUtilisateur },
      { http }
    );

    return {
      produits: [
        ...produits.map(p => ({
          id: p.id,
          crm: { id: p.crmGuidProduit },
          label: getLibelle(p.localisations, marcheUtilisateur),
          marche: getLibelle(p.categorie?.localisations, marcheUtilisateur),
          categorie: { id: p.categorie?.id },
          motsCles: p.motsCles,
        })),
        GET_PRODUIT_NON_REFERENCE(marcheUtilisateur),
      ],
      marques: getMarques(produits, marcheUtilisateur),
      grillesDeVr: getGrillesDeVr(produits, marcheUtilisateur),
    };
  },

  async getCategoriesProduit(marcheUtilisateur, { http = httpR4000 } = {}) {
    const categories = await http.get(
      "/api/produits/categories?pagination=false"
    );

    const resultat = [];

    const categoriePasEncoreVue = idCategorie =>
      !resultat.find(({ id }) => id === idCategorie);

    for (const c of categories) {
      if (c.parent === null) {
        if (categoriePasEncoreVue(c.id))
          resultat.push(categorieDirecte(c, marcheUtilisateur));
      } else {
        if (categoriePasEncoreVue(c.parent.id))
          resultat.push(categorieParente(c, marcheUtilisateur));

        resultat.find(({ id }) => id === c.parent.id).categoriesEnfants.push({
          id: c.id,
          nom: getLibelle(c.localisations, marcheUtilisateur),
        });
      }
    }

    return resultat;
  },
};

async function getProduitsDuMarche(
  { marcheProjet, marcheUtilisateur },
  { http }
) {
  const categoriesDuMarche = await http.get(
    `/api/produits/categories/guid_crm_du_marche/${
      marcheProjet.id
    }?pagination=false`
  );
  const categories = categoriesDuMarche
    .map(c => `categorie.id[]=${c.id}`)
    .join("&");

  const sansPagination = `pagination=false`;
  const avecLocalisation = `localisations.disponible=true`;
  const dispoPourLePays = `localisations.pays.code=${
    CODE_PAYS_R4000[marcheUtilisateur]
  }`;

  return await http.get(
    `/api/produits/produits_itl?${sansPagination}&${avecLocalisation}&${dispoPourLePays}&${categories}`
  );
}

function categorieDirecte(c, marcheUtilisateur) {
  return {
    id: c.id,
    nom: getLibelle(c.localisations, marcheUtilisateur),
    categoriesEnfants: [],
  };
}

function categorieParente(c, marcheUtilisateur) {
  return {
    id: c.parent.id,
    nom: getLibelle(c.parent.localisations, marcheUtilisateur),
    categoriesEnfants: [],
  };
}

function getLibelle(localisations, marcheUtilisateur) {
  return localisations?.find(
    l => l.codePays === CODE_PAYS_R4000[marcheUtilisateur]
  )?.libelle;
}

function getMarques(produits, marcheUtilisateur) {
  const toutesLesMarques = produits
    .map(p => p.marques)
    .flat()
    .filter(m => m.actif)
    .map(m => ({ id: m.id, label: m.libelle, crm: { id: m.crmGuidMarque } }));

  const uniques = toutesLesMarques.reduce((toutes, m) => {
    const dejaPresente = toutes.find(({ id }) => id === m.id);
    return dejaPresente ? toutes : [...toutes, m];
  }, []);

  return [...uniques, GET_MARQUE_GENERIQUE(marcheUtilisateur)];
}

function getGrillesDeVr(produits, marcheUtilisateur) {
  const lesProduits = extractProduits(produits);
  const lesVrs = extractGrillesDeVr(produits);

  return lesProduits.map(
    p =>
      trouverGrilleDePaysEtMarque(p) ??
      trouverGrilleDeMarque(p) ??
      trouverGrilleDePays(p) ??
      trouverGrilleGenerique(p)
  );

  function trouverGrilleDePaysEtMarque(p) {
    const vr = lesVrs.find(
      vr =>
        vr.idProduit === p.idProduit &&
        vr.pays === CODE_PAYS_R4000[marcheUtilisateur] &&
        vr.idMarque === p.idMarque
    );
    return vr ? { ...p, vrParMois: vr.vrParMois } : null;
  }

  function trouverGrilleDeMarque(p) {
    const vr = lesVrs.find(
      vr =>
        vr.idProduit === p.idProduit &&
        vr.pays === undefined &&
        vr.idMarque === p.idMarque
    );
    return vr ? { ...p, vrParMois: vr.vrParMois } : null;
  }

  function trouverGrilleDePays(p) {
    const vr = lesVrs.find(
      vr =>
        vr.idProduit === p.idProduit &&
        vr.pays === CODE_PAYS_R4000[marcheUtilisateur] &&
        vr.idMarque === undefined
    );
    return vr ? { ...p, vrParMois: vr.vrParMois } : null;
  }

  function trouverGrilleGenerique(p) {
    const vr = lesVrs.find(
      vr =>
        vr.idProduit === p.idProduit &&
        vr.pays === undefined &&
        vr.idMarque === ID_MARQUE_GENERIQUE
    );
    return vr ? { ...p, vrParMois: vr.vrParMois } : null;
  }
}

function enGrilleForce(grilleR4000) {
  return grilleR4000.valeurs.reduce(
    (grille, ligne) => ({
      ...grille,
      [ligne.borneInferieureEnNombreDeMois]: getTauxDepuisPourcentage(
        ligne.pourcentageValeurResiduelle
      ),
    }),
    {}
  );
}

function GET_PRODUIT_NON_REFERENCE(marcheUtilisateur) {
  const labels = {
    [FRANCE]: { produit: "Matériel non référencé", marche: "DIVERS" },
    [ESPAGNE]: { produit: "Equipo no referenciado", marche: "VARIOS" },
  };
  return {
    id: ID_MATERIEL_NON_REFERENCE,
    label: labels[marcheUtilisateur].produit,
    marche: labels[marcheUtilisateur].marche,
    categorie: { id: ID_CATEGORIE_DIVERS_R4000 },
    motsCles: [],
    crm: { id: ID_MATERIEL_NON_REFERENCE },
  };
}

export function extractProduits(produitsR4000) {
  const produits = [];

  for (const produit of produitsR4000) {
    const generique = { idProduit: produit.id, idMarque: ID_MARQUE_GENERIQUE };
    produits.push(generique);

    for (const marque of produit.marques.filter(m => m.actif)) {
      const deMarque = { idProduit: produit.id, idMarque: marque.id };
      produits.push(deMarque);
    }
  }

  const nonReference = {
    idProduit: ID_MATERIEL_NON_REFERENCE,
    idMarque: ID_MARQUE_GENERIQUE,
  };
  produits.push(nonReference);

  return produits;
}

export function extractGrillesDeVr(produitsR4000) {
  const grilles = [];

  for (const p of produitsR4000) {
    const generique = {
      idProduit: p.id,
      idMarque: ID_MARQUE_GENERIQUE,
      vrParMois:
        p.grilleDeValeursResiduelles === null
          ? GRILLE_DE_VR_VIDE
          : enGrilleForce(p.grilleDeValeursResiduelles),
    };
    grilles.push(generique);

    const declinaisons = p.declinaisonsDeGrilleDeValeursResiduelles ?? [];
    for (const declinaison of declinaisons) {
      const vrParMois = enGrilleForce(declinaison.grilleDeValeursResiduelles);
      const marques = getMarquesDeclinees(declinaison);
      const codePays = getCodePaysDeclines(declinaison);

      for (const m of marques)
        for (const pays of codePays)
          grilles.push({ idProduit: p.id, idMarque: m.id, pays, vrParMois });
    }
  }

  const grilleDuNonReference = {
    idProduit: ID_MATERIEL_NON_REFERENCE,
    idMarque: ID_MARQUE_GENERIQUE,
    vrParMois: GRILLE_DE_VR_VIDE,
  };
  grilles.push(grilleDuNonReference);

  return grilles;
}

function getMarquesDeclinees(declinaison) {
  // Le [{}] va permettre de faire un tour de boucle même en l'absence de marques
  return declinaison.marques.length > 0
    ? declinaison.marques.filter(m => m.actif)
    : [{}];
}

function getCodePaysDeclines(declinaison) {
  // Le [undefined] va permettre de faire un tour de boucle même en l'abscence de code pays
  return declinaison.codePays.length > 0 ? declinaison.codePays : [undefined];
}

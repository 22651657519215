import { call } from "redux-saga/effects";
import { Adapters } from "../../../../reduxStore/adapters";
import { crmSendEmail } from "../../../../Connecteurs/Crm/sendEmail";
import { contenuDeLaNotification } from "./contenu";

export function* notifierLeCommercialDeLaDecisionSaga(
  idDerogation,
  {
    detailPourNotificationAdapter = Adapters.detailPourNotificationAdapter,
    envoyerEmail = crmSendEmail,
  } = {}
) {
  const details = yield call(
    detailPourNotificationAdapter.getDetailPourNotification,
    idDerogation
  );

  const notification = yield call(contenuDeLaNotification, details);

  yield call(envoyerEmail, {
    sujet: notification.sujet,
    destinataire: `/systemusers(${details.demandeur.id})`,
    corps: notification.texte,
    regarding: {
      type: "itl_derogation",
      id: `/itl_derogations(${idDerogation})`,
    },
  });
}

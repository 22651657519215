import { Money, Zero } from "../../../../../Money/money";
import { AnomalieLoyerNegatif } from "../Anomalies";

export function verifierLesLoyersNegatifs(planDeFinancement) {
  const auMoinsUnNegatif = planDeFinancement.paliers.some(
    (p) =>
      Money(p.loyer).lessThan(Zero) || Money(p.loyerFullService).lessThan(Zero)
  );

  if (auMoinsUnNegatif) return AnomalieLoyerNegatif();
}

import React from "react";
import PropTypes from "prop-types";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uuid from "uuid";
import { fichierEnBase64 } from "./InputFile";
import { useFileInput } from "./useFileInput.hook";

export const InputMultipleFiles = ({ files, onFilePick, onFileDelete }) => (
  <div>
    <div className="flex-wrap">
      {files.map((f, index) => (
        <UnFichier
          key={`${f.name}-${index}`}
          fichier={f}
          onDeleteClick={() => onFileDelete(f)}
        />
      ))}
    </div>
    <div className="mt-10">
      <SelectionnerFichier onAjout={(fichier) => onFilePick(fichier)} />
    </div>
  </div>
);

InputMultipleFiles.propTypes = {
  files: PropTypes.array.isRequired,
  onFilePick: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
};

function UnFichier({ fichier, onDeleteClick }) {
  return (
    <div className="mr-10 mb-5 flex-ai-center" style={style.fichier}>
      <div style={style.nomFichier}>{fichier.name}</div>
      <FontAwesomeIcon
        icon={faTrash}
        className="mr-10 pointable"
        style={style.supprimerFichier}
        onClick={onDeleteClick}
      />
    </div>
  );
}

function SelectionnerFichier(props) {
  const [fileInput, resetFileInput] = useFileInput();

  return (
    <input
      type="file"
      accept={fichiersAcceptes}
      ref={fileInput}
      className="pointable"
      onChange={(e) => {
        const file = e.target.files[0];
        fichierEnBase64(file).then((base64) => {
          props.onAjout({
            id: uuid(),
            name: file.name,
            type: file.type,
            base64,
            tailleEnKo: file.size / 1024,
          });
          resetFileInput();
        });
      }}
    />
  );
}

const pdf = [".pdf"];
const word = [".docx", ".doc"];
const excel = [".xls", ".xlsx"];
const fichiersAcceptes = [pdf, word, excel].flat().join(",");

const style = {
  fichier: { fontSize: "inherit" },
  nomFichier: {
    border: "1px solid #ccc",
    borderRadius: "3px 0 0 3px",
    padding: "3px 10px",
  },
  supprimerFichier: {
    border: "1px solid #cccccc",
    borderRadius: "0 3px 3px 0",
    borderLeft: "none",
    padding: "4px 5px",
    textAlign: "center",
    backgroundColor: "buttonface",
  },
};

import { CHARGER_PROJET_DE_TRAVAIL_SUCCES } from "./actions";
import { CODES_GROUPES_OPTIONS } from "../../../../../Connecteurs/Crm/codesGroupesOptions";

const { TYPES_CONTRAT } = CODES_GROUPES_OPTIONS;

const STATE_INITIAL = {
  projetDeTravail: null,
};

export function projetDeTravailReducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case CHARGER_PROJET_DE_TRAVAIL_SUCCES:
      return { ...state, projetDeTravail: action.projetDeTravail };

    default:
      return state;
  }
}

export const projetDeTravailEstAER = state =>
  [
    TYPES_CONTRAT.AER,
    TYPES_CONTRAT.AVENANT,
    TYPES_CONTRAT.PROLONGATION_NEGOCIEE,
    TYPES_CONTRAT.CB_VENTE,
  ].includes(state.projetDeTravail.idTypeDeContrat);

export const selectClient = state => state.projetDeTravail.client;
export const selectTvaDuClient = state => selectClient(state).tva.tauxApplique;
export const selectProjetDeTravail = state => state.projetDeTravail;
export const selectBrokerDuMarche = state =>
  state.projetDeTravail.marche.broker;
export const selectMarqueItlChoisie = state => state.projetDeTravail.marqueItl;
export const selectMarcheChoisi = state => state.projetDeTravail.marche;

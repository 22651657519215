import { httpK3000 } from "../../../../../Connecteurs/K3000/httpK3000";
import { Money } from "../../../../../Money/money";
import {
  LABELS_PERIODICITE_K3000,
  LABELS_TERME_K3000,
} from "../../../../../Connecteurs/K3000/labelsK3000";

export const margeAdapterK3000 = {
  async getMarge(
    { aFinancerSansMarge, paliers, periodicite, teg, terme, vr, depenses },
    { http = httpK3000 } = {}
  ) {
    const margeK3000 = await http.post(
      "/api/v1/financier/calcul/montant-marge",
      {
        listeDePaliersDeLoyer: paliers.map(p => ({
          nombreDEcheances: p.duree,
          montant: Money(p.loyer)
            .toUnit()
            .toString(),
        })),
        montantValeurResiduelle: Money(vr.montant)
          .toUnit()
          .toString(),
        terme: LABELS_TERME_K3000[terme],
        typeDePeriodicite: LABELS_PERIODICITE_K3000[periodicite],
        tegAnnuel: teg.toString(),
        montantInvestissement: Money(aFinancerSansMarge.montant)
          .toUnit()
          .toString(),
      }
    );

    return {
      taux: calculerTauxPrecis(margeK3000.montantMarge, depenses.montant),
    };
  },
};

export function calculerTauxPrecis(montantMargeK3000, montantDesDepenses) {
  const taux = montantMargeK3000 / Money(montantDesDepenses).toUnit();
  return Number(taux.toFixed(6));
}

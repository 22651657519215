import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import ListeDesDerogations from "./ListeDesDerogations/ListeDesDerogations.container";
import DerogationsDeVrParProjet from "./DerogationsDeVrParProjet/DerogationsDeVrParProjet.container";
import { ChargerDerogationDetaille } from "./Aiguillage/ChargerDerogationDetaillee";
import { RedirectVersDerogationsParProjet } from "./DerogationsDeVrParProjet/RedirectVersDerogationsParProjet";

function Derogations(props) {
  const { match } = props;
  const derogations = match.path;

  return (
    <Switch>
      <Route exact path={derogations} component={ListeDesDerogations} />

      <Route
        exact
        path={`${derogations}/ft/:id/vr`}
        render={({ match }) => {
          return <RedirectVersDerogationsParProjet idFt={match.params.id} />;
        }}
      />

      <Route
        exact
        path={`${derogations}/projet/:id/vr`}
        render={({ match }) => {
          const idProjet = match.params.id;
          return <DerogationsDeVrParProjet idProjet={idProjet} />;
        }}
      />

      <Route
        exact
        path={`${derogations}/demande/:id`}
        component={ChargerDerogationDetaille}
      />

      <Route path="*">
        <Redirect to={derogations} />
      </Route>
    </Switch>
  );
}

Derogations.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Derogations;

const un = 0.01;
const deux_et_demi = 0.025;
const quatre_et_demi = 0.045;
const cinq = 0.05;
const six = 0.06;
const sept_et_demi = 0.075;

export const TAUX_DE_MARGE_CIBLE = {
  FRANCE: {
    MEDICAL: cinq,
    ENV_IND: six,
    DIVERS: quatre_et_demi,
    HIGH_TECH: quatre_et_demi,
    INFO_BUREAUTIQUE: quatre_et_demi,
    OL_ENVIRONNEMENT: quatre_et_demi,
  },
  ESPAGNE: {
    MEDICAL: sept_et_demi,
    ENV_IND: sept_et_demi,
    DIVERS: sept_et_demi,
    HIGH_TECH: sept_et_demi,
    INFO_BUREAUTIQUE: sept_et_demi,
    OL_ENVIRONNEMENT: sept_et_demi,
  },
};

export const TAUX_SEUIL_DE_DEROGATION = {
  FRANCE: {
    MEDICAL: {
      LIZEMED: 0,
      AUTRE: {
        NOUVEAU_CLIENT: quatre_et_demi,
        CLIENT_EXISTANT: quatre_et_demi,
      },
    },
    ENV_IND: quatre_et_demi,
    DIVERS: quatre_et_demi,
    HIGH_TECH: quatre_et_demi,
    INFO_BUREAUTIQUE: quatre_et_demi,
    OL_ENVIRONNEMENT: quatre_et_demi,
  },
  ESPAGNE: {
    MEDICAL: un,
    ENV_IND: un,
    DIVERS: un,
    HIGH_TECH: un,
    INFO_BUREAUTIQUE: un,
    OL_ENVIRONNEMENT: un,
  },
};

export const SEUILS_AUTONOMIE_DES_DIRECTEURS_REGIONAUX = {
  FRANCE: {
    MEDICAL: {
      LIZEMED: 0,
      AUTRE: un,
    },
    ENV_IND: un,
    DIVERS: un,
    HIGH_TECH: un,
    INFO_BUREAUTIQUE: un,
    OL_ENVIRONNEMENT: un,
  },
  ESPAGNE: {
    MEDICAL: un,
    ENV_IND: un,
    DIVERS: un,
    HIGH_TECH: un,
    INFO_BUREAUTIQUE: un,
    OL_ENVIRONNEMENT: un,
  },
};

import { call, put, select } from "redux-saga/effects";
import { crmGet } from "../../../../../Connecteurs/Crm/webApi";
import { taux } from "../../../../../Kit/formatage";
import {
  ajouterNotificationDeDerogationDeVr,
  chargerLesDerogationsDeMarge,
} from "./actions";
import {
  CODES_GROUPES_OPTIONS,
  MAPPING_CRM_VERS_DOMAINE,
} from "../../../../../Connecteurs/Crm/codesGroupesOptions";
import {
  calculateur,
  choixDuProjet,
} from "../../../../../reduxStore/rootReducer";
import { STATUT_DEROGATION } from "../../../../Derogations/domaine/reducer";
import { Adapters } from "../../../../../reduxStore/adapters";
import { selectProjetDeTravail } from "../projetDeTravail/reducer";

const { statutDeDerogation } = CODES_GROUPES_OPTIONS;
const { ACCEPTEE, A_DECIDER } = STATUT_DEROGATION;
const ID_TYPE_DEROGATION_DE_MARGE =
  process.env.REACT_APP_ID_TYPE_DE_DEROGATION_MARGE;

export function* chargerDerogationsDeMargeDuProjetSaga() {
  const { simulation } = yield select(calculateur);
  const idProjet = selectProjetDeTravail(simulation.projetDeTravail).id;

  const filtreSurProjet = `_itl_projetconcerne_value eq '${idProjet}'`;
  const acceptee = statutDeDerogation.get(ACCEPTEE);
  const aDecider = statutDeDerogation.get(A_DECIDER);
  const filtreDerogationEnCours = `(itl_statutderogation eq ${acceptee} or itl_statutderogation eq ${aDecider})`;
  const filtreTypeMarge = `_itl_typederogation_value eq '${ID_TYPE_DEROGATION_DE_MARGE}'`;

  const derogations = yield call(crmGet, "itl_derogations", {
    $select: [
      "itl_name",
      "itl_datedemande",
      "itl_datedecision",
      "itl_statutderogation",
      "_itl_derogateur_value",
      "itl_valeurdemandee",
      "itl_valeurorigine",
      "itl_commentairesdecideur",
      "itl_commentairesdemandeur",
    ],
    $filter: [filtreSurProjet, filtreDerogationEnCours, filtreTypeMarge],
  });

  yield put(
    chargerLesDerogationsDeMarge(
      derogations.map(transformationDerogationDeMargeCrmEnFormatForce)
    )
  );
}

const transformationDerogationDeMargeCrmEnFormatForce = derogation => {
  const { statutDerogation } = MAPPING_CRM_VERS_DOMAINE;

  return {
    nom: derogation.itl_name,
    dateDeDemande: derogation.itl_datedemande,
    statut: statutDerogation[derogation.itl_statutderogation],
    idDerogateur: derogation._itl_derogateur_value,
    taux: {
      origine: taux(derogation.itl_valeurorigine),
      demande: taux(derogation.itl_valeurdemandee),
    },
    commentaires: {
      demandeur: derogation.itl_commentairesdemandeur,
      decideur: derogation.itl_commentairesdecideur,
    },
    dateDeDecision: derogation.itl_datedecision,
  };
};

export function* chargerDerniereNotificationDeVrSaga({
  notificationDeVrAdapter = Adapters.notificationDeVrAdapter,
} = {}) {
  const { projetDeTravail } = yield select(choixDuProjet);

  const notification = yield call(notificationDeVrAdapter.getDerniereSansAvis, {
    idProjet: projetDeTravail.id,
  });

  yield put(ajouterNotificationDeDerogationDeVr(notification));
}

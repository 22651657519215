import { formatjjMMaa, formatjjMMaaaa } from "../../../../../utils/dateUtils";

export function descriptionCompleteDeRachatDeContrat(rachat, longueurMax) {
  const morceauxDePrefixe = [];

  const pasDansLaDescription = (texte) => !rachat.description.includes(texte);

  if (pasDansLaDescription(rachat.numeroContrat))
    morceauxDePrefixe.push(`N. ${rachat.numeroContrat}`);

  if (rachat.dateEncours) {
    const dateAnneeDeuxChiffres = formatjjMMaa(rachat.dateEncours);
    const dateAnneeQuatreChiffres = formatjjMMaaaa(rachat.dateEncours);
    const descriptionSansDateEncours =
      pasDansLaDescription(dateAnneeDeuxChiffres) &&
      pasDansLaDescription(dateAnneeQuatreChiffres);
    if (descriptionSansDateEncours)
      morceauxDePrefixe.push(dateAnneeDeuxChiffres);
  }

  const { materielRachete } = rachat;
  if (pasDansLaDescription(materielRachete.label))
    morceauxDePrefixe.push(materielRachete.label);

  const separateur = " ";
  const prefixe = `${morceauxDePrefixe.join(separateur)}.`;

  const descriptionDejaComplete = morceauxDePrefixe.length === 0;

  const complete = descriptionDejaComplete
    ? rachat.description
    : [prefixe, rachat.description].join(separateur).substring(0, longueurMax);

  return {
    complete: complete,
    caracteresRestants: longueurMax - (prefixe.length + separateur.length),
  };
}

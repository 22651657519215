import { httpServiceCrm } from "../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../utils/odata/odata";
import { TYPE_DE_DEROGATION } from "../../reducer";
import { MAPPING_CRM_VERS_DOMAINE } from "../../../../../Connecteurs/Crm/codesGroupesOptions";
import { rapportFicheTransmissionUrl } from "../../../../../Connecteurs/Crm/rapports";

const { TEG } = TYPE_DE_DEROGATION;
const { statutDerogation } = MAPPING_CRM_VERS_DOMAINE;

export const derogationDeTegAdapterCrm = {
  async getDerogation(idDerogation, { http = httpServiceCrm } = {}) {
    const ft = [
      "itl_FTconcernee",
      { $select: ["itl_fichetransmissionid", "itl_name"] },
    ];
    const derogateur = ["itl_derogateur", { $select: ["itl_name"] }];
    const auteur = ["createdby", { $select: ["systemuserid", "fullname"] }];

    const derogation = await http.get(
      odataQuery(`itl_derogations(${idDerogation})`, {
        $select: [
          "itl_derogationid",
          "itl_name",
          "itl_statutderogation",
          "itl_commentairesdemandeur",
          "itl_commentairesdecideur",
          "itl_datedecision",
        ],
        $expand: [ft, derogateur, auteur],
      })
    );

    return {
      id: derogation.itl_derogationid,
      type: TEG,
      nom: derogation.itl_name,
      statut: statutDerogation[derogation.itl_statutderogation],
      motivation: derogation.itl_commentairesdemandeur,
      commentaireDecideur: derogation.itl_commentairesdecideur,
      dateDecision: derogation.itl_datedecision,
      ft: {
        nom: derogation.itl_FTconcernee.itl_name,
        urlRapport: rapportFicheTransmissionUrl(
          derogation.itl_FTconcernee.itl_fichetransmissionid
        ),
      },
      auteur: {
        id: derogation.createdby.systemuserid,
        nom: derogation.createdby.fullname,
      },
      derogateur: { nom: derogation.itl_derogateur?.itl_name },
    };
  },
};

import "./polyfills.js";
import React from "react";
import ReactDOM from "react-dom";
import "react-drawer/lib/react-drawer.css";
import { reporterSentry } from "./reporting/reporterSentry";
import { i18n } from "./i18n/i18n";
import { AppAvecAuthentificationRequise } from "./authentification/MSAL";
import App from "./App";
import { getStore } from "./reduxStore/store";

i18n.init();

const reporter = reporterSentry;
reporter.init();

const Force = () => {
  const store = getStore();
  return <App store={store} />;
};

ReactDOM.render(
  <AppAvecAuthentificationRequise>
    <Force />
  </AppAvecAuthentificationRequise>,
  document.getElementById("root")
);

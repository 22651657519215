import React from "react";
import { FormattedEnhancedMessage } from "react-intl-enhanced-message";

export function ht(intl) {
  return intl.formatMessage({ id: "general.hors_taxe" });
}

export function htOuTtc(intl, recupereLaTva) {
  return intl.formatMessage({
    id: recupereLaTva ? "general.hors_taxe" : "general.ttc",
  });
}

export function enhancedMessage(id, values) {
  return (
    <FormattedEnhancedMessage
      id={id}
      enhancers={{
        strong: (texte) => <strong>{texte}</strong>,
        retourLigne: () => <br />,
      }}
      values={values}
    />
  );
}

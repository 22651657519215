export const ACTIVER_MAINTENANCE = "ACTIVER_MAINTENANCE";
export const activerMaintenance = (maintenance) => ({
  type: ACTIVER_MAINTENANCE,
  maintenance,
});

export const DECALER_DEBUT_DE_MAINTENANCE = "DECALER_DEBUT_DE_MAINTENANCE";
export const decalerDebutDeMaintenance = (nbEcheancesDeDecalage) => ({
  type: DECALER_DEBUT_DE_MAINTENANCE,
  nbEcheancesDeDecalage,
});

export const DEFINIR_MONTANT_MAINTENANCE_PAR_ECHEANCE =
  "DEFINIR_MONTANT_MAINTENANCE_PAR_ECHEANCE";
export const definirMaintenanceParEcheance = (montantParEcheance) => ({
  type: DEFINIR_MONTANT_MAINTENANCE_PAR_ECHEANCE,
  montantParEcheance,
});

export const PASSER_EN_MAINTENANCE_AVEC_EQUILIBRAGE =
  "PASSER_EN_MAINTENANCE_AVEC_EQUILIBRAGE";
export const passerEnMaintenanceAvecEquilibrage = () => ({
  type: PASSER_EN_MAINTENANCE_AVEC_EQUILIBRAGE,
});

export const REVENIR_EN_MAINTENANCE_SANS_EQUILIBRAGE =
  "REVENIR_EN_MAINTENANCE_SANS_EQUILIBRAGE";
export const revenirEnMaintenanceSansEquilibrage = () => ({
  type: REVENIR_EN_MAINTENANCE_SANS_EQUILIBRAGE,
});

export const DESACTIVER_MAINTENANCE = "DESACTIVER_MAINTENANCE";
export const desactiverMaintenance = () => ({ type: DESACTIVER_MAINTENANCE });

import { CHARGER_MARGE_CIBLE_SUCCES } from "./actions";

const STATE_INITIAL = {
  tauxSeuilDeDerogation: null,
  tauxDeMargeCible: null,
};

const reducer = (state = STATE_INITIAL, action) => {
  switch (action.type) {
    case CHARGER_MARGE_CIBLE_SUCCES:
      return { ...state, ...action.margeCible };

    default:
      return state;
  }
};

export default reducer;

export const selectTauxSeuilDeDerogation = (state) =>
  state.tauxSeuilDeDerogation;

import { Money } from "../../../../../Money/money";

// Cette fonction permet de soustraire les montants "facturés pour compte" au full service.
// ITL facture pour compte la maintenance : donc si on a 1 200 de Full Service avec 300 de maintenance,
// cette fonction va transformer ça en Full Service de 900.
// C'est utile pour le cas où la seule raison d'avoir du multi-p dans Force est la maintenance :
// dans ce cas on ne veut *pas* plusieurs paliers en CRM.
// Autrement dit : pour le CRM, la maintenance ne rentre pas dans le full service.
// Car elle est facturée pour compte.

export function soustraireLeFacturePourCompte(paliers) {
  return paliers.map((p) => {
    const loyerFullService = p.avecMaintenance
      ? Money(p.loyerFullService)
          .subtract(Money(p.maintenance.montantParEcheance))
          .toObject()
      : p.loyerFullService;

    return { ...p, loyerFullService };
  });
}

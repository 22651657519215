import { call, put, select } from "redux-saga/effects";
import { chargerPlanDeFinancement } from "../../actions";
import { Adapters } from "../../../../../../reduxStore/adapters";
import { calculateur } from "../../../../../../reduxStore/rootReducer";
import { planDeFinancementEstCalculable } from "./planDeFinancementEstCalculable";
import {
  PLAN_DE_FINANCEMENT_VIDE,
  PlanDeFinancement,
} from "../../PlanDeFinancement/PlanDeFinancement";
import { listeDesProduitsAchetes } from "../../../simulation/montantAFinancer/reducer";
import { financerProduitAchete } from "../../../simulation/montantAFinancer/actions";
import { recupererLesVrGrillesSaga } from "../../../simulation/valeurResiduelle/sagas";
import { getFraisDeReglement } from "./fraisDeReglement";
import { getParametresDuPlan } from "./planDeFinancement.parametres";

export function* orchestrationDuCalculInvestissement({
  planDeFinancementAdapter = Adapters.planDeFinancementInvestissementAdapter,
  fraisDeReglementAdapter = Adapters.fraisDeReglementAdapter,
  tegAdapter = Adapters.tegAdapter,
} = {}) {
  const { simulation } = yield select(calculateur);

  if (!planDeFinancementEstCalculable(simulation)) {
    yield put(chargerPlanDeFinancement(PLAN_DE_FINANCEMENT_VIDE));
    return;
  }

  yield* recupererLesVr();
  const invest = yield* recupererInvestissement({ planDeFinancementAdapter });
  yield* valoriserMontantDuMateriel(invest.montant);
  yield* calculerPlanDeFinancement({ fraisDeReglementAdapter, tegAdapter });
}

function* recupererLesVr() {
  yield call(recupererLesVrGrillesSaga);
}

function* recupererInvestissement({ planDeFinancementAdapter }) {
  const { simulation } = yield select(calculateur);
  const reponse = yield call(planDeFinancementAdapter.calculerPlan, simulation);
  return reponse.investissement;
}

function* valoriserMontantDuMateriel(montantUnitaire) {
  const { simulation } = yield select(calculateur);
  const materiel = listeDesProduitsAchetes(simulation.montantAFinancer)[0];
  yield put(financerProduitAchete({ ...materiel, montantUnitaire }));
}

function* calculerPlanDeFinancement({ fraisDeReglementAdapter, tegAdapter }) {
  const fraisDeReglement = yield* getFraisDeReglement({
    fraisDeReglementAdapter,
    tegAdapter,
  });

  const { simulation } = yield select(calculateur);
  const paliers = simulation.modeDeCalcul.paliers;
  const plan = PlanDeFinancement(
    paliers,
    getParametresDuPlan(simulation, fraisDeReglement)
  );
  yield put(chargerPlanDeFinancement(plan));
}

import { httpServiceCrm } from "../../../../../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../../../../../utils/odata/odata";
import { Money } from "../../../../../../../Money/money";
import {
  montantPrimeAssuranceFranceAnnuelle,
  OPTION_ASSURANCE,
} from "../../../../simulation/assurance/france/reducer";

const { GARANTIE_DES_LOYERS, PERTE_DE_RECETTES } = OPTION_ASSURANCE;

export const creerAssuranceFranceAdapterCrm = {
  async ajouterAssuranceSurDepense(
    { materiel, depenseCrm, primeAssurance },
    { http = httpServiceCrm } = {}
  ) {
    const assuranceCrm = await getAssuranceCrm(materiel.crm, { http });
    const selectionAssurance = await creerSelectionAssurance(
      { assuranceCrm, primeAssurance },
      { http }
    );
    await assurerDepense(
      { depenseCrm, assuranceCrm, selectionAssurance, primeAssurance },
      { http }
    );
  },

  async ajouterAssuranceSurFt(
    idFt,
    stateAssurance,
    { http = httpServiceCrm } = {}
  ) {
    const montantTotalAssurance = montantPrimeAssuranceFranceAnnuelle(
      stateAssurance
    ).toUnit();

    await http.patch(`itl_fichetransmissions(${idFt})`, {
      itl_montantassurance: montantTotalAssurance,
      itl_montantassurancebris: montantTotalAssurance,
      itl_montantassuranceptia: 0,
    });
  },

  async ajouterAssuranceSurLoyer(
    loyerCrm,
    palierForce,
    montantPrimeParLoyer,
    { http = httpServiceCrm } = {}
  ) {
    await http.patch(`itl_loyerfts(${loyerCrm.id})`, {
      itl_montantassuranceecheance: Money(montantPrimeParLoyer).toUnit(),
      itl_montanthtfullservice: Money(palierForce.loyerFullService).toUnit(),
    });
  },
};

async function getAssuranceCrm(familleProduitCrm, { http }) {
  const famille = await http.get(
    odataQuery(`itl_familles(${familleProduitCrm.id})`, {
      $select: ["_itl_categorieassuranceid_value"],
    })
  );
  const idCategorie = famille._itl_categorieassuranceid_value;
  const categorie = await http.get(
    odataQuery(`itl_categorieassurances(${idCategorie})`, {
      $expand: [
        "itl_itl_assurance_itl_categorieassurance",
        { $select: ["itl_assuranceid", "itl_name"] },
      ],
    })
  );
  const nbAssurances =
    categorie.itl_itl_assurance_itl_categorieassurance.length;
  if (nbAssurances !== 1) {
    throw new TropDeAssuranceDansCategorieErreur(idCategorie, nbAssurances);
  }

  const [assurance] = categorie.itl_itl_assurance_itl_categorieassurance;
  return assurance;
}

async function creerSelectionAssurance(
  { assuranceCrm, primeAssurance },
  { http }
) {
  const selection = await http.post(
    "itl_selectionassurances?$select=itl_selectionassuranceid",
    {
      itl_name: `${
        assuranceCrm.itl_name
      } - ${primeAssurance.tauxApplique.toFixed(5)}`,
      "itl_AssuranceId@odata.bind": `/itl_assurances(${
        assuranceCrm.itl_assuranceid
      })`,
      itl_taux: primeAssurance.tauxApplique * 100,
      itl_isgarantieloyers: primeAssurance.option === GARANTIE_DES_LOYERS,
      itl_isperteexploitation: primeAssurance.option === PERTE_DE_RECETTES,
    }
  );
  return selection;
}

async function assurerDepense(
  { depenseCrm, assuranceCrm, selectionAssurance, primeAssurance },
  { http }
) {
  const { itl_selectionassuranceid } = selectionAssurance;
  const { itl_assuranceid } = assuranceCrm;
  await http.patch(`itl_depensefts(${depenseCrm.itl_depenseftid})`, {
    "itl_AssuranceId@odata.bind": `/itl_assurances(${itl_assuranceid})`,
    "itl_SelectionAssuranceId@odata.bind": `/itl_selectionassurances(${itl_selectionassuranceid})`,
    itl_montantassurance: Money(primeAssurance.annuelle.montant).toUnit(),
  });
}

class TropDeAssuranceDansCategorieErreur extends Error {
  constructor(idCategorieAssurance, nbAssurances) {
    const message = `Impossible d'assurer le matériel. La catégorie d'assurance ${idCategorieAssurance} a ${nbAssurances} assurance(s). Il n'en faut qu'une seule.`;
    super(message);
  }
}

import { call, put } from "redux-saga/effects";
import {
  RECUPERER_REFERENTIELS,
  recupererMarchesEtBrokers_succes,
} from "./actions";
import { Adapters } from "../reduxStore/adapters";
import { trySaga } from "../utils/sagasUtils";

export function* recupererReferentielsSaga() {
  const saga = function* () {
    yield call(recupererMarchesEtBrokersSaga);
  };

  yield* trySaga(saga, RECUPERER_REFERENTIELS);
}

export function* recupererMarchesEtBrokersSaga({
  referentielAdapter = Adapters.referentielAdapter,
} = {}) {
  const marchesEtBrokers = yield call(referentielAdapter.getMarchesEtBrokers);
  yield put(recupererMarchesEtBrokers_succes(marchesEtBrokers));
}

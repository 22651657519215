import { call, select } from "redux-saga/effects";
import { formatjjMMaaaa } from "../../../../../utils/dateUtils";
import { UrlsForce } from "../../../../../routing/Urls";
import { crmSendEmail } from "../../../../../Connecteurs/Crm/sendEmail";
import { identiteUtilisateur } from "../../../../../reduxStore/rootReducer";
import {
  MARCHES,
  POSTE_UTILISATEUR,
  selectIdCrmUtilisateurEst,
  selectUtilisateurALePoste,
} from "../../../../../identiteUtilisateur/reducer";
import { templating } from "../../../../../i18n/templating";
import { TEMPLATE_NOTIFICATION_DECISION_FR } from "./templatesDeNotifications/NOTIFICATION_DECISION_fr";
import { TEMPLATE_NOTIFICATION_DECISION_ES } from "./templatesDeNotifications/NOTIFICATION_DECISION_es";
import { UrlsCrm } from "../../../../../Connecteurs/Crm/Urls";
import { Adapters } from "../../../../../reduxStore/adapters";

const { ESPAGNE, FRANCE } = MARCHES;
const { DIRECTEUR_COMMERCIAL } = POSTE_UTILISATEUR;

export function* notifierLeCommercialDeLaDecisionSaga(
  idDerogation,
  decision,
  {
    detailPourNotificationAdapter = Adapters.detailPourNotificationMargeAdapter,
    envoyerEmail = crmSendEmail,
  } = {}
) {
  const detailPourNotification = yield call(
    detailPourNotificationAdapter.getDetailPourNotification,
    { idDerogation }
  );

  const notification = contenuDeLaNotification(
    detailPourNotification,
    decision
  );

  const { demandeur } = detailPourNotification.derogationConcernee;

  const mettreLeDirecteurRegionalEnCopie = yield call(
    doitMettreLeDirecteurRegionalEnCopieSaga,
    demandeur.directeur.id
  );

  const enCopie = [];
  if (mettreLeDirecteurRegionalEnCopie)
    enCopie.push(`/systemusers(${demandeur.directeur.id})`);
  demandeur.adv.forEach(adv => enCopie.push(`/systemusers(${adv.id})`));

  yield call(envoyerEmail, {
    sujet: notification.sujet,
    destinataire: `/systemusers(${demandeur.id})`,
    destinatairesCC: enCopie,
    corps: notification.texte,
    regarding: {
      type: "itl_derogation",
      id: `/itl_derogations(${idDerogation})`,
    },
  });
}

export function* doitMettreLeDirecteurRegionalEnCopieSaga(
  idDuDirecteurDuDemandeur
) {
  if (idDuDirecteurDuDemandeur === null) return false;

  const identite = yield select(identiteUtilisateur);

  const jeSuisLeDirecteurCommercial = selectUtilisateurALePoste(
    identite,
    DIRECTEUR_COMMERCIAL
  );
  const jeSuisLeDirecteurDuDemandeur = selectIdCrmUtilisateurEst(
    identite,
    idDuDirecteurDuDemandeur
  );

  return jeSuisLeDirecteurCommercial && !jeSuisLeDirecteurDuDemandeur;
}

export function contenuDeLaNotification(detailPourNotification, decision) {
  const templatesParMarche = {
    [FRANCE]: TEMPLATE_NOTIFICATION_DECISION_FR,
    [ESPAGNE]: TEMPLATE_NOTIFICATION_DECISION_ES,
  };
  const { derogationConcernee } = detailPourNotification;

  const template = templatesParMarche[derogationConcernee.demandeur.marche];
  const { CORPS, SUJET } = template;

  const texte = templating(CORPS, {
    dateDeDemandeFormatee: formatjjMMaaaa(derogationConcernee.dateDemande),
    verdict: decision.verdict,
    nomDerogateur: derogationConcernee.derogateur.nom,
    commentaire: derogationConcernee.derogateur.commentaire,
    nomDuClient: derogationConcernee.client.nom,
    nomDuProjet: derogationConcernee.projet.nom,
    urlPourOuvrirFtEnCrm: UrlsCrm.ft(derogationConcernee.ft.id).ouvrir,
    urlPourOuvrirProjetEnCrm: UrlsForce.projet(derogationConcernee.projet.id)
      .ouvrir,
  });

  const sujet = templating(SUJET, {
    emoji: decision.emoji,
    nomDuProjet: derogationConcernee.projet.nom,
    verdict: decision.verdict,
  });

  return { texte, sujet };
}

export function trier(optionsNonTriees) {
  return optionsNonTriees.sort((a, b) => {
    if (a.estUnDefault && !b.estUnDefault) return 1;
    else if (!a.estUnDefault && b.estUnDefault) return -1;
    else return `${a.groupe}${a.label}`.localeCompare(`${b.groupe}${b.label}`);
  });
}

export function grouper(optionsAvecGroupe) {
  const groupeEnKey = optionsAvecGroupe.reduce((lesGroupes, option) => {
    const { groupe, ...optionBrute } = option;
    const leGroupe = lesGroupes[groupe] ?? { options: [] };
    return {
      ...lesGroupes,
      [groupe]: {
        options: [...leGroupe.options, optionBrute],
      },
    };
  }, {});

  return Object.keys(groupeEnKey).map(groupe => ({
    label: groupe,
    options: groupeEnKey[groupe].options,
  }));
}

import { httpServiceCrm } from "../../../Connecteurs/Crm/httpServiceCrm";
import { odataQuery } from "../../../utils/odata/odata";
import { MAPPING_CRM_VERS_DOMAINE } from "../../../Connecteurs/Crm/codesGroupesOptions";
import { $obj } from "../../../Money/money";
import { TYPE_DE_DEROGATION } from "./reducer";
import { getMarcheDeDivisionCrm } from "../../../identiteUtilisateur/identite.adapter.crm";
import { reporterSentry } from "../../../reporting/reporterSentry";
import { STATE_ACTIF } from "../../../Connecteurs/Crm/filtres";

const ID_TYPE_DEROGATION_DE_VR = process.env.REACT_APP_ID_TYPE_DE_DEROGATION_VR.toUpperCase();
const ID_TYPE_DEROGATION_DE_MARGE = process.env.REACT_APP_ID_TYPE_DE_DEROGATION_MARGE.toUpperCase();
const ID_TYPE_DEROGATION_DE_TEG = process.env.REACT_APP_ID_TYPE_DE_DEROGATION_TEG.toUpperCase();
const ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE = process.env.REACT_APP_ID_TYPE_DE_DEROGATION_ASSURANCE_ESPAGNE.toUpperCase();

const MAPPING_ID_TYPES_DE_DEROGATION = {
  [ID_TYPE_DEROGATION_DE_MARGE]: TYPE_DE_DEROGATION.MARGE,
  [ID_TYPE_DEROGATION_DE_VR]: TYPE_DE_DEROGATION.VR,
  [ID_TYPE_DEROGATION_DE_TEG]: TYPE_DE_DEROGATION.TEG,
  [ID_TYPE_DEROGATION_ASSURANCE_ESPAGNE]: TYPE_DE_DEROGATION.ASSURANCE_ESPAGNE,
};
const { statutDerogation } = MAPPING_CRM_VERS_DOMAINE;

export const resumeDeDerogationAdapterCrm = {
  async getTypeDeLaDerogation(
    idDerogation,
    { httpService = httpServiceCrm } = {}
  ) {
    const typeCrm = odataQuery(`itl_derogations(${idDerogation})`, {
      $select: ["_itl_typederogation_value"],
    });
    const { _itl_typederogation_value } = await httpService.get(typeCrm);
    return getTypeDeDerogation(_itl_typederogation_value);
  },

  async getDerogations({
    httpService = httpServiceCrm,
    reporter = reporterSentry,
  } = {}) {
    const champsDirects = [
      "itl_derogationid",
      "itl_name",
      "itl_statutderogation",
      "itl_datedemande",
      "itl_valeurdemandee",
      "itl_datedecision",
      "itl_datecombroke",
      "_itl_typederogation_value",
    ];

    const auteur = [
      "createdby",
      { $select: ["systemuserid", "fullname", "_parentsystemuserid_value"] },
    ];

    const ft = [
      "itl_FTconcernee",
      { $select: ["itl_name", "itl_actualisationestimee"] },
    ];

    const projet = [
      "itl_projetconcerne",
      { $select: ["_itl_marcheid_value", "_owningbusinessunit_value"] },
    ];

    const derogateur = [
      "itl_derogateur",
      { $select: ["_itl_derogateur_value"] },
    ];

    const requete = odataQuery("itl_derogations", {
      $select: champsDirects,
      $expand: [auteur, ft, projet, derogateur],
      $filter: [`itl_projetconcerne/${STATE_ACTIF}`],
      $orderBy: [["itl_datedemande", "desc"]],
    });

    const degradees = [];
    const derogations = (await httpService.get(requete))
      .map(d => {
        try {
          return {
            id: d.itl_derogationid,
            type: getTypeDeDerogation(d._itl_typederogation_value),
            nom: d.itl_name,
            statut: statutDerogation[d.itl_statutderogation],
            dateDemande: d.itl_datedemande,
            valeurDemandee: d.itl_valeurdemandee,
            dateDecision: d.itl_datedecision,
            dateAvisBroker: d.itl_datecombroke,
            auteur: {
              id: d.createdby.systemuserid,
              nom: d.createdby.fullname,
              directeur: { id: d.createdby._parentsystemuserid_value },
            },
            ficheDeTransmission: {
              nom: d.itl_FTconcernee.itl_name,
              actualisationEstimee: $obj(
                d.itl_FTconcernee.itl_actualisationestimee
              ),
            },
            projet: {
              idMarche: d.itl_projetconcerne._itl_marcheid_value,
              division: getMarcheDeDivisionCrm(
                d.itl_projetconcerne._owningbusinessunit_value
              ),
            },
            derogateur: d.itl_derogateur
              ? { utilisateur: { id: d.itl_derogateur._itl_derogateur_value } }
              : null,
          };
        } catch (e) {
          degradees.push({ derogation: { nom: d.itl_name }, e });
        }
      })
      .filter(d => d !== undefined);

    if (degradees.length > 0)
      reporter.reportError(new DerogsDegradeesError(degradees));

    return derogations;
  },

  async getIdDerogateurDeUtilisateur(
    idUtilisateur,
    idTypeDerogation,
    { http = httpServiceCrm } = {}
  ) {
    const derogateurs = await http.get(
      odataQuery("itl_derogateurs", {
        $select: ["itl_derogateurid"],
        $filter: [
          `_itl_derogateur_value eq ${idUtilisateur}`,
          `_itl_typederogation_value eq ${idTypeDerogation}`,
        ],
      })
    );

    return derogateurs[0].itl_derogateurid;
  },
};

function getTypeDeDerogation(idTypeCrm) {
  return MAPPING_ID_TYPES_DE_DEROGATION[idTypeCrm.toUpperCase()];
}

export class DerogsDegradeesError extends Error {
  constructor(derogations) {
    const nombre = `${derogations.length} dérogation(s) dégradée(s)`;
    const invisible = "(l'utilisateur n'a vu aucune erreur)";
    const details = derogations
      .map(({ derogation, e }) => `\t${derogation.nom} : ${e.message}`)
      .join("\n");

    super(`${nombre} ${invisible}\n${details}`);
  }
}

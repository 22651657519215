import { URL_DU_CRM } from "./webApi";

export function rapportFicheTransmissionUrl(idFt) {
  // La génération d'URL est très bas-niveau...
  // Je n'ai pas su faire mieux : la doc qui parle de consulter un rapport via son URL
  // ne montre pas comment ouvrir le rapport directement sur une entité :
  // https://docs.microsoft.com/en-us/dynamics365/customer-engagement/developer/open-forms-views-dialogs-reports-url#opening-a-report-by-using-a-url
  // Donc l'URL est forgée avec des paramètres très techniques.

  const reportViewer = "crmreports/viewer/viewer.aspx";
  const action = "action=run";
  const rapport = `id={${
    process.env.REACT_APP_REPORTING_ID_DU_RAPPORT_FICHE_TRANSMISSION
  }}`;
  const contexte = "context=records";
  const record = `records={${idFt.toUpperCase()}}`;
  const recordType = `recordstype=${
    process.env.REACT_APP_REPORTING_RECORDSTYPE_FICHE_TRANSMISSION
  }`;

  return encodeURI(
    `${URL_DU_CRM}/${reportViewer}?${action}&${contexte}&${rapport}&${record}&${recordType}`
  );
}

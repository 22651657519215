import { Money } from "../../../../../../Money/money";
import {
  DEFINIR_PRIME_ASSURANCE_ESPAGNE_MANUELLE,
  DEFINIR_PRIME_MINIMALE_ASSURANCE_ESPAGNE,
} from "./actions";
import { selectMarcheUtilisateur } from "../../profilUtilisateur/reducer";
import { MARCHES } from "../../../../../../identiteUtilisateur/reducer";
import { montantAchatDesProduitsNeufs } from "../../montantAFinancer/montants";
import { CHANGER_LA_PERIODICITE } from "../../modeDeCalcul/actions";
import { PERIODICITES } from "../../modeDeCalcul/periodicite/periodicite";

const { MENSUEL, TRIMESTRIEL, ANNUEL } = PERIODICITES;
const { ESPAGNE } = MARCHES;

export const PAS_DE_PRIME_ASSURANCE = {
  parLoyer: { montant: null },
  annuelle: { montant: null },
};

const STATE_INITIAL = {
  prime: PAS_DE_PRIME_ASSURANCE,
  primeMinimale: { annuelle: { montant: null } },
};

export default function reducer(state = STATE_INITIAL, action) {
  switch (action.type) {
    case DEFINIR_PRIME_ASSURANCE_ESPAGNE_MANUELLE:
      return { ...state, prime: action.prime };

    case CHANGER_LA_PERIODICITE:
      return {
        ...state,
        prime: {
          ...state.prime,
          parLoyer: {
            ...state.prime.parLoyer,
            montant: calculerMontantParLoyer(
              Money(state.prime.annuelle.montant),
              action.periodicite
            ).toObject(),
          },
        },
      };

    case DEFINIR_PRIME_MINIMALE_ASSURANCE_ESPAGNE:
      return { ...state, primeMinimale: action.prime };

    default:
      return state;
  }
}

export function calculerMontantParLoyer(montantAnnuel, periodicite) {
  const loyersParAn = { [MENSUEL]: 12, [TRIMESTRIEL]: 4, [ANNUEL]: 1 };
  return montantAnnuel.divide(loyersParAn[periodicite]);
}

export const selectPrimeAssuranceEspagne = (state) => state.prime;

export const montantPrimeAssuranceEspagneParLoyer = (state) =>
  Money(state.prime.parLoyer.montant);

export const selectAssuranceEspagneEstAjoutee = (state) => {
  return state.prime !== PAS_DE_PRIME_ASSURANCE;
};

export function selectAssuranceEspagneEstVisible(profilUtilisateur) {
  const marcheUtilisateur = selectMarcheUtilisateur(profilUtilisateur);
  return marcheUtilisateur === ESPAGNE;
}

export const getMontantAAssurer = (simulation) =>
  montantAchatDesProduitsNeufs(simulation.montantAFinancer);

export const selectMontantPrimeMinimaleAssuranceEspagne = (state) =>
  state.primeMinimale.annuelle.montant;

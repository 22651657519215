import { createFilter } from "react-select";

// https://react-select.com/advanced
const filtreParDefaut = createFilter();
export function avecDefaultToujoursVisibles(candidat, input) {
  if (candidat.data.estUnDefault) return true;
  return filtreParDefaut(candidat, input);
}

export function filtreDeMateriels(candidat, input) {
  const { data } = candidat;

  if (data.estUnDefault) return true;

  const correspondance = recherche =>
    recherche.toLowerCase().includes(input.trim().toLowerCase());

  if (correspondance(data.label ?? "")) return true;

  const { categorisation } = data.value;
  if (correspondance(categorisation?.niveauEnfant ?? "")) return true;
  if (correspondance(categorisation?.niveauParent ?? "")) return true;

  const { motsCles } = data;
  if (motsCles?.some(mot => correspondance(mot))) return true;

  return false;
}

import {
  ajouterCommission,
  changerLeTauxDeMarge,
  financerProduitAchete,
  racheterContrat,
  racheterUnMateriel,
} from "../simulation/montantAFinancer/actions";
import { CODES_GROUPES_OPTIONS } from "../../../../Connecteurs/Crm/codesGroupesOptions";
import {
  ajouterDerogationAssuranceEspagne,
  ajouterDerogationDeTeg,
  ajouterDerogationDeVr,
} from "../simulation/derogations/actions";
import { changerTypeDeFinancement } from "../simulation/valeurResiduelle/actions";
import {
  calculerEnLineaire,
  calculerEnMultiPaliers,
  changerLaPeriodicite,
} from "../simulation/modeDeCalcul/actions";
import { definirChaineDeLoyersDeReference } from "../simulation/chaineDeLoyersDeReference/actions";
import { choisirLeTerme } from "../simulation/terme/actions";
import {
  changerDelaiDeReglement,
  changerModeDeReglement,
} from "../simulation/conditionsDeReglement/actions";
import {
  LOUEUR_DE_CONTRAT,
  PAS_DE_MATERIEL_SUR_LE_RACHAT,
} from "../simulation/montantAFinancer/reducer";
import { definirPrimeAssuranceEspagneManuelle } from "../simulation/assurance/espagne/actions";
import { supprimerDernierLoyer } from "../simulation/modeDeCalcul/paliers/supprimerDernierLoyer";
import { labelCatalogue } from "../simulation/catalogueProduits/reducer";
import { donnerTegManuel } from "../simulation/tauxInteret/actions";
import {
  activerMaintenance,
  decalerDebutDeMaintenance,
  passerEnMaintenanceAvecEquilibrage,
} from "../simulation/maintenance/actions";
import { $, Money } from "../../../../Money/money";

const { typesDeMateriels, typesDeRachats } = CODES_GROUPES_OPTIONS;

export const importService = {
  convertirFtEnActions(ft, { timestampImport, catalogueProduits }) {
    return [
      ...transformerProduitsAchetes(ft, timestampImport),
      ...transformerRachatsDeContrats(ft, timestampImport, catalogueProduits),
      ...transformerRachatsDeMateriels(ft, timestampImport),
      ...transformerCommissions(ft),
      ...transformerLesInformationsFinancieres(ft),
      ...transformerLesDerogationsDeVr(ft),
      ...transformerLesDerogationsDeTeg(ft),
      ...transformerLesLoyers(ft),
      ...transformerAssuranceEspagneDeLaFt(ft),
      ...transformerLaMaintenance(ft),
    ];
  },
};

function transformerProduitsAchetes(ft, timestampImport) {
  return getProduitsAchetes(ft).map((depense, key) =>
    financerProduitAchete({ ...depense, timestamp: timestampImport + key })
  );
}

function transformerLesDerogationsDeVr(ft) {
  return ft.derogationsDeVr.map((derogation) =>
    ajouterDerogationDeVr(derogation.produit.id, derogation)
  );
}

function transformerLesDerogationsDeTeg(ft) {
  return ft.derogationsDeTeg
    .map((derogation) => [
      ajouterDerogationDeTeg(derogation),
      donnerTegManuel(derogation.valeurDemandee),
    ])
    .flat();
}

function transformerRachatsDeContrats(ft, timestampImport, catalogueProduits) {
  const { rachatContratConcurrent, rachatContratItl } = typesDeRachats;
  const { CONCURRENT, ITL } = LOUEUR_DE_CONTRAT;

  const loueurs = {
    [rachatContratItl]: ITL,
    [rachatContratConcurrent]: CONCURRENT,
  };

  return ft.depenses
    .filter(
      (d) =>
        d.codeTypeRachat === rachatContratConcurrent ||
        d.codeTypeRachat === rachatContratItl
    )
    .map((depense) => {
      const reventeDeLaDepense = ft.reventes.find(
        (r) => r.idDepense === depense.id
      );
      const pasDeReventeLiee = { montantUnitaire: null };

      return {
        depense,
        revente: reventeDeLaDepense || pasDeReventeLiee,
      };
    })
    .map(({ depense, revente }, key) =>
      racheterContrat({
        id: depense.id,
        montantEncours: depense.montantUnitaire,
        montantDeLaRevente: revente.montantUnitaire,
        dateEncours: depense.contrat.dateEncours,
        numeroContrat: depense.contrat.numeroContrat,
        loueurContrat: loueurs[depense.codeTypeRachat],
        description: depense.description,
        materielRachete: depense.idFamille
          ? {
              idFamille: depense.idFamille,
              idMarque: depense.idMarque,
              label: labelCatalogue(catalogueProduits, depense),
            }
          : PAS_DE_MATERIEL_SUR_LE_RACHAT,
        timestamp: timestampImport + (key + 1000), // fix order
      })
    );
}

function transformerRachatsDeMateriels(ft, timestampImport) {
  const { rachat } = typesDeMateriels;
  const { achatReventeMateriel } = typesDeRachats;

  const partieAchat = ft.depenses.filter(
    (d) =>
      d.codeTypeMateriel === rachat && d.codeTypeRachat === achatReventeMateriel
  );

  if (partieAchat.length === 0) return [];

  const idDepenses = partieAchat.map((depense) => depense.id);

  return ft.reventes
    .filter((revente) => idDepenses.includes(revente.idDepense))
    .map((revente, key) => {
      const depense = partieAchat.find((d) => d.id === revente.idDepense);
      return racheterUnMateriel({
        id: depense.id,
        montantDuRachat: depense.montantUnitaire,
        montantDeLaRevente: revente.montantUnitaire,
        description: depense.description,
        annee: depense.annee,
        timestamp: timestampImport + (key + 2000),
      });
    });
}

function transformerCommissions(ft) {
  return ft.commissions.map((c) =>
    ajouterCommission(c.id, c.montant, c.description)
  );
}

function transformerLesLoyers(ft) {
  const { periodicite } = ft.informationsFinancieres;
  const paliers = loyersEnPaliers(ft);

  const unSeulPalier = paliers.length === 1;
  const actionQuiCalcule = unSeulPalier
    ? calculerEnLineaire(paliers[0].duree)
    : calculerEnMultiPaliers(supprimerDernierLoyer(paliers));

  const chaineDeReference = definirChaineDeLoyersDeReference(
    paliers,
    periodicite
  );

  return [chaineDeReference, actionQuiCalcule];
}

function transformerLesInformationsFinancieres(ft) {
  const { informationsFinancieres } = ft;

  return [
    choisirLeTerme(informationsFinancieres.terme),
    changerModeDeReglement(informationsFinancieres.modeDeReglement),
    changerDelaiDeReglement(informationsFinancieres.delaiDeReglement),
    changerLeTauxDeMarge(informationsFinancieres.tauxDeMarge),
    changerLaPeriodicite(informationsFinancieres.periodicite),
    changerTypeDeFinancement(informationsFinancieres.typeDeFinancement),
  ];
}

function* transformerAssuranceEspagneDeLaFt(ft) {
  if (ft.assurance.estAjoutee)
    yield definirPrimeAssuranceEspagneManuelle(ft.assurance.prime);

  for (const derogation of ft.derogationsAssuranceEspagne) {
    yield ajouterDerogationAssuranceEspagne({
      ...derogation,
      produitsAssocies: { montantTotal: montantDesAchats(ft).toObject() },
    });
  }
}

export function loyersEnPaliers(ft) {
  return ft.loyers.map((loyer) => ({
    duree: loyer.nombreEcheances,
    loyer: loyer.montant,
  }));
}

export function getProduitsAchetes(ft) {
  return ft.depenses.filter(
    (d) => d.codeTypeMateriel === typesDeMateriels.materielAchete
  );
}

export function getNombreEcheances(ft) {
  return ft.loyers.reduce((duree, loyer) => duree + loyer.nombreEcheances, 0);
}

function montantDesAchats(ft) {
  return getProduitsAchetes(ft).reduce(
    (total, { quantite, montantUnitaire }) =>
      Money(montantUnitaire).multiply(quantite).add(total),
    $(0)
  );
}

export function transormerToutesLesDepenses(ft) {
  return [
    ...transformerProduitsAchetes(ft),
    ...transformerRachatsDeContrats(ft),
    ...transformerRachatsDeMateriels(ft),
    ...transformerCommissions(ft),
  ];
}

function* transformerLaMaintenance(ft) {
  if (!ft.maintenance.estActivee) return [];

  yield activerMaintenance({
    dureeEnEcheances: ft.maintenance.dureeEnEcheances,
    montantTotal: ft.maintenance.montantTotal,
  });

  if (ft.maintenance.nbEcheancesDeDecalage > 0)
    yield decalerDebutDeMaintenance(ft.maintenance.nbEcheancesDeDecalage);

  if (ft.maintenance.avecEquilibrage)
    yield passerEnMaintenanceAvecEquilibrage();
}

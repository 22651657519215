import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import "purecss-components/dist/bundles/inline-alert.css";
import "./Alert.css";

export const TYPE_ALERTE = {
  ERREUR: "ERREUR",
  INFO: "INFO",
};

const Alert = ({ children, type, onClose }) => {
  const uid = uuid();
  return (
    <div className="pcssc-alert">
      <input
        type="checkbox"
        id={"close-alert-" + uid}
        className="alert-toggle pcssc-invisible"
        onClick={onClose}
      />
      <div className={recupererCouleurDepuisType(type)} role="alert">
        <span>{children}</span>
        <label htmlFor={"close-alert-" + uid} className="close-alert ml-10">
          <span>&times;</span>
        </label>
      </div>
    </div>
  );
};

const recupererCouleurDepuisType = (type) => {
  const classesBase = "alert alert-success alert-dismissible";

  switch (type) {
    case TYPE_ALERTE.ERREUR:
      return classesBase + " button-error";
    default:
      return classesBase + " button-secondary";
  }
};

Alert.propTypes = {
  type: PropTypes.oneOf(Object.keys(TYPE_ALERTE)),
  onClose: PropTypes.func,
};

export default Alert;

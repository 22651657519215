import { call } from "redux-saga/effects";
import { listeDesRachatsDeMateriels } from "../../../../simulation/montantAFinancer/reducer";
import { mapperRachatDeMateriel } from "./rachatDeMateriel.mapping";
import { crmPost } from "../../../../../../../Connecteurs/Crm/webApi";

export function* creerRachatsDeMaterielsSaga(simulation, idFt) {
  const rachats = yield call(
    listeDesRachatsDeMateriels,
    simulation.montantAFinancer
  );

  for (const rachat of rachats) {
    const mappingDuRachat = yield call(mapperRachatDeMateriel, rachat);

    const nouvelleDepenseCrm = yield call(crmPost, "itl_depensefts", {
      "itl_FicheTransmissionId@odata.bind": `/itl_fichetransmissions(${idFt})`,
      ...mappingDuRachat.depense,
    });

    yield call(crmPost, "itl_recettefts", {
      "itl_FicheTransmissionId@odata.bind": `/itl_fichetransmissions(${idFt})`,
      "itl_DepenseFtId@odata.bind": `/itl_depensefts(${
        nouvelleDepenseCrm.itl_depenseftid
      })`,
      ...mappingDuRachat.revente,
    });
  }
}

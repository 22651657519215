import BadgeErreur from "../../../../../Kit/Badges/Erreur/BadgeErreur";
import PropTypes from "prop-types";
import React from "react";

export default function ErreursDeCreation(props) {
  return (
    <>
      {props.toutesLesErreurs.length > 0 && (
        <div className="flex-1 mb-10">
          <BadgeErreur messages={props.toutesLesErreurs} />
        </div>
      )}
    </>
  );
}

ErreursDeCreation.propTypes = {
  toutesLesErreurs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

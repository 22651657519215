import {
  selectTegEffectif,
  selectTegGrille,
} from "../../simulation/tauxInteret/reducer";
import { AnomalieTegManuelInferieurAGrille } from "../Anomalies";
import { selectMarcheUtilisateur } from "../../simulation/profilUtilisateur/reducer";

export function verifierLeTegManuel(simulation) {
  const effectif = selectTegEffectif(
    simulation.tauxInteret,
    selectMarcheUtilisateur(simulation.profilUtilisateur)
  );
  const grille = selectTegGrille(simulation.tauxInteret);

  if (effectif < grille)
    return AnomalieTegManuelInferieurAGrille(grille, effectif);
}

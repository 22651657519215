import {
  getNombreEcheances,
  getProduitsAchetes,
  loyersEnPaliers,
} from "../importService";
import { Money } from "../../../../../Money/money";
import { call, put, select } from "redux-saga/effects";
import { demanderTauxDeVr } from "../../simulation/valeurResiduelle/actions";
import { Adapters } from "../../../../../reduxStore/adapters";
import { calculateur } from "../../../../../reduxStore/rootReducer";
import { selectVrParMois } from "../../simulation/valeurResiduelle/selecteurs/generalites.selecteurs";
import { TAUX_DE_VR_VIDE } from "../../simulation/valeurResiduelle/selecteurs/taux.selecteurs";
import { vrUnLoyerEstEffective } from "../../planDeFinancement/PlanDeFinancement/PlanDeFinancemenet.selecteurs";

export function* importerLesTauxDeVrSaga(
  ft,
  { vrGrillesAdapter = Adapters.vrGrillesAdapter } = {}
) {
  const paliers = loyersEnPaliers(ft);
  const montantTotalDeVr = ft.informationsFinancieres.vr.montant;
  const estVrUnLoyer = vrUnLoyerEstEffective({ paliers, montantTotalDeVr });

  if (estVrUnLoyer) yield* importerLesVrEnVrUnLoyer(ft);
  else yield* importerLesVrManuelles(ft, { vrGrillesAdapter });
}

function* importerLesVrEnVrUnLoyer(ft) {
  const tauxDeVr = getProduitsAchetes(ft).map((p) => ({
    materiel: p,
    taux: Money(p.vr.montant)
      .convertPrecision(6)
      .divide(Money(p.montantUnitaire).toUnit())
      .toUnit(),
  }));

  yield put(demanderTauxDeVr(tauxDeVr, { estUneDemandeDeVrUnLoyer: true }));
}

function* importerLesVrManuelles(ft, { vrGrillesAdapter }) {
  const { simulation } = yield select(calculateur);

  const materiels = getProduitsAchetes(ft).filter(
    (p) => p.vr.taux !== TAUX_DE_VR_VIDE
  );

  const materielsEtGrilles = materiels.map((p) => ({
    materiel: p,
    grilleDeVr: selectVrParMois(simulation.valeurResiduelle, p, simulation),
  }));

  const configuration = {
    typeDeFinancement: ft.informationsFinancieres.typeDeFinancement,
    nombreEcheances: getNombreEcheances(ft),
    periodicite: ft.informationsFinancieres.periodicite,
  };

  const vrGrilles = yield call(
    vrGrillesAdapter.getLesVrGrilles,
    materielsEtGrilles,
    configuration
  );

  const materielsAvecVrManuelle = materiels
    .map((m) => ({
      materielImport: m,
      materielVr: vrGrilles.find((vr) => vr.materiel.id === m.id),
    }))
    .filter(
      ({ materielImport, materielVr }) =>
        materielImport.vr.taux !== materielVr.tauxDeVr
    )
    .map(({ materielImport }) => ({
      materiel: materielImport,
      taux: materielImport.vr.taux,
    }));

  if (materielsAvecVrManuelle.length > 0)
    yield put(demanderTauxDeVr(materielsAvecVrManuelle));
}

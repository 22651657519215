import { MARCHES } from "../../../../../../identiteUtilisateur/reducer";
import { findGuidKey } from "../../../../../../utils/objetUtils";
import { isLizemed } from "../../../../../../Connecteurs/Crm/codesGroupesOptions";
import { SEUILS_AUTONOMIE_DES_DIRECTEURS_REGIONAUX } from "../../../../../../ConfigMarge/SeuilsDeMarge";

const { ESPAGNE, FRANCE } = MARCHES;
const MEDICAL = process.env.REACT_APP_ID_MARCHE_MEDICAL;
const ENV_IND = process.env.REACT_APP_ID_MARCHE_ENV_IND;
const DIVERS = process.env.REACT_APP_ID_MARCHE_DIVERS;
const HIGH_TECH = process.env.REACT_APP_ID_MARCHE_HIGH_TECH;
const INFO_BUREAUTIQUE = process.env.REACT_APP_ID_MARCHE_INFO_BUREAUTIQUE;
const OL_ENVIRONNEMENT = process.env.REACT_APP_ID_MARCHE_OL_ENVIRONNEMENT;

const {
  ESPAGNE: SEUILS_ESPAGNES,
  FRANCE: SEUILS_FRANCE,
} = SEUILS_AUTONOMIE_DES_DIRECTEURS_REGIONAUX;

const SEUILS_AUTONOMIE_DES_DR = {
  [FRANCE]: {
    [MEDICAL]: idMarqueItl =>
      isLizemed(idMarqueItl)
        ? SEUILS_FRANCE.MEDICAL.LIZEMED
        : SEUILS_FRANCE.MEDICAL.AUTRE,
    [ENV_IND]: () => SEUILS_FRANCE.ENV_IND,
    [DIVERS]: () => SEUILS_FRANCE.DIVERS,
    [HIGH_TECH]: () => SEUILS_FRANCE.HIGH_TECH,
    [INFO_BUREAUTIQUE]: () => SEUILS_FRANCE.INFO_BUREAUTIQUE,
    [OL_ENVIRONNEMENT]: () => SEUILS_FRANCE.OL_ENVIRONNEMENT,
  },
  [ESPAGNE]: {
    [MEDICAL]: () => SEUILS_ESPAGNES.MEDICAL,
    [ENV_IND]: () => SEUILS_ESPAGNES.ENV_IND,
    [DIVERS]: () => SEUILS_ESPAGNES.DIVERS,
    [HIGH_TECH]: () => SEUILS_ESPAGNES.HIGH_TECH,
    [INFO_BUREAUTIQUE]: () => SEUILS_ESPAGNES.INFO_BUREAUTIQUE,
    [OL_ENVIRONNEMENT]: () => SEUILS_ESPAGNES.OL_ENVIRONNEMENT,
  },
};

export function seuilAutonomieDuDirecteurRegional({
  marcheUtilisateur,
  idMarcheProjet,
  idMarqueItl,
}) {
  const seuilsDuPays = SEUILS_AUTONOMIE_DES_DR[marcheUtilisateur];

  const cleDuMarcheProjet = findGuidKey(seuilsDuPays, idMarcheProjet);

  return seuilsDuPays[cleDuMarcheProjet](idMarqueItl);
}

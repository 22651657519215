import { connect } from "react-redux";
import NatureDuCalcul from "./NatureDuCalcul";
import { selectNatureDuCalcul } from "../../domaine/simulation/modeDeCalcul/reducer";
import { calculateur } from "../../../../reduxStore/rootReducer";
import { changerNatureDuCalcul } from "../../domaine/simulation/modeDeCalcul/actions";
import { orchestrerLePlanDeFinancement } from "../../domaine/planDeFinancement/actions";
import {
  listeAFinancer,
  listeDesCommissions,
  listeDesProduitsAchetes,
} from "../../domaine/simulation/montantAFinancer/reducer";
import { selectMaintenance } from "../../domaine/simulation/maintenance/reducer";

const mapStateToProps = (state) => {
  const { simulation } = calculateur(state);
  return {
    natureActive: selectNatureDuCalcul(simulation.modeDeCalcul),
    natureInvestissementEstDisponible: peutCalculerEnInvestissement(simulation),
  };
};

function peutCalculerEnInvestissement({ montantAFinancer, maintenance }) {
  const aucunProduit = listeAFinancer(montantAFinancer).length === 0;
  const seulementUnProduit =
    listeAFinancer(montantAFinancer).length === 1 &&
    listeDesProduitsAchetes(montantAFinancer).length === 1;
  const aucuneCommission = listeDesCommissions(montantAFinancer).length === 0;
  const pasDeMaintenance = !selectMaintenance(maintenance).estActivee;

  return (
    aucuneCommission && (aucunProduit || seulementUnProduit) && pasDeMaintenance
  );
}

const mapDispatchToProps = (dispatch) => ({
  onChange: (nouvelleNature) => {
    dispatch(changerNatureDuCalcul(nouvelleNature));
    dispatch(orchestrerLePlanDeFinancement());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NatureDuCalcul);

import { all, call, select } from "redux-saga/effects";
import { calculateur } from "../../../../../../../reduxStore/rootReducer";
import { listeDesCommissions } from "../../../../simulation/montantAFinancer/reducer";
import { Adapters } from "../../../../../../../reduxStore/adapters";
import { getMarcheUtilisateur } from "../../../../simulation/profilUtilisateur/sagas";
import { selectProjetDeTravail } from "../../../../simulation/projetDeTravail/reducer";

export function* creerCommissionsSaga(
  ft,
  { commissionsAdapter = Adapters.commissionsAdapter } = {}
) {
  const { simulation } = yield select(calculateur);
  const projetDeTravail = selectProjetDeTravail(simulation.projetDeTravail);
  const marcheUtilisateur = yield* getMarcheUtilisateur();
  const commissions = listeDesCommissions(simulation.montantAFinancer);

  const creerToutesLesCommissions = commissions.map((c) =>
    call(
      commissionsAdapter.creerCommission,
      { ft, projetDeTravail, marcheUtilisateur },
      c
    )
  );

  yield all(creerToutesLesCommissions);
}

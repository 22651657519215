import { fetchAvecTokenAzure } from "../../authentification/fetch";
import { extractErrorMessageK3000 } from "../K3000/httpK3000";
import { scopeR4000 } from "../R4000/httpR4000";

const URL_ASSU4000 = process.env.REACT_APP_URL_ASSU4000;

// Assu4000 utilise le même scope que R4000.
// Car Assu4000 est simplement une URL en façade de R4000.
const scopeAssu4000 = scopeR4000;

export const httpAssu4000 = {
  async post(cible, body) {
    const reponse = await fetchAvecTokenAzure(
      `${URL_ASSU4000}${cible}`,
      scopeAssu4000,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      },
      extractErrorMessageK3000
    );

    return JSON.parse(reponse);
  },
};

import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./rootReducer";
import sagasDesInstantanes from "./enhancers/instantanes/sagas";
import sagasDuCalculateur from "../App/Calculateur/domaine/sagas";
import sagasDeChoixDuProjet from "../App/Calculateur/domaine/simulation/projetDeTravail/sagas";
import sagasDesFichesDeTransmission from "../App/Calculateur/domaine/creationDeFt/creation/sagas";
import sagasDesDerogations from "../App/Derogations/domaine/sagas";
import sagasDuReporting from "../reporting/sagas";
import { sagaDeDemarrage } from "./demarrage";
import sagasDuPlanDeFinancement from "../App/Calculateur/domaine/planDeFinancement/sagas";
import sagasDeAssuranceEspagne from "../App/Calculateur/domaine/simulation/assurance/espagne/sagas";
import sagasDeAssuranceFrance from "../App/Calculateur/domaine/simulation/assurance/france/sagas";
import { sagasDuCatalogueProduit } from "../App/Calculateur/domaine/simulation/catalogueProduits/sagas";
import { ADAPTERS_DE_PROD, registerAdapters } from "./adapters";
import { sagasDuModeLight } from "../App/ModeLight/domaine/sagas";
import { orchestrerLePlanDeFinancement } from "../App/Calculateur/domaine/planDeFinancement/actions";
import { activerMaintenance } from "../App/Calculateur/domaine/simulation/maintenance/actions";
import { $obj } from "../Money/money";

const activerMaintenancePourTotalDe1200Sur12Echeances = () =>
  activerMaintenance({ montantTotal: $obj(1_200), dureeEnEcheances: 12 });

export function getStore(adapters = ADAPTERS_DE_PROD) {
  const composeEnhancers = composeWithDevTools({
    trace: process.env.NODE_ENV === "development",
    traceLimit: 25,
    actionCreators: {
      activerMaintenancePourTotalDe1200Sur12Echeances,
      orchestrerLePlanDeFinancement,
    },
  });

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  registerAdapters(adapters);

  sagaMiddleware.run(sagaDeDemarrage);
  sagaMiddleware.run(sagasDuCalculateur);
  sagaMiddleware.run(sagasDuPlanDeFinancement);
  sagaMiddleware.run(sagasDeAssuranceEspagne);
  sagaMiddleware.run(sagasDeAssuranceFrance);
  sagaMiddleware.run(sagasDeChoixDuProjet);
  sagaMiddleware.run(sagasDesFichesDeTransmission);
  sagaMiddleware.run(sagasDesDerogations);
  sagaMiddleware.run(sagasDesInstantanes);
  sagaMiddleware.run(sagasDuReporting);
  sagaMiddleware.run(sagasDuCatalogueProduit);
  sagaMiddleware.run(sagasDuModeLight);

  return store;
}
